export function deltaTime(
  startDate: Date | number | string,
  endDate: Date | number | string
): {
  hours: number;
  minutes: number;
  seconds: number;
} {
  const timeOn = calculateTimeOn(startDate, endDate);
  return scomposeTimeOn(timeOn);
}

export function calculateTimeOn(startDate: Date | number | string, endDate: Date | number | string): number {
  let start: number;
  let end: number;

  if (startDate instanceof Date) {
    start = startDate.getTime();
  } else {
    start = +startDate;
  }
  if (endDate instanceof Date) {
    end = endDate.getTime();
  } else {
    end = +endDate;
  }

  return (end - start) / 1000;
}

export function scomposeTimeOn(timeOn: number): {
  hours: number;
  minutes: number;
  seconds: number;
} {
  const hours = Math.floor(timeOn / 3600);
  const minutes = Math.floor((timeOn % 3600) / 60);
  const seconds = Math.floor(timeOn % 60);

  return { hours, minutes, seconds };
}

export function isToday(date: Date): boolean {
  const today = new Date();
  return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
}

export function dateToIso(date: Date, complete = false): string {
  const isoComplete = date.toISOString();
  if (complete) {
    return isoComplete;
  }
  return isoComplete.split('T')[0];
}

export function getTime(date?: Date): number {
  // Returns the time in milliseconds since 1970/01/01
  if (!date) date = new Date();
  return date.getTime();
}
