[
    {
        "regex": "Grid OS (\\d+[\\.\\d]+)",
        "name": "GridOS",
        "version": "$1"
    },
    {
        "regex": "CaixaMagica",
        "name": "Caixa Mágica",
        "version": ""
    },
    {
        "regex": "Mageia; Linux",
        "name": "Mageia",
        "version": ""
    },
    {
        "regex": "(?:WH|WhaleTV/)(\\d+[\\.\\d]+)",
        "name": "Whale OS",
        "version": "$1"
    },
    {
        "regex": "Tizen[ /]?(\\d+[\\.\\d]+)?",
        "name": "Tizen",
        "version": "$1"
    },
    {
        "regex": "Sailfish|Jolla",
        "name": "Sailfish OS",
        "version": ""
    },
    {
        "regex": "(?:Ali)?YunOS[ /]?(\\d+[\\.\\d]+)?",
        "name": "YunOS",
        "version": "$1"
    },
    {
        "regex": "Windows Phone;FBSV/(\\d+[\\.\\d]+)",
        "name": "Windows Phone",
        "version": "$1"
    },
    {
        "regex": "(?:Windows Phone (?:OS)?|wds)[ /]?(\\d+[\\.\\d]+)",
        "name": "Windows Phone",
        "version": "$1"
    },
    {
        "regex": "XBLWP7|Windows Phone",
        "name": "Windows Phone",
        "version": ""
    },
    {
        "regex": "Windows CE(?: (\\d+[\\.\\d]+))?",
        "name": "Windows CE",
        "version": "$1"
    },
    {
        "regex": "(?:IEMobile|Windows ?Mobile)(?: (\\d+[\\.\\d]+))?",
        "name": "Windows Mobile",
        "version": "$1"
    },
    {
        "regex": "Windows NT 6.2; ARM;",
        "name": "Windows RT",
        "version": ""
    },
    {
        "regex": "Windows NT 6.3; ARM;",
        "name": "Windows RT",
        "version": "8.1"
    },
    {
        "regex": "Windows IoT 10.0",
        "name": "Windows IoT",
        "version": "10"
    },
    {
        "regex": "KAIOS(?:/(\\d+[\\.\\d]+))?",
        "name": "KaiOS",
        "version": "$1"
    },
    {
        "regex": "RazoDroiD(?: v(\\d+[\\.\\d]*))?",
        "name": "RazoDroiD",
        "version": "$1"
    },
    {
        "regex": "MildWild(?: CM-(\\d+[\\.\\d]*))?",
        "name": "MildWild",
        "version": "$1"
    },
    {
        "regex": "CyanogenMod(?:[\\-/](?:CM)?(\\d+[\\.\\d]*))?",
        "name": "CyanogenMod",
        "version": "$1"
    },
    {
        "regex": "(?:.*_)?MocorDroid(?:(\\d+[\\.\\d]*))?",
        "name": "MocorDroid",
        "version": "$1"
    },
    {
        "regex": "Fire OS(?:/(\\d+[\\.\\d]*))?",
        "name": "Fire OS",
        "version": "$1"
    },
    {
        "regex": "AFTSO001",
        "name": "Fire OS",
        "version": "7"
    },
    {
        "regex": "FydeOS",
        "name": "FydeOS",
        "version": ""
    },
    {
        "regex": "(?:Podbean|Podimo)(?:.+)?/Android",
        "name": "Android",
        "version": ""
    },
    {
        "regex": "Pinterest for Android/.+; (\\d(?:[\\d\\.]+)?)\\)$",
        "name": "Android",
        "version": "$1"
    },
    {
        "regex": "Android; (\\d+[\\.\\d]*); Mobile;",
        "name": "Android",
        "version": "$1"
    },
    {
        "regex": "[ ]([\\d\\.]+)\\) AppleWebKit.*ROBLOX Android App",
        "name": "Android",
        "version": "$1"
    },
    {
        "regex": "(?:(?:Orca-)?Android|Adr)[ /]?(?:[a-z]+ )?(\\d+[\\.\\d]*)",
        "name": "Android",
        "version": "$1"
    },
    {
        "regex": "(?:Allview_TX1_Quasar|Cosmote_My_mini_Tab) (\\d+[\\.\\d]*)",
        "name": "Android",
        "version": "$1"
    },
    {
        "regex": "Android ?(?:jelly bean|Kit Kat|S.O. Ginger Bread|The FireCyano|:) (\\d+[\\.\\d]*)",
        "name": "Android",
        "version": "$1"
    },
    {
        "regex": "(?:Orca-Android|FB4A).*FBSV/(\\d+[\\.\\d]*);",
        "name": "Android",
        "version": "$1"
    },
    {
        "regex": " Adr |Android|Silk-Accelerated=[a-z]{4,5}",
        "name": "Android",
        "version": ""
    },
    {
        "regex": "(?:TwitterAndroid).*[ /](?:[a-z]+ )?(\\d+[\\.\\d]*)",
        "name": "Android",
        "version": "$1"
    },
    {
        "regex": "BeyondPod|AntennaPod|Podkicker|DoggCatcher|Player FM|okhttp|Podcatcher Deluxe|.+K_?Android_?TV_|Sonos/.+\\(ACR_",
        "name": "Android",
        "version": ""
    },
    {
        "regex": "Linux; Andr0id[; ](\\d+[\\.\\d]*)",
        "name": "Android",
        "version": "$1"
    },
    {
        "regex": "Linux; diordnA[; ](\\d+[\\.\\d]*)",
        "name": "Android",
        "version": "$1"
    },
    {
        "regex": "AmigaOS[ ]?(\\d+[\\.\\d]+)",
        "name": "AmigaOS",
        "version": "$1"
    },
    {
        "regex": "AmigaOS|AmigaVoyager|Amiga-AWeb",
        "name": "AmigaOS",
        "version": ""
    },
    {
        "regex": "ThreadX(?:/(\\d+[\\.\\d]*))?",
        "name": "ThreadX",
        "version": "$1"
    },
    {
        "regex": "Nucleus(?:(?: |/v?)(\\d+[\\.\\d]*))?",
        "name": "MTK / Nucleus",
        "version": "$1"
    },
    {
        "regex": "MTK(?:(?: |/v?)(\\d+[\\.\\d]*))?",
        "name": "MTK / Nucleus",
        "version": "$1"
    },
    {
        "regex": "MRE/(\\d+)\\.(\\d+).*;MAUI",
        "name": "MRE",
        "version": "$1.$2"
    },
    {
        "regex": "dvkbuntu",
        "name": "DVKBuntu",
        "version": ""
    },
    {
        "regex": "Helio/(\\d+[\\.\\d]+)",
        "name": "Lumin OS",
        "version": "$1"
    },
    {
        "regex": "HasCodingOs (\\d+[\\.\\d]+)",
        "name": "HasCodingOS",
        "version": "$1"
    },
    {
        "regex": "PCLinuxOS/(\\d+[\\.\\d]+)",
        "name": "PCLinuxOS",
        "version": "$1"
    },
    {
        "regex": "(Ordissimo|webissimo3)",
        "name": "Ordissimo",
        "version": ""
    },
    {
        "regex": "(?:Win|Sistema )Fenix",
        "name": "Fenix",
        "version": ""
    },
    {
        "regex": "TOS; Linux",
        "name": "TmaxOS",
        "version": ""
    },
    {
        "regex": "Maemo",
        "name": "Maemo",
        "version": ""
    },
    {
        "regex": "Arch ?Linux(?:[ /\\-](\\d+[\\.\\d]+))?",
        "name": "Arch Linux",
        "version": "$1"
    },
    {
        "regex": "VectorLinux(?: package)?(?:[ /\\-](\\d+[\\.\\d]+))?",
        "name": "VectorLinux",
        "version": "$1"
    },
    {
        "regex": "Linux; .*((?:Debian|Knoppix|Mint|Ubuntu|Kubuntu|Xubuntu|Lubuntu|Fedora|Red Hat|Mandriva|Gentoo|Sabayon|Slackware|SUSE|CentOS|BackTrack))[ /](\\d+[\\.\\d]+)",
        "name": "$1",
        "version": "$2"
    },
    {
        "regex": "Deepin[ /](\\d+[\\.\\d]+)",
        "name": "Deepin",
        "version": "$1"
    },
    {
        "regex": "(Debian|Knoppix|Mint(?! Browser)|Ubuntu|Kubuntu|Xubuntu|Lubuntu|Fedora|Red Hat|Mandriva|Gentoo|Sabayon|Slackware|SUSE|CentOS|BackTrack|Freebox)(?:(?: Enterprise)? Linux)?(?:[ /\\-](\\d+[\\.\\d]+))?",
        "name": "$1",
        "version": "$2"
    },
    {
        "regex": "OS ROSA; Linux",
        "name": "Rosa",
        "version": ""
    },
    {
        "regex": "WEBOS(\\d+[\\.\\d]+)",
        "name": "webOS",
        "version": "$1"
    },
    {
        "regex": "Web0S; Linux/SmartTV.+Chrome/68",
        "name": "webOS",
        "version": "5"
    },
    {
        "regex": "Web0S; Linux/SmartTV.+Chrome/53",
        "name": "webOS",
        "version": "4"
    },
    {
        "regex": "Web0S; Linux/SmartTV.+Chrome/38",
        "name": "webOS",
        "version": "3"
    },
    {
        "regex": "Web0S; Linux/SmartTV.+Safari/538",
        "name": "webOS",
        "version": "2"
    },
    {
        "regex": "Web0S; Linux/SmartTV.+Safari/537",
        "name": "webOS",
        "version": "1"
    },
    {
        "regex": "(?:Web0S; .*WEBOS|webOS|web0S|Palm webOS|hpwOS)(?:[/]?(\\d+[\\.\\d]+))?",
        "name": "webOS",
        "version": "$1"
    },
    {
        "regex": "(?:PalmOS|Palm OS)(?:[/ ](\\d+[\\.\\d]+))?|Palm",
        "name": "palmOS",
        "version": "$1"
    },
    {
        "regex": "Xiino(?:.*v\\. (\\d+[\\.\\d]+))?",
        "name": "palmOS",
        "version": "$1"
    },
    {
        "regex": "MorphOS(?:[ /](\\d+[\\.\\d]+))?",
        "name": "MorphOS",
        "version": "$1"
    },
    {
        "regex": "FBW.+FBSV/(\\d+[\\.\\d]*);",
        "name": "Windows",
        "version": "$1"
    },
    {
        "regex": "mingw32",
        "name": "Windows",
        "version": ""
    },
    {
        "regex": "Windows/(\\d+\\.\\d+)",
        "name": "Windows",
        "version": "$1"
    },
    {
        "regex": "CYGWIN_NT-10.0|Windows NT 10.0|Windows 10",
        "name": "Windows",
        "version": "10"
    },
    {
        "regex": "CYGWIN_NT-6.4|Windows NT 6.4|Windows 10|win10",
        "name": "Windows",
        "version": "10"
    },
    {
        "regex": "CYGWIN_NT-6.3|Windows NT 6.3|Windows 8.1",
        "name": "Windows",
        "version": "8.1"
    },
    {
        "regex": "CYGWIN_NT-6.2|Windows NT 6.2|Windows 8",
        "name": "Windows",
        "version": "8"
    },
    {
        "regex": "CYGWIN_NT-6.1|Windows NT 6.1|Windows 7|win7",
        "name": "Windows",
        "version": "7"
    },
    {
        "regex": "CYGWIN_NT-6.0|Windows NT 6.0|Windows Vista",
        "name": "Windows",
        "version": "Vista"
    },
    {
        "regex": "CYGWIN_NT-5.2|Windows NT 5.2|Windows Server 2003 / XP x64",
        "name": "Windows",
        "version": "Server 2003"
    },
    {
        "regex": "CYGWIN_NT-5.1|Windows NT 5.1|Windows XP",
        "name": "Windows",
        "version": "XP"
    },
    {
        "regex": "CYGWIN_NT-5.0|Windows NT 5.0|Windows 2000",
        "name": "Windows",
        "version": "2000"
    },
    {
        "regex": "CYGWIN_NT-4.0|Windows NT 4.0|WinNT|Windows NT",
        "name": "Windows",
        "version": "NT"
    },
    {
        "regex": "CYGWIN_ME-4.90|Win 9x 4.90|Windows ME",
        "name": "Windows",
        "version": "ME"
    },
    {
        "regex": "CYGWIN_98-4.10|Win98|Windows 98",
        "name": "Windows",
        "version": "98"
    },
    {
        "regex": "CYGWIN_95-4.0|Win32|Win95|Windows 95|Windows_95",
        "name": "Windows",
        "version": "95"
    },
    {
        "regex": "Windows 3.1",
        "name": "Windows",
        "version": "3.1"
    },
    {
        "regex": "Windows",
        "name": "Windows",
        "version": ""
    },
    {
        "regex": "Haiku",
        "name": "Haiku OS",
        "version": ""
    },
    {
        "regex": "(?:Watch1,[12]/|Watch OS,|watchOS[ /])(\\d+[\\.\\d]*)",
        "name": "watchOS",
        "version": "$1"
    },
    {
        "regex": "iPad/([89]|1[012]).(\\d+[\\.\\d]*)",
        "name": "iOS",
        "version": "$1.$2"
    },
    {
        "regex": "Pinterest for iOS/.+; (\\d(?:[\\d\\.]+)?)\\)$",
        "name": "iOS",
        "version": "$1"
    },
    {
        "regex": "CFNetwork/.+ Darwin/20.4.0(?!.*x86_64)",
        "name": "iOS",
        "version": "14.5"
    },
    {
        "regex": "CFNetwork/.+ Darwin/20.3.0(?!.*x86_64)",
        "name": "iOS",
        "version": "14.4"
    },
    {
        "regex": "CFNetwork/.+ Darwin/20.2.0(?!.*x86_64)",
        "name": "iOS",
        "version": "14.3"
    },
    {
        "regex": "CFNetwork/.+ Darwin/20.1.0(?!.*x86_64)",
        "name": "iOS",
        "version": "14.2"
    },
    {
        "regex": "CFNetwork/.+ Darwin/20.0.0(?!.*x86_64)",
        "name": "iOS",
        "version": "14.0"
    },
    {
        "regex": "CFNetwork/.+ Darwin/19.6.0(?!.*x86_64)",
        "name": "iOS",
        "version": "13.6"
    },
    {
        "regex": "CFNetwork/.+ Darwin/19.5.0(?!.*x86_64)",
        "name": "iOS",
        "version": "13.5"
    },
    {
        "regex": "CFNetwork/.+ Darwin/19.4.0(?!.*x86_64)",
        "name": "iOS",
        "version": "13.4"
    },
    {
        "regex": "CFNetwork/.+ Darwin/19.3.0(?!.*x86_64)",
        "name": "iOS",
        "version": "13.3.1"
    },
    {
        "regex": "CFNetwork/.+ Darwin/19.2.0(?!.*x86_64)",
        "name": "iOS",
        "version": "13.3"
    },
    {
        "regex": "CFNetwork/.+ Darwin/19.0.0(?!.*x86_64)",
        "name": "iOS",
        "version": "13.0"
    },
    {
        "regex": "CFNetwork/.+ Darwin/18.7.0(?!.*x86_64)",
        "name": "iOS",
        "version": "12.4"
    },
    {
        "regex": "CFNetwork/.+ Darwin/18.6.0(?!.*x86_64)",
        "name": "iOS",
        "version": "12.3"
    },
    {
        "regex": "CFNetwork/.+ Darwin/18.5.0(?!.*x86_64)",
        "name": "iOS",
        "version": "12.2"
    },
    {
        "regex": "CFNetwork/.+ Darwin/18.2.0(?!.*x86_64)",
        "name": "iOS",
        "version": "12.1"
    },
    {
        "regex": "CFNetwork/.+ Darwin/18.0.0(?!.*x86_64)",
        "name": "iOS",
        "version": "12.0"
    },
    {
        "regex": "CFNetwork/.+ Darwin/17.7.0(?!.*x86_64)",
        "name": "iOS",
        "version": "11.4.1"
    },
    {
        "regex": "CFNetwork/.+ Darwin/17.6.0(?!.*x86_64)",
        "name": "iOS",
        "version": "11.4"
    },
    {
        "regex": "CFNetwork/889(?!.*x86_64)",
        "name": "iOS",
        "version": "11.1"
    },
    {
        "regex": "CFNetwork/887(?!.*x86_64)",
        "name": "iOS",
        "version": "11.0"
    },
    {
        "regex": "CFNetwork/811(?!.*x86_64)",
        "name": "iOS",
        "version": "10.3"
    },
    {
        "regex": "CFNetwork/808\\.3",
        "name": "iOS",
        "version": "10.3"
    },
    {
        "regex": "CFNetwork/808\\.2",
        "name": "iOS",
        "version": "10.2"
    },
    {
        "regex": "CFNetwork/808\\.1",
        "name": "iOS",
        "version": "10.1"
    },
    {
        "regex": "CFNetwork/808\\.0",
        "name": "iOS",
        "version": "10.0"
    },
    {
        "regex": "CFNetwork/808",
        "name": "iOS",
        "version": "10"
    },
    {
        "regex": "CFNetwork/758\\.4\\.3",
        "name": "iOS",
        "version": "9.3.2"
    },
    {
        "regex": "CFNetwork/758\\.3\\.15",
        "name": "iOS",
        "version": "9.3"
    },
    {
        "regex": "CFNetwork/758\\.2\\.[78]",
        "name": "iOS",
        "version": "9.2"
    },
    {
        "regex": "CFNetwork/758\\.1\\.6",
        "name": "iOS",
        "version": "9.1"
    },
    {
        "regex": "CFNetwork/758\\.0\\.2",
        "name": "iOS",
        "version": "9.0"
    },
    {
        "regex": "CFNetwork/711\\.5\\.6",
        "name": "iOS",
        "version": "8.4.1"
    },
    {
        "regex": "CFNetwork/711\\.4\\.6",
        "name": "iOS",
        "version": "8.4"
    },
    {
        "regex": "CFNetwork/711\\.3\\.18",
        "name": "iOS",
        "version": "8.3"
    },
    {
        "regex": "CFNetwork/711\\.2\\.23",
        "name": "iOS",
        "version": "8.2"
    },
    {
        "regex": "CFNetwork/711\\.1\\.1[26]",
        "name": "iOS",
        "version": "8.1"
    },
    {
        "regex": "CFNetwork/711\\.0\\.6",
        "name": "iOS",
        "version": "8.0"
    },
    {
        "regex": "CFNetwork/672\\.1",
        "name": "iOS",
        "version": "7.1"
    },
    {
        "regex": "CFNetwork/672\\.0",
        "name": "iOS",
        "version": "7.0"
    },
    {
        "regex": "CFNetwork/609\\.1",
        "name": "iOS",
        "version": "6.1"
    },
    {
        "regex": "CFNetwork/60[29]",
        "name": "iOS",
        "version": "6.0"
    },
    {
        "regex": "CFNetwork/548\\.1",
        "name": "iOS",
        "version": "5.1"
    },
    {
        "regex": "CFNetwork/548\\.0",
        "name": "iOS",
        "version": "5.0"
    },
    {
        "regex": "CFNetwork/485\\.13",
        "name": "iOS",
        "version": "4.3"
    },
    {
        "regex": "CFNetwork/485\\.12",
        "name": "iOS",
        "version": "4.2"
    },
    {
        "regex": "CFNetwork/485\\.10",
        "name": "iOS",
        "version": "4.1"
    },
    {
        "regex": "CFNetwork/485\\.2",
        "name": "iOS",
        "version": "4.0"
    },
    {
        "regex": "CFNetwork/467\\.12",
        "name": "iOS",
        "version": "3.2"
    },
    {
        "regex": "CFNetwork/459",
        "name": "iOS",
        "version": "3.1"
    },
    {
        "regex": "iPhone/(\\d+[\\.\\d]*) hw/",
        "name": "iOS",
        "version": "$1"
    },
    {
        "regex": "iOS(\\d+\\.\\d+\\.\\d+)",
        "name": "iOS",
        "version": "$1"
    },
    {
        "regex": "iOS(\\d+)\\.(\\d+)0",
        "name": "iOS",
        "version": "$1.$2"
    },
    {
        "regex": "iPhone OS ([0-9]{1})([0-9]{1})([0-9]{1})",
        "name": "iOS",
        "version": "$1.$2.$3"
    },
    {
        "regex": "(?:CPU OS|iPh(?:one)?[ _]OS|iOS)[ _/](\\d+(?:[_\\.]\\d+)*)",
        "name": "iOS",
        "version": "$1"
    },
    {
        "regex": "(?:FBIOS|Messenger(?:Lite)?ForiOS).*FBSV/ ?(\\d+[\\.\\d]*);",
        "name": "iOS",
        "version": "$1"
    },
    {
        "regex": "(?:Apple-)?(?:iPhone|iPad|iPod)(?:.*Mac OS X.*Version/(\\d+\\.\\d+)|; Opera)?",
        "name": "iOS",
        "version": "$1"
    },
    {
        "regex": "(?:Podcasts/(?:[\\d\\.]+)|Instacast(?:HD)?/(?:\\d\\.[\\d\\.abc]+)|Pocket Casts, iOS|\\(iOS\\)|iOS; Opera|Overcast|Castro|Podcat|iCatcher|RSSRadio/|MobileSafari/)(?!.*x86_64)",
        "name": "iOS",
        "version": ""
    },
    {
        "regex": "iTunes-(iPod|iPad|iPhone)/(?:[\\d\\.]+)",
        "name": "iOS",
        "version": ""
    },
    {
        "regex": "iOS/Version ([\\d\\.]+)",
        "name": "iOS",
        "version": "$1"
    },
    {
        "regex": "Sonos/.+\\(ICRU_",
        "name": "iOS",
        "version": ""
    },
    {
        "regex": "CFNetwork/.+ Darwin/20.2.0.*(x86_64)|(x86_64-apple-)?darwin20.2.0",
        "name": "Mac",
        "version": "11.1"
    },
    {
        "regex": "CFNetwork/.+ Darwin/20.[01].0.*(x86_64)|(x86_64-apple-)?darwin20.[01].0",
        "name": "Mac",
        "version": "11.0"
    },
    {
        "regex": "CFNetwork/.+ Darwin/19.6.0.*(x86_64)|(x86_64-apple-)?darwin19.6.0",
        "name": "Mac",
        "version": "10.15.6"
    },
    {
        "regex": "CFNetwork/.+ Darwin/19.5.0.*(x86_64)|(x86_64-apple-)?darwin19.5.0",
        "name": "Mac",
        "version": "10.15.5"
    },
    {
        "regex": "CFNetwork/.+ Darwin/19.4.0.*(x86_64)|(x86_64-apple-)?darwin19.4.0",
        "name": "Mac",
        "version": "10.15.4"
    },
    {
        "regex": "CFNetwork/.+ Darwin/19.3.0.*(x86_64)|(x86_64-apple-)?darwin19.3.0",
        "name": "Mac",
        "version": "10.15.3"
    },
    {
        "regex": "CFNetwork/.+ Darwin/19.2.0.*(x86_64)|(x86_64-apple-)?darwin19.2.0",
        "name": "Mac",
        "version": "10.15.2"
    },
    {
        "regex": "CFNetwork/(?:108[258]|109[18]|1103).*(x86_64)",
        "name": "Mac",
        "version": "10.15"
    },
    {
        "regex": "CFNetwork/.+ Darwin/18.2.0.*(x86_64)|(x86_64-apple-)?darwin18.2.0",
        "name": "Mac",
        "version": "10.14.1"
    },
    {
        "regex": "CFNetwork/(?:96[29]|97[14568]).*(x86_64)",
        "name": "Mac",
        "version": "10.14"
    },
    {
        "regex": "CFNetwork/.+ Darwin/17.7.0.*(x86_64)|(x86_64-apple-)?darwin17.7.0",
        "name": "Mac",
        "version": "10.13.6"
    },
    {
        "regex": "CFNetwork/.+ Darwin/17.6.0.*(x86_64)|(x86_64-apple-)?darwin17.6.0",
        "name": "Mac",
        "version": "10.13.5"
    },
    {
        "regex": "CFNetwork/.+ Darwin/17.5.0.*(x86_64)|(x86_64-apple-)?darwin17.5.0",
        "name": "Mac",
        "version": "10.13.4"
    },
    {
        "regex": "CFNetwork/.+ Darwin/17.4.0.*(x86_64)|(x86_64-apple-)?darwin17.4.0",
        "name": "Mac",
        "version": "10.13.3"
    },
    {
        "regex": "CFNetwork/.+ Darwin/17.3.0.*(x86_64)|(x86_64-apple-)?darwin17.3.0",
        "name": "Mac",
        "version": "10.13.2"
    },
    {
        "regex": "CFNetwork/.+ Darwin/17.2.0.*(x86_64)|(x86_64-apple-)?darwin17.2.0",
        "name": "Mac",
        "version": "10.13.1"
    },
    {
        "regex": "CFNetwork/(?:887|889|893|897|901|902).*(x86_64)",
        "name": "Mac",
        "version": "10.13"
    },
    {
        "regex": "CFNetwork/.+ Darwin/16.7.0.*(x86_64)|(x86_64-apple-)?darwin16.7.0",
        "name": "Mac",
        "version": "10.12.6"
    },
    {
        "regex": "CFNetwork/.+ Darwin/16.6.0.*(x86_64)|(x86_64-apple-)?darwin16.6.0",
        "name": "Mac",
        "version": "10.12.5"
    },
    {
        "regex": "CFNetwork/.+ Darwin/16.5.0.*(x86_64)|(x86_64-apple-)?darwin16.5.0",
        "name": "Mac",
        "version": "10.12.4"
    },
    {
        "regex": "CFNetwork/.+ Darwin/16.3.0.*(x86_64)|(x86_64-apple-)?darwin16.3.0",
        "name": "Mac",
        "version": "10.12.3"
    },
    {
        "regex": "CFNetwork/.+ Darwin/16.2.0.*(x86_64)|(x86_64-apple-)?darwin16.2.0",
        "name": "Mac",
        "version": "10.12.2"
    },
    {
        "regex": "CFNetwork/.+ Darwin/16.1.0.*(x86_64)|(x86_64-apple-)?darwin16.1.0",
        "name": "Mac",
        "version": "10.12.1"
    },
    {
        "regex": "CFNetwork/811.*(x86_64)",
        "name": "Mac",
        "version": "10.12"
    },
    {
        "regex": "CFNetwork/807",
        "name": "Mac",
        "version": "10.12"
    },
    {
        "regex": "CFNetwork/.+ Darwin/15.6.0.*(x86_64)|(x86_64-apple-)?darwin15.6.0",
        "name": "Mac",
        "version": "10.11.6"
    },
    {
        "regex": "CFNetwork/.+ Darwin/15.5.0.*(x86_64)|(x86_64-apple-)?darwin15.5.0",
        "name": "Mac",
        "version": "10.11.5"
    },
    {
        "regex": "CFNetwork/.+ Darwin/15.4.0.*(x86_64)|(x86_64-apple-)?darwin15.4.0",
        "name": "Mac",
        "version": "10.11.4"
    },
    {
        "regex": "CFNetwork/.+ Darwin/15.3.0.*(x86_64)|(x86_64-apple-)?darwin15.3.0",
        "name": "Mac",
        "version": "10.11.3"
    },
    {
        "regex": "CFNetwork/.+ Darwin/15.2.0.*(x86_64)|(x86_64-apple-)?darwin15.2.0",
        "name": "Mac",
        "version": "10.11.2"
    },
    {
        "regex": "CFNetwork/760",
        "name": "Mac",
        "version": "10.11"
    },
    {
        "regex": "CFNetwork/.+ Darwin/14.5.0.*(x86_64)|(x86_64-apple-)?darwin14.5.0",
        "name": "Mac",
        "version": "10.10.5"
    },
    {
        "regex": "CFNetwork/.+ Darwin/14.4.0.*(x86_64)|(x86_64-apple-)?darwin14.4.0",
        "name": "Mac",
        "version": "10.10.4"
    },
    {
        "regex": "CFNetwork/.+ Darwin/14.3.0.*(x86_64)|(x86_64-apple-)?darwin14.3.0",
        "name": "Mac",
        "version": "10.10.3"
    },
    {
        "regex": "CFNetwork/.+ Darwin/14.1.0.*(x86_64)|(x86_64-apple-)?darwin14.1.0",
        "name": "Mac",
        "version": "10.10.2"
    },
    {
        "regex": "CFNetwork/720",
        "name": "Mac",
        "version": "10.10"
    },
    {
        "regex": "CFNetwork/.+ Darwin/13.4.0.*(x86_64)|(x86_64-apple-)?darwin13.4.0",
        "name": "Mac",
        "version": "10.9.5"
    },
    {
        "regex": "CFNetwork/.+ Darwin/13.3.0.*(x86_64)|(x86_64-apple-)?darwin13.3.0",
        "name": "Mac",
        "version": "10.9.4"
    },
    {
        "regex": "CFNetwork/.+ Darwin/13.2.0.*(x86_64)|(x86_64-apple-)?darwin13.2.0",
        "name": "Mac",
        "version": "10.9.3"
    },
    {
        "regex": "CFNetwork/.+ Darwin/13.1.0.*(x86_64)|(x86_64-apple-)?darwin13.1.0",
        "name": "Mac",
        "version": "10.9.2"
    },
    {
        "regex": "CFNetwork/673",
        "name": "Mac",
        "version": "10.9"
    },
    {
        "regex": "CFNetwork/.+ Darwin/12.5.0.*(x86_64)|(x86_64-apple-)?darwin12.5.0",
        "name": "Mac",
        "version": "10.8.5"
    },
    {
        "regex": "CFNetwork/.+ Darwin/12.4.0.*(x86_64)|(x86_64-apple-)?darwin12.4.0",
        "name": "Mac",
        "version": "10.8.4"
    },
    {
        "regex": "CFNetwork/.+ Darwin/12.3.0.*(x86_64)|(x86_64-apple-)?darwin12.3.0",
        "name": "Mac",
        "version": "10.8.3"
    },
    {
        "regex": "CFNetwork/.+ Darwin/12.2.0.*(x86_64)|(x86_64-apple-)?darwin12.2.0",
        "name": "Mac",
        "version": "10.8.2"
    },
    {
        "regex": "CFNetwork/.+ Darwin/12.1.0.*(x86_64)|(x86_64-apple-)?darwin12.1.0",
        "name": "Mac",
        "version": "10.8.1"
    },
    {
        "regex": "CFNetwork/596",
        "name": "Mac",
        "version": "10.8"
    },
    {
        "regex": "CFNetwork/.+ Darwin/11.5.0.*(x86_64)|(x86_64-apple-)?darwin11.5.0",
        "name": "Mac",
        "version": "10.7.5"
    },
    {
        "regex": "CFNetwork/.+ Darwin/11.4.0.*(x86_64)|(x86_64-apple-)?darwin11.4.0",
        "name": "Mac",
        "version": "10.7.4"
    },
    {
        "regex": "CFNetwork/.+ Darwin/11.3.0.*(x86_64)|(x86_64-apple-)?darwin11.3.0",
        "name": "Mac",
        "version": "10.7.3"
    },
    {
        "regex": "CFNetwork/.+ Darwin/11.2.0.*(x86_64)|(x86_64-apple-)?darwin11.2.0",
        "name": "Mac",
        "version": "10.7.2"
    },
    {
        "regex": "CFNetwork/.+ Darwin/11.1.0.*(x86_64)|(x86_64-apple-)?darwin11.1.0",
        "name": "Mac",
        "version": "10.7.1"
    },
    {
        "regex": "CFNetwork/520",
        "name": "Mac",
        "version": "10.7"
    },
    {
        "regex": "CFNetwork/454",
        "name": "Mac",
        "version": "10.6"
    },
    {
        "regex": "CFNetwork/(?:438|422|339|330|221|220|217)",
        "name": "Mac",
        "version": "10.5"
    },
    {
        "regex": "CFNetwork/12[89]",
        "name": "Mac",
        "version": "10.4"
    },
    {
        "regex": "CFNetwork/1\\.2",
        "name": "Mac",
        "version": "10.3"
    },
    {
        "regex": "CFNetwork/1\\.1",
        "name": "Mac",
        "version": "10.2"
    },
    {
        "regex": "Mac[ +]OS[ +]?X(?:[ /](?:Version )?(\\d+(?:[_\\.]\\d+)+))?",
        "name": "Mac",
        "version": "$1"
    },
    {
        "regex": "Mac (?:OS/)?(\\d+(?:[_\\.]\\d+)+)",
        "name": "Mac",
        "version": "$1"
    },
    {
        "regex": "macOS/(\\d+[\\.\\d]+)",
        "name": "Mac",
        "version": "$1"
    },
    {
        "regex": "Darwin|Macintosh|Mac_PowerPC|PPC|Mac PowerPC|iMac|MacBook|macOS|Sonos/.+\\(MDCR_",
        "name": "Mac",
        "version": ""
    },
    {
        "regex": "SeewoOS x86_64 (\\d+[\\.\\d]+)",
        "name": "SeewoOS",
        "version": "$1"
    },
    {
        "regex": "CrOS [a-z0-9_]+ .* Chrome/(\\d+[\\.\\d]+)",
        "name": "Chrome OS",
        "version": "$1"
    },
    {
        "regex": "(?:BB10;.+Version|Black[Bb]erry[0-9a-z]+|Black[Bb]erry.+Version)/(\\d+[\\.\\d]+)",
        "name": "BlackBerry OS",
        "version": "$1"
    },
    {
        "regex": "RIM Tablet OS (\\d+[\\.\\d]+)",
        "name": "BlackBerry Tablet OS",
        "version": "$1"
    },
    {
        "regex": "RIM Tablet OS|QNX|Play[Bb]ook",
        "name": "BlackBerry Tablet OS",
        "version": ""
    },
    {
        "regex": "BlackBerry",
        "name": "BlackBerry OS",
        "version": ""
    },
    {
        "regex": "bPod",
        "name": "BlackBerry OS",
        "version": ""
    },
    {
        "regex": "BeOS",
        "name": "BeOS",
        "version": ""
    },
    {
        "regex": "Symbian/3.+NokiaBrowser/7\\.3",
        "name": "Symbian^3",
        "version": "Anna"
    },
    {
        "regex": "Symbian/3.+NokiaBrowser/7\\.4",
        "name": "Symbian^3",
        "version": "Belle"
    },
    {
        "regex": "Symbian/3",
        "name": "Symbian^3",
        "version": ""
    },
    {
        "regex": "(?:Series ?60|SymbOS|S60)(?:[ /]?(\\d+[\\.\\d]+|V\\d+))?",
        "name": "Symbian OS Series 60",
        "version": "$1"
    },
    {
        "regex": "Series40",
        "name": "Symbian OS Series 40",
        "version": ""
    },
    {
        "regex": "SymbianOS/(\\d+[\\.\\d]+)",
        "name": "Symbian OS",
        "version": "$1"
    },
    {
        "regex": "MeeGo|WeTab",
        "name": "MeeGo",
        "version": ""
    },
    {
        "regex": "Symbian(?: OS)?|SymbOS",
        "name": "Symbian OS",
        "version": ""
    },
    {
        "regex": "Nokia",
        "name": "Symbian",
        "version": ""
    },
    {
        "regex": "(?:Mobile|Tablet);.+Firefox/\\d+\\.\\d+",
        "name": "Firefox OS",
        "version": ""
    },
    {
        "regex": "RISC OS(?:-NC)?(?:[ /](\\d+[\\.\\d]+))?",
        "name": "RISC OS",
        "version": "$1"
    },
    {
        "regex": "Inferno(?:[ /](\\d+[\\.\\d]+))?",
        "name": "Inferno",
        "version": "$1"
    },
    {
        "regex": "bada(?:[ /](\\d+[\\.\\d]+))?",
        "name": "Bada",
        "version": "$1"
    },
    {
        "regex": "(?:Brew(?!-Applet)(?: MP)?|BMP)(?:[ /](\\d+[\\.\\d]+))?",
        "name": "Brew",
        "version": "$1"
    },
    {
        "regex": "GoogleTV(?:[ /](\\d+[\\.\\d]+))?",
        "name": "Google TV",
        "version": "$1"
    },
    {
        "regex": "AppleTV(?:/?(\\d+[\\.\\d]+))?",
        "name": "Apple TV",
        "version": "$1"
    },
    {
        "regex": "WebTV/(\\d+[\\.\\d]+)",
        "name": "WebTV",
        "version": "$1"
    },
    {
        "regex": "RemixOS 5.1.1",
        "name": "Remix OS",
        "version": "1"
    },
    {
        "regex": "RemixOS 6.0",
        "name": "Remix OS",
        "version": "2"
    },
    {
        "regex": "RemixOS",
        "name": "Remix OS",
        "version": ""
    },
    {
        "regex": "(?:SunOS|Solaris)(?:[/ ](\\d+[\\.\\d]+))?",
        "name": "Solaris",
        "version": "$1"
    },
    {
        "regex": "AIX(?:[/ ]?(\\d+[\\.\\d]+))?",
        "name": "AIX",
        "version": "$1"
    },
    {
        "regex": "HP-UX(?:[/ ]?(\\d+[\\.\\d]+))?",
        "name": "HP-UX",
        "version": "$1"
    },
    {
        "regex": "FreeBSD(?:[/ ]?(\\d+[\\.\\d]+))?",
        "name": "FreeBSD",
        "version": "$1"
    },
    {
        "regex": "NetBSD(?:[/ ]?(\\d+[\\.\\d]+))?",
        "name": "NetBSD",
        "version": "$1"
    },
    {
        "regex": "OpenBSD(?:[/ ]?(\\d+[\\.\\d]+))?",
        "name": "OpenBSD",
        "version": "$1"
    },
    {
        "regex": "DragonFly(?:[/ ]?(\\d+[\\.\\d]+))?",
        "name": "DragonFly",
        "version": "$1"
    },
    {
        "regex": "Syllable(?:[/ ]?(\\d+[\\.\\d]+))?",
        "name": "Syllable",
        "version": "$1"
    },
    {
        "regex": "IRIX(?:;64)?(?:[/ ]?(\\d+[\\.\\d]+))",
        "name": "IRIX",
        "version": "$1"
    },
    {
        "regex": "OSF1(?:[/ ]?v?(\\d+[\\.\\d]+))?",
        "name": "OSF1",
        "version": "$1"
    },
    {
        "regex": "Nintendo (Wii|Switch)",
        "name": "Nintendo",
        "version": "$1"
    },
    {
        "regex": "PlayStation ?([34])",
        "name": "PlayStation",
        "version": "$1"
    },
    {
        "regex": "Xbox|KIN\\.(?:One|Two)",
        "name": "Xbox",
        "version": "360"
    },
    {
        "regex": "Nitro|Nintendo ([3]?DS[i]?)",
        "name": "Nintendo Mobile",
        "version": "$1"
    },
    {
        "regex": "PlayStation ((?:Portable|Vita))",
        "name": "PlayStation Portable",
        "version": "$1"
    },
    {
        "regex": "OS/2",
        "name": "OS/2",
        "version": ""
    },
    {
        "regex": "Linux(?:OS)?[^a-z]",
        "name": "GNU/Linux",
        "version": ""
    }
]
