import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createCustomHeader } from '../util/http.util';

@Injectable({
  providedIn: 'root',
})
export class DynamicDialogService {
  constructor(private http: HttpClient) {}

  get(dialog_name: string): Observable<{ [key: string]: any }> {
    return this.http.get<{
      [key: string]: any;
    }>(
      environment.be_url + '/dynamic_dialogs/',
      createCustomHeader({
        target_resource: dialog_name,
      })
    );
  }
}
