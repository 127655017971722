<fut-base-dialog-icon dialogIcon="/assets/emoji/present.png" dialogSubtitle="dialogs.referral.subtitle" dialogTitle="dialogs.referral.title">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <div class="w-100 bg-quaternary rounded p-4 d-flex flex-column mb-4" style="gap: 1.5em">
      @for (copy of COPY_LIST; track copy) {
        <div class="d-flex align-items-start">
          <mat-icon fontSet="material-symbols-outlined" class="mr-1">check</mat-icon>
          <span [futEditable]="'dialogs.referral.list.element-' + copy" [innerHTML]="'dialogs.referral.list.element-' + copy | transloco"></span>
        </div>
      }
      <div class="text-center">
        <button (click)="goToLanding()" class="fut-btn fut-btn-link">{{ 'dialogs.referral.moreinfo' | transloco }}</button>
      </div>
    </div>

    <div class="w-100 text-center">
      <h3 class="fut-bold fut-h3">Il tuo codice amico</h3>
    </div>
    <div (click)="copy(referral_code)" class="code-container pointer">
      <span class="code">{{ referral_code }}</span>
      <mat-icon fontSet="material-symbols-outlined">content_copy</mat-icon>
    </div>
  </div>

  <div class="d-flex align-items-center flex-column flex-md-row" style="gap: 1em" actions>
    <!-- <button class="fut-btn fut-btn-primary" (click)="copy(referral_code)">{{'dialogs.referral.copy_code' | transloco}}</button> -->
    <button (click)="shareText()" class="fut-btn fut-btn-link fut-btn-icon fut-btn-lg w-100">
      <mat-icon fontSet="material-symbols-outlined">ios_share</mat-icon>
      <span>Invia link invito</span>
    </button>
    <button (click)="shareOnWhatsapp()" class="fut-btn fut-btn-whatsapp fut-btn-icon fut-btn-lg w-100">
      <img alt="whatsapp logo" src="/assets/emoji/whatsapp_white.svg" />
      <span>Invia su WhatsApp</span>
    </button>
  </div>
</fut-base-dialog-icon>
