[
    {
        "regex": "AndroidDownloadManager(?:[ /]([\\d\\.]+))?",
        "name": "AndroidDownloadManager",
        "version": "$1"
    },
    {
        "regex": "(?:Apple)?News(?:[ /][\\d\\.]+)? Version(?:[ /]([\\d\\.]+))?",
        "name": "Apple News",
        "version": "$1"
    },
    {
        "regex": "bPod",
        "name": "bPod",
        "version": ""
    },
    {
        "regex": "MessengerLiteForiOS.(?:FBAV)(?:[ /]([\\d\\.]+))?",
        "name": "Facebook Messenger Lite",
        "version": "$1"
    },
    {
        "regex": "(?:MessengerForiOS|MESSENGER).(?:FBAV)(?:[ /]([\\d\\.]+))?",
        "name": "Facebook Messenger",
        "version": "$1"
    },
    {
        "regex": "(?:FBAV|com.facebook.katana)(?:[ /]([\\d\\.]+))?",
        "name": "Facebook",
        "version": "$1"
    },
    {
        "regex": "(?:FBAN|FBSV|FBID|FBBV)/",
        "name": "Facebook",
        "version": ""
    },
    {
        "regex": "FeedR(?:/([\\d\\.]+))?",
        "name": "FeedR",
        "version": "$1"
    },
    {
        "regex": "com.google.android.apps.searchlite",
        "name": "Google Go",
        "version": ""
    },
    {
        "regex": "com.google.android.apps.magazines",
        "name": "Google Play Newsstand",
        "version": ""
    },
    {
        "regex": "com.google.GooglePlus",
        "name": "Google Plus",
        "version": ""
    },
    {
        "regex": "MicroMessenger/([^; ]+)",
        "name": "WeChat",
        "version": "$1"
    },
    {
        "regex": "WeChatShareExtensionNew/([\\d\\.]+)",
        "name": "WeChat Share Extension",
        "version": "$1"
    },
    {
        "regex": "DingTalk/([0-9\\.]+)",
        "name": "DingTalk",
        "version": "$1"
    },
    {
        "regex": ".*__weibo__([0-9\\.]+)__",
        "name": "Sina Weibo",
        "version": "$1"
    },
    {
        "regex": "Pinterest(?: for (?:Android|iOS))?(?:/([\\d\\.]+))?",
        "name": "Pinterest",
        "version": "$1"
    },
    {
        "regex": "Podcatcher Deluxe",
        "name": "Podcatcher Deluxe",
        "version": ""
    },
    {
        "regex": "com.google.android.youtube(?:/([\\d\\.]+))?",
        "name": "YouTube",
        "version": "$1"
    },
    {
        "regex": "([^/]+)/(\\d+(?:\\.\\d+)+) \\((?:iPhone|iPad); iOS [0-9\\.]+; Scale/[0-9\\.]+\\)",
        "name": "$1",
        "version": "$2"
    },
    {
        "regex": "WhatsApp(?:[ /]([\\d\\.]+))?",
        "name": "WhatsApp",
        "version": "$1"
    },
    {
        "regex": "Line(?:[ /]([\\d\\.]+))",
        "name": "Line",
        "version": "$1"
    },
    {
        "regex": "Instacast(?:HD)?/(\\d\\.[\\d\\.abc]+) CFNetwork/([\\d\\.]+) Darwin/([\\d\\.]+)",
        "name": "Instacast",
        "version": "$1"
    },
    {
        "regex": "Podcasts/([\\d\\.]+)",
        "name": "Podcasts",
        "version": "$1"
    },
    {
        "regex": "Pocket Casts(?:, (?:Android|iOS) v([\\d\\.]+))?",
        "name": "Pocket Casts",
        "version": "$1"
    },
    {
        "regex": "Podcat/([\\d\\.]+)",
        "name": "Podcat",
        "version": "$1"
    },
    {
        "regex": "BeyondPod",
        "name": "BeyondPod",
        "version": null
    },
    {
        "regex": "AntennaPod/?([\\d\\.]+)?",
        "name": "AntennaPod",
        "version": "$1"
    },
    {
        "regex": "Overcast/([\\d\\.]+)",
        "name": "Overcast",
        "version": "$1"
    },
    {
        "regex": "(?:CastBox|fm.castbox.audiobook.radio.podcast)/?([\\d\\.]+)?",
        "name": "CastBox",
        "version": "$1"
    },
    {
        "regex": "Player FM",
        "name": "Player FM",
        "version": ""
    },
    {
        "regex": "Podkicker(?: Pro)?/([\\d\\.]+)",
        "name": "Podkicker",
        "version": "$1"
    },
    {
        "regex": "PodcastRepublic/([\\d\\.]+)",
        "name": "Podcast Republic",
        "version": "$1"
    },
    {
        "regex": "Castro/(\\d+)",
        "name": "Castro",
        "version": "$1"
    },
    {
        "regex": "Castro 2 ([\\d\\.]+)/[\\d]+ Like iTunes",
        "name": "Castro 2",
        "version": "$1"
    },
    {
        "regex": "Castro 2",
        "name": "Castro 2",
        "version": ""
    },
    {
        "regex": "DoggCatcher",
        "name": "DoggCatcher",
        "version": null
    },
    {
        "regex": "PodcastAddict/v([\\d]+)",
        "name": "Podcast & Radio Addict",
        "version": "$1"
    },
    {
        "regex": "Podcat(?:%202)?/([\\d]+) CFNetwork",
        "name": "Podcat",
        "version": "$1"
    },
    {
        "regex": "iCatcher[^\\d]+([\\d\\.]+)",
        "name": "iCatcher",
        "version": "$1"
    },
    {
        "regex": "YelpApp/([\\d\\.]+)",
        "name": "Yelp Mobile",
        "version": "$1"
    },
    {
        "regex": "jp.co.yahoo.android.yjtop/([\\d\\.]+)",
        "name": "Yahoo! Japan",
        "version": "$1"
    },
    {
        "regex": "RSSRadio/([\\d]+)?",
        "name": "RSSRadio",
        "version": "$1"
    },
    {
        "regex": "SogouSearch Android[\\d\\.]+ version([\\d\\.]+)?",
        "name": "SogouSearch App",
        "version": "$1"
    },
    {
        "regex": "NewsArticle/([\\d\\.]+)?",
        "name": "NewsArticle App",
        "version": "$1"
    },
    {
        "regex": "tieba/([\\d\\.]+)?",
        "name": "tieba",
        "version": "$1"
    },
    {
        "regex": "com\\.douban\\.group/([\\d\\.]+)?",
        "name": "douban App",
        "version": "$1"
    },
    {
        "regex": "BingWeb/([\\d\\.]+)?",
        "name": "BingWebApp",
        "version": "$1"
    },
    {
        "regex": "(?:com.google.GoogleMobile|GSA)/([\\d\\.]+)?",
        "name": "Google Search App",
        "version": "$1"
    },
    {
        "regex": "Flipboard/([\\d\\.]+)?",
        "name": "Flipboard App",
        "version": "$1"
    },
    {
        "regex": "Instagram[ /]([\\d\\.]+)?",
        "name": "Instagram App",
        "version": "$1"
    },
    {
        "regex": "baiduboxapp/([\\d\\.]+)?",
        "name": "Baidu Box App",
        "version": "$1"
    },
    {
        "regex": "Crosswalk(?!.*(?:Streamy|QwantMobile))/([\\d\\.]+)?",
        "name": "CrosswalkApp",
        "version": "$1"
    },
    {
        "regex": "Twitter for iPhone[/]?([\\d\\.]+)?",
        "name": "Twitter",
        "version": "$1"
    },
    {
        "regex": "Twitter/([\\d\\.]+)",
        "name": "Twitter",
        "version": "$1"
    },
    {
        "regex": "TwitterAndroid[/]?([\\d\\.]+)?",
        "name": "Twitter",
        "version": "$1"
    },
    {
        "regex": "TopBuzz/([\\d\\.]+)",
        "name": "TopBuzz",
        "version": "$1"
    },
    {
        "regex": "Snapchat/([\\d\\.]+)",
        "name": "Snapchat",
        "version": "$1"
    },
    {
        "regex": "UnityPlayer/([\\d\\.]+)",
        "name": "UnityPlayer",
        "version": "$1"
    },
    {
        "regex": "UCURSOS/v([\\d\\.]+)",
        "name": "U-Cursos",
        "version": "$1"
    },
    {
        "regex": "HeyTapBrowser/([\\d\\.]+)",
        "name": "HeyTapBrowser",
        "version": "$1"
    },
    {
        "regex": "RobloxApp/([\\d\\.]+)",
        "name": "Roblox",
        "version": "$1"
    },
    {
        "regex": "Viber/([\\d\\.]+)",
        "name": "Viber",
        "version": "$1"
    },
    {
        "regex": "Siri/1",
        "name": "Siri",
        "version": "1.0"
    },
    {
        "regex": "LinkedIn/([\\d\\.]+)",
        "name": "LinkedIn",
        "version": "$1"
    },
    {
        "regex": "Instapaper/([\\d\\.]+)",
        "name": "Instapaper",
        "version": "$1"
    },
    {
        "regex": "Keeper/([\\d\\.]+)",
        "name": "Keeper Password Manager",
        "version": "$1"
    },
    {
        "regex": "Skyeng Teachers/([\\d\\.]+)",
        "name": "Skyeng Teachers",
        "version": "$1"
    },
    {
        "regex": "Kik/([\\d\\.]+) \\(Android",
        "name": "Kik",
        "version": "$1"
    },
    {
        "regex": "Procast/?([\\d\\.]+)?",
        "name": "Procast",
        "version": "$1"
    },
    {
        "regex": "DeviantArt/([\\d\\.]+)",
        "name": "DeviantArt",
        "version": ""
    },
    {
        "regex": "Discord/([\\d\\.]+)",
        "name": "Discord",
        "version": ""
    },
    {
        "regex": "Covenant%20Eyes/([\\d\\.]+)",
        "name": "Covenant Eyes",
        "version": "$1"
    },
    {
        "regex": "HP%20Smart/([\\d\\.]+)",
        "name": "HP Smart",
        "version": ""
    },
    {
        "regex": "Bitsboard/([\\d\\.]+)",
        "name": "Bitsboard",
        "version": "$1"
    },
    {
        "regex": "Betbull/([\\d\\.]+)",
        "name": "BetBull",
        "version": ""
    },
    {
        "regex": "U-Cursos/([\\d\\.]+)",
        "name": "U-Cursos",
        "version": ""
    },
    {
        "regex": "1PasswordThumbs/([\\d\\.]+)",
        "name": "1Password",
        "version": "$1"
    },
    {
        "regex": "(?:Microsoft Office )?(Access|Excel|OneDrive for Business|OneNote|PowerPoint|Project|Publisher|Visio|Word)[ /]([\\d\\.]+)",
        "name": "Microsoft Office $1",
        "version": "$2"
    },
    {
        "regex": "OneDriveiOSApp/([\\d\\.]+)",
        "name": "Microsoft OneDrive",
        "version": "$1"
    },
    {
        "regex": "Microsoft Office Mobile/([\\d\\.]+)",
        "name": "Microsoft Office Mobile",
        "version": "$1"
    },
    {
        "regex": "OC/([\\d\\.]+) \\(Skype for Business\\)",
        "name": "Skype for Business",
        "version": "$1"
    },
    {
        "regex": "TikTok[/ ](\\d+\\.(?:[\\.\\d]+))?",
        "name": "TikTok",
        "version": "$1"
    },
    {
        "regex": "NAVER/(\\d+[\\.\\d]+) CFNetwork",
        "name": "Naver",
        "version": "$1"
    },
    {
        "regex": "Copied/(\\d+[\\.\\d]+) CFNetwork",
        "name": "Copied",
        "version": ""
    },
    {
        "regex": "Pic%20Collage/(\\d+[\\.\\d]+) CFNetwork",
        "name": "Pic Collage",
        "version": "$1"
    },
    {
        "regex": "Papers/(\\d+[\\.\\d]+) CFNetwork",
        "name": "Papers",
        "version": "$1"
    },
    {
        "regex": "RoboForm/(\\d+[\\.\\d]+) CFNetwork",
        "name": "RoboForm",
        "version": ""
    },
    {
        "regex": "Slack/(\\d+[\\.\\d]+) CFNetwork",
        "name": "Slack",
        "version": ""
    },
    {
        "regex": "KAKAOTALK (\\d+\\.(?:[\\.\\d]+))?",
        "name": "KakaoTalk",
        "version": "$1"
    },
    {
        "regex": "ShopeeVN/([\\d\\.]+)",
        "name": "Shopee",
        "version": "$1"
    },
    {
        "regex": "SPORT1/([\\d\\.]+)",
        "name": "SPORT1",
        "version": ""
    },
    {
        "regex": "Clovia/([\\d\\.]+)",
        "name": "Clovia",
        "version": "$1"
    },
    {
        "regex": "ShowMe/([\\d\\.]+)",
        "name": "ShowMe",
        "version": "$1"
    },
    {
        "regex": "Wattpad/([\\d\\.]+)",
        "name": "Wattpad",
        "version": "$1"
    },
    {
        "regex": "WSJ/([\\d\\.]+)",
        "name": "The Wall Street Journal",
        "version": ""
    },
    {
        "regex": "WH%20Questions/([\\d\\.]+)",
        "name": "WH Questions",
        "version": "$1"
    },
    {
        "regex": "whisper/([\\d\\.]+)",
        "name": "Whisper",
        "version": ""
    },
    {
        "regex": "Opal/([\\d\\.]+)",
        "name": "Opal Travel",
        "version": "$1"
    },
    {
        "regex": "Zalo/([\\d\\.]+)",
        "name": "Zalo",
        "version": "$1"
    },
    {
        "regex": "Yandex/([\\d\\.]+)",
        "name": "Yandex",
        "version": ""
    },
    {
        "regex": "Zoho%20Chat/([\\d\\.]+)",
        "name": "Zoho Chat",
        "version": "$1"
    },
    {
        "regex": "Thunder/(\\d+[\\.\\d]+)",
        "name": "Thunder",
        "version": "$1"
    },
    {
        "regex": "CGNBrowser/(\\d+[\\.\\d]+)",
        "name": "CGN",
        "version": "$1"
    },
    {
        "regex": "Podbean/.+App (\\d+[\\.\\d]+)",
        "name": "Podbean",
        "version": "$1"
    },
    {
        "regex": "AlexaMediaPlayer/(\\d+[\\.\\d]+)",
        "name": "Alexa Media Player",
        "version": "$1"
    },
    {
        "regex": "TuneIn Radio Pro(?:[^/]+)?/(\\d+[\\.\\d]+)",
        "name": "TuneIn Radio Pro",
        "version": "$1"
    },
    {
        "regex": "TuneIn Radio(?:[^/]+)?/(\\d+[\\.\\d]+)",
        "name": "TuneIn Radio",
        "version": "$1"
    },
    {
        "regex": "Podcaster/(\\d+[\\.\\d]+)",
        "name": "Podcaster",
        "version": "$1"
    },
    {
        "regex": "Swoot/(\\d+[\\.\\d]+)",
        "name": "Swoot",
        "version": "$1"
    },
    {
        "regex": "RadioPublic/android-(\\d+[\\.\\d]+)",
        "name": "RadioPublic",
        "version": "$1"
    },
    {
        "regex": "Podimo/(\\d+[\\.\\d]+)",
        "name": "Podimo",
        "version": "$1"
    },
    {
        "regex": "com.evolve.podcast/(\\d+[\\.\\d]+)",
        "name": "Evolve Podcast",
        "version": "$1"
    },
    {
        "regex": " Rocket.Chat\\+?/(\\d+[\\.\\d]+)",
        "name": "Rocket Chat",
        "version": "$1"
    },
    {
        "regex": "^Pandora Audio.+Android",
        "name": "Pandora",
        "version": ""
    },
    {
        "regex": "^NPROneAndroid",
        "name": "NPR One",
        "version": ""
    },
    {
        "regex": "^WirtschaftsWoche-iOS-(\\d+[\\.\\d]+)",
        "name": "Wirtschafts Woche",
        "version": "$1"
    },
    {
        "regex": "^Outlook-iOS/.+\\((\\d+[\\.\\d]+)\\)$",
        "name": "Microsoft Outlook",
        "version": "$1"
    },
    {
        "regex": "^TVirl/(\\d+[\\.\\d]+)",
        "name": "TVirl",
        "version": "$1"
    },
    {
        "regex": "2?chMate/(\\d+[\\.\\d]+)",
        "name": "ChMate",
        "version": "$1"
    },
    {
        "regex": "2tch/(\\d+[\\.\\d]+)",
        "name": "2tch",
        "version": "$1"
    },
    {
        "regex": "Ciisaa/(\\d+[\\.\\d]+)",
        "name": "Ciisaa",
        "version": "$1"
    },
    {
        "regex": "BB2C (\\d+[\\.\\d]+)",
        "name": "BB2C",
        "version": "$1"
    },
    {
        "regex": "twinkle/(\\d+[\\.\\d]+)",
        "name": "twinkle",
        "version": "$1"
    },
    {
        "regex": "JaneStyle_iOS/(\\d+[\\.\\d]+)",
        "name": "JaneStyle",
        "version": "$1"
    }
]
