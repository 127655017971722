<div class="d-flex justify-content-end align-items-center" mat-dialog-title>
  <mat-icon fontSet="material-symbols-outlined" mat-dialog-close>close</mat-icon>
</div>

<div class="d-flex flex-column justify-content-center align-items-center" mat-dialog-content>
  <img class="fut-emoji mb-2" src="../../../../assets/emoji/ringbuoy.svg" />
  <h1 class="fut-h1 mb-4">{{ 'dialogs.support.title' | transloco }}</h1>
  <div class="w-100 mb-2">
    <div>
      <span>{{ 'dialogs.support.faq.title' | transloco }}</span>
    </div>
    <div (click)="goFaq()" class="d-flex align-items-center pointer py-4 hover-effect">
      <img class="mr-2" src="../../../../assets/material_icons/help.svg" />
      <h3 class="fut-h3 mb-0">{{ 'dialogs.support.faq.content' | transloco }}</h3>
    </div>
  </div>
  @if (affiliated_admins.tutors.length) {
    <hr class="w-100 m-0" />
  }
  @if (affiliated_admins.tutors.length) {
    <div class="w-100">
      <div>
        <span>{{ 'dialogs.support.tutors.title' | transloco }}</span>
      </div>
      <div>
        <span class="fut-bold">{{ 'dialogs.support.tutors.content' | transloco }}</span>
      </div>
      @for (tutor of affiliated_admins.tutors; track tutor) {
        <div class="d-flex justify-content-between align-items-center py-4 hover-effect" (click)="goToWhatsapp(tutor.phone)">
          <div class="d-flex align-items-center pointer">
            <img src="../../../../assets/material_icons/whatsapp.svg" class="mr-2" />
            <h3 class="fut-bold fut-h3 mb-0">{{ tutor.name }} {{ tutor.surname }}</h3>
          </div>
          <h3 class="fut-h3 mb-0" style="font-weight: normal">{{ tutor.phone | mask: '+00 000 000 0000' }}</h3>
        </div>
      }
    </div>
  }
  @if (affiliated_admins.customer_cares.length) {
    <hr class="w-100 m-0" />
  }
  @if (affiliated_admins.customer_cares.length) {
    <div class="w-100">
      <div>
        <span>{{ 'dialogs.support.customer_cares.title' | transloco }}</span>
      </div>
      <div>
        <span class="fut-bold">{{ 'dialogs.support.customer_cares.content' | transloco }}</span>
      </div>
      @for (customer_care of affiliated_admins.customer_cares; track customer_care) {
        <div class="d-flex justify-content-between align-items-center py-4 hover-effect" (click)="goToWhatsapp(customer_care.phone)">
          <div class="d-flex align-items-center pointer">
            <img src="../../../../assets/material_icons/whatsapp.svg" class="mr-2" />
            <h3 class="fut-bold fut-h3 mb-0">{{ customer_care.name }} {{ customer_care.surname }}</h3>
          </div>
          <h3 class="fut-h3 mb-0" style="font-weight: normal">{{ customer_care.phone | mask: '+00 000 000 0000' }}</h3>
        </div>
      }
    </div>
  }
</div>
