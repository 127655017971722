import { Component, forwardRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { InvalidLicenseForActionComponent } from '../../components/invalid-license-for-action/invalid-license-for-action.component';
import { ConfigService } from '../../../core/config/config.service';

@Component({
  selector: 'app-invalid-license-for-action-dialog',
  templateUrl: './invalid-license-for-action-dialog.component.html',
  styleUrls: ['./invalid-license-for-action-dialog.component.scss'],
  standalone: true,
  imports: [forwardRef(() => InvalidLicenseForActionComponent)],
})
export class InvalidLicenseForActionDialogComponent {
  course_link = '';

  constructor(
    private dialogRef: MatDialogRef<InvalidLicenseForActionDialogComponent>,
    configService: ConfigService
  ) {
    configService.getUiConfig('_course_link').subscribe(l => {
      if (l) this.course_link = l;
    });
  }

  close(): void {
    this.dialogRef.close();
  }
}
