import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { InsertLicenseCodeDialogComponent } from '../../dialogs/insert-license-code-dialog/insert-license-code-dialog.component';
import { FutDialogService } from '../../../core/dialog-service/fut-dialog.service';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'app-invalid-license-for-action',
  templateUrl: './invalid-license-for-action.component.html',
  styleUrls: ['./invalid-license-for-action.component.scss'],
  standalone: true,
  imports: [TranslocoModule],
})
export class InvalidLicenseForActionComponent {
  @Output() goProductChange = new EventEmitter<void>();
  @Output() insertCodeChange = new EventEmitter<void>();

  constructor(
    private router: Router,
    private dialog: FutDialogService
  ) {}

  goProducts(): void {
    this.router.navigate(['products']).then();
    this.goProductChange.emit();
  }

  insertCode(): void {
    this.dialog.open(InsertLicenseCodeDialogComponent, { dimension: 'sm' });
    this.insertCodeChange.emit();
  }
}
