{
    "operatingSystem": {
        "AIX": "AIX",
        "AND": "Android",
        "AMG": "AmigaOS",
        "ATV": "Apple TV",
        "ARL": "Arch Linux",
        "BTR": "BackTrack",
        "SBA": "Bada",
        "BEO": "BeOS",
        "BLB": "BlackBerry OS",
        "QNX": "BlackBerry Tablet OS",
        "BMP": "Brew",
        "CES": "CentOS",
        "COS": "Chrome OS",
        "CYN": "CyanogenMod",
        "DEB": "Debian",
        "DEE": "Deepin",
        "DFB": "DragonFly",
        "FED": "Fedora",
        "FOS": "Firefox OS",
        "FIR": "Fire OS",
        "FRE": "Freebox",
        "BSD": "FreeBSD",
        "FYD": "FydeOS",
        "GNT": "Gentoo",
        "GTV": "Google TV",
        "HPX": "HP-UX",
        "HAI": "Haiku OS",
        "IRI": "IRIX",
        "INF": "Inferno",
        "KOS": "KaiOS",
        "KNO": "Knoppix",
        "KBT": "Kubuntu",
        "LIN": "GNU/Linux",
        "LBT": "Lubuntu",
        "VLN": "VectorLinux",
        "MAC": "Mac",
        "MAE": "Maemo",
        "MDR": "Mandriva",
        "SMG": "MeeGo",
        "MCD": "MocorDroid",
        "MIN": "Mint",
        "MLD": "MildWild",
        "MOR": "MorphOS",
        "NBS": "NetBSD",
        "MTK": "MTK / Nucleus",
        "MRE": "MRE",
        "WII": "Nintendo",
        "NDS": "Nintendo Mobile",
        "OS2": "OS/2",
        "T64": "OSF1",
        "OBS": "OpenBSD",
        "ORD": "Ordissimo",
        "PSP": "PlayStation Portable",
        "PS3": "PlayStation",
        "RHT": "Red Hat",
        "ROS": "RISC OS",
        "RSO": "Rosa",
        "REM": "Remix OS",
        "RZD": "RazoDroiD",
        "SAB": "Sabayon",
        "SSE": "SUSE",
        "SAF": "Sailfish OS",
        "SEE": "SeewoOS",
        "SLW": "Slackware",
        "SOS": "Solaris",
        "SYL": "Syllable",
        "SYM": "Symbian",
        "SYS": "Symbian OS",
        "S40": "Symbian OS Series 40",
        "S60": "Symbian OS Series 60",
        "SY3": "Symbian^3",
        "TDX": "ThreadX",
        "TIZ": "Tizen",
        "TOS": "TmaxOS",
        "UBT": "Ubuntu",
        "WAS": "watchOS",
        "WTV": "WebTV",
        "WHS": "Whale OS",
        "WIN": "Windows",
        "WCE": "Windows CE",
        "WIO": "Windows IoT",
        "WMO": "Windows Mobile",
        "WPH": "Windows Phone",
        "WRT": "Windows RT",
        "XBX": "Xbox",
        "XBT": "Xubuntu",
        "YNS": "YunOs",
        "IOS": "iOS",
        "POS": "palmOS",
        "WOS": "webOS"
    },
    "osFamilies": {
        "Android": ["AND", "CYN", "FIR", "REM", "RZD", "MLD", "MCD", "YNS"],
        "AmigaOS": ["AMG", "MOR"],
        "Apple TV": ["ATV"],
        "BlackBerry": ["BLB", "QNX"],
        "Brew": ["BMP"],
        "BeOS": ["BEO", "HAI"],
        "Chrome OS": ["COS", "FYD", "SEE"],
        "Firefox OS": ["FOS", "KOS"],
        "Gaming Console": ["WII", "PS3"],
        "Google TV": ["GTV"],
        "IBM": ["OS2"],
        "iOS": ["IOS", "WAS"],
        "RISC OS": ["ROS"],
        "GNU/Linux": [
            "LIN", "ARL", "DEB", "KNO", "MIN", "UBT", "KBT", "XBT", "LBT", "FED",
            "RHT", "VLN", "MDR", "GNT", "SAB", "SLW", "SSE", "CES", "BTR", "SAF",
            "ORD", "TOS", "RSO", "DEE", "FRE"
        ],
        "Mac": ["MAC"],
        "Mobile Gaming Console": ["PSP", "NDS", "XBX"],
        "Real-time OS": ["MTK", "TDX", "MRE"],
        "Other Mobile": ["WOS", "POS", "SBA", "TIZ", "SMG", "MAE"],
        "Symbian": ["SYM", "SYS", "SY3", "S60", "S40"],
        "Unix": ["SOS", "AIX", "HPX", "BSD", "NBS", "OBS", "DFB", "SYL", "IRI", "T64", "INF"],
        "WebTV": ["WTV"],
        "Windows": ["WIN"],
        "Windows Mobile": ["WPH", "WMO", "WCE", "WRT", "WIO"],
        "Other Smart TV": ["WHS"]
    }
}
