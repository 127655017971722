{
    "1B": "115 Browser",
    "2B": "2345 Browser",
    "36": "360 Phone Browser",
    "3B": "360 Browser",
    "7B": "7654 Browser",
    "AA": "Avant Browser",
    "AB": "ABrowse",
    "AF": "ANT Fresco",
    "AG": "ANTGalio",
    "AL": "Aloha Browser",
    "AH": "Aloha Browser Lite",
    "AM": "Amaya",
    "AO": "Amigo",
    "AN": "Android Browser",
    "AE": "AOL Desktop",
    "AD": "AOL Shield",
    "AR": "Arora",
    "AX": "Arctic Fox",
    "AV": "Amiga Voyager",
    "AW": "Amiga Aweb",
    "A0": "Atom",
    "AT": "Atomic Web Browser",
    "AS": "Avast Secure Browser",
    "VG": "AVG Secure Browser",
    "AC": "Avira Scout",
    "A1": "AwoX",
    "BA": "Beaker Browser",
    "BM": "Beamrise",
    "BB": "BlackBerry Browser",
    "BD": "Baidu Browser",
    "BS": "Baidu Spark",
    "BI": "Basilisk",
    "BE": "Beonex",
    "BH": "BlackHawk",
    "BJ": "Bunjalloo",
    "BL": "B-Line",
    "BU": "Blue Browser",
    "BN": "Borealis Navigator",
    "BR": "Brave",
    "BK": "BriskBard",
    "BX": "BrowseX",
    "BZ": "Browzar",
    "BY": "Biyubi",
    "BF": "Byffox",
    "CA": "Camino",
    "CL": "CCleaner",
    "C6": "Chedot",
    "C0": "Centaury",
    "CC": "Coc Coc",
    "C4": "CoolBrowser",
    "C2": "Colibri",
    "CD": "Comodo Dragon",
    "C1": "Coast",
    "CX": "Charon",
    "CE": "CM Browser",
    "CF": "Chrome Frame",
    "HC": "Headless Chrome",
    "CH": "Chrome",
    "CI": "Chrome Mobile iOS",
    "CK": "Conkeror",
    "CM": "Chrome Mobile",
    "CN": "CoolNovo",
    "CO": "CometBird",
    "CB": "COS Browser",
    "CW": "Cornowser",
    "C3": "Chim Lac",
    "CP": "ChromePlus",
    "CR": "Chromium",
    "C5": "Chromium GOST",
    "CY": "Cyberfox",
    "CS": "Cheshire",
    "CT": "Crusta",
    "CG": "Craving Explorer",
    "CZ": "Crazy Browser",
    "CU": "Cunaguaro",
    "CV": "Chrome Webview",
    "DB": "dbrowser",
    "DE": "Deepnet Explorer",
    "DG": "deg-degan",
    "DA": "Deledao",
    "DT": "Delta Browser",
    "DS": "DeskBrowse",
    "DF": "Dolphin",
    "DO": "Dorado",
    "DR": "Dot Browser",
    "DL": "Dooble",
    "DI": "Dillo",
    "DD": "DuckDuckGo Privacy Browser",
    "EC": "Ecosia",
    "EI": "Epic",
    "EL": "Elinks",
    "EB": "Element Browser",
    "EE": "Elements Browser",
    "EZ": "eZ Browser",
    "EU": "EUI Browser",
    "EP": "GNOME Web",
    "ES": "Espial TV Browser",
    "FA": "Falkon",
    "FX": "Faux Browser",
    "F1": "Firefox Mobile iOS",
    "FB": "Firebird",
    "FD": "Fluid",
    "FE": "Fennec",
    "FF": "Firefox",
    "FK": "Firefox Focus",
    "FY": "Firefox Reality",
    "FR": "Firefox Rocket",
    "FL": "Flock",
    "FO": "Flow",
    "FM": "Firefox Mobile",
    "FW": "Fireweb",
    "FN": "Fireweb Navigator",
    "FS": "Flast",
    "FU": "FreeU",
    "GA": "Galeon",
    "GH": "Ghostery Privacy Browser",
    "GI": "GinxDroid Browser",
    "GB": "Glass Browser",
    "GE": "Google Earth",
    "GO": "GOG Galaxy",
    "HS": "HasBrowser",
    "HA": "Hawk Turbo Browser",
    "HE": "Helio",
    "HO": "hola! Browser",
    "HJ": "HotJava",
    "HU": "Huawei Browser",
    "IB": "IBrowse",
    "IC": "iCab",
    "I2": "iCab Mobile",
    "I1": "Iridium",
    "I3": "Iron Mobile",
    "I4": "IceCat",
    "ID": "IceDragon",
    "IV": "Isivioo",
    "IW": "Iceweasel",
    "IE": "Internet Explorer",
    "IM": "IE Mobile",
    "IR": "Iron",
    "JB": "Japan Browser",
    "JS": "Jasmine",
    "JA": "JavaFX",
    "JI": "Jig Browser",
    "JP": "Jig Browser Plus",
    "JO": "Jio Browser",
    "KB": "K.Browser",
    "KI": "Kindle Browser",
    "KM": "K-meleon",
    "KO": "Konqueror",
    "KP": "Kapiko",
    "KN": "Kinza",
    "KW": "Kiwi",
    "KD": "Kode Browser",
    "KY": "Kylo",
    "KZ": "Kazehakase",
    "LB": "Cheetah Browser",
    "LF": "LieBaoFast",
    "LG": "LG Browser",
    "LH": "Light",
    "LI": "Links",
    "IF": "Lolifox",
    "LO": "Lovense Browser",
    "LU": "LuaKit",
    "LL": "Lulumi",
    "LS": "Lunascape",
    "LN": "Lunascape Lite",
    "LX": "Lynx",
    "M1": "mCent",
    "MB": "MicroB",
    "MC": "NCSA Mosaic",
    "MZ": "Meizu Browser",
    "ME": "Mercury",
    "MF": "Mobile Safari",
    "MI": "Midori",
    "MO": "Mobicip",
    "MU": "MIUI Browser",
    "MS": "Mobile Silk",
    "MN": "Minimo",
    "MT": "Mint Browser",
    "MX": "Maxthon",
    "NM": "MxNitro",
    "MY": "Mypal",
    "MR": "Monument Browser",
    "MW": "MAUI WAP Browser",
    "NW": "Navigateur Web",
    "NR": "NFS Browser",
    "NB": "Nokia Browser",
    "NO": "Nokia OSS Browser",
    "NV": "Nokia Ovi Browser",
    "NX": "Nox Browser",
    "NE": "NetSurf",
    "NF": "NetFront",
    "NL": "NetFront Life",
    "NP": "NetPositive",
    "NS": "Netscape",
    "NT": "NTENT Browser",
    "OC": "Oculus Browser",
    "O1": "Opera Mini iOS",
    "OB": "Obigo",
    "O2": "Odin",
    "H2": "OceanHero",
    "OD": "Odyssey Web Browser",
    "OF": "Off By One",
    "HH": "OhHai Browser",
    "OE": "ONE Browser",
    "OX": "Opera GX",
    "OG": "Opera Neon",
    "OH": "Opera Devices",
    "OI": "Opera Mini",
    "OM": "Opera Mobile",
    "OP": "Opera",
    "ON": "Opera Next",
    "OO": "Opera Touch",
    "OA": "Orca",
    "OS": "Ordissimo",
    "OR": "Oregano",
    "O0": "Origin In-Game Overlay",
    "OY": "Origyn Web Browser",
    "OV": "Openwave Mobile Browser",
    "OW": "OmniWeb",
    "OT": "Otter Browser",
    "PL": "Palm Blazer",
    "PM": "Pale Moon",
    "PY": "Polypane",
    "PP": "Oppo Browser",
    "PR": "Palm Pre",
    "PU": "Puffin",
    "PW": "Palm WebPro",
    "PA": "Palmscape",
    "PE": "Perfect Browser",
    "PH": "Phantom Browser",
    "PX": "Phoenix",
    "PB": "Phoenix Browser",
    "PF": "PlayFree Browser",
    "PO": "Polaris",
    "PT": "Polarity",
    "LY": "PolyBrowser",
    "PI": "PrivacyWall",
    "PS": "Microsoft Edge",
    "QA": "Qazweb",
    "Q2": "QQ Browser Lite",
    "Q1": "QQ Browser Mini",
    "QQ": "QQ Browser",
    "QT": "Qutebrowser",
    "QU": "Quark",
    "QZ": "QupZilla",
    "QM": "Qwant Mobile",
    "QW": "QtWebEngine",
    "RE": "Realme Browser",
    "RK": "Rekonq",
    "RM": "RockMelt",
    "SB": "Samsung Browser",
    "SA": "Sailfish Browser",
    "S8": "Seewo Browser",
    "SC": "SEMC-Browser",
    "SE": "Sogou Explorer",
    "SF": "Safari",
    "S5": "Safe Exam Browser",
    "SW": "SalamWeb",
    "SV": "SFive",
    "SH": "Shiira",
    "S1": "SimpleBrowser",
    "SY": "Sizzy",
    "SK": "Skyfire",
    "SS": "Seraphic Sraf",
    "SL": "Sleipnir",
    "S6": "Slimjet",
    "S7": "SP Browser",
    "7S": "7Star",
    "LE": "Smart Lenovo Browser",
    "OZ": "Smooz",
    "SN": "Snowshoe",
    "SO": "Sogou Mobile Browser",
    "S2": "Splash",
    "SI": "Sputnik Browser",
    "SR": "Sunrise",
    "SP": "SuperBird",
    "SU": "Super Fast Browser",
    "S3": "surf",
    "SG": "Stargon",
    "S0": "START Internet Browser",
    "S4": "Steam In-Game Overlay",
    "ST": "Streamy",
    "SX": "Swiftfox",
    "SZ": "Seznam Browser",
    "TR": "T-Browser",
    "TO": "t-online.de Browser",
    "TA": "Tao Browser",
    "TF": "TenFourFox",
    "TB": "Tenta Browser",
    "TZ": "Tizen Browser",
    "TU": "Tungsten",
    "TG": "ToGate",
    "TS": "TweakStyle",
    "TV": "TV Bro",
    "UB": "UBrowser",
    "UC": "UC Browser",
    "UH": "UC Browser HD",
    "UM": "UC Browser Mini",
    "UT": "UC Browser Turbo",
    "UR": "UR Browser",
    "UZ": "Uzbl",
    "VE": "Venus Browser",
    "VI": "Vivaldi",
    "VV": "vivo Browser",
    "VB": "Vision Mobile Browser",
    "VM": "VMware AirWatch",
    "WI": "Wear Internet Browser",
    "WP": "Web Explorer",
    "WE": "WebPositive",
    "WF": "Waterfox",
    "WH": "Whale Browser",
    "WO": "wOSBrowser",
    "WT": "WeTab Browser",
    "YJ": "Yahoo! Japan Browser",
    "YA": "Yandex Browser",
    "YL": "Yandex Browser Lite",
    "YN": "Yaani Browser",
    "YB": "Yolo Browser",
    "XS": "xStand",
    "XI": "Xiino",
    "XV": "Xvast",
    "ZE": "Zetakey",
    "ZV": "Zvu"
}
