<div class="ia-head">
  <div class="d-flex justify-content-between align-items-center text-left">
    <span>{{ 'dialogs.no_license.dialog.title' | transloco }}</span>
  </div>
  <div class="d-flex justify-content-between align-items-center mt-3">
    <div class="d-flex flex-column pr-2">
      <img alt="Futura" class="logo mb-3" src="../../../../assets/futura-logo-blu.svg" />
      <h3 class="fut-h3 text-left">{{ 'dialogs.no_license.dialog.content.title' | transloco }}</h3>
    </div>
    <img alt="Futura" class="image-3d" src="../../../../assets/modal_unlock_image.png" />
  </div>
</div>

<div class="ia-action d-flex flex-column justify-content-center align-items-center">
  <button (click)="goProducts()" class="fut-btn fut-btn-accent fut-btn-big w-100" style="margin-top: 2em">Scopri di più</button>
  <span class="my-3">{{ 'dialogs.invalid_license.sub_content.title' | transloco }}</span>
  <span (click)="insertCode()" class="font-weight-bold pointer">{{ 'profile.license.cta' | transloco }}</span>
</div>
