import { UtilsService } from 'src/app/core/utils-service/utils.service';
import { MatDialog, MatDialogClose } from '@angular/material/dialog';
import { UserService } from '../../../core/user-service/user.service';
import { Component, forwardRef, Inject } from '@angular/core';
import { LottieInfoDialogComponent } from '../lotti-info-dialog/lottie-info-dialog.component';
import { TranslocoModule } from '@jsverse/transloco';
import { MatFormField, MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatLabel } from '@angular/material/form-field';

@Component({
  selector: 'app-insert-license-code-dialog',
  templateUrl: './insert-license-code-dialog.component.html',
  styleUrls: ['./insert-license-code-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogClose, TranslocoModule, MatInput, FormsModule, MatFormField, MatLabel],
})
export class InsertLicenseCodeDialogComponent {
  public licenseValue?: string;

  constructor(
    @Inject(forwardRef(() => UserService)) private userService: UserService,
    private dialog: MatDialog,
    private utils: UtilsService
  ) {}

  verifyLicense() {
    if (!this.licenseValue) {
      return;
    }
    const license = this.licenseValue.trim();
    if (!license) {
      return;
    }

    this.userService.activeLicense(license).subscribe(() => {
      this.utils.sendEvent('cl_profile_licence_activation').catch(console.error);
      this.userService.clearFuturaUserCache();
      this.dialog
        .open(LottieInfoDialogComponent, {
          data: {
            infoTitle: 'profile.license.success.title',
            infoSubTitle: 'profile.license.success.subtitle',
            lottiePath: './assets/lotties/success.json',
          },
          width: 'min-content',
          minWidth: 'min(50vw, 23em)',
        })
        .afterClosed()
        .subscribe(() => {
          location.reload();
        });
    });
  }
}
