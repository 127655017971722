{
    "Dell": [
        "MDDR(JS)?",
        "MDDC(JS)?",
        "MDDS(JS)?"
    ],
    "Acer": [
        "MAAR(JS)?"
    ],
    "Sony": [
        "MASE(JS)?",
        "MASP(JS)?",
        "MASA(JS)?"
    ],
    "Asus": [
        "MAAU",
        "NP0[26789]",
        "ASJB",
        "ASU2(JS)?"
    ],
    "Samsung": [
        "MASM(JS)?",
        "SMJB"
    ],
    "Lenovo": [
        "MALC(JS)?",
        "MALE(JS)?",
        "MALN(JS)?",
        "LCJB",
        "LEN2"
    ],
    "Toshiba": [
        "MATM(JS)?",
        "MATB(JS)?",
        "MATP(JS)?",
        "TNJB",
        "TAJB"
    ],
    "Medion": [
        "MAMD"
    ],
    "MSI": [
        "MAMI(JS)?",
        "MAM3"
    ],
    "Gateway": [
        "MAGW(JS)?"
    ],
    "Fujitsu": [
        "MAFS(JS)?",
        "FSJB"
    ],
    "Compaq": [
        "CPDTDF",
        "CPNTDF(JS?)",
        "CMNTDF(JS)?",
        "CMDTDF(JS)?"
    ],
    "HP": [
        "HPCMHP",
        "HPNTDF(JS)?",
        "HPDTDF(JS)?"
    ],
    "Hyrican": [
        "MANM(JS)?"
    ],
    "Ordissimo": [
        "Ordissimo",
        "webissimo3"
    ]
}
