{
    "Airties": {
        "regex": "Airties",
        "device": "tv",
        "models": [
            {
                "regex": "Airties; ?([^);/]+)",
                "model": "$1"
            }
        ]
    },
    "ALDI NORD": {
        "regex": "ALDINORD[;,]",
        "device": "tv",
        "model": ""
    },
    "ALDI SÜD": {
        "regex": "ALDISUED[;,]",
        "device": "tv",
        "model": ""
    },
    "Altech UEC": {
        "regex": "Altech UEC",
        "device": "tv",
        "models": [
            {
                "regex": "Altech UEC; ?([^);/]+)",
                "model": "$1"
            }
        ]
    },
    "AOC": {
        "regex": "AOC",
        "device": "tv",
        "models": [
            {
                "regex": "(LE43S5970-20|S50856)",
                "model": "$1"
            }
        ]
    },
    "ARRIS": {
        "regex": "ARRIS[;,]",
        "device": "tv",
        "models": [
            {
                "regex": "FS-ARS-01B",
                "model": "FS-ARS-01B"
            }
        ]
    },
    "Atvio": {
        "regex": "ATVIO",
        "device": "tv",
        "models": [
            {
                "regex": "55D1620",
                "model": "55D1620"
            }
        ]
    },
    "BangOlufsen": {
        "regex": "Bangolufsen",
        "device": "tv",
        "model": "BeoVision"
    },
    "Blaupunkt": {
        "regex": "Blaupunkt_UMC[;,]",
        "device": "tv",
        "models": [
            {
                "regex": "LC-65CUG8052E",
                "model": "LC-65CUG8052E"
            }
        ]
    },
    "Bush": {
        "regex": "BUSH[;,]",
        "device": "tv",
        "model": ""
    },
    "Celcus": {
        "regex": "CELCUS[;,]",
        "device": "tv",
        "model": ""
    },
    "Changhong": {
        "regex": "Changhong",
        "device": "tv",
        "models": [
            {
                "regex": "Changhong; ?([^);/]+)",
                "model": "$1"
            }
        ]
    },
    "CreNova": {
        "regex": "CreNova",
        "device": "tv",
        "model": "CNV001"
    },
    "Digihome": {
        "regex": "DIGIHOME[;,]",
        "device": "tv",
        "model": ""
    },
    "DMM": {
        "regex": "DMM",
        "device": "tv",
        "model": "Dreambox"
    },
    "ELECTRONIA": {
        "regex": "ELECTRONIA[;,]",
        "device": "tv",
        "model": ""
    },
    "Essentielb": {
        "regex": "ESSENTIELB[;,]",
        "device": "tv",
        "model": ""
    },
    "Finlux": {
        "regex": "FINLUX[;,]",
        "device": "tv",
        "model": ""
    },
    "F&U": {
        "regex": "FU[;,]",
        "device": "tv",
        "model": ""
    },
    "Fuego": {
        "regex": "FUEGO[;,]",
        "device": "tv",
        "model": ""
    },
    "GoGEN": {
        "regex": "GOGEN[;,]",
        "device": "tv",
        "model": ""
    },
    "Grundig": {
        "regex": "(OWB|Grundig|Arcelik)",
        "device": "tv",
        "model": ""
    },
    "Haier": {
        "regex": "(?:HHW_)?HAIER",
        "device": "tv",
        "models": [
            {
                "regex": "LE55X7000U",
                "model": "LE55X7000U"
            }
        ]
    },
    "Hi-Level": {
        "regex": "HI-LEVEL[;,]",
        "device": "tv",
        "model": ""
    },
    "Hisense": {
        "regex": "Hisense|Eurofins_Digital_Testing",
        "device": "tv",
        "models": [
            {
                "regex": "50ADEVTOOL",
                "model": "50ADEVTOOL"
            },
            {
                "regex": "50A683FEVS",
                "model": "50A683FEVS"
            },
            {
                "regex": "55A6100EE",
                "model": "55A6100EE"
            },
            {
                "regex": "55U62QGAVT",
                "model": "55U62QGAVT"
            },
            {
                "regex": "50A6502EA",
                "model": "50A6502EA"
            },
            {
                "regex": "MICALIDVB6886",
                "model": "MICALIDVB6886"
            },
            {
                "regex": "(L[A-Z]{2,3}[0-9]{2}[A-Z][0-9]{3,4}[A-Z]{0,6}[0-9]?[A-Z]?)",
                "model": "$1"
            },
            {
                "regex": "(H[A-Z]?[0-9]{2}[A-Z][0-9]{3,4}[A-Z]{0,4})",
                "model": "$1"
            }
        ]
    },
    "Hitachi": {
        "regex": "Hitachi[;,]",
        "device": "tv",
        "models": [
            {
                "regex": "49D2900",
                "model": "49D2900"
            }
        ]
    },
    "Horizon": {
        "regex": "HORIZON[;,]",
        "device": "tv",
        "model": ""
    },
    "Hotel": {
        "regex": "HOTEL[;,]",
        "device": "tv",
        "model": ""
    },
    "Humax": {
        "regex": "Humax",
        "device": "tv",
        "models": [
            {
                "regex": "(HD-FOX C|HD (FOX\\+|NANO)|iCord (HD\\+|MINI|Cable)|(CX|IR)HD-5100(C|S)|HM9503HD)",
                "model": "$1"
            },
            {
                "regex": "HMS1000S",
                "model": "HMS-1000S"
            },
            {
                "regex": "Humax; ([^);/]+)",
                "model": "$1"
            }
        ]
    },
    "Hyundai": {
        "regex": "HYUNDAI[;,]",
        "device": "tv",
        "model": ""
    },
    "Ikea": {
        "regex": "Ikea",
        "device": "tv",
        "model": ""
    },
    "Intek": {
        "regex": "Intek",
        "device": "tv",
        "models": [
            {
                "regex": "(Vantage|VT-100|VT-1)",
                "model": "$1"
            }
        ]
    },
    "Inverto": {
        "regex": "Inverto",
        "device": "tv",
        "models": [
            {
                "regex": "inverto; ([^);/]+)",
                "model": "$1"
            },
            {
                "regex": "(Volksbox Web Edition|Volksbox Essential|Volksbox II|Volksbox)",
                "model": "$1"
            }
        ]
    },
    "JVC": {
        "regex": "AFTSO001|JVC[;,]",
        "device": "tv",
        "models": [
            {
                "regex": "AFTSO001",
                "model": "4K (2019)"
            }
        ]
    },
    "Kalley": {
        "regex": "KALLEY[;,]",
        "device": "tv",
        "models": [
            {
                "regex": "32D1620",
                "model": "32D1620"
            }
        ]
    },
    "KUBO": {
        "regex": "KUBO[;,]",
        "device": "tv",
        "model": ""
    },
    "Laurus": {
        "regex": "LAURUS[;,]",
        "device": "tv",
        "model": ""
    },
    "LG": {
        "regex": "LGE",
        "device": "tv",
        "models": [
            {
                "regex": "XU43WT180N",
                "model": "XU43WT180N"
            },
            {
                "regex": "43LJ614V-ZA",
                "model": "43LJ614V-ZA"
            },
            {
                "regex": "55SK850V-ZA",
                "model": "55SK850V-ZA"
            },
            {
                "regex": "KEY0000213F1z",
                "model": "KEY0000213F1z"
            },
            {
                "regex": "KEY0000213F",
                "model": "KEY0000213F"
            },
            {
                "regex": "KEY000000(2E|2F|3B|3F)",
                "model": "KEY000000$1"
            },
            {
                "regex": "(NetCast [0-9]{1}.[0-9]{1}|GLOBAL_PLAT3)",
                "model": "$1"
            },
            {
                "regex": "(OLED[0-9]{2}[A-Z0-9]{3}[A-Z]{2})",
                "model": "$1"
            },
            {
                "regex": "(OLED[0-9]{2}[A-Z][0-9][A-Z])",
                "model": "$1"
            },
            {
                "regex": "(OLED[0-9]{2}[A-Z0-9]{2})",
                "model": "$1"
            },
            {
                "regex": "([0-9]{2}[A-Z]{2}[0-9]{4}[A-Z0-9]{1}[A-Z]{2})",
                "model": "$1"
            },
            {
                "regex": "([0-9]{2}NANO[0-9]{3}[A-Z]{2})",
                "model": "$1"
            },
            {
                "regex": "([0-9]{2}NANO[0-9]{2})",
                "model": "$1"
            },
            {
                "regex": "LGE;? ?([0-9]{2}[A-Z]{2}[0-9]{2,4}[A-Z]?)",
                "model": "$1"
            },
            {
                "regex": "LM21U",
                "model": "LM21U"
            },
            {
                "regex": "32LM",
                "model": "32LM"
            }
        ]
    },
    "Lifemaxx": {
        "regex": "Lifemaxx[;,]",
        "device": "tv",
        "model": ""
    },
    "Linsar": {
        "regex": "LINSAR[;,]",
        "device": "tv",
        "model": ""
    },
    "Loewe": {
        "regex": "Loewe",
        "device": "tv",
        "models": [
            {
                "regex": "([A-Z]{2}[0-9]{3})",
                "model": "$1"
            }
        ]
    },
    "Luxor": {
        "regex": "LUXOR[;,]",
        "device": "tv",
        "model": ""
    },
    "Manhattan": {
        "regex": "Manhattan",
        "device": "tv",
        "models": [
            {
                "regex": "T3",
                "model": "T3"
            }
        ]
    },
    "MediaTek": {
        "regex": "MTK|MediaTek;",
        "device": "tv",
        "models": [
            {
                "regex": "(MT[0-9]{4})",
                "model": "$1"
            }
        ]
    },
    "Medion": {
        "regex": "Medion",
        "device": "tv",
        "model": ""
    },
    "Miray": {
        "regex": "MIRAY",
        "device": "tv",
        "models": [
            {
                "regex": "LEDM-322NIP",
                "model": "LEDM-322NIP"
            }
        ]
    },
    "MStar": {
        "regex": "MStar[;,]",
        "device": "tv",
        "models": [
            {
                "regex": "([24])KTV18",
                "model": "$1KTV18"
            }
        ]
    },
    "MTC": {
        "regex": "MTC[;,]",
        "device": "tv",
        "models": [
            {
                "regex": "([24])K_Android_TV_V01",
                "model": "$1K Android TV"
            }
        ]
    },
    "Nordmende": {
        "regex": "NORDMENDE[;,]",
        "device": "tv",
        "model": ""
    },
    "Metz": {
        "regex": "Metz",
        "device": "tv",
        "model": ""
    },
    "Ok": {
        "regex": "OK[;,]",
        "device": "tv",
        "model": ""
    },
    "Panasonic": {
        "regex": "Panasonic",
        "device": "tv",
        "models": [
            {
                "regex": "VIERA (201[1-9])",
                "model": "VIERA ($1)"
            },
            {
                "regex": "(DIGA [A-Z]{1}[0-9]{4})",
                "model": "$1"
            },
            {
                "regex": "DIGA Webkit ([A-Z]{1}[0-9]{4})",
                "model": "DIGA $1"
            },
            {
                "regex": "SmartTV(201[89]|202[0-1])",
                "model": "Smart TV ($1)"
            }
        ]
    },
    "PEAQ": {
        "regex": "PEAQ",
        "device": "tv",
        "model": ""
    },
    "Philips": {
        "regex": "Philips|NETTV/",
        "device": "tv",
        "models": [
            {
                "regex": "Philips[,;] ?((?! )[^),;/]+)",
                "model": "$1"
            },
            {
                "regex": "NETTV/[0-9\\.]{5}",
                "model": "NetTV Series"
            }
        ]
    },
    "Polaroid": {
        "regex": "POLAROID[;,]",
        "device": "tv",
        "model": ""
    },
    "PROFiLO": {
        "regex": "PROFILO[;,]",
        "device": "tv",
        "model": ""
    },
    "Qilive": {
        "regex": "QILIVE[;,]",
        "device": "tv",
        "model": ""
    },
    "REGAL": {
        "regex": "REGAL[;,]",
        "device": "tv",
        "model": ""
    },
    "Saba": {
        "regex": "Saba[;,]",
        "device": "tv",
        "model": ""
    },
    "Salora": {
        "regex": "Salora[;,]",
        "device": "tv",
        "model": ""
    },
    "Samsung": {
        "regex": "Samsung|Maple_2011",
        "device": "tv",
        "models": [
            {
                "regex": "SmartTV(201[2-9]|202[0-1]):([^);/]+)",
                "model": "$2"
            },
            {
                "regex": "SmartTV(201[2-9]|202[0-1])",
                "model": "Smart TV ($1)"
            },
            {
                "regex": "Maple_2011",
                "model": "Smart TV (2011)"
            }
        ]
    },
    "SCBC": {
        "regex": "SCBC[;,]",
        "device": "tv",
        "models": [
            {
                "regex": "SCBC586",
                "model": "SCBC586"
            },
            {
                "regex": "43D1850",
                "model": "43D1850"
            }
        ]
    },
    "SEG": {
        "regex": "SEG[;,]",
        "device": "tv",
        "model": ""
    },
    "Selevision": {
        "regex": "Selevision",
        "device": "tv",
        "models": [
            {
                "regex": "Selevision; (?:Selevision )?([^);/]+)",
                "model": "$1"
            },
            {
                "regex": "(EMC1000i)",
                "model": "$1"
            }
        ]
    },
    "Sharp": {
        "regex": "(?:UMC-)?Sharp",
        "device": "tv",
        "models": [
            {
                "regex": "Sharp[,;] ?((?! |HbbTV)[^),;/]+)",
                "model": "$1"
            },
            {
                "regex": "(LE[0-9]{3}[A-Z]{0,3})",
                "model": "$1"
            },
            {
                "regex": "LC-([^);/]+)",
                "model": "LC-$1"
            },
            {
                "regex": "BLA-43",
                "model": "BLA-43"
            }
        ]
    },
    "Skyworth": {
        "regex": "Sky_worth",
        "device": "tv",
        "models": [
            {
                "regex": "Sky_worth;([^);/]+)",
                "model": "$1"
            }
        ]
    },
    "Smart": {
        "regex": "Smart[;,]",
        "device": "tv",
        "models": [
            {
                "regex": "Smart; ([^);/]+)",
                "model": "$1"
            },
            {
                "regex": "([A-Z]{2}[0-9]{2}|ZAPPIX)",
                "model": "$1"
            }
        ]
    },
    "Sony": {
        "regex": "Sony",
        "device": "tv",
        "models": [
            {
                "regex": "KDL-GR1",
                "model": "KDL-GR1"
            },
            {
                "regex": "KDL-GN([56])",
                "model": "KDL-GN$1"
            },
            {
                "regex": "BRAVIA (VH1|4K VH2)",
                "model": "BRAVIA $1"
            },
            {
                "regex": "(KDL?-?[0-9]{2}[A-Z]{1}[0-9]{4}[A-Z]{1})",
                "model": "$1"
            },
            {
                "regex": "(KDL?-?[0-9]{2}[A-Z]{1}[0-9]{3}[A-Z]{1})",
                "model": "$1"
            },
            {
                "regex": "(KDL?-?[0-9]{2}[A-Z]{1,2}[0-9]{1,5})",
                "model": "$1"
            }
        ]
    },
    "SWTV": {
        "regex": "SWTV[;,]",
        "device": "tv",
        "models": [
            {
                "regex": "SKWE20E21",
                "model": "SKWE20E21"
            }
        ]
    },
    "TD Systems": {
        "regex": "TDSystems[;,]",
        "device": "tv",
        "models": [
            {
                "regex": "SmartTV(2019|2020)",
                "model": "Smart TV ($1)"
            }
        ]
    },
    "Technicolor": {
        "regex": "Technicolor",
        "device": "tv",
        "models": [
            {
                "regex": "uzw4054ttg",
                "model": "UZW4054TTG"
            }
        ]
    },
    "Technika": {
        "regex": "TECHNIKA[;,]",
        "device": "tv",
        "model": ""
    },
    "TechniSat": {
        "regex": "TechniSat",
        "device": "tv",
        "models": [
            {
                "regex": "((DigiCorder|MultyVision|Digit) (ISIO S|ISIO C|ISIO))",
                "model": "$1"
            }
        ]
    },
    "TechnoTrend": {
        "regex": "TechnoTrend",
        "device": "tv",
        "models": [
            {
                "regex": "([A-Z]{1}-[0-9]{3})",
                "model": "$1"
            }
        ]
    },
    "Techwood": {
        "regex": "Techwood[;,]",
        "device": "tv",
        "model": ""
    },
    "Telefunken": {
        "regex": "Telefunken",
        "device": "tv",
        "model": ""
    },
    "TCL": {
        "regex": "TCL",
        "device": "tv",
        "models": [
            {
                "regex": "(32D1820|(?:39|55)D2900|32D2930|(?:32|43)S4900)",
                "model": "$1"
            }
        ]
    },
    "Thomson": {
        "regex": "THOMSON[,]?|THOM",
        "device": "tv",
        "models": [
            {
                "regex": "(TB28D19DHS-01|T28D18SFS-01B)",
                "model": "$1 28.0\""
            },
            {
                "regex": "(T32RTM5040|T32D18SFS-01B)",
                "model": "$1 32.0\""
            },
            {
                "regex": "(T43FSL5031|T43D18SFS-01B)",
                "model": "$1 43.0\""
            },
            {
                "regex": "(T40D18SFS-01B)",
                "model": "$1 40.0\""
            },
            {
                "regex": "(T49D18SFS-01B)",
                "model": "$1 49.0\""
            },
            {
                "regex": "(T55D18[SD]FS-01B)",
                "model": "$1 55.0\""
            },
            {
                "regex": "40FB5426",
                "model": "40FB5426"
            }
        ]
    },
    "TOKYO": {
        "regex": "TOKYO[;,]",
        "device": "tv",
        "models": [
            {
                "regex": "TOKTCLED32S",
                "model": "TOKTCLED32S"
            }
        ]
    },
    "Toshiba": {
        "regex": "Toshiba",
        "device": "tv",
        "models": [
            {
                "regex": "40L2600",
                "model": "40L2600"
            },
            {
                "regex": "(([0-9]{2}|DTV_)[A-Z]{2}[0-9]{1,3})",
                "model": "$1"
            }
        ]
    },
    "Videoweb": {
        "regex": "videoweb|tv2n",
        "device": "tv",
        "models": [
            {
                "regex": "(tv2n)",
                "model": "$1"
            },
            {
                "regex": "(videowebtv)",
                "model": "VideoWeb TV"
            }
        ]
    },
    "VOX": {
        "regex": "VOX[;,]",
        "device": "tv",
        "model": ""
    },
    "WELLINGTON": {
        "regex": "WELLINGTON[;,]",
        "device": "tv",
        "model": ""
    },
    "X.Vision": {
        "regex": "X-VISION[;,]",
        "device": "tv",
        "model": ""
    },
    "Vestel": {
        "regex": "(?:Vestel.+VESTEL|VESTEL;)",
        "device": "tv",
        "model": ""
    }
}
