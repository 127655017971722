[
    {
        "regex": "Audacious(?:[ /]([\\d\\.]+))?",
        "name": "Audacious",
        "version": "$1"
    },
    {
        "regex": "Banshee(?:[ /]([\\d\\.]+))?",
        "name": "Banshee",
        "version": "$1"
    },
    {
        "regex": "Boxee(?:[ /]([\\d\\.]+))?",
        "name": "Boxee",
        "version": "$1"
    },
    {
        "regex": "Clementine(?:[ /]([\\d\\.]+))?",
        "name": "Clementine",
        "version": "$1"
    },
    {
        "regex": "Deezer(?:/([\\d\\.]+))?",
        "name": "Deezer",
        "version": "$1"
    },
    {
        "regex": "iTunes(?:-iPhone|-iPad)?(?:/([\\d\\.]+))?",
        "name": "iTunes",
        "version": "$1"
    },
    {
        "regex": "FlyCast(?:/([\\d\\.]+))?",
        "name": "FlyCast",
        "version": "$1"
    },
    {
        "regex": "foobar2000(?:/([\\d\\.]+))?",
        "name": "Foobar2000",
        "version": "$1"
    },
    {
        "regex": "MediaMonkey(?:[ /](\\d+[\\.\\d]+))?",
        "name": "MediaMonkey",
        "version": "$1"
    },
    {
        "regex": "Miro(?:/(\\d+[\\.\\d]+))?",
        "name": "Miro",
        "version": "$1"
    },
    {
        "regex": "NexPlayer(?:/(\\d+[\\.\\d]+))?",
        "name": "NexPlayer",
        "version": "$1"
    },
    {
        "regex": "Nightingale(?:/([\\d\\.]+))?",
        "name": "Nightingale",
        "version": "$1"
    },
    {
        "regex": "QuickTime(?:(?:(?:.+qtver=)|(?:(?: E-)?[\\./]))([\\d\\.]+))?",
        "name": "QuickTime",
        "version": "$1"
    },
    {
        "regex": "Songbird(?:/([\\d\\.]+))?",
        "name": "Songbird",
        "version": "$1"
    },
    {
        "regex": "SubStream(?:/([\\d\\.]+))?",
        "name": "SubStream",
        "version": "$1"
    },
    {
        "regex": "Sonos/([\\d\\.]+)?",
        "name": "SONOS",
        "version": "$1"
    },
    {
        "regex": "(?:Lib)?VLC(?:/([\\d\\.]+))?",
        "name": "VLC",
        "version": "$1"
    },
    {
        "regex": "Winamp(?:MPEG)?(?:/(\\d+[\\.\\d]+))?",
        "name": "Winamp",
        "version": "$1"
    },
    {
        "regex": "(?:Windows-Media-Player|NSPlayer)(?:/(\\d+[\\.\\d]+))?",
        "name": "Windows Media Player",
        "version": "$1"
    },
    {
        "regex": "XBMC(?:/([\\d\\.]+))?",
        "name": "XBMC",
        "version": "$1"
    },
    {
        "regex": "Kodi(?:/([\\d\\.]+))?",
        "name": "Kodi",
        "version": "$1"
    },
    {
        "regex": "stagefright(?:/([\\d\\.]+))?",
        "name": "Stagefright",
        "version": "$1"
    },
    {
        "regex": "GoogleChirp(?:/(\\d+[\\.\\d]+))?",
        "name": "Google Podcasts",
        "version": "$1"
    },
    {
        "regex": "Music Player Daemon (?:(\\d+[\\.\\d]+))?",
        "name": "Music Player Daemon",
        "version": "$1"
    },
    {
        "regex": "mpv (?:(\\d+[\\.\\d]+))?",
        "name": "mpv",
        "version": "$1"
    }
]
