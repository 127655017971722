{
    "Archos": {
        "regex": "Archos.*GAMEPAD([2]?)",
        "device": "console",
        "model": "Gamepad $1"
    },
    "Microsoft": {
        "regex": "Xbox",
        "device": "console",
        "models": [
            {
                "regex": "Xbox Series X",
                "model": "Xbox Series X"
            },
            {
                "regex": "Xbox One X",
                "model": "Xbox One X"
            },
            {
                "regex": "Xbox One",
                "model": "Xbox One"
            },
            {
                "regex": "XBOX_ONE_ED",
                "model": "Xbox One S"
            },
            {
                "regex": "Xbox",
                "model": "Xbox 360"
            }
        ]
    },
    "Nintendo": {
        "regex": "Nintendo (([3]?DS[i]?)|Wii[U]?|Switch)",
        "device": "console",
        "model": "$1"
    },
    "OUYA": {
        "regex": "OUYA",
        "device": "console",
        "model": "OUYA"
    },
    "Sega": {
        "regex": "Dreamcast",
        "device": "console",
        "model": "Dreamcast"
    },
    "Sony": {
        "regex": "PlayStation (3|4 Pro|4|Portable|Vita)",
        "device": "console",
        "model": "PlayStation $1"
    }
}
