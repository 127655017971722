@if (lottiePath) {
  <div class="lottie-container">
    <ng-lottie [options]="{ path: lottiePath, loop: false }"></ng-lottie>
  </div>
}
@if (infoTitle) {
  <h1 class="fut-h1 text-center">
    {{ infoTitle | transloco }}
  </h1>
}
@if (infoSubTitle) {
  <p class="info-subtitle text-center">
    {{ infoSubTitle | transloco }}
  </p>
}
