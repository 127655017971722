{
    "360": {
        "regex": "(?:180[1379]-A01|1713-A01|1707-A01|1509-A00|1603-A03|1607-A01|1503-A01)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "1807-A01(?:[);/ ]|$)",
                "model": "N7"
            },
            {
                "regex": "1803-A01(?:[);/ ]|$)",
                "model": "N7 Lite"
            },
            {
                "regex": "1809-A01(?:[);/ ]|$)",
                "model": "N7 Pro"
            },
            {
                "regex": "1713-A01(?:[);/ ]|$)",
                "model": "N6 Lite"
            },
            {
                "regex": "1707-A01(?:[);/ ]|$)",
                "model": "N6"
            },
            {
                "regex": "1801-A01(?:[);/ ]|$)",
                "model": "N6 Pro"
            },
            {
                "regex": "1509-A00(?:[);/ ]|$)",
                "model": "Q5 Plus"
            },
            {
                "regex": "1503-A01(?:[);/ ]|$)",
                "model": "N4"
            },
            {
                "regex": "1603-A03(?:[);/ ]|$)",
                "model": "N4A"
            },
            {
                "regex": "1607-A01(?:[);/ ]|$)",
                "model": "N5S"
            }
        ]
    },
    "8848": {
        "regex": "8848 (M[3-6])(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Ace": {
        "regex": "(?:BUZZ [12]|BUZZ 2|CLEVER 1|URBAN 1(?: Pro)?)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "BUZZ ([12]) Lite",
                "model": "Buzz $1 Lite"
            },
            {
                "regex": "BUZZ ([12]) Plus",
                "model": "Buzz $1 Plus"
            },
            {
                "regex": "BUZZ ([12])",
                "model": "Buzz $1"
            },
            {
                "regex": "CLEVER 1",
                "model": "Clever 1"
            },
            {
                "regex": "URBAN 1 Pro",
                "model": "Urban 1 Pro"
            },
            {
                "regex": "URBAN 1",
                "model": "Urban 1"
            }
        ]
    },
    "Tunisie Telecom": {
        "regex": "StarTrail TT(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "StarTrail by TT"
    },
    "SFR": {
        "regex": "StarShine|StarTrail|STARADDICT|StarText|StarNaute|StarXtrem|StarTab",
        "device": "smartphone",
        "models": [
            {
                "regex": "StarXtrem II(?:[);/ ]|$)",
                "model": "StarXtrem 2"
            },
            {
                "regex": "StarXtrem ?([3-6])(?:[);/ ]|$)",
                "model": "StarXtrem $1"
            },
            {
                "regex": "StarXtrem(?:[);/ ]|$)",
                "model": "StarXtrem"
            },
            {
                "regex": "StarTrail III(?:[);/ ]|$)",
                "model": "StarTrail 3"
            },
            {
                "regex": "StarTrail II(?:[);/ ]|$)",
                "model": "StarTrail 2"
            },
            {
                "regex": "StarTrail[ _]?([1-9])[ _]([34])G(?:[);/ ]|$)",
                "model": "StarTrail $1 $2G"
            },
            {
                "regex": "StarTrail[ _]?([1-9])(?:[);/ ]|$)",
                "model": "StarTrail $1"
            },
            {
                "regex": "StarTrail(?:[);/ ]|$)",
                "model": "StarTrail"
            },
            {
                "regex": "StarShine II(?:[);/ ]|$)",
                "model": "StarShine 2"
            },
            {
                "regex": "StarShine(?:[);/ ]|$)",
                "model": "StarShine"
            },
            {
                "regex": "STARADDICT 4(?:[);/ ]|$)",
                "model": "Staraddict 4"
            },
            {
                "regex": "STARADDICT III(?:[);/ ]|$)",
                "model": "Staraddict 3"
            },
            {
                "regex": "STARADDICT II Plus(?:[);/ ]|$)",
                "model": "Staraddict 2 Plus"
            },
            {
                "regex": "STARADDICT II(?:[);/ ]|$)",
                "model": "Staraddict 2"
            },
            {
                "regex": "STARADDICT(?:[);/ ]|$)",
                "model": "Staraddict"
            },
            {
                "regex": "StarText II(?:[);/ ]|$)",
                "model": "StarText 2"
            },
            {
                "regex": "StarText(?:[);/ ]|$)",
                "model": "StarText"
            },
            {
                "regex": "StarNaute II(?:[);/ ]|$)",
                "model": "StarNaute 2"
            },
            {
                "regex": "StarNaute(?:[);/ ]|$)",
                "model": "StarNaute"
            },
            {
                "regex": "StarTab",
                "model": "StarTab",
                "device": "tablet"
            },
            {
                "regex": "((?:StarShine|StarTrail|STARADDICT|StarText|StarNaute|StarXtrem)[^;/]*) Build",
                "model": "$1"
            }
        ]
    },
    "HTC": {
        "regex": "HTC|Sprint (?:APA|ATP)|ADR(?!910L)[a-z0-9]+|NexusHD2|Amaze[ _]4G(?:[);/ ]|$)|(Desire|Sensation|Evo ?3D|IncredibleS|Wildfire|Butterfly)[ _]?([^;/)]+)(?: Build|\\))|(Amaze[ _]4G|(?<!BLU LIFE )One ?[XELSV\\+]+)(?:[);/ ]|$)|SPV E6[05]0|(One M8|One Mini 2|X525a|PG86100|PC36100|XV6975|PJ83100|2PYB2|2PS64|2PZC5|0PJA10|0PJA2|HTV33|0PM92|0PCV1|601HT|0PAJ5|HTL2[23]|HTV3[12]|2PQ93|X2-HT|EXODUS 1|ISW13HT)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "2PZC5(?:[);/ ]|$)",
                "model": "U11"
            },
            {
                "regex": "XV6975(?:[);/ ]|$)",
                "model": "Imagio"
            },
            {
                "regex": "PG86100(?:[);/ ]|$)",
                "model": "Evo 3G"
            },
            {
                "regex": "PC36100(?:[);/ ]|$)",
                "model": "Evo 4G"
            },
            {
                "regex": "(?:HTV33|601HT)(?:[);/ ]|$)",
                "model": "U11"
            },
            {
                "regex": "(?:801e|802[wdt])",
                "model": "One"
            },
            {
                "regex": "PJ83100(?:[);/ ]|$)",
                "model": "One X"
            },
            {
                "regex": "One Mini 2(?:[);/ ]|$)",
                "model": "One Mini 2"
            },
            {
                "regex": "X2-HT(?:[);/ ]|$)",
                "model": "One X2"
            },
            {
                "regex": "ISW13HT(?:[);/ ]|$)",
                "model": "J"
            },
            {
                "regex": "HTL22(?:[);/ ]|$)",
                "model": "J One"
            },
            {
                "regex": "HTL23(?:[);/ ]|$)",
                "model": "J Butterfly"
            },
            {
                "regex": "(?:0PJA2|0PJA10)(?:[);/ ]|$)",
                "model": "One M9"
            },
            {
                "regex": "X525a(?:[);/ ]|$)",
                "model": "One X+"
            },
            {
                "regex": "2PQ93(?:[);/ ]|$)",
                "model": "One A9"
            },
            {
                "regex": "ADR6300",
                "model": "Droid Incredible"
            },
            {
                "regex": "ADR6400L",
                "model": "ThunderBolt"
            },
            {
                "regex": "ADR6410LRA",
                "model": "Droid Incredible 3"
            },
            {
                "regex": "SPV E600",
                "model": "Excalibur"
            },
            {
                "regex": "SPV E650",
                "model": "Vox"
            },
            {
                "regex": "2PYB2",
                "model": "Bolt"
            },
            {
                "regex": "2PS64",
                "model": "10"
            },
            {
                "regex": "0PM92(?:[);/ ]|$)",
                "model": "Desire 626S"
            },
            {
                "regex": "0PCV1(?:[);/ ]|$)",
                "model": "Desire 510"
            },
            {
                "regex": "608t(?:[);/ ]|$)",
                "model": "Desire 608t"
            },
            {
                "regex": "EXODUS 1(?:[);/ ]|$)",
                "model": "Exodus 1"
            },
            {
                "regex": "NexusHD2",
                "model": "HD2"
            },
            {
                "regex": "HTC[ _\\-]P715a",
                "device": "tablet",
                "model": "P715a"
            },
            {
                "regex": "HTC[ _\\-]Flyer Build",
                "device": "tablet",
                "model": "Flyer"
            },
            {
                "regex": "HTC[ _\\-]Flyer[ _\\-]([\\w]{1,5})",
                "device": "tablet",
                "model": "Flyer $1"
            },
            {
                "regex": "HTC[ _\\-]One[ _\\-]max(?:[);/ ]|$)",
                "device": "phablet",
                "model": "One max"
            },
            {
                "regex": "HTC[ _]([^/;]+) [0-9]+(?:\\.[0-9]+)+ Build",
                "model": "$1"
            },
            {
                "regex": "HTC[ _]([^/;]+) Build",
                "model": "$1"
            },
            {
                "regex": "HTC[ _]([a-z0-9]+[ _\\-]?(?:[a-z0-9_+\\-])*)",
                "model": "$1"
            },
            {
                "regex": "USCCHTC(\\d+)",
                "model": "$1"
            },
            {
                "regex": "Sprint (ATP.*) Build",
                "device": "tablet",
                "model": "$1 (Sprint)"
            },
            {
                "regex": "Sprint (APA.*) Build",
                "model": "$1 (Sprint)"
            },
            {
                "regex": "(?:HTC-)?HTC(?:[\\-/ ])?([a-z0-9\\-_]+)",
                "model": "$1"
            },
            {
                "regex": "HTC;(?: )?([a-z0-9 ]+)",
                "model": "$1"
            },
            {
                "regex": "(Desire|Sensation|Evo ?3D|IncredibleS|Wildfire|Butterfly)[ _]?([^;/)]+)(?: Build|\\))",
                "model": "$1 $2"
            },
            {
                "regex": "(Amaze[ _]4G|One ?[XELSV\\+]*) Build",
                "model": "$1"
            },
            {
                "regex": "(ADR[^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "(ADR[a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "(One M8)",
                "model": "$1"
            },
            {
                "regex": "0PAJ5(?:[);/ ]|$)",
                "model": "One E8"
            },
            {
                "regex": "HTV32(?:[);/ ]|$)",
                "model": "10"
            },
            {
                "regex": "HTV31(?:[);/ ]|$)",
                "model": "J Butterfly"
            }
        ]
    },
    "Microsoft": {
        "regex": "KIN\\.(One|Two)|RM-(?:1010|1031|106[57]|107[23467]|1089|109[02]|1096|1099|1109|111[348]|1127|1141|115[24]|994)|Microsoft; Lumia|Lumia (?:1530|1320|950|850|650|640|550|535|435)|Corporation/Surface",
        "device": "smartphone",
        "models": [
            {
                "regex": "KIN\\.(One|Two)",
                "device": "feature phone",
                "model": "Kin $1"
            },
            {
                "regex": "RM-1099|Lumia 430",
                "model": "Lumia 430"
            },
            {
                "regex": "RM-1114|Lumia 435",
                "model": "Lumia 435"
            },
            {
                "regex": "RM-1031|Lumia 532",
                "model": "Lumia 532"
            },
            {
                "regex": "Lumia 535 Dual SIM",
                "model": "Lumia 535 Dual SIM"
            },
            {
                "regex": "RM-109[02]|RM-1089|Lumia 535",
                "model": "Lumia 535"
            },
            {
                "regex": "RM-1141|Lumia 540",
                "model": "Lumia 540"
            },
            {
                "regex": "RM-1127|Lumia 550",
                "model": "Lumia 550"
            },
            {
                "regex": "RM-1010",
                "model": "Lumia 638"
            },
            {
                "regex": "Lumia 640 XL LTE",
                "model": "Lumia 640 XL LTE",
                "device": "phablet"
            },
            {
                "regex": "RM-106[57]|RM-1096|Lumia 640 XL",
                "model": "Lumia 640 XL",
                "device": "phablet"
            },
            {
                "regex": "Lumia 640 LTE Dual SIM",
                "model": "Lumia 640 LTE Dual SIM"
            },
            {
                "regex": "Lumia 640 LTE",
                "model": "Lumia 640 LTE"
            },
            {
                "regex": "Lumia 640 Dual SIM",
                "model": "Lumia 640 Dual SIM"
            },
            {
                "regex": "RM-1109|RM-1113|RM-107[23467]|Lumia 640",
                "model": "Lumia 640"
            },
            {
                "regex": "RM-1154|Lumia 650",
                "model": "Lumia 650"
            },
            {
                "regex": "RM-1152",
                "model": "Lumia 650 SS"
            },
            {
                "regex": "Lumia 850",
                "model": "Lumia 850"
            },
            {
                "regex": "Lumia 950 XL Dual SIM",
                "model": "Lumia 950 XL Dual SIM",
                "device": "phablet"
            },
            {
                "regex": "Lumia 950 XL",
                "model": "Lumia 950 XL",
                "device": "phablet"
            },
            {
                "regex": "Lumia 950 Dual SIM",
                "model": "Lumia 950 Dual SIM"
            },
            {
                "regex": "Lumia 950|RM-1118",
                "model": "Lumia 950"
            },
            {
                "regex": "RM-994|Lumia 1320",
                "model": "Lumia 1320"
            },
            {
                "regex": "Lumia 1530",
                "model": "Lumia 1530"
            },
            {
                "regex": "Surface Book 3",
                "device": "desktop",
                "model": "Surface Book 3"
            },
            {
                "regex": "Surface Pro( [34])?",
                "device": "desktop",
                "model": "Surface Pro$1"
            },
            {
                "regex": "Microsoft; Lumia ([^;/)]+)(?:[;/)]|$)",
                "model": "Lumia $1"
            }
        ]
    },
    "Nokia": {
        "regex": "Nokia(?!Browser|Webview|; GT-I8750)|Lumia|Maemo RX|portalmmm/2\\.0 N7|portalmmm/2\\.0 NK|nok[0-9]+|Symbian.*\\s([a-z0-9]+)$|RX-51 N900|TA-[0-9]{4}|ARM; 909",
        "device": "smartphone",
        "models": [
            {
                "regex": "TA-10(07|23|29|35)",
                "model": "2"
            },
            {
                "regex": "TA-10(20|28|32|38)",
                "model": "3"
            },
            {
                "regex": "TA-10(24|27|44|53)",
                "model": "5"
            },
            {
                "regex": "TA-10(00|03|21|25|33|39|54)",
                "model": "6"
            },
            {
                "regex": "TA-1041",
                "model": "7"
            },
            {
                "regex": "TA-10(04|12|52)",
                "model": "8"
            },
            {
                "regex": "Nokia 8\\.1",
                "model": "8.1"
            },
            {
                "regex": "Nokia 8 Sirocco",
                "model": "8 Sirocco"
            },
            {
                "regex": "Nokia 7\\.1",
                "model": "7.1"
            },
            {
                "regex": "Nokia 7 plus",
                "model": "7 plus"
            },
            {
                "regex": "Nokia 6\\.1 Plus",
                "model": "6.1 Plus"
            },
            {
                "regex": "Nokia 6\\.1",
                "model": "6.1"
            },
            {
                "regex": "Nokia 5\\.1 Plus",
                "model": "5.1 Plus"
            },
            {
                "regex": "Nokia 5\\.1",
                "model": "5.1"
            },
            {
                "regex": "Nokia 3\\.1 Plus",
                "model": "3.1 Plus"
            },
            {
                "regex": "Nokia 3\\.1",
                "model": "3.1"
            },
            {
                "regex": "Nokia 2\\.1",
                "model": "2.1"
            },
            {
                "regex": "Nokia 1 Plus",
                "model": "1 Plus"
            },
            {
                "regex": "Nokia 1",
                "model": "1"
            },
            {
                "regex": "RX-51 N900",
                "model": "N900"
            },
            {
                "regex": "(?: )?(Nokia500|nokiaasha500(?:dualsim)?)(?: Build|[_);/])",
                "model": "Asha 500"
            },
            {
                "regex": "Nokia5130c(?:-2)?",
                "model": "5130 XpressMusic"
            },
            {
                "regex": "Nokia5230",
                "model": "Nuron"
            },
            {
                "regex": "Nokia5233",
                "model": "5233"
            },
            {
                "regex": "Nokia5800d-1",
                "model": "5800 XpressMusic"
            },
            {
                "regex": "Nokia6210Navigator",
                "model": "Navigator"
            },
            {
                "regex": "Nokia8800e?",
                "model": "Sapphire Arte"
            },
            {
                "regex": "NOKIA-RH-17",
                "model": "2280"
            },
            {
                "regex": "NOKIA-RH-27",
                "model": "6225"
            },
            {
                "regex": "NOKIA-RH-34",
                "model": "6585"
            },
            {
                "regex": "NOKIA-RH-48",
                "model": "3105"
            },
            {
                "regex": "NOKIA-RM-11",
                "model": "3205"
            },
            {
                "regex": "Nokia808 PureView",
                "model": "808 PureView"
            },
            {
                "regex": "Nokia ?([0-9]{4})c(?:-)?",
                "model": "$1 Classic"
            },
            {
                "regex": "Nokia ?([0-9]{4})s(?:-)?",
                "model": "$1 Slide"
            },
            {
                "regex": "Nokia ?([0-9]{4})",
                "model": "$1"
            },
            {
                "regex": "(?: )?Nokia([235][0-9]{2})(?: Build|[_);/])",
                "model": "Asha $1"
            },
            {
                "regex": "Nokia([CEX]-?[0-9]{1,2}i?)-[0-9]{1,2}u?(?: Build|[_);/])",
                "model": "$1"
            },
            {
                "regex": "Nokia;? 520T",
                "model": "Lumia 520T"
            },
            {
                "regex": "RM-91[45]",
                "model": "Lumia 520"
            },
            {
                "regex": "RM-997|Nokia 526",
                "model": "Lumia 526"
            },
            {
                "regex": "RM-846",
                "model": "Lumia 620"
            },
            {
                "regex": "RM-97[68]",
                "model": "Lumia 630"
            },
            {
                "regex": "RM-97[45]",
                "model": "Lumia 635"
            },
            {
                "regex": "RM-1027",
                "model": "Lumia 636"
            },
            {
                "regex": "NOKIA;? 710",
                "model": "Lumia 710"
            },
            {
                "regex": "Nokia;? 720T",
                "model": "Lumia 720T"
            },
            {
                "regex": "RM-885",
                "model": "Lumia 720"
            },
            {
                "regex": "Lumia 730",
                "model": "Lumia 730"
            },
            {
                "regex": "RM-103[89]",
                "model": "Lumia 735"
            },
            {
                "regex": "Nokia;? 800C",
                "model": "Lumia 800C"
            },
            {
                "regex": "Nokia;? 800",
                "model": "Lumia 800"
            },
            {
                "regex": "Nokia 820",
                "model": "Lumia 820"
            },
            {
                "regex": "RM-984",
                "model": "Lumia 830"
            },
            {
                "regex": "Nokia;? 900",
                "model": "Lumia 900"
            },
            {
                "regex": "(RM-82[12]|Nokia;? 920)(?: Build|[_);/])",
                "model": "Lumia 920"
            },
            {
                "regex": "(RM-89[23]|RM-910|Nokia;? 925)(?: Build|[a_);/])",
                "model": "Lumia 925"
            },
            {
                "regex": "Lumia 929",
                "model": "Lumia Icon"
            },
            {
                "regex": "RM-1045",
                "model": "Lumia 930"
            },
            {
                "regex": "(ARM; 909|NOKIA;? 909|NOKIA; id300)(?: Build|[_);/])",
                "model": "Lumia 1020"
            },
            {
                "regex": "NOKIA;? 1520\\.1",
                "model": "Lumia 1520.1"
            },
            {
                "regex": "Nokia ([A-Za-z0-9\\. ]+)(?: Build|\\))",
                "model": "$1"
            },
            {
                "regex": "Nokia(N[0-9]+)",
                "model": "$1"
            },
            {
                "regex": "Nokia-([a-z0-9]+)",
                "model": "N$1"
            },
            {
                "regex": "NOKIA; (?!Qt;)([a-z0-9\\- ]+)",
                "model": "$1"
            },
            {
                "regex": "NOKIA[ _]?([a-z0-9\\-]+)",
                "model": "$1"
            },
            {
                "regex": "NOKIA/([a-z0-9 ]+)",
                "model": "$1"
            },
            {
                "regex": "(Lumia [a-z0-9\\-]+ XL)",
                "device": "phablet",
                "model": "$1"
            },
            {
                "regex": "(Lumia [a-z0-9\\-]+)",
                "model": "$1"
            },
            {
                "regex": "Maemo RX-51 ([a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "Maemo RX-34",
                "model": "N800"
            },
            {
                "regex": "NokiaInternal|Nokia-WAP-Toolkit|Nokia-MIT-Browser|Nokia Mobile|Nokia Browser|Nokia/Series",
                "model": ""
            },
            {
                "regex": "portalmmm/2\\.0 (N7[37]|NK[a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "nok([0-9]+)",
                "model": "$1"
            },
            {
                "regex": "Symbian.*\\s([a-z0-9]+)$",
                "device": "feature phone",
                "model": "$1"
            }
        ]
    },
    "CnM": {
        "regex": "CnM",
        "device": "tablet",
        "models": [
            {
                "regex": "CnM[ \\-](?:Touchpad|TP)[ \\-]([0-9\\.]+)",
                "model": "Touchpad $1"
            }
        ]
    },
    "RIM": {
        "regex": "BB10;|BlackBerry|rim[0-9]+|PlayBook|STV100-[1234]|STH100-[12]|BBA100-[12]|BBB100-[1234567]|BBD100-[126]|BB[EF]100-[123456789]|BB[CGH]100-1",
        "device": "smartphone",
        "models": [
            {
                "regex": "BBA100-[12]",
                "model": "BlackBerry DTEK60"
            },
            {
                "regex": "BBB100-[1234567]",
                "model": "KEYone"
            },
            {
                "regex": "BBC100-1",
                "model": "Aurora"
            },
            {
                "regex": "BBD100-[126]",
                "model": "Motion"
            },
            {
                "regex": "BBE100-[123456789]",
                "model": "KEY2 LE"
            },
            {
                "regex": "BBF100-[1234567]",
                "model": "KEY2"
            },
            {
                "regex": "BBF100-8",
                "model": "KEY2 Silver"
            },
            {
                "regex": "BBF100-9",
                "model": "KEY2 Black"
            },
            {
                "regex": "BBG100-1",
                "model": "Evolve"
            },
            {
                "regex": "BBH100-1",
                "model": "Evolve X"
            },
            {
                "regex": "STV100-[1234]",
                "model": "BlackBerry Priv"
            },
            {
                "regex": "STH100-[12]",
                "model": "BlackBerry DTEK50"
            },
            {
                "regex": "BB10; ([a-z0-9\\- ]+)\\)",
                "model": "BlackBerry $1"
            },
            {
                "regex": "PlayBook.+RIM Tablet OS",
                "model": "BlackBerry Playbook",
                "device": "tablet"
            },
            {
                "regex": "BlackBerry(?: )?([a-z0-9]+)",
                "model": "BlackBerry $1"
            },
            {
                "regex": "rim([0-9]+)",
                "model": "BlackBerry $1"
            },
            {
                "regex": "9000",
                "model": "Bold 9000"
            },
            {
                "regex": "9300",
                "model": "Curve 3G 9300"
            },
            {
                "regex": "(8520|8530|9220|9320|9360)",
                "model": "Curve $1"
            },
            {
                "regex": "9630",
                "model": "Tour 9630"
            },
            {
                "regex": "9720",
                "model": "9720"
            },
            {
                "regex": "(9800|9860)",
                "model": "Torch $1"
            },
            {
                "regex": "BlackBerry",
                "model": "BlackBerry"
            }
        ]
    },
    "CHIA": {
        "regex": "(SVEGLIO[ _][QH]1|TREO_TAB8G|GTA7WF)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "SVEGLIO[ _]([QH]1)",
                "model": "Sveglio $1"
            },
            {
                "regex": "TREO_TAB8G",
                "device": "tablet",
                "model": "TREO TAB8G"
            },
            {
                "regex": "GTA7WF",
                "device": "tablet",
                "model": "GTA7WF"
            }
        ]
    },
    "Palm": {
        "regex": "(?:Pre|Pixi)/(\\d+)\\.(\\d+)|Palm|Treo|Xiino",
        "device": "smartphone",
        "models": [
            {
                "regex": "((?:Pre|Pixi))/(\\d+\\.\\d+)",
                "model": "$1 $2"
            },
            {
                "regex": "Palm(?:[ \\-])?((?!OS|Source|scape)[a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "Treo([a-z0-9]+)",
                "model": "Treo $1"
            },
            {
                "regex": "Tungsten",
                "model": "Tungsten"
            },
            {
                "regex": "Xiino|Palmscape",
                "model": ""
            }
        ]
    },
    "HP": {
        "regex": "Hewlett-Packard|HP/HP|HP EliteBook 820|TouchPad/\\d+\\.\\d+|hp-tablet|HP ?iPAQ|webOS.*P160U|HP (?:Pro )?Slate|HP [78]|HP 10 Plus|Compaq [7|8]|HP; [^;/)]+",
        "device": "smartphone",
        "models": [
            {
                "regex": "HP Slate 6 Voice Tab",
                "model": "Slate 6 VoiceTab",
                "device": "phablet"
            },
            {
                "regex": "HP 10 Plus",
                "model": "10 Plus",
                "device": "tablet"
            },
            {
                "regex": "HP ([78][^)/;]*)(?: Build|\\))",
                "model": "Slate $1",
                "device": "tablet"
            },
            {
                "regex": "Compaq ([78][^/;]*) Build",
                "model": "Compaq $1",
                "device": "tablet"
            },
            {
                "regex": "HP Pro Slate 8",
                "model": "Pro Slate 8",
                "device": "tablet"
            },
            {
                "regex": "HP Slate ?(.+) Build",
                "model": "Slate $1",
                "device": "tablet"
            },
            {
                "regex": "HP Slate ?([0-9]+)",
                "model": "Slate $1",
                "device": "tablet"
            },
            {
                "regex": "TouchPad/(\\d+\\.\\d+)|hp-tablet",
                "model": "TouchPad",
                "device": "tablet"
            },
            {
                "regex": "HP; ([^;/)]+)",
                "model": "$1"
            },
            {
                "regex": "HP ?iPAQ ?([a-z0-9]+)",
                "model": "iPAQ $1"
            },
            {
                "regex": "webOS.*(P160U)",
                "model": "Veer"
            },
            {
                "regex": "HP ProBook (6560b)",
                "device": "desktop",
                "model": "ProBook $1"
            },
            {
                "regex": "HP EliteBook 820 G2",
                "device": "desktop",
                "model": "EliteBook 820 G2"
            },
            {
                "regex": "Z240 Tower Workstation",
                "device": "desktop",
                "model": "Z240 Tower Workstation"
            }
        ]
    },
    "TiPhone": {
        "regex": "TiPhone ?([a-z0-9]+)",
        "device": "smartphone",
        "model": "$1"
    },
    "Apple": {
        "regex": "(?:iTunes-)?Apple[ _]?TV|Apple/|(?:Apple-|iTunes-)?(?<!like )(?:iPad|iPhone)|iPh[0-9],[0-9]|CFNetwork|HomePod|Watch ?OS|Watch1,[12]|MDCR_Mac|ICRU_iP(?:ad|hone)",
        "models": [
            {
                "regex": "HomePod",
                "device": "smart speaker",
                "model": "HomePod"
            },
            {
                "regex": "Watch1,1",
                "device": "wearable",
                "model": "Watch 38mm"
            },
            {
                "regex": "Watch1,2",
                "device": "wearable",
                "model": "Watch 42mm"
            },
            {
                "regex": "Watch2,6",
                "device": "wearable",
                "model": "Watch Series 1 38mm"
            },
            {
                "regex": "Watch2,7",
                "device": "wearable",
                "model": "Watch Series 1 42mm"
            },
            {
                "regex": "Watch2,3",
                "device": "wearable",
                "model": "Watch Series 2 38mm"
            },
            {
                "regex": "Watch2,4",
                "device": "wearable",
                "model": "Watch Series 2 42mm"
            },
            {
                "regex": "Watch3,[13]",
                "device": "wearable",
                "model": "Watch Series 3 38mm"
            },
            {
                "regex": "Watch3,[24]",
                "device": "wearable",
                "model": "Watch Series 3 42mm"
            },
            {
                "regex": "Watch5,(?:9|11)",
                "device": "wearable",
                "model": "Watch SE 40mm"
            },
            {
                "regex": "Watch5,1[02]",
                "device": "wearable",
                "model": "Watch SE 44mm"
            },
            {
                "regex": "Watch([456]),[13]",
                "device": "wearable",
                "model": "Watch Series $1 40mm"
            },
            {
                "regex": "Watch([456]),[24]",
                "device": "wearable",
                "model": "Watch Series $1 44mm"
            },
            {
                "regex": "(?:.+_)?MacBook7(?:%2C|,)1",
                "device": "desktop",
                "model": "MacBook 13\" (2010)"
            },
            {
                "regex": "(?:.+_)?MacPro5(?:%2C|,)1",
                "device": "desktop",
                "model": "Mac Pro (2010-2012)"
            },
            {
                "regex": "(?:.+_)?iMac12(?:%2C|,)1",
                "device": "desktop",
                "model": "iMac 21.5\" (2011)"
            },
            {
                "regex": "(?:.+_)?iMac17(?:%2C|,)1",
                "device": "desktop",
                "model": "iMac 27\" (2015)"
            },
            {
                "regex": "(?:.+_)?iMac19(?:%2C|,)2",
                "device": "desktop",
                "model": "iMac 21.5\" (2019)"
            },
            {
                "regex": "(?:.+_)?iMac19(?:%2C|,)1",
                "device": "desktop",
                "model": "iMac 27\" (2019)"
            },
            {
                "regex": "(?:.+_)?iMac20(?:%2C|,)[12]",
                "device": "desktop",
                "model": "iMac 27\" (2020)"
            },
            {
                "regex": "(?:.+_)?iMac14(?:%2C|,)2",
                "device": "desktop",
                "model": "iMac 27\" (2013)"
            },
            {
                "regex": "(?:.+_)?iMac14(?:%2C|,)4",
                "device": "desktop",
                "model": "iMac 21.5\" (2014)"
            },
            {
                "regex": "(?:.+_)?iMac14(?:%2C|,)1",
                "device": "desktop",
                "model": "iMac 21.5\" (2013)"
            },
            {
                "regex": "(?:.+_)?MacBook6(?:%2C|,)1",
                "device": "desktop",
                "model": "MacBook 13\" (2009)"
            },
            {
                "regex": "(?:.+_)?MacBook8(?:%2C|,)1",
                "device": "desktop",
                "model": "MacBook 12\" (2015)"
            },
            {
                "regex": "(?:.+_)?MacBook9(?:%2C|,)1",
                "device": "desktop",
                "model": "MacBook 12\" (2016)"
            },
            {
                "regex": "(?:.+_)?iMac10(?:%2C|,)1",
                "device": "desktop",
                "model": "iMac (2009)"
            },
            {
                "regex": "(?:.+_)?MacBookAir2(?:%2C|,)1",
                "device": "desktop",
                "model": "MacBook Air (2009)"
            },
            {
                "regex": "(?:.+_)?MacBookAir3(?:%2C|,)1",
                "device": "desktop",
                "model": "MacBook Air 11\" (2010)"
            },
            {
                "regex": "(?:.+_)?MacBookAir3(?:%2C|,)2",
                "device": "desktop",
                "model": "MacBook Air 13\" (2010)"
            },
            {
                "regex": "(?:.+_)?MacBookAir4(?:%2C|,)1",
                "device": "desktop",
                "model": "MacBook Air 11\" (2011)"
            },
            {
                "regex": "(?:.+_)?MacBookAir4(?:%2C|,)2",
                "device": "desktop",
                "model": "MacBook Air 13\" (2011)"
            },
            {
                "regex": "(?:.+_)?MacBookAir5(?:%2C|,)1",
                "device": "desktop",
                "model": "MacBook Air 11\" (2012)"
            },
            {
                "regex": "(?:.+_)?MacBookAir5(?:%2C|,)2",
                "device": "desktop",
                "model": "MacBook Air 13\" (2012)"
            },
            {
                "regex": "(?:.+_)?MacBookAir6(?:%2C|,)1",
                "device": "desktop",
                "model": "MacBook Air 11\" (2013-2014)"
            },
            {
                "regex": "(?:.+_)?MacBookAir6(?:%2C|,)2",
                "device": "desktop",
                "model": "MacBook Air 13\" (2013-2014)"
            },
            {
                "regex": "(?:.+_)?MacBookAir7(?:%2C|,)1",
                "device": "desktop",
                "model": "MacBook Air 11\" (2015)"
            },
            {
                "regex": "(?:.+_)?MacBookAir7(?:%2C|,)2",
                "device": "desktop",
                "model": "MacBook Air 13\" (2015-2017)"
            },
            {
                "regex": "(?:.+_)?MacBookAir8(?:%2C|,)1",
                "device": "desktop",
                "model": "MacBook Air 13\" (2018)"
            },
            {
                "regex": "(?:.+_)?MacBookAir8(?:%2C|,)2",
                "device": "desktop",
                "model": "MacBook Air 13\" (2019)"
            },
            {
                "regex": "(?:.+_)?MacBookAir9(?:%2C|,)1",
                "device": "desktop",
                "model": "MacBook Air 13\" (2020)"
            },
            {
                "regex": "(?:.+_)?MacBookAir10(?:%2C|,)1",
                "device": "desktop",
                "model": "MacBook Air 13\" (2020)"
            },
            {
                "regex": "(?:.+_)?MacBookPro1(?:%2C|,)1",
                "device": "desktop",
                "model": "MacBook Pro 15\" (2006)"
            },
            {
                "regex": "(?:.+_)?MacBookPro1(?:%2C|,)2",
                "device": "desktop",
                "model": "MacBook Pro 17\" (2006)"
            },
            {
                "regex": "(?:.+_)?MacBookPro2(?:%2C|,)1",
                "device": "desktop",
                "model": "MacBook Pro 17\" (2007)"
            },
            {
                "regex": "(?:.+_)?MacBookPro2(?:%2C|,)2",
                "device": "desktop",
                "model": "MacBook Pro 15\" (2007)"
            },
            {
                "regex": "(?:.+_)?MacBookPro[34](?:%2C|,)1",
                "device": "desktop",
                "model": "MacBook Pro (2008)"
            },
            {
                "regex": "(?:.+_)?MacBookPro5(?:%2C|,)1",
                "device": "desktop",
                "model": "MacBook Pro 15\" (2008)"
            },
            {
                "regex": "(?:.+_)?MacBookPro5(?:%2C|,)2",
                "device": "desktop",
                "model": "MacBook Pro 17\" (2009)"
            },
            {
                "regex": "(?:.+_)?MacBookPro5(?:%2C|,)[34]",
                "device": "desktop",
                "model": "MacBook Pro 15\" (2009)"
            },
            {
                "regex": "(?:.+_)?MacBookPro5(?:%2C|,)5",
                "device": "desktop",
                "model": "MacBook Pro 13\" (2009)"
            },
            {
                "regex": "(?:.+_)?MacBookPro6(?:%2C|,)1",
                "device": "desktop",
                "model": "MacBook Pro 17\" (2010)"
            },
            {
                "regex": "(?:.+_)?MacBookPro6(?:%2C|,)2",
                "device": "desktop",
                "model": "MacBook Pro 15\" (2010)"
            },
            {
                "regex": "(?:.+_)?MacBookPro7(?:%2C|,)1",
                "device": "desktop",
                "model": "MacBook Pro 13\" (2010)"
            },
            {
                "regex": "(?:.+_)?MacBookPro8(?:%2C|,)1",
                "device": "desktop",
                "model": "MacBook Pro 13\" (2011)"
            },
            {
                "regex": "(?:.+_)?MacBookPro8(?:%2C|,)2",
                "device": "desktop",
                "model": "MacBook Pro 15\" (2011)"
            },
            {
                "regex": "(?:.+_)?MacBookPro8(?:%2C|,)3",
                "device": "desktop",
                "model": "MacBook Pro 17\" (2011)"
            },
            {
                "regex": "(?:.+_)?MacBookPro9(?:%2C|,)1",
                "device": "desktop",
                "model": "MacBook Pro 15\" (2012)"
            },
            {
                "regex": "(?:.+_)?MacBookPro9(?:%2C|,)2",
                "device": "desktop",
                "model": "MacBook Pro 13\" (2012)"
            },
            {
                "regex": "(?:.+_)?MacBookPro10(?:%2C|,)1",
                "device": "desktop",
                "model": "MacBook Pro 15\" (2012-2013)"
            },
            {
                "regex": "(?:.+_)?MacBookPro10(?:%2C|,)2",
                "device": "desktop",
                "model": "MacBook Pro 13\" (2012-2013)"
            },
            {
                "regex": "(?:.+_)?MacBookPro11(?:%2C|,)1",
                "device": "desktop",
                "model": "MacBook Pro 13\" (2013-2014)"
            },
            {
                "regex": "(?:.+_)?MacBookPro11(?:%2C|,)[23]",
                "device": "desktop",
                "model": "MacBook Pro 15\" (2013-2014)"
            },
            {
                "regex": "(?:.+_)?MacBookPro11(?:%2C|,)[45]",
                "device": "desktop",
                "model": "MacBook Pro 15\" (2015)"
            },
            {
                "regex": "(?:.+_)?MacBookPro12(?:%2C|,)1",
                "device": "desktop",
                "model": "MacBook Pro 13\" (2015)"
            },
            {
                "regex": "(?:.+_)?MacBookPro13(?:%2C|,)[12]",
                "device": "desktop",
                "model": "MacBook Pro 13\" (2016)"
            },
            {
                "regex": "(?:.+_)?MacBookPro13(?:%2C|,)3",
                "device": "desktop",
                "model": "MacBook Pro 15\" (2016)"
            },
            {
                "regex": "(?:.+_)?MacBookPro14(?:%2C|,)[12]",
                "device": "desktop",
                "model": "MacBook Pro 13\" (2017)"
            },
            {
                "regex": "(?:.+_)?MacBookPro14(?:%2C|,)3",
                "device": "desktop",
                "model": "MacBook Pro 15\" (2017)"
            },
            {
                "regex": "(?:.+_)?MacBookPro15(?:%2C|,)1",
                "device": "desktop",
                "model": "MacBook Pro 15\" (2018-2019)"
            },
            {
                "regex": "(?:.+_)?MacBookPro15(?:%2C|,)2",
                "device": "desktop",
                "model": "MacBook Pro 13\" (2018-2019)"
            },
            {
                "regex": "(?:.+_)?MacBookPro15(?:%2C|,)3",
                "device": "desktop",
                "model": "MacBook Pro 15\" (2019)"
            },
            {
                "regex": "(?:.+_)?MacBookPro15(?:%2C|,)4",
                "device": "desktop",
                "model": "MacBook Pro 13\" (2019)"
            },
            {
                "regex": "(?:.+_)?MacBookPro16(?:%2C|,)[14]",
                "device": "desktop",
                "model": "MacBook Pro 16\" (2019)"
            },
            {
                "regex": "(?:.+_)?MacBookPro16(?:%2C|,)[23]",
                "device": "desktop",
                "model": "MacBook Pro 13\" (2020)"
            },
            {
                "regex": "(?:.+_)?MacBookPro17(?:%2C|,)1",
                "device": "desktop",
                "model": "MacBook Pro 13\" (2020)"
            },
            {
                "regex": "(?:.+_)?Macmini8(?:%2C|,)1",
                "device": "desktop",
                "model": "Mac Mini (2018)"
            },
            {
                "regex": "(?:.+_)?Macmini9(?:%2C|,)1",
                "device": "desktop",
                "model": "Mac Mini (2020)"
            },
            {
                "regex": "(?:.+_)?Macmini7(?:%2C|,)1",
                "device": "desktop",
                "model": "Mac Mini (2014)"
            },
            {
                "regex": "(?:Apple-|.+_)?iPh(?:one)?1[C,_]1",
                "model": "iPhone",
                "device": "smartphone"
            },
            {
                "regex": "(?:Apple-|.+_)?iPh(?:one)?1[C,_]2",
                "model": "iPhone 3G",
                "device": "smartphone"
            },
            {
                "regex": "(?:Apple-|.+_)?iPh(?:one)?2[C,_]1| 3GS\\)$",
                "model": "iPhone 3GS",
                "device": "smartphone"
            },
            {
                "regex": "(?:Apple-|.+_)?iPh(?:one)?3[C,_][123]",
                "model": "iPhone 4",
                "device": "smartphone"
            },
            {
                "regex": "(?:Apple-|.+_)?iPh(?:one)?4[C,_]1|1C2%254enohPi| 4S\\)$",
                "model": "iPhone 4S",
                "device": "smartphone"
            },
            {
                "regex": "(?:Apple-|.+_)?iPh(?:one)?5[C,_][12]| 5\\)$",
                "model": "iPhone 5",
                "device": "smartphone"
            },
            {
                "regex": "(?:Apple-|.+_)?iPh(?:one)?5[C,_][34]",
                "model": "iPhone 5C",
                "device": "smartphone"
            },
            {
                "regex": "(?:Apple-|.+_)?iPh(?:one)?6[C,_][12]| 5S\\)$",
                "model": "iPhone 5S",
                "device": "smartphone"
            },
            {
                "regex": "(?:Apple-|.+_)?iPh(?:one)?7[C,_]1|1C2%257enohPi| 6PLUS\\)$",
                "model": "iPhone 6 Plus",
                "device": "phablet"
            },
            {
                "regex": "(?:Apple-|.+_)?iPh(?:one)?7[C,_]2| 6\\)$",
                "model": "iPhone 6",
                "device": "smartphone"
            },
            {
                "regex": "(?:Apple-|.+_)?iPh(?:one)?8[C,_]2| 6SPLUS\\)$",
                "model": "iPhone 6s Plus",
                "device": "phablet"
            },
            {
                "regex": "(?:Apple-|.+_)?iPh(?:one)?8[C,_]1|iPhone 6s| 6S\\)$",
                "model": "iPhone 6s",
                "device": "smartphone"
            },
            {
                "regex": "(?:Apple-|.+_)?iPh(?:one)?8[C,_]4| SE\\)$",
                "model": "iPhone SE",
                "device": "smartphone"
            },
            {
                "regex": "(?:Apple-|.+_)?iPh(?:one)?9[C,_][13]| 7\\)$",
                "model": "iPhone 7",
                "device": "smartphone"
            },
            {
                "regex": "(?:Apple-|.+_)?iPh(?:one)?9[C,_][24]| 7PLUS\\)$",
                "model": "iPhone 7 Plus",
                "device": "phablet"
            },
            {
                "regex": "(?:Apple-|.+_)?iPh(?:one)?10[C,_][14]| 8\\)$",
                "model": "iPhone 8",
                "device": "smartphone"
            },
            {
                "regex": "(?:Apple-|.+_)?iPh(?:one)?10[C,_][25]| 8PLUS\\)$",
                "model": "iPhone 8 Plus",
                "device": "phablet"
            },
            {
                "regex": "(?:Apple-|.+_)?iPh(?:one)?10[C,_][36]|iPhone X| X\\)$",
                "model": "iPhone X",
                "device": "phablet"
            },
            {
                "regex": "(?:Apple-|.+_)?iPh(?:one)?11[C,_][2]| XS\\)$",
                "model": "iPhone XS",
                "device": "smartphone"
            },
            {
                "regex": "(?:Apple-|.+_)?iPh(?:one)?11[C,_][46]| XSMAX\\)$",
                "model": "iPhone XS Max",
                "device": "phablet"
            },
            {
                "regex": "(?:Apple-|.+_)?iPh(?:one)?11[C,_][8]| XR\\)$",
                "model": "iPhone XR",
                "device": "smartphone"
            },
            {
                "regex": "(?:Apple-|.+_)?iPh(?:one)?12[C,_][1]| 11\\)$",
                "model": "iPhone 11",
                "device": "smartphone"
            },
            {
                "regex": "(?:Apple-|.+_)?iPh(?:one)?12[C,_][3]| 11PRO\\)$",
                "model": "iPhone 11 Pro",
                "device": "phablet"
            },
            {
                "regex": "(?:Apple-|.+_)?iPh(?:one)?12[C,_][5]| 11PROMAX\\)$",
                "model": "iPhone 11 Pro Max",
                "device": "phablet"
            },
            {
                "regex": "(?:Apple-|.+_)?iPh(?:one)?12[C,_][8]",
                "model": "iPhone SE (2020)",
                "device": "phablet"
            },
            {
                "regex": "(?:Apple-|.+_)?iPh(?:one)?13[C,_][1]",
                "model": "iPhone 12 Mini",
                "device": "smartphone"
            },
            {
                "regex": "(?:Apple-|.+_)?iPh(?:one)?13[C,_][2]",
                "model": "iPhone 12",
                "device": "phablet"
            },
            {
                "regex": "(?:Apple-|.+_)?iPh(?:one)?13[C,_][3]",
                "model": "iPhone 12 Pro",
                "device": "phablet"
            },
            {
                "regex": "(?:Apple-|.+_)?iPh(?:one)?13[C,_][4]",
                "model": "iPhone 12 Pro Max",
                "device": "phablet"
            },
            {
                "regex": "(?:Apple-|.+_)?iPad1[C,_]1",
                "model": "iPad",
                "device": "tablet"
            },
            {
                "regex": "(?:Apple-|.+_)?iPad2[C,_][1234]",
                "model": "iPad 2",
                "device": "tablet"
            },
            {
                "regex": "(?:Apple-|.+_)?iPad2[C,_][567]",
                "model": "iPad Mini",
                "device": "tablet"
            },
            {
                "regex": "(?:Apple-|.+_)?iPad3[C,_][123]",
                "model": "iPad 3",
                "device": "tablet"
            },
            {
                "regex": "(?:Apple-|.+_)?iPad3[C,_][456]",
                "model": "iPad 4",
                "device": "tablet"
            },
            {
                "regex": "(?:Apple-|.+_)?iPad4[C,_][123]",
                "model": "iPad Air",
                "device": "tablet"
            },
            {
                "regex": "(?:Apple-|.+_)?iPad4[C,_][456]",
                "model": "iPad Mini 2",
                "device": "tablet"
            },
            {
                "regex": "(?:Apple-|.+_)?iPad4[C,_][789]",
                "model": "iPad Mini 3",
                "device": "tablet"
            },
            {
                "regex": "(?:Apple-|.+_)?iPad5[C,_][12]",
                "model": "iPad Mini 4",
                "device": "tablet"
            },
            {
                "regex": "(?:Apple-|.+_)?iPad5[C,_][34]",
                "model": "iPad Air 2",
                "device": "tablet"
            },
            {
                "regex": "(?:Apple-|.+_)?iPad6[C,_][34]",
                "model": "iPad Pro 9.7",
                "device": "tablet"
            },
            {
                "regex": "(?:Apple-|.+_)?iPad6[C,_][78]",
                "model": "iPad Pro 12.9",
                "device": "tablet"
            },
            {
                "regex": "(?:Apple-|.+_)?iPad6[C,_](?:11|12)",
                "model": "iPad 5 9.7",
                "device": "tablet"
            },
            {
                "regex": "(?:Apple-|.+_)?iPad7[C,_][12]",
                "model": "iPad Pro 2 12.9",
                "device": "tablet"
            },
            {
                "regex": "(?:Apple-|.+_)?iPad7[C,_][34]",
                "model": "iPad Pro 10.5",
                "device": "tablet"
            },
            {
                "regex": "(?:Apple-|.+_)?iPad7[C,_][56]",
                "model": "iPad 6 9.7",
                "device": "tablet"
            },
            {
                "regex": "(?:Apple-|.+_)?iPad7[C,_](?:11|12)",
                "model": "iPad 7 10.2",
                "device": "tablet"
            },
            {
                "regex": "(?:Apple-|.+_)?iPad8[C,_](?:9|10)",
                "model": "iPad Pro 2 11\"",
                "device": "tablet"
            },
            {
                "regex": "(?:Apple-|.+_)?iPad8[C,_]1[12]",
                "model": "iPad Pro 4 12.9\"",
                "device": "tablet"
            },
            {
                "regex": "(?:Apple-|.+_)?iPad8[C,_][1-4]",
                "model": "iPad Pro 3 11.0",
                "device": "tablet"
            },
            {
                "regex": "(?:Apple-|.+_)?iPad8[C,_][5-8]",
                "model": "iPad Pro 3 12.9",
                "device": "tablet"
            },
            {
                "regex": "(?:Apple-|.+_)?iPad11[C,_][12]",
                "model": "iPad Mini 5",
                "device": "tablet"
            },
            {
                "regex": "(?:Apple-|.+_)?iPad11[C,_][34]",
                "model": "iPad Air 3",
                "device": "tablet"
            },
            {
                "regex": "(?:Apple-|.+_)?iPad11[C,_][67]",
                "model": "iPad 8 10.2\"",
                "device": "tablet"
            },
            {
                "regex": "(?:Apple-|.+_)?iPad13[C,_][12]",
                "model": "iPad Air 4",
                "device": "tablet"
            },
            {
                "regex": "Apple-?TV2[C,_]1",
                "model": "Apple TV 2",
                "device": "tv"
            },
            {
                "regex": "Apple-?TV3[C,_][12]",
                "model": "Apple TV 3",
                "device": "tv"
            },
            {
                "regex": "Apple-?TV5[C,_]3",
                "model": "Apple TV 4",
                "device": "tv"
            },
            {
                "regex": "Apple-?TV6[C,_]2",
                "model": "Apple TV 4K",
                "device": "tv"
            },
            {
                "regex": "(?:iTunes-)?Apple[ _]?TV",
                "model": "Apple TV",
                "device": "tv"
            },
            {
                "regex": "HomePod",
                "model": "HomePod",
                "device": "smart speaker"
            },
            {
                "regex": "iTunes-iPad/[0-9]+(?:\\.[0-9]+)* \\(([^;]+);",
                "model": "iPad $1",
                "device": "tablet"
            },
            {
                "regex": "(?:Apple-|.+_)?iPad",
                "model": "iPad",
                "device": "tablet"
            },
            {
                "regex": "iTunes-iPhone/[0-9]+(?:\\.[0-9]+)* \\(([^;]+);",
                "model": "iPhone $1",
                "device": "smartphone"
            },
            {
                "regex": "(?:Apple-)?iPhone ?(3GS?|4S?|5[CS]?|6(:? Plus)?)?",
                "model": "iPhone $1",
                "device": "smartphone"
            }
        ]
    },
    "MicroMax": {
        "regex": "(?:MicroMax[ \\-\\_]?[a-z0-9]+|Q327)|4560MMX|P70221|(?:YU(?:5040|5530)|AQ5001(?: Canvas Power)?|N8216|N8301)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "AQ5001 Canvas Power",
                "model": "Canvas Power"
            },
            {
                "regex": "AQ5001",
                "model": "Canvas Juice 2"
            },
            {
                "regex": "4560MMX(?:[);/ ]|$)",
                "model": "4560MMX"
            },
            {
                "regex": "E481",
                "model": "Canvas 5"
            },
            {
                "regex": "P70221(?:[);/ ]|$)",
                "model": "Canvas Tab",
                "device": "tablet"
            },
            {
                "regex": "Q327",
                "model": "Bolt"
            },
            {
                "regex": "Q417",
                "model": "Canvas Mega"
            },
            {
                "regex": "Q402\\+",
                "model": "Bolt Pace"
            },
            {
                "regex": "YU5040(?:[);/ ]|$)",
                "model": "YU Yureka YU5040"
            },
            {
                "regex": "YU5530(?:[);/ ]|$)",
                "model": "YU Yureka S YU5530"
            },
            {
                "regex": "Q424",
                "model": "Bolt Selfie"
            },
            {
                "regex": "Q426",
                "model": "Canvas Mega 2"
            },
            {
                "regex": "N8216",
                "model": "Infinity N11"
            },
            {
                "regex": "N8301",
                "model": "Infinity N12"
            },
            {
                "regex": "MicroMax(?:[ \\-\\_])?(X291|X246|X252|X458|X312)",
                "model": "$1",
                "device": "feature phone"
            },
            {
                "regex": "MicroMax(?:[ \\-\\_])?(P[a-z0-9]+)",
                "model": "$1",
                "device": "tablet"
            },
            {
                "regex": "MicroMax(?:[ \\-\\_])?([a-z0-9]+)",
                "model": "$1"
            }
        ]
    },
    "3Q": {
        "regex": "AC0731B|AC1024C|AC7803C|BC9710AM|EL72B|LC0720C|LC0723B|LC0725B|LC0804B|LC0808B|LC0809B|LC0810C|LC0816C|LC0901D|LC1016C|MT0724B|MT0729[BD]|MT0811B|OC1020A|RC0709B|RC0710B|RC0718C|RC0719H|RC0721B|RC0722C|RC0726B|RC0734H|RC0743H|RC0817C|RC1018C|RC1019G|RC1025F|RC1301C|RC780[24]F|RC9711B|RC9712C|RC9716B|RC9717B|RC9724C|RC9726C|RC9727F|RC9730C|RC9731C|TS0807B|TS1013B|VM0711A|VM1017A|RC0813C|QS9719D|QS9718C|QS9715F|QS1023H|QS0815C|QS0730C|QS0728C|QS0717D|QS0716D|QS0715C|MT7801C|TS9708B|AC0732C|MT0739D|MT0812E|(?:3Q_)?ER71B",
        "device": "tablet",
        "models": [
            {
                "regex": "MT0724B|LC0901D|LC0816C|LC0810C|LC0809B|LC0808B|LC0804B|LC0725B|LC0723B",
                "model": "Qoo! P-Pad"
            },
            {
                "regex": "VM1017A|TS1013B|TS0807B|RC9731C|RC9730C|RC9727F|RC9726C|RC9724C|RC9717B|RC9716B|RC9712C|RC9711B|RC1301C|RC1025F|RC1019G|RC1018C|RC0817C|RC0813C|RC0743H|RC0734H|RC0726B|RC0722C|RC0721B|RC0719H|RC0718C|RC0710B|RC0709B|QS9715F|QS1023H|QS0815C|QS0730C|QS0728C|QS0717D|QS0716D|QS0715C|MT0811B|MT0739D|MT0729[BD]|LC1016C|LC0720C|BC9710AM|AC1024C|AC0731B",
                "model": "Qoo! Q-Pad"
            },
            {
                "regex": "MT7801C|QS9718C|QS9719D|VM0711A|OC1020A",
                "model": "Qoo! Surf"
            },
            {
                "regex": "TS9708B",
                "model": "Surf TS1009B"
            },
            {
                "regex": "AC7803C|AC0732C",
                "model": "Qoo! Lite"
            },
            {
                "regex": "MT0812E|RC7802F",
                "model": "Qoo! Meta"
            },
            {
                "regex": "EL72B|(?:3Q_)?ER71B",
                "model": "Qoo! Q-Book"
            },
            {
                "regex": "(RC7804F)",
                "model": "Qoo! Glaze"
            }
        ]
    },
    "4Good": {
        "regex": "4Good[ _]|S450m [43]G|S555m 4G|S501m 3G|T700i_3G|People (?:G503|G410|GT300)|Light (?:A103|B100)",
        "device": "smartphone",
        "models": [
            {
                "regex": "T700i_3G",
                "model": "T700i 3G",
                "device": "tablet"
            },
            {
                "regex": "People GT300",
                "model": "People GT300",
                "device": "tablet"
            },
            {
                "regex": "S555m[_ ]4G",
                "model": "People"
            },
            {
                "regex": "(S(?:450m|501m)[_ ][43]G|Light (?:A103|B100)|People (?:G503|G410))",
                "model": "$1"
            },
            {
                "regex": "4Good[ _]([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "4Good[ _]([^);/ ]+)",
                "model": "$1"
            }
        ]
    },
    "Acteck": {
        "regex": "BLECK",
        "device": "tablet",
        "model": "Bleck"
    },
    "Alba": {
        "regex": "Alba ?(?:57|4|10 TABLET|(?:7|10)(?:Nou|Pie))(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Alba 57",
                "model": "5.7"
            },
            {
                "regex": "Alba (4)",
                "model": "$1"
            },
            {
                "regex": "10 TABLET",
                "device": "tablet",
                "model": "10 Tablet"
            },
            {
                "regex": "Alba((?:7|10)(?:Nou|Pie))",
                "device": "tablet",
                "model": "$1"
            }
        ]
    },
    "AllCall": {
        "regex": "AllCall_|Heat[34]|Rio_X(?:[);/ ]|$)|Hot ?[125][^;/]+ Build",
        "device": "smartphone",
        "models": [
            {
                "regex": "AllCall_(Alpha|Atom|Bro|Madrid)",
                "model": "$1"
            },
            {
                "regex": "AllCall_S1_X",
                "model": "S1 X"
            },
            {
                "regex": "AllCall_S1",
                "model": "S1"
            },
            {
                "regex": "AllCall_RIO_S",
                "model": "Rio S"
            },
            {
                "regex": "AllCall_RIO_PRO",
                "model": "Rio Pro"
            },
            {
                "regex": "AllCall_RIO",
                "model": "Rio"
            },
            {
                "regex": "Heat([34])",
                "model": "Heat $1"
            },
            {
                "regex": "Rio_X(?:[);/ ]|$)",
                "model": "Rio X"
            },
            {
                "regex": "Hot ?([125][^;/]+) Build",
                "model": "Hot $1"
            }
        ]
    },
    "Bravis": {
        "regex": "Bravis[ _]|A501 Bright|A552 JOY Max|A504 Trace|A505 JOY Plus|A503 Joy|NB(?:106M|10[1-8]|751|7[145]|8[57]1|76)|NP101|NB961|NP 104 3G|B501|N1-570 Space|Atlas A551|A512 Harmony Pro|A510_Jeans_4G|A509_Jeans|N1_550_Cruiser",
        "device": "smartphone",
        "models": [
            {
                "regex": "A501 BRIGHT",
                "model": "A501 Bright"
            },
            {
                "regex": "Bravis[_ ]X500",
                "model": "Trace Pro"
            },
            {
                "regex": "Bravis[_ ]A506",
                "model": "Crystal"
            },
            {
                "regex": "Bravis[_ ]A553",
                "model": "Discovery"
            },
            {
                "regex": "BRAVIS[ _]TAU",
                "model": "Tau"
            },
            {
                "regex": "BRAVIS[ _]DELTA",
                "model": "Delta"
            },
            {
                "regex": "BRAVIS[ _]TREND",
                "model": "Trend"
            },
            {
                "regex": "B501",
                "model": "Easy"
            },
            {
                "regex": "N1-570",
                "model": "Space"
            },
            {
                "regex": "BRAVIS[ ]A554",
                "model": "A554 Grand"
            },
            {
                "regex": "BRAVIS[ ]S500",
                "model": "S500 Diamond"
            },
            {
                "regex": "A505 JOY Plus",
                "model": "A505 Joy Plus"
            },
            {
                "regex": "A552 JOY Max",
                "model": "A552 Joy Max"
            },
            {
                "regex": "Atlas A551",
                "model": "A551 Atlas"
            },
            {
                "regex": "A503 Joy",
                "model": "A503 Joy"
            },
            {
                "regex": "A504 Trace",
                "model": "A504 Trace"
            },
            {
                "regex": "A512 Harmony Pro",
                "model": "A512 Harmony Pro"
            },
            {
                "regex": "A510_Jeans_4G",
                "model": "A510 Jeans 4G"
            },
            {
                "regex": "A509_Jeans",
                "model": "A509 Jeans"
            },
            {
                "regex": "BRAVIS NEO",
                "model": "A401 Neo"
            },
            {
                "regex": "N1_550_Cruiser",
                "model": "N1-550 Cruiser"
            },
            {
                "regex": "BRAVIS OMEGA",
                "model": "Omega"
            },
            {
                "regex": "BRAVIS BIZ",
                "model": "Biz"
            },
            {
                "regex": "BRAVIS POWER",
                "model": "Power"
            },
            {
                "regex": "BRAVIS[ _]SPARK",
                "model": "Spark"
            },
            {
                "regex": "BRAVIS[ _]SLIM[ _]3G",
                "model": "Slim 3G",
                "device": "tablet"
            },
            {
                "regex": "NP 104 3G",
                "model": "NP104 3G",
                "device": "tablet"
            },
            {
                "regex": "BRAVIS NP 103",
                "model": "NP103",
                "device": "tablet"
            },
            {
                "regex": "BRAVIS NP 844",
                "model": "NP844",
                "device": "tablet"
            },
            {
                "regex": "Bravis[_ ]([^;\\)]+) Build",
                "model": "$1"
            },
            {
                "regex": "(NB(?:106M|10[1-8]|751|7[145]|8[57]1|76)|NP101|NB961)",
                "model": "$1",
                "device": "tablet"
            }
        ]
    },
    "Acer": {
        "regex": "acer|(?<!Satellite |ZTE BLADE |ImSmart |ERGO |X-Style Tab )a(?:101|110|2[10]0|211|50[10]|51[10]|70[10])(?:[);/ ]|$)|Android.*V3[67]0(?:[);/ ]|$)|Android.*Z1[23456]0 Build|Android.*Z5\\d{2} Build|Android.*T0[234678] Build|Android.*S55(?:[);/ ]|$)|(?:A1-(713(HD)?|7[23]4|830|841|840FHD|81[01])|A3-A[1234][01]|B1-(7[1235-8][01](HD)?|7[23]3|7A0|8[1235]0|A71|860A)|TPA60W|B3-(A[125]0|A3[02]|A40FHD)|E39)(?:[);/ ]|$)|S5[12]0 Build|(DA[0-9]+HQ?L|Aspire (?:V5-121|XC-704G|V5-573G)|Chromebook 14 \\(CB3-431\\)|Predator G9-793|GT-810|B1-870|B3-A4[02]|B3-A50FHD)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "DA220HQL(?:[);/ ]|$)",
                "model": "DA220HQL",
                "device": "smart display"
            },
            {
                "regex": "DA241HL(?:[);/ ]|$)",
                "model": "DA241HL",
                "device": "smart display"
            },
            {
                "regex": "(DA[0-9]+HQ?L)(?:[);/ ]|$)",
                "model": "$1",
                "device": "smart display"
            },
            {
                "regex": "A1-81[01]",
                "model": "Iconia A",
                "device": "tablet"
            },
            {
                "regex": "TPA60W",
                "model": "TPA60W",
                "device": "tablet"
            },
            {
                "regex": "A1-84(1|0FHD)",
                "model": "Iconia Tab 8",
                "device": "tablet"
            },
            {
                "regex": "A1-713(HD)?",
                "model": "Iconia Tab 7",
                "device": "tablet"
            },
            {
                "regex": "A1-830",
                "model": "Iconia A1",
                "device": "tablet"
            },
            {
                "regex": "A1-7[23]4",
                "model": "Iconia Talk S",
                "device": "tablet"
            },
            {
                "regex": "A3-A[12][01]",
                "model": "Iconia A3",
                "device": "tablet"
            },
            {
                "regex": "A3-A[34]0",
                "model": "Iconia Tab 10",
                "device": "tablet"
            },
            {
                "regex": "B1-7(?:[5678]0|30HD|A0)",
                "model": "Iconia One 7",
                "device": "tablet"
            },
            {
                "regex": "B1-7[123][01]|B1-A71",
                "model": "Iconia B1",
                "device": "tablet"
            },
            {
                "regex": "B1-7[23]3",
                "model": "Iconia Talk 7",
                "device": "tablet"
            },
            {
                "regex": "B1-8[123567]0",
                "model": "Iconia One 8",
                "device": "tablet"
            },
            {
                "regex": "B3-A([125]0|3[02]|[45]0FHD|4[02])",
                "model": "Iconia One 10",
                "device": "tablet"
            },
            {
                "regex": "A101",
                "model": "Vangogh",
                "device": "tablet"
            },
            {
                "regex": "A200",
                "model": "Picasso E",
                "device": "tablet"
            },
            {
                "regex": "A50[01]",
                "model": "Picasso",
                "device": "tablet"
            },
            {
                "regex": "Acer One 7",
                "model": "One 7",
                "device": "tablet"
            },
            {
                "regex": "GT-810",
                "model": "Predator 8",
                "device": "tablet"
            },
            {
                "regex": "E39(?:[);/ ]|$)",
                "model": "Liquid E700"
            },
            {
                "regex": "E310(?:[);/ ]|$)",
                "model": "Liquid Mini"
            },
            {
                "regex": "E320(?:-orange)?(?:[);/ ]|$)",
                "model": "Liquid Express"
            },
            {
                "regex": "E310(?:[);/ ]|$)",
                "model": "beTouch E210"
            },
            {
                "regex": "Android.*V360(?:[);/ ]|$)",
                "model": "Liquid E1 Duo"
            },
            {
                "regex": "Android.*V370(?:[);/ ]|$)",
                "model": "Liquid E2 Duo"
            },
            {
                "regex": "S510(?:[);/ ]|$)",
                "model": "Liquid S1"
            },
            {
                "regex": "S520(?:[);/ ]|$)",
                "model": "Liquid S2"
            },
            {
                "regex": "(?:Acer_)?P400(?:[);/ ]|$)",
                "model": "neoTouch P400"
            },
            {
                "regex": "(?:Acer_)?P300(?:[);/ ]|$)",
                "model": "neoTouch P300"
            },
            {
                "regex": "(?:Acer_)?S200(?:[);/ ]|$)",
                "model": "neoTouch S200"
            },
            {
                "regex": "(?:Acer_)?E100(?:[);/ ]|$)",
                "model": "beTouch E100"
            },
            {
                "regex": "Android.*Z1([234])0(?:[);/ ]|$)",
                "model": "Liquid Z$1"
            },
            {
                "regex": "Android.*Z110(?:[);/ ]|$)",
                "model": "Liquid Z110"
            },
            {
                "regex": "Android.*Z150(?:[);/ ]|$)",
                "model": "Liquid Z5 Duo"
            },
            {
                "regex": "Android.*Z160(?:[);/ ]|$)",
                "model": "Liquid Z4 Duo"
            },
            {
                "regex": "Android.*Z(5\\d{2})(?:[);/ ]|$)",
                "model": "Liquid Z$1"
            },
            {
                "regex": "Android.*S57(?:[);/ ]|$)",
                "model": "Liquid Jade Z"
            },
            {
                "regex": "Android.*T02(?:[);/ ]|$)",
                "model": "Liquid Z530"
            },
            {
                "regex": "Android.*T03(?:[);/ ]|$)",
                "model": "Liquid Z630"
            },
            {
                "regex": "Android.*T04(?:[);/ ]|$)",
                "model": "Liquid Z630S"
            },
            {
                "regex": "Android.*T06(?:[);/ ]|$)",
                "model": "Liquid Zest"
            },
            {
                "regex": "Android.*T07(?:[);/ ]|$)",
                "model": "Liquid Zest 4G"
            },
            {
                "regex": "Android.*T08(?:[);/ ]|$)",
                "model": "Liquid Zest Plus"
            },
            {
                "regex": "TM01(?:[);/ ]|$)",
                "model": "Liquid M330"
            },
            {
                "regex": "M220(?:[);/ ]|$)",
                "model": "Liquid M220"
            },
            {
                "regex": "AcerTD600",
                "model": "TD600"
            },
            {
                "regex": "Android.*S55(?:[);/ ]|$)",
                "model": "Liquid Jade"
            },
            {
                "regex": "(?:Acer )?(Chromebook R1[13])",
                "model": "$1",
                "device": "desktop"
            },
            {
                "regex": "(?:Acer )?(Chromebook 14)",
                "model": "$1",
                "device": "desktop"
            },
            {
                "regex": "(Aspire (?:V5-121|XC-704G|V5-573G|A515-51G))",
                "model": "$1",
                "device": "desktop"
            },
            {
                "regex": "(Predator G9-793|Aspire E5-511)",
                "model": "$1",
                "device": "desktop"
            },
            {
                "regex": "Acer; ?([^;\\(\\)/]+)",
                "model": "$1"
            },
            {
                "regex": "Acer(?! ?inc)[ _\\-]?([^;\\(\\)/]+)[();/ ]",
                "model": "$1"
            },
            {
                "regex": "acer[\\-_]([a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "a(101|110|2[10]0|211|50[10]|51[10]|70[10])(?:[);/ ]|$)",
                "model": "Iconia Tab A$1",
                "device": "tablet"
            }
        ]
    },
    "Advan": {
        "regex": "(?:ADVAN (?:M4|S40)|i7U|S45E|S50H|I7D|i55[KD]|i4U|S7D|S4Z|i5[KE]|S5E[ _]NXT|S5J\\+|S4\\+)(?:[);/ ]|$)|[ ](?:50(?:[46]1|59)|600[24])(?: Build|\\) AppleWebKit)|(?:E1C_3G)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "ADVAN M4",
                "model": "M4"
            },
            {
                "regex": "ADVAN S40",
                "model": "S40"
            },
            {
                "regex": "i7U",
                "model": "I Lite i7U"
            },
            {
                "regex": "S5E[ _]NXT",
                "model": "S5E NXT"
            },
            {
                "regex": "i(55[KD]|5[KE])",
                "model": "I$1"
            },
            {
                "regex": "i4U",
                "model": "I4U"
            },
            {
                "regex": " 6004(?: Build|\\) AppleWebKit)",
                "model": "G2 Pro"
            },
            {
                "regex": " 6002(?: Build|\\) AppleWebKit)",
                "model": "G3 Pro"
            },
            {
                "regex": "(S45E|50[46]1|5059|S50H|I7D|S7D|S4Z|S5J\\+|S4\\+)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "E1C_3G",
                "device": "tablet",
                "model": "E1C 3G"
            }
        ]
    },
    "Advance": {
        "regex": "Hollogram|(?:HL(?:4936|6246|[56]575|5[67]67)|IntroTr3544|Pr(?:61(?:4[56]|50)|60[27]0|5[69]50)|Tr3845)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "IntroTr3544|Tr3845",
                "model": "Intro",
                "device": "tablet"
            },
            {
                "regex": "Pr(61(?:4[56]|50)|60[27]0|5[69]50)",
                "model": "Prime PR$1",
                "device": "tablet"
            },
            {
                "regex": "HL(4936|6246|[56]575|5[67]67)",
                "model": "Hollogram HL$1"
            },
            {
                "regex": "Hollogram ([^;/)]+)(?: Build|[;)])",
                "model": "Hollogram $1"
            }
        ]
    },
    "AGM": {
        "regex": "AGM ([^/;)]+)(?: Build|[;)])",
        "device": "smartphone",
        "model": "$1"
    },
    "Airness": {
        "regex": "AIRNESS-([\\w]+)",
        "device": "feature phone",
        "model": "$1"
    },
    "Aiwa": {
        "regex": "(?:AW790|M300|AWM(?:533|50[19]|561)|AW500)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "AW790(?:[);/ ]|$)",
                "model": "AW790"
            },
            {
                "regex": "AW500(?:[);/ ]|$)",
                "model": "AW500"
            },
            {
                "regex": "AWM(533|50[19]|561)(?:[);/ ]|$)",
                "model": "AWM$1"
            },
            {
                "regex": "M300(?:[);/ ]|$)",
                "model": "M300",
                "device": "phablet"
            }
        ]
    },
    "Akai": {
        "regex": "AKAI,|Akai[ _-]|Eco[ _]E2|Glory[ _](?:G[1235]|L[123]|O[125])|TAB-[79]8[03]0Q?|X6 METAL|AKTB-703MZ",
        "device": "smartphone",
        "models": [
            {
                "regex": "TAB-([79]8[03]0Q?)",
                "device": "tablet",
                "model": "TAB $1"
            },
            {
                "regex": "AKTB-703MZ",
                "device": "tablet",
                "model": "AKTB-703MZ"
            },
            {
                "regex": "X6 METAL",
                "model": "iLike"
            },
            {
                "regex": "Glory[ _](G[1235]|L[123]|O[125])",
                "model": "Glory $1"
            },
            {
                "regex": "Eco[ _]E2",
                "model": "Eco E2"
            },
            {
                "regex": "Akai[ _-]([^;/)]+)(?: Build|[;)])",
                "model": "$1"
            },
            {
                "regex": "AKAI,(AK3219NF)",
                "device": "tv",
                "model": "$1"
            },
            {
                "regex": "Si2157LG32",
                "device": "tv",
                "model": "Si2157LG32"
            }
        ]
    },
    "Arian Space": {
        "regex": "Arian Space",
        "device": "tablet",
        "models": [
            {
                "regex": "100 ST1004PG",
                "model": "100"
            },
            {
                "regex": "80 SS8003PG",
                "model": "80"
            },
            {
                "regex": "71 ST7002PG",
                "model": "71"
            },
            {
                "regex": "70 ST7001RW",
                "model": "70"
            }
        ]
    },
    "Alcatel": {
        "regex": "Alcatel|Alc(?!or )[a-z0-9]+|One[ _]?Touch|idol3|TIMXL|(?:(?:4003[AJ]|4009[ADEFIKMSX]|4013[DEJKMX]|4014[ADEKMX]|4015[ADNTX]|4016[ADX]|4017[ADEFSX]|4018[ADEFMX]|4024[EDX]|4027[ADNX]|4028[AEJS]|4032[ADEX]|4034[ADEFGXTL]|4035[ADXY]|4045[ADEX]|4047[ADFGNX]|4049[DEGMX]|4060[SW]|A466BG|A621BL|4114E|4087U|5001[ADJTU]|5002[ADFH]|5003[ADGU]|5006D|5007[AU]|5008[ADUYT]|5009[AD]|5010[DEGSUX]|5011A|5012[DFG]|5015[ADEX]|5016[AXJ]|5017[ABDEOX]|5019D|5022[EDX]|5023[EF]|5024[ADJF]|5025[DEG]|5026[ADJ]|5027B|5028[AYD]|5029[EYD]|5030[DE]|5032W|5033[AEFXDJGMOTXYQS]|5034D|5036D|5038[ADEX]|5039[DY]|5041[CD]|5042[ADEFGWXT]|5044[ADGIKOPSTY]|5045[ADFGIJTXY]|5046[ADGIJSTUY]|5047[DIUY]|5048[AYUI]|5049[EGSWZ]|5050[ASXY]|5051[ADEJMTWX]|5052[ADY]|5053[ADKY]|5054[ADNSTWX]|5056[ADEGIJMNTUWX]|5057M|5058[AIY]|5059[ADJXYZIST]|5060[ADJ]|5061[KU]|5065[ADNWX]|5070D|5080[ADFQUX]|5085[ABCDGHIJNOQY]|5086[ADY]|5090[AIY]|5095[IKY]|5098[OS]|5099[ADYUI]|5116J|5145A|6016[ADEX]|6036[AXY]|6037[BKY]|6039[AHJKY]|6042D|6043[AD]|6044D|6045[BFIKOYX]|6050[AFY]|6055[ABDHIKPUYZ]|6058[ADX]|6060[SX]|6062W|6070K|7040[ADEFKRT]|7041[DX]|7042A|7043[AEKY]|7044[AX]|7045Y|7048[ASWX]|7053D|7055A|7070X|7071[ADX]|8030Y|8050[DEGX]|8063|8088[XQM]|9001[DIX]|9002X|9003[AX]|9024O|9005X|9026X|9007[ATX]|9008[ADIJNTUX]|9009G|9010X|9020A|9022X|9027[FTWX]|9029Z|9203A|A(?:464BG|570BL|50[13]DL|57[17]VL|574BL)|I213|I216X|(?<!\\.)80(?:82|6[78])|A576CC)(?:_(?:EEA|RU))?|P360X)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "4087U",
                "model": "1SE Light"
            },
            {
                "regex": "5022[EDX]|5070D",
                "model": "One Touch Pop Star"
            },
            {
                "regex": "6044D",
                "model": "One Touch Pop Up"
            },
            {
                "regex": "4015[ATX]|4016A",
                "model": "One Touch Pop C1"
            },
            {
                "regex": "4015[DN]|4016[DX]",
                "model": "One Touch Pop C1 Dual SIM"
            },
            {
                "regex": "4032[AX]",
                "model": "One Touch Pop C2"
            },
            {
                "regex": "4032[DE]",
                "model": "One Touch Pop C2 Dual SIM"
            },
            {
                "regex": "5036D",
                "model": "One Touch Pop C5 Dual SIM"
            },
            {
                "regex": "7040[AFK]|7041X|7042A",
                "model": "One Touch Pop C7"
            },
            {
                "regex": "7040[DE]|7041D",
                "model": "One Touch Pop C7 Dual SIM"
            },
            {
                "regex": "4018[ADEFMX]",
                "model": "One Touch Pop D1"
            },
            {
                "regex": "4035[ADXY]",
                "model": "One Touch Pop D3"
            },
            {
                "regex": "5038[ADEX]",
                "model": "One Touch Pop D5"
            },
            {
                "regex": "4045[AX]",
                "model": "One Touch Pop 2 4\""
            },
            {
                "regex": "4045[DE]",
                "model": "One Touch Pop 2 4\" Dual SIM"
            },
            {
                "regex": "5042[AFGWX]",
                "model": "One Touch Pop 2 4.5\""
            },
            {
                "regex": "5042[DE]",
                "model": "One Touch Pop 2 4.5\" Dual SIM"
            },
            {
                "regex": "7043[AY]|7044[AX]",
                "model": "One Touch Pop 2 5\""
            },
            {
                "regex": "7043[EK]",
                "model": "One Touch Pop 2 5\" Dual SIM"
            },
            {
                "regex": "50[16]5X",
                "model": "One Touch Pop 3"
            },
            {
                "regex": "5015[AE]|5016[AJ]|5065[ADWX]|5116J",
                "model": "One Touch Pop 3 5\""
            },
            {
                "regex": "5025[DEG]|5054[ADSTX]",
                "model": "One Touch Pop 3 5.5\""
            },
            {
                "regex": "5015D",
                "model": "One Touch Pop 3 Dual SIM"
            },
            {
                "regex": "5051[AEJMTWX]",
                "model": "One Touch Pop 4"
            },
            {
                "regex": "5051D",
                "model": "One Touch Pop 4 Dual SIM"
            },
            {
                "regex": "5095[IKY]",
                "model": "One Touch Pop 4S"
            },
            {
                "regex": "5056[ADEGJMTUX]",
                "model": "One Touch Pop 4+"
            },
            {
                "regex": "7070X",
                "model": "One Touch Pop 4 6\""
            },
            {
                "regex": "5057M",
                "model": "One Touch Pop Mirage"
            },
            {
                "regex": "5050[ASXY]",
                "model": "One Touch Pop S3"
            },
            {
                "regex": "7045Y",
                "model": "One Touch Pop S7"
            },
            {
                "regex": "6037[BKY]",
                "model": "One Touch Idol 2"
            },
            {
                "regex": "5042T",
                "model": "One Touch Astro"
            },
            {
                "regex": "5027B",
                "model": "One Touch Dawn"
            },
            {
                "regex": "5024[ADF]|5028Y",
                "model": "1S"
            },
            {
                "regex": "7071[ADX]",
                "model": "A7 XL"
            },
            {
                "regex": "5002[ADH]",
                "model": "1B (2020)"
            },
            {
                "regex": "4034T",
                "model": "1E"
            },
            {
                "regex": "5002F",
                "model": "1A (2020)"
            },
            {
                "regex": "5034D",
                "model": "3L"
            },
            {
                "regex": "A576CC",
                "model": "Kora A576CC"
            },
            {
                "regex": "7040[RT]",
                "model": "One Touch Fierce 2"
            },
            {
                "regex": "5056[NW]",
                "model": "One Touch Fierce 4"
            },
            {
                "regex": "5054N",
                "model": "One Touch Fierce XL"
            },
            {
                "regex": "6042D",
                "model": "One Touch Flash"
            },
            {
                "regex": "6016[AX]",
                "model": "One Touch Idol 2 mini"
            },
            {
                "regex": "6016[DE]",
                "model": "One Touch Idol 2 mini Dual SIM"
            },
            {
                "regex": "6036[AXY]",
                "model": "One Touch Idol 2 mini S"
            },
            {
                "regex": "6050[AFY]",
                "model": "One Touch Idol 2S"
            },
            {
                "regex": "6039[AHJKY]|idol3",
                "model": "One Touch Idol 3"
            },
            {
                "regex": "6045[BFIKOYX]",
                "model": "One Touch Idol 3 5.5\""
            },
            {
                "regex": "6055[ABDHIKPUYZ]",
                "model": "One Touch Idol 4"
            },
            {
                "regex": "6070K",
                "model": "One Touch Idol 4S"
            },
            {
                "regex": "6058[ADX]",
                "model": "One Touch Idol 5"
            },
            {
                "regex": "6060[SX]",
                "model": "One Touch Idol 5S"
            },
            {
                "regex": "6043[AD]",
                "model": "One Touch Idol X+"
            },
            {
                "regex": "8030Y",
                "model": "One Touch Hero 2"
            },
            {
                "regex": "7055A",
                "model": "One Touch Hero 2C"
            },
            {
                "regex": "7053D",
                "model": "One Touch X1"
            },
            {
                "regex": "5065N",
                "model": "TRU"
            },
            {
                "regex": "A574BL",
                "model": "Raven"
            },
            {
                "regex": "A577VL",
                "model": "Zip"
            },
            {
                "regex": "A466BG",
                "model": "Pixi Unite"
            },
            {
                "regex": "5056I",
                "model": "One Touch Optus X Smart"
            },
            {
                "regex": "A570BL",
                "model": "One Touch Pixi Avion LTE"
            },
            {
                "regex": "A571VL",
                "model": "One Touch Pixi Avion 4G LTE"
            },
            {
                "regex": "4024[DEX]",
                "model": "One Touch Pixi First"
            },
            {
                "regex": "A464BG",
                "model": "One Touch Pixi Glitz"
            },
            {
                "regex": "A621BL",
                "model": "One Touch Pixi Glory"
            },
            {
                "regex": "4014D",
                "model": "One Touch Pixi 2"
            },
            {
                "regex": "4009[AFIKMSX]",
                "model": "One Touch Pixi 3 3.5\""
            },
            {
                "regex": "4009[DE]",
                "model": "One Touch Pixi 3 3.5\" Dual SIM"
            },
            {
                "regex": "4003[AJ]|4013[DEJKMX]|4014[AEKMX]|4114E",
                "model": "One Touch Pixi 3 4\""
            },
            {
                "regex": "4027[ADNX]|4028[AEJS]|5019D",
                "model": "One Touch Pixi 3 4.5\""
            },
            {
                "regex": "5017[ABDEOX]",
                "model": "One Touch Pixi 3 4.5\" 4G"
            },
            {
                "regex": "9002X",
                "model": "One Touch Pixi 3 7\"",
                "device": "phablet"
            },
            {
                "regex": "9007A",
                "model": "One Touch Pixi 3 7\"",
                "device": "tablet"
            },
            {
                "regex": "9007[TX]",
                "model": "One Touch Pixi 3 7\" 4G",
                "device": "tablet"
            },
            {
                "regex": "9009G",
                "model": "1T",
                "device": "tablet"
            },
            {
                "regex": "(?!<\\.)8082(?:_(?:EEA|RU))?(?:[);/ ]|$)",
                "model": "1T 10",
                "device": "tablet"
            },
            {
                "regex": "(?!<\\.)806[78](?:_(?:EEA|RU))?(?:[);/ ]|$)",
                "model": "1T 7",
                "device": "tablet"
            },
            {
                "regex": "9022X",
                "model": "One Touch Pixi 3 8\"",
                "device": "phablet"
            },
            {
                "regex": "9010X",
                "model": "One Touch Pixi 3 10\"",
                "device": "tablet"
            },
            {
                "regex": "4017[ADEFSX]",
                "model": "One Touch Pixi 4 3.5\""
            },
            {
                "regex": "4034[ADEFGXL]",
                "model": "One Touch Pixi 4 4\""
            },
            {
                "regex": "4060[SW]",
                "model": "One Touch Pixi 4 4.5\""
            },
            {
                "regex": "5023[EF]",
                "model": "One Touch Pixi 4 Plus Power"
            },
            {
                "regex": "5010[DEGSUX]",
                "model": "One Touch Pixi 4 5\" 3G"
            },
            {
                "regex": "5041D",
                "model": "One Touch Pixi 4 5.0\""
            },
            {
                "regex": "5012[DFG]",
                "model": "One Touch Pixi 4 5.5\" 3G"
            },
            {
                "regex": "5045[ADFGIJTXY]|5145A",
                "model": "One Touch Pixi 4 5\" 4G"
            },
            {
                "regex": "5098O",
                "model": "One Touch Pixi Theatre"
            },
            {
                "regex": "A501DL",
                "model": "A1"
            },
            {
                "regex": "A503DL",
                "model": "A1X"
            },
            {
                "regex": "5046[ADIJTUY]",
                "model": "A3"
            },
            {
                "regex": "5011A|5049[EG]",
                "model": "A3 Plus"
            },
            {
                "regex": "9008[ADIJNTUX]",
                "model": "A3 XL"
            },
            {
                "regex": "9203A",
                "model": "A3 7\" 3G",
                "device": "tablet"
            },
            {
                "regex": "9027[FTWX]",
                "model": "3T 8.0\"",
                "device": "tablet"
            },
            {
                "regex": "9026X",
                "model": "A3 10\"",
                "device": "tablet"
            },
            {
                "regex": "5085[BQ]",
                "model": "A5"
            },
            {
                "regex": "5085[ADHIJY]",
                "model": "A5 LED"
            },
            {
                "regex": "5085N",
                "model": "A5 Max LED"
            },
            {
                "regex": "5090[AIY]",
                "model": "A7"
            },
            {
                "regex": "5046[GS]",
                "model": "A30"
            },
            {
                "regex": "5049S",
                "model": "A30 Plus"
            },
            {
                "regex": "5049Z",
                "model": "A30 Fierce"
            },
            {
                "regex": "5085[GO]",
                "model": "A50"
            },
            {
                "regex": "5048[AYUI]",
                "model": "3X (2019)"
            },
            {
                "regex": "5049W",
                "model": "Revvl"
            },
            {
                "regex": "8050[GX]",
                "model": "One Touch Pixi 4 6\" 3G"
            },
            {
                "regex": "8050[ED]",
                "model": "One Touch Pixi 4 6\" 3G Dual SIM"
            },
            {
                "regex": "5098S|9001[DIX]",
                "model": "One Touch Pixi 4 6\" 4G"
            },
            {
                "regex": "9003[AX]",
                "model": "One Touch Pixi 4 7\" 3G",
                "device": "tablet"
            },
            {
                "regex": "8063",
                "model": "One Touch Pixi 4 7\" WiFi",
                "device": "tablet"
            },
            {
                "regex": "9024O",
                "model": "One Touch Pixi 5",
                "device": "tablet"
            },
            {
                "regex": "I216X",
                "model": "One Touch Pixi 7",
                "device": "tablet"
            },
            {
                "regex": "I213",
                "model": "One Touch Pixi 7",
                "device": "tablet"
            },
            {
                "regex": "9005X",
                "model": "One Touch Pixi 8",
                "device": "tablet"
            },
            {
                "regex": "P320X",
                "model": "One Touch POP 8",
                "device": "tablet"
            },
            {
                "regex": "P310X",
                "model": "One Touch POP 7",
                "device": "tablet"
            },
            {
                "regex": "9020A",
                "model": "One Touch Trek HD",
                "device": "tablet"
            },
            {
                "regex": "7048[ASWX]",
                "model": "One Touch Go Play"
            },
            {
                "regex": "(?:Alcatel-)?OT-802",
                "model": "Wave"
            },
            {
                "regex": "ALCATEL_ONE_TOUCH_768T",
                "model": "Island"
            },
            {
                "regex": "(?:TRIBE_)?3075A",
                "model": "One Touch Tribe"
            },
            {
                "regex": "ALCATEL_one_touch_585",
                "model": "One Touch 585"
            },
            {
                "regex": "ALCATEL_ONE_TOUCH_838",
                "model": "One Touch 838"
            },
            {
                "regex": "ALCATEL_one_touch_813d",
                "model": "One Touch 813D"
            },
            {
                "regex": "ALCATEL_one_touch_813",
                "model": "One Touch 813"
            },
            {
                "regex": "OT871A",
                "model": "One Touch 871A"
            },
            {
                "regex": "P360X",
                "model": "One Touch Pop 10"
            },
            {
                "regex": "(?:Alcatel[ _])?One[ _]?Touch[ _]((?:T[0-9]+|TAB[^/;]+|EVO[78](?:HD)?)) Build",
                "device": "tablet",
                "model": "One Touch $1"
            },
            {
                "regex": "(?:Alcatel[ _])?One[ _]?Touch([^/;]*) Build",
                "model": "One Touch$1"
            },
            {
                "regex": "(?:Alcatel[ _])?One[ _]?Touch([^/;\\)]*)\\)",
                "model": "One Touch$1"
            },
            {
                "regex": "5080[ADFQUX]",
                "model": "Shine Lite"
            },
            {
                "regex": "TIMXL",
                "model": "TIM XL"
            },
            {
                "regex": "5085C",
                "model": "PulseMix"
            },
            {
                "regex": "4049[DEGMX]",
                "model": "U3"
            },
            {
                "regex": "5044[ADIKOPTY]",
                "model": "U5"
            },
            {
                "regex": "4047[DFXN]",
                "model": "U5 3G"
            },
            {
                "regex": "5047[DIUY]",
                "model": "U5 HD"
            },
            {
                "regex": "4047G",
                "model": "U5 Lite"
            },
            {
                "regex": "4047A",
                "model": "U5 Plus"
            },
            {
                "regex": "4044[CV]",
                "model": "Quick Flip"
            },
            {
                "regex": "4044O",
                "model": "Cingular Flip 2"
            },
            {
                "regex": "(?:Alcatel)?4044[MNT]",
                "model": "Go Flip"
            },
            {
                "regex": "5044[GS]",
                "model": "U50"
            },
            {
                "regex": "5033[ADXJEFGMOTXYQS]",
                "model": "1"
            },
            {
                "regex": "5009[AD]",
                "model": "1C"
            },
            {
                "regex": "5008[ADUYT]",
                "model": "1X (2019)"
            },
            {
                "regex": "5007[AU]",
                "model": "1V (2020)"
            },
            {
                "regex": "5003[ADGU]",
                "model": "1C (2019)"
            },
            {
                "regex": "5006D",
                "model": "3C (2019)"
            },
            {
                "regex": "5024J",
                "model": "1S"
            },
            {
                "regex": "5028[AD]",
                "model": "1S (2020)"
            },
            {
                "regex": "5030[DE]",
                "model": "1SE (2020)"
            },
            {
                "regex": "5001[ADJTU]",
                "model": "1V"
            },
            {
                "regex": "5059[ADJXYZIT]",
                "model": "1X"
            },
            {
                "regex": "5059S",
                "model": "Avalon V"
            },
            {
                "regex": "5060[ADJ]",
                "model": "5V",
                "device": "phablet"
            },
            {
                "regex": "5052[ADY]|5053[ADKY]",
                "model": "3"
            },
            {
                "regex": "5029E",
                "model": "3 (2020)"
            },
            {
                "regex": "5029[DY]",
                "model": "3L (2020)"
            },
            {
                "regex": "5032W|5099[ADYUI]",
                "model": "3V"
            },
            {
                "regex": "5026[ADJ]",
                "model": "3C"
            },
            {
                "regex": "5039[DY]",
                "model": "3L",
                "device": "phablet"
            },
            {
                "regex": "5058[AIY]",
                "model": "3X"
            },
            {
                "regex": "5061[KU]",
                "model": "3X (2020)"
            },
            {
                "regex": "5086[ADY]",
                "model": "5"
            },
            {
                "regex": "6062W",
                "model": "7"
            },
            {
                "regex": "8088[XQM]",
                "model": "3T 10",
                "device": "tablet"
            },
            {
                "regex": "9029Z",
                "model": "Joy Tab",
                "device": "tablet"
            },
            {
                "regex": "9029Z",
                "model": "Joy Tab",
                "device": "tablet"
            },
            {
                "regex": "Alcatel UP",
                "model": ""
            },
            {
                "regex": "(?:ALCATEL_)?A383G",
                "model": "Big Easy Plus"
            },
            {
                "regex": "5041C",
                "model": "TETRA"
            },
            {
                "regex": "ALCATEL([^/;]+) Build",
                "model": "$1"
            },
            {
                "regex": "ALCATEL[ \\-]?([^/;\\)]+)",
                "model": "$1"
            },
            {
                "regex": "ALCATEL_([^/;\\)]+)",
                "model": "$1"
            },
            {
                "regex": "Alc([a-z0-9]+)",
                "model": "$1"
            }
        ]
    },
    "Allview": {
        "regex": "Allview|A4You|H1003_LTE_PRO|A5_(?:Easy(?:_TM)?|Ready(?:_TM)?|Quad|Quad_Plus_TM)|A[5-9]_Lite|A5Smiley|A6_Duo|A10_Lite_2019|A10_(?:Plus|Max)|AX2_Frenzy|AX4Nano|AX5NanoQ|C6_Duo|E[23]_Living|E3_(?:Jump|Sign)|E4_Lite|M9_Connect|P(?:43_Easy|[5689]_Energy|6_Energy_Lite|[68]_Energy_Mini(?:_TM)?|(41|[458])_eMagic(?:_TM)?|[589](?:_)?Life(?:_TM)?|[567]_Lite(?:_TM)?|6_plus|[45678]_Pro|7_Seon|10_Life|10_Max|10_Style|7_Xtreme|6_Qmax|4_Quad)|V(?:[134]_Viper|1_Viper_I|2_Viper_(?:E|I|S|X|X_plus|Xe))|X4_Soul_Vision|X(?:[1-46]_Soul_Xtreme|[1-5]_Soul|3_Soul_Lite|[2-6]_Soul_Mini(?:_TM)?|4_Soul_Mini_S(?:_TM)?|[2-5]_Soul_Style(?:_TM)?|2_Soul_Style_Plus|2_Twin)|Viva_(?:H801|100[13]G|(?:H100[12]|H701|H802)_LTE|C701|i701G)",
        "device": "smartphone",
        "models": [
            {
                "regex": "A4You",
                "model": "A4 You"
            },
            {
                "regex": "AX2_Frenzy",
                "model": "AX2 Frenzy",
                "device": "tablet"
            },
            {
                "regex": "AX4Nano",
                "model": "AX4 Nano"
            },
            {
                "regex": "AX5NanoQ",
                "model": "AX5 Nano Q",
                "device": "tablet"
            },
            {
                "regex": "C6_Duo",
                "model": "C6 Duo"
            },
            {
                "regex": "E([23])_Living",
                "model": "E$1 Living"
            },
            {
                "regex": "E3_Jump",
                "model": "E3 Jump"
            },
            {
                "regex": "E3_Sign",
                "model": "E3 Sign"
            },
            {
                "regex": "E4_Lite",
                "model": "E4 Lite"
            },
            {
                "regex": "M9_Connect",
                "model": "M9 Connect"
            },
            {
                "regex": "P43_Easy",
                "model": "P43 Easy"
            },
            {
                "regex": "P(41|[458])_eMagic(?:_TM)?",
                "model": "P$1 eMagic"
            },
            {
                "regex": "P([567])_Lite(?:_TM)?",
                "model": "P$1 Lite"
            },
            {
                "regex": "P6_plus",
                "model": "P6 Plus"
            },
            {
                "regex": "P6_Qmax",
                "model": "P6 Qmax"
            },
            {
                "regex": "P([45678])_Pro",
                "model": "P$1 Pro"
            },
            {
                "regex": "P4_Quad",
                "model": "P4 Quad"
            },
            {
                "regex": "P9_Energy_Lite_2017",
                "model": "P9 Energy Lite (2017)"
            },
            {
                "regex": "P9_Energy_S",
                "model": "P9 Energy S"
            },
            {
                "regex": "P([69])_Energy_Lite",
                "model": "P$1 Energy Lite"
            },
            {
                "regex": "P8_Energy_PRO",
                "model": "P8 Energy Pro"
            },
            {
                "regex": "P([689])_Energy_mini(?:_TM)?",
                "model": "P$1 Energy Mini"
            },
            {
                "regex": "P([5689])_Energy",
                "model": "P$1 Energy"
            },
            {
                "regex": "P7_Seon",
                "model": "P7 Seon"
            },
            {
                "regex": "P7_Xtreme",
                "model": "P7 Xtreme"
            },
            {
                "regex": "P([589])(?:_)?Life(?:_TM)?",
                "model": "P$1 Life"
            },
            {
                "regex": "P10_Life",
                "model": "P10 Life"
            },
            {
                "regex": "P10_Max",
                "model": "P10 Max"
            },
            {
                "regex": "P10_Style",
                "model": "P10 Style"
            },
            {
                "regex": "V([12])_Viper_I4G(?:_TM)?",
                "model": "V$1 Viper I 4G"
            },
            {
                "regex": "V1_Viper_I",
                "model": "V1 Viper I"
            },
            {
                "regex": "V([134])_Viper",
                "model": "V$1 Viper"
            },
            {
                "regex": "V2_Viper_X_plus",
                "model": "V2 Viper X Plus"
            },
            {
                "regex": "V2_Viper_(E|I|S|X(?:e)?)",
                "model": "V2 Viper $1"
            },
            {
                "regex": "X4_Soul_Vision",
                "model": "X4 Soul Vision"
            },
            {
                "regex": "X([1246])_Soul_Xtreme",
                "model": "X$1 Soul Xtreme"
            },
            {
                "regex": "X4_Soul_Infinity_(L|N|S|Z)",
                "model": "X4 Soul Infinity $1"
            },
            {
                "regex": "X([34])_Soul_Lite",
                "model": "X$1 Soul Lite"
            },
            {
                "regex": "X4_Soul_Mini_S(?:_TM)?",
                "model": "X4 Soul Mini S"
            },
            {
                "regex": "X([23456])_Soul_Mini(?:_TM)?",
                "model": "X$1 Soul Mini"
            },
            {
                "regex": "X3_Soul_PLUS",
                "model": "X3 Soul Plus"
            },
            {
                "regex": "X([35])_Soul_Pro",
                "model": "X$1 Soul Pro"
            },
            {
                "regex": "X2_Soul_Style_Plus",
                "model": "X2 Soul Style Plus"
            },
            {
                "regex": "X([2345])_Soul_Style(?:_TM)?",
                "model": "X$1 Soul Style"
            },
            {
                "regex": "X([12345])_Soul",
                "model": "X$1 Soul"
            },
            {
                "regex": "X2_Twin",
                "model": "X2 Twin"
            },
            {
                "regex": "A5_Easy(?:_TM)?",
                "model": "A5 Easy"
            },
            {
                "regex": "A([56789])_Lite",
                "model": "A$1 Lite"
            },
            {
                "regex": "A5_Ready(?:_TM)?",
                "model": "A5 Ready"
            },
            {
                "regex": "A5Smiley",
                "model": "A5 Smiley"
            },
            {
                "regex": "A5_Quad_Plus_TM",
                "model": "A5 Quad Plus"
            },
            {
                "regex": "A5_Quad",
                "model": "A5 Quad"
            },
            {
                "regex": "A6_Duo",
                "model": "A6 Duo"
            },
            {
                "regex": "A10_Lite_2019",
                "model": "A10 Lite (2019)"
            },
            {
                "regex": "A10_Plus",
                "model": "A10 Plus"
            },
            {
                "regex": "A10_Max",
                "model": "A10 Max"
            },
            {
                "regex": "Impera S",
                "model": "Impera S"
            },
            {
                "regex": "ALLVIEW P4i",
                "model": "AllDro P4"
            },
            {
                "regex": "Allview_P2",
                "model": "AllDro"
            },
            {
                "regex": "AllviewCityPlus",
                "device": "tablet",
                "model": "City Plus"
            },
            {
                "regex": "AllviewCity",
                "device": "tablet",
                "model": "City"
            },
            {
                "regex": "AllviewAX2Frenzy",
                "device": "tablet",
                "model": "AX2 Frenzy"
            },
            {
                "regex": "Allview2SpeedDuo",
                "device": "tablet",
                "model": "Alldro 2 Speed Duo"
            },
            {
                "regex": "Allview3SpeedQuad",
                "device": "tablet",
                "model": "Alldro 3 Speed Quad"
            },
            {
                "regex": "H1003_LTE_PRO",
                "device": "tablet",
                "model": "Viva H1003 LTE Pro"
            },
            {
                "regex": "Viva_(H801|100[13]G|(?:H802|H100[12]|H701)_LTE|C701|i701G)",
                "device": "tablet",
                "model": "Viva $1"
            },
            {
                "regex": "ALLVIEW ?SPEEDI",
                "device": "tablet",
                "model": "Speed I"
            },
            {
                "regex": "ALLVIEW ?SPEED",
                "device": "tablet",
                "model": "Speed"
            },
            {
                "regex": "ALLVIEWSPEED",
                "device": "tablet",
                "model": "Speed"
            },
            {
                "regex": "AX4Nano",
                "device": "tablet",
                "model": "AX4 Nano"
            },
            {
                "regex": "ALLVIEW_TX1_Quasar",
                "device": "tablet",
                "model": "TX1 Quasar"
            },
            {
                "regex": "Allview[ _-]([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "Allview[ _-]([^;/]+)(?:[;/)]|$)",
                "model": "$1"
            }
        ]
    },
    "Allwinner": {
        "regex": "(?:QUAD-CORE (?:A64 p3|A33 y3|T3 K2001M)|ZY-07B|UltraOcta-T8|OCTA-CORE T8)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "QUAD-CORE A64 p3",
                "model": "A64 QUAD-CORE p3"
            },
            {
                "regex": "QUAD-CORE A33 y3",
                "model": "A33 QUAD-CORE y3"
            },
            {
                "regex": "ZY-07B",
                "model": "ZY-07B"
            },
            {
                "regex": "UltraOcta-T8",
                "device": "car browser",
                "model": "Ultra Octa T8"
            },
            {
                "regex": "QUAD-CORE T3 K2001M",
                "device": "car browser",
                "model": "QUAD-CORE T3 K2001M"
            },
            {
                "regex": "OCTA-CORE T8",
                "device": "car browser",
                "model": "OCTA-CORE T8"
            }
        ]
    },
    "altron": {
        "regex": "(AL-555|GI-626|OB[_-](?:421|728|627))(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "AL-555",
                "model": "AL-555"
            },
            {
                "regex": "OB[_-](421|728|627)",
                "model": "OB-$1"
            },
            {
                "regex": "GI-626",
                "device": "phablet",
                "model": "GI-626"
            }
        ]
    },
    "AMGOO": {
        "regex": "AM(350|355|40[257]|41[025]|450|50[89]|52[037]|51[58]|53[05])(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "AM350",
                "model": "Jack Pro"
            },
            {
                "regex": "AM(355|407)",
                "model": "Tigo"
            },
            {
                "regex": "AM402",
                "model": "Pronto"
            },
            {
                "regex": "AM410",
                "model": "Unico"
            },
            {
                "regex": "AM450",
                "model": "Swift"
            },
            {
                "regex": "AM508",
                "model": "Fuego"
            },
            {
                "regex": "AM509",
                "model": "Uno"
            },
            {
                "regex": "AM515",
                "model": "D1"
            },
            {
                "regex": "AM518",
                "model": "C1"
            },
            {
                "regex": "AM520",
                "model": "Pro"
            },
            {
                "regex": "AM523",
                "model": "Plus"
            },
            {
                "regex": "AM527",
                "model": "Geo"
            },
            {
                "regex": "AM530",
                "model": "A1"
            },
            {
                "regex": "AM535",
                "model": "P1"
            },
            {
                "regex": "AM([0-9]{3})",
                "model": "AM$1"
            }
        ]
    },
    "Amoi": {
        "regex": "Amoi|(?:A862W|Clever[ -]?(?:Gem S52|Gleam S45|Pad P7|Wave S50|Touch S46|JOY S40|Lite S41))(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Clever Pad P7",
                "model": "Clever Pad P7"
            },
            {
                "regex": "A862W",
                "model": "A862W"
            },
            {
                "regex": "CLEVER JOY S40",
                "model": "Clever Joy S40"
            },
            {
                "regex": "Clever[ -]?(Gleam S45|Gem S52|Wave S50|Touch S46|Lite S41)",
                "model": "Clever $1"
            },
            {
                "regex": "Amoi[\\- /]([a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "Amoisonic-([a-z0-9]+)",
                "model": "$1"
            }
        ]
    },
    "Ainol": {
        "regex": "Numy|novo[0-9]|Novo 10 Hero|AX10PRO|NUMY3GAX9|NOVO10 Spark|Aurora-II",
        "device": "tablet",
        "models": [
            {
                "regex": "Numy 3G AX1",
                "model": "Novo 7 Numy AX1 3G"
            },
            {
                "regex": "Numy[_ ]?3G[_ ]?Sword",
                "model": "Numy 3G Sword"
            },
            {
                "regex": "NOVO10 Spark",
                "model": "Novo 10 Spark"
            },
            {
                "regex": "AX10PRO",
                "model": "Numy AX10 Pro"
            },
            {
                "regex": "Numy_3G_AX3",
                "model": "Numy AX3 Sword"
            },
            {
                "regex": "Numy3GTalos",
                "model": "Numy 3G Talos"
            },
            {
                "regex": "Numy_3G_BW1",
                "model": "Numy 3G Talos 2"
            },
            {
                "regex": "NUMY3GAX9(?:[);/ ]|$)",
                "model": "Numy 3G AX9"
            },
            {
                "regex": "Numy3G_AX10(?:[);/ ]|$)",
                "model": "Numy 3G AX10"
            },
            {
                "regex": "(Novo 10 Hero QuadCore)",
                "model": "$1"
            },
            {
                "regex": "Aurora-II",
                "model": "Novo 7 Aurora II"
            },
            {
                "regex": "Novo7 Flame(?:[);/ ]|$)",
                "model": "Novo 7 Flame"
            },
            {
                "regex": "NOVO7(?:[);/ ]|$)",
                "model": "Novo 7"
            },
            {
                "regex": "Numy[ _]([^;/]+) Build",
                "model": "Numy $1"
            },
            {
                "regex": "Novo([0-9]+)[ \\-]([^;/]+) Build",
                "model": "Novo $1 $2"
            }
        ]
    },
    "Archos": {
        "regex": "(?:YL-)?Archos|(?:A50TI|AC101BHE)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Archos (Oxygen (?:63|57|68XL))(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "A50TI(?:[);/ ]|$)",
                "model": "50 Titanium 4G"
            },
            {
                "regex": "Archos ?5(?:[);/ ]|$)",
                "device": "tablet",
                "model": "5"
            },
            {
                "regex": "AC101BHE(?:[);/ ]|$)",
                "device": "tablet",
                "model": "101B Helium 4G"
            },
            {
                "regex": "(?:YL-)?Archos ([^/;]*(?:PAD)[^/;]*) Build",
                "device": "tablet",
                "model": "$1"
            },
            {
                "regex": "Archos ((?:[789]|10)[0-9]?[a-z]* ?(?:G9|G10|Helium|Titanium|Cobalt|Platinum|Xenon|Carbon|Neon|XS|IT)[^/;]*) Build",
                "device": "tablet",
                "model": "$1"
            },
            {
                "regex": "(?:YL-)?Archos ([a-z0-9 ]+) Build",
                "model": "$1"
            },
            {
                "regex": "(?:YL-)?Archos ([a-z0-9]+)",
                "model": "$1"
            }
        ]
    },
    "Axxion": {
        "regex": "Axxion ATAB-[0-9]+(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "Axxion ATAB-([0-9]+)(?:[);/ ]|$)",
                "model": "ATAB-$1"
            }
        ]
    },
    "MEU": {
        "regex": "MEU ([a-z0-9]+) Build",
        "device": "smartphone",
        "model": "$1"
    },
    "Arnova": {
        "regex": "arnova|ARCHM901|AN7CG2|AN7G2(DTE|I)?|AN7[BCDFH]?G3|A80KSC|AN8G2|AN8[BC]?G3|AN9G2I?|AN9G3|A101[BC]|AN10G2|AN10BG2(DT|I)?|AN10BG3(DT)?|AN10[CD]G3",
        "device": "tablet",
        "models": [
            {
                "regex": "ARCHM901",
                "model": "M901"
            },
            {
                "regex": "AN7CG2",
                "model": "7c G2"
            },
            {
                "regex": "AN7G2I|AN7G2|AN7G2DTE",
                "model": "7 G2"
            },
            {
                "regex": "AN7G3",
                "model": "7 G3"
            },
            {
                "regex": "AN7BG3",
                "model": "7b G3"
            },
            {
                "regex": "AN7CG3",
                "model": "7c G3"
            },
            {
                "regex": "AN7DG3-CP",
                "model": "Childpad"
            },
            {
                "regex": "AN7DG3",
                "model": "7d G3"
            },
            {
                "regex": "AN7FG3",
                "model": "7f G3"
            },
            {
                "regex": "AN7HG3",
                "model": "7h G3"
            },
            {
                "regex": "A80KSC",
                "model": "8"
            },
            {
                "regex": "AN8G2",
                "model": "8 G2"
            },
            {
                "regex": "AN8G3",
                "model": "8 G3"
            },
            {
                "regex": "AN8BG3",
                "model": "8b G3"
            },
            {
                "regex": "AN8CG3",
                "model": "8c G3"
            },
            {
                "regex": "AN9G2I",
                "model": "9i G2"
            },
            {
                "regex": "AN9G2",
                "model": "9 G2"
            },
            {
                "regex": "AN9G3",
                "model": "9 G3"
            },
            {
                "regex": "A101B2|A101C|AN10G2",
                "model": "10 G2"
            },
            {
                "regex": "A101B",
                "model": "10"
            },
            {
                "regex": "AN10BG2|AN10BG2DT|AN10BG2I",
                "model": "10b G2"
            },
            {
                "regex": "AN10BG3|AN10BG3DT|AN10BG3-LZ",
                "model": "10b G3"
            },
            {
                "regex": "AN10CG3",
                "model": "10c G3"
            },
            {
                "regex": "AN10DG3",
                "model": "10d G3"
            },
            {
                "regex": "ARNOVA 90G3",
                "model": "90 G3"
            },
            {
                "regex": "ARNOVA 90 G4",
                "model": "90 G4"
            },
            {
                "regex": "ARNOVA 97G4",
                "model": "97 G4"
            },
            {
                "regex": "ARNOVA 101 G4",
                "model": "101 G4"
            },
            {
                "regex": "Arnova ([^/;]*) Build",
                "model": "$1"
            }
        ]
    },
    "ARRIS": {
        "regex": "ARRIS",
        "device": "tv",
        "models": [
            {
                "regex": "ARRIS[,;] ([^),;/]+)",
                "model": "$1"
            }
        ]
    },
    "Ask": {
        "regex": "ASK[ _]",
        "device": "smartphone",
        "models": [
            {
                "regex": "ASK[ _]791SP[ _]3G",
                "model": "791SP 3G",
                "device": "tablet"
            },
            {
                "regex": "ASK[_ ]([^/;]*) Build",
                "model": "$1"
            }
        ]
    },
    "ANS": {
        "regex": "UL40 Build",
        "device": "smartphone",
        "model": "UL40"
    },
    "AOC": {
        "regex": "AOC,",
        "device": "tv",
        "models": [
            {
                "regex": "(LE(?:32|43)S5970(?:s)?-20|LE55U7970-30|LE43S5977-20|U60856)",
                "model": "$1"
            }
        ]
    },
    "Assistant": {
        "regex": "(AS[-_](?:50[123]|543[1-6]|44[12]1|541[12]|54[23]1|6431|[46]01L)|AP-(?:109|115G|721N|753G|7[25]7G|10[68]|107G|110N|941|719))[;/)_ ]",
        "device": "smartphone",
        "models": [
            {
                "regex": "AS[-_]401L(?:[);/ ]|$)",
                "model": "Asper"
            },
            {
                "regex": "AS-5432(?:[);/ ]|$)",
                "model": "Agio"
            },
            {
                "regex": "AS[-_](?:5434|501)(?:[);/ ]|$)",
                "model": "Club"
            },
            {
                "regex": "AS_601L(?:[);/ ]|$)",
                "model": "AS-601L"
            },
            {
                "regex": "AS-5433 Secret(?:[);/ ]|$)",
                "model": "Secret"
            },
            {
                "regex": "AS-5433(?:[);/ ]|$)",
                "model": "Max Secret"
            },
            {
                "regex": "AS-5421(?:[);/ ]|$)",
                "model": "Surf"
            },
            {
                "regex": "AS-44[12]1(?:[);/ ]|$)",
                "model": "Unami"
            },
            {
                "regex": "AS-5431(?:[);/ ]|$)",
                "model": "Prima"
            },
            {
                "regex": "AS-502(?:[);/ ]|$)",
                "model": "Shot"
            },
            {
                "regex": "AS-503(?:[);/ ]|$)",
                "model": "Target"
            },
            {
                "regex": "AS-5435(?:[);/ ]|$)",
                "model": "Shine"
            },
            {
                "regex": "AS-5436[ _]",
                "model": "Grid"
            },
            {
                "regex": "AS-6431(?:[);/ ]|$)",
                "model": "Rider"
            },
            {
                "regex": "AS-5411[_ ]",
                "model": "Max Ritm"
            },
            {
                "regex": "AS-5412 Max(?:[);/ ]|$)",
                "model": "AS-5412 Max"
            },
            {
                "regex": "AP-721N(?:[);/ ]|$)",
                "model": "AP-721N Force",
                "device": "tablet"
            },
            {
                "regex": "AP-941(?:[);/ ]|$)",
                "model": "AP-941",
                "device": "tablet"
            },
            {
                "regex": "AP-108(?:[);/ ]|$)",
                "model": "Cetus",
                "device": "tablet"
            },
            {
                "regex": "AP-106(?:[);/ ]|$)",
                "model": "AP-106 Force",
                "device": "tablet"
            },
            {
                "regex": "(AP-(?:109|115G|7[25]7G|753G|107G|110N|719))(?:[);/ ]|$)",
                "device": "tablet",
                "model": "$1"
            }
        ]
    },
    "Ark": {
        "regex": "(ARK[_ -])?Benefit[_ -]([^/;]*)(?: Build|\\))|EDGE A5HD|Elf_S8|ICON (R40\\+|R45)|Impulse[ _](P[12])|Wizard_[12](?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(?:ARK[_ -])?Benefit[_ -]([^/;]*)(?: Build|\\))",
                "model": "Benefit $1"
            },
            {
                "regex": "Impulse[ _]P1\\+",
                "model": "Impulse P1 Plus"
            },
            {
                "regex": "Impulse[ _](P[12])",
                "model": "Impulse $1"
            },
            {
                "regex": "EDGE (A5HD)",
                "model": "Edge $1"
            },
            {
                "regex": "ICON (R40\\+|R45)",
                "model": "Icon $1"
            },
            {
                "regex": "Wizard_([12])",
                "model": "Wizard $1"
            },
            {
                "regex": "Elf_S8(?:[);/ ]|$)",
                "model": "Elf S8"
            }
        ]
    },
    "Garmin-Asus": {
        "regex": "Garmin-Asus|Garminfone",
        "device": "smartphone",
        "models": [
            {
                "regex": "Garminfone[ \\-_]([^;/]*)Build",
                "model": "Garminfone $1"
            },
            {
                "regex": "Garmin-Asus[ \\-_]([^;/]+)Build",
                "model": "$1"
            },
            {
                "regex": "Garmin-Asus[ \\-_]([^;/]+)[\\)/]",
                "model": "$1"
            }
        ]
    },
    "Asus": {
        "regex": "Asus|Transformer|ZenFone Max|TF300T|Slider SL101|PadFone|ME302(?:C|KL)|ME301T|ME371MG|ME17(?:1|2V|3X)|(?:K0[01][0-9a-z]|P(?:00[18ACIL]|01[MTVWYZ]|01MA|01T_1|02[13478])(?: Build|\\))|X015D|X018D|X003|X00[7ABT]D|Z00D|Z00[MTY]D|Z01[7FGHKMR]D)(?:[);/ ]|$)|G55[023]KL|ZB(631|602|633|55[135]|50[01])KL|ZC(55[134]|520|600)KL|ZA550KL|ZE(5[02]0|620|55[2345])KL|ZD55[23]KL|ZS6(?:61|7[01])KS|ZS(6[0236]0|55[10])KL|ZB(500KG|601KL)|MeMo Pad FHD 10 LTE|Z00[SV]D",
        "device": "smartphone",
        "models": [
            {
                "regex": "ME171(?:[);/ ]|$)",
                "model": "Eee Pad MeMO 171",
                "device": "tablet"
            },
            {
                "regex": "ASUS Z906(?:[);/ ]|$)",
                "model": "Z906 10.1\"",
                "device": "tablet"
            },
            {
                "regex": "ME172V",
                "model": "MeMO Pad",
                "device": "tablet"
            },
            {
                "regex": "ME302C(?:[);/ ]|$)",
                "model": "MeMO Pad FHD 10",
                "device": "tablet"
            },
            {
                "regex": "(ME302KL|MeMo Pad FHD 10 LTE)(?:[);/ ]|$)",
                "model": "MeMO Pad FHD 10 LTE",
                "device": "tablet"
            },
            {
                "regex": "ME301T(?:[);/ ]|$)",
                "model": "MeMO Pad Smart 10",
                "device": "tablet"
            },
            {
                "regex": "Z101(?:[);/ ]|$)",
                "model": "Z101",
                "device": "tablet"
            },
            {
                "regex": "X Pad 10 LTE(?:[);/ ]|$)",
                "model": "X Pad 10 LTE",
                "device": "tablet"
            },
            {
                "regex": "(?:K01[3A]|K007|K00R)(?:[);/ ]|$)|MeMO Pad 7",
                "model": "MeMO Pad 7",
                "device": "tablet"
            },
            {
                "regex": "K01E(?:[);/ ]|$)",
                "model": "MeMO Pad 10 ME103K",
                "device": "tablet"
            },
            {
                "regex": "K00U|ME173X(?:[);/ ]|$)",
                "model": "MeMO Pad HD 7",
                "device": "tablet"
            },
            {
                "regex": "(?:K011|K00L)(?:[);/ ]|$)",
                "model": "MeMO Pad 8",
                "device": "tablet"
            },
            {
                "regex": "K014(?:[);/ ]|$)",
                "model": "MeMO Pad 8.9",
                "device": "tablet"
            },
            {
                "regex": "K00S(?:[);/ ]|$)",
                "model": "MeMO Pad HD 7 Dual SIM",
                "device": "tablet"
            },
            {
                "regex": "K00F(?:[);/ ]|$)",
                "model": "MeMO Pad 10",
                "device": "tablet"
            },
            {
                "regex": "K00C(?:[);/ ]|$)",
                "model": "Transformer Pad TF701T",
                "device": "tablet"
            },
            {
                "regex": "K010(?:[);/ ]|$)",
                "model": "Transformer Pad TF103C",
                "device": "tablet"
            },
            {
                "regex": "K018(?:[);/ ]|$)",
                "model": "Transformer Pad TF103CG",
                "device": "tablet"
            },
            {
                "regex": "TF300T(?:[);/ ]|$)",
                "model": "Transformer Pad TF300T",
                "device": "tablet"
            },
            {
                "regex": "K01B(?:[);/ ]|$)",
                "model": "Transformer Pad TF303K",
                "device": "tablet"
            },
            {
                "regex": "Slider SL101",
                "model": "Eee Pad Slider SL101",
                "device": "tablet"
            },
            {
                "regex": "P01[YZ]",
                "model": "ZenPad C 7.0",
                "device": "tablet"
            },
            {
                "regex": "P008 Build",
                "model": "ZenPad Z8",
                "device": "tablet"
            },
            {
                "regex": "P001",
                "model": "ZenPad Z10",
                "device": "tablet"
            },
            {
                "regex": "(?:ASUS_)?P00J",
                "model": "ZenPad Z8s",
                "device": "tablet"
            },
            {
                "regex": "P01[VW]",
                "model": "ZenPad 7.0",
                "device": "tablet"
            },
            {
                "regex": "(?:P024|P00A)(?:[);/ ]|$)",
                "model": "ZenPad 8.0",
                "device": "tablet"
            },
            {
                "regex": "P01MA(?: Build|\\))|P01M(?: Build|\\))",
                "model": "ZenPad S 8.0",
                "device": "tablet"
            },
            {
                "regex": "P027",
                "model": "ZenPad 3S 10",
                "device": "tablet"
            },
            {
                "regex": "(?:ASUS_)?P00I",
                "model": "ZenPad 3S 10 LTE",
                "device": "tablet"
            },
            {
                "regex": "(?:ASUS_)?P00C|P02[138]|P00L|P01T_1",
                "model": "ZenPad 10",
                "device": "tablet"
            },
            {
                "regex": "ASUS Tablet P1801-T",
                "model": "Transformer AiO P1801 18.4\"",
                "device": "tablet"
            },
            {
                "regex": "ASUS TAB A8",
                "model": "TAB A8",
                "device": "tablet"
            },
            {
                "regex": "ME371MG(?:[);/ ]|$)",
                "model": "Fonepad",
                "device": "phablet"
            },
            {
                "regex": "K00G(?:[);/ ]|$)",
                "model": "Fonepad Note 6",
                "device": "phablet"
            },
            {
                "regex": "(?:K012|K00E)(?:[);/ ]|$)",
                "model": "Fonepad 7",
                "device": "phablet"
            },
            {
                "regex": "K00Z(?:[);/ ]|$)",
                "model": "Fonepad 7 Dual SIM",
                "device": "phablet"
            },
            {
                "regex": "K016(?:[);/ ]|$)",
                "model": "Fonepad 8",
                "device": "phablet"
            },
            {
                "regex": "(?:ASUS_)?T00N",
                "model": "PadFone S"
            },
            {
                "regex": "(?:ASUS_)?T00[CE]",
                "model": "PadFone Mini"
            },
            {
                "regex": "(?:ASUS[-_])?T00T",
                "model": "PadFone X Mini"
            },
            {
                "regex": "PadFone T008",
                "model": "PadFone E"
            },
            {
                "regex": "PadFone T004",
                "model": "PadFone Infinity"
            },
            {
                "regex": "(?:ASUS_)?A002(A)?",
                "model": "ZenFone AR"
            },
            {
                "regex": "(?:ASUS_)?A006",
                "model": "ZenFone V"
            },
            {
                "regex": "(?:ASUS_)?A009",
                "model": "ZenFone V Live"
            },
            {
                "regex": "(?:ASUS_)?Z007",
                "model": "ZenFone C"
            },
            {
                "regex": "(?:ASUS_)?(X00P[SD]|ZB555KL)",
                "model": "ZenFone Max M1"
            },
            {
                "regex": "(?:ASUS_)?(?:ZB60[12]KL)|ZenFone Max Pro M1",
                "model": "ZenFone Max Pro M1"
            },
            {
                "regex": "(?:ASUS_)?(X01([AB]D)|ZB633KL)(?:[);/ ]|$)",
                "model": "ZenFone Max M2"
            },
            {
                "regex": "(?:ASUS_)?(?:Z00[AD]|Z008D|Z008|Z00AD[AB]?|ZE551ML)",
                "model": "ZenFone 2"
            },
            {
                "regex": "(?:ASUS_)?(?:ZenFone 2E)",
                "model": "ZenFone 2E"
            },
            {
                "regex": "(?:ASUS_)?(?:A007|Z00YD|ZA550KL|ZB553KL|ZB501KL)(?:[);/ ]|$)",
                "model": "ZenFone Live"
            },
            {
                "regex": "(?:ASUS_)?(?:X00RD|G552KL)",
                "model": "ZenFone Live L1"
            },
            {
                "regex": "G553KL",
                "model": "ZenFone Lite L1"
            },
            {
                "regex": "(?:ASUS_)?(?:Z00[ERLMTW]D|Z011D|ZE500KL)",
                "model": "ZenFone 2 Laser"
            },
            {
                "regex": "(?:ASUS_)?(?:T00[IQ1]|Z01KD)",
                "model": "ZenFone 4"
            },
            {
                "regex": "(?:ASUS_)?(?:T00[JF]|X00QD|X00QSA|ZE620KL)",
                "model": "ZenFone 5"
            },
            {
                "regex": "(?:ASUS_)?T00P",
                "model": "ZenFone 5 LTE"
            },
            {
                "regex": "(?:ASUS_)?(?:T00K|X017DA|X017D|ZC600KL)",
                "model": "ZenFone 5 Lite"
            },
            {
                "regex": "(?:ASUS_)?(?:Z01RD|ZS620KL)",
                "model": "ZenFone 5Z"
            },
            {
                "regex": "(?:ASUS_)?(?:T00G|Z002|ZS630KL|I01WD[X]?)",
                "model": "ZenFone 6"
            },
            {
                "regex": "(?:ASUS_)?ZS671KS",
                "model": "ZenFone 7 Pro"
            },
            {
                "regex": "(?:ASUS_)?(?:ZS670KS|I002D)(?:[);/ ]|$)",
                "model": "ZenFone 7"
            },
            {
                "regex": "(?:ASUS_)?Z010D",
                "model": "ZenFone Max"
            },
            {
                "regex": "(?:ASUS_)?X018D",
                "model": "ZenFone Max Plus M1"
            },
            {
                "regex": "(?:ASUS_)?X00TD?",
                "model": "ZenFone Max Pro M1"
            },
            {
                "regex": "(?:ASUS_)?(?:X01BDA|ZB631KL|Zenfone Max Pro M2)",
                "model": "ZenFone Max Pro M2"
            },
            {
                "regex": "(?:ASUS_)?X014D",
                "model": "ZenFone Go Plus"
            },
            {
                "regex": "(?:ASUS_)?(L001|X00[7AB]D|X009[DB][DA]|X009D|Z00[SV]D|ZB500K[LG]|ZB551KL)",
                "model": "ZenFone Go"
            },
            {
                "regex": "(?:ASUS_)?X013D",
                "model": "ZenFone Go Life"
            },
            {
                "regex": "(?:ASUS_)?G550KL",
                "model": "ZenFone Go TV"
            },
            {
                "regex": "(?:ASUS_)?Z00[SV]D",
                "model": "ZenFone Go"
            },
            {
                "regex": "(?:ASUS_)?(X00[23])",
                "model": "ZenFone Pegasus"
            },
            {
                "regex": "(?:ASUS_)?X005",
                "model": "ZenFone Pegasus 5000"
            },
            {
                "regex": "(?:ASUS_)?X550",
                "model": "ZenFone Pegasus 2 Plus"
            },
            {
                "regex": "(?:ASUS_)?(X00GD)",
                "model": "ZenFone Pegasus 3S Max"
            },
            {
                "regex": "(?:ASUS_)?(?:X00D[DS]|X008D)|ZC553KL",
                "model": "ZenFone 3 Max"
            },
            {
                "regex": "(?:ASUS_)?(X008)",
                "model": "ZenFone 3 Pegasus"
            },
            {
                "regex": "(?:ASUS_)?(ZE553KL|Z01HD)",
                "model": "ZenFone 3 Zoom"
            },
            {
                "regex": "(?:ASUS_)?ZS550KL",
                "model": "ZenFone 3 Deluxe"
            },
            {
                "regex": "(?:ASUS_)?Z00UD",
                "model": "ZenFone Selfie"
            },
            {
                "regex": "(?:ASUS_)?(Z016[DS]|Z01FD)",
                "model": "ZenFone 3 Deluxe"
            },
            {
                "regex": "(?:ASUS_)?(Z017DA|ZE520KL|ZE552KL|Z017D|Z012(D|S))",
                "model": "ZenFone 3"
            },
            {
                "regex": "(?:ASUS_)?Z01B[DS]|ZC551KL",
                "model": "ZenFone 3 Laser"
            },
            {
                "regex": "(?:ASUS_)?A001",
                "model": "ZenFone 3 Ultra"
            },
            {
                "regex": "(?:ASUS_)?(?:ZD552KL|Z01MDA?)(?:[);/ ]|$)",
                "model": "ZenFone 4 Selfie Pro"
            },
            {
                "regex": "(?:ASUS_)?(?:X00LDA|ZD553KL)",
                "model": "ZenFone 4 Selfie"
            },
            {
                "regex": "(?:ASUS_)?(?:X00LD|Z01KS|Z01KDA|ZE554KL)",
                "model": "ZenFone 4"
            },
            {
                "regex": "(?:ASUS_)?X00(?:[HIK]D|IS)|ZC554KL|ZC520KL",
                "model": "ZenFone 4 Max"
            },
            {
                "regex": "(?:ASUS_)?X015D",
                "model": "ZenFone 4 Max Plus"
            },
            {
                "regex": "(?:ASUS_)?(:?Z01G[DS]|ZS551KL)",
                "model": "ZenFone 4 Pro"
            },
            {
                "regex": "(?:ASUS_)?Z00XS",
                "model": "ZenFone Zoom"
            },
            {
                "regex": "(?:ASUS_)?(?:Z01QD|ZS600KL)",
                "model": "ROG Phone"
            },
            {
                "regex": "(?:ASUS_)?(?:I001D[ABCE]?|ZS660KL)",
                "model": "ROG Phone 2"
            },
            {
                "regex": "(?:ASUS_)?(?:I003DD?|ZS661KS)",
                "model": "ROG Phone 3"
            },
            {
                "regex": "(?:ASUS_)?(?:I005DA)",
                "model": "ROG Phone 5"
            },
            {
                "regex": "ZN220IC",
                "device": "desktop",
                "model": "Zen AiO ZN220IC"
            },
            {
                "regex": "(?:ASUS_)?ZenWatch( 2)?",
                "device": "wearable",
                "model": "ZenWatch$1"
            },
            {
                "regex": "Asus(?:-|;)?([a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "(PadFone(?: [^;/]+)?) Build",
                "model": "$1"
            },
            {
                "regex": "(PadFone(?: [a-z0-9]+)?)",
                "model": "$1"
            },
            {
                "regex": "(?:Asus|Transformer) ((?:Pad |Prime )?TF[0-9a-z]+)",
                "device": "tablet",
                "model": "Transformer $1"
            },
            {
                "regex": "Chromebook Flip (C10[01]PA|C302)",
                "device": "desktop",
                "model": "Chromebook Flip $1"
            }
        ]
    },
    "Audiovox": {
        "regex": "Audiovox|CDM|UTS(?:TARCOM)?\\-|audio(?!book)[a-z0-9\\-]+",
        "device": "smartphone",
        "models": [
            {
                "regex": "Audiovox[_\\-]([a-z0-9\\-]+)",
                "model": "$1"
            },
            {
                "regex": "CDM(?:-)?([a-z0-9]+)",
                "model": "CDM-$1"
            },
            {
                "regex": "UTS(?:TARCOM)?-([a-z0-9\\-]+)",
                "model": "CDM-$1"
            },
            {
                "regex": "audio([a-z0-9\\-]+)",
                "model": "CDM-$1"
            }
        ]
    },
    "AVH": {
        "regex": "Excer[ _]",
        "device": "tablet",
        "models": [
            {
                "regex": "Excer[ _]G5.3",
                "model": "Excer G5.3"
            },
            {
                "regex": "Excer[ _]G5",
                "model": "Excer G5"
            },
            {
                "regex": "Excer[ _]10[ _]PRO",
                "model": "Excer 10 Pro"
            },
            {
                "regex": "Excer[ _]8",
                "model": "Excer 8"
            }
        ]
    },
    "Avvio": {
        "regex": "Avvio[ _]?([a-z0-9\\-]+)|(?:CHIVAS_55|Colombia_2018|Mint_M353)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "CHIVAS_55",
                "model": "Chivas 55"
            },
            {
                "regex": "Mint_M353",
                "model": "Mint M353"
            },
            {
                "regex": "Colombia_2018",
                "model": "Colombia (2018)"
            },
            {
                "regex": "Avvio[ _]PAD",
                "model": "PAD",
                "device": "tablet"
            },
            {
                "regex": "Avvio[ _]?([a-z0-9\\-]+)",
                "model": "$1"
            }
        ]
    },
    "Azumi Mobile": {
        "regex": "Azumi[_ -]|(?:SPEED55|A50_Style_Plus)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Azumi[_ -]([^/;]+) Build",
                "model": "$1"
            },
            {
                "regex": "Azumi[_ -]([a-z0-9_]+)",
                "model": "$1"
            },
            {
                "regex": "SPEED55",
                "model": "Speed 5.5\""
            },
            {
                "regex": "A50_Style_Plus",
                "model": "A50 Style Plus"
            }
        ]
    },
    "Barnes & Noble": {
        "regex": "Nook|BN[TR]V[0-9]+",
        "device": "tablet",
        "models": [
            {
                "regex": "Nook([a-z0-9]+)",
                "model": "Nook $1"
            },
            {
                "regex": "Nook[ _]([^/;]+)[ _]Build",
                "model": "Nook $1"
            },
            {
                "regex": "(BN[TR]V[0-9]+)",
                "model": "Nook $1"
            }
        ]
    },
    "BGH": {
        "regex": "(?!Positivo )BGH ([^/;\\)]+)(?: Build|\\))",
        "device": "smartphone",
        "models": [
            {
                "regex": "Y([17]00|2[01]0|1010)",
                "device": "tablet",
                "model": "Y$1"
            },
            {
                "regex": "BGH (\\+Simple|Mini)",
                "device": "tablet",
                "model": "$1"
            },
            {
                "regex": "Y710 KIDS",
                "device": "tablet",
                "model": "Y710 Kids"
            },
            {
                "regex": "JOY X2",
                "model": "Joy X2"
            },
            {
                "regex": "BGH ([^/;\\)]+)(?: Build|\\))",
                "model": "$1"
            }
        ]
    },
    "Bitel": {
        "regex": "Bitel[ _-]([^/;]+) Build|(?:B(?:840[5-9]|841[0456]|850[2346]|860[146]|9401|950[1-5])|S8402L?|S8501L?)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(B(?:840[5789]|841[0456]|850[2346]|860[146]|9401|950[12345])|S8402L?|S8501L?)",
                "model": "$1"
            },
            {
                "regex": "Bitel[ _-]([^/;]+) Build",
                "model": "$1"
            }
        ]
    },
    "Blu": {
        "regex": "BLU[ _]|(?:blu|Dash)[ _]([^/;)]+)(?: Build|[;)])|(?:Studio[ _](5.0K|5.5|View XL|Mega|C 8\\+8|[CGM][ _]HD|[CGX]|SELFIE|Selfie LTE|Touch|M5 Plus|J[1258]|X8 HD)|Advance (4.0 ?[LM]|5.[02](?: HD)?|A4|L[45])|ENERGY (DIAMOND|XL)|Energy X 2|LIFE XL|B110DL|Dash (?:X[2L]|L3)|PURE (?:X[LR]|MINI)|Life One X2|G90(?: PRO)?|Grand M3|GRAND (?:5.5 HD|XL LTE)|R1 (?:HD|PLUS)|Tank Xtreme [45].0|Tank Xtreme Pro|Touchbook M7 Pro|DASH X PLUS|C[456] 2019|BOLD N1|B130DL|Vivo 5 Mini)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Advance (4.0 ?[LM]|4.0|5.[02](?: HD)?|A4|L[54])",
                "model": "Advance $1"
            },
            {
                "regex": "(?:BLU[_ ])?Studio[ _](5.0 C|5.5|View XL|Mega|C 8\\+8|[CG][ _]HD|C|G|Touch|M HD|M5 Plus|J[1258]|X8 HD|X5|XL|X)(?:[);/ ]|$)",
                "model": "Studio $1"
            },
            {
                "regex": "(?:BLU[_ ])?Studio[ _]5.0 ?K(?:[);/ ]|$)",
                "model": "Studio 5.0 K"
            },
            {
                "regex": "STUDIO SELFIE LTE",
                "model": "Studio Selfie LTE"
            },
            {
                "regex": "STUDIO ONE",
                "model": "Studio One"
            },
            {
                "regex": "STUDIO SELFIE ([23]|LTE)",
                "model": "Studio Selfie $1"
            },
            {
                "regex": "STUDIO SELFIE",
                "model": "Studio Selfie"
            },
            {
                "regex": "(?:BLU[_ ])?NEO_ENERGY_MINI",
                "model": "Neo Energy Mini"
            },
            {
                "regex": "ENERGY DIAMOND",
                "model": "Energy Diamond"
            },
            {
                "regex": "ENERGY XL",
                "model": "Energy XL"
            },
            {
                "regex": "Energy X 2",
                "model": "Energy X 2"
            },
            {
                "regex": "ENERGY X PLUS 2",
                "model": "Energy X Plus 2"
            },
            {
                "regex": "ENERGY X PLUS",
                "model": "Energy X Plus"
            },
            {
                "regex": "LIFE XL",
                "model": "Life XL"
            },
            {
                "regex": "DASH[ _]X[ _]PLUS[ _]LTE",
                "model": "Dash X Plus LTE"
            },
            {
                "regex": "DASH X PLUS",
                "model": "Dash X Plus"
            },
            {
                "regex": "DASH 4.5",
                "model": "Dash 4.5"
            },
            {
                "regex": "Dash XL",
                "model": "Dash XL"
            },
            {
                "regex": "PURE (X[LR])",
                "model": "Pure $1"
            },
            {
                "regex": "PURE MINI",
                "model": "Pure Mini"
            },
            {
                "regex": "BOLD N1",
                "model": "Bold N1"
            },
            {
                "regex": "Life One X2",
                "model": "Life One X2"
            },
            {
                "regex": "LIFE ONE X",
                "model": "Life One X"
            },
            {
                "regex": "LIFE ONE",
                "model": "Life One"
            },
            {
                "regex": "LIFE MARK",
                "model": "Life Mark"
            },
            {
                "regex": "NEO X PLUS",
                "model": "Neo X Plus"
            },
            {
                "regex": "NEO (XL|X)",
                "model": "Neo $1"
            },
            {
                "regex": "G90 PRO",
                "model": "G90 Pro"
            },
            {
                "regex": "G90",
                "model": "G90"
            },
            {
                "regex": "GRAND 5.5 HD",
                "model": "Grand 5.5 HD"
            },
            {
                "regex": "Grand X LTE",
                "model": "Grand X LTE"
            },
            {
                "regex": "Grand XL LTE",
                "model": "Grand XL LTE"
            },
            {
                "regex": "Grand M3",
                "model": "Grand M3"
            },
            {
                "regex": "B110DL",
                "model": "View Mega"
            },
            {
                "regex": "B130DL",
                "model": "View 2"
            },
            {
                "regex": "R1 HD",
                "model": "R1 HD"
            },
            {
                "regex": "R1 PLUS",
                "model": "R1 Plus"
            },
            {
                "regex": "C([456]) (2019)",
                "model": "C$1 ($2)"
            },
            {
                "regex": "Tank Xtreme ([45]).0",
                "model": "Tank Xtreme $1.0\""
            },
            {
                "regex": "Tank Xtreme Pro",
                "model": "Tank Xtreme Pro"
            },
            {
                "regex": "VIVO AIR LTE",
                "model": "Vivo Air LTE"
            },
            {
                "regex": "Touchbook G7",
                "model": "Touchbook G7"
            },
            {
                "regex": "(?:BLU[_ ])?VIVO[ _]SELFIE",
                "model": "Vivo Selfie"
            },
            {
                "regex": "(?:BLU[_ ])?Vivo 5 Mini",
                "model": "Vivo 5 Mini"
            },
            {
                "regex": "Touchbook M7 Pro",
                "model": "Touchbook M7 Pro",
                "device": "tablet"
            },
            {
                "regex": "STUDIO 7.0 II",
                "model": "Studio 7.0\" II",
                "device": "tablet"
            },
            {
                "regex": "DASH (L[45] LTE|5.0|L[45]X|L3|X2|[LMX])(?:[);/ ]|$)",
                "model": "Dash $1"
            },
            {
                "regex": "DASH[ _]([^)/;]+)(?: Build|\\))",
                "model": "Dash $1"
            },
            {
                "regex": "blu[ _]([^/;]+)( Build|[);])",
                "model": "$1"
            }
        ]
    },
    "Bluegood": {
        "regex": "BLUEGOOD",
        "device": "smartphone",
        "models": [
            {
                "regex": "V6",
                "model": "V6"
            }
        ]
    },
    "Blackview": {
        "regex": "Blackview|(BV([24-9]000|[46]900|(5[1589]|9[15689])00|6[138]00)(?:[ _](?:PRO))?|(P10000(?:[ _](?:PRO))?)|omega[ _]pro|Alife[ _][PS]1|Heatwave|DM550|BV9700Pro|(?<!Lenovo[_ ])A[68]0(?:Pro)?\\))|A[86]0Plus|BL6000Pro",
        "device": "smartphone",
        "models": [
            {
                "regex": "A60Pro\\)",
                "model": "A60 Pro"
            },
            {
                "regex": "A60\\)",
                "model": "A60"
            },
            {
                "regex": "A80Pro(?:[);/ ]|$)",
                "model": "A80 Pro"
            },
            {
                "regex": "A([86]0)Plus(?:[);/ ]|$)",
                "model": "A$1 Plus"
            },
            {
                "regex": "(BV6000S)",
                "model": "$1"
            },
            {
                "regex": "(BV9700|BL6000)Pro",
                "model": "$1 Pro"
            },
            {
                "regex": "Blackview (A[78]|E7S)",
                "model": "$1"
            },
            {
                "regex": "BV9000Pro-F",
                "model": "BV9000 Pro F"
            },
            {
                "regex": "(BV([48]000|9[56]00|6[83]00))Pro",
                "model": "$1 Pro"
            },
            {
                "regex": "(BV6[13]00)(?:[);/ ]|$)",
                "model": "$1",
                "device": "phablet"
            },
            {
                "regex": "(BV([24-9]000|6900|4900|(5[1589]|9[15689])00)(?:[ _](?:PRO))?)",
                "model": "$1"
            },
            {
                "regex": "(P10000(?:[ _](?:PRO))?)",
                "model": "$1"
            },
            {
                "regex": "omega[ _]pro",
                "model": "Omega Pro"
            },
            {
                "regex": "(Alife[ _][PS]1|DM550|Heatwave)",
                "model": "$1"
            }
        ]
    },
    "Bluboo": {
        "regex": "Bluboo|Xfire|Picasso 4G|Maya Build",
        "device": "smartphone",
        "models": [
            {
                "regex": "Maya Build",
                "model": "Maya"
            },
            {
                "regex": "Xfire2",
                "model": "Xfire 2"
            },
            {
                "regex": "Picasso 4G",
                "model": "Picasso 4G"
            },
            {
                "regex": "Xfire",
                "model": "Xfire"
            },
            {
                "regex": "Bluboo[ _-]?([^;/)]+) Build",
                "model": "$1"
            },
            {
                "regex": "Bluboo[ _-]?([^;/)]+)",
                "model": "$1"
            }
        ]
    },
    "Black Fox": {
        "regex": "BMM[ _-]?(?:44[123]D|441[WB]|44[12]S|431D|533D|54[12]S|541[ABW]|54[123]D|543S|531[ABD]|532 S)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "BMM443D",
                "model": "B7 Fox+"
            },
            {
                "regex": "BMM441S",
                "model": "B8 Fox"
            },
            {
                "regex": "BMM442S|BMM441B",
                "model": "B8 Fox+"
            },
            {
                "regex": "BMM441W",
                "model": "B8M"
            },
            {
                "regex": "BMM442D",
                "model": "B7"
            },
            {
                "regex": "BMM531D",
                "model": "B6"
            },
            {
                "regex": "BMM531A",
                "model": "B5"
            },
            {
                "regex": "BMM541A",
                "model": "B5+"
            },
            {
                "regex": "BMM543[DS]",
                "model": "B4"
            },
            {
                "regex": "BMM441D",
                "model": "B4 Mini"
            },
            {
                "regex": "BMM541B",
                "model": "B4 Mini NFC"
            },
            {
                "regex": "BMM531B",
                "model": "B4 Mini (2019)"
            },
            {
                "regex": "BMM542D",
                "model": "B3+"
            },
            {
                "regex": "BMM541D",
                "model": "B3"
            },
            {
                "regex": "BMM541W",
                "model": "B7R Fox"
            },
            {
                "regex": "BMM 532 S",
                "model": "BMM 532S"
            },
            {
                "regex": "BMM[ _-]?(533D|431D|54[12]S)(?:[);/ ]|$)",
                "model": "BMM $1"
            }
        ]
    },
    "bogo": {
        "regex": "BO-(FRSP4|LFSPBS5|LFSP4|LFSPSL4|LFSPBS5|LFSPSL6QCI)",
        "device": "smartphone",
        "models": [
            {
                "regex": "BO-LFSP4",
                "model": "LifeStyle 4DC"
            },
            {
                "regex": "BO-LFSPSL4",
                "model": "LifeStyle 4SL"
            },
            {
                "regex": "BO-LFSPBS5",
                "model": "LifeStyle 5BS"
            },
            {
                "regex": "BO-LFSPSL6QCI",
                "model": "LifeStyle 6QC"
            },
            {
                "regex": "BO-(FRSP4)",
                "model": "$1"
            }
        ]
    },
    "Boway": {
        "regex": "(?:sprd-)?(BOWAY)",
        "device": "smartphone",
        "models": [
            {
                "regex": "BOWAY[ _-]([^/;]+)[ _]Build",
                "model": "$1"
            },
            {
                "regex": "([^/;]+) Build/BOWAY",
                "model": "$1"
            },
            {
                "regex": ".*BOWAY[ _-]([^/;]+)/",
                "model": "$1"
            }
        ]
    },
    "Brondi": {
        "regex": "Brondi[ _]|(?:CENTURION|GLADIATOR| GLORY|LUXURY|SENSUELLE|VICTORY)(?:[ _-]?[2-6])?(?:[);/ ]|$)|Surfing Tab|501 SZ",
        "device": "smartphone",
        "models": [
            {
                "regex": "(?:Brondi[ _])?(620 SZ|730_4G_HD|501 SZ)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "CENTURION(?:[ _-]?([2-6]))?(?:[);/ ]|$)",
                "model": "CENTURION $1"
            },
            {
                "regex": "GLADIATOR(?:[ _-]?([2-6]))?(?:[);/ ]|$)",
                "model": "GLADIATOR $1"
            },
            {
                "regex": "GLORY(?:[ _-]?([2-6]))?(?:[);/ ]|$)",
                "model": "GLORY $1"
            },
            {
                "regex": "LUXURY(?:[ _-]?([2-6]))?(?:[);/ ]|$)",
                "model": "LUXURY $1"
            },
            {
                "regex": "SENSUELLE(?:[ _-]?([2-6]))?(?:[);/ ]|$)",
                "model": "SENSUELLE $1"
            },
            {
                "regex": "VICTORY(?:[ _-]?([2-6]))?(?:[);/ ]|$)",
                "model": "VICTORY $1"
            },
            {
                "regex": "Surfing Tab ([^;/]+) Build",
                "model": "SURFING TAB $1",
                "device": "tablet"
            },
            {
                "regex": "Surfing Tab ([^;/)]+)[;/)]",
                "model": "SURFING TAB $1",
                "device": "tablet"
            }
        ]
    },
    "VGO TEL": {
        "regex": "(?:Venture V(?:[278]|12)|Ocean[_ ][689]|Ocean9L)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Venture V([278]|12)(?:[);/ ]|$)",
                "model": "Venture V$1"
            },
            {
                "regex": "Ocean[_ ]?([689]|9L)(?:[);/ ]|$)",
                "model": "Ocean $1"
            }
        ]
    },
    "Vivo": {
        "regex": "((?:VIV-|BBG-)?(?<!FBCR/)vivo(?!(?:Browser)))|(?:V1730(D[AT]|GA)|V18(18CA|01A0|13B[AT]|18T|09[AT]|1[346][AT]|[13]8[AT]|14A|24[B]?A|2[19][AT]|3[12][AT]|36[AT])|V1731CA|V1732[AT]|V1818CT|V19[01]1[AT]|V1932[AT]|V191[3469][AT]|V192[1348]A|V193[04]A|V194[15]A|V1938CT|V1955A|V1938T|V1730EA|V19[26]2A|V196[35]A|V198[16]A|V1936A[L]?|V19[59]0A|V200[125]A|1819|V201[12]A|V202[0345]C?A|V202[235-9]|V2054A|V203[026]|V204[03]|V2049A|X50 Pro\\+|I1927)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "V1824[B]?A(?:[);/ ]|$)",
                "model": "iQOO"
            },
            {
                "regex": "V1955A(?:[);/ ]|$)",
                "model": "iQOO 3"
            },
            {
                "regex": "I1927(?:[);/ ]|$)",
                "model": "iQOO 3 5G"
            },
            {
                "regex": "V2049A(?:[);/ ]|$)",
                "model": "iQOO 7"
            },
            {
                "regex": "V1922A(?:[);/ ]|$)",
                "model": "iQOO Pro"
            },
            {
                "regex": "V1916A(?:[);/ ]|$)",
                "model": "iQOO Pro 5G"
            },
            {
                "regex": "V1914A(?:[);/ ]|$)",
                "model": "iQOO Neo"
            },
            {
                "regex": "V1981A(?:[);/ ]|$)",
                "model": "iQOO Neo 3"
            },
            {
                "regex": "V1936A[L]?(?:[);/ ]|$)",
                "model": "iQOO Neo 855"
            },
            {
                "regex": "V1986A(?:[);/ ]|$)",
                "model": "iQOO Z1"
            },
            {
                "regex": "V2012A(?:[);/ ]|$)",
                "model": "iQOO Z1x"
            },
            {
                "regex": "V1919A(?:[);/ ]|$)",
                "model": "iQOO Z5x"
            },
            {
                "regex": "V2023A(?:[);/ ]|$)",
                "model": "iQOO U1"
            },
            {
                "regex": "V2024A(?:[);/ ]|$)",
                "model": "iQOO 5"
            },
            {
                "regex": "V2025A(?:[);/ ]|$)",
                "model": "iQOO 5 Pro"
            },
            {
                "regex": "V2025(?:[);/ ]|$)",
                "model": "V20"
            },
            {
                "regex": "V2040(?:[);/ ]|$)",
                "model": "V20 (2021)"
            },
            {
                "regex": "vivo 1805(?:[);/ ]|$)",
                "model": "Nex"
            },
            {
                "regex": "V1923A(?:[);/ ]|$)",
                "model": "Nex 3"
            },
            {
                "regex": "V1924A(?:[);/ ]|$)",
                "model": "Nex 3 5G"
            },
            {
                "regex": "V1950A(?:[);/ ]|$)",
                "model": "Nex 3S"
            },
            {
                "regex": "V1821[AT](?:[);/ ]|$)",
                "model": "Nex Dual Display"
            },
            {
                "regex": "Vivo ONE(?:[);/ ]|$)",
                "model": "One"
            },
            {
                "regex": "V1831[AT](?:[);/ ]|$)",
                "model": "S1"
            },
            {
                "regex": "V1832[AT](?:[);/ ]|$)",
                "model": "S1 Pro"
            },
            {
                "regex": "V1932[AT](?:[);/ ]|$)",
                "model": "S5"
            },
            {
                "regex": "V1962A(?:[);/ ]|$)",
                "model": "S6 5G"
            },
            {
                "regex": "V2020C?A(?:[);/ ]|$)",
                "model": "S7"
            },
            {
                "regex": "V1941A(?:[);/ ]|$)",
                "model": "U3"
            },
            {
                "regex": "V1928A(?:[);/ ]|$)",
                "model": "U3X"
            },
            {
                "regex": "vivo 1804(?:[);/ ]|$)",
                "model": "V11 Pro"
            },
            {
                "regex": "vivo 1806(?:[);/ ]|$)",
                "model": "V11i"
            },
            {
                "regex": "1819(?:[);/ ]|$)",
                "model": "V15"
            },
            {
                "regex": "vivo 1818(?:[);/ ]|$)",
                "model": "V15 Pro"
            },
            {
                "regex": "vivo 1909(?:[);/ ]|$)",
                "model": "V17 Pro"
            },
            {
                "regex": "V2022(?:[);/ ]|$)",
                "model": "V20 SE"
            },
            {
                "regex": "(?:VIV-|BBG-)?vivo[ _]1601(?:[);/ ]|$)",
                "model": "V5"
            },
            {
                "regex": "vivo 1609(?:[);/ ]|$)",
                "model": "V5 Lite"
            },
            {
                "regex": "vivo 1611(?:[);/ ]|$)",
                "model": "V5 Plus"
            },
            {
                "regex": "vivo (1713|1612)(?:[);/ ]|$)",
                "model": "V5s"
            },
            {
                "regex": "vivo 1718(?:[);/ ]|$)",
                "model": "V7"
            },
            {
                "regex": "vivo 1716(?:[);/ ]|$)",
                "model": "V7 Plus"
            },
            {
                "regex": "vivo 1723(?:[);/ ]|$)",
                "model": "V9"
            },
            {
                "regex": "vivo 1727(?:[);/ ]|$)",
                "model": "V9 Youth"
            },
            {
                "regex": "vivo 1721(?:[);/ ]|$)",
                "model": "X20"
            },
            {
                "regex": "X20Plus(?:[);/ ]|$)",
                "model": "X20 Plus"
            },
            {
                "regex": "vivo 1725(?:[);/ ]|$)",
                "model": "X21"
            },
            {
                "regex": "V1814[AT](?:[);/ ]|$)",
                "model": "X21S"
            },
            {
                "regex": "V1809[AT](?:[);/ ]|$)",
                "model": "X23"
            },
            {
                "regex": "V1816[AT](?:[);/ ]|$)",
                "model": "X23"
            },
            {
                "regex": "V18(38|29)[AT](?:[);/ ]|$)",
                "model": "X27"
            },
            {
                "regex": "V1836[AT](?:[);/ ]|$)",
                "model": "X27 Pro"
            },
            {
                "regex": "V1938CT(?:[);/ ]|$)",
                "model": "X30"
            },
            {
                "regex": "V1938T(?:[);/ ]|$)",
                "model": "X30 Pro"
            },
            {
                "regex": "V2001A(?:[);/ ]|$)",
                "model": "X50"
            },
            {
                "regex": "V2005A(?:[);/ ]|$)",
                "model": "X50 Pro"
            },
            {
                "regex": "(?:V2011A|X50 Pro\\+)(?:[);/ ]|$)",
                "model": "X50 Pro Plus"
            },
            {
                "regex": "X9Plus(?:[);/ ]|$)",
                "model": "X9 Plus"
            },
            {
                "regex": "vivo 1902(?:[);/ ]|$)",
                "model": "Y17"
            },
            {
                "regex": "vivo 1915(?:[);/ ]|$)",
                "model": "Y19"
            },
            {
                "regex": "V202[79](?:[);/ ]|$)",
                "model": "Y20"
            },
            {
                "regex": "V2028(?:[);/ ]|$)",
                "model": "Y11s"
            },
            {
                "regex": "V2043(?:[);/ ]|$)",
                "model": "Y20 (2021)"
            },
            {
                "regex": "V2032(?:[);/ ]|$)",
                "model": "Y20i"
            },
            {
                "regex": "V2023(?:[);/ ]|$)",
                "model": "V20 SE"
            },
            {
                "regex": "V1901[AT](?:[);/ ]|$)",
                "model": "Y3"
            },
            {
                "regex": "V1930A(?:[);/ ]|$)",
                "model": "Y3 Standard"
            },
            {
                "regex": "V2036(?:[);/ ]|$)",
                "model": "Y31"
            },
            {
                "regex": "V1934A(?:[);/ ]|$)",
                "model": "Y5s"
            },
            {
                "regex": "V1965A(?:[);/ ]|$)",
                "model": "Y50"
            },
            {
                "regex": "V2030(?:[);/ ]|$)",
                "model": "Y51"
            },
            {
                "regex": "V2054A(?:[);/ ]|$)",
                "model": "Y31s"
            },
            {
                "regex": "vivo 1606(?:[);/ ]|$)",
                "model": "Y53i"
            },
            {
                "regex": "vivo 1603(?:[);/ ]|$)",
                "model": "Y55l"
            },
            {
                "regex": "vivo 1610(?:[);/ ]|$)",
                "model": "Y55s"
            },
            {
                "regex": "vivo 1719(?:[);/ ]|$)",
                "model": "Y65"
            },
            {
                "regex": "vivo 1714(?:[);/ ]|$)",
                "model": "Y69"
            },
            {
                "regex": "V2002A(?:[);/ ]|$)",
                "model": "Y70s"
            },
            {
                "regex": "vivo 1724(?:[);/ ]|$)",
                "model": "Y71"
            },
            {
                "regex": "vivo 1801(?:[);/ ]|$)",
                "model": "Y71i"
            },
            {
                "regex": "V1731CA(?:[);/ ]|$)",
                "model": "Y73"
            },
            {
                "regex": "V1913[AT](?:[);/ ]|$)",
                "model": "Y7s"
            },
            {
                "regex": "vivo 180([38]|8i)(?:[);/ ]|$)",
                "model": "Y81"
            },
            {
                "regex": "vivo 1812(?:[);/ ]|$)",
                "model": "Y81i"
            },
            {
                "regex": "V1732[AT](?:[);/ ]|$)",
                "model": "Y81s"
            },
            {
                "regex": "vivo 1726(?:[);/ ]|$)",
                "model": "Y83 Pro"
            },
            {
                "regex": "V1730EA(?:[);/ ]|$)",
                "model": "Y89"
            },
            {
                "regex": "vivo 181[17](?:[);/ ]|$)",
                "model": "Y91"
            },
            {
                "regex": "V1945A(?:[);/ ]|$)",
                "model": "Y9s"
            },
            {
                "regex": "V2026(?:[);/ ]|$)",
                "model": "Y12s"
            },
            {
                "regex": "vivo 18(16|20)(?:[);/ ]|$)",
                "model": "Y91i"
            },
            {
                "regex": "(V1818[AT]|vivo 181[45])(?:[);/ ]|$)",
                "model": "Y93"
            },
            {
                "regex": "V1818C[AT](?:[);/ ]|$)",
                "model": "Y93s"
            },
            {
                "regex": "vivo 1807(?:[);/ ]|$)",
                "model": "Y95"
            },
            {
                "regex": "V1813[AT](?:[);/ ]|$)",
                "model": "Y97"
            },
            {
                "regex": "V1801A0(?:[);/ ]|$)",
                "model": "Z1"
            },
            {
                "regex": "V1730D[AT](?:[);/ ]|$)",
                "model": "Z1i"
            },
            {
                "regex": "vivo 1917(?:[);/ ]|$)",
                "model": "Z1x"
            },
            {
                "regex": "V1813B[AT](?:[);/ ]|$)",
                "model": "Z3"
            },
            {
                "regex": "V1730GA(?:[);/ ]|$)",
                "model": "Z3x"
            },
            {
                "regex": "V1921A(?:[);/ ]|$)",
                "model": "Z5"
            },
            {
                "regex": "V1911A(?:[);/ ]|$)",
                "model": "Z5x"
            },
            {
                "regex": "V1990A(?:[);/ ]|$)",
                "model": "Z5x (2020)"
            },
            {
                "regex": "V1963A(?:[);/ ]|$)",
                "model": "Z6"
            },
            {
                "regex": "(?:VIV-|BBG-)?vivo[ _]([^/;]+) Build",
                "model": "$1"
            },
            {
                "regex": "(?:VIV-|BBG-)?vivo[ _]([^);/]+)(?:[);/]|$)",
                "model": "$1"
            }
        ]
    },
    "Vinsoc": {
        "regex": "(XA Pro) Build",
        "device": "smartphone",
        "model": "$1"
    },
    "Bird": {
        "regex": "BIRD[\\-. _]([^;/]+)",
        "device": "feature phone",
        "models": [
            {
                "regex": "BIRD[\\-. _]([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "BIRD[\\-. _]([^;/]+)",
                "model": "$1"
            }
        ]
    },
    "Becker": {
        "regex": "Becker-([a-z0-9]+)",
        "device": "feature phone",
        "model": "$1"
    },
    "Beeline": {
        "regex": "Beeline",
        "device": "smartphone",
        "models": [
            {
                "regex": "Beeline[_ ]Pro[_ ]([2436])",
                "model": "Pro $1"
            },
            {
                "regex": "Tab (Fast|Pro)",
                "model": "Tab $1",
                "device": "tablet"
            },
            {
                "regex": "Tab ([0-9])",
                "model": "Tab $1",
                "device": "tablet"
            },
            {
                "regex": "Tab",
                "model": "Tab",
                "device": "tablet"
            },
            {
                "regex": "E700",
                "model": "E700"
            },
            {
                "regex": "Smart2",
                "model": "Smart 2"
            },
            {
                "regex": "Smart ([0-9])",
                "model": "Smart $1"
            },
            {
                "regex": "Smart Dual",
                "model": "Smart Dual"
            },
            {
                "regex": "(?:Beeline[_ ])?Fast[_ ]2(?:[);/ ]|$)",
                "model": "Fast 2"
            },
            {
                "regex": "Fast(?:[);/ ]|$)",
                "model": "Fast"
            }
        ]
    },
    "Beetel": {
        "regex": "Beetel ([a-z0-9]+)",
        "device": "feature phone",
        "model": "$1"
    },
    "BenQ-Siemens": {
        "regex": "BENQ-SIEMENS - ([a-z0-9]+)",
        "device": "feature phone",
        "model": "$1"
    },
    "BenQ": {
        "regex": "BENQ(?:[ \\-])?([a-z0-9]+)|F52_09(?:[);/ ]|$)",
        "device": "feature phone",
        "models": [
            {
                "regex": "F52_09(?:[);/ ]|$)",
                "model": "F52"
            },
            {
                "regex": "BENQ(?:[ \\-])?([a-z0-9]+)",
                "model": "$1"
            }
        ]
    },
    "Blaupunkt": {
        "regex": "Blaupunkt|Soundphone S2|Atlantis[_ ](?:1001A|1010A|A10\\.G40[23])|SLplus02|Discovery[_ ](?:A10_302|111C|1000C|1001A?)|Endeavour[_ ](?:785|101[GLM]|1000|1001|101[03]|1100)|Polaris[_ ]803|BP_6010",
        "device": "tablet",
        "models": [
            {
                "regex": "Discovery[_ ]111C",
                "model": "Discovery 111C"
            },
            {
                "regex": "Discovery[_ ]A10_302",
                "model": "Discovery A10.302"
            },
            {
                "regex": "Discovery[_ ]1000C",
                "model": "Discovery 1000C"
            },
            {
                "regex": "Discovery[_ ]1001A",
                "model": "Discovery 1001A"
            },
            {
                "regex": "Discovery[_ ]1001",
                "model": "Discovery 1001"
            },
            {
                "regex": "Soundphone S2",
                "model": "Soundphone S2"
            },
            {
                "regex": "Endeavour[_ ](101[GLM]|785|100[01]|101[03]|1100)",
                "model": "Endeavour $1"
            },
            {
                "regex": "Polaris[_ ](803)",
                "model": "Polaris $1"
            },
            {
                "regex": "Atlantis[_ ](1001A|1010A|A10\\.G40[23])",
                "model": "Atlantis $1"
            },
            {
                "regex": "SLplus02",
                "model": "SL Plus 02"
            },
            {
                "regex": "(BP_6010)",
                "model": "$1"
            },
            {
                "regex": "Blaupunkt[_ ](SL[_ ]0[124]|SM[_ ]0[12])",
                "device": "smartphone",
                "model": "$1"
            },
            {
                "regex": "(4K Android TV)",
                "device": "tv",
                "model": "$1"
            }
        ]
    },
    "Bmobile": {
        "regex": "Bmobile[_ ]|AX-?(?:[1-9][0-9]{2,3}[eEO+]?|7OO)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "AX-?([1-9][0-9]{2,3}[eEO+]?)",
                "model": "AX$1"
            },
            {
                "regex": "AX7OO",
                "model": "AX700"
            },
            {
                "regex": "Bmobile[_ ]([^/;]+) Build",
                "model": "$1"
            },
            {
                "regex": "Bmobile[_ ]([a-z0-9]+)",
                "model": "$1"
            }
        ]
    },
    "bq": {
        "regex": "Aquaris|bq [^/;]+ Build|BQS[-_]([0-9]{4})|(BQ(ru)?[_ -][0-9]{3,4})|BQ5059|BQ1008G|BQru_BQru-",
        "device": "smartphone",
        "models": [
            {
                "regex": "BQ(?:ru)?-(?:5209L|5044)(?:[);/ ]|$)",
                "model": "Strike LTE"
            },
            {
                "regex": "BQ(?:ru)?-(?:6035L)(?:[);/ ]|$)",
                "model": "Strike Power Max"
            },
            {
                "regex": "BQ(?:ru)?-(?:5535L)(?:[);/ ]|$)",
                "model": "Strike Power Plus"
            },
            {
                "regex": "BQ(S|ru)?-(?:5070|6040L)(?:[);/ ]|$)",
                "model": "Magic"
            },
            {
                "regex": "BQ(S|ru)?-(?:6424L)(?:[);/ ]|$)",
                "model": "Magic O"
            },
            {
                "regex": "BQ(?:S|ru)?-(?:5730L)(?:[);/ ]|$)",
                "model": "Magic C"
            },
            {
                "regex": "BQ(?:S|ru)?-(?:5731L)(?:[);/ ]|$)",
                "model": "Magic S"
            },
            {
                "regex": "BQ(?:S|ru)?-5745L(?:[);/ ]|$)",
                "model": "Clever"
            },
            {
                "regex": "BQ(?:S|ru)?-5533G(?:[);/ ]|$)",
                "model": "Fresh"
            },
            {
                "regex": "BQ(?:S|ru)?-5031G(?:[);/ ]|$)",
                "model": "Fun"
            },
            {
                "regex": "BQ(?:S|ru)?-(?:5732L)(?:[);/ ]|$)",
                "model": "Aurora SE"
            },
            {
                "regex": "BQ(?:S|ru)?-6430L(?:[);/ ]|$)",
                "model": "Aurora"
            },
            {
                "regex": "BQ(?:S|ru)?-6630L(?:[);/ ]|$)",
                "model": "Magic L"
            },
            {
                "regex": "BQ-9011[G]?(?:[);/ ]|$)",
                "model": "Vision",
                "device": "tablet"
            },
            {
                "regex": "BQ-8068L(?:[);/ ]|$)",
                "model": "Hornet Plus Pro"
            },
            {
                "regex": "BQ-8067L",
                "model": "Hornet Plus",
                "device": "tablet"
            },
            {
                "regex": "BQ-8052G",
                "model": "BQ-8052G",
                "device": "tablet"
            },
            {
                "regex": "BQ-8041L",
                "model": "Art",
                "device": "tablet"
            },
            {
                "regex": "BQ-7084G",
                "model": "Simple",
                "device": "tablet"
            },
            {
                "regex": "BQ-7061G",
                "model": "Andros",
                "device": "tablet"
            },
            {
                "regex": "BQ(ru)?-(7082)",
                "model": "Armor",
                "device": "tablet"
            },
            {
                "regex": "BQ-7051G",
                "model": "Elba",
                "device": "tablet"
            },
            {
                "regex": "BQ-7036L",
                "model": "Hornet",
                "device": "tablet"
            },
            {
                "regex": "BQ-7022(G)?",
                "model": "Canion",
                "device": "tablet"
            },
            {
                "regex": "BQ-7021G",
                "model": "Hit",
                "device": "tablet"
            },
            {
                "regex": "BQ-7010G",
                "model": "Max",
                "device": "tablet"
            },
            {
                "regex": "BQ-7006G",
                "model": "Union",
                "device": "tablet"
            },
            {
                "regex": "BQ-7003",
                "model": "Jamaica",
                "device": "tablet"
            },
            {
                "regex": "BQ-6200L|BQ-6000L",
                "model": "Aurora"
            },
            {
                "regex": "BQ-6631G",
                "model": "Surf"
            },
            {
                "regex": "BQ-6022G",
                "model": "Aura"
            },
            {
                "regex": "BQ(ru)?[ -]6015L",
                "model": "Universe"
            },
            {
                "regex": "BQ(?:S|ru)?-(?:5525|6030G|6010G)",
                "model": "Practic"
            },
            {
                "regex": "BQ-6001L|BQ-6050",
                "model": "Jumbo"
            },
            {
                "regex": "BQ-5707G(?:[);/ ]|$)",
                "model": "Next Music"
            },
            {
                "regex": "BQ-5702(?:[);/ ]|$)",
                "model": "Spring"
            },
            {
                "regex": "BQ-5700L(?:[);/ ]|$)",
                "model": "Space X"
            },
            {
                "regex": "BQ-(?:5591|551[89]G)(?:[);/ ]|$)",
                "model": "Jeans"
            },
            {
                "regex": "BQ-5522",
                "model": "Next"
            },
            {
                "regex": "BQ-5520L(?:[);/ ]|$)",
                "model": "Silk"
            },
            {
                "regex": "BQ-5519L(?:[);/ ]|$)",
                "model": "Fast Plus"
            },
            {
                "regex": "BQ-5540L(?:[);/ ]|$)",
                "model": "Fast Pro"
            },
            {
                "regex": "BQ-5517L(?:[);/ ]|$)",
                "model": "Twin Pro"
            },
            {
                "regex": "BQ-5516L(?:[);/ ]|$)",
                "model": "Twin"
            },
            {
                "regex": "BQS-5515(?:[);/ ]|$)",
                "model": "Wide"
            },
            {
                "regex": "BQ-5515[L]?(?:[);/ ]|$)",
                "model": "Fast"
            },
            {
                "regex": "BQ-(?:5512L|5528L)(?:[);/ ]|$)",
                "model": "Strike Forward"
            },
            {
                "regex": "BQ-5511L(?:[);/ ]|$)",
                "model": "Bliss"
            },
            {
                "regex": "BQS[_ ]5505(?:[);/ ]|$)",
                "model": "Amsterdam"
            },
            {
                "regex": "BQ(?:ru)?-5510(?:[);/ ]|$)",
                "model": "Strike Power Max 4G"
            },
            {
                "regex": "BQ(?:ru)?-(?:5521|5594)(?:[);/ ]|$)",
                "model": "Strike Power Max"
            },
            {
                "regex": "BQ-5508L(?:[);/ ]|$)",
                "model": "Next LTE"
            },
            {
                "regex": "BQ-5507L(?:[);/ ]|$)",
                "model": "Iron Max"
            },
            {
                "regex": "BQ(?:ru)?-5504(?:[);/ ]|$)",
                "model": "Strike Selfie Max"
            },
            {
                "regex": "BQ(?:ru)?-5503(?:[);/ ]|$)",
                "model": "Nice 2"
            },
            {
                "regex": "BQ(?:ru)?-6045L(?:[);/ ]|$)",
                "model": "Nice"
            },
            {
                "regex": "BQ(?:ru)?-4030G(?:[);/ ]|$)",
                "model": "Nice Mini"
            },
            {
                "regex": "BQS-5502(?:[);/ ]|$)",
                "model": "Hammer"
            },
            {
                "regex": "BQS-5501(?:[);/ ]|$)",
                "model": "Kawasaki"
            },
            {
                "regex": "BQS-5500(?:[);/ ]|$)",
                "model": "Vancouver"
            },
            {
                "regex": "BQ-5500L(?:[);/ ]|$)",
                "model": "Advance"
            },
            {
                "regex": "BQ-5302G(?:[);/ ]|$)",
                "model": "Velvet 2"
            },
            {
                "regex": "BQ-5301(?:[);/ ]|$)",
                "model": "Strike View"
            },
            {
                "regex": "BQ-5300G(?:[);/ ]|$)",
                "model": "Velvet View"
            },
            {
                "regex": "BQ(ru|S)?-(?:5211|5020)(?:[);/ ]|$)",
                "model": "Strike"
            },
            {
                "regex": "BQ-5206L(?:[);/ ]|$)",
                "model": "Balance"
            },
            {
                "regex": "BQ(?:ru|S)?-(?:5204|5050)(?:[);/ ]|$)",
                "model": "Strike Selfie"
            },
            {
                "regex": "BQ(?:ru)?-5203(?:[);/ ]|$)",
                "model": "Vision"
            },
            {
                "regex": "BQ(?:ru)?-5202(?:[);/ ]|$)",
                "model": "Space Lite"
            },
            {
                "regex": "BQ(?:ru)?-5201(?:[);/ ]|$)",
                "model": "Space"
            },
            {
                "regex": "BQ-5082(?:[);/ ]|$)",
                "model": "Sense 2"
            },
            {
                "regex": "BQ-5045L(?:[);/ ]|$)",
                "model": "Wallet"
            },
            {
                "regex": "BQ-5047L(?:[);/ ]|$)",
                "model": "Like"
            },
            {
                "regex": "BQ-5071(?:[);/ ]|$)",
                "model": "Belief"
            },
            {
                "regex": "BQ[S]?-(?:5065|5340)(?:[);/ ]|$)",
                "model": "Choice"
            },
            {
                "regex": "BQ(?:S|ru)?-5046L(?:[);/ ]|$)",
                "model": "Choice LTE"
            },
            {
                "regex": "BQ(?:ru)?-?(?:5059|5514G)(?:[);/ ]|$)",
                "model": "Strike Power"
            },
            {
                "regex": "(?:BQru_)?BQ(?:ru)?-5058(?:[);/ ]|$)",
                "model": "Strike Power Easy"
            },
            {
                "regex": "BQ(?:ru)?-5057(?:[);/ ]|$)",
                "model": "Strike 2"
            },
            {
                "regex": "BQ[S]?-(?:5056|5030)(?:[);/ ]|$)",
                "model": "Fresh"
            },
            {
                "regex": "BQ-6042l(?:[);/ ]|$)",
                "model": "Magic E"
            },
            {
                "regex": "BQS-5055(?:[);/ ]|$)",
                "model": "Turbo Plus"
            },
            {
                "regex": "BQ(?:ru)?-5054(?:[);/ ]|$)",
                "model": "Crystal"
            },
            {
                "regex": "BQ-5052(?:[);/ ]|$)",
                "model": "Sense"
            },
            {
                "regex": "BQ[S]?-5045(?:[);/ ]|$)",
                "model": "Fast"
            },
            {
                "regex": "BQ(?:ru)?-(?:5037|5514L)(?:[);/ ]|$)",
                "model": "Strike Power 4G"
            },
            {
                "regex": "BQ(?:ru)?-5035(?:[);/ ]|$)",
                "model": "Velvet"
            },
            {
                "regex": "BQ(?:ru)?-5033(?:[);/ ]|$)",
                "model": "Shark"
            },
            {
                "regex": "BQ-5541L(?:[);/ ]|$)",
                "model": "Shark Rush"
            },
            {
                "regex": "BQ-5032(?:[);/ ]|$)",
                "model": "Element"
            },
            {
                "regex": "BQS-5025(?:[);/ ]|$)",
                "model": "High Way"
            },
            {
                "regex": "BQ(?:ru)?-5022(?:[);/ ]|$)",
                "model": "Bond"
            },
            {
                "regex": "BQ-5015L(?:[);/ ]|$)",
                "model": "First"
            },
            {
                "regex": "BQ-5016G(?:[);/ ]|$)",
                "model": "Choice"
            },
            {
                "regex": "BQ-5012L(?:[);/ ]|$)",
                "model": "Rich"
            },
            {
                "regex": "BQ-5521L(?:[);/ ]|$)",
                "model": "Rich Max"
            },
            {
                "regex": "BQS-5011(?:[);/ ]|$)",
                "model": "Monte Carlo"
            },
            {
                "regex": "BQ-5010G(?:[);/ ]|$)",
                "model": "Spot"
            },
            {
                "regex": "BQ-5000G(?:[);/ ]|$)",
                "model": "Velvet Easy"
            },
            {
                "regex": "BQS-5010(?:[);/ ]|$)",
                "model": "Prague"
            },
            {
                "regex": "BQ-5009L(?:[);/ ]|$)",
                "model": "Trend"
            },
            {
                "regex": "BQS-500[59](?:[);/ ]|$)",
                "model": "Sydney"
            },
            {
                "regex": "BQ-5008L(?:[);/ ]|$)",
                "model": "Brave"
            },
            {
                "regex": "BQ-5007L(?:[);/ ]|$)",
                "model": "Iron"
            },
            {
                "regex": "BQS-5006(?:[);/ ]|$)",
                "model": "Los Angeles"
            },
            {
                "regex": "BQ-(?:5005L|5530L)(?:[);/ ]|$)",
                "model": "Intense"
            },
            {
                "regex": "BQS-5004(?:[);/ ]|$)",
                "model": "Paris"
            },
            {
                "regex": "BQ-5003L(?:[);/ ]|$)",
                "model": "Shark Pro"
            },
            {
                "regex": "BQ-5002G(?:[);/ ]|$)",
                "model": "Fun"
            },
            {
                "regex": "BQS-5002(?:[);/ ]|$)",
                "model": "Colombo"
            },
            {
                "regex": "BQS-5003(?:[);/ ]|$)",
                "model": "Colombo II"
            },
            {
                "regex": "BQ-5001L(?:[);/ ]|$)",
                "model": "Contact"
            },
            {
                "regex": "BQS-5001(?:[);/ ]|$)",
                "model": "Milan"
            },
            {
                "regex": "BQ(?:ru|S)?-5000(?:[);/ ]|$)",
                "model": "Tokyo"
            },
            {
                "regex": "BQS-4800(?:[);/ ]|$)",
                "model": "Blade"
            },
            {
                "regex": "BQS-4707(?:[);/ ]|$)",
                "model": "Montreal"
            },
            {
                "regex": "BQS-4702(?:[);/ ]|$)",
                "model": "Оsaka"
            },
            {
                "regex": "BQS-4701(?:[);/ ]|$)",
                "model": "Venice"
            },
            {
                "regex": "BQ-(?:4585|5011G)(?:[);/ ]|$)",
                "model": "Fox View"
            },
            {
                "regex": "BQ(?:ru)?-4583(?:[);/ ]|$)",
                "model": "Fox Power"
            },
            {
                "regex": "BQS-4570(?:[);/ ]|$)",
                "model": "Drive"
            },
            {
                "regex": "BQS-4560(?:[);/ ]|$)",
                "model": "Golf"
            },
            {
                "regex": "BQS-4555(?:[);/ ]|$)",
                "model": "Turbo"
            },
            {
                "regex": "BQS-4550(?:[);/ ]|$)",
                "model": "Richmond"
            },
            {
                "regex": "BQ(?:ru)?-(?:4526|5004G)(?:[);/ ]|$)",
                "model": "Fox"
            },
            {
                "regex": "BQS-4525(?:[);/ ]|$)",
                "model": "Vienna"
            },
            {
                "regex": "BQS-4516(?:[);/ ]|$)",
                "model": "Singapore"
            },
            {
                "regex": "BQS-4515(?:[);/ ]|$)",
                "model": "Moscow"
            },
            {
                "regex": "BQS-4510(?:[);/ ]|$)",
                "model": "Florence"
            },
            {
                "regex": "BQS-4505(?:[);/ ]|$)",
                "model": "Santiago"
            },
            {
                "regex": "BQS-4503(?:[);/ ]|$)",
                "model": "Dubai"
            },
            {
                "regex": "BQS-4502(?:[);/ ]|$)",
                "model": "Kingston"
            },
            {
                "regex": "BQS-4501 Bristol",
                "model": "Bristol"
            },
            {
                "regex": "BQ-4501G(?:[);/ ]|$)",
                "model": "Fox Easy"
            },
            {
                "regex": "BQ(?:ru)?-4500(?:[);/ ]|$)",
                "model": "Fox LTE"
            },
            {
                "regex": "BQ-4077(?:[);/ ]|$)",
                "model": "Shark Mini"
            },
            {
                "regex": "BQ(?:ru)?-4072(?:[);/ ]|$)",
                "model": "Strike Mini"
            },
            {
                "regex": "BQ(?:ru)?[- ]402[68](?:[);/ ]|$)",
                "model": "Up!"
            },
            {
                "regex": "BQS-4010(?:[);/ ]|$)",
                "model": "Aspen"
            },
            {
                "regex": "BQS-4009(?:[);/ ]|$)",
                "model": "Orleans"
            },
            {
                "regex": "BQS-4008(?:[);/ ]|$)",
                "model": "Shanghai"
            },
            {
                "regex": "BQS-4007(?:[);/ ]|$)",
                "model": "Valencia"
            },
            {
                "regex": "BQS-4005(?:[);/ ]|$)",
                "model": "Seoul"
            },
            {
                "regex": "BQS-4004(?:[);/ ]|$)",
                "model": "Dusseldorf"
            },
            {
                "regex": "BQS-4003(?:[);/ ]|$)",
                "model": "Verona"
            },
            {
                "regex": "BQ-4001G(?:[);/ ]|$)",
                "model": "Cool"
            },
            {
                "regex": "BQS-4001(?:[);/ ]|$)",
                "model": "Oxford"
            },
            {
                "regex": "BQS-3510(?:[);/ ]|$)",
                "model": "Aspen Mini"
            },
            {
                "regex": "BQS-3503(?:[);/ ]|$)",
                "model": "Bombay"
            },
            {
                "regex": "BQ-1085L(?:[);/ ]|$)",
                "model": "Hornet Max Pro",
                "device": "tablet"
            },
            {
                "regex": "BQ-1084L(?:[);/ ]|$)",
                "model": "Hornet Max",
                "device": "tablet"
            },
            {
                "regex": "BQ-1083G(?:[);/ ]|$)",
                "model": "Armor Pro Plus",
                "device": "tablet"
            },
            {
                "regex": "BQ-1082G(?:[);/ ]|$)",
                "model": "Armor Pro",
                "device": "tablet"
            },
            {
                "regex": "BQ-1077L(?:[);/ ]|$)",
                "model": "Armor Pro LTE",
                "device": "tablet"
            },
            {
                "regex": "BQ(?:ru)?-1057L(?:[);/ ]|$)",
                "model": "Passion",
                "device": "tablet"
            },
            {
                "regex": "BQ-1051G(?:[);/ ]|$)",
                "model": "Corsika",
                "device": "tablet"
            },
            {
                "regex": "BQ-1050G(?:[);/ ]|$)",
                "model": "Hawaii",
                "device": "tablet"
            },
            {
                "regex": "BQ[-]?1008G(?:[);/ ]|$)",
                "model": "Grace",
                "device": "tablet"
            },
            {
                "regex": "Aquaris M8",
                "model": "Aquaris M8",
                "device": "tablet"
            },
            {
                "regex": "Aquaris_([AM])4.5",
                "model": "Aquaris $14.5"
            },
            {
                "regex": "BQ(?:ru)?[_ -](?:5590|5740G)(?:[);/ ]|$)",
                "model": "Spring"
            },
            {
                "regex": "BQ[S]?-(?:6016L|5520)(?:[);/ ]|$)",
                "model": "Mercury"
            },
            {
                "regex": "BQ(?:S|ru)?-(?:5701L|5060)(?:[);/ ]|$)",
                "model": "Slim"
            },
            {
                "regex": "(BQ-7850|BQ-700[12]G|BQ-8002G|BQ-905[12]G|BQ-9702G|BQ-7056G)",
                "model": "$1",
                "device": "tablet"
            },
            {
                "regex": "BQ-8006G(?:[);/ ]|$)",
                "model": "Java",
                "device": "tablet"
            },
            {
                "regex": "BQ-7062G(?:[);/ ]|$)",
                "model": "Fiji",
                "device": "tablet"
            },
            {
                "regex": "BQ(?:ru)?-1081G(?:[);/ ]|$)",
                "model": "Grace 3G",
                "device": "tablet"
            },
            {
                "regex": "BQ-7802G(?:[);/ ]|$)",
                "model": "Luzon",
                "device": "tablet"
            },
            {
                "regex": "BQ-7008G(?:[);/ ]|$)",
                "model": "Clarion",
                "device": "tablet"
            },
            {
                "regex": "BQ-7064G(?:[);/ ]|$)",
                "model": "Fusion",
                "device": "tablet"
            },
            {
                "regex": "BQ-7050G(?:[);/ ]|$)",
                "model": "Malta",
                "device": "tablet"
            },
            {
                "regex": "BQ-7000G(?:[);/ ]|$)",
                "model": "Сharm",
                "device": "tablet"
            },
            {
                "regex": "BQ-7040G(?:[);/ ]|$)",
                "model": "Сharm Plus",
                "device": "tablet"
            },
            {
                "regex": "BQ-7038G(?:[);/ ]|$)",
                "model": "Light Plus",
                "device": "tablet"
            },
            {
                "regex": "BQ-7005G(?:[);/ ]|$)",
                "model": "Maui",
                "device": "tablet"
            },
            {
                "regex": "BQ-7098G(?:[);/ ]|$)",
                "model": "Armor Power",
                "device": "tablet"
            },
            {
                "regex": "BQ-1007(?:[);/ ]|$)",
                "model": "Necker",
                "device": "tablet"
            },
            {
                "regex": "BQ-1045[G]?(?:[);/ ]|$)",
                "model": "Orion",
                "device": "tablet"
            },
            {
                "regex": "BQ-(?:1054L|1020L)(?:[);/ ]|$)",
                "model": "Nexion",
                "device": "tablet"
            },
            {
                "regex": "BQ-7083G(?:[);/ ]|$)",
                "model": "Light",
                "device": "tablet"
            },
            {
                "regex": "BQ-8077L(?:[);/ ]|$)",
                "model": "Exion Plus",
                "device": "tablet"
            },
            {
                "regex": "BQ(?:ru)?-7083(?:[);/ ]|$)",
                "model": "7083",
                "device": "tablet"
            },
            {
                "regex": "BQ(?:ru)?-1056L(?:[);/ ]|$)",
                "model": "1056L",
                "device": "tablet"
            },
            {
                "regex": "BQ(?:ru)?-1022l(?:[);/ ]|$)",
                "model": "Armor Pro LTE+",
                "device": "tablet"
            },
            {
                "regex": "BQ(?:ru)?-1024L(?:[);/ ]|$)",
                "model": "Exion Pro",
                "device": "tablet"
            },
            {
                "regex": "BQ(?:ru)?-7081(?:[);/ ]|$)",
                "model": "Charm",
                "device": "tablet"
            },
            {
                "regex": "(?:bq )?(Aquaris[^/;]*) Build",
                "model": "$1"
            },
            {
                "regex": "Aquaris (E(4.5|[56])|M(5|10)|U2 Lite|U2|U (Lite|Plus)|V Plus|X5 Plus|X2? Pro|X[25]|[CMUVX])",
                "model": "Aquaris $1"
            },
            {
                "regex": "bq ([^/;]+) Build",
                "model": "$1",
                "device": "tablet"
            }
        ]
    },
    "Bush": {
        "regex": "Bush[ _-]|Bush10Nou",
        "device": "smartphone",
        "models": [
            {
                "regex": "Bush10Nou",
                "device": "tablet",
                "model": "10Nou"
            },
            {
                "regex": "BUSH ([^;/)]+)Tablet(?: Build|[);])",
                "model": "$1Tablet",
                "device": "tablet"
            },
            {
                "regex": "BUSH ([^;/)]+)(?: Build|[);])",
                "model": "$1"
            }
        ]
    },
    "CAGI": {
        "regex": "CAGI-",
        "device": "smartphone",
        "models": [
            {
                "regex": "CAGI-OMEGA",
                "model": "Omega"
            },
            {
                "regex": "CAGI-([a-z0-9_\\-]+)",
                "model": "$1"
            }
        ]
    },
    "Capitel": {
        "regex": "Capitel-([a-z0-9]+)",
        "device": "feature phone",
        "model": "$1"
    },
    "Captiva": {
        "regex": "Captiva[ _-]([^;/)]+)(?: Build|\\))",
        "device": "tablet",
        "model": "Pad $1"
    },
    "Casio": {
        "regex": "(C811|C7[57]1)(?: 4G)?(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "C751(?:[);/ ]|$)",
                "model": "G'zOne Ravine"
            },
            {
                "regex": "C771(?:[);/ ]|$)",
                "model": "G'zOne Commando"
            },
            {
                "regex": "C811 4G(?:[);/ ]|$)",
                "model": "G'zOne Commando 4G LTE"
            }
        ]
    },
    "Casper": {
        "regex": "CASPER[_ ]|VIA[- _](T7D|A[34]|E3|F[123]|G4|M4|[LS]8|S7|P2|V8C|A1_Plus(?:_64)?|A1(?:_1)?|S[13]0|S|T17_M|T1[07]|T7|T7-3G|T8B|T8D-3G|S1[78])(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(?:CASPER[_ ])?VIA_A1_Plus(?:_64)?",
                "model": "VIA A1 Plus"
            },
            {
                "regex": "(?:CASPER[_ ])?VIA[_ ](G1_Plus|G1|E1|[AE]2|M[123]|V[35689]|V10|G3|V6X|A[34]|E3|F[123]|G4|M4|P2|S|V8C|A1(?:_1)?)(?:[);/ ]|$)",
                "model": "VIA $1"
            },
            {
                "regex": "CASPER_VIA_E1c",
                "model": "VIA E1C"
            },
            {
                "regex": "CASPER_L10_4.5G",
                "model": "L10 4.5G",
                "device": "tablet"
            },
            {
                "regex": "CASPER_(S[23]8)(?:[);/ ]|$)",
                "model": "$1",
                "device": "tablet"
            },
            {
                "regex": "VIA[- _](T7|T8D)-3G",
                "model": "VIA $1 3G",
                "device": "tablet"
            },
            {
                "regex": "VIA[- _](T7D|S[13]0|[LS]8|S7|T17_M|T1[07]|T7|T8B|S1[78])(?:[);/ ]|$)",
                "model": "VIA $1",
                "device": "tablet"
            }
        ]
    },
    "Cat": {
        "regex": "Cat ?(tablet|stargate|nova)|B15Q|CAT B35|CAT-B100",
        "device": "tablet",
        "models": [
            {
                "regex": "B15Q",
                "model": "B15Q",
                "device": "smartphone"
            },
            {
                "regex": "CAT B35",
                "model": "B35",
                "device": "smartphone"
            },
            {
                "regex": "Cat ?(?:tablet)? ?((?:Galactica|Nova|StarGate|PHOENIX)[^/;]*) Build",
                "model": "$1"
            },
            {
                "regex": "Cat ?tablet",
                "model": "Nova"
            },
            {
                "regex": "CAT-(B100)",
                "device": "feature phone",
                "model": "$1"
            }
        ]
    },
    "Carrefour": {
        "regex": "CT(?:10[0123]0|7[12]0|820)(?:W|FR)?(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "CT820",
                "model": "Touch Tablet Neo2"
            },
            {
                "regex": "CT(10[0123]0(?:W|FR)?)",
                "model": "CT$1"
            },
            {
                "regex": "CT(7[12]0(?:W|FR)?)",
                "model": "CT$1"
            }
        ]
    },
    "Celkon": {
        "regex": "Celkon|Millennia Hero",
        "device": "smartphone",
        "models": [
            {
                "regex": "Celkon[ _*](C[78]20)",
                "model": "$1",
                "device": "tablet"
            },
            {
                "regex": "Millennia Hero",
                "model": "Millennia Hero"
            },
            {
                "regex": "Celkon[ _*](CT[^;/]+) Build",
                "model": "$1",
                "device": "tablet"
            },
            {
                "regex": "Celkon[ _*]([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "Celkon[\\. _*]([^;/\\)]+)[\\)/]",
                "model": "$1"
            }
        ]
    },
    "Changhong": {
        "regex": "Changhong",
        "device": "smartphone",
        "models": [
            {
                "regex": "Changhong[ ]?(X1-K|X6|R8|Z9S?|Z1S|S18)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "ChangHong Android TV",
                "device": "tv",
                "model": ""
            },
            {
                "regex": "(?:Opera TV.+)Changhong-([^);/]+)",
                "device": "tv",
                "model": "$1"
            }
        ]
    },
    "Cherry Mobile": {
        "regex": "Cherry|Superion S1 DTV|Flare([ _])?(2X|4|A[123]|J[123568]|P[13]|S[4-8]|X2|Y[367]|S Play|HD|LITE|XL|X_V2|Y3s|Tab)(?:[ _](?:Pro|Plus|Max|LTE))?|Fusion Bolt|Omega[_ ](?:Icon 2|Icon Lite 2|lite[_ ][43][Cs]?|HD [45]|HD_Duo)|Spin Max 2|Selfie_Two",
        "device": "smartphone",
        "models": [
            {
                "regex": "(Flare2X)",
                "model": "$1"
            },
            {
                "regex": "Flare[_ ]HD[_ ]([345])",
                "model": "Flare HD $1"
            },
            {
                "regex": "Flare_HD_MAX",
                "model": "Flare HD Max"
            },
            {
                "regex": "FLARE LITE",
                "model": "Flare Lite"
            },
            {
                "regex": "Flare S Play",
                "model": "Flare S Play"
            },
            {
                "regex": "Flare[_ ]J([26])S",
                "model": "Flare J$1s"
            },
            {
                "regex": "Flare_(J2|S7)_Prime",
                "model": "Flare $1 Prime"
            },
            {
                "regex": "Flare_S7_Deluxe",
                "model": "Flare S7 Deluxe"
            },
            {
                "regex": "Flare_S7_Power",
                "model": "Flare S7 Power"
            },
            {
                "regex": "Flare[_ ]Y([367])[_ ]Pro",
                "model": "Flare Y$1 Pro"
            },
            {
                "regex": "Flare XL Plus",
                "model": "Flare XL Plus"
            },
            {
                "regex": "Flare[ _]X[_ ]V2",
                "model": "Flare X V2"
            },
            {
                "regex": "Flare[ _](A2|J3|P1|S[467])[ _]Lite",
                "model": "Flare $1 Lite"
            },
            {
                "regex": "Flare[ _](J[12]|S[57]|Y3|J6)[ _]mini",
                "model": "Flare $1 Mini"
            },
            {
                "regex": "Flare_J2_2018",
                "model": "Flare J2 (2018)"
            },
            {
                "regex": "Flare S4 Max LTE",
                "model": "Flare S4 Max LTE"
            },
            {
                "regex": "Flare[_ ](S[47]|J2)[_ ]Max",
                "model": "Flare $1 Max"
            },
            {
                "regex": "FlareS5LiteDTV",
                "model": "Flare S5 Lite DTV"
            },
            {
                "regex": "Flare[_ ](S[567]|J6)[_ ]Plus",
                "model": "Flare $1 Plus"
            },
            {
                "regex": "FLARE S6 POWER",
                "model": "Flare S6 Power"
            },
            {
                "regex": "Flare(?:[ _])?(4|A[123]|J8 LTE|J[13568]|P[13]|S[4-8]|X2|Y3s|Y7_LTE|Y[673])",
                "model": "Flare $1"
            },
            {
                "regex": "OMEGA HD ([45])",
                "model": "Omega HD $1"
            },
            {
                "regex": "Omega_HD_Duo",
                "model": "Omega HD Duo"
            },
            {
                "regex": "Omega Icon Lite 2",
                "model": "Omega Icon Lite 2"
            },
            {
                "regex": "Omega Icon 2",
                "model": "Omega Icon 2"
            },
            {
                "regex": "Omega[_ ]Lite[_ ](3[Cs]|4)",
                "model": "Omega Lite $1"
            },
            {
                "regex": "Spin Max 2",
                "model": "Spin Max 2"
            },
            {
                "regex": "Selfie_Two",
                "model": "Selfie Two"
            },
            {
                "regex": "(Fusion Bolt|Flare Tab Pro|Flare Tab|Superion S1 DTV)",
                "model": "$1",
                "device": "tablet"
            },
            {
                "regex": "Cherry(?:[ _]?Mobile)?[ _]?([^/;]+)(?: Build|[);])",
                "model": "$1"
            }
        ]
    },
    "China Mobile": {
        "regex": "(M812C|1501_M02|9930i|A1303|A309W|M651CY) Build",
        "device": "smartphone",
        "models": [
            {
                "regex": "M651CY",
                "model": "A3"
            },
            {
                "regex": "(M812C|1501_M02|9930i|A1303|A309W)",
                "model": "$1"
            }
        ]
    },
    "Comio": {
        "regex": "Comio|CT701G PLUS|CT701W|GT100",
        "device": "smartphone",
        "models": [
            {
                "regex": "GT100",
                "model": "GT100",
                "device": "tablet"
            },
            {
                "regex": "CT701W",
                "model": "CT701W",
                "device": "tablet"
            },
            {
                "regex": "CT701G PLUS",
                "model": "CT701G Plus",
                "device": "tablet"
            },
            {
                "regex": "Comio[ _-]?([^;/)]+)(?: Build|[);])",
                "model": "$1"
            }
        ]
    },
    "Compal": {
        "regex": "Compal-[a-z0-9]+",
        "device": "feature phone",
        "model": "$1"
    },
    "Artel": {
        "regex": "Artel[_ ]|\\w+_ARTEL_\\w+|GAP YOQ(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "GAP YOQ(?:[);/ ]|$)",
                "model": "Gap Yo Q"
            },
            {
                "regex": "Artel[_ ](Quadro(?: Pro)?|Tesla|Air|Tomchi|Premium|U3_4G|Z5|Sentinel (?:X 8G|X D5|X)|T10)",
                "model": "$1"
            },
            {
                "regex": "Artel[_ ]Komi3",
                "model": "Komi 3"
            },
            {
                "regex": "Artel[_ ](Star|Nova|Connect|Choice|Style)",
                "model": "$1",
                "device": "tablet"
            },
            {
                "regex": "(?:\\w+_)?ARTEL_([FVM]5|X[45])_\\w+",
                "device": "feature phone",
                "model": "$1"
            }
        ]
    },
    "ComTrade Tesla": {
        "regex": "Tesla|Impulse 7.85 3G|Impulse 10.1 OCTA|Smartphone_6.1|SP(?:6.2|6.2_Lite)|TTL(?:713G|8)",
        "device": "smartphone",
        "models": [
            {
                "regex": "TeslaEvo5.0",
                "model": "Evo 5.0"
            },
            {
                "regex": "(Gravity 9.7 SHD)",
                "model": "$1",
                "device": "tablet"
            },
            {
                "regex": "(Impulse 7.85 3G)",
                "model": "$1",
                "device": "tablet"
            },
            {
                "regex": "Impulse 10.1 OCTA",
                "model": "Impulse 10.1\" OCTA",
                "device": "tablet"
            },
            {
                "regex": "Tesla_SP([^/;]+)L Build",
                "model": "Smartphone $1 Lite"
            },
            {
                "regex": "Smartphone_6.1",
                "model": "Smartphone 6.1"
            },
            {
                "regex": "SP6.2_Lite",
                "model": "Smartphone 6.2 Lite"
            },
            {
                "regex": "Tesla_SP([^/;]+) Build",
                "model": "Smartphone $1"
            },
            {
                "regex": "SP([^/;]+) Build",
                "model": "Smartphone $1"
            },
            {
                "regex": "Smartphone ([36])",
                "model": "Smartphone $1"
            },
            {
                "regex": "Tesla_Tablet_785",
                "model": "7.85\"",
                "device": "tablet"
            },
            {
                "regex": "TTH7",
                "model": "H7",
                "device": "tablet"
            },
            {
                "regex": "TTL7 Build",
                "model": "L7",
                "device": "tablet"
            },
            {
                "regex": "Tesla_Tablet_L7_3G",
                "model": "L7 3G",
                "device": "tablet"
            },
            {
                "regex": "(L7.1)",
                "model": "$1",
                "device": "tablet"
            },
            {
                "regex": "TTL713G",
                "model": "L7.1 3G",
                "device": "tablet"
            },
            {
                "regex": "(L7 Quad) Build",
                "model": "$1",
                "device": "tablet"
            },
            {
                "regex": "(L7 Quad Lite)",
                "model": "$1",
                "device": "tablet"
            },
            {
                "regex": "TTL8 Build",
                "model": "L8",
                "device": "tablet"
            },
            {
                "regex": "(L8.1)",
                "model": "$1",
                "device": "tablet"
            },
            {
                "regex": "TESLA_TABLET_M7",
                "model": "M7",
                "device": "tablet"
            },
            {
                "regex": "Tesla_Tablet_M8",
                "model": "M8",
                "device": "tablet"
            }
        ]
    },
    "ConCorde": {
        "regex": "ConCorde ([^/;]+) Build|Concorde_Raptor_Z55(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Concorde_(Raptor_Z55)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "ConCorde Tab ?([^/;]+) Build",
                "model": "Tab $1",
                "device": "tablet"
            },
            {
                "regex": "ConCorde ReadMan ?([^/;]+) Build",
                "model": "ReadMan $1",
                "device": "tablet"
            },
            {
                "regex": "ConCorde ([^/;]+) Build",
                "model": "$1"
            }
        ]
    },
    "Condor": {
        "regex": "Allure (?:M[13]|X)|Griffe T[2356789]|TFX712G|TRA-901G|TGW[-]?(710G|709)|(?:PGN\\-?[456][012][0-9]|PHS\\-601|PHQ519|PHQ52[056]|TB105L|TB717G|PKT\\-301|Plume (?:H1|L[1-468]|P8|P6 PRO LTE))(?:[);/ ]|$)|CTAB[^/;]+ Build",
        "device": "smartphone",
        "models": [
            {
                "regex": "CTAB([^/;]+) Build",
                "device": "tablet",
                "model": "CTAB $1"
            },
            {
                "regex": "(TGW)-?(710G|709)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "$1 $2"
            },
            {
                "regex": "(TFX712G|TB105L|TB717G)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "$1"
            },
            {
                "regex": "(TRA)-(901G)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "$1 $2"
            },
            {
                "regex": "PHS\\-601",
                "model": "C8"
            },
            {
                "regex": "PGN\\-?403",
                "model": "C4+ Noir"
            },
            {
                "regex": "PGN\\-?404",
                "model": "C7 Mini"
            },
            {
                "regex": "PGN\\-?409",
                "model": "Plume P4"
            },
            {
                "regex": "PKT\\-?301",
                "model": "C2"
            },
            {
                "regex": "PGN\\-?504",
                "model": "C5"
            },
            {
                "regex": "PGN\\-?505",
                "model": "C8S"
            },
            {
                "regex": "PGN\\-?506",
                "model": "C7"
            },
            {
                "regex": "(Allure (?:M[13]|X))",
                "model": "$1"
            },
            {
                "regex": "PGN\\-?507",
                "model": "Allure A9"
            },
            {
                "regex": "PGN\\-?508",
                "model": "C6+"
            },
            {
                "regex": "PGN\\-?509",
                "model": "C6 Pro"
            },
            {
                "regex": "PGN\\-?511",
                "model": "Allure A9 Plus"
            },
            {
                "regex": "PGN\\-?513",
                "model": "Griffe G4"
            },
            {
                "regex": "PHQ519",
                "model": "Griffe G4S"
            },
            {
                "regex": "PHQ520",
                "model": "Griffe G5"
            },
            {
                "regex": "PHQ525",
                "model": "Griffe G6"
            },
            {
                "regex": "PHQ526",
                "model": "Griffe G6 Pro"
            },
            {
                "regex": "PGN\\-?514",
                "model": "Plume P7"
            },
            {
                "regex": "PGN\\-?515",
                "model": "Plume P4 Pro"
            },
            {
                "regex": "PGN\\-?516",
                "model": "Plume P5"
            },
            {
                "regex": "PGN\\-?51[78]",
                "model": "Plume P6"
            },
            {
                "regex": "PGN\\-?521",
                "model": "Griffe G4 Plus"
            },
            {
                "regex": "Griffe T3 ?pro",
                "model": "Griffe T3 Pro"
            },
            {
                "regex": "Griffe T([0-9]) Plus",
                "model": "Griffe T$1 Plus"
            },
            {
                "regex": "Griffe T([0-9])",
                "model": "Griffe T$1"
            },
            {
                "regex": "PGN\\-?522",
                "model": "P6 Plus"
            },
            {
                "regex": "PGN\\-?523",
                "model": "P7 Plus"
            },
            {
                "regex": "PGN\\-?527",
                "model": "Plume P4 Plus"
            },
            {
                "regex": "PGN\\-?528|PLUME P6 PRO LTE",
                "model": "Plume P6 Pro LTE"
            },
            {
                "regex": "PGN\\-?605",
                "model": "Plume P8"
            },
            {
                "regex": "PGN\\-?606",
                "model": "Allure A55"
            },
            {
                "regex": "PGN\\-?607",
                "model": "Allure A100"
            },
            {
                "regex": "PGN\\-?608",
                "model": "Allure A55 Slim"
            },
            {
                "regex": "PGN\\-?609",
                "model": "Allure A100 Lite"
            },
            {
                "regex": "PGN\\-?610",
                "model": "Plume P8 Lite"
            },
            {
                "regex": "PGN\\-?611",
                "model": "Allure A8"
            },
            {
                "regex": "PGN\\-?612",
                "model": "Allure A8 Plus"
            },
            {
                "regex": "PGN\\-?613",
                "model": "Allure A55 Plus"
            },
            {
                "regex": "Plume H1",
                "model": "Plume H1"
            },
            {
                "regex": "Plume L([2468]) Pro",
                "model": "Plume L$1 Pro"
            },
            {
                "regex": "Plume L3 Plus",
                "model": "Plume L3 Plus"
            },
            {
                "regex": "Plume L3 Smart",
                "model": "Plume L3 Smart"
            },
            {
                "regex": "Plume L([1-4])",
                "model": "Plume L$1"
            },
            {
                "regex": "Plume P8 Pro",
                "model": "Plume P8 Pro"
            },
            {
                "regex": "PGN\\-?([0-9]{3})",
                "model": "PGN-$1"
            }
        ]
    },
    "Coolpad": {
        "regex": "(?:YL-)?Coolpad|(?:Cool_9S|1824-S0|8190Q|1831-A0|8676-[AM]01|CP8298_I00|CP3648A|CP8676_I0[23]|REVVLPLUS C3701A|VCR-[AI]0|C105-E0|C106-[79]| C106|MTS-T0|cp3705A[S]?|ORL-C0|DLS-E0|8298-M02|5560S)(?:[);/ ]|$)|(8295|5860S) Build",
        "device": "smartphone",
        "models": [
            {
                "regex": "VCR-[AI]0",
                "model": "Cool Play 6"
            },
            {
                "regex": "1831-A0(?:[);/ ]|$)",
                "model": "Cool Play C7"
            },
            {
                "regex": "REVVLPLUS C3701A",
                "model": "REVVL Plus",
                "device": "phablet"
            },
            {
                "regex": "8190Q(?:[);/ ]|$)",
                "model": "8190Q"
            },
            {
                "regex": "DLS-E0(?:[);/ ]|$)",
                "model": "N5"
            },
            {
                "regex": "1824-S0(?:[);/ ]|$)",
                "model": "N5C"
            },
            {
                "regex": "CP3705A[S]?(?:[);/ ]|$)",
                "model": "Legacy"
            },
            {
                "regex": "CP3648A(?:[);/ ]|$)",
                "model": "Legacy S"
            },
            {
                "regex": "MTS-T0(?:[);/ ]|$)",
                "model": "N2M"
            },
            {
                "regex": "C106-[79](?:[);/ ]|$)",
                "model": "Cool 1"
            },
            {
                "regex": "C105-E0(?:[);/ ]|$)",
                "model": "Cool S1"
            },
            {
                "regex": "ORL-C0(?:[);/ ]|$)",
                "model": "Cool 9"
            },
            {
                "regex": "Cool_9S(?:[);/ ]|$)",
                "model": "Cool 9S"
            },
            {
                "regex": "5560S(?:[);/ ]|$)",
                "model": "Arise"
            },
            {
                "regex": "(5860S|8295) Build",
                "model": "$1"
            },
            {
                "regex": "CP8676_I02(?:[);/ ]|$)",
                "model": "Note 3"
            },
            {
                "regex": "CP8676_I03(?:[);/ ]|$)",
                "model": "Note 3 Plus"
            },
            {
                "regex": "CP8298_I00(?:[);/ ]|$)",
                "model": "Note 3 Lite"
            },
            {
                "regex": "(8676-[AM]01|8298-M02)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "(?:YL-)?Coolpad[ _\\-]?([^/;]+) Build",
                "model": "$1"
            },
            {
                "regex": "(?:YL-)?Coolpad[ _\\-]?([a-z0-9\\-]+)",
                "model": "$1"
            }
        ]
    },
    "Clarmin": {
        "regex": "Clarmin",
        "device": "smartphone",
        "models": [
            {
                "regex": "Clarmin[_ ](B[65]|C1)",
                "model": "$1"
            }
        ]
    },
    "CORN": {
        "regex": "CORN ",
        "device": "smartphone",
        "models": [
            {
                "regex": "CORN R20",
                "model": "R20"
            }
        ]
    },
    "Cosmote": {
        "regex": "Cosmote",
        "device": "smartphone",
        "models": [
            {
                "regex": "Cosmote_My_mini_Tab",
                "device": "tablet",
                "model": "My Mini Tab"
            }
        ]
    },
    "Cricket": {
        "regex": "Cricket-([a-z0-9]+)|U705AC(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "U705AC",
                "model": "Ovation"
            },
            {
                "regex": "Cricket-([a-z0-9]+)",
                "device": "feature phone",
                "model": "$1"
            }
        ]
    },
    "Crius Mea": {
        "regex": "(Q7A\\+?)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "Crosscall": {
        "regex": "Crosscall|ODYSSEY_Plus|Odyssey S1|Trekker-[MSX][1234]|Action-X3|Core-X3",
        "device": "smartphone",
        "models": [
            {
                "regex": "Action-X3",
                "model": "Action-X3"
            },
            {
                "regex": "Core-X3",
                "model": "Core-X3"
            },
            {
                "regex": "Crosscall ([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "ODYSSEY_Plus",
                "model": "ODYSSEY+"
            },
            {
                "regex": "Odyssey S1",
                "model": "ODYSSEY S1"
            },
            {
                "regex": "Trekker-([MSX][1234](?: Core)?)",
                "model": "Trekker-$1"
            }
        ]
    },
    "AllDocube": {
        "regex": "(?:T1001XS?|T1006S?|iPlay_[24]0|U1006H?)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "T1001XS(?:[);/ ]|$)",
                "model": "M5XS"
            },
            {
                "regex": "T1006S(?:[);/ ]|$)",
                "model": "M5S"
            },
            {
                "regex": "T1006(?:[);/ ]|$)",
                "model": "M5"
            },
            {
                "regex": "T1001X(?:[);/ ]|$)",
                "model": "M5X"
            },
            {
                "regex": "U1006H?(?:[);/ ]|$)",
                "model": "iPlay 10 Pro 10.1\""
            },
            {
                "regex": "iPlay_([24]0)(?:[);/ ]|$)",
                "model": "iPlay $1"
            }
        ]
    },
    "Cube": {
        "regex": "(<!myPhone ?)Cube|(U[0-9]+GT|K8GT)|(?:T8-PLUSM?S?|T8PLUSML|I1-TF[BD]?|I1-TC|i1002S|Iwork10 Flagship|iwork10 Pro)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "U27GT",
                "model": "Talk 8"
            },
            {
                "regex": "(?:T8-PLUSM?S?|T8PLUSML)(?:[);/ ]|$)",
                "model": "T8 Ultimate"
            },
            {
                "regex": "U55GT",
                "model": "Talk 79"
            },
            {
                "regex": "U65GT",
                "model": "Talk 9X"
            },
            {
                "regex": "I1-TF[BD]?(?:[);/ ]|$)",
                "model": "iWork 8 Air"
            },
            {
                "regex": "I1-TC(?:[);/ ]|$)",
                "model": "iWork 8 Ultimate"
            },
            {
                "regex": "(?:i1002S|iwork10 Pro)(?:[);/ ]|$)",
                "model": "iWork 10 Pro"
            },
            {
                "regex": "Iwork10 Flagship(?:[);/ ]|$)",
                "model": "iWork 10 Flagship"
            },
            {
                "regex": "(U[0-9]+GT[^;/]*) Build",
                "model": "$1"
            },
            {
                "regex": "(U[0-9]+GT(?:[0-9]|[\\-_][a-z]+)?)",
                "model": "$1"
            },
            {
                "regex": "(K8GT)",
                "model": "$1"
            }
        ]
    },
    "CUBOT": {
        "regex": "CUBOT|RAINBOW 2|KING_KONG_3|ZORRO 001|KINGKONG[_ ]MINI2?|KING KONG CS|(?<!QUMO )QUEST(?!(?:[0-9]|\\s[0-9]))(?: LITE)?|NOTE 20 PRO",
        "device": "smartphone",
        "models": [
            {
                "regex": "CHEETAH 2",
                "model": "Cheetah 2"
            },
            {
                "regex": "RAINBOW 2",
                "model": "Rainbow 2"
            },
            {
                "regex": "RAINBOW",
                "model": "Rainbow"
            },
            {
                "regex": "DINOSAUR",
                "model": "Dinosaur"
            },
            {
                "regex": "KINGKONG MINI2",
                "model": "King Kong Mini 2"
            },
            {
                "regex": "KINGKONG_MINI",
                "model": "King Kong Mini"
            },
            {
                "regex": "KING_KONG_3",
                "model": "King Kong 3"
            },
            {
                "regex": "KING KONG CS",
                "model": "King Kong CS"
            },
            {
                "regex": "KING KONG",
                "model": "King Kong"
            },
            {
                "regex": "QUEST LITE",
                "model": "Quest Lite"
            },
            {
                "regex": "QUEST",
                "model": "Quest"
            },
            {
                "regex": "ZORRO 001",
                "model": "Zorro 001"
            },
            {
                "regex": "CUBOT[ _]Echo",
                "model": "Echo"
            },
            {
                "regex": "CUBOT[ _]MANITO",
                "model": "Manito"
            },
            {
                "regex": "CUBOT[ _]MAGIC",
                "model": "Magic"
            },
            {
                "regex": "CUBOT[ _]MAX",
                "model": "Max"
            },
            {
                "regex": "NOTE Plus",
                "model": "Note Plus"
            },
            {
                "regex": "NOTE 20 PRO",
                "model": "Note 20 Pro"
            },
            {
                "regex": "CUBOT[_ ]NOTE[_ ]S",
                "model": "Note S"
            },
            {
                "regex": "CUBOT_NOVA",
                "model": "Nova"
            },
            {
                "regex": "CUBOT_POWER",
                "model": "Power"
            },
            {
                "regex": "CUBOT_X18_Plus",
                "model": "X18 Plus"
            },
            {
                "regex": "CUBOT[ _](A5|C6W|H3|J3|P7|P20|[RXJ]9|R11|X18)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "CUBOT ([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "([^;/]+) Build/CUBOT",
                "model": "$1"
            }
        ]
    },
    "Clout": {
        "regex": "Hind[ _]5\\.1|X417[ _]Amaze|X418[ _]Zest|X421[ _]Nova|X422[ ]Exotic|X425[ _]Shavit|X428[ _]Astute",
        "device": "smartphone",
        "models": [
            {
                "regex": "Hind[ _]5\\.1",
                "model": "Hind 5.1"
            },
            {
                "regex": "X417[ _]Amaze",
                "model": "X417 Amaze"
            },
            {
                "regex": "X418[ _]Zest",
                "model": "X418 Zest"
            },
            {
                "regex": "X421[ _]Nova",
                "model": "X421 Nova"
            },
            {
                "regex": "X422[ _]Exotic",
                "model": "X422 Exotic"
            },
            {
                "regex": "X425[ _]Shavit",
                "model": "X425 Shavit"
            },
            {
                "regex": "X428[ _]Astute",
                "model": "X428 Astute"
            }
        ]
    },
    "Concord": {
        "regex": "Concord[ _-]|(?:Flyfix 6|C-721|Plus-7_C777|Plus_M19_C779|Plus-9_C778)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "M10",
                "model": "M10"
            },
            {
                "regex": "Flyfix 6",
                "model": "Flyfix 6"
            },
            {
                "regex": "C-721",
                "model": "Flyfix Q",
                "device": "tablet"
            },
            {
                "regex": "Plus[-_]([79]|M19)_(C77[789])(?:[);/ ]|$)",
                "model": "Plus-$1 $2",
                "device": "tablet"
            }
        ]
    },
    "Crescent": {
        "regex": "WING 5|WING9|Venus[ _][14]",
        "device": "smartphone",
        "models": [
            {
                "regex": "WING 5",
                "model": "Wing 5"
            },
            {
                "regex": "WING9",
                "model": "Wing 9"
            },
            {
                "regex": "Venus[ _]([14])",
                "model": "Venus $1"
            }
        ]
    },
    "Cyrus": {
        "regex": "Cyrus[ _-]",
        "device": "smartphone",
        "models": [
            {
                "regex": "Cyrus[ _-]?([^;/)]+) Build",
                "model": "$1"
            },
            {
                "regex": "Cyrus[ _-]?([^;/)]+)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Datang": {
        "regex": "DATANG",
        "device": "smartphone",
        "models": [
            {
                "regex": "(?:DATANG[ _-])+([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "(?:DATANG[ _-])+([^;/)]+)(?:[;/)]|$)",
                "model": "$1"
            }
        ]
    },
    "Datsun": {
        "regex": "DATSUN",
        "device": "smartphone",
        "models": [
            {
                "regex": "DATSUN_D5500",
                "model": "D5500"
            },
            {
                "regex": "Datsun_D5001",
                "model": "D5001"
            }
        ]
    },
    "Danew": {
        "regex": "Dslide ?([^;/]+) Build|Konnect_?(?:402|50[246]|60[127])|GEODROID_A5",
        "device": "smartphone",
        "models": [
            {
                "regex": "Konnect_?(402|50[246]|60[127])",
                "model": "Konnect $1"
            },
            {
                "regex": "Dslide ?([^;/]+) Build",
                "device": "tablet",
                "model": "DSlide $1"
            },
            {
                "regex": "GEODROID_A5",
                "device": "tablet",
                "model": "Geodroid A5"
            }
        ]
    },
    "Denver": {
        "regex": "(?:TA[CDQ]-[0-9]+)|P(?:.+)TAQ-(?:[0-9]+)|SDQ[_-](?:550[24]4L|52001G|50002G|57004L)",
        "device": "tablet",
        "models": [
            {
                "regex": "P(?:.+)TAQ-(10213GMK2|10172MK3|10242MK2|70242MK2|[0-9]+)(?:[);/ ]|$)",
                "model": "TAQ-$1"
            },
            {
                "regex": "(TA[CDQ]-[0-9]+)",
                "model": "$1"
            },
            {
                "regex": "SDQ[_-](550[24]4L|52001G|50002G|57004L)",
                "device": "smartphone",
                "model": "SDQ-$1"
            }
        ]
    },
    "Dell": {
        "regex": "Dell[^a-z]|Venue|XCD35",
        "device": "smartphone",
        "models": [
            {
                "regex": "XCD35",
                "model": "XCD35"
            },
            {
                "regex": "Venue (8 7840|10 7040|8 3830)",
                "model": "Venue $1",
                "device": "tablet"
            },
            {
                "regex": "(?:Dell )?Venue Build",
                "model": "Venue"
            },
            {
                "regex": "Dell; Venue Pro",
                "model": "Venue Pro"
            },
            {
                "regex": "(OptiPlex (?:3050|7010))",
                "device": "desktop",
                "model": "$1"
            },
            {
                "regex": "(?:Dell )?(Streak|Venue) ?([^/;]*) Build",
                "model": "$1 $2",
                "device": "tablet"
            },
            {
                "regex": "(?:Dell Inc./)?(XPS 13 9360|Latitude 7480)(?:[);/ ]|$)",
                "device": "desktop",
                "model": "$1"
            },
            {
                "regex": "Dell; ((?:Streak|Venue)[^;/\\)]*)",
                "model": "$1",
                "device": "tablet"
            },
            {
                "regex": "Dell; ([^;/\\)]+)",
                "model": "$1"
            },
            {
                "regex": "Dell[ _\\-]([^/;]+) Build",
                "model": "$1"
            }
        ]
    },
    "Desay": {
        "regex": "Desay",
        "device": "smartphone",
        "models": [
            {
                "regex": "Desay[ _-]?([^;/)]+) Build",
                "model": "$1"
            },
            {
                "regex": "Desay[ _-]?([^;/)]+)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "DEXP": {
        "regex": "DEXP|(?:Ursus|Ixion)[ _]([^;/)]+)|H135[ _]Build|Larus_TV1|Ixion(?:ML4.7|ES250)|M150Storm|(?:SENIOR|F4[38]D8000K|VA[12]10|VA170|ML450|MS[56]50|BL[123]50|BS155|BS160|BS[1256]50|AL[12]40|AL[23]50|AS155|AS260|GS15[035]| (?:N280i|P380i|R110|BL160|B450|B340|A1[45]0|A340|AS160|GL[23]55|ES750))(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Ixion[_ ]([^;/]+)(?: Build|\\))",
                "model": "Ixion $1"
            },
            {
                "regex": "(ML450|MS[56]50|ES750)(?:[);/ ]|$)",
                "model": "Ixion $1"
            },
            {
                "regex": "IxionES250(?:[);/ ]|$)",
                "model": "Ixion ES250 Rage"
            },
            {
                "regex": "IxionML4.7(?:[);/ ]|$)",
                "model": "Ixion ML 4.7\""
            },
            {
                "regex": "M150Storm(?:[);/ ]|$)",
                "model": "Ixion M150 Storm"
            },
            {
                "regex": "AL250(?:[);/ ]|$)",
                "model": "4.5\" AL250"
            },
            {
                "regex": "AL350(?:[);/ ]|$)",
                "model": "5.0\" AL350"
            },
            {
                "regex": "AS155(?:[);/ ]|$)",
                "model": "5.45\" AS155"
            },
            {
                "regex": "AS260(?:[);/ ]|$)",
                "model": "5.85\" AS260",
                "device": "phablet"
            },
            {
                "regex": "Ursus ([^;/)]+)(?: Build|\\))",
                "model": "Ursus $1",
                "device": "tablet"
            },
            {
                "regex": "(VA[21]10|VA170|R110|N280i|P380i)(?:[);/ ]|$)",
                "model": "Ursus $1",
                "device": "tablet"
            },
            {
                "regex": " (B450|B340|BL160|A340)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "SENIOR(?:[);/ ]|$)",
                "model": "Senior"
            },
            {
                "regex": "Larus_TV1",
                "device": "feature phone",
                "model": "Larus_TV1"
            },
            {
                "regex": "H135[ _]Build",
                "model": "Atlas",
                "device": "desktop"
            },
            {
                "regex": "(BL[123]50|BS155|BS160|BS[1256]50|AL[12]40|A1[45]0|GS15[035]|AS160|GL[23]55)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "DEXP[ _]([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "DEXP[ _]([^);/]+)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "(F4[38]D8000K)(?:[);/ ]|$)",
                "device": "tv",
                "model": "$1"
            }
        ]
    },
    "Dbtel": {
        "regex": "DBTEL(?:[\\-/])?([a-z0-9]+)",
        "device": "feature phone",
        "model": "$1"
    },
    "Dialog": {
        "regex": "Dialog ?([^;/]+) Build",
        "device": "smartphone",
        "model": "$1"
    },
    "Dicam": {
        "regex": "DICAM-([a-z0-9]+)",
        "device": "feature phone",
        "model": "$1"
    },
    "Digi": {
        "regex": "(?<!FBCR/)Digi[ _]([^);/]+)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Digicel": {
        "regex": "DIGICEL",
        "device": "smartphone",
        "models": [
            {
                "regex": "DL810",
                "model": "DL810"
            },
            {
                "regex": "DIGICEL ([^/;]+) Build",
                "model": "$1"
            }
        ]
    },
    "Digiland": {
        "regex": "(?:DL1008M|DL(?:1023|9002|[78]006))(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "DL1008M",
                "model": "DL1008M"
            },
            {
                "regex": "DL(1023|9002|[78]006)",
                "model": "DL$1"
            }
        ]
    },
    "Digma": {
        "regex": "Digma[_ ]([^;/]+) Build|HIT HT707[10]MG|CITI (?:7 E401 4G|8 E400 4G|ATL 4G|Kids|Octa [178]0|1902 3G|10 E402|Z530 3G)|(CITI[_ ](?:[A-Z0-9]+)[_ ][34]G)[ _][TC][ST](606[32]ML|111[46]ML|11[459]4MG|1117PG|1207MG|1210MG|1195MG|1196ML|3001ML|4024PG|5009PL|5021ML|5026PL|500[67]PG|7052PG|706[56]MG|709[68]PL|7097MG|720[48]MG|7140ML|7141MG|7113PL|7193MG|820[69]MG|7153MG|8139ML|8143MG|8152ML|1050PL|8205PG|7203MG|106[24]ML|1115MG)|HIT 4G|HT7074ML|IDX5|(iDx10|iDx7)|MVM900H(?:WZ|C)|MVM908HCZ|(iDxD8 3G|iDxD10 3G|iDrQ10 3G|iDxD[45]|iDxQ5|iD[nx]D7(?:[_ ]3G)?)|PS604M|PT452E|LINX B510 3G LT5037MG|LINX A452 3G LT4030PG|LINX A453 3G LT4038PG|LINX A420 3G LS4019PG|LINX A450 3G LT4028PG|Linx A400 3G LT4001PG|Linx C500 3G LT5001PG|Linx PS474S|LS504[01]PL|LT40(47|54)MG|PS1043MG|LS405[01]MG|LS5053ML|LT5048MG|HT7071MG|LT5052ML|HT5035PG|HT4039PG|LT4049PG|NS6902QL|NS9797MG|TS7854M|TS1012E 3G|TT1004PG|TT702[045]MG|TT1002MG|TT7026MW|((?:Optima|Platina|Plane)[ _](?:[EM])?(?:[0-9\\.ST]+|Prime)(?:[ _][43]G)?)|(VOX[ _](?:[0-9\\.A-Z]+)[_ ][43]G)",
        "device": "smartphone",
        "models": [
            {
                "regex": "HIT(?: 3G)? HT707[10]MG",
                "model": "HIT 3G",
                "device": "tablet"
            },
            {
                "regex": "iDx5",
                "model": "iDx5"
            },
            {
                "regex": "HT5035PG",
                "model": "HIT Q500 3G"
            },
            {
                "regex": "HT4039PG",
                "model": "HIT Q401 3G"
            },
            {
                "regex": "(VOX[ _](?:[0-9\\.A-Z]+)[_ ][43]G)",
                "model": "$1"
            },
            {
                "regex": "PS604M",
                "model": "Linx 6.0"
            },
            {
                "regex": "LT4049PG",
                "model": "Linx Atom 3G"
            },
            {
                "regex": "LT5048MG",
                "model": "Linx Joy 3G"
            },
            {
                "regex": "LT5052ML",
                "model": "Linx Base 4G"
            },
            {
                "regex": "LT4047MG",
                "model": "Linx Alfa 3G"
            },
            {
                "regex": "LS4050MG",
                "model": "Linx X1 3G"
            },
            {
                "regex": "LS4051MG",
                "model": "Linx X1 Pro 3G"
            },
            {
                "regex": "LT4054MG",
                "model": "Linx Argo 3G"
            },
            {
                "regex": "LS5041PL",
                "model": "Linx Trix 4G"
            },
            {
                "regex": "LS5040PL",
                "model": "Linx Rage 4G"
            },
            {
                "regex": "LS5053ML",
                "model": "Linx Pay 4G"
            },
            {
                "regex": "Linx ([A-Z][0-9]{3}) 3G (?:LT4028PG|LS4019PG|LT4001PG|LT5001PG|LT4038PG|LT5037MG|LT4030PG)",
                "model": "Linx $1 3G"
            },
            {
                "regex": "(Linx PS474S)",
                "model": "$1"
            },
            {
                "regex": "PT452E",
                "model": "Linx 4.5"
            },
            {
                "regex": "CITI ATL 4G CS5029ML",
                "model": "CITI ATL 4G"
            },
            {
                "regex": "(CITI Z530 3G)",
                "model": "$1"
            },
            {
                "regex": "(CITI [A-Z0-9]+ [34]G) C[ST](?:606[32]ML|4024PG|5009PL|5021ML|5026PL)",
                "model": "$1"
            },
            {
                "regex": "TS7854M",
                "model": "Plane 8.1\" 3G",
                "device": "tablet"
            },
            {
                "regex": "PS1043MG",
                "model": "Plane 10.3\" 3G",
                "device": "tablet"
            },
            {
                "regex": "TS1012E 3G",
                "model": "Plane 10.1\" 3G",
                "device": "tablet"
            },
            {
                "regex": "MVM900H(?:WZ|C)",
                "model": "iDx8",
                "device": "tablet"
            },
            {
                "regex": "MVM908HCZ",
                "model": "iDx9",
                "device": "tablet"
            },
            {
                "regex": "(iD(?:xD10|rQ10|xD7)[_ ]3G)",
                "model": "$1",
                "device": "tablet"
            },
            {
                "regex": "(iDx10|iDx7)",
                "model": "$1"
            },
            {
                "regex": "(iDxD[45]|iDxQ5)",
                "model": "$1"
            },
            {
                "regex": "HIT 4G HT7074ML",
                "model": "HIT 4G",
                "device": "tablet"
            },
            {
                "regex": "CITI[_ ](1902 3G|Kids|8527_4G|Octa [178]0|10 E402 4G|8 E400 4G|7 E401 4G)",
                "model": "CITI $1",
                "device": "tablet"
            },
            {
                "regex": "(CITI[ _](?:[A-Z0-9]+)[ _][34]G)[ _][TC][ST](?:106[24]ML|111[46]ML|1115MG|1117PG|1207MG|1210MG|3001ML|500[67]PG|7052PG|706[567]MG|7097MG|720[48]MG|820[69]MG|11[459]4MG|1195MG|1196ML|3001ML|8152ML|1050PL|709[68]PL|7113PL|7193MG|7216MG|8205PG|7203MG|8143MG|7153MG|7141MG|7140ML)",
                "model": "$1",
                "device": "tablet"
            },
            {
                "regex": "iDxD8 3G",
                "model": "D-Plane2 8",
                "device": "tablet"
            },
            {
                "regex": "(iDnD7)",
                "model": "$1",
                "device": "tablet"
            },
            {
                "regex": "NS6902QL",
                "model": "Platina 7.2 4G",
                "device": "tablet"
            },
            {
                "regex": "NS9797MG",
                "model": "Platina 7.2 3G",
                "device": "tablet"
            },
            {
                "regex": "TT1004PG",
                "model": "Optima 10.4\" 3G",
                "device": "tablet"
            },
            {
                "regex": "TT1002MG",
                "model": "Optima D 10.4\" 3G",
                "device": "tablet"
            },
            {
                "regex": "TT7025MG",
                "model": "Optima 7.5\" 3G",
                "device": "tablet"
            },
            {
                "regex": "TT7026MW",
                "model": "Optima 7.6\" 3G",
                "device": "tablet"
            },
            {
                "regex": "TT7020MG",
                "model": "Optima 7.3\" 3G",
                "device": "tablet"
            },
            {
                "regex": "TT7024MG",
                "model": "Optima 7.4\" 3G",
                "device": "tablet"
            },
            {
                "regex": "((?:Optima|Platina|Plane)[ _](?:[EM])?(?:[0-9\\.STNM]+|Prime)(?:[ _][43]G)?)",
                "model": "$1",
                "device": "tablet"
            },
            {
                "regex": "Digma[_ ]([^;/]+) Build",
                "model": "$1",
                "device": "tablet"
            }
        ]
    },
    "DoCoMo": {
        "regex": "DoCoMo|\\;FOMA|KGT/1\\.0",
        "device": "feature phone",
        "models": [
            {
                "regex": "DoCoMo/.+MST.+(SH2101V)",
                "model": "$1"
            },
            {
                "regex": "DoCoMo/[12]\\.0[/ ]([a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "([a-z0-9]+)(?:_W)?\\;FOMA",
                "model": "$1"
            },
            {
                "regex": "KGT/1\\.0 ([a-z0-9]+)",
                "model": "$1"
            }
        ]
    },
    "Doogee": {
        "regex": "DOOGEE[ _-]|Valencia2_Y100_?(?:pro|plus)|Y100_Plus|X5max(?:[_ ]PRO)?|Shoot_[12]|x5_doogee|(KISSME|BIGBOY|COLLO[23]?|DAGGER|DISCOVERY2?|FIND|HOTWIND|LATTE|MAX|MINT|MOON|PIXELS|RAINBOX|TURBO|VALENCIA|VOYAGER2?|TITANS2?)[ -_](DG[0-9]+C?)(?:[);/ ]|$)|(?:BL12000|BL5500[_ ]Lite|X9 Mini|LEO_DG280|Y9Plus|Hitman_DG850|S(?:[568]8|9[056]|59|40)Pro|X95Pro|S40Lite|BL[579]000|N20Pro|(?<!Lenovo )S90C|Ibiza_F2)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(?:Valencia2_)?Y100_Plus(?:[);/ ]|$)",
                "model": "Valencia2 Y100 Plus"
            },
            {
                "regex": "Valencia2_Y100pro",
                "model": "Valencia2 Y100 Pro"
            },
            {
                "regex": "LEO_(DG280)",
                "model": "Leo $1"
            },
            {
                "regex": "(BL[579]000|S90C)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "X95Pro(?:[);/ ]|$)",
                "model": "X95 Pro"
            },
            {
                "regex": "N20Pro(?:[);/ ]|$)",
                "model": "N20 Pro"
            },
            {
                "regex": "BL12000 PRO",
                "model": "BL12000 Pro"
            },
            {
                "regex": "BL5500[_ ]Lite",
                "model": "BL5500 Lite"
            },
            {
                "regex": "BL12000",
                "model": "BL12000"
            },
            {
                "regex": "S([568]8|9[056]|59|40)Pro",
                "model": "S$1 Pro"
            },
            {
                "regex": "S40Lite",
                "model": "S40 Lite"
            },
            {
                "regex": "KISSME-DG580",
                "model": "Kissme DG580"
            },
            {
                "regex": "Hitman_DG850",
                "model": "Hitman DG580"
            },
            {
                "regex": "X9 Mini",
                "model": "X9 Mini"
            },
            {
                "regex": "x5_doogee",
                "model": "X5"
            },
            {
                "regex": "Shoot_([12])",
                "model": "Shoot $1"
            },
            {
                "regex": "Ibiza_F2",
                "model": "Ibiza F2"
            },
            {
                "regex": "(X5max(?:[_ ]PRO)?)",
                "model": "$1"
            },
            {
                "regex": "(?:DOOGEE[ _-])?(BIGBOY|COLLO[23]?|DAGGER|DISCOVERY2?|FIND|HOTWIND|LATTE|MAX|MINT|MOON|PIXELS|RAINBOX|TURBO|VALENCIA|Valencia2_Y100pro|VOYAGER2?|TITANS2?)[ -_](DG[0-9]+C?)(?:[);/ ]|$)",
                "model": "$1 $2"
            },
            {
                "regex": "DOOGEE[ _-]([^);/]+) Build",
                "model": "$1"
            },
            {
                "regex": "DOOGEE[ _-]([^);/]+)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "Y9Plus(?:[);/ ]|$)",
                "model": "Y9 Plus"
            }
        ]
    },
    "Doov": {
        "regex": "(?:Doov-)?Doov[ _]",
        "device": "smartphone",
        "models": [
            {
                "regex": "(?:Doov-)?Doov[ _]([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "(?:Doov-)?Doov[ _]([^);/]+)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Dopod": {
        "regex": "Dopod(?: )?([a-z0-9]+)",
        "device": "feature phone",
        "model": "$1"
    },
    "Doro": {
        "regex": "Doro",
        "device": "smartphone",
        "models": [
            {
                "regex": "Doro[ _-]?([^;/)]+) Build",
                "model": "$1"
            },
            {
                "regex": "Doro[ _-]([0-9a-z]+)",
                "model": "$1"
            }
        ]
    },
    "Dune HD": {
        "regex": "DuneHD|SOLO 4K(?:[);/ ]|$)",
        "device": "tv",
        "models": [
            {
                "regex": "SOLO 4K(?:[);/ ]|$)",
                "model": "Solo 4k"
            },
            {
                "regex": "DuneHD/\\d.\\d \\([a-z]+__([a-z0-9_-]+);",
                "model": "$1"
            },
            {
                "regex": "DuneHD/\\d.\\d \\(([a-z0-9_-]+);",
                "model": "$1"
            }
        ]
    },
    "DNS": {
        "regex": "(?:DNS|AirTab)[ _\\-]([^;/)]+)|(?:S4505M|S4004M|SD01M)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(?:DNS[_ ])?(S4505M|S4004M|SD01M|S4502|S4003|S4003)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "AirTab[ _\\-]([^;/]+)(?: Build|[);])",
                "model": "AirTab $1",
                "device": "tablet"
            },
            {
                "regex": "DNS[ _\\-]([^;/]+)(?: Build|[);])",
                "model": "$1"
            }
        ]
    },
    "E-Boda": {
        "regex": "E-Boda|Eclipse_(?:G400M|G500)",
        "device": "smartphone",
        "models": [
            {
                "regex": "E-Boda ((?:Revo|Izzycomm|Essential|Intelligence|Supreme)[^/;]+) Build",
                "device": "tablet",
                "model": "$1"
            },
            {
                "regex": "Eclipse_(G400M|G500)",
                "model": "Eclipse $1"
            },
            {
                "regex": "E-Boda ([^/;]+) Build",
                "model": "$1"
            }
        ]
    },
    "Easypix": {
        "regex": "EasyPad|EasyPhone",
        "device": "smartphone",
        "models": [
            {
                "regex": "(EasyPhone[^/;]+) Build",
                "model": "$1"
            },
            {
                "regex": "EasyPad ([^/;)]+)(?: Build|[;)])",
                "model": "EasyPad $1",
                "device": "tablet"
            }
        ]
    },
    "EBEST": {
        "regex": "EBEST",
        "device": "smartphone",
        "models": [
            {
                "regex": "EBEST[ _-]?([^;/)]+) Build",
                "model": "$1"
            },
            {
                "regex": "EBEST[ _-]?([^;/)]+)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Echo Mobiles": {
        "regex": "ECHO[ _](HOLI|HORIZON|MAX|MOSS|NOTE|POWER|SMART|DUNE|JAVA|FUSION)|HORIZON_M|Stellar 3G|FEELING",
        "device": "smartphone",
        "models": [
            {
                "regex": "ECHO_HORIZON_LITE",
                "model": "HORIZON Lite"
            },
            {
                "regex": "(HORIZON_M|FEELING)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "STELLAR 3G",
                "model": "STELLAR 3G"
            },
            {
                "regex": "ECHO_SMART_4G",
                "model": "SMART 4G"
            },
            {
                "regex": "ECHO[ _](HOLI|MAX|MOSS|NOTE|POWER|SMART|DUNE|JAVA|FUSION)",
                "model": "$1"
            }
        ]
    },
    "ECS": {
        "regex": "(?:TA10CA3|TM105A?|TR10[CR]S1)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "TA10CA3(?:[);/ ]|$)",
                "model": "TA10CA3"
            },
            {
                "regex": "(TR10[CR]S1)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "TM105A(?:[);/ ]|$)",
                "model": "TM105A"
            },
            {
                "regex": "TM105(?:[);/ ]|$)",
                "model": "TM105"
            }
        ]
    },
    "EE": {
        "regex": "Hawk_from_EE",
        "device": "smartphone",
        "models": [
            {
                "regex": "Hawk_from_EE",
                "model": "Hawk"
            }
        ]
    },
    "EKT": {
        "regex": "EK-(?:5040|5336|5540|5937|6020|8680|MII|T7020)",
        "device": "tablet",
        "models": [
            {
                "regex": "EK-(MII|T7020)",
                "model": "EK-$1"
            },
            {
                "regex": "EK-(5040|5336|5540|5937|6020|8680)",
                "device": "smartphone",
                "model": "EK-$1"
            }
        ]
    },
    "Eks Mobility": {
        "regex": "S5LS|X4UPlus",
        "device": "smartphone",
        "models": [
            {
                "regex": "S5LS",
                "model": "S5LS"
            },
            {
                "regex": "X4UPlus",
                "model": "X4U Plus"
            }
        ]
    },
    "Elephone": {
        "regex": "Elephone|P6000( ?Pro| ?Plus|\\+| ?02| 5.0 01)?",
        "device": "smartphone",
        "models": [
            {
                "regex": "Elephone Trunk(?:[);/ ]|$)",
                "model": "Trunk"
            },
            {
                "regex": "P6000(\\+| ?Plus)(?:[);/ ]|$)",
                "model": "P6000 Plus"
            },
            {
                "regex": "P6000 02(?:[);/ ]|$)",
                "model": "P6000 02"
            },
            {
                "regex": "P6000 5.0 01(?:[);/ ]|$)",
                "model": "P6000 01"
            },
            {
                "regex": "P6000 ?Pro(?:[);/ ]|$)",
                "model": "P6000 Pro"
            },
            {
                "regex": "(?:Elephone[ _])?(P[768]000|S2)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "Elephone[ _\\-]([^/;]+)(?: Build|[;)])",
                "model": "$1"
            }
        ]
    },
    "Element": {
        "regex": "AFTRS",
        "model": "Element 4K (2017)",
        "device": "tv"
    },
    "Elenberg": {
        "regex": "(TAB(?:101|728)[ _]3G|TAB7(?:[03]8|16|40|3[30]))(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "Ericy": {
        "regex": "Ericy-([a-z0-9]+)",
        "device": "feature phone",
        "model": "$1"
    },
    "Essential": {
        "regex": "PH-1[ )]",
        "device": "smartphone",
        "models": [
            {
                "regex": "PH-1[ )]",
                "model": "PH-1"
            }
        ]
    },
    "E-tel": {
        "regex": "E-tel_i250",
        "device": "smartphone",
        "models": [
            {
                "regex": "E-tel[_ ]i250",
                "model": "i250"
            }
        ]
    },
    "Evercoss": {
        "regex": "EVERCOSS[ _]|(A75A\\*|U50A_PLUS|M50 STAR|A75 MAX)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "A75A\\*(?:[);/ ]|$)",
                "model": "A75A Star"
            },
            {
                "regex": "A75 MAX*(?:[);/ ]|$)",
                "model": "A75 Max"
            },
            {
                "regex": "U50A_PLUS(?:[);/ ]|$)",
                "model": "U50A Plus"
            },
            {
                "regex": "M50 STAR(?:[);/ ]|$)",
                "model": "M50 Star"
            },
            {
                "regex": "EVERCOSS[ _](A7[BRLZ]|A65|A74A|A5Z)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Eurostar": {
        "regex": "(ET7002C-H12|Onyx-[13]S)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Onyx-([13]S)(?:[);/ ]|$)",
                "model": "Onyx $1"
            },
            {
                "regex": "ET7002C-H12(?:[);/ ]|$)",
                "model": "ePad 4S",
                "device": "tablet"
            }
        ]
    },
    "Eton": {
        "regex": "Eton",
        "device": "smartphone",
        "models": [
            {
                "regex": "Eton[ _-]?([^;/)]+) Build",
                "model": "$1"
            },
            {
                "regex": "Eton[ _-]?([^;/)]+)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Essentielb": {
        "regex": "(?:Wooze[_ ]?(?:I55?|I45|X?L)|HEYOU(?:[135]|30))(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "HEYOU([135]|30)(?:[);/ ]|$)",
                "model": "HEYou $1"
            },
            {
                "regex": "Wooze[_ ]?((?:I55?|I45|X?L))(?:[);/ ]|$)",
                "model": "Wooze $1"
            }
        ]
    },
    "Facebook": {
        "regex": "PortalTV",
        "device": "tv",
        "model": "Portal TV"
    },
    "Rikomagic": {
        "regex": "MK(80[28][^/;]*) Build",
        "device": "tv",
        "model": "MK$1"
    },
    "Rinno": {
        "regex": "Rinno (R(?:400|505))",
        "device": "smartphone",
        "model": "$1"
    },
    "Riviera": {
        "regex": "RIVIERA ",
        "device": "smartphone",
        "models": [
            {
                "regex": "F23",
                "model": "F23"
            }
        ]
    },
    "Senseit": {
        "regex": "SENSEIT[ _]?([^;/)]+)( Build|\\))",
        "device": "smartphone",
        "model": "$1"
    },
    "Sony": {
        "regex": "Sony(?: ?Ericsson)?|SGP|Xperia|(?:[4-9]0[12])SO|C1[569]0[45]|C2[01]0[45]|C230[45]|C530[236]|C550[23]|C6[56]0[236]|C6616|C68(?:0[26]|[34]3)|C69(?:0[236]|16|43)|D200[45]|D21(?:0[45]|14)|D22(?:0[236]|12|43)|D230[2356]|D240[36]|D25(?:02|33)|D510[236]|D530[36]|D5316|D5322|D5503|D58[03]3|D65(?:0[23]|43|63)|D66[03458]3|D66[14]6|D6708|E(?:20[0345]3|2006|210[45]|2115|2124|230[36]|2312|23[356]3|530[36]|53[3456]3|5506|55[356]3|56[46305][36]|58[02]3|65[35]3|66[0358]3|68[358]3)|I[34]312|I4332|F311[12356]|F331[13]|F321[12356]|F5[13]21|F5122|F813[12]|F833[12]|G312[135]|G311[26]|G322[136]|G3212|G331[123]|G3412|G3416|G342[136]|G823[12]|G834[123]|G8[14]4[12]|H(?:3113|3123|3133|3213|3223|3311|3321|4113|4133|4213|4233|4311|4331|4413|4433|82[167]6|83[12]4|8416|9436)|(?:WT|LT|SO|ST|SK|MK)[0-9]+[a-z]+[0-9]*(?: Build|\\))|X?L39H|XM50[ht]|W960|portalmmm/2\\.0 K|S3[69]h|SOG01|SOL2[2346]|SOV3[1-9]|SOV4[0-3]|X10[ia]v?|E1[05][ai]v?|MT[0-9]{2}[a-z]? Build|SO-0(?:[12]C|[345]D|[234]E|[1-5]F|[1-5]G|[1-4]H|[1-4]J|[1-5]K|1M|[1-3]L)|R800[aix]|J3173|J82[17]0|J9110|J92[16]0|J81[17]0|I[34]113|I3[12]23|I42[19]3|H9493|H8296|H8166|H4493|G2299|LiveWithWalkman|BRAVIA|SGP771|E3 Dual|A001SO|I4193|E6508|SOT31|SO-[45]1A|SO-52A|XQ-A[DT]51|XQ-A[TU]42|XQ-AS[67]2|XQ-A[TSU]52|SOL25|SOG02|A002SO|NW-(A100|Z1000)Series|J3273",
        "device": "smartphone",
        "models": [
            {
                "regex": "(?:Sony(?:Ericsson)?)?E10[ai]v?",
                "model": "Xperia X10 mini",
                "brand": "Sony Ericsson"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?U20",
                "model": "Xperia X10 mini pro",
                "brand": "Sony Ericsson"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?E15[ai]v?",
                "model": "Xperia X8",
                "brand": "Sony Ericsson"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?(LT15[ai]?v?|SO-01C)",
                "model": "Xperia arc",
                "brand": "Sony Ericsson"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?LT18[ai]v?",
                "model": "Xperia arc S",
                "brand": "Sony Ericsson"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?MT11",
                "model": "Xperia neo V",
                "brand": "Sony Ericsson"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?MT15",
                "model": "Xperia neo",
                "brand": "Sony Ericsson"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?MT27",
                "model": "Xperia Sola",
                "brand": "Sony Ericsson"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?MK16[ai]",
                "model": "Xperia pro",
                "brand": "Sony Ericsson"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?ST15[ai]",
                "model": "Xperia mini",
                "brand": "Sony Ericsson"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?ST17[ai]",
                "model": "Xperia active",
                "brand": "Sony Ericsson"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?SO-02L",
                "model": "Xperia Ace",
                "brand": "Sony Ericsson"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?ST18[ai]",
                "model": "Xperia ray",
                "brand": "Sony Ericsson"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?SK17[ai]?",
                "model": "Xperia mini pro",
                "brand": "Sony Ericsson"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?X10[ai]v?",
                "model": "Xperia X10",
                "brand": "Sony Ericsson"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)M1",
                "model": "Aspen",
                "brand": "Sony Ericsson"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?R800",
                "model": "Xperia PLAY",
                "brand": "Sony Ericsson"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?WT19|LiveWithWalkman",
                "model": "Live with Walkman",
                "brand": "Sony Ericsson"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?CK13i",
                "model": "txt",
                "brand": "Sony Ericsson",
                "device": "feature phone"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?CK15[ai]",
                "model": "txt pro",
                "brand": "Sony Ericsson",
                "device": "feature phone"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?F100i",
                "model": "Jalou",
                "brand": "Sony Ericsson",
                "device": "feature phone"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?J105",
                "model": "Naite",
                "brand": "Sony Ericsson",
                "device": "feature phone"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?J108",
                "model": "Cedar",
                "brand": "Sony Ericsson",
                "device": "feature phone"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?J10",
                "model": "Elm",
                "brand": "Sony Ericsson",
                "device": "feature phone"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?J20",
                "model": "Hazel",
                "brand": "Sony Ericsson",
                "device": "feature phone"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?U10",
                "model": "Aino",
                "brand": "Sony Ericsson",
                "device": "feature phone"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?U100",
                "model": "Yari",
                "brand": "Sony Ericsson",
                "device": "feature phone"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?U1",
                "model": "Satio",
                "brand": "Sony Ericsson",
                "device": "feature phone"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?U5",
                "model": "Vivaz",
                "brand": "Sony Ericsson",
                "device": "feature phone"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?U8",
                "model": "Vivaz pro",
                "brand": "Sony Ericsson",
                "device": "feature phone"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?W20",
                "model": "Zylo",
                "brand": "Sony Ericsson",
                "device": "feature phone"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?W100",
                "model": "Spiro",
                "brand": "Sony Ericsson",
                "device": "feature phone"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?W150",
                "model": "Yendo",
                "brand": "Sony Ericsson",
                "device": "feature phone"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?W960",
                "model": "W960",
                "brand": "Sony Ericsson",
                "device": "feature phone"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?WT13",
                "model": "Mix Walkman",
                "brand": "Sony Ericsson",
                "device": "feature phone"
            },
            {
                "regex": "SO-04F",
                "model": "Xperia A2"
            },
            {
                "regex": "G2299",
                "model": "Xperia R1 Plus"
            },
            {
                "regex": "SO-04G",
                "model": "Xperia A4"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?I(?:4113|31[12]3)",
                "model": "Xperia 10"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?J3173(?:[);/ ]|$)",
                "model": "Xperia Ace"
            },
            {
                "regex": "(?:A001SO|XQ-A[TU]52|XQ-AU42|SO-41A|SOV43)(?:[);/ ]|$)",
                "model": "Xperia 10 II"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?I(?:42[19]3|3223)",
                "model": "Xperia 10 Plus"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?I4193",
                "model": "Xperia 10 Dual"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?(?:J(?:9110|81[17]0)|SOV40|SO-03L|802SO)",
                "model": "Xperia 1"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?(?:SOG01|SO-51A|XQ-AT42|XQ-AT51)(?:[);/ ]|$)",
                "model": "Xperia 1 II"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?LT22i|Xperia P",
                "model": "Xperia P"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?LT25i|Xperia V",
                "model": "Xperia V"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?J92[16]0",
                "model": "Xperia 5 Dual"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?(?:J82[17]0|SO-01M|SOV41|901SO)",
                "model": "Xperia 5"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?(?:SOG02|A002SO|XQ-AS[567]2|SO-52A)",
                "model": "Xperia 5 II"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?(?:SOV42|902SO)",
                "model": "Xperia 8"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?J3273",
                "model": "Xperia 8 Lite"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?LT26ii",
                "model": "Xperia SL"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?LT26i|Xperia S(?:[);/ ]|$)",
                "model": "Xperia S"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?SO-02C",
                "model": "Xperia arco"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?SO-03D",
                "model": "Xperia arco HD"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?LT26w",
                "model": "Xperia arco S"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?LT28[aih]",
                "model": "Xperia ion"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?LT29i|Xperia TX(?:[);/ ]|$)",
                "model": "Xperia TX"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?LT30a",
                "model": "Xperia T LTE"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?LT30p",
                "model": "Xperia T"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?ST21[ia]2",
                "model": "Xperia tipo dual"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?ST21[ia]",
                "model": "Xperia tipo"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?ST23[ia]",
                "model": "Xperia miro"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?ST25[ia]",
                "model": "Xperia U"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?SOL22",
                "model": "Xperia UL"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?ST26[ia]",
                "model": "Xperia J"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?ST27[ia]",
                "model": "Xperia go"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?C150[45]",
                "model": "Xperia E"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?C160[45]",
                "model": "Xperia E Dual"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?(?:E210[45]|E2115|E2124)",
                "model": "Xperia E4"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?E20[05]3|E2006",
                "model": "Xperia E4G"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?C190[45]",
                "model": "Xperia M"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?C200[45]",
                "model": "Xperia M Dual"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?C210[45]|S36h",
                "model": "Xperia L"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?C230[45]|S39h",
                "model": "Xperia C"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?C530[236]|Xperia SP(?:[);/ ]|$)",
                "model": "Xperia SP"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?C550[23]",
                "model": "Xperia ZR"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?C650[236]|Xperia ZL(?:[);/ ]|$)",
                "model": "Xperia ZL"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?SOL25(?:[);/ ]|$)",
                "model": "Xperia ZL2"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?(?:C66(?:0[236]|16)|SO-02E)",
                "model": "Xperia Z"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?(?:C68(?:0[26]|[34]3)|XL39H|SOL24)|Xperia Z Ultra(?:[);/ ]|$)",
                "device": "phablet",
                "model": "Xperia Z Ultra"
            },
            {
                "regex": "Xperia Z(?:[);/ ]|$)",
                "model": "Xperia Z"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?D200[45]",
                "model": "Xperia E1"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?D21(?:0[45]|14)",
                "model": "Xperia E1 Dual"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?D22(?:0[236]|43)",
                "model": "Xperia E3"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?D2212|E3 Dual",
                "model": "Xperia E3 Dual"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?E20[34]3",
                "model": "Xperia E4g Dual"
            },
            {
                "regex": "H33[12]1",
                "model": "Xperia L2"
            },
            {
                "regex": "H43[13]1",
                "model": "Xperia L2 Dual"
            },
            {
                "regex": "I3312",
                "model": "Xperia L3"
            },
            {
                "regex": "I43[13]2",
                "model": "Xperia L3 Dual"
            },
            {
                "regex": "XQ-AD51",
                "model": "Xperia L4"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?D2302",
                "model": "Xperia M2 Dual"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?D230[356]",
                "model": "Xperia M2"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?D240[36]",
                "model": "Xperia M2 Aqua"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?(?:E230[36]|E23[35]3)",
                "model": "Xperia M4 Aqua"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?(?:E2312|E2363)",
                "model": "Xperia M4 Aqua Dual"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?E56[05][36]",
                "model": "Xperia M5"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?E56[436]3",
                "model": "Xperia M5 Dual"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?D2502",
                "model": "Xperia C3 Dual"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?D2533",
                "model": "Xperia C3"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?E53[05]3|E5306",
                "model": "Xperia C4"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?E53[0346]3",
                "model": "Xperia C4 Dual"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?E5506|E5553",
                "model": "Xperia C5 Ultra"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?E55[36]3",
                "model": "Xperia C5 Ultra Dual"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?D510[236]",
                "model": "Xperia T3"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?(?:D530[36]|D5316|XM50[ht])",
                "model": "Xperia T2 Ultra",
                "device": "phablet"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?D5322",
                "model": "Xperia T2 Ultra Dual",
                "device": "phablet"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?D5503|Xperia Z1 Compact",
                "model": "Xperia Z1 Compact"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?(?:C69(?:0[236]|16|43)|SOL23|L39H|SO-0[12]F)|Xperia Z1(?:[);/ ]|$)",
                "model": "Xperia Z1"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?(?:D58[03]3|SO-02G)|Xperia Z3 Compact(?:[);/ ]|$)",
                "model": "Xperia Z3 Compact"
            },
            {
                "regex": "Xperia Z2 Tablet Wifi",
                "model": "Xperia Z2 Tablet WiFi",
                "device": "tablet"
            },
            {
                "regex": "SGP561",
                "model": "Xperia Z2 Tablet",
                "device": "tablet"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?(?:D65(?:0[23]|43)|SO-03F)|Xperia Z2(?:[);/ ]|$)",
                "model": "Xperia Z2"
            },
            {
                "regex": "D6563",
                "model": "Xperia Z2a"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?(?:D66[045]3|D66[14]6|SO-01G|SOL26|401SO)|Xperia Z3(?:[);/ ]|$)",
                "model": "Xperia Z3"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?(?:D6633|D6683)",
                "model": "Xperia Z3 Dual"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?(?:E65[35]3)",
                "model": "Xperia Z3+"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?(?:D6708)",
                "model": "Xperia Z3v"
            },
            {
                "regex": "SOV31|402SO|SO-03G",
                "model": "Xperia Z4"
            },
            {
                "regex": "E6508",
                "model": "Xperia Z4v"
            },
            {
                "regex": "SGP771|SOT31|SO-05G",
                "model": "Xperia Z4",
                "device": "tablet"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?(?:E58[02]3|SO-02H)",
                "model": "Xperia Z5 Compact"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?(?:E66[05]3|SOV32|501SO|SO-01H)",
                "model": "Xperia Z5"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?E66[38]3",
                "model": "Xperia Z5 Dual"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?(?:E68[58]3|SO-03H)",
                "model": "Xperia Z5 Premium"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?E6833",
                "model": "Xperia Z5 Premium Dual"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?F311[135]",
                "model": "Xperia XA"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?F311[26]",
                "model": "Xperia XA Dual"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?F321[135]",
                "model": "Xperia XA Ultra"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?F321[26]",
                "model": "Xperia XA Ultra Dual"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?F833[12]|SOV34|601SO|SO-01J",
                "model": "Xperia XZ"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?(?:701SO|SOV36|G834[123]|SO-01K)",
                "model": "Xperia XZ1"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?G8441|SO-02K",
                "model": "Xperia XZ1 Compact"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?F331[13]",
                "model": "Xperia E5"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?G312[135]|G311[26]",
                "model": "Xperia XA1"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?G341[26]|G342[13]",
                "model": "Xperia XA1 Plus"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?G3426",
                "model": "Xperia XA1 Plus Dual"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?G322[136]|G3212",
                "model": "Xperia XA1 Ultra"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?H31[123]3",
                "model": "Xperia XA2"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?H41[13]3",
                "model": "Xperia XA2 Dual"
            },
            {
                "regex": "H44[19]3",
                "model": "Xperia XA2 Plus Dual"
            },
            {
                "regex": "H4433",
                "model": "H4433"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?H(?:3213|3223|4213|4233)",
                "model": "Xperia XA2 Ultra"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?G823[12]|SOV35|602SO|SO-03J",
                "model": "Xperia XZs"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?G814[12]|SO-04[JK]",
                "model": "Xperia XZ Premium"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?(?:H83[12]4|H82[17]6|SOV37|SO-03K|H8296|702SO)",
                "model": "Xperia XZ2"
            },
            {
                "regex": "H8266",
                "model": "Xperia XZ2 Dual"
            },
            {
                "regex": "SO-05K",
                "model": "Xperia XZ2 Compact"
            },
            {
                "regex": "(?:H8166|SOV38)(?:[);/ ]|$)",
                "model": "Xperia XZ2 Premium Dual"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?(?:H(8416|9436|9493)|SO-01L|SOV39|801SO)",
                "model": "Xperia XZ3"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?G331[13]",
                "model": "Xperia L1"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?G3312",
                "model": "Xperia L1 Dual"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?SO-04E",
                "model": "Xperia A"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?SO-04D",
                "model": "Xperia GX"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?SO-05D",
                "model": "Xperia SX"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?F5121",
                "model": "Xperia X"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?F5122",
                "model": "Xperia X Dual"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?F5321|SO-02J",
                "model": "Xperia X Compact"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?(?:F8131|502SO|SOV33|SO-04H)",
                "model": "Xperia X Performance"
            },
            {
                "regex": "(?:Sony(?:Ericsson)?)?F8132",
                "model": "Xperia X Performance Dual"
            },
            {
                "regex": "SGP(?:311|312|321)(?:[);/ ]|$)|SO-03E",
                "model": "Xperia Tablet Z",
                "device": "tablet"
            },
            {
                "regex": "SGP712(?:[);/ ]|$)",
                "model": "Xperia Tablet Z4",
                "device": "tablet"
            },
            {
                "regex": "SGP(?:511|512|521)(?:[);/ ]|$)|SO-05F",
                "model": "Xperia Tablet Z2",
                "device": "tablet"
            },
            {
                "regex": "SGP(?:6[124]1|612)(?:[);/ ]|$)",
                "model": "Xperia Tablet Z3 Compact",
                "device": "tablet"
            },
            {
                "regex": "SGPT(?:12|121|122|123|13|131|132|133)(?:[);/ ]|$)",
                "model": "Xperia Tablet S",
                "device": "tablet"
            },
            {
                "regex": "(?:BRAVIA )(2K GB ATV3|4K UR2|4K GB ATV3|4K GB|4K 2015|2015)(?:[);/ ]|$)",
                "model": "Bravia $1",
                "device": "tv"
            },
            {
                "regex": "NW-A100Series",
                "model": "Walkman A100",
                "device": "portable media player"
            },
            {
                "regex": "NW-Z1000Series",
                "model": "Walkman Z1000",
                "device": "portable media player"
            },
            {
                "regex": "SonyBDP|SonyDBV",
                "model": "Blu-ray Player",
                "device": "tv"
            },
            {
                "regex": "(?:Sony-)?(KDL?-?[0-9a-z]+)",
                "model": "$1",
                "device": "tv"
            },
            {
                "regex": "Opera TV Store.*(?:Sony-)([0-9a-z\\-_]+)",
                "model": "$1",
                "device": "tv"
            },
            {
                "regex": "((?:WT|SO|ST|SK|MK)[0-9]+[a-z]*[0-9]*)(?: Build|\\))",
                "model": "$1",
                "brand": "Sony Ericsson"
            },
            {
                "regex": "(MT[0-9]{2}[a-z]?)(?:[);/ ]|$)",
                "model": "$1",
                "brand": "Sony Ericsson"
            },
            {
                "regex": "portalmmm/2.0 K([a-z0-9]+)",
                "model": "K$1",
                "brand": "Sony Ericsson"
            },
            {
                "regex": "Sony ?Ericsson?([^/;]*) Build",
                "model": "$1",
                "brand": "Sony Ericsson"
            },
            {
                "regex": "SonyEricsson([CGJKPTWZ][0-9]+[a-z]+)/",
                "model": "$1",
                "brand": "Sony Ericsson",
                "device": "feature phone"
            },
            {
                "regex": "Sony ?Ericsson ?([a-z0-9\\-]+)",
                "model": "$1",
                "brand": "Sony Ericsson"
            },
            {
                "regex": "Sony (Tablet[^/;]*) Build",
                "model": "$1",
                "device": "tablet"
            },
            {
                "regex": "(SGP[^/;]*) Build",
                "model": "$1",
                "device": "tablet"
            },
            {
                "regex": "Xperia ([^/;]*Tablet[^/;]*) Build",
                "model": "Xperia $1",
                "device": "tablet"
            },
            {
                "regex": "Xperia ([^;/]+) Build",
                "model": "Xperia $1"
            },
            {
                "regex": "Sony[ -]?([^/;]*) Build",
                "model": "$1"
            },
            {
                "regex": "Sony[ -]?([a-z0-9\\-]+)",
                "model": "$1"
            }
        ]
    },
    "Ergo": {
        "regex": "ERGO|(V550[_ ]Vision|B502 Basic|B504[_ ]Unit|A502[_ ]Aurum|A503[ _]Optima|Prime B400|A551 Sky 4G|A553 Power|A555 Universe|Force F500|GoTab GBT9|GoTab Gti8|F501_Magic|V551[ _]Aura|V540_Level|V570_BIG_BEN|B506[_ ]INTRO|A503-Optima|B505_Unit_4G)",
        "device": "smartphone",
        "models": [
            {
                "regex": "F501_Magic",
                "model": "F501 Magic"
            },
            {
                "regex": "B502 Basic",
                "model": "B502 Basic"
            },
            {
                "regex": "A503-Optima",
                "model": "A503 Optima"
            },
            {
                "regex": "B506[_ ]INTRO",
                "model": "B506 Intro"
            },
            {
                "regex": "V550[_ ]Vision",
                "model": "V550 Vision"
            },
            {
                "regex": "B504[_ ]Unit",
                "model": "B504 Unit"
            },
            {
                "regex": "B505_Unit_4G",
                "model": "B505 Unit 4G"
            },
            {
                "regex": "V551[ _]Aura",
                "model": "V551 Aura"
            },
            {
                "regex": "V540_Level",
                "model": "V540 Level"
            },
            {
                "regex": "V570_BIG_BEN",
                "model": "V570 Big Ben"
            },
            {
                "regex": "Ergo F502",
                "model": "F502 Platinum"
            },
            {
                "regex": "(ERGO_)?A500[ _]Best",
                "model": "A500 Best"
            },
            {
                "regex": "(ERGO_)?A550[ _]Maxx",
                "model": "A550 Maxx"
            },
            {
                "regex": "Prime B400",
                "model": "Prime B400"
            },
            {
                "regex": "A502[_ ]Aurum",
                "model": "A502 Aurum"
            },
            {
                "regex": "A503[ _]Optima",
                "model": "A503 Optima"
            },
            {
                "regex": "A551 Sky 4G",
                "model": "A551 Sky 4G"
            },
            {
                "regex": "A553 Power",
                "model": "A553 Power"
            },
            {
                "regex": "A555 Universe",
                "model": "A555 Universe"
            },
            {
                "regex": "ERGO[_ ]A556",
                "model": "A556"
            },
            {
                "regex": "ERGO[_ ]F500",
                "model": "F500"
            },
            {
                "regex": "ERGO[_ ]F501",
                "model": "F501"
            },
            {
                "regex": "B500 First",
                "model": "B500 First"
            },
            {
                "regex": "Force F500",
                "model": "Force F500"
            },
            {
                "regex": "GoTab GBT9",
                "model": "GoTab GBT9",
                "device": "tablet"
            },
            {
                "regex": "GoTab Gti8",
                "model": "GoTab GTi8",
                "device": "tablet"
            },
            {
                "regex": "ERGO[_ ]Tab[ _]Crystal[ _]Lite",
                "model": "Tab Crystal Lite",
                "device": "tablet"
            },
            {
                "regex": "ERGO[_ ]Tab[ _]Hero[ _]II",
                "model": "Tab Hero II",
                "device": "tablet"
            },
            {
                "regex": "ERGO[_ ](B700)",
                "model": "Tab $1",
                "device": "tablet"
            },
            {
                "regex": "ERGO SmartBox SX",
                "model": "SmartBox SX",
                "device": "tv"
            }
        ]
    },
    "Ericsson": {
        "regex": "(?:Ericsson(?:/ )?[a-z0-9]+)|(?:R380 2.0 WAP1.1)",
        "device": "feature phone",
        "models": [
            {
                "regex": "Ericsson(?:/ )?([a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "R380 2.0 WAP1.1",
                "model": "R380"
            }
        ]
    },
    "Energizer": {
        "regex": "PowerMax|ENERGY[_ ]?(?:[45]00|400S|S[56]00|E12|E5[02]0S?)(?:[_ ](?:LTE|EU))?",
        "device": "smartphone",
        "models": [
            {
                "regex": "PowerMax(P[0-9]+S?)",
                "model": "Power Max $1"
            },
            {
                "regex": "ENERGY[_ ]([45]00|E520)[_ ]LTE",
                "model": "Energy $1 LTE"
            },
            {
                "regex": "ENERGY[_ ]400S",
                "model": "Energy 400S"
            },
            {
                "regex": "ENERGY400",
                "model": "Energy 400"
            },
            {
                "regex": "ENERGY[_ ]S([56]00)",
                "model": "Energy S$1"
            },
            {
                "regex": "ENERGY[_ ]?(E500S|E500|E12)",
                "model": "Energy $1"
            }
        ]
    },
    "Energy Sistem": {
        "regex": "ENERGY[ _-]?([^;/]+) Build|Energy Phone Pro 3",
        "device": "smartphone",
        "models": [
            {
                "regex": "Energy Phone Pro 3",
                "model": "Energy Phone Pro 3"
            },
            {
                "regex": "ENERGY[ _-]?([^;/]+) Build",
                "device": "tablet",
                "model": "$1"
            }
        ]
    },
    "eTouch": {
        "regex": "eTouch ?([a-z0-9]+)",
        "device": "smartphone",
        "model": "$1"
    },
    "Etuline": {
        "regex": "(ETL-S(?:3520|4521|5042|5084|6022)|ETL-T(?:752G|882G|9[78]0))(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(ETL-S(?:[^/); ]+))",
                "model": "$1"
            },
            {
                "regex": "(ETL-T(?:[^/); ]+))",
                "device": "tablet",
                "model": "$1"
            }
        ]
    },
    "Storex": {
        "regex": "eZee[^a-z]*Tab ?([^;/]*) Build|STOREX LinkBox",
        "device": "tablet",
        "models": [
            {
                "regex": "eZee[^a-z]*Tab ?([^;/]*) Build",
                "model": "eZee'Tab$1"
            },
            {
                "regex": "STOREX LinkBox",
                "model": "LinkBox",
                "device": "tv"
            }
        ]
    },
    "Evertek": {
        "regex": "(Ever(?:Glory|Shine|Miracle|Mellow|Classic|Trendy|Fancy|Vivid|Slim|Glow|Magic|Smart|Star)[^/;]*) Build|E70[25]0HD|E805[01]HD|E9054HD|E8050HG|E7914HG",
        "device": "smartphone",
        "models": [
            {
                "regex": "(E70[25]0HD|E805[01]HD|E9054HD|E8050HG|E7914HG)",
                "device": "tablet",
                "model": "Everpad $1"
            },
            {
                "regex": "(Ever(?:Glory|Shine|Miracle|Mellow|Classic|Trendy|Fancy|Vivid|Slim|Glow|Magic|Smart|Star)[^/;]*) Build",
                "model": "$1"
            }
        ]
    },
    "Evolio": {
        "regex": "Evolio|M4MAGIC",
        "device": "smartphone",
        "models": [
            {
                "regex": "M4MAGIC",
                "model": "M4 Magic"
            },
            {
                "regex": "Evolio_M5Pro",
                "model": "M5 Pro"
            },
            {
                "regex": "Evolio[ _](M6|S4 Cobalt|S5|X10|Yuppi)",
                "model": "$1"
            }
        ]
    },
    "Evolveo": {
        "regex": "EVOLVEO|StrongPhone",
        "device": "smartphone",
        "models": [
            {
                "regex": "EVOLVEO MultiMedia Box M4",
                "device": "tv",
                "model": "MultiMedia Box M4"
            },
            {
                "regex": "Smart TV ([^;/]*) Build",
                "device": "tv",
                "model": "Smart TV $1"
            },
            {
                "regex": "EVOLVEO StrongPhone G([248])",
                "model": "StrongPhone G$1"
            },
            {
                "regex": "(?:EVOLVEO[ _])?StrongPhone[ _]?Q([679])(_LTE)?",
                "model": "StrongPhone Q$1$2"
            },
            {
                "regex": "EVOLVEO[ _]([^;/]*) Build",
                "model": "$1"
            }
        ]
    },
    "Explay": {
        "regex": "Explay|[\\w]+-EXPLAY-FIN|ActiveD[ _]|Atlant |Informer[ _][0-9]+|CinemaTV 3G|Surfer[ _][0-9\\.]|sQuad[ _][0-9\\.]|Onliner[1-3]|RioPlay|Leader|Pulsar|Infinityll|Imperium[78]|Indigo|Lagoon",
        "device": "tablet",
        "models": [
            {
                "regex": "ActiveD[ _]7.4[ _]3G",
                "model": "ActiveD 7.4 3G"
            },
            {
                "regex": "Imperium([78])(?:[);/ ]|$)",
                "model": "Imperium $1 3G"
            },
            {
                "regex": "ActiveD[ _]8.2[ _]3G",
                "model": "ActiveD 8.2 3G"
            },
            {
                "regex": "CinemaTV[ _]3G",
                "model": "CinemaTV 3G"
            },
            {
                "regex": "Infinityll",
                "model": "Infinity II"
            },
            {
                "regex": "Informer[ _]([0-9]+(?:[ _]3G)?)",
                "model": "Informer $1"
            },
            {
                "regex": "Surfer[ _]([0-9\\.]+(?:[ _]3G)?)",
                "model": "Surfer $1"
            },
            {
                "regex": "sQuad[ _]([0-9\\.]+(?:[ _]3G)?)",
                "model": "sQuad $1"
            },
            {
                "regex": "Onliner([1-3])",
                "model": "Onliner $1"
            },
            {
                "regex": "Atlant(?:[);/ ]|$)",
                "device": "smartphone",
                "model": "Atlant"
            },
            {
                "regex": "Indigo(?:[);/ ]|$)",
                "device": "smartphone",
                "model": "Indigo"
            },
            {
                "regex": "Pulsar(?:[);/ ]|$)",
                "device": "smartphone",
                "model": "Pulsar"
            },
            {
                "regex": "[\\w]+-EXPLAY-FIN",
                "device": "feature phone",
                "model": "Fin"
            },
            {
                "regex": "Lagoon(?:[);/ ]|$)",
                "model": "Lagoon"
            },
            {
                "regex": "Explay[ _-]([^;/]+)(?: Build|[;)])",
                "model": "$1"
            },
            {
                "regex": "RioPlay(?:[);/ ]|$)",
                "device": "smartphone",
                "model": "Rio Play"
            },
            {
                "regex": "Leader",
                "model": "Leader"
            }
        ]
    },
    "EvroMedia": {
        "regex": "EVROMEDIA|Playpad[ _]|PLAY_PAD_PRO_XL",
        "device": "tablet",
        "models": [
            {
                "regex": "Playpad 3G GOO",
                "model": "Play Pad 3G Goo"
            },
            {
                "regex": "Play Pad 3G 2Goo",
                "model": "Play Pad 3G 2Goo"
            },
            {
                "regex": "Play Pad PRO",
                "model": "Play Pad Pro"
            },
            {
                "regex": "PLAY_PAD_PRO_XL",
                "model": "Play Pad Pro XL"
            },
            {
                "regex": "PlayPad 3GTab XL",
                "model": "Play Pad 3G Tab XL"
            }
        ]
    },
    "EKO": {
        "regex": "EKO",
        "device": "smartphone",
        "models": [
            {
                "regex": "EKO[ _-]?([^;/)]+) Build",
                "model": "$1"
            },
            {
                "regex": "EKO[ _-]?([^;/)]+)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Extrem": {
        "regex": "Rock X9+|ROCK_X11",
        "device": "smartphone",
        "models": [
            {
                "regex": "Rock X9+",
                "model": "Rock X9+"
            },
            {
                "regex": "ROCK_X11",
                "model": "Rock X11"
            }
        ]
    },
    "Ezze": {
        "regex": "EZZE-|EZ(?!Browser)[a-z0-9]+",
        "device": "feature phone",
        "models": [
            {
                "regex": "EZZE-([a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "EZ([a-z0-9]+)",
                "model": "EZ$1"
            }
        ]
    },
    "Ezio": {
        "regex": "EZIO-([a-z0-9]+)",
        "device": "feature phone",
        "model": "$1"
    },
    "Forstar": {
        "regex": "Amosta ?3G5",
        "device": "smartphone",
        "models": [
            {
                "regex": "Amosta ?3G5",
                "model": "Amosta 3G5"
            }
        ]
    },
    "Foxconn": {
        "regex": "InFocus M[0-9]+[a-z]?",
        "device": "smartphone",
        "models": [
            {
                "regex": "InFocus M([0-9]+[a-z]?)",
                "model": "InFocus M$1"
            }
        ]
    },
    "Fondi": {
        "regex": "Fondi[- _]([^;/)]+)|(T702|Q008B|Q010B|Q718B|Q902|T602B|T707G|T708B|T725B|T725B1|T907B)(?:\\)| Build)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(T702|T602B)",
                "model": "$1"
            },
            {
                "regex": "(Q008B|Q010B|Q718B|Q902|T707G|T708B|T725B|T725B1|T907B)",
                "device": "tablet",
                "model": "$1"
            },
            {
                "regex": "Fondi[- _]([^;/)]+)",
                "model": "$1"
            }
        ]
    },
    "Fairphone": {
        "regex": "(FP(?:1U?|2))(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Famoco": {
        "regex": "(FX[123]00)",
        "device": "smartphone",
        "model": "$1"
    },
    "FiGO": {
        "regex": "ATRIUM II F55L2|TRIO F40LT|CENTRIC S50G|EPIC F50G|Gravity X55L|Orion M50L|ULTRA M50G|M405B|VIRTUE3|F55L Build",
        "device": "smartphone",
        "models": [
            {
                "regex": "ATRIUM II F55L2",
                "model": "Atrium II"
            },
            {
                "regex": "(F55L)",
                "model": "$1"
            },
            {
                "regex": "TRIO F40LT",
                "model": "Trio F40LT"
            },
            {
                "regex": "EPIC F50G",
                "model": "Epic F50G"
            },
            {
                "regex": "(Gravity X55L|Orion M50L)",
                "model": "$1"
            },
            {
                "regex": "ULTRA M50G",
                "model": "Prime"
            },
            {
                "regex": "M405B_8GB",
                "model": "Virtue II 8GB"
            },
            {
                "regex": "M405B",
                "model": "Virtue II"
            },
            {
                "regex": "VIRTUE3",
                "model": "Virtue III"
            },
            {
                "regex": "CENTRIC S50G",
                "model": "Centric S50G"
            }
        ]
    },
    "FNB": {
        "regex": "ConeXis (A[12]|X[12])|SP5045V",
        "device": "smartphone",
        "models": [
            {
                "regex": "SP5045V",
                "model": "SP5045V"
            },
            {
                "regex": "ConeXis A1",
                "model": "ConeXis A1"
            },
            {
                "regex": "ConeXis A2",
                "model": "ConeXis A2"
            },
            {
                "regex": "ConeXis X1",
                "model": "ConeXis X1"
            },
            {
                "regex": "ConeXis X2",
                "model": "ConeXis X2"
            }
        ]
    },
    "Fujitsu": {
        "regex": "(?:F-01[FHJKLM]|F-02[EFGHLK]|F-03[DEFGKHL]|F-04[EGFHKJ]|F-05[DEFJG]|F-06[EF]|F-07[DE]|F-08D|F-09[DE]|F-10D|F-[45]1A|F-[45]2A|F-11D|F-12C|M532|FARTM933KZ|901FJ|arrows(?:RX|M03|M0[45](?:-PREMIUM)?)|801FJ|FJL2[12])(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "F-51A(?:[);/ ]|$)",
                "model": "Arrows 5G F-51A"
            },
            {
                "regex": "F-01F(?:[);/ ]|$)",
                "model": "Arrows NX F-01F"
            },
            {
                "regex": "(F-01[LM]|F-06F|F-03K|F-42A)(?:[);/ ]|$)",
                "model": "Easy Phone $1"
            },
            {
                "regex": "F-03L(?:[);/ ]|$)",
                "model": "Arrows Phone F-03L"
            },
            {
                "regex": "F-01J(?:[);/ ]|$)",
                "model": "Arrows NX F-01J"
            },
            {
                "regex": "F-01K(?:[);/ ]|$)",
                "model": "Arrows NX F-01K"
            },
            {
                "regex": "F-01H(?:[);/ ]|$)",
                "model": "Arrows Fit F-01H"
            },
            {
                "regex": "F-02E(?:[);/ ]|$)",
                "model": "Arrows X F-02E"
            },
            {
                "regex": "F-02G(?:[);/ ]|$)",
                "model": "Arrows NX F-02G"
            },
            {
                "regex": "FJL21(?:[);/ ]|$)",
                "model": "Arrows EF FJL21"
            },
            {
                "regex": "FJL22(?:[);/ ]|$)",
                "model": "Arrows Z FJL22"
            },
            {
                "regex": "F-02H(?:[);/ ]|$)",
                "model": "Arrows NX F-02H"
            },
            {
                "regex": "F-52A(?:[);/ ]|$)",
                "model": "Arrows NX9 F-52A"
            },
            {
                "regex": "F-03D(?:[);/ ]|$)",
                "model": "Arrows Kiss F-03D"
            },
            {
                "regex": "F-03E(?:[);/ ]|$)",
                "model": "Arrows Kiss F-03E"
            },
            {
                "regex": "F-03F(?:[);/ ]|$)",
                "model": "Disney Mobile F-03F"
            },
            {
                "regex": "F-03H(?:[);/ ]|$)",
                "model": "Arrows SV F-03H"
            },
            {
                "regex": "901FJ(?:[);/ ]|$)",
                "model": "Arrows J 901FJ"
            },
            {
                "regex": "F-04E(?:[);/ ]|$)",
                "model": "Arrows V F-04E"
            },
            {
                "regex": "F-04G(?:[);/ ]|$)",
                "model": "Arrows NX F-04G"
            },
            {
                "regex": "F-05D(?:[);/ ]|$)",
                "model": "Arrows X LTE F-05D"
            },
            {
                "regex": "F-05F(?:[);/ ]|$)",
                "model": "Arrows NX F-05F"
            },
            {
                "regex": "F-05J(?:[);/ ]|$)",
                "model": "Arrows Be F-05J"
            },
            {
                "regex": "F-05G(?:[);/ ]|$)",
                "model": "Arrows K-tai"
            },
            {
                "regex": "F-04K(?:[);/ ]|$)",
                "model": "Arrows Be F-04K"
            },
            {
                "regex": "F-02L(?:[);/ ]|$)",
                "model": "Arrows Be 3 F-02L"
            },
            {
                "regex": "F-41A(?:[);/ ]|$)",
                "model": "Arrows Be 4 F-41A"
            },
            {
                "regex": "F-06E(?:[);/ ]|$)",
                "model": "Arrows NX F-06E"
            },
            {
                "regex": "F-07D(?:[);/ ]|$)",
                "model": "Arrows μ F-07D"
            },
            {
                "regex": "801FJ(?:[);/ ]|$)",
                "model": "Arrows U 801FJ"
            },
            {
                "regex": "F-07E(?:[);/ ]|$)",
                "model": "Disney Mobile F-07E"
            },
            {
                "regex": "F-08D(?:[);/ ]|$)",
                "model": "Disney Mobile F-08D"
            },
            {
                "regex": "F-09D(?:[);/ ]|$)",
                "model": "ANTEPRIMA F-09D"
            },
            {
                "regex": "F-09E(?:[);/ ]|$)",
                "model": "Raku-Raku Smartphone Premium F-09E"
            },
            {
                "regex": "F-10D(?:[);/ ]|$)",
                "model": "Arrows X F-10D"
            },
            {
                "regex": "F-11D(?:[);/ ]|$)",
                "model": "Arrows Me F-11D"
            },
            {
                "regex": "F-12C(?:[);/ ]|$)",
                "model": "F-12C"
            },
            {
                "regex": "(F-04[JF])(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "arrowsM03(?:[);/ ]|$)",
                "model": "Arrows M03"
            },
            {
                "regex": "arrowsRX(?:[);/ ]|$)",
                "model": "Arrows RX"
            },
            {
                "regex": "arrowsM04-PREMIUM(?:[);/ ]|$)",
                "model": "Arrows M04 Premium"
            },
            {
                "regex": "arrowsM(0[45])(?:[);/ ]|$)",
                "model": "Arrows M$1"
            },
            {
                "regex": "(F-0[32]F|F-03G|F-04H|F-05E|F-02K)(?:[);/ ]|$)",
                "model": "Arrows Tab $1",
                "device": "tablet"
            },
            {
                "regex": "FARTM933KZ",
                "model": "Arrows Tab M504/HA4",
                "device": "tablet"
            },
            {
                "regex": "M532(?:[);/ ]|$)",
                "model": "Stylistic",
                "device": "tablet"
            }
        ]
    },
    "G-TiDE": {
        "regex": "G-TiDE",
        "device": "smartphone",
        "models": [
            {
                "regex": "Shining7",
                "model": "Shining 7"
            },
            {
                "regex": "G-TiDE_(A1|S[34])",
                "model": "$1"
            },
            {
                "regex": "G-TiDE V6",
                "model": "V6"
            },
            {
                "regex": "E(57|60|66|72|77)",
                "model": "$1"
            }
        ]
    },
    "Gemini": {
        "regex": "(GEM[0-9]+[a-z]*)",
        "device": "tablet",
        "model": "$1"
    },
    "General Mobile": {
        "regex": "GM 5 Plus|GM8 go|GM [68] d|GM 8|General Mobile 4G|GM 9 (?:Pro|Plus)|e-tab 20(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "GM 5 Plus",
                "model": "GM 5 Plus"
            },
            {
                "regex": "GM ([68]) d",
                "model": "GM $1D"
            },
            {
                "regex": "GM 8",
                "model": "GM 8"
            },
            {
                "regex": "GM8 go",
                "model": "GM 8 Go"
            },
            {
                "regex": "GM 9 Pro",
                "model": "GM 9 Pro"
            },
            {
                "regex": "GM 9 Plus",
                "model": "GM 9 Plus"
            },
            {
                "regex": "General Mobile 4G Dual",
                "model": "4G Dual"
            },
            {
                "regex": "General Mobile 4G",
                "model": "4G"
            },
            {
                "regex": "e-tab 20(?:[);/ ]|$)",
                "device": "tablet",
                "model": "E-Tab 20"
            }
        ]
    },
    "Geotel": {
        "regex": "GEOTEL[ _]?",
        "device": "smartphone",
        "models": [
            {
                "regex": "G1",
                "model": "G1"
            },
            {
                "regex": "GEOTEL[ _]?Note",
                "model": "Note"
            },
            {
                "regex": "GEOTEL[ _]AMIGO",
                "model": "Amigo"
            }
        ]
    },
    "Ghia": {
        "regex": "GHIA|QS702|VECTOR10\\.1",
        "device": "smartphone",
        "models": [
            {
                "regex": "QS702",
                "model": "QS702"
            },
            {
                "regex": "GHIA_ZEUS_3G",
                "model": "Zeus 3G"
            },
            {
                "regex": "GHIA_AXIS7",
                "model": "Axis 7",
                "device": "tablet"
            },
            {
                "regex": "VECTOR10\\.1",
                "model": "Vector 10.1\"",
                "device": "tablet"
            }
        ]
    },
    "Gigabyte": {
        "regex": "GSmart[ _][a-z0-9 ]+(?: Build|\\))|Gigabyte-[a-z0-9]+|Simba SX1|GSmart_Classic_Lite|(?<!Eagle )Classic[_ ](?:Joy|Pro)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(GSmart[ _][a-z0-9 ]+)(?: Build|[);])",
                "model": "$1"
            },
            {
                "regex": "Gigabyte-([a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "(Classic[ _](?:Joy|Pro)|GSmart_Classic_Lite)",
                "model": "$1"
            },
            {
                "regex": "Simba SX1",
                "model": "GSmart Simba SX1"
            }
        ]
    },
    "Gigaset": {
        "regex": "Gigaset|(?: GS80|GS1[0189]0|GS1[89]5|GS2[89]0|GS5[357]-6|GS[23]70(?:_Plus)?)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Gigaset QV(1030|830)",
                "model": "Gigaset QV$1",
                "device": "tablet"
            },
            {
                "regex": "G(S[23]70)[_ ]Plus(?:[);/ ]|$)",
                "model": "G$1 Plus"
            },
            {
                "regex": "GS([23]70|1[0189]0|1[89]5|2[89]0|80)(?:[);/ ]|$)",
                "model": "GS$1"
            },
            {
                "regex": "GS57-6",
                "model": "ME Pro"
            },
            {
                "regex": "GS55-6",
                "model": "ME"
            },
            {
                "regex": "GS53-6",
                "model": "ME Pure"
            },
            {
                "regex": "Gigaset ([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "Gigaset ([^;/)]+)(?:[);/]|$)",
                "model": "$1"
            }
        ]
    },
    "Ginzzu": {
        "regex": "GINZZU[_ ]|((?:RS(?:7[14]|81|9[67])D)|S5002|(?<!Wave Pro )S5021|S5230|S50[45]0|S5120|RS(6[12]|9[45])D|S40[23]0|RS850[12]|RS9602)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "GINZZU[_ ]?(S40[17]0|S4710|ST60[14]0|ST6120)",
                "model": "$1"
            },
            {
                "regex": "GINZZU[_ ]?(GT-(?:1050|7105|70[1-4]0|7115|7210|[WX]831|X[78]70|7810|8[01]10|X853))",
                "model": "$1",
                "device": "tablet"
            },
            {
                "regex": "(RS6[12]D) ULTIMATE",
                "model": "$1 Ultimate"
            },
            {
                "regex": "(?:GINZZU[ _])?((?:RS(?:7[14]|81|9[4-7])D)|S50[45]0|S5002|S5021|S5230|S5120|S40[23]0|RS850[12]|RS9602|RS94D)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Gionee": {
        "regex": "(?:GIO-)?GIONEE[ _-]?[a-z0-9]+|(?:Dream_D1|V188S?|GN[0-9]{3,4}[a-z]?)(?:[);/ ]|$)|F103(?: Pro)?(?: Build|\\))|P5 mini Build|P7 Max Build|(M7 Power|F106L|F100A|F103S|F103 Pro)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "GN810(?:[);/ ]|$)",
                "model": "GN810",
                "device": "phablet"
            },
            {
                "regex": "GN([0-9]{3,4}[a-z]?)(?:[);/ ]|$)",
                "model": "GN$1"
            },
            {
                "regex": "(F106L|F100A|V188S|V188|F103S)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "M7 Power(?:[);/ ]|$)",
                "model": "M7 Power"
            },
            {
                "regex": "P5 mini Build",
                "model": "P5 mini"
            },
            {
                "regex": "P7 Max Build",
                "model": "P7 Max"
            },
            {
                "regex": "(S11) Lite(?:[);/ ]|$)",
                "model": "$1 Lite"
            },
            {
                "regex": "F103( Pro)?( BUILD|\\))",
                "model": "F103$1"
            },
            {
                "regex": "(?:(?:GIO-)?GIONEE[ _-])?Dream_D1(?:[);/ ]|$)",
                "model": "Dream D1"
            },
            {
                "regex": "(?:GIO-)?GIONEE[ _-]([a-z0-9_-]+).*Android",
                "model": "$1"
            },
            {
                "regex": "Android.*(?:GIO-)?GIONEE[ _-]([a-z0-9_-]+)",
                "model": "$1"
            },
            {
                "regex": "(?:GIO-)?GIONEE[ _-]?([a-z0-9]+)",
                "model": "$1",
                "device": "feature phone"
            }
        ]
    },
    "Grape": {
        "regex": "(?:Grape[ _])?GTM-5([^;/]+) Build",
        "device": "smartphone",
        "model": "GTM-5$1"
    },
    "Vizio": {
        "regex": "Vizio|VAP430|VTAB1008",
        "device": "tv",
        "models": [
            {
                "regex": "VAP430",
                "model": "VAP430"
            },
            {
                "regex": "VTAB1008",
                "device": "tablet",
                "model": "VTAB1008"
            }
        ]
    },
    "GOCLEVER": {
        "regex": "GOCLEVER|QUANTUM_[0-9]{3}|QUANTUM [0-9]{1}|QUANTUM 700N|Quantum2 500|QUANTUM_(1010N|1010M|900)|ARIES|INSIGNIA|ORION_|ELIPSO|LIBRA[ _]97",
        "device": "smartphone",
        "models": [
            {
                "regex": "QUANTUM_(1010N|1010M|900)",
                "model": "QUANTUM $1",
                "device": "tablet"
            },
            {
                "regex": "INSIGNIA_(785_PRO|1010M|800M)",
                "model": "INSIGNIA $1",
                "device": "tablet"
            },
            {
                "regex": "(ORION|ARIES|ELIPSO)[_ ](71|101|102)",
                "model": "$1 $2",
                "device": "tablet"
            },
            {
                "regex": "TQ700N",
                "model": "QUANTUM 700N",
                "device": "tablet"
            },
            {
                "regex": "Quantum2 500",
                "model": "Quantum 2 500"
            },
            {
                "regex": "((?:QUANTUM|ARIES|INSIGNIA|ORION|ELIPSO)[ _-]?[^;/]+)(?: Build|\\))",
                "model": "$1"
            },
            {
                "regex": "GOCLEVER[ _-]TAB[ _-]?([^;/]+) Build",
                "model": "TAB $1",
                "device": "tablet"
            },
            {
                "regex": "GOCLEVER[ _-]?([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "TAB ([^;/)]+) Build/GOCLEVER",
                "model": "TAB $1",
                "device": "tablet"
            },
            {
                "regex": "([^;/)]+) Build/GOCLEVER",
                "model": "$1"
            },
            {
                "regex": "GOCLEVER[ _-]?TAB[ _-]([^;/)]+)(?:[;/)]|$)",
                "model": "TAB $1",
                "device": "tablet"
            },
            {
                "regex": "GOCLEVER[ _-]?([^;/)]+)(?:[;/)]|$)",
                "model": "$1"
            },
            {
                "regex": "LIBRA[ _]97",
                "model": "Libra 97",
                "device": "tablet"
            }
        ]
    },
    "GoMobile": {
        "regex": "GO Onyx|GO[0-9]{3,4}",
        "device": "smartphone",
        "models": [
            {
                "regex": "GO Onyx",
                "model": "Go Onyx"
            },
            {
                "regex": "GO([0-9]{3,4})",
                "model": "Go$1"
            }
        ]
    },
    "Gol Mobile": {
        "regex": "F10_PRIME",
        "device": "smartphone",
        "models": [
            {
                "regex": "F10_PRIME",
                "model": "F10 Prime"
            }
        ]
    },
    "Goly": {
        "regex": "Goly[ _-]",
        "device": "smartphone",
        "models": [
            {
                "regex": "Goly[ _-]([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "Goly[ _-]([^;/)]+)(?:[;/)]|$)",
                "model": "$1"
            }
        ]
    },
    "Google": {
        "regex": "Nexus|GoogleTV|Glass(?<!Browser)|CrKey[^a-z0-9]|Pixel(?: Build|\\))|Pixel (?:XL|C|[2-5]|[2-4] XL)|Google 2XL|G011A",
        "device": "smartphone",
        "models": [
            {
                "regex": "Glass",
                "model": "Glass"
            },
            {
                "regex": "Galaxy Nexus",
                "model": "Galaxy Nexus"
            },
            {
                "regex": "(Nexus (?:S|4|5X?|One))",
                "model": "$1"
            },
            {
                "regex": "(Nexus (?:6P?))",
                "device": "phablet",
                "model": "$1"
            },
            {
                "regex": "(Nexus (?:7|9|10))",
                "device": "tablet",
                "model": "$1"
            },
            {
                "regex": "CrKey[^a-z0-9]",
                "model": "Chromecast",
                "device": "tv"
            },
            {
                "regex": "GoogleTV",
                "device": "tv",
                "model": "GoogleTV"
            },
            {
                "regex": "Pixel(?: Build|\\))",
                "model": "Pixel"
            },
            {
                "regex": "Pixel XL",
                "model": "Pixel XL"
            },
            {
                "regex": "Pixel 3 XL",
                "model": "Pixel 3 XL"
            },
            {
                "regex": "Pixel 2 XL",
                "model": "Pixel 2 XL"
            },
            {
                "regex": "Pixel 3a XL",
                "model": "Pixel 3a XL"
            },
            {
                "regex": "Pixel 3a",
                "model": "Pixel 3a"
            },
            {
                "regex": "Pixel 4 XL",
                "model": "Pixel 4 XL"
            },
            {
                "regex": "Pixel 4a \\(5G\\)",
                "model": "Pixel 4a (5G)"
            },
            {
                "regex": "pixel 4a",
                "model": "Pixel 4a"
            },
            {
                "regex": "G011A",
                "model": "Pixel 2"
            },
            {
                "regex": "Google 2XL",
                "model": "Pixel 2 XL"
            },
            {
                "regex": "pixel ([2-5])",
                "model": "Pixel $1"
            },
            {
                "regex": "Pixel C",
                "device": "tablet",
                "model": "Pixel C"
            }
        ]
    },
    "Gradiente": {
        "regex": "GRADIENTE",
        "device": "feature phone",
        "models": [
            {
                "regex": "GRADIENTE-([a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "GRADIENTE ([a-z0-9\\-]+)",
                "model": "$1"
            }
        ]
    },
    "Grundig": {
        "regex": "GR?-TB[0-9]+[a-z]*|GRUNDIG|portalmmm/2\\.0 G|AFTEU(?:011|014|FF014)",
        "device": "tv",
        "models": [
            {
                "regex": "(GR?-TB[0-9]+[a-z]*)",
                "device": "tablet",
                "model": "$1"
            },
            {
                "regex": "GRUNDIG ([a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "portalmmm/2\\.0 G([a-z0-9]+)",
                "model": "G$1"
            },
            {
                "regex": "AFTEU014",
                "model": "Vision 7 4K (2019)"
            },
            {
                "regex": "AFTEUFF014",
                "model": "OLED 4K (2019)"
            },
            {
                "regex": "AFTEU011",
                "model": "Vision 6 HD (2019)"
            }
        ]
    },
    "Hafury": {
        "regex": "HAFURY",
        "device": "smartphone",
        "models": [
            {
                "regex": "(MIX|UMAX)",
                "model": "$1"
            }
        ]
    },
    "Haier": {
        "regex": "Haier|I6_Infinity|Titan[ _]T[135]|(?:HW-)?W(?:71[67]|757|860|970)(?:[);/ ]|$)|S5 Silk|(?:HM-)?(N505|N700|G(?:152|303|353|552|70[01])?-FL|HM-N501-FL|G(?:303)?-W|I(?:557)?-FL)|(?:PAD[ _](?:D71|G781|d85))|A2_Lite_NFC",
        "device": "smartphone",
        "models": [
            {
                "regex": "A2_Lite_NFC",
                "model": "Alpha A2 Lite NFC"
            },
            {
                "regex": "HM-N505-FL",
                "model": "I8"
            },
            {
                "regex": "S5 Silk",
                "model": "S5 Silk"
            },
            {
                "regex": "HM-N700-FL",
                "model": "L7"
            },
            {
                "regex": "HM-N501-FL",
                "model": "L56"
            },
            {
                "regex": "(?:HM-)?(G(?:152|353|552|70[01])|G(?:303)|I(?:557))",
                "model": "$1"
            },
            {
                "regex": "(Titan[_ ]T[135]|I6_Infinity)",
                "model": "$1"
            },
            {
                "regex": "(?:HW-)?W(71[67]|757|860|970)(?:[);/ ]|$)",
                "model": "W$1"
            },
            {
                "regex": "PAD[ -_](D71|G781|d85)",
                "device": "tablet",
                "model": "$1"
            },
            {
                "regex": "Haier[ _\\-](H[WT]-[^/;]+) Build",
                "model": "$1"
            },
            {
                "regex": "Haier[ _\\-](H[WT]-[a-z0-9_\\-]+)",
                "model": "$1"
            },
            {
                "regex": "Haier[ _\\-](sy[0-9]+)",
                "model": "$1"
            },
            {
                "regex": "Haier[ _\\-]([a-z0-9\\-]+)",
                "model": "$1",
                "device": "feature phone"
            }
        ]
    },
    "HannSpree": {
        "regex": "(HSG[0-9]{4})|SN97T41W|SN1AT71W\\(B\\)|SN70T51A|SN70T31?|T7-QC",
        "device": "tablet",
        "models": [
            {
                "regex": "(HSG[0-9]{4})",
                "model": "$1"
            },
            {
                "regex": "SN1AT71W\\(B\\)",
                "model": "SN1AT71W(B)"
            },
            {
                "regex": "SN70T31",
                "model": "SN70T31"
            },
            {
                "regex": "SN70T3",
                "model": "SN70T3"
            },
            {
                "regex": "SN70T51A",
                "model": "SN70T51A"
            },
            {
                "regex": "SN97T41W",
                "model": "SN97T41W"
            },
            {
                "regex": "T7-QC",
                "model": "SNAT71BUE"
            }
        ]
    },
    "Hasee": {
        "regex": "Hasee",
        "device": "smartphone",
        "models": [
            {
                "regex": "Hasee ([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "Hasee ([^;/)]+)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Hisense": {
        "regex": "(?:HS-)?Hisense ([^;/]+) Build|Hisense [^);/]+|HISENSE; |HS-(?:G|U|EG?|I|L|T|X)[0-9]+[a-z0-9\\-]*|E270BSA|HWCD100E|HITV300C|M470BS[AE]|EG98|E2281|EG680|I46D1G|HAT4KDTV|HLTE([237]00T|200M|203T|202N|21[023567]T|22[67]T|22[13]E|230E|310[TM]|31[16]T|500T|501N|510[TM]|7[23]0T)",
        "device": "smartphone",
        "models": [
            {
                "regex": "E270BSA",
                "device": "tablet",
                "model": "Sero 7"
            },
            {
                "regex": "M470BS[AE]",
                "device": "tablet",
                "model": "Sero 7 Pro"
            },
            {
                "regex": "E2281",
                "device": "tablet",
                "model": "Sero 8"
            },
            {
                "regex": "HLTE202N",
                "model": "A5"
            },
            {
                "regex": "HLTE203T",
                "model": "A5 Pro CC"
            },
            {
                "regex": "HLTE700T",
                "model": "A6"
            },
            {
                "regex": "HLTE730T",
                "model": "A6L"
            },
            {
                "regex": "HLTE221E",
                "model": "E Max"
            },
            {
                "regex": "HLTE501N",
                "model": "V Plus"
            },
            {
                "regex": "HLTE223E",
                "model": "H30"
            },
            {
                "regex": "I46D1G",
                "model": "Smartfren Andromax R"
            },
            {
                "regex": "EG680",
                "model": "Smartfren Andromax Z"
            },
            {
                "regex": "EG98",
                "model": "Smartfren Andromax U2"
            },
            {
                "regex": "HLTE200T(?:[);/ ]|$)",
                "model": "F26"
            },
            {
                "regex": "HLTE210T(?:[);/ ]|$)",
                "model": "F28"
            },
            {
                "regex": "HLTE215T(?:[);/ ]|$)",
                "model": "F29"
            },
            {
                "regex": "HLTE213T(?:[);/ ]|$)",
                "model": "King Kong 4"
            },
            {
                "regex": "HLTE311T(?:[);/ ]|$)",
                "model": "King Kong 4 Pro"
            },
            {
                "regex": "HLTE216T(?:[);/ ]|$)",
                "model": "King Kong 5"
            },
            {
                "regex": "HLTE316T(?:[);/ ]|$)",
                "model": "King Kong 5 Pro"
            },
            {
                "regex": "HLTE226T(?:[);/ ]|$)",
                "model": "King Kong 6"
            },
            {
                "regex": "HLTE217T(?:[);/ ]|$)",
                "model": "F30S"
            },
            {
                "regex": "HLTE227T(?:[);/ ]|$)",
                "model": "F40"
            },
            {
                "regex": "HLTE310[TM](?:[);/ ]|$)",
                "model": "H18"
            },
            {
                "regex": "HLTE510[TM](?:[);/ ]|$)",
                "model": "H20"
            },
            {
                "regex": "HLTE500T(?:[);/ ]|$)",
                "model": "H11"
            },
            {
                "regex": "HLTE230E(?:[);/ ]|$)",
                "model": "H40 Lite"
            },
            {
                "regex": "HLTE720T(?:[);/ ]|$)",
                "model": "U30",
                "device": "phablet"
            },
            {
                "regex": "HWCD100E(?:[);/ ]|$)",
                "model": "U40 Lite"
            },
            {
                "regex": "(HLTE300T|HLTE200M|HLTE212T)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "HITV300C(?:[);/ ]|$)",
                "model": "E9"
            },
            {
                "regex": "E260T\\+(?:[);/ ]|$)",
                "model": "Nana"
            },
            {
                "regex": "(HS-(?:G|U|EG?|I|L|T|X)[0-9]+[a-z0-9\\-]*)",
                "model": "$1"
            },
            {
                "regex": "(HAT4KDTV)(?:[);/ ]|$)",
                "device": "tv",
                "model": "$1"
            },
            {
                "regex": "(?:HS-)?Hisense ([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "Hisense ([^);/]+)",
                "model": "$1"
            }
        ]
    },
    "Hi-Level": {
        "regex": "HLV-T([a-z0-9]+)",
        "device": "tablet",
        "model": "HLV-T$1"
    },
    "Hoffmann": {
        "regex": "(HOFFMANN|Neo[_ ]A[1379]00)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Neo[_ ](A[1379]00)",
                "model": "Neo $1"
            },
            {
                "regex": "HOFFMANN[ _]X[-_ ](Prime|Twist|Max)",
                "model": "X $1"
            }
        ]
    },
    "Homtom": {
        "regex": "Homtom|(?:HT[0-9]{1,2})(?: ?Pro)?(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(?:Homtom[_ ])?HT ?([0-9]{1,2}(?: ?Pro)?)(?:[);/ ]|$)",
                "model": "HT$1"
            },
            {
                "regex": "Homtom ([^;/]+) Build",
                "model": "$1"
            }
        ]
    },
    "Hosin": {
        "regex": "HOSIN[ _-]",
        "device": "smartphone",
        "models": [
            {
                "regex": "HOSIN[ _-]([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "HOSIN[ _-]([^;/]+)(?:[;/)]|$)",
                "model": "$1"
            }
        ]
    },
    "Hoozo": {
        "regex": "(?:HOOZO[_ ](MT232|MT116|MT Pad 116 LTE|X1001)|HZ0010)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "HOOZO[_ ](MT232|MT116|MT Pad 116 LTE|X1001)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "(HZ0010)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Minix": {
        "regex": "NEO-(?:X8-H|U9-H|X[57]-mini|X8H-PLUS|X88-?I|X7-216A|X5-116A|X[68]|U1|Z64)(?:[);/ ]|$)",
        "device": "tv",
        "models": [
            {
                "regex": "NEO-X8-H(?:[);/ ]|$)",
                "model": "NEO X8H"
            },
            {
                "regex": "NEO-X([57])-mini(?:[);/ ]|$)",
                "model": "NEO X$1 Mini"
            },
            {
                "regex": "NEO-X8H-PLUS(?:[);/ ]|$)",
                "model": "NEO X8H Plus"
            },
            {
                "regex": "NEO-X88-?I(?:[);/ ]|$)",
                "model": "NEO X88i"
            },
            {
                "regex": "NEO-X7-216A(?:[);/ ]|$)",
                "model": "NEO X7"
            },
            {
                "regex": "NEO-X5-116A(?:[);/ ]|$)",
                "model": "NEO X5"
            },
            {
                "regex": "NEO-X([68])(?:[);/ ]|$)",
                "model": "NEO X$1"
            },
            {
                "regex": "NEO-U1(?:[);/ ]|$)",
                "model": "NEO U1"
            },
            {
                "regex": "NEO-Z64(?:[);/ ]|$)",
                "model": "NEO Z64"
            },
            {
                "regex": "NEO-U9-H(?:[);/ ]|$)",
                "model": "NEO U9H"
            }
        ]
    },
    "Huawei": {
        "regex": "(HW-)?(?:Huawei|MediaPad T1|Ideos|Honor[ _]?|(?:(?:AGS|AGS2|ALE|ALP|AMN|ANA|ANE|ARE|ARS|ASK|ATH|ATU|AUM|BAC|BAH[23]?|BG2|BGO|B[ZK]K|BKL|BL[ALN]|BND|BTV|CA[GMNZ]|CH[CM]|CHE[12]?|CLT|CMR|COL|COR|CPN|CRO|CRR|CUN|DIG|DLI|DRA|DUA|DUB|DUK|EDI|ELE|EML|EVA|EVR|FDR|FIG|FLA|FRD|FRL|GEM|GRA|HDN|HLK|HMA|Hol|HRY|HWI|H[36]0|INE|JAT|JEF|JDN|JDN2|JKM|JMM|JSN|KII|KIW|KNT|KOB|KSA|LDN|LEO|LIO|LLD|LND|LON|LRA|LUA|LY[AO]|MAR|MHA|MRD|MYA|NCE|NEM|NEO|NXT|PAR|PCT|PIC|PLE|PLK|POT|PPA|PRA|RIO|RNE|RVL|SCC|SCL|SCM|SEA|SHT|SLA|SNE|SPN|STF|STK|TAG|TIT|TNY|TRT|VCE|VEN|VIE|VKY|VNS|VOG|VRD|VTR|WAS|YAL|G(?:527|620S|621|630|735)|Y(?:221|330|550|6[23]5))-(?:[A-Z]{0,2}[0-9]{1,4}[A-Z]{0,3}?)|H1711|U(?:8230|8500|8661|8665|8667|8800|8818|8860|9200|9508))(?:[);/ ]|$))|hi6210sft|PE-(UL00|TL[12]0|TL00M)|T1-(A21[Lw]|A23L|701u|823L)|G7-(?:L01|TL00)|HW-01K|JNY-(LX[12]|AL10)|OXF-AN[01]0|TAS-(A[LN]00|L29|TL00)|WLZ-(AL10|AN00)|NIC-LX1A|MRX-(AL09|W09)|CDY-(?:[AT]N00|AN90|NX9A)|GLK-(?:[AT]L00|LX1U)|JER-[AT]N10|ELS-(?:[AT]N[10]0|NX9|N39|N04)|AKA-(AL10|L29)|MON-(W|AL)19|BMH-AN[12]0|AQM-([AT]L[01]0|LX1)|MOA-(AL[02]0|LX9N)|NTS-AL00|ART-(?:[AT]L00[xm]|L29N?|L28)|MED-(?:[AT]L00|LX9N?)|EBG-(?:AN[01]0|TN00)|ANA-(?:[AT]N00|NX9)|BZ[AK]-W00|BZT-(W09|AL[01]0)|HDL-(AL09|W09)|HWV3[123]|HW-02L|TEL-[AT]N(?:00a?|10)|KKG-AN00|MXW-AN00|JKM-AL00[ab]|TAH-(?:N29|AN00)m|C8817D|T1-821W|d-01[JK]|d-02[HK]|KRJ-W09|HWT31|Y320-U10|Y541-U02|VAT-L19|70[14]HW|60[58]HW|NOH-(?:NX9|AN00)|TNNH-AN00|LIO-(?:[TA]L00|[LN]29|AN00)|KOB2-[LW]09|AGS3-[WL]09|DNN-LX9|NEY-NX9|LON-AL00|HLK-L41|503HW|AGR-[LW]09|PPA-LX[12]|ANG-AN00|NOP-AN00",
        "device": "smartphone",
        "models": [
            {
                "regex": "POT-[TA]L00a(?:[);/ ]|$)",
                "model": "9S"
            },
            {
                "regex": "U9500(?:[);/ ]|$)",
                "model": "Ascend D1"
            },
            {
                "regex": "Z100-UL00(?:[);/ ]|$)",
                "model": "Ascend D3"
            },
            {
                "regex": "Y320-(?:U10|T00|U05)(?:[);/ ]|$)",
                "model": "Ascend Y320"
            },
            {
                "regex": "Y330-U05(?:[);/ ]|$)",
                "model": "Ascend Y330"
            },
            {
                "regex": "Y541-U02(?:[);/ ]|$)",
                "model": "Ascend Y5C"
            },
            {
                "regex": "U8818(?:[);/ ]|$)",
                "model": "Ascend G300"
            },
            {
                "regex": "G527-U081(?:[);/ ]|$)",
                "model": "Ascend G527"
            },
            {
                "regex": "(G620S-L0[13]|C8817D)(?:[);/ ]|$)",
                "model": "Ascend G620S"
            },
            {
                "regex": "G630-U251(?:[);/ ]|$)",
                "model": "Ascend G630"
            },
            {
                "regex": "G7-(?:L01|TL00)(?:[);/ ]|$)",
                "model": "Ascend G7"
            },
            {
                "regex": "P7-L10(?:[);/ ]|$)",
                "model": "Ascend P7"
            },
            {
                "regex": "G730-C00(?:[);/ ]|$)",
                "model": "Ascend G730"
            },
            {
                "regex": "U9200(?:[);/ ]|$)",
                "model": "Ascend P1"
            },
            {
                "regex": "H1711(?:[);/ ]|$)",
                "model": "Ascend XT2"
            },
            {
                "regex": "Y221-U(?:[12]2|[0345]3)(?:[);/ ]|$)",
                "model": "Ascend Y221"
            },
            {
                "regex": "Y550-L03(?:[);/ ]|$)",
                "model": "Ascend Y550"
            },
            {
                "regex": "ART-[AT]L00[xm](?:[);/ ]|$)",
                "model": "Enjoy 10"
            },
            {
                "regex": "STK-[AT]L00(?:[);/ ]|$)",
                "model": "Enjoy 10 Plus"
            },
            {
                "regex": "MED-[AT]L00(?:[);/ ]|$)",
                "model": "Enjoy 10E"
            },
            {
                "regex": "AQM-[AT]L00(?:[);/ ]|$)",
                "model": "Enjoy 10S"
            },
            {
                "regex": "TAG-[ACT]L00(?:[);/ ]|$)",
                "model": "Enjoy 5S"
            },
            {
                "regex": "NCE-(AL[10]0|TL10)(?:[);/ ]|$)",
                "model": "Enjoy 6"
            },
            {
                "regex": "DIG-(?:TL10|AL00)(?:[);/ ]|$)",
                "model": "Enjoy 6S"
            },
            {
                "regex": "SLA-(?:AL00|TL10)(?:[);/ ]|$)",
                "model": "Enjoy 7"
            },
            {
                "regex": "FIG-(?:[AT]L[10]0)(?:[);/ ]|$)",
                "model": "Enjoy 7S"
            },
            {
                "regex": "(?:TRT-AL00[A]?|TRT-TL10A)(?:[);/ ]|$)",
                "model": "Enjoy 7 Plus"
            },
            {
                "regex": "LDN-(?:AL[12]0|TL[02]0)(?:[);/ ]|$)",
                "model": "Enjoy 8"
            },
            {
                "regex": "FLA-AL10(?:[);/ ]|$)",
                "model": "Enjoy 8 Plus"
            },
            {
                "regex": "ATU-[AT]L10(?:[);/ ]|$)",
                "model": "Enjoy 8e"
            },
            {
                "regex": "MRD-[TA]L00(?:[);/ ]|$)",
                "model": "Enjoy 9e"
            },
            {
                "regex": "DVC-TN20(?:[);/ ]|$)",
                "model": "Enjoy 20 Pro"
            },
            {
                "regex": "U8665(?:[);/ ]|$)",
                "model": "Fusion 2"
            },
            {
                "regex": "MED-LX9N?(?:[);/ ]|$)",
                "model": "Y6p"
            },
            {
                "regex": "G735-L(?:03|12|23)(?:[);/ ]|$)",
                "model": "G Play"
            },
            {
                "regex": "CHC-U(?:0[13]|23)(?:[);/ ]|$)",
                "model": "G Play Mini"
            },
            {
                "regex": "(?:Honor_|HW-)?G621-TL00M?(?:[);/ ]|$)",
                "model": "G621"
            },
            {
                "regex": "DIG-L2[12](?:[);/ ]|$)",
                "model": "GR3 (2017)"
            },
            {
                "regex": "KII-L21(?:[);/ ]|$)",
                "model": "GR5"
            },
            {
                "regex": "BLL-L2[12](?:[);/ ]|$)",
                "model": "GR5 (2017)"
            },
            {
                "regex": "RIO-L03(?:[);/ ]|$)",
                "model": "GX8"
            },
            {
                "regex": "U8860(?:[);/ ]|$)",
                "model": "Honor"
            },
            {
                "regex": "COL-(?:AL[01]0|TL[01]0|L29)(?:[);/ ]|$)",
                "model": "Honor 10"
            },
            {
                "regex": "HRY-(?:[AT]L00[A]?|LX[12]|LX1MEB)(?:[);/ ]|$)",
                "model": "Honor 10 Lite"
            },
            {
                "regex": "HRY-LX1T(?:[);/ ]|$)",
                "model": "Honor 10I"
            },
            {
                "regex": "U9508(?:[);/ ]|$)",
                "model": "Honor 2"
            },
            {
                "regex": "YAL-(?:L21|[AT]L00)(?:[);/ ]|$)",
                "model": "Honor 20"
            },
            {
                "regex": "LRA-AL00(?:[);/ ]|$)",
                "model": "Honor 20 Lite"
            },
            {
                "regex": "(HRY-AL00T[a]?)(?:[);/ ]|$)",
                "model": "Honor 20I"
            },
            {
                "regex": "YAL-(L41|AL10)(?:[);/ ]|$)",
                "model": "Honor 20 Pro"
            },
            {
                "regex": "(MAR-LX1H|YAL-AL50)(?:[);/ ]|$)",
                "model": "Honor 20S"
            },
            {
                "regex": "(?:BMH-AN[12]0|MXW-AN00)(?:[);/ ]|$)",
                "model": "Honor 30"
            },
            {
                "regex": "EBG-[AT]N00(?:[);/ ]|$)",
                "model": "Honor 30 Pro"
            },
            {
                "regex": "EBG-AN10(?:[);/ ]|$)",
                "model": "Honor 30 Pro Plus"
            },
            {
                "regex": "CDY-(?:AN90|NX9A)(?:[);/ ]|$)",
                "model": "Honor 30S"
            },
            {
                "regex": "LRA-LX1(?:[);/ ]|$)",
                "model": "Honor 30I"
            },
            {
                "regex": "(?:Honor_|HW-)?H30-(?:C00|L01M?|L02|U10|T00|T10)(?:_TD)?(?:[);/ ]|$)",
                "model": "Honor 3C"
            },
            {
                "regex": "Hol-U19(?:[);/ ]|$)",
                "model": "Honor 3C Lite"
            },
            {
                "regex": "G750-T01(?:[);/ ]|$)",
                "model": "Honor 3X"
            },
            {
                "regex": "(?:Honor_|HW-)?SCL-(?:AL00|CL00|TL00H?)(?:_TD)?(?:[);/ ]|$)",
                "model": "Honor 4A"
            },
            {
                "regex": "(?:Honor_|HW-)?CHM-U01(?:_TD)?(?:[);/ ]|$)",
                "model": "Honor 4C"
            },
            {
                "regex": "Honor 4c Pro(?:[);/ ]|$)",
                "model": "Honor 4C Pro"
            },
            {
                "regex": "AQM-AL10(?:[);/ ]|$)",
                "model": "Honor 4T Pro"
            },
            {
                "regex": "(CHE2?-[UT]L00[HM]?|CHE1-CL[12]0|CHE2-L(?:1[12]|23)|Che1-L04|CHE-TL00)(?:[);/ ]|$)",
                "model": "Honor 4X"
            },
            {
                "regex": "(?:Honor_|HW-)?CUN-[AT]L00(?:[);/ ]|$)",
                "model": "Honor 5 Play"
            },
            {
                "regex": "(?:Honor_|HW-)?CAM-(?:AL00|TL00H|TL00)(?:_TD)?(?:[);/ ]|$)",
                "model": "Honor 5A"
            },
            {
                "regex": "(?:Honor_|HW-)?NEM-(?:AL10|L51|UL10|TL00)(?:[);/ ]|$)",
                "model": "Honor 5C"
            },
            {
                "regex": "(?:Honor_|HW-)?NEM-(?:L22|TL00H)(?:[);/ ]|$)",
                "model": "Honor 5C Dual SIM"
            },
            {
                "regex": "(?:Honor_|HW-)?KIW-(?:AL10|TL00H|[TC]L00|L2[1-4]|UL00)(?:_TD)?(?:[);/ ]|$)",
                "model": "Honor 5X"
            },
            {
                "regex": "((?:Honor_|HW-)?H60-L(?:01|02|03|04|11|12)(?:_TD)?|HW-H60-J1)(?:[);/ ]|$)",
                "model": "Honor 6"
            },
            {
                "regex": "MYA-TL10(?:[);/ ]|$)",
                "model": "Honor 6 Play"
            },
            {
                "regex": "PE-(UL00|TL[12]0|TL00M)(?:[);/ ]|$)",
                "model": "Honor 6 Plus"
            },
            {
                "regex": "(?:HONOR[ _]?)?DLI-(?:AL10|L[24]2|TL20)(?:[);/ ]|$)",
                "model": "Honor 6A"
            },
            {
                "regex": "DIG-L21HN(?:[);/ ]|$)",
                "model": "Honor 6C"
            },
            {
                "regex": "JMM-L22(?:[);/ ]|$)",
                "model": "Honor 6C Pro"
            },
            {
                "regex": "(?:HONOR[ _]?)?BLN-(?:L2[124]|AL[1234]0|TL[01]0(?:[);/ ]|$))",
                "model": "Honor 6X"
            },
            {
                "regex": "(?:Honor[_]?|HW-)?PLK-(?:AL10|CL00|TL00|TL01H?|UL00|L01)(?:[);/ ]|$)",
                "model": "Honor 7"
            },
            {
                "regex": "(?:Honor_|HW-)?NEM-L21(?:[);/ ]|$)",
                "model": "Honor 7 Lite"
            },
            {
                "regex": "AUM-(?:AL[20]0|L33[A]?|TL20)(?:[);/ ]|$)",
                "model": "Honor 7A"
            },
            {
                "regex": "AUM-L29(?:[);/ ]|$)",
                "model": "Honor 7A Pro"
            },
            {
                "regex": "(?:Honor_|HW-)?(?:LND-(?:AL[34]0|L29|TL40)|AUM-L41)(?:[);/ ]|$)",
                "model": "Honor 7C"
            },
            {
                "regex": "ATH-(?:[CAU]L00|TL00H)(?:[);/ ]|$)",
                "model": "Honor 7i"
            },
            {
                "regex": "(?:Honor_|HW-)?DUA-(?:L22|LX3)(?:[);/ ]|$)",
                "model": "Honor 7S"
            },
            {
                "regex": "(?:HONOR[ _]?)?BND-(?:AL[01]0|TL10|L2[14]|L31)(?:[);/ ]|$)",
                "model": "Honor 7X"
            },
            {
                "regex": "(?:FRD-(?:AL[01]0|L0[249]|L1[49]|DL00)|VAT-L19)(?:[);/ ]|$)",
                "model": "Honor 8"
            },
            {
                "regex": "PRA-(?:AL00X|AL00|TL10)(?:[);/ ]|$)",
                "model": "Honor 8 Lite"
            },
            {
                "regex": "DUK-(?:L09|TL30)(?:[);/ ]|$)",
                "model": "Honor 8 Pro"
            },
            {
                "regex": "VEN-L22(?:[);/ ]|$)",
                "model": "Honor 8 Smart"
            },
            {
                "regex": "JAT-L(29|X[13])(?:[);/ ]|$)",
                "model": "Honor 8A"
            },
            {
                "regex": "JAT-L41(?:[);/ ]|$)",
                "model": "Honor 8A Pro"
            },
            {
                "regex": "BKK-(?:AL[10]0|L21|L22|LX2|TL00)(?:[);/ ]|$)",
                "model": "Honor 8C"
            },
            {
                "regex": "KSA-(LX[239]|AL00)(?:[);/ ]|$)",
                "model": "Honor 8S"
            },
            {
                "regex": "JSN-L(?:2[123]|42)|JSN-[TA]L00[a]?(?:[);/ ]|$)",
                "model": "Honor 8X"
            },
            {
                "regex": "ARE-(?:AL[10]0|L22(?:HN)?|TL00)(?:[);/ ]|$)",
                "model": "Honor 8X Max"
            },
            {
                "regex": "STF-(?:AL[10]0|L09S?|TL10)(?:[);/ ]|$)",
                "model": "Honor 9"
            },
            {
                "regex": "MOA-LX9N(?:[);/ ]|$)",
                "model": "Honor 9A"
            },
            {
                "regex": "AKA-L29(?:[);/ ]|$)",
                "model": "Honor 9C"
            },
            {
                "regex": "LLD-(?:AL[01]0|L[23]1|TL10)(?:[);/ ]|$)",
                "model": "Honor 9 Lite"
            },
            {
                "regex": "LLD-AL[23]0(?:[);/ ]|$)",
                "model": "Honor 9i"
            },
            {
                "regex": "DUA-LX9(?:[);/ ]|$)",
                "model": "Honor 9S"
            },
            {
                "regex": "HLK-AL00a?(?:[);/ ]|$)",
                "model": "Honor 9X"
            },
            {
                "regex": "HLK-(?:AL10|L4[12])(?:[);/ ]|$)",
                "model": "Honor 9X Pro"
            },
            {
                "regex": "CAM-UL00(?:[);/ ]|$)",
                "model": "Honor Holly 3"
            },
            {
                "regex": "NTS-AL00(?:[);/ ]|$)",
                "model": "Honor Magic"
            },
            {
                "regex": "TNY-[AT]L00(?:[);/ ]|$)",
                "model": "Honor Magic 2"
            },
            {
                "regex": "RVL-AL09(?:[);/ ]|$)",
                "model": "Honor Note 10"
            },
            {
                "regex": "EDI-AL10(?:[);/ ]|$)",
                "model": "Honor Note 8"
            },
            {
                "regex": "VKY-TL00(?:[);/ ]|$)",
                "model": "Honor P10 Plus"
            },
            {
                "regex": "VOG-AL00(?:[);/ ]|$)",
                "model": "Honor P30 Pro"
            },
            {
                "regex": "ANA-(?:[AT]N00|NX9)(?:[);/ ]|$)",
                "model": "Honor P40"
            },
            {
                "regex": "COR-(?:AL[01]0|L29|TL10)(?:[);/ ]|$)",
                "model": "Honor Play"
            },
            {
                "regex": "ASK-AL00x(?:[);/ ]|$)",
                "model": "Honor Play 3"
            },
            {
                "regex": "KSA-AL10(?:[);/ ]|$)",
                "model": "Honor Play 3E"
            },
            {
                "regex": "TNNH-AN00(?:[);/ ]|$)",
                "model": "Honor Play 4"
            },
            {
                "regex": "CHM-[CUT]L00[HM]?(?:[);/ ]|$)",
                "model": "Honor Play 4C"
            },
            {
                "regex": "AKA-AL10(?:[);/ ]|$)",
                "model": "Honor Play 4T"
            },
            {
                "regex": "DUA-[TA]L00(?:[);/ ]|$)",
                "model": "Honor Play 7"
            },
            {
                "regex": "JAT-[AT]L00(?:[);/ ]|$)",
                "model": "Honor Play 8A"
            },
            {
                "regex": "MOA-AL[02]0(?:[);/ ]|$)",
                "model": "Honor Play 9A"
            },
            {
                "regex": "BKL-(?:AL00|AL20|AL30|L09|TL10)(?:[);/ ]|$)",
                "model": "Honor V10"
            },
            {
                "regex": "PCT-[TA]L10(?:[);/ ]|$)",
                "model": "Honor V20"
            },
            {
                "regex": "KNT-(?:AL[12]0|[TU]L10)(?:[);/ ]|$)",
                "model": "Honor V8"
            },
            {
                "regex": "DUK-AL20(?:[);/ ]|$)",
                "model": "Honor V9"
            },
            {
                "regex": "JMM-[AT]L[01]0(?:[);/ ]|$)",
                "model": "Honor V9 Play"
            },
            {
                "regex": "BKL-L04(?:[);/ ]|$)",
                "model": "Honor View 10"
            },
            {
                "regex": "PCT-L29(?:[);/ ]|$)",
                "model": "Honor View 20"
            },
            {
                "regex": "OXF-AN00(?:[);/ ]|$)",
                "model": "Honor View 30"
            },
            {
                "regex": "OXF-AN10(?:[);/ ]|$)",
                "model": "Honor View 30 Pro"
            },
            {
                "regex": "(?:Honor_|HW-)?SCL-L(01|32)(?:_TD)?(?:[);/ ]|$)",
                "model": "Honor Y6"
            },
            {
                "regex": "(?:Honor_|HW-)?LYO-L21(?:[);/ ]|$)",
                "model": "Honor Y6 II Compact"
            },
            {
                "regex": "TEL-(?:AN(?:00a?|10)|TN00)(?:[);/ ]|$)",
                "model": "Honor X10"
            },
            {
                "regex": "KKG-AN00(?:[);/ ]|$)",
                "model": "Honor X10 Max"
            },
            {
                "regex": "DNN-LX9(?:[);/ ]|$)",
                "model": "Honor X10 Lite"
            },
            {
                "regex": "U8500(?:[);/ ]|$)",
                "model": "Ideos X2"
            },
            {
                "regex": "U8510(?:[);/ ]|$)",
                "model": "Ideos X3"
            },
            {
                "regex": "U8800(?:[);/ ]|$)",
                "model": "Ideos X5"
            },
            {
                "regex": "MLA-AL10(?:[);/ ]|$)",
                "model": "Maimang 5"
            },
            {
                "regex": "POT-AL10(?:[);/ ]|$)",
                "model": "Maimang 8"
            },
            {
                "regex": "TAH-(?:N29|AN00)m(?:[);/ ]|$)",
                "model": "Mate XS"
            },
            {
                "regex": "ALP-(?:[TA]L00|L[02]9)(?:[);/ ]|$)",
                "model": "Mate 10"
            },
            {
                "regex": "RNE-(?:AL00|L(?:01|03|21|23))(?:[);/ ]|$)",
                "model": "Mate 10 Lite"
            },
            {
                "regex": "BLA-(?:A09|L[02]9|[TA]L00)(?:[);/ ]|$)",
                "model": "Mate 10 Pro"
            },
            {
                "regex": "HMA-(?:[AT]L00|L[02]9)(?:[);/ ]|$)",
                "model": "Mate 20"
            },
            {
                "regex": "SNE-(?:AL00|LX[0-3])(?:[);/ ]|$)",
                "model": "Mate 20 Lite"
            },
            {
                "regex": "LYA-(?:AL[01]0|L[02]9|L0C|TL00)(?:[);/ ]|$)",
                "model": "Mate 20 Pro"
            },
            {
                "regex": "LYA-AL00P(?:[);/ ]|$)",
                "model": "Mate 20 RS"
            },
            {
                "regex": "EVR-(?:[TA]L00|L29|N29|AN00)(?:[);/ ]|$)",
                "model": "Mate 20 X"
            },
            {
                "regex": "TAS-([AT]L00|L29)(?:[);/ ]|$)",
                "model": "Mate 30"
            },
            {
                "regex": "TAS-AN00(?:[);/ ]|$)",
                "model": "Mate 30 5G"
            },
            {
                "regex": "SPN-AL00(?:[);/ ]|$)",
                "model": "Mate 30 Lite"
            },
            {
                "regex": "LIO-(?:[TA]L00|L29|AN00)(?:[);/ ]|$)",
                "model": "Mate 30 Pro"
            },
            {
                "regex": "LIO-N29(?:[);/ ]|$)",
                "model": "Mate 30 Pro 5G"
            },
            {
                "regex": "NOH-(?:NX9|AN00)(?:[);/ ]|$)",
                "model": "Mate 40 Pro"
            },
            {
                "regex": "NOP-AN00(?:[);/ ]|$)",
                "model": "Mate 40 Pro+"
            },
            {
                "regex": "NXT-(?:AL10|L29)(?:[);/ ]|$)",
                "model": "Mate 8"
            },
            {
                "regex": "MHA-(?:L[02]9|[AT]L00)(?:[);/ ]|$)",
                "model": "Mate 9"
            },
            {
                "regex": "BLL-L23(?:[);/ ]|$)",
                "model": "Mate 9 Lite"
            },
            {
                "regex": "LON-(?:AL00(?:-PD)?|L29)(?:[);/ ]|$)",
                "model": "Mate 9 Pro"
            },
            {
                "regex": "NEO-AL00(?:[);/ ]|$)",
                "model": "Mate RS"
            },
            {
                "regex": "NEO-L29(?:[);/ ]|$)",
                "model": "Mate RS Porsche Design"
            },
            {
                "regex": "CRR-(?:L09|UL00)(?:[);/ ]|$)",
                "model": "Mate S"
            },
            {
                "regex": "BND-L34(?:[);/ ]|$)",
                "model": "Mate SE"
            },
            {
                "regex": "(CAZ-(?:AL[01]0|TL[12]0)|CAN-L[01][1-3])(?:[);/ ]|$)",
                "model": "Nova"
            },
            {
                "regex": "608HW(?:[);/ ]|$)",
                "model": "Nova Lite"
            },
            {
                "regex": "(?:PIC-(?:[AT]L00|LX9)|HWV31)(?:[);/ ]|$)",
                "model": "Nova 2"
            },
            {
                "regex": "704HW(?:[);/ ]|$)",
                "model": "Nova 2 Lite"
            },
            {
                "regex": "BAC-(?:L01|TL00)(?:[);/ ]|$)",
                "model": "Nova 2 Plus"
            },
            {
                "regex": "BAC-(?:AL00|L2[12])(?:[);/ ]|$)",
                "model": "Nova 2 Plus Dual SIM"
            },
            {
                "regex": "RNE-L[02]2(?:[);/ ]|$)",
                "model": "Nova 2I"
            },
            {
                "regex": "HWI-[AT]L00(?:[);/ ]|$)",
                "model": "Nova 2S"
            },
            {
                "regex": "PAR-(?:[AT]L00|LX[19]|LX1M|TL20)(?:[);/ ]|$)",
                "model": "Nova 3"
            },
            {
                "regex": "ANE-AL00(?:[);/ ]|$)",
                "model": "Nova 3e"
            },
            {
                "regex": "INE-(?:AL00|LX[12]r|LX[12]|TL00)(?:[);/ ]|$)",
                "model": "Nova 3i"
            },
            {
                "regex": "VCE-(L22|[AT]L00)(?:[);/ ]|$)",
                "model": "Nova 4"
            },
            {
                "regex": "MAR-AL00(?:[);/ ]|$)",
                "model": "Nova 4e"
            },
            {
                "regex": "SEA-AL00(?:[);/ ]|$)",
                "model": "Nova 5"
            },
            {
                "regex": "SEA-AL10(?:[);/ ]|$)",
                "model": "Nova 5 Pro"
            },
            {
                "regex": "GLK-(?:[AT]L00|LX1U)(?:[);/ ]|$)",
                "model": "Nova 5i"
            },
            {
                "regex": "SPN-TL00(?:[);/ ]|$)",
                "model": "Nova 5i Pro"
            },
            {
                "regex": "WLZ-AL10(?:[);/ ]|$)",
                "model": "Nova 6"
            },
            {
                "regex": "WLZ-AN00(?:[);/ ]|$)",
                "model": "Nova 6 5G"
            },
            {
                "regex": "JNY-AL10(?:[);/ ]|$)",
                "model": "Nova 6 SE"
            },
            {
                "regex": "JEF-(?:[AT]N00|AN20|NX9)(?:[);/ ]|$)",
                "model": "Nova 7 5G"
            },
            {
                "regex": "JER-[AT]N10(?:[);/ ]|$)",
                "model": "Nova 7 Pro 5G"
            },
            {
                "regex": "CDY-(?:[AT]N00|NX9B)(?:[);/ ]|$)",
                "model": "Nova 7 SE 5G"
            },
            {
                "regex": "JNY-LX2(?:[);/ ]|$)",
                "model": "Nova 7i"
            },
            {
                "regex": "ANG-AN00(?:[);/ ]|$)",
                "model": "Nova 8 5G"
            },
            {
                "regex": "DIG-L01(?:[);/ ]|$)",
                "model": "Nova Smart"
            },
            {
                "regex": "WAS-AL00(?:[);/ ]|$)",
                "model": "Nova Youth"
            },
            {
                "regex": "FIG-L(?:A1|X[123])(?:[);/ ]|$)",
                "model": "P smart"
            },
            {
                "regex": "POT-(?:LX(?:[13]|1T|2J|1AF)|AL00)(?:[);/ ]|$)",
                "model": "P smart (2019)"
            },
            {
                "regex": "POT-LX1A(?:[);/ ]|$)",
                "model": "P smart (2020)"
            },
            {
                "regex": "PPA-LX[12](?:[);/ ]|$)",
                "model": "P smart (2021)"
            },
            {
                "regex": "STK-LX1(?:[);/ ]|$)",
                "model": "P smart Z"
            },
            {
                "regex": "VTR-(?:L[02]9|AL00|TL00)(?:[);/ ]|$)",
                "model": "P10"
            },
            {
                "regex": "WAS-(?:L(?:X1|X1A|X2|X2J|X3|03T)|TL10)(?:[);/ ]|$)",
                "model": "P10 Lite"
            },
            {
                "regex": "VKY-(?:AL00|L09|L29)(?:[);/ ]|$)",
                "model": "P10 Plus"
            },
            {
                "regex": "BAC-L[02]3(?:[);/ ]|$)",
                "model": "P10 Selfie"
            },
            {
                "regex": "EML-(?:[TA]L00|L[02]9)(?:[);/ ]|$)",
                "model": "P20"
            },
            {
                "regex": "(?:ANE-(?:LX[123]|LX2J|TL00)|HWV32)(?:[);/ ]|$)",
                "model": "P20 Lite"
            },
            {
                "regex": "(?:CLT-(?:AL0[01]|TL0[01]|L(?:04|[02]9))|HW-01K)(?:[);/ ]|$)",
                "model": "P20 Pro"
            },
            {
                "regex": "(ELE-L(?:04|09|29)|ELE-[AT]L00)(?:[);/ ]|$)",
                "model": "P30"
            },
            {
                "regex": "(?:MAR-(?:LX(?:1[BM]|2J?|3BM)|TL00)|NIC-LX1A|HWV33)(?:[);/ ]|$)",
                "model": "P30 Lite"
            },
            {
                "regex": "MAR-LX[13]Am?(?:[);/ ]|$)",
                "model": "P30 Lite Dual SIM"
            },
            {
                "regex": "(?:VOG-(?:L(?:04|09|29)|TL00|AL10)|HW-02L)(?:[);/ ]|$)",
                "model": "P30 Pro"
            },
            {
                "regex": "ANA-LX4(?:[);/ ]|$)",
                "model": "P40"
            },
            {
                "regex": "JNY-LX1(?:[);/ ]|$)",
                "model": "P40 Lite"
            },
            {
                "regex": "ART-L29(?:[);/ ]|$)",
                "model": "P40 Lite E"
            },
            {
                "regex": "ART-L29N(?:[);/ ]|$)",
                "model": "P40 Lite E NFC"
            },
            {
                "regex": "(?:ELS-(?:[AT]N00|NX9|N04)|NEY-NX9)(?:[);/ ]|$)",
                "model": "P40 Pro"
            },
            {
                "regex": "ELS-(?:AN10|N39)(?:[);/ ]|$)",
                "model": "P40 Pro Plus"
            },
            {
                "regex": "GRA-(?:L09|UL00)(?:[);/ ]|$)",
                "model": "P8"
            },
            {
                "regex": "(?:ALE-L(?:02|21|23)|ALE-UL00|ALE-TL00|503HW)(?:[);/ ]|$)",
                "model": "P8 Lite (2015)"
            },
            {
                "regex": "(?:PRA-L(?:A1|X2|X1|X3)|hi6210sft)(?:[);/ ]|$)",
                "model": "P8 Lite (2017)"
            },
            {
                "regex": "EVA-(?:AL[10]0|L[012]9|[TCD]L00)(?:[);/ ]|$)",
                "model": "P9"
            },
            {
                "regex": "VNS-L(?:[23]1|[26]2)(?:[);/ ]|$)",
                "model": "P9 Lite"
            },
            {
                "regex": "SLA-L(?:02|03|22|23)(?:[);/ ]|$)",
                "model": "P9 Lite Mini"
            },
            {
                "regex": "DIG-L(?:03|23)(?:[);/ ]|$)",
                "model": "P9 Lite Smart"
            },
            {
                "regex": "VIE-(?:AL10|L[02]9)(?:[);/ ]|$)",
                "model": "P9 Plus"
            },
            {
                "regex": "ATH-UL0[16](?:[);/ ]|$)",
                "model": "ShotX"
            },
            {
                "regex": "U(8230|8661|8667)(?:[);/ ]|$)",
                "model": "U$1"
            },
            {
                "regex": "ARS-([AT]L00|L22)(?:[);/ ]|$)",
                "model": "Y Max"
            },
            {
                "regex": "CRO-(?:L[02]2|U00)(?:[);/ ]|$)",
                "model": "Y3 (2017)"
            },
            {
                "regex": "CAG-L[02]2(?:[);/ ]|$)",
                "model": "Y3 (2018)"
            },
            {
                "regex": "LUA-(U22|L2[12]|U03)(?:[);/ ]|$)",
                "model": "Y3II"
            },
            {
                "regex": "MYA-(?:L(?:02|03|2[23])|U29)(?:[);/ ]|$)",
                "model": "Y5 (2017)"
            },
            {
                "regex": "DRA-L(?:01|03|21|23|X3)(?:[);/ ]|$)",
                "model": "Y5 (2018)"
            },
            {
                "regex": "AMN-LX[1239](?:[);/ ]|$)",
                "model": "Y5 (2019)"
            },
            {
                "regex": "DRA-LX5(?:[);/ ]|$)",
                "model": "Y5 lite"
            },
            {
                "regex": "DRA-LX9(?:[);/ ]|$)",
                "model": "Y5p"
            },
            {
                "regex": "CRO-L[02]3(?:[);/ ]|$)",
                "model": "Y5 Lite (2017)"
            },
            {
                "regex": "CAG-L[02]3(?:[);/ ]|$)",
                "model": "Y5 Lite (2018)"
            },
            {
                "regex": "DRA-(?:LX2|[AT]L00)(?:[);/ ]|$)",
                "model": "Y5 Prime (2018)"
            },
            {
                "regex": "MYA-L13(?:[);/ ]|$)",
                "model": "Y5 Pro (2017)"
            },
            {
                "regex": "CUN-(?:L(?:0[123]|2[123]|33)|U29)(?:[);/ ]|$)",
                "model": "Y5II"
            },
            {
                "regex": "(?:SCC-U21|SCL-U31)(?:[);/ ]|$)",
                "model": "Y6"
            },
            {
                "regex": "MYA-L11(?:[);/ ]|$)",
                "model": "Y6 (2016)"
            },
            {
                "regex": "MYA-(L41|AL10)(?:[);/ ]|$)",
                "model": "Y6 (2017)"
            },
            {
                "regex": "ATU-L(?:11|21|22|X3)(?:[);/ ]|$)",
                "model": "Y6 (2018)"
            },
            {
                "regex": "MRD-L(?:X1[NF]?|X3)(?:[);/ ]|$)",
                "model": "Y6 (2019)"
            },
            {
                "regex": "ATU-L(?:31|42)(?:[);/ ]|$)",
                "model": "Y6 Prime (2018)"
            },
            {
                "regex": "TIT-(?:AL00|L01|U02)(?:[);/ ]|$)",
                "model": "Y6 Pro"
            },
            {
                "regex": "MRD-LX2(?:[);/ ]|$)",
                "model": "Y6 Pro (2019)"
            },
            {
                "regex": "Y625-U03(?:[);/ ]|$)",
                "model": "Y625"
            },
            {
                "regex": "Y635-L0[123](?:[);/ ]|$)",
                "model": "Y635"
            },
            {
                "regex": "Y635-L21(?:[);/ ]|$)",
                "model": "Y635 Dual SIM"
            },
            {
                "regex": "CAM-L(?:03|21|23|32)(?:[);/ ]|$)",
                "model": "Y6II"
            },
            {
                "regex": "TRT-LX[123](?:[);/ ]|$)",
                "model": "Y7 (2017)"
            },
            {
                "regex": "LDN-L(?:01|X3)(?:[);/ ]|$)",
                "model": "Y7 (2018)"
            },
            {
                "regex": "(?:HUAWEI[ _]?)?DUB-(?:LX[13]|TL00)(?:[);/ ]|$)",
                "model": "Y7 (2019)"
            },
            {
                "regex": "(LDN-(?:AL00|L21)|TRT-TL10)(?:[);/ ]|$)",
                "model": "Y7 Prime"
            },
            {
                "regex": "(LDN-(?:LX2|TL10)|TRT-(?:L21A|L53))(?:[);/ ]|$)",
                "model": "Y7 Prime (2018)"
            },
            {
                "regex": "DUB-(?:LX2|AL00)(?:[);/ ]|$)",
                "model": "Y7 Pro (2019)"
            },
            {
                "regex": "DUB-AL00a(?:[);/ ]|$)",
                "model": "Enjoy 9"
            },
            {
                "regex": "DUB-AL20(?:[);/ ]|$)",
                "model": "Y7 Pro (2019)"
            },
            {
                "regex": "PPA-LX3(?:[);/ ]|$)",
                "model": "Y7a"
            },
            {
                "regex": "ART-L28(?:[);/ ]|$)",
                "model": "Y7p"
            },
            {
                "regex": "AQM-LX1(?:[);/ ]|$)",
                "model": "Y8p"
            },
            {
                "regex": "FLA-(LX[123]|AL20|TL10)(?:[);/ ]|$)",
                "model": "Y9 (2018)"
            },
            {
                "regex": "JKM-(?:[TA]L00[ab]?|LX[123])(?:[);/ ]|$)",
                "model": "Y9 (2019)"
            },
            {
                "regex": "STK-(L2[12]|LX3)(?:[);/ ]|$)",
                "model": "Y9 Prime (2019)"
            },
            {
                "regex": "FRL-L2[23](?:[);/ ]|$)",
                "model": "Y9a"
            },
            {
                "regex": "HWT31(?:[);/ ]|$)",
                "model": "Qua Tab 02 10.1\"",
                "device": "tablet"
            },
            {
                "regex": "HDN-[LW]09(?:[);/ ]|$)",
                "model": "Honor Water Play 10.1",
                "device": "tablet"
            },
            {
                "regex": "BAH3-(?:AL00|[LW]09)(?:[);/ ]|$)",
                "model": "MatePad 10.4\"",
                "device": "tablet"
            },
            {
                "regex": "BAH3-W59(?:[);/ ]|$)",
                "model": "MatePad 10.4\" WiFi",
                "device": "tablet"
            },
            {
                "regex": "AGS3-[WL]09(?:[);/ ]|$)",
                "model": "MatePad T10s",
                "device": "tablet"
            },
            {
                "regex": "HDL-(?:W09|AL09)(?:[);/ ]|$)",
                "model": "Honor Water Play 8.0",
                "device": "tablet"
            },
            {
                "regex": "JDN-(?:AL00|W09)(?:[);/ ]|$)",
                "model": "Honor Pad 2",
                "device": "tablet"
            },
            {
                "regex": "KRJ-W09(?:[);/ ]|$)",
                "model": "Honor Pad V6",
                "device": "tablet"
            },
            {
                "regex": "MON-(W|AL)19(?:[);/ ]|$)",
                "device": "tablet",
                "model": "MatePad C5 8"
            },
            {
                "regex": "BZA-W00(?:[);/ ]|$)",
                "device": "tablet",
                "model": "C3 9.6"
            },
            {
                "regex": "M2-801L(?:[);/ ]|$)",
                "device": "tablet",
                "model": "MediaPad M2 8.0\""
            },
            {
                "regex": "MRX-(AL09|W09)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "MatePad Pro"
            },
            {
                "regex": "CMR-(?:AL[01]9|W09)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "MediaPad M5 10.8"
            },
            {
                "regex": "CMR-W19(?:[);/ ]|$)",
                "device": "tablet",
                "model": "MediaPad M5 Pro 10.8"
            },
            {
                "regex": "SCM-AL09(?:[);/ ]|$)",
                "device": "tablet",
                "model": "MediaPad M5 Pro"
            },
            {
                "regex": "GEM-70[1-3]L(?:[);/ ]|$)",
                "device": "tablet",
                "model": "MediaPad X2"
            },
            {
                "regex": "(?:JDN2-(:?[WL]09|AL[50]0)|BAH2-(?:AL[01]0|L09|W[01]9))(?:[);/ ]|$)",
                "device": "tablet",
                "model": "MediaPad M5 Lite"
            },
            {
                "regex": "SHT-(?:AL|W)09(?:[);/ ]|$)",
                "device": "tablet",
                "model": "MediaPad M5 8.4"
            },
            {
                "regex": "VRD-(?:(AL|W)09|AL10|W10)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "MediaPad M6 8.4"
            },
            {
                "regex": "SCM-W09(?:[);/ ]|$)",
                "device": "tablet",
                "model": "MediaPad M6 10"
            },
            {
                "regex": "BTV-W09(?:[);/ ]|$)",
                "device": "tablet",
                "model": "MediaPad M3 8"
            },
            {
                "regex": "(BAH-W09|CPN-(?:L|W)09|CPN-AL00|701HW)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "MediaPad M3 Lite"
            },
            {
                "regex": "BAH-(?:L09|AL00)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "MediaPad M3 Lite 10"
            },
            {
                "regex": "BTV-DL09",
                "device": "tablet",
                "model": "MediaPad M3"
            },
            {
                "regex": "(AGS2-AL00|JDN2-W09)HN(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Honor Tab 5"
            },
            {
                "regex": "AGS2-(?:[LW]09|W19|L03)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "MediaPad T5 10"
            },
            {
                "regex": "KOB2-[LW]09(?:[);/ ]|$)",
                "device": "tablet",
                "model": "MediaPad T 8.0\""
            },
            {
                "regex": "AGR-[LW]09(?:[);/ ]|$)",
                "device": "tablet",
                "model": "MediaPad T 10"
            },
            {
                "regex": "AGS-(?:L0[39]|W09)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "MediaPad T3 10"
            },
            {
                "regex": "(KOB-(?:L|W)09|BZK-[LW]00)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "MediaPad T3 8"
            },
            {
                "regex": "BG2-(?:U0[13]|W09)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "MediaPad T3 7"
            },
            {
                "regex": "BGO-L03(?:[);/ ]|$)",
                "device": "tablet",
                "model": "MediaPad T2 7.0"
            },
            {
                "regex": "(?:BGO-DL09|PLE-70[13]L)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "MediaPad T2 7.0 Pro"
            },
            {
                "regex": "JDN-L01(?:[);/ ]|$)",
                "device": "tablet",
                "model": "MediaPad T2 8.0 Pro"
            },
            {
                "regex": "(?:FDR-(A01[LW]|A03L)|605HW)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "MediaPad T2 10.0\" Pro"
            },
            {
                "regex": "T1-(?:A21[LW]|A23L)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "MediaPad T1 10"
            },
            {
                "regex": "T1-701u(?:[);/ ]|$)",
                "device": "tablet",
                "model": "MediaPad T1 7"
            },
            {
                "regex": "(?:T1-82(?:3L|1W)|MediaPad T1 8.0)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "MediaPad T1 8"
            },
            {
                "regex": "AGS2-AL00(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Enjoy Tablet 10.1"
            },
            {
                "regex": "BZT-(W09|AL[01]0)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "MediaPad C5 10.1"
            },
            {
                "regex": "(?:d-01J|d-02[HK])(?:[);/ ]|$)",
                "device": "tablet",
                "model": "D Tab Compact"
            },
            {
                "regex": "(d-01K)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "dtab d-01K"
            },
            {
                "regex": "LEO-BX9(?:[);/ ]|$)",
                "device": "wearable",
                "model": "Smart Watch 2"
            },
            {
                "regex": "(MediaPad[^/;]*) Build",
                "device": "tablet",
                "model": "$1"
            },
            {
                "regex": "Ideos([^;/]*) Build",
                "model": "Ideos$1"
            },
            {
                "regex": "(?:HUAWEI )?MT([0-9]+)",
                "device": "phablet",
                "model": "Ascend Mate $1"
            },
            {
                "regex": "Huawei[ _\\-]?([^/;]*) Build",
                "model": "$1"
            },
            {
                "regex": "(?:HW-)?Huawei(?!Browser)(?:/1\\.0/0?(?:Huawei))?[_\\- /]?([a-z0-9\\-_]+)",
                "model": "$1"
            },
            {
                "regex": "Huawei; ([a-z0-9 \\-]+)",
                "model": "$1"
            }
        ]
    },
    "Hyundai": {
        "regex": "Hyundai|Ultra (?:Active|Air|Charm|Dream|Energy|Latitude|Link|Live|Shadow|Shine|Storm|Style|Sync|Trend|Vision|Wave)|G(24027|2552[34])K|W25042L|HP554O|Seoul S8|L503F Plus|HT0701L16|HT0704K08|HT1004L16|HT1004LI16|Koral_8W2|Eternity[_ ]G25",
        "device": "smartphone",
        "models": [
            {
                "regex": "Ultra (Active|Air|Charm|Dream|Energy Lite|Energy Plus|Energy|Latitude|Link|Live II|Live|Shadow|Shine|Storm|Style|Sync|Trend|Vision|Wave)",
                "model": "Ultra $1"
            },
            {
                "regex": "Seoul S8",
                "model": "Seoul S8"
            },
            {
                "regex": "G25523K",
                "model": "Eternity G23"
            },
            {
                "regex": "Eternity[_ ]G25",
                "model": "Eternity G25"
            },
            {
                "regex": "G25524K",
                "model": "Eternity G24"
            },
            {
                "regex": "G24027K",
                "model": "Eternity G27"
            },
            {
                "regex": "W25042L",
                "model": "Eternity W42"
            },
            {
                "regex": "HP554O",
                "model": "HP554O"
            },
            {
                "regex": "L503F Plus",
                "model": "L503F Plus"
            },
            {
                "regex": "L503F Plus",
                "model": "L503F Plus"
            },
            {
                "regex": "(?:Hyundai_)?(Eternity_G57L)",
                "model": "$1"
            },
            {
                "regex": "HT0701L16",
                "model": "Koral 7XL",
                "device": "tablet"
            },
            {
                "regex": "HT1004LI16",
                "model": "Koral 10XL",
                "device": "tablet"
            },
            {
                "regex": "Koral_8W2",
                "model": "Koral 8W2",
                "device": "tablet"
            },
            {
                "regex": "HT1004L16",
                "model": "Koral 10XL",
                "device": "tablet"
            },
            {
                "regex": "HT0704K08",
                "model": "Koral 7M4",
                "device": "tablet"
            },
            {
                "regex": "Hyundai ([^;/]+) Build",
                "model": "$1",
                "device": "tablet"
            },
            {
                "regex": "Hyundai[ _\\-]?([a-z0-9_-]+)",
                "model": "$1",
                "device": "tablet"
            }
        ]
    },
    "i-Joy": {
        "regex": "i-Joy|i-Call|(?:Neon[79]|Sygnus|Deox|Elektra (?:M|L|XL|XXL))(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Elektra M(?:[);/ ]|$)",
                "model": "Elektra M"
            },
            {
                "regex": "Elektra L(?:[);/ ]|$)",
                "model": "Elektra L"
            },
            {
                "regex": "Elektra XL(?:[);/ ]|$)",
                "model": "Elektra XL"
            },
            {
                "regex": "Elektra XXL(?:[);/ ]|$)",
                "model": "Elektra XXL",
                "device": "phablet"
            },
            {
                "regex": "Neon([79])(?:[);/ ]|$)",
                "model": "Neon $1",
                "device": "tablet"
            },
            {
                "regex": "Sygnus(?:[);/ ]|$)",
                "model": "Sygnus",
                "device": "tablet"
            },
            {
                "regex": "Deox(?:[);/ ]|$)",
                "model": "Deox",
                "device": "tablet"
            },
            {
                "regex": "i-Call ([^;/]+) Build",
                "model": "i-Call $1"
            },
            {
                "regex": "i-Joy ([^;/]+) Build",
                "model": "$1"
            }
        ]
    },
    "iBall": {
        "regex": "iBall[ _]([^;/)]+)[ _]Build|iBall_Slide|(?:Snap[_ ]4G2|6351-Q400i|iT-KSA0003|iT-KSA0012|iT-KSA0066|Avid|Twinkle_i5|Gorgeo[_ ]4GL|Brisk 4G2|Blaze_V4|Wondro 10|Q27 4G|Andi (?:Wink|5G Blink) 4G)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "Andi Wink 4G",
                "device": "smartphone",
                "model": "Andi Wink 4G"
            },
            {
                "regex": "Andi 5G Blink 4G",
                "device": "smartphone",
                "model": "Andi 5G Blink 4G"
            },
            {
                "regex": "(Snap[_ ]4G2|Avid|Twinkle_i5|Gorgeo[_ ]4GL|Blaze_V4|Wondro 10|Q27 4G|Brisk 4G2)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "6351-Q400i(?:[);/ ]|$)",
                "model": "Slide"
            },
            {
                "regex": "iT-KSA0003(?:[);/ ]|$)",
                "model": "iTAB BizniZ"
            },
            {
                "regex": "iT-KSA0066(?:[);/ ]|$)",
                "model": "iTAB BizniZ Mini"
            },
            {
                "regex": "iT-KSA0012(?:[);/ ]|$)",
                "model": "iTAB Moviez Pro"
            },
            {
                "regex": "iBall[ _]([^;/)]+)(?:[ _]Build|\\))",
                "model": "$1"
            }
        ]
    },
    "iRola": {
        "regex": "iRola ([^/;]+) Build|(DX758|DX752|DX752|DX758[ _]?Pro) Build",
        "device": "tablet",
        "models": [
            {
                "regex": "DX758[ _]?Pro",
                "model": "DX758 Pro"
            },
            {
                "regex": "(DX758|DX752)",
                "model": "$1"
            },
            {
                "regex": "iRola ([^/;]+) Build",
                "model": "$1"
            }
        ]
    },
    "iRulu": {
        "regex": "iRULU[ _-](X11|V[34]|U2|AL101)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "iRULU[ _-](X11|V[34]|AL101)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "iRULU[ _](U2)(?:[);/ ]|$)",
                "device": "smartphone",
                "model": "$1"
            }
        ]
    },
    "Irbis": {
        "regex": "IRBIS|TZ(1(?:0[04]|42|5[01]|84|65|7[01345-89]|8[356]|9[12578])|49|55|72|22|7(?:1[124679]|2[021567]|37|4[257]|5[23457]|6[12]|72|7[17]|8[1]|9[147])|8(?:[34]1|5[3-8]|6[45]|7[2478]|8[1-5]|9[0127])|96[0-9])|TZ85|TZ13|TZ(?:one|art|HIT|81L)|(SP(?:0[56]|2[01]|4[1-36]|40[12]|45[345]|49[34]|50|51[0147]|531|54[12]|55[0124]|571))(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "(TZ(?:[0-9]+)|TZ(?:one|art|HIT|81L))(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "(SP[0-9]+)(?:[);/ ]|$)",
                "device": "smartphone",
                "model": "$1"
            }
        ]
    },
    "iBerry": {
        "regex": "AUXUS ([^/;]+) Build",
        "device": "smartphone",
        "models": [
            {
                "regex": "AUXUS (Core[^/;]+) Build",
                "device": "tablet",
                "model": "$1"
            },
            {
                "regex": "AUXUS ([^/;]+) Build",
                "model": "$1"
            }
        ]
    },
    "iHunt": {
        "regex": "iHunt|One_ Love_|TITAN_P11000_PRO|Like_(3|4U|3_Pro)|X300 Elite|S10_Tank_2019|S60_Discovery_2019|AlienXLite2020|Alien X Lite|Alien_X|S20 Ultra ApeX 2021",
        "device": "smartphone",
        "models": [
            {
                "regex": "iHunt_Like",
                "model": "Like"
            },
            {
                "regex": "S10_Tank_2019",
                "model": "S10 Tank (2019)"
            },
            {
                "regex": "One_ Love_",
                "model": "One Love"
            },
            {
                "regex": "TITAN_P11000_PRO",
                "model": "Titan P11000 Pro"
            },
            {
                "regex": "Like_3_Pro",
                "model": "Like 3 Pro"
            },
            {
                "regex": "Like_3",
                "model": "Like 3"
            },
            {
                "regex": "Like_4U",
                "model": "Like 4U"
            },
            {
                "regex": "X300 Elite",
                "model": "X300 Elite"
            },
            {
                "regex": "S60_Discovery_2019",
                "model": "S60 Discovery (2019)"
            },
            {
                "regex": "AlienXLite2020",
                "model": "Alien X Lite (2020)"
            },
            {
                "regex": "Alien X Lite PRO",
                "model": "Alien X Lite Pro"
            },
            {
                "regex": "Alien X Lite",
                "model": "Alien X Lite"
            },
            {
                "regex": "Alien_X_ApeX",
                "model": "Alien X ApeX"
            },
            {
                "regex": "Alien_X",
                "model": "Alien X"
            },
            {
                "regex": "S20 Ultra ApeX 2021",
                "model": "S20 Ultra ApeX (2021)"
            }
        ]
    },
    "IconBIT": {
        "regex": "IconBIT|NT-(3506M|0704S|0909T|1009T|10[01]1T|1017T|3601P/3602P|3603P|3702S|3805C|3905T|3701S|0701S|0805C|0902S|370[23]M)|XDS[789]4K|Omnicast UHD|Movie Smart 4K|Movie IPTV Quad",
        "device": "tablet",
        "models": [
            {
                "regex": "NT-3506M",
                "device": "smartphone",
                "model": "NetTAB Mercury Quad FHD"
            },
            {
                "regex": "NT-0704S",
                "model": "NetTAB Sky LE"
            },
            {
                "regex": "NT-0909T",
                "model": "NetTAB Thor IZ"
            },
            {
                "regex": "NT-1009T",
                "model": "NetTAB Thor quad II"
            },
            {
                "regex": "NT-10[01]1T",
                "model": "NetTAB Thor LE"
            },
            {
                "regex": "NT-1017T",
                "model": "NetTAB Thor 3G quad"
            },
            {
                "regex": "NT-3601P/3602P",
                "model": "NetTAB Pocket 3G"
            },
            {
                "regex": "NT-3603P",
                "model": "NetTAB Pocket 3G Slim"
            },
            {
                "regex": "NT-3702S",
                "model": "NetTAB Sky HD 3G"
            },
            {
                "regex": "NT-3805C",
                "model": "NetTAB Skat 3G quad"
            },
            {
                "regex": "NT-3701S",
                "model": "NetTAB Sky 3G duo"
            },
            {
                "regex": "NT-3905T",
                "model": "NetTAB Thor ZX 3G"
            },
            {
                "regex": "NT-0701S",
                "model": "NetTAB Sky net"
            },
            {
                "regex": "NT-0805C",
                "model": "NetTAB Skat MX"
            },
            {
                "regex": "NT-0902S",
                "model": "NetTAB Space quad RX"
            },
            {
                "regex": "NT-3702M",
                "model": "NetTAB Matrix 3G duo"
            },
            {
                "regex": "NT-3703M",
                "model": "NetTAB Matrix 3GT"
            },
            {
                "regex": "(?:iconBIT )?(Movie Smart 4K|Movie IPTV Quad|Movie Ultra HD 4K|XDS[789]4K|Omnicast UHD)",
                "model": "$1",
                "device": "tv"
            }
        ]
    },
    "LYF": {
        "regex": "(LYF[ _])?LS-[456][0-9]{3}|LYF/[^/]+/",
        "device": "smartphone",
        "models": [
            {
                "regex": "LS-4004",
                "model": "Flame 2"
            },
            {
                "regex": "LS-5501",
                "model": "Earth 1"
            },
            {
                "regex": "LS-5021",
                "model": "Earth 2"
            },
            {
                "regex": "LS-5505",
                "model": "Water F1"
            },
            {
                "regex": "LS-4503",
                "model": "Flame LS-4503"
            },
            {
                "regex": "LS-4001",
                "model": "Flame 3"
            },
            {
                "regex": "LS-4003",
                "model": "Flame 4"
            },
            {
                "regex": "LS-4002",
                "model": "Flame 5"
            },
            {
                "regex": "LS-4005",
                "model": "Flame 6"
            },
            {
                "regex": "(LYF[ _])?LS-4006",
                "model": "Flame 7"
            },
            {
                "regex": "LS-4008",
                "model": "Flame 7S"
            },
            {
                "regex": "LS-450[58]",
                "model": "Flame 8"
            },
            {
                "regex": "LS-5002",
                "model": "Water 1"
            },
            {
                "regex": "LS-5005",
                "model": "Water 4"
            },
            {
                "regex": "LS-5006",
                "model": "Water 6"
            },
            {
                "regex": "LS-5008",
                "model": "Water 2"
            },
            {
                "regex": "LS-5009",
                "model": "Wind 6"
            },
            {
                "regex": "LS-5013",
                "model": "Wind 5"
            },
            {
                "regex": "LS-5014",
                "model": "Wind 4"
            },
            {
                "regex": "LS-5010",
                "model": "Wind 1"
            },
            {
                "regex": "LS-5015",
                "model": "Water 8"
            },
            {
                "regex": "LS-5016",
                "model": "Wind 7"
            },
            {
                "regex": "LS-5504",
                "model": "Water 7"
            },
            {
                "regex": "LS-5507",
                "model": "Water 7S"
            },
            {
                "regex": "LS-5017",
                "model": "Water 11"
            },
            {
                "regex": "LS-5018",
                "model": "Wind 4S"
            },
            {
                "regex": "LS-5020",
                "model": "Water 10"
            },
            {
                "regex": "LS-5201",
                "model": "Water F1S"
            },
            {
                "regex": "LS-5502",
                "model": "Wind 3"
            },
            {
                "regex": "LS-5503",
                "model": "Water 3"
            },
            {
                "regex": "LS-5506",
                "model": "Water 9"
            },
            {
                "regex": "LS-6001",
                "model": "Wind 2"
            },
            {
                "regex": "LYF/([^/]+)/",
                "model": "$1"
            }
        ]
    },
    "Lumus": {
        "regex": "LUMUS Shell|(?:NEO(SR620|SC600)|INNOSR545|Lumus[_ ]Ion)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "NEO(SR620|SC600)(?:[);/ ]|$)",
                "model": "Neo $1"
            },
            {
                "regex": "INNO(SR545)(?:[);/ ]|$)",
                "model": "INNO $1"
            },
            {
                "regex": "Lumus[_ ]Ion(?:[);/ ]|$)",
                "model": "Ion"
            },
            {
                "regex": "LUMUS Shell [0-9A-Z]+",
                "device": "tv",
                "model": ""
            }
        ]
    },
    "M4tel": {
        "regex": "M4 SS[0-9]{4}(?:-R)?|M4_B[23]",
        "device": "smartphone",
        "models": [
            {
                "regex": "SS1050",
                "model": "Joy"
            },
            {
                "regex": "SS1060",
                "model": "Live"
            },
            {
                "regex": "SS1070",
                "model": "Sense"
            },
            {
                "regex": "SS1080",
                "model": "Max One"
            },
            {
                "regex": "SS1090",
                "model": "Max Ultra"
            },
            {
                "regex": "SS4020",
                "model": "Play"
            },
            {
                "regex": "SS4040",
                "model": "In Touch"
            },
            {
                "regex": "SS4045",
                "model": "Style"
            },
            {
                "regex": "SS4345",
                "model": "Soul Mini"
            },
            {
                "regex": "SS4350",
                "model": "Soul"
            },
            {
                "regex": "SS4445",
                "model": "Style Access"
            },
            {
                "regex": "SS4450",
                "model": "Share"
            },
            {
                "regex": "SS4451",
                "model": "Believe"
            },
            {
                "regex": "SS4452",
                "model": "Dream"
            },
            {
                "regex": "SS4453",
                "model": "Inspiration"
            },
            {
                "regex": "SS4455",
                "model": "Excite"
            },
            {
                "regex": "SS4456",
                "model": "Evolution"
            },
            {
                "regex": "SS4457-R",
                "model": "Ever"
            },
            {
                "regex": "SS4457",
                "model": "Elegance"
            },
            {
                "regex": "SS4458-R",
                "model": "Feel Plus"
            },
            {
                "regex": "SS4458",
                "model": "Attitude"
            },
            {
                "regex": "M4_B([23])",
                "model": "B$1"
            }
        ]
    },
    "IMO Mobile": {
        "regex": "IMO[ _]\\w",
        "device": "smartphone",
        "models": [
            {
                "regex": "IMO[ _]Q([23]) Plus(?:[);/ ]|$)",
                "model": "Q$1 Plus"
            },
            {
                "regex": "IMO[ _]Q2(?:[);/ ]|$)",
                "model": "Q2"
            },
            {
                "regex": "IMO[ _]S2(?:[);/ ]|$)",
                "model": "S2"
            },
            {
                "regex": "IMO[ _]FEEL A2(?:[);/ ]|$)",
                "model": "Feel A2"
            },
            {
                "regex": "IMO[ _]S50(?:[);/ ]|$)",
                "model": "S50 Light"
            },
            {
                "regex": "IMO[ _]S80(?:[);/ ]|$)",
                "model": "S80 Hero"
            },
            {
                "regex": "IMO[ _]S89(?:[);/ ]|$)",
                "model": "S89 Miracle"
            },
            {
                "regex": "IMO[ _]S88(?:[);/ ]|$)",
                "model": "S88 Discovery"
            },
            {
                "regex": "IMO[ _]S99(?:[);/ ]|$)",
                "model": "S99 Ocean"
            },
            {
                "regex": "IMO[ _]S98(?:[);/ ]|$)",
                "model": "S98 Champion"
            },
            {
                "regex": "IMO[ _]S87(?:[);/ ]|$)",
                "model": "S87 Raptor"
            },
            {
                "regex": "IMO[ _]S67(?:[);/ ]|$)",
                "model": "S67 Blast"
            },
            {
                "regex": "IMO[ _]S78(?:[);/ ]|$)",
                "model": "S78 Glory"
            },
            {
                "regex": "IMO[ _]Q8(?:[);/ ]|$)",
                "model": "Q8 Clarity"
            },
            {
                "regex": "IMO[ _]Discovery II(?:[);/ ]|$)",
                "model": "Discovery II"
            },
            {
                "regex": "IMO[ _]Q(?:[);/ ]|$)",
                "model": "Q"
            },
            {
                "regex": "IMO[ _](Y5|Z[67])(?:[);/ ]|$)",
                "model": "Tab $1",
                "device": "tablet"
            },
            {
                "regex": "IMO TAB X9(?:[);/ ]|$)",
                "model": "Tab X9",
                "device": "tablet"
            }
        ]
    },
    "iLA": {
        "regex": "iLA[-_ ](Silk|X)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "iNew": {
        "regex": "iNew|(?:V7A|V3 Plus|V3-?E|V3C|FIRE_Tab_6)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "V3C(?:[);/ ]|$)",
                "model": "V3C"
            },
            {
                "regex": "V3-?E(?:[);/ ]|$)",
                "model": "V3-E"
            },
            {
                "regex": "V3 Plus(?:[);/ ]|$)",
                "model": "V3 Plus"
            },
            {
                "regex": "V7A(?:[);/ ]|$)",
                "model": "V7A"
            },
            {
                "regex": "FIRE_Tab_6(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Fire Tab 6"
            },
            {
                "regex": "iNew[ -_]([^;/]+) Build",
                "model": "$1"
            }
        ]
    },
    "iPro": {
        "regex": "(?:Kylin[ _]5.[05]S?|KYLIN40|Jade[78]s|Amber[67]S?|Amber5S_Pro|Phonenix50S|Zafiro)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Kylin_5.0S",
                "model": "Kylin 5.0S"
            },
            {
                "regex": "Kylin (5.[05])",
                "model": "Kylin $1"
            },
            {
                "regex": "KYLIN40",
                "model": "Kylin 40"
            },
            {
                "regex": "Jade([78])s",
                "model": "Jade $1s"
            },
            {
                "regex": "Amber7S",
                "model": "Amber 7S"
            },
            {
                "regex": "Amber5S_Pro",
                "model": "Amber 5S Pro"
            },
            {
                "regex": "Amber6",
                "model": "Amber 6"
            },
            {
                "regex": "Phonenix50S",
                "model": "Phonenix 50S"
            },
            {
                "regex": "Zafiro",
                "model": "Zafiro"
            }
        ]
    },
    "Infinix": {
        "regex": "Infinix",
        "device": "smartphone",
        "models": [
            {
                "regex": "Infinix[ _-]X260",
                "model": "Buzz"
            },
            {
                "regex": "Infinix[ _-]X502",
                "model": "Alpha Marvel"
            },
            {
                "regex": "Infinix[ _-]X570",
                "model": "Alpha"
            },
            {
                "regex": "Infinix[ _-]X350",
                "model": "Surf Smart"
            },
            {
                "regex": "Infinix[ _-]X351",
                "model": "Surf Smart 3G"
            },
            {
                "regex": "Infinix[ _-]X352",
                "model": "Surf Smart 2"
            },
            {
                "regex": "Infinix[ _-]X403",
                "model": "Surf Spice"
            },
            {
                "regex": "Infinix[ _-]X503",
                "model": "Surf Bravo"
            },
            {
                "regex": "Infinix[ _-]X511",
                "model": "Surf Noir"
            },
            {
                "regex": "Infinix[ _-]X400",
                "model": "Race"
            },
            {
                "regex": "Infinix[ _-]X401",
                "model": "Race Lite"
            },
            {
                "regex": "Infinix[ _-]X450",
                "model": "Race Bolt"
            },
            {
                "regex": "Infinix[ _-]X451",
                "model": "Race Bolt Q"
            },
            {
                "regex": "Infinix[ _-]X454",
                "model": "Race Bolt 2"
            },
            {
                "regex": "Infinix[ _-]X500",
                "model": "Race Eagle"
            },
            {
                "regex": "Infinix[ _-]X501",
                "model": "Race Jet"
            },
            {
                "regex": "Infinix[ _-]X506",
                "model": "Zero"
            },
            {
                "regex": "Infinix[ _-]X509",
                "model": "Zero 2"
            },
            {
                "regex": "Infinix[ _-]X552",
                "model": "Zero 3"
            },
            {
                "regex": "Infinix[ _-]X555",
                "model": "Zero 4"
            },
            {
                "regex": "Infinix[ _-]X(?:574|602)",
                "model": "Zero 4 Plus"
            },
            {
                "regex": "Infinix[ _-]X603",
                "model": "Zero 5"
            },
            {
                "regex": "Infinix[ _-]X603B",
                "model": "Zero 5 Pro"
            },
            {
                "regex": "Infinix[ _-]X620",
                "model": "Zero 6"
            },
            {
                "regex": "Infinix[ _-]X507",
                "model": "Hot"
            },
            {
                "regex": "Infinix[ _-]X510",
                "model": "Hot 2"
            },
            {
                "regex": "Infinix[ _-]X55[34]",
                "model": "Hot 3"
            },
            {
                "regex": "Infinix[ _-]X521",
                "model": "Hot S"
            },
            {
                "regex": "Infinix[ _-]X522",
                "model": "Hot S2"
            },
            {
                "regex": "Infinix[ _-]X557",
                "model": "Hot 4"
            },
            {
                "regex": "Infinix[ _-]X556",
                "model": "Hot 4 Pro"
            },
            {
                "regex": "Infinix[ _-]X559c",
                "model": "Hot 5"
            },
            {
                "regex": "Infinix[ _-]X559",
                "model": "Hot 5 Lite"
            },
            {
                "regex": "Infinix[ _-]X606[BC]?",
                "model": "Hot 6"
            },
            {
                "regex": "Infinix[ _-]X608",
                "model": "Hot 6 Pro"
            },
            {
                "regex": "Infinix[ _-]X624",
                "model": "Hot 7"
            },
            {
                "regex": "Infinix[ _-]X650",
                "model": "Hot 8"
            },
            {
                "regex": "Infinix[ _-]X680",
                "model": "Hot 9 Play"
            },
            {
                "regex": "Infinix[ _-]X573B?",
                "model": "Hot S3"
            },
            {
                "regex": "Infinix[ _-]X5010",
                "model": "Smart"
            },
            {
                "regex": "Infinix[ _-]X5515F",
                "model": "Smart 2"
            },
            {
                "regex": "Infinix[ _-]X5514D",
                "model": "Smart 2 Pro"
            },
            {
                "regex": "Infinix[ _-]X653",
                "model": "Smart 4"
            },
            {
                "regex": "Infinix[ _-]X626",
                "model": "S4"
            },
            {
                "regex": "Infinix[ _-]X652",
                "model": "S5"
            },
            {
                "regex": "Infinix[ _-]X660",
                "model": "S5 Pro"
            },
            {
                "regex": "Infinix[ _-]X551",
                "model": "Hot Note",
                "device": "phablet"
            },
            {
                "regex": "Infinix[ _-]X600",
                "model": "Note 2",
                "device": "phablet"
            },
            {
                "regex": "Infinix[ _-]X601",
                "model": "Note 3",
                "device": "phablet"
            },
            {
                "regex": "Infinix[ _-]X572",
                "model": "Note 4",
                "device": "phablet"
            },
            {
                "regex": "Infinix[ _-]X571",
                "model": "Note 4 Pro",
                "device": "phablet"
            },
            {
                "regex": "Infinix[ _-]X604",
                "model": "Note 5",
                "device": "phablet"
            },
            {
                "regex": "Infinix[ _-]X610",
                "model": "Note 6",
                "device": "phablet"
            },
            {
                "regex": "Infinix[ _-]X690",
                "model": "Note 7",
                "device": "phablet"
            },
            {
                "regex": "Infinix[ _-]X656",
                "model": "Note 7 Lite",
                "device": "phablet"
            },
            {
                "regex": "Infinix HOT ([a-z]?[1-6])( (?:Lite|Plus|Pro))?",
                "model": "Hot $1$2"
            },
            {
                "regex": "Infinix[ _-](X[78]00)",
                "device": "tablet",
                "model": "$1"
            },
            {
                "regex": "Infinix[ _-](X\\d+)",
                "model": "$1"
            },
            {
                "regex": "Infinix[ _-]([a-z0-9_\\-]+)",
                "model": "$1"
            }
        ]
    },
    "InFocus": {
        "regex": "IF(900[1237]|902[19]|903[15])(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "IF9001",
                "model": "Turbo 5"
            },
            {
                "regex": "IF9007",
                "model": "A3"
            },
            {
                "regex": "IF9021",
                "model": "Turbo 5 Plus"
            },
            {
                "regex": "IF9035",
                "model": "A2"
            },
            {
                "regex": "IF9002",
                "model": "M5s"
            },
            {
                "regex": "IF9003",
                "model": "M6s"
            },
            {
                "regex": "IF9031",
                "model": "Vision 3"
            },
            {
                "regex": "IF9029",
                "model": "Vision 3 Pro"
            }
        ]
    },
    "InnJoo": {
        "regex": "InnJoo|(?:Halo Plus|Halo X|Halo[ _]4(?:[ _]mini[ _]LTE)?|Halo2 3G|Fire2 Plus LTE)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "InnJoo[ _]X3(?:[);/ ]|$)",
                "model": "X3"
            },
            {
                "regex": "Halo[ _]X[ _]LTE(?:[);/ ]|$)",
                "model": "Halo X LTE"
            },
            {
                "regex": "InnJoo[ _]X(?:[);/ ]|$)",
                "model": "X"
            },
            {
                "regex": "Halo Plus(?:[);/ ]|$)",
                "model": "Halo Plus"
            },
            {
                "regex": "Halo[ _]4[ _]mini[ _]LTE(?:[);/ ]|$)",
                "model": "Halo 4 Mini LTE"
            },
            {
                "regex": "Halo 4(?:[);/ ]|$)",
                "model": "Halo 4"
            },
            {
                "regex": "Halo2 3G(?:[);/ ]|$)",
                "model": "Halo 2 3G"
            },
            {
                "regex": "InnJoo[ _]Pro2(?:[);/ ]|$)",
                "model": "Pro 2"
            },
            {
                "regex": "Innjoo ONE 3G(?:[);/ ]|$)",
                "model": "One 3G"
            },
            {
                "regex": "Innjoo[ _]2[ _]LTE(?:[);/ ]|$)",
                "model": "2 LTE"
            },
            {
                "regex": "InnJoo[ _]([2-4])",
                "model": "$1"
            },
            {
                "regex": "Fire2 Plus LTE",
                "model": "Fire 2 Plus LTE"
            }
        ]
    },
    "Inkti": {
        "regex": "intki[ _]([^/;]*)[ _]Build",
        "device": "smartphone",
        "model": "$1"
    },
    "Innos": {
        "regex": "Innos_?(D6000)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Innostream": {
        "regex": "INNO([a-z0-9]+)",
        "device": "feature phone",
        "model": "INNO$1"
    },
    "Insignia": {
        "regex": "NS-(P11A8100|P10A8100|P10A6100|P16AT08|P16AT10|P16AT785HD|P08A7100|P10A7100)(?:[);/ ]|$)|AFTEAMR311|AFTJMST12",
        "device": "tablet",
        "models": [
            {
                "regex": "NS-P11A8100",
                "model": "11.6\""
            },
            {
                "regex": "NS-(P10A6100|P16AT10)",
                "model": "Flex 10.1\""
            },
            {
                "regex": "NS-P16AT08",
                "model": "Flex 8\""
            },
            {
                "regex": "NS-P08A7100",
                "model": "8\""
            },
            {
                "regex": "NS-(P10A8100|P10A7100)",
                "model": "10.1\""
            },
            {
                "regex": "NS-P16AT785HD",
                "model": "Flex Elite 7.85\""
            },
            {
                "regex": "AFTEAMR311",
                "model": "HD (2018)",
                "device": "tv"
            },
            {
                "regex": "AFTJMST12",
                "model": "4K (2018)",
                "device": "tv"
            }
        ]
    },
    "INQ": {
        "regex": "INQ[/ ]",
        "device": "feature phone",
        "models": [
            {
                "regex": "INQ/([a-z0-9\\-]+)",
                "model": "$1"
            },
            {
                "regex": "INQ ([^;/]+) Build",
                "model": "$1",
                "device": "smartphone"
            }
        ]
    },
    "Intex": {
        "regex": "Intex|(Aqua|Cloud[ _][XY][0-9]{1}|Cloud (?:Fame|Glory) 4G)|IM0318ND(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "IM0318ND",
                "model": "Aqua Lions 3"
            },
            {
                "regex": "Intex[ _]([^/;]*)[ _]Build",
                "model": "$1"
            },
            {
                "regex": "(Cloud (?:Fame|Glory) 4G)",
                "model": "$1"
            },
            {
                "regex": "(Aqua|Cloud)[ _\\.]([^/;\\)]+)(?:[ _]Build|(?<!Build)/|\\))",
                "model": "$1 $2"
            },
            {
                "regex": "Intex[ _]([a-z0-9_+\\-]+)",
                "model": "$1"
            }
        ]
    },
    "i-mate": {
        "regex": "i-mate ([a-z0-9]+)",
        "device": "feature phone",
        "model": "$1"
    },
    "i-mobile": {
        "regex": "i-mobile ?[a-z0-9]+|i-Style|IQ ?(9\\.1|5\\.5|5\\.6A?)",
        "device": "feature phone",
        "models": [
            {
                "regex": "i-mobile (i-note[^/;]*) Build",
                "model": "$1",
                "device": "tablet"
            },
            {
                "regex": "i-Style ?([^;/]+) Build",
                "model": "i-Style $1",
                "device": "smartphone"
            },
            {
                "regex": "IQ ?(9\\.1|5\\.5|5\\.6A?)",
                "model": "IQ $1",
                "device": "smartphone"
            },
            {
                "regex": "i-mobile (IQ[^/;]*) Build",
                "model": "$1",
                "device": "smartphone"
            },
            {
                "regex": "i-mobile(?: )?([a-z0-9\\- ]+) Build",
                "model": "$1"
            },
            {
                "regex": "i-mobile(?: )?([a-z0-9]+)",
                "model": "$1"
            }
        ]
    },
    "iKoMo": {
        "regex": "iKoMo ([a-z0-9]+)",
        "device": "feature phone",
        "model": "$1"
    },
    "iOcean": {
        "regex": "iOCEAN|M6752|W180|X7[ _]?S|X8 mini",
        "device": "smartphone",
        "models": [
            {
                "regex": "X7S-T",
                "model": "X7S-T"
            },
            {
                "regex": "X8 mini pro",
                "model": "X8 Mini Pro"
            },
            {
                "regex": "X8 mini",
                "model": "X8 Mini"
            },
            {
                "regex": "X7[ _]?S",
                "model": "X7S Elite"
            },
            {
                "regex": "(M6752|W180) Build",
                "model": "$1"
            },
            {
                "regex": "iOCEAN[ _\\-]?([^/;]+) Build",
                "model": "$1"
            },
            {
                "regex": "iOCEAN[ _\\-]?([^);/ ]+)",
                "model": "$1"
            }
        ]
    },
    "iView": {
        "regex": "(733TPC|730TPC|769TPCII|930TPC)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "Impression": {
        "regex": "(?:Impression[ _\\-]?([^/;]+)|A502|I10\\-LE|ImPad[ _]?(.*)|ImSmart[ _]?(.*))(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "(A502)",
                "model": "$1",
                "device": "smartphone"
            },
            {
                "regex": "ImSmart[ _]?([^/;)]+)(?: Build|\\))",
                "model": "ImSmart $1",
                "device": "smartphone"
            },
            {
                "regex": "ImPad[ _]?([^/;)]+)(?: Build|\\))",
                "model": "ImPad $1"
            },
            {
                "regex": "(I10\\-LE)",
                "model": "$1",
                "device": "smartphone"
            },
            {
                "regex": "Impression[ _\\-]?([^/;)]+)(?: Build|\\))",
                "model": "$1"
            }
        ]
    },
    "iLife": {
        "regex": "(ITELL[_ ](?:K3300|K3500N?|K4700))(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "iTel": {
        "regex": "itel|iNote|GP10X2019(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "W6502",
                "device": "phablet",
                "model": "S16"
            },
            {
                "regex": "W5505",
                "model": "A36"
            },
            {
                "regex": "W6002E?",
                "model": "S15"
            },
            {
                "regex": "L5502",
                "model": "A44 Air"
            },
            {
                "regex": "L5505",
                "model": "A47"
            },
            {
                "regex": "L6006",
                "model": "A48"
            },
            {
                "regex": "L6003P",
                "model": "A55 LTE"
            },
            {
                "regex": "L6002P",
                "model": "S15 Pro"
            },
            {
                "regex": "L5002",
                "model": "A22"
            },
            {
                "regex": "L5503",
                "model": "A46"
            },
            {
                "regex": "L6004",
                "model": "A56 LTE"
            },
            {
                "regex": "W6004P",
                "model": "A56 Pro"
            },
            {
                "regex": "W6001",
                "model": "P33 Plus"
            },
            {
                "regex": "W6003",
                "model": "A55"
            },
            {
                "regex": "L6005",
                "model": "Vision 1"
            },
            {
                "regex": "L6501",
                "model": "Vision 1 Plus"
            },
            {
                "regex": "L6502",
                "model": "Vision 1 Pro"
            },
            {
                "regex": "GP10X2019",
                "model": "GP10X2019"
            },
            {
                "regex": "iNote ([^/;]*)Build",
                "model": "iNote $1"
            },
            {
                "regex": "iNote_([a-z0-9\\-_]+)",
                "model": "iNote $1"
            },
            {
                "regex": "itel-it(1410|1512|1520)(?:[);/ ]|$)",
                "model": "it$1"
            },
            {
                "regex": "itel-it1553(?:[);/ ]|$)",
                "model": "Power Pro"
            },
            {
                "regex": "itel[- ](A14|A16 Plus|A44 Power|A23S|A52 Lite)",
                "model": "$1"
            },
            {
                "regex": "itel it1701",
                "device": "tablet",
                "model": "iNote Prime"
            },
            {
                "regex": "itel it170([23])",
                "device": "tablet",
                "model": "iNote Prime $1"
            },
            {
                "regex": "iTel ([^/;]*)Build",
                "model": "$1"
            },
            {
                "regex": "iTel[_ ]([a-z0-9\\-_]*)",
                "model": "$1"
            }
        ]
    },
    "iZotron": {
        "regex": "(Mi7_HERO_(?:BETA|TAB))(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "Mi7[_ ]HERO[_ ]BETA(?:[);/ ]|$)",
                "model": "MI7 Hero Beta"
            },
            {
                "regex": "Mi7[_ ]HERO[_ ]TAB(?:[);/ ]|$)",
                "model": "MI7 Hero Tab"
            }
        ]
    },
    "JAY-Tech": {
        "regex": "TPC-[A-Z0-9]+|TPCY-TX[TE][^);/ ]+",
        "device": "tablet",
        "models": [
            {
                "regex": "TPC-([A-Z0-9]+)",
                "model": "Tablet PC $1"
            },
            {
                "regex": "(TPCY-TX[TE][^);/ ]+)",
                "model": "$1"
            }
        ]
    },
    "Jiayu": {
        "regex": "JIAYU[ _-]|(?:JY-G4[\\\\_]G5|(?:JY-[a-z0-9]+)|JY_G4C)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(?:JIAYU G4_G5|JY-G4[\\\\_]G5)(?:[);/ ]|$)",
                "model": "G4/G5"
            },
            {
                "regex": "JIAYU[ _-](G4[S]?|G2F|G3[S]?|S[23])(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "JY[-_]([a-z0-9]+|G4C)(?:[);/ ]|$)",
                "model": "JY-$1"
            }
        ]
    },
    "Jolla": {
        "regex": "Jolla",
        "device": "smartphone",
        "model": ""
    },
    "Just5": {
        "regex": "JUST5(-)?SPACER(s)?|COSMO[_ ](L707|L808)|BLASTER|FREEDOM[ _](C100|M303|X1)|Freedom",
        "device": "smartphone",
        "models": [
            {
                "regex": "COSMO[_ ](L707|L808)",
                "model": "Cosmo $1"
            },
            {
                "regex": "FREEDOM[ _](C100|M303|X1)",
                "model": "Freedom $1"
            },
            {
                "regex": "Freedom",
                "model": "Freedom"
            },
            {
                "regex": "JUST5(-)?SPACER2s",
                "model": "Spacer 2S"
            },
            {
                "regex": "JUST5(-)?SPACER2",
                "model": "Spacer 2"
            },
            {
                "regex": "JUST5SPACER",
                "model": "Spacer"
            },
            {
                "regex": "BLASTER 2",
                "model": "Blaster 2"
            },
            {
                "regex": "BLASTER",
                "model": "Blaster"
            }
        ]
    },
    "Kalley": {
        "regex": "KALLEY|(?:ELEMENT (?:MAX|Q)|ELEMENT_PRO_2|K-BOOK(?:8MG|73G))(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "ELEMENT MAX",
                "model": "Element Max"
            },
            {
                "regex": "ELEMENT Q",
                "model": "Element Q"
            },
            {
                "regex": "ELEMENT_PRO_2",
                "model": "Element Pro 2"
            },
            {
                "regex": "K-BOOK(8MG|73G)",
                "device": "tablet",
                "model": "K-BOOK$1"
            },
            {
                "regex": "KALLEY,(55D1240)",
                "device": "tv",
                "model": "$1"
            }
        ]
    },
    "Kaan": {
        "regex": "KAAN[ _](N[12]|A1)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Kazam": {
        "regex": "Kazam|Trooper_X[0-9][0-9](?:[);/ ]|$)|Tornado 348|TROOPER 4\\.0",
        "device": "smartphone",
        "models": [
            {
                "regex": "Tornado 348",
                "model": "Tornado 348"
            },
            {
                "regex": "Thunder 450W",
                "model": "Thunder 450W"
            },
            {
                "regex": "TROOPER 4\\.0",
                "model": "Trooper X4.0"
            },
            {
                "regex": "Trooper_X([0-9])([0-9])(?:[);/ ]|$)",
                "model": "Trooper X$1.$2"
            },
            {
                "regex": "Kazam[; _]([^;/)]+)(?: Build|\\))",
                "model": "$1"
            }
        ]
    },
    "Keneksi": {
        "regex": "KENEKSI|(?:Hemera|Teta Dual|Helios)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(Hemera|Teta Dual|Helios)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "KENEKSI[ _-]liberty",
                "model": "Liberty"
            },
            {
                "regex": "KENEKSI[ _-]FLASH",
                "model": "Flash"
            },
            {
                "regex": "KENEKSI[ _-]SUN",
                "model": "Sun"
            },
            {
                "regex": "KENEKSI[ _-]ZETA",
                "model": "Zeta"
            },
            {
                "regex": "KENEKSI[ _-]SIGMA",
                "model": "Sigma"
            },
            {
                "regex": "KENEKSI[ _-](Moon|Libra_Dual|Glass|Amber|Chance|Amulet|Choice|Crystal|Dream|Delta[_ ]Dual|Norma 2|Norma|Sky|Fire 2|Fire|Flame|Flora|Rock|Solo|Soul|Step|Smart|Star|Storm|Wind)",
                "model": "$1"
            }
        ]
    },
    "Kempler & Strauss": {
        "regex": "Alumini_?3_?Plus|Alumini ?[23]|KEMPLER_(?:P1|TV|X|8_PLUS)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Alumini ?2",
                "model": "Alumini 2"
            },
            {
                "regex": "Alumini_?3_?Plus",
                "model": "Alumini 3 Plus"
            },
            {
                "regex": "Alumini3",
                "model": "Alumini 3"
            },
            {
                "regex": "KEMPLER_P1",
                "model": "P1"
            },
            {
                "regex": "KEMPLER_TV",
                "model": "TV"
            },
            {
                "regex": "KEMPLER_X",
                "model": "X"
            },
            {
                "regex": "KEMPLER_8_PLUS",
                "model": "8 Plus"
            }
        ]
    },
    "Kiano": {
        "regex": "Kiano|Elegance_[45]_[015](?:_PRO)?|Elegance 6|CORE 10.1 DUAL 3G|Intelect ?(7|8|10)|Slim ?Tab ?\\d+(?:[_ ]3GR?)?(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Slim ?Tab ?(\\d+)(?:[_ ]3GR?)?",
                "model": "Slim Tab $1",
                "device": "tablet"
            },
            {
                "regex": "CORE 10.1 DUAL 3G",
                "model": "CORE 10.1 DUAL 3G",
                "device": "tablet"
            },
            {
                "regex": "Elegance_(5)_([51])_Pro",
                "model": "Elegance $1.$2\" Pro"
            },
            {
                "regex": "Elegance_([45])_([015])",
                "model": "Elegance $1.$2\""
            },
            {
                "regex": "Elegance 6",
                "model": "Elegance 6 5.85\""
            },
            {
                "regex": "(Kiano)?(Intelect|Elegance) ?(7|8|10)?",
                "model": "$2 $3",
                "device": "tablet"
            },
            {
                "regex": "Kiano[ _]([^;/]+) Build",
                "model": "$1"
            }
        ]
    },
    "Kingsun": {
        "regex": "Kingsun[ _-]",
        "device": "smartphone",
        "models": [
            {
                "regex": "Kingsun[ _-]([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "Kingsun[ -]((?!Build)[a-z0-9_-]+)",
                "model": "$1"
            }
        ]
    },
    "Kocaso": {
        "regex": "Kocaso|M(?:729|7[357][026][hw]?|76[01236][bw]?|83[016]|8[567]0|1050s|106[012368]w?|1070|X736(?:-kit)?|X780)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "M729(?:[);/ ]|$)",
                "model": "M729"
            },
            {
                "regex": "M(7[357][026])[hw]?(?:[);/ ]|$)",
                "model": "M$1"
            },
            {
                "regex": "M(76[01236])[bw]?(?:[);/ ]|$)",
                "model": "M$1"
            },
            {
                "regex": "MX780(?:[);/ ]|$)",
                "model": "MX780"
            },
            {
                "regex": "M(83[016])(?:[);/ ]|$)",
                "model": "M$1"
            },
            {
                "regex": "M(8[567]0)(?:[);/ ]|$)",
                "model": "M$1"
            },
            {
                "regex": "M851(?:[);/ ]|$)",
                "model": "M851"
            },
            {
                "regex": "M1050S(?:[);/ ]|$)",
                "model": "M1050s"
            },
            {
                "regex": "M(106[012368])W?(?:[);/ ]|$)",
                "model": "M$1"
            },
            {
                "regex": "M1070(?:[);/ ]|$)",
                "model": "M1070"
            },
            {
                "regex": "MX736(?:-kit)?(?:[);/ ]|$)",
                "model": "MX736"
            },
            {
                "regex": "MX780(?:[);/ ]|$)",
                "model": "MX780"
            },
            {
                "regex": "Kocaso[ _\\-]?([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "Kocaso[ _\\-]?([a-z0-9_-]+)",
                "model": "$1"
            }
        ]
    },
    "Kogan": {
        "regex": "Kogan",
        "device": "smartphone",
        "models": [
            {
                "regex": "Kogan[ _-]([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "Kogan[ _-]([a-z0-9_-]+)",
                "model": "$1"
            }
        ]
    },
    "Komu": {
        "regex": "Komu[ -]",
        "device": "smartphone",
        "models": [
            {
                "regex": "Komu[ -]([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "Komu[ -]([a-z0-9_-]+)",
                "model": "$1"
            }
        ]
    },
    "Koobee": {
        "regex": "Koobee",
        "device": "smartphone",
        "models": [
            {
                "regex": "Koobee[ _\\-]?([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "Koobee[ _\\-]?([a-z0-9_-]+)",
                "model": "$1"
            }
        ]
    },
    "Kumai": {
        "regex": "(?:KM-)?Kumai",
        "device": "smartphone",
        "models": [
            {
                "regex": "(?:KM-)?Kumai[ _\\-]?([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "(?:KM-)?Kumai[ _\\-]?([a-z0-9_-]+)",
                "model": "$1"
            }
        ]
    },
    "KT-Tech": {
        "regex": "(KM-[a-z0-9]+|EV-[a-z0-9]+)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "KDDI": {
        "regex": "kddi-([a-z0-9]+)",
        "device": "feature phone",
        "model": "$1"
    },
    "Kodak": {
        "regex": "KODAK|(?:IM5|Ektra|Smartway_T1)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "IM5",
                "model": "IM5"
            },
            {
                "regex": "Ektra(?:[);/ ]|$)",
                "model": "Ektra"
            },
            {
                "regex": "(?:KODAK[_ ])?SMARTWAY[_ ]([MTX]1)",
                "model": "Smartway $1"
            },
            {
                "regex": "Tablet (7|10)",
                "device": "tablet",
                "model": "Tablet $1"
            }
        ]
    },
    "KOPO": {
        "regex": "KOPO[ _-]",
        "device": "smartphone",
        "models": [
            {
                "regex": "KOPO[ _\\-]?([^/;]+) Build",
                "model": "$1"
            },
            {
                "regex": "KOPO[ _\\-]?([^);/ ]+)",
                "model": "$1"
            }
        ]
    },
    "Koridy": {
        "regex": "KORIDY[ _-]([^/;]+) Build",
        "device": "smartphone",
        "model": "$1"
    },
    "KRONO": {
        "regex": "NET1100|NET_(?:TITAN|VOLT)",
        "device": "smartphone",
        "models": [
            {
                "regex": "NET1100",
                "model": "NET 1100"
            },
            {
                "regex": "NET_(TITAN|VOLT)",
                "model": "NET $1"
            }
        ]
    },
    "K-Touch": {
        "regex": "K-?Touch[ _][a-z0-9]+",
        "device": "smartphone",
        "models": [
            {
                "regex": "K-?Touch[ _]([^/;]*)[ _]Build",
                "model": "$1"
            },
            {
                "regex": "K-?Touch[ _]([a-z0-9]+)",
                "model": "$1"
            }
        ]
    },
    "Diva": {
        "regex": "(QC-(?:703GN|704GA|803GN|1013G|7BHD))(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "Kyocera": {
        "regex": "Kyocera|KWC-|QC-[0-9]{4}|(?:QC6035|NP501KC|302KC|503KC|602KC|705KC|901KC|C51[27]0|C5155|C5215|C67[35]0|C6522N?|C6530N|C6740N?|C6743|E6[89]10|KYL2[13]|KYV3[13-9]|KYT3[123]|KYF3[123579]|KYF40|KYX31|KYG01|KYY2[1-4]|S2151|KC-S701|S[468]-KC|KYV40U?|KYV4[1-8]|KYV4[47]|KC-S301AE|X3-KC|E6560[CT]|E6782[L]?|E6790(?:TM)?|[47]04KC|A001KC|WX10K|URBANO PROGRESSO|E7110)(?:[-_]u2?)?(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "C5120(?:[);/ ]|$)",
                "model": "Milano"
            },
            {
                "regex": "S4-KC(?:[);/ ]|$)",
                "model": "One S4"
            },
            {
                "regex": "S6-KC(?:[);/ ]|$)",
                "model": "One S6"
            },
            {
                "regex": "S8-KC(?:[);/ ]|$)",
                "model": "One S8"
            },
            {
                "regex": "C5155(?:[);/ ]|$)",
                "model": "Rise"
            },
            {
                "regex": "602KC(?:[);/ ]|$)",
                "model": "Digno G"
            },
            {
                "regex": "503KC(?:[);/ ]|$)",
                "model": "Digno E"
            },
            {
                "regex": "404KC(?:[);/ ]|$)",
                "model": "Digno C"
            },
            {
                "regex": "704KC(?:[);/ ]|$)",
                "model": "Digno J"
            },
            {
                "regex": "302KC(?:[);/ ]|$)",
                "model": "Digno T"
            },
            {
                "regex": "NP501KC(?:[);/ ]|$)",
                "model": "Digno"
            },
            {
                "regex": "WX10K(?:[);/ ]|$)",
                "model": "Digno Dual 2"
            },
            {
                "regex": "KYV36(?:[);/ ]|$)",
                "model": "Digno Rafre"
            },
            {
                "regex": "901KC(?:[);/ ]|$)",
                "model": "Digno BX"
            },
            {
                "regex": "KYV39(?:[);/ ]|$)",
                "model": "Miraie F"
            },
            {
                "regex": "KYX31(?:[);/ ]|$)",
                "model": "Infobar XV"
            },
            {
                "regex": "C5170(?:[);/ ]|$)",
                "model": "Hydro"
            },
            {
                "regex": "C5215(?:[);/ ]|$)",
                "model": "Hydro EDGE"
            },
            {
                "regex": "C6522N?",
                "model": "Hydro XTRM"
            },
            {
                "regex": "C6730",
                "model": "Hydro ICON"
            },
            {
                "regex": "C6750",
                "model": "Hydro ELITE"
            },
            {
                "regex": "C6530N",
                "model": "Hydro LIFE"
            },
            {
                "regex": "C6740(N)?",
                "model": "Hydro WAVE"
            },
            {
                "regex": "C6743",
                "model": "Hydro Reach"
            },
            {
                "regex": "E6560[CT]",
                "model": "DuraForce"
            },
            {
                "regex": "E6810",
                "model": "DuraForce Pro"
            },
            {
                "regex": "E6910",
                "model": "DuraForce Pro 2"
            },
            {
                "regex": "E7110",
                "model": "DuraForce Ultra 5G"
            },
            {
                "regex": "KC-S701",
                "model": "Torque"
            },
            {
                "regex": "KYG01",
                "model": "Torque 5G"
            },
            {
                "regex": "KYL21(?:[);/ ]|$)",
                "model": "Digno S"
            },
            {
                "regex": "KYL23(?:[);/ ]|$)",
                "model": "Miraie"
            },
            {
                "regex": "KYY24(?:[);/ ]|$)",
                "model": "Torque G01"
            },
            {
                "regex": "KYF32(?:[);/ ]|$)",
                "model": "Easy Mobile Phone"
            },
            {
                "regex": "KYF35(?:[);/ ]|$)",
                "model": "Marvera"
            },
            {
                "regex": "KYF40(?:[);/ ]|$)",
                "model": "Mamorino 5"
            },
            {
                "regex": "KYF3[179](?:[);/ ]|$)",
                "model": "Gratina 4G"
            },
            {
                "regex": "KYV48(?:[);/ ]|$)",
                "model": "Gratina KYV48"
            },
            {
                "regex": "KYF33(?:[);/ ]|$)",
                "model": "Torque X01"
            },
            {
                "regex": "KYV35(?:[);/ ]|$)",
                "model": "Torque G02"
            },
            {
                "regex": "KYV41(?:[);/ ]|$)",
                "model": "Torque G03"
            },
            {
                "regex": "KYV46(?:[);/ ]|$)",
                "model": "Torque G04"
            },
            {
                "regex": "KYV47(?:-u)?(?:[);/ ]|$)",
                "model": "Basio 4"
            },
            {
                "regex": "KYV43(?:[);/ ]|$)",
                "model": "Basio 3"
            },
            {
                "regex": "705KC(?:[);/ ]|$)",
                "model": "Kantan Sumaho"
            },
            {
                "regex": "KYV37(?:[);/ ]|$)",
                "model": "Qua Phone"
            },
            {
                "regex": "KYV42(?:_u)?(?:[);/ ]|$)",
                "model": "Qua Phone QX"
            },
            {
                "regex": "KYV44(?:_u2?)?(?:[);/ ]|$)",
                "model": "Qua Phone QZ"
            },
            {
                "regex": "KYT33(?:[);/ ]|$)",
                "model": "Qua Tab QZ10",
                "device": "tablet"
            },
            {
                "regex": "KYT32(?:[);/ ]|$)",
                "model": "Qua Tab QZ8",
                "device": "tablet"
            },
            {
                "regex": "KYT31(?:[);/ ]|$)",
                "model": "Qua Tab 01",
                "device": "tablet"
            },
            {
                "regex": "KYV40U?(?:[);/ ]|$)",
                "model": "Rafre"
            },
            {
                "regex": "KYV33(?:[);/ ]|$)",
                "model": "Infobar A03"
            },
            {
                "regex": "KYY21(?:[);/ ]|$)",
                "model": "Urbano L01"
            },
            {
                "regex": "KYY22(?:[);/ ]|$)",
                "model": "Urbano L02"
            },
            {
                "regex": "KYY23(?:[);/ ]|$)",
                "model": "Urbano L03"
            },
            {
                "regex": "KYV31(?:[);/ ]|$)",
                "model": "Urbano V01"
            },
            {
                "regex": "KYV34(?:[);/ ]|$)",
                "model": "Urbano V02"
            },
            {
                "regex": "KYV(?:45|38)(?:[);/ ]|$)",
                "model": "Urbano V03"
            },
            {
                "regex": "(?:Kyocera-)?E6790(?:TM)?",
                "model": "DuraForce XD"
            },
            {
                "regex": "X3-KC(?:[);/ ]|$)",
                "model": "One X3"
            },
            {
                "regex": "E6782(?:[);/ ]|$)",
                "model": "Brigadier"
            },
            {
                "regex": "E6782L(?:[);/ ]|$)",
                "model": "DuraScout"
            },
            {
                "regex": "(KC-S301AE)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "A001KC(?:[);/ ]|$)",
                "model": "Katan Sumaho 2"
            },
            {
                "regex": "URBANO PROGRESSO(?:[);/ ]|$)",
                "model": "Urbano Progresso"
            },
            {
                "regex": "S2151",
                "model": "Coast",
                "device": "feature phone"
            },
            {
                "regex": "Kyocera-KZ-([a-z0-9]+)",
                "model": "KZ $1"
            },
            {
                "regex": "Kyocera(?:[\\-/])?([a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "(KWC|QC)-?([a-z0-9]+)",
                "model": "$1-$2"
            }
        ]
    },
    "Krüger&Matz": {
        "regex": "Kruger[ _&]Matz|KrugerMatz[ _]|FLOW ?5PLUS|FLOW_5|FLOW7|FLOW6(?:S|Lite)|DRIVE[_ ]6S?|MOVE8mini|MOVE_|LIVE_6plus|LIVE 5 PLUS|LIVE4_KM043[89]|LIVE 7S|KM(?:106[67]|1065G|0701_1|0702|0805_1)",
        "device": "smartphone",
        "models": [
            {
                "regex": "MOVE8mini",
                "model": "MOVE 8 Mini"
            },
            {
                "regex": "MOVE_6_mini",
                "model": "MOVE 6 Mini"
            },
            {
                "regex": "MOVE[_ ]([78]|6S)",
                "model": "MOVE $1"
            },
            {
                "regex": "DRIVE 4 mini LTE",
                "model": "DRIVE 4 mini LTE"
            },
            {
                "regex": "DRIVE[ _]([456][S]?)(?:[);/ ]|$)",
                "model": "DRIVE $1"
            },
            {
                "regex": "FLOW ?5PLUS(?:[);/ ]|$)",
                "model": "FLOW 5 Plus"
            },
            {
                "regex": "FLOW6Lite(?:[);/ ]|$)",
                "model": "FLOW 6 Lite"
            },
            {
                "regex": "FLOW[ _]?([4-7][S]?)(?:[);/ ]|$)",
                "model": "FLOW $1"
            },
            {
                "regex": "LIVE4_(KM043[89])(?:[);/ ]|$)",
                "model": "LIVE 4 $1"
            },
            {
                "regex": "KrugerMatz_LIVE4(?:[);/ ]|$)",
                "model": "LIVE 4"
            },
            {
                "regex": "_LIVE5_(KM0450)(?:[);/ ]|$)",
                "model": "LIVE 5 $1"
            },
            {
                "regex": "LIVE 5 PLUS(?:[);/ ]|$)",
                "model": "LIVE 5 Plus"
            },
            {
                "regex": "LIVE_6plus(?:[);/ ]|$)",
                "model": "LIVE 6 Plus"
            },
            {
                "regex": "LIVE 7S(?:[);/ ]|$)",
                "model": "LIVE 7S"
            },
            {
                "regex": "KM(106[67])(?:[);/ ]|$)",
                "device": "tablet",
                "model": "EAGLE $1 10.1\""
            },
            {
                "regex": "KM(1065G)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "EAGLE $1 10.1\""
            },
            {
                "regex": "KM0(701)_1(?:[);/ ]|$)",
                "device": "tablet",
                "model": "EAGLE $1 7.1\""
            },
            {
                "regex": "KM0(805)_1(?:[);/ ]|$)",
                "device": "tablet",
                "model": "EAGLE $1 8.0\""
            },
            {
                "regex": "KM0(702)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "EAGLE $1 7.0\""
            }
        ]
    },
    "LAIQ": {
        "regex": "LAIQ",
        "device": "smartphone",
        "models": [
            {
                "regex": "(Glam|New York|Dubai)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "AIS": {
        "regex": "iris708|Kingcomm C500",
        "device": "smartphone",
        "models": [
            {
                "regex": "iris708",
                "model": "LAVA PRO 4.5"
            },
            {
                "regex": "Kingcomm C500",
                "model": "Super Smart Plus X3"
            }
        ]
    },
    "Lava": {
        "regex": "Lava[ _]|LAVAA1|(?:DISCOVER136|KKT QUADRA)|iris[ _]?([^/;]+)(?:\\)| Build)|A(?:67|76) Build|X1 Selfie Build|X41 Plus Build|(?:Flair Z1|PixelV1|Pixel V2|Z61_2GB|A97 IPS|A97 2GB PLUS|L[EH]9920(?:[_ ]P)?|L[NEH]9910|LH9810|Ivory(?:S1|S4G)?|A76Plus)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Flair Z1(?:[);/ ]|$)",
                "model": "Flair Z1"
            },
            {
                "regex": "A97 IPS(?:[);/ ]|$)",
                "model": "A97 IPS"
            },
            {
                "regex": "A97 2GB PLUS(?:[);/ ]|$)",
                "model": "A97 2GB Plus"
            },
            {
                "regex": "A76Plus(?:[);/ ]|$)",
                "model": "A76 Plus"
            },
            {
                "regex": "Pixel ?V([12])(?:[);/ ]|$)",
                "model": "Pixel V$1"
            },
            {
                "regex": "X1 Selfie(?:[);/ ]|$)",
                "model": "Iris X1 Selfie"
            },
            {
                "regex": "X41 Plus(?:[);/ ]|$)",
                "model": "X41 Plus"
            },
            {
                "regex": "(?:LAVA[_ ]?)?A([13]|67|76)(?:[);/ ]|$)",
                "model": "A$1"
            },
            {
                "regex": "LAVA_R1",
                "model": "R1"
            },
            {
                "regex": "Z61_2GB(?:[);/ ]|$)",
                "model": "Z61"
            },
            {
                "regex": "LE9920[ _]P(?:[);/ ]|$)",
                "model": "V7"
            },
            {
                "regex": "LE9920(?:[);/ ]|$)",
                "model": "Z53"
            },
            {
                "regex": "LH9910(?:[);/ ]|$)",
                "model": "Z52"
            },
            {
                "regex": "LH9810(?:[);/ ]|$)",
                "model": "Iris 52"
            },
            {
                "regex": "LN9910(?:[);/ ]|$)",
                "model": "Z41"
            },
            {
                "regex": "LE9910(?:[);/ ]|$)",
                "model": "V8"
            },
            {
                "regex": "LH9920(?:[);/ ]|$)",
                "model": "V+6"
            },
            {
                "regex": "DISCOVER136",
                "device": "feature phone",
                "model": "Discover 136"
            },
            {
                "regex": "KKT QUADRA",
                "device": "feature phone",
                "model": "KKT Quadra"
            },
            {
                "regex": "KKT40[ ]?Power[P\\+]",
                "device": "feature phone",
                "model": "KKT40 Power Plus"
            },
            {
                "regex": "Ivory(S1|S4G)?(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Ivory $1"
            },
            {
                "regex": "iris[ _]?([^/;]+)(?:\\)| Build)",
                "model": "Iris $1"
            },
            {
                "regex": "Lava[ _]?([^/;]+)(?:/| Build)",
                "model": "$1"
            }
        ]
    },
    "SKG": {
        "regex": "(Modern[ _](?:S10?|S[3679]))(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Landvo": {
        "regex": "XM[123]00|(?<!Blade )S6 Build",
        "device": "smartphone",
        "models": [
            {
                "regex": "S6 Build",
                "model": "S6"
            },
            {
                "regex": "XM([123])00([^/;]*) Build",
                "model": "XM$100$2"
            }
        ]
    },
    "Land Rover": {
        "regex": "Land[ ]?Rover[d]?[ _-](?:X1[0-9]|Explore|T878|L15\\+|V18|V[168]|X)(?:[);/ ]|$)|Polar3",
        "device": "smartphone",
        "models": [
            {
                "regex": "Land[ ]?Rover[d]?[ _-](X1[0-9]|Explore|T878|L15\\+|V18|V[168]|X)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "Polar3",
                "model": "Ioutdoor Polar 3"
            }
        ]
    },
    "Lanix": {
        "regex": "LANIX-([a-z0-9]+)|Ilium[ _]|IliumPad|X120C|Alpha 950",
        "device": "smartphone",
        "models": [
            {
                "regex": "ILIUM[ _]?PAD[ _]?([^;/]+)(?: Build|[;)])",
                "model": "Ilium Pad $1",
                "device": "tablet"
            },
            {
                "regex": "X120C",
                "model": "Ilium X120C"
            },
            {
                "regex": "Ilium[ _](Alpha [15]s|Alpha [379]|L(?:200|6[12]0|9[0125]0|1[0124]00|1050|1120)|LT5[012]0|M[13579]|S(?:106|520|6[27]0)|X(?:1[12]0|2[0126]0|5[12]0|500B|710)|M[579]s)(?:[);/ ]|$)",
                "model": "Ilium $1"
            },
            {
                "regex": "ILIUM[ _]([^;/]+) Build",
                "model": "Ilium $1"
            },
            {
                "regex": "Alpha 950",
                "model": "Ilium Alpha 950"
            },
            {
                "regex": "LANIX-([a-z0-9]+)",
                "device": "feature phone",
                "model": "$1"
            }
        ]
    },
    "Lark": {
        "regex": "(Lark|Cumulus|Evolution|FreeMe|Ultimate (?:X4|7i))[ _-]|Build/Lark|Bjorn[_ ]SP-500|FreeBird 5WiFi",
        "device": "tablet",
        "models": [
            {
                "regex": "Cumulus[ _](5\\.5|[56])[ _]HD",
                "model": "Cumulus $1 HD",
                "device": "smartphone"
            },
            {
                "regex": "FreeMe X2 ([79]) (?:ver|v)?\\.([23])",
                "model": "FreeMe X2 $1 Version $2"
            },
            {
                "regex": "FreeMe X4 7 3G HD",
                "model": "FreeMe X4 7HD 3G"
            },
            {
                "regex": "FreeMe[ _]X2[ _]([7-9])",
                "model": "FreeMe X2 $1"
            },
            {
                "regex": "FreeMe X4[ _]7[_ ]HD",
                "model": "FreeMe X4 7HD"
            },
            {
                "regex": "FreeMe X4[ _]9",
                "model": "FreeMe X4 9"
            },
            {
                "regex": "FreeBird 5WiFi",
                "model": "FreeBird 5 WiFi"
            },
            {
                "regex": "(?:Lark[ _-])?Evolution[ _](X2 7 3G-GPS|X4[_ ]10\\.1|10|X4[_ ]7[ _]IPS)",
                "model": "Evolution $1"
            },
            {
                "regex": "Ul(?:ti|it)mate (X4 10\\.1 3G IPS|X4 8s 3G|7i)",
                "model": "Ultimate $1"
            },
            {
                "regex": "PHABLET 7",
                "model": "Phablet 7"
            },
            {
                "regex": "Phablet 6\\.0",
                "model": "Phablet 6.0",
                "device": "smartphone"
            },
            {
                "regex": "Bjorn[_ ]SP-500",
                "model": "Bjorn SP-500",
                "device": "smartphone"
            },
            {
                "regex": "Cirrus[ _](4\\.5|[45])",
                "model": "Cirrus $1",
                "device": "smartphone"
            },
            {
                "regex": "Lark[ _](Stratus[_ ]5)",
                "model": "$1",
                "device": "smartphone"
            }
        ]
    },
    "LCT": {
        "regex": "LCT_([a-z0-9]+)",
        "device": "feature phone",
        "model": "$1"
    },
    "Le Pan": {
        "regex": "Le ?Pan|TC970 ([^;/]+)Build",
        "device": "tablet",
        "models": [
            {
                "regex": "TC802A",
                "model": "Le Pan Mini"
            },
            {
                "regex": "TC970 ([^;/]+)Build",
                "model": "TC970 $1"
            },
            {
                "regex": "Le ?Pan ?(II|S)(?:[);/ ]|$)",
                "model": "Le Pan $1"
            },
            {
                "regex": "Le ?Pan ?([^;/]+) Build",
                "model": "$1"
            }
        ]
    },
    "Leagoo": {
        "regex": "(?:MY)?LEAGOO[ _-]?|(?:M5 EDGE|KIICAA (POWER|MIX)|Leapad[ _](?:X|7s)|Elite [15]|Venture 1|Z10-E)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "LEAGOO[- _]M8[_-]?Pro(?:[);/ ]|$)",
                "model": "M8 Pro"
            },
            {
                "regex": "LEAGOO[- _](Z[156]|M[58]|T1)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "M5 EDGE",
                "model": "M5 Edge"
            },
            {
                "regex": "LEAGOO[_ ]Lead([57])",
                "model": "Lead $1"
            },
            {
                "regex": "KIICAA POWER",
                "model": "KICCAA Power"
            },
            {
                "regex": "KIICAA MIX",
                "model": "KICCAA Mix"
            },
            {
                "regex": "Elite ([15])(?:[);/ ]|$)",
                "model": "Elite $1"
            },
            {
                "regex": "Venture 1(?:[);/ ]|$)",
                "model": "Venture 1"
            },
            {
                "regex": "(?:LEAGOO[- _])?(Z10-E|M6)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "(?:My)?Leagoo[- _](E4)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "(?:Leapad[ _])(7s|10|X)",
                "model": "LeaPad $1",
                "device": "tablet"
            }
        ]
    },
    "Ledstar": {
        "regex": "(?:Ledstar[_ ])?(?:Trendy 5.0|Novus_Premium_6.0)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Trendy 5.0",
                "model": "Trendy 5.0"
            },
            {
                "regex": "Ledstar_Novus_Premium_6.0(?:[);/ ]|$)",
                "model": "Novus Premium 6.0\""
            }
        ]
    },
    "LeEco": {
        "regex": "Letv|LeEco|Le ?X[0-9][^;/]+|Le Max|Le [12]|Le2[_ ]x527|X900 Build|(?:X[89]00\\+|X52[67])(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "X800\\+",
                "model": "Le 1 Pro"
            },
            {
                "regex": "X900\\+",
                "model": "Max X900+"
            },
            {
                "regex": "X50[10]",
                "model": "Le 1S"
            },
            {
                "regex": "(X910)",
                "model": "$1"
            },
            {
                "regex": "X900 Build",
                "model": "Le X900"
            },
            {
                "regex": "(?:Le ?X526|Le2[_ ]x527|Le 2|X52[67])(?:[);/ ]|$)",
                "model": "Le 2"
            },
            {
                "regex": "Le ?X([^;/]+) Build",
                "model": "Le X$1"
            },
            {
                "regex": "Le ?X([^) ;/]+)",
                "model": "Le X$1"
            },
            {
                "regex": "Le ?([12][^;/]+) Build",
                "model": "Le $1"
            },
            {
                "regex": "Le Max([^;/]*) Build",
                "model": "Le Max$1"
            },
            {
                "regex": "Le Max([^ ;/]*)",
                "model": "Le Max$1"
            },
            {
                "regex": "LeEco ([^;/]+) Build",
                "model": "$1"
            }
        ]
    },
    "Leotec": {
        "regex": "LESPH501[14]|LESPH5003B|SUPERNOVA (Qi16|Qi32|i3G96[X]?)",
        "device": "smartphone",
        "models": [
            {
                "regex": "LESPH5011",
                "model": "Argon e250"
            },
            {
                "regex": "LESPH5003B",
                "model": "Argon A250B"
            },
            {
                "regex": "LESPH5014",
                "model": "Krypton K150"
            },
            {
                "regex": "SUPERNOVA (Qi16|Qi32|i3G96[X]?)",
                "device": "tablet",
                "model": "Supernova $1"
            }
        ]
    },
    "Lephone": {
        "regex": "lephone[ _]|lephoneP1",
        "device": "smartphone",
        "models": [
            {
                "regex": "lephone[ _](K7|K10)",
                "device": "feature phone",
                "model": "$1"
            },
            {
                "regex": "lephone(?:[ _])?(W(1[0125]|[279])|T2|P1)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "lephone_W7_plus",
                "model": "W7 Plus"
            }
        ]
    },
    "Lenco": {
        "regex": "Lenco ([^/;]*) Build",
        "device": "tablet",
        "model": "$1"
    },
    "Lenovo": {
        "regex": "(?:LNV-)?Lenovo|IdeaTab|IdeaPad|Thinkpad|Yoga Tablet|Tab2A[0-9]-[0-9]{2}[a-z]?|ZUK[ -_]|(?:TB2-X30L|TB3-710I|YT3-X50[ML]|K50a40|TB-(?:X103F|8504F|X304F|X704V|X704A)|A5500-H|A2107A-H|A3500-HV|A2109A|S6000|Z2 Plus|Vibe[ _](?:P1|S6i Plus|S[23567]i|C|[ZX]2|K5(?: Plus)?|V7)|P2A42|L38011|L18011|[5-8]01LV|[67]02LV|Z2131|K350t|K31-t3|TAB 2 A8-50LC|S90-A|P70-A|VR-1541F)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Lenovo ?G780",
                "model": "G780",
                "device": "desktop"
            },
            {
                "regex": "Lenovo ?A3500-FL",
                "model": "IdeaTab A7-40",
                "device": "tablet"
            },
            {
                "regex": "(?:Lenovo ?)?A3500-(?:H|HV|F)",
                "model": "IdeaTab A7-50",
                "device": "tablet"
            },
            {
                "regex": "(?:Lenovo[ _])?A5500-[HF]",
                "model": "IdeaTab A8-50",
                "device": "tablet"
            },
            {
                "regex": "Lenovo ?A7600-[HF]",
                "model": "IdeaTab A10-70",
                "device": "tablet"
            },
            {
                "regex": "A2107A-H",
                "model": "A2107",
                "device": "tablet"
            },
            {
                "regex": "A2109A",
                "model": "IdeaTab A2109A",
                "device": "tablet"
            },
            {
                "regex": "TB2-X30[FL]",
                "model": "Tab 2 A10-30",
                "device": "tablet"
            },
            {
                "regex": "YT3-X50[FLM]",
                "model": "Yoga Tab 3",
                "device": "tablet"
            },
            {
                "regex": "TB3-730X",
                "model": "TAB3 7",
                "device": "tablet"
            },
            {
                "regex": "TB3-710[IF]",
                "model": "TAB3 7 Essential",
                "device": "tablet"
            },
            {
                "regex": "TB-7703X",
                "model": "TAB3 7 Plus",
                "device": "tablet"
            },
            {
                "regex": "TB3-X70L",
                "model": "TAB3 10 Plus",
                "device": "tablet"
            },
            {
                "regex": "TB-8703X",
                "model": "TAB3 8 Plus",
                "device": "tablet"
            },
            {
                "regex": "TAB 2 A8-50LC",
                "model": "TAB2 A8-50LC",
                "device": "tablet"
            },
            {
                "regex": "501LV(?:[);/ ]|$)",
                "model": "TAB2 8.0\"",
                "device": "tablet"
            },
            {
                "regex": "TAB 2 A10-70L(?:[);/ ]|$)",
                "model": "TAB 2 A10",
                "device": "tablet"
            },
            {
                "regex": "(?:TB3-850[FM]|601LV)(?:[);/ ]|$)",
                "model": "TAB3 8",
                "device": "tablet"
            },
            {
                "regex": "602LV(?:[);/ ]|$)",
                "model": "TAB3 8 Dual",
                "device": "tablet"
            },
            {
                "regex": "TB-X304[FL]",
                "model": "TAB4 10",
                "device": "tablet"
            },
            {
                "regex": "TB-8504X",
                "model": "TAB4 8",
                "device": "tablet"
            },
            {
                "regex": "TB-8704X",
                "model": "TAB4 8 Plus",
                "device": "tablet"
            },
            {
                "regex": "(?:TB-X704[FVL]|70[12]LV)(?:[);/ ]|$)",
                "model": "TAB4 10 Plus",
                "device": "tablet"
            },
            {
                "regex": "801LV(?:[);/ ]|$)",
                "model": "TAB5 10.1\"",
                "device": "tablet"
            },
            {
                "regex": "TB-X103F(?:[);/ ]|$)",
                "model": "TAB10 10.1\"",
                "device": "tablet"
            },
            {
                "regex": "TB-X704A(?:[);/ ]|$)",
                "model": "Moto Tab",
                "device": "tablet"
            },
            {
                "regex": "TB-X605F(?:[);/ ]|$)",
                "model": "Tab M10",
                "device": "tablet"
            },
            {
                "regex": "TB-X705F(?:[);/ ]|$)",
                "model": "Tab P10",
                "device": "tablet"
            },
            {
                "regex": "TB-X705F(?:[);/ ]|$)",
                "model": "Tab P10",
                "device": "tablet"
            },
            {
                "regex": "TB-7104I(?:[);/ ]|$)",
                "model": "Tab E7",
                "device": "tablet"
            },
            {
                "regex": "TB-8304F1(?:[);/ ]|$)",
                "model": "Tab E8",
                "device": "tablet"
            },
            {
                "regex": "TB-7304X(?:[);/ ]|$)",
                "model": "Tab 7 Essential",
                "device": "tablet"
            },
            {
                "regex": "TB-7504X(?:[);/ ]|$)",
                "model": "Tab 7",
                "device": "tablet"
            },
            {
                "regex": "TB-X606[FXV](?:[);/ ]|$)",
                "model": "Tab M10 FHD Plus",
                "device": "tablet"
            },
            {
                "regex": "Lenovo ?B8000(-[FH])?",
                "model": "Yoga Tablet 10",
                "device": "tablet"
            },
            {
                "regex": "YOGA Tablet 2 Pro-1380[FL]",
                "model": "Yoga Tablet 2 Pro 13.3\"",
                "device": "tablet"
            },
            {
                "regex": "YOGA Tablet 2-1050[FL]",
                "model": "Yoga Tablet 2 10.1\"",
                "device": "tablet"
            },
            {
                "regex": "YOGA Tablet 2-830(?:[FL]|LC)",
                "model": "Yoga Tablet 2 8.0\"",
                "device": "tablet"
            },
            {
                "regex": "Lenovo ?B6000(-[FH])?",
                "model": "Yoga Tablet 8",
                "device": "tablet"
            },
            {
                "regex": "YT3-X90(L|F|X)",
                "model": "Yoga 3 Pro",
                "device": "tablet"
            },
            {
                "regex": "YT3-850[FL]",
                "model": "Yoga Tab 3 8",
                "device": "tablet"
            },
            {
                "regex": "YT3-850M",
                "model": "Yoga Tab 3 10",
                "device": "tablet"
            },
            {
                "regex": "YT-X703L",
                "model": "Yoga Tab 3 Plus",
                "device": "tablet"
            },
            {
                "regex": "S6000(?:[);/ ]|$)",
                "model": "IdeaTab S6000",
                "device": "tablet"
            },
            {
                "regex": "Lenovo ([ASB][0-9]{4})[A-Z]?\\-[EHF]",
                "model": "IdeaTab $1",
                "device": "tablet"
            },
            {
                "regex": "ThinkPadTablet_(A[0-9]{3})_",
                "model": "ThinkPad Tablet $1",
                "device": "tablet"
            },
            {
                "regex": "ThinkPad ?Tablet([^;/]*) Build",
                "model": "ThinkPad Tablet$1",
                "device": "tablet"
            },
            {
                "regex": "Lenovo ?A3300-(?:GV|H)",
                "model": "TAB 2 A7-30",
                "device": "tablet"
            },
            {
                "regex": "TAB 2 A7-30[HGD]C",
                "model": "TAB 2 A7",
                "device": "tablet"
            },
            {
                "regex": "TB-8504F",
                "model": "TAB 4 8",
                "device": "tablet"
            },
            {
                "regex": "TB-X304X",
                "model": "Tab 4 10.1\"",
                "device": "tablet"
            },
            {
                "regex": "TB-X304X",
                "model": "Tab 4 10.1\"",
                "device": "tablet"
            },
            {
                "regex": "TB-X304F",
                "model": "TAB4 10",
                "device": "tablet"
            },
            {
                "regex": "TB-X505[FX]",
                "model": "M10",
                "device": "tablet"
            },
            {
                "regex": "TB-X104[FXL]",
                "model": "E10",
                "device": "tablet"
            },
            {
                "regex": "Tab2A([0-9]-[0-9]{2}[a-z]?)",
                "model": "TAB 2 A$1",
                "device": "tablet"
            },
            {
                "regex": "IdeaTab[ \\-_]?([a-z0-9]+)",
                "model": "IdeaTab $1",
                "device": "tablet"
            },
            {
                "regex": "Yoga Tablet([^;/]+) Build",
                "model": "Yoga Tablet$1",
                "device": "tablet"
            },
            {
                "regex": "TB-8505F",
                "model": "Tab M8",
                "device": "tablet"
            },
            {
                "regex": "TB-8705F",
                "model": "Tab M8 FHD",
                "device": "tablet"
            },
            {
                "regex": "TB-8705F",
                "model": "Tab M8 FHD",
                "device": "tablet"
            },
            {
                "regex": "Lenovo (TB[0-9]?\\-[a-z0-9\\-]+) Build",
                "model": "$1",
                "device": "tablet"
            },
            {
                "regex": "L18011(?:[);/ ]|$)",
                "model": "A5"
            },
            {
                "regex": "Z2 Plus",
                "model": "Z2 Plus"
            },
            {
                "regex": "Z2151",
                "model": "ZUK Edge"
            },
            {
                "regex": "Z213[12]",
                "model": "ZUK Z2"
            },
            {
                "regex": "Z2121",
                "model": "ZUK Z2 Pro"
            },
            {
                "regex": "ZUK[ -_]([^/;]*)(?: Build|\\))",
                "model": "ZUK $1"
            },
            {
                "regex": "K10a40(?:[);/ ]|$)",
                "model": "C2"
            },
            {
                "regex": "k50a40(?:[);/ ]|$)",
                "model": "K3 Note"
            },
            {
                "regex": "A7010a48(?:[);/ ]|$)",
                "model": "K4 Note"
            },
            {
                "regex": "K350t(?:[);/ ]|$)",
                "model": "K5"
            },
            {
                "regex": "L38011(?:[);/ ]|$)",
                "model": "K5 Play"
            },
            {
                "regex": "A7020a4[08](?:[);/ ]|$)",
                "model": "K5 Note"
            },
            {
                "regex": "K31-t3(?:[);/ ]|$)",
                "model": "K31"
            },
            {
                "regex": "(?:A6020a46|Vibe K5 Plus)(?:[);/ ]|$)",
                "model": "Vibe K5 Plus"
            },
            {
                "regex": "(?:Lenovo-)?(?:A6020(?:a40|l36)|Vibe K5)(?:[);/ ]|$)",
                "model": "Vibe K5"
            },
            {
                "regex": "VIBE Z2(?:[);/ ]|$)",
                "model": "Vibe Z2"
            },
            {
                "regex": "VIBE C(?:[);/ ]|$)",
                "model": "Vibe C"
            },
            {
                "regex": "VIBE X2(?:[);/ ]|$)",
                "model": "Vibe X2"
            },
            {
                "regex": "VIBE V7(?:[);/ ]|$)",
                "model": "Vibe V7"
            },
            {
                "regex": "VIBE S6i Plus(?:[);/ ]|$)",
                "model": "Vibe S6i Plus"
            },
            {
                "regex": "VIBE[ _]S([23567])i(?:[);/ ]|$)",
                "model": "Vibe S$1i"
            },
            {
                "regex": "k33a48(?:[);/ ]|$)",
                "model": "K6"
            },
            {
                "regex": "P2A42(?:[);/ ]|$)",
                "model": "P2"
            },
            {
                "regex": "k53a48(?:[);/ ]|$)",
                "model": "K6 Note"
            },
            {
                "regex": "K33a42(?:[);/ ]|$)",
                "model": "K6 Power"
            },
            {
                "regex": "P1ma40(?:[);/ ]|$)",
                "model": "Vibe P1m"
            },
            {
                "regex": "Vibe P1 Turbo(?:[);/ ]|$)",
                "model": "Vibe P1 Turbo"
            },
            {
                "regex": "(?:P1(?:a42|c72)|Vibe P1)(?:[);/ ]|$)",
                "model": "Vibe P1"
            },
            {
                "regex": "A2016a40(?:[);/ ]|$)",
                "model": "Vibe B"
            },
            {
                "regex": "A1010a20(?:[);/ ]|$)",
                "model": "A Plus"
            },
            {
                "regex": "Z90a40(?:[);/ ]|$)",
                "model": "Vibe Shot"
            },
            {
                "regex": "S1a40(?:[);/ ]|$)",
                "model": "Vibe S1"
            },
            {
                "regex": "L78071(?:[);/ ]|$)",
                "model": "Z5s"
            },
            {
                "regex": "L58041(?:[);/ ]|$)",
                "model": "S5 Pro"
            },
            {
                "regex": "L18021(?:[);/ ]|$)",
                "model": "A3"
            },
            {
                "regex": "PB2-670M(?:[);/ ]|$)",
                "model": "Phab 2"
            },
            {
                "regex": "(S90-A|P70-A)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "IdeaPad ?([^;/]*) Build",
                "model": "IdeaPad $1",
                "device": "tablet"
            },
            {
                "regex": "VR-1541F",
                "device": "wearable",
                "model": "Mirage Solo"
            },
            {
                "regex": "LENOVO/81W8(?:[);/ ]|$)",
                "device": "desktop",
                "model": "IdeaPad S145-15IIL"
            },
            {
                "regex": "LENOVO/81MV(?:[);/ ]|$)",
                "device": "desktop",
                "model": "IdeaPad S145-15IWL"
            },
            {
                "regex": "LENOVO/81SS(?:[);/ ]|$)",
                "device": "desktop",
                "model": "Flex 14API 81SS"
            },
            {
                "regex": "Lenovo TAB ?([^/;]*) Build",
                "model": "TAB $1",
                "device": "tablet"
            },
            {
                "regex": "Lenovo ([^/;]*) Build",
                "model": "$1"
            },
            {
                "regex": "(?:LNV-|Lenovo-)?Lenovo[ \\-_]?([a-z0-9_+\\-]+)",
                "model": "$1"
            }
        ]
    },
    "Lexand": {
        "regex": "(?:SC7 PRO HD|S5A[1-4]|S4A[1-5]|S6A1|A811|A802)(?:[);/ ]|$)|Lexand ([^;/]+) Build",
        "device": "smartphone",
        "models": [
            {
                "regex": "A(811|802)",
                "device": "tablet",
                "model": "A$1"
            },
            {
                "regex": "S4A1",
                "model": "Vega"
            },
            {
                "regex": "S4A2",
                "model": "Irida"
            },
            {
                "regex": "S4A3",
                "model": "Pallada"
            },
            {
                "regex": "S4A5",
                "model": "Oxygen"
            },
            {
                "regex": "S4A4 NEON",
                "model": "S4A4 Neon"
            },
            {
                "regex": "S5A1",
                "model": "Callisto"
            },
            {
                "regex": "S5A2",
                "model": "Overon"
            },
            {
                "regex": "S5A3",
                "model": "Capella"
            },
            {
                "regex": "S5A4",
                "model": "Argon"
            },
            {
                "regex": "S6A1",
                "model": "Antares"
            },
            {
                "regex": "SC7 PRO HD",
                "model": "SC7 PRO HD"
            },
            {
                "regex": "Lexand ([^;/]+) Build",
                "model": "$1"
            }
        ]
    },
    "Lexibook": {
        "regex": "(MFC[0-9]{3}[a-z]{2,})",
        "device": "tablet",
        "models": [
            {
                "regex": "MFC191FR",
                "model": "Tablet Ultra 3 XL"
            },
            {
                "regex": "MFC045FR",
                "model": "TabTab"
            },
            {
                "regex": "MFC163FR",
                "model": "Tablet Master 3"
            },
            {
                "regex": "MFC142FR",
                "model": "Tablet Kids"
            },
            {
                "regex": "MFC250FR",
                "model": "Tablet Junior"
            },
            {
                "regex": "MFC270FR",
                "model": "Tablet Junior Power Touch"
            },
            {
                "regex": "MFC280FR",
                "model": "Tablet Junior 2"
            },
            {
                "regex": "MFC156FR",
                "model": "Tablet One"
            },
            {
                "regex": "MFC155FR",
                "model": "Tablet Master"
            },
            {
                "regex": "MFC157FR",
                "model": "Tablet Master 2"
            },
            {
                "regex": "MFC17[05]FR",
                "model": "Tablet Ultra"
            },
            {
                "regex": "MFC375FR",
                "model": "Tablet Ultra 2"
            },
            {
                "regex": "MFC162FR",
                "model": "Power Tablet"
            },
            {
                "regex": "MFC180FR",
                "model": "Tablet Advanced"
            },
            {
                "regex": "MFC181FR",
                "model": "Tablet Advanced 2"
            },
            {
                "regex": "MFC500FR",
                "model": "Tablet XL"
            },
            {
                "regex": "MFC190BBFR",
                "model": "Barbie Tablet"
            },
            {
                "regex": "MFC195DCFR",
                "model": "Tablet Disney Cars HD"
            },
            {
                "regex": "MFC195FUFR",
                "model": "Furby Tablet"
            },
            {
                "regex": "MFC195DPFR",
                "model": "Tablet Disney Princesse HD"
            },
            {
                "regex": "MFC140FR",
                "model": "LapTab"
            },
            {
                "regex": "MFC141FR",
                "model": "LapTab 2"
            },
            {
                "regex": "(MFC[0-9]{3}[a-z]{2,})",
                "model": "$1"
            }
        ]
    },
    "LG": {
        "regex": "LG|Web0S; Linux/SmartTV|portalmmm/2\\.0 (?:KE|KG|KP|L3)|(?:VX[0-9]+|L-0[12]D|L-07C|P713|(LM-[A-Z][0-9]{3}[A-Z]{0,3}(?:\\(FGN?\\)|\\(G\\)|\\.FN|\\.F(?:GN?)?)?)|LM-(X625N1|G820QM[0127]))(?:[);/ ]|$)|NetCast|RS98[78]|RS500|VS(?:50[01]|835|876|880|98[0578]|99[056]|(410|415|425|500|880)PP)|VS820|VS870 4G|Nexsus 5|LML(41[34]D|211B|713D|212V)L|VK810 4G|VK815|X5-LG|L-05[DE]|L-03K|L-01[LJK]|L-52A|L-[45]1A|901LG|A001LG|80[12]LG|402LG|LGT32|L-02K|L-01F|LM-V40|DM-01K|LMX130IM",
        "device": "smartphone",
        "models": [
            {
                "regex": "DM-01K",
                "model": "Disney Mobile DM-01K"
            },
            {
                "regex": "LG-D325",
                "model": "L70"
            },
            {
                "regex": "LG-D340F8",
                "model": "L70 Tri"
            },
            {
                "regex": "LM-X210APM",
                "model": "Phoenix 4"
            },
            {
                "regex": "LG-P505R",
                "model": "Phoenix"
            },
            {
                "regex": "LM-X210VPP",
                "model": "Zone 4"
            },
            {
                "regex": "LM-X220PM",
                "model": "Tribute Empire"
            },
            {
                "regex": "VS980|L-01F",
                "model": "G2"
            },
            {
                "regex": "LG-D6(?:18|20)",
                "model": "G2 Mini"
            },
            {
                "regex": "LG-D85[01258]|VS985",
                "model": "G3"
            },
            {
                "regex": "LG-D72[2458]",
                "model": "G3 Beat"
            },
            {
                "regex": "LG-H81[158]",
                "model": "G4"
            },
            {
                "regex": "LG-H525n?",
                "model": "G4c"
            },
            {
                "regex": "LG-(?:H540|H542|H630)",
                "model": "G4 Stylus"
            },
            {
                "regex": "LG-H736",
                "model": "G4s"
            },
            {
                "regex": "LG-H735",
                "model": "G4 Beat"
            },
            {
                "regex": "LG-H8(?:20|3[01]|5[08]|6[08])|VS987|RS988",
                "model": "G5"
            },
            {
                "regex": "LG-H84[05]",
                "model": "G5 SE"
            },
            {
                "regex": "VS988|LG-H87(?:0DS|3)",
                "model": "G6"
            },
            {
                "regex": "LM-Q850",
                "model": "G7 Fit"
            },
            {
                "regex": "LM-Q910",
                "model": "G7 One"
            },
            {
                "regex": "LM-G820(N|QM[0127]|UMB)?",
                "model": "G8"
            },
            {
                "regex": "LM-G850",
                "model": "G8X ThinQ"
            },
            {
                "regex": "LM-G810",
                "model": "G8S ThinQ"
            },
            {
                "regex": "901LG(?:[);/ ]|$)",
                "model": "G8X ThinQ"
            },
            {
                "regex": "VS501|LM-X120",
                "model": "K20"
            },
            {
                "regex": "(?:LG-)?H502",
                "model": "Magna"
            },
            {
                "regex": "(?:LG-)?H343",
                "model": "Risio"
            },
            {
                "regex": "(?:LG-)?H650",
                "model": "Zero"
            },
            {
                "regex": "(?:LG-)?M154",
                "model": "Risio 2"
            },
            {
                "regex": "(?:LG-)?P500H",
                "model": "Optimus One"
            },
            {
                "regex": "(L-05[DE])(?:[);/ ]|$)",
                "model": "Optimus it $1"
            },
            {
                "regex": "LG-L160L(?:[);/ ]|$)",
                "model": "Optimus LTE 2"
            },
            {
                "regex": "P713(?:[);/ ]|$)",
                "model": "Optimus L7II"
            },
            {
                "regex": "L-01D(?:[);/ ]|$)",
                "model": "Optimus LTE L-01D"
            },
            {
                "regex": "L-02D(?:[);/ ]|$)",
                "model": "PRADA phone L-02D"
            },
            {
                "regex": "L-07C",
                "model": "Optimus Bright L-07C"
            },
            {
                "regex": "(?:LG-)?F800(?:S|K|L)?",
                "model": "V20"
            },
            {
                "regex": "LM-Q710(?:\\(FGN\\)|[\\.]?FGN)",
                "model": "Q Stylus Plus"
            },
            {
                "regex": "801LG",
                "model": "Q Stylus"
            },
            {
                "regex": "LM-Q[67]10\\.FG(?:[);/ ]|$)",
                "model": "Q7"
            },
            {
                "regex": "LM-(Q725[KL]|Q610.FGN)(?:[);/ ]|$)",
                "model": "Q7+"
            },
            {
                "regex": "LM-Q815[SL](?:[);/ ]|$)",
                "model": "Q8+"
            },
            {
                "regex": "LM-Q925L(?:[);/ ]|$)",
                "model": "Q9"
            },
            {
                "regex": "LM-Q730N(?:[);/ ]|$)",
                "model": "Q70"
            },
            {
                "regex": "LGV33(?:[);/ ]|$)",
                "model": "Qua Phone PX"
            },
            {
                "regex": "L-03K(?:[);/ ]|$)",
                "model": "Style"
            },
            {
                "regex": "L-01L(?:[);/ ]|$)",
                "model": "Style 2"
            },
            {
                "regex": "VS835(?:[);/ ]|$)",
                "model": "Stylo 2 V"
            },
            {
                "regex": "L-41A(?:[);/ ]|$)",
                "model": "Style 3"
            },
            {
                "regex": "LM-Q710|LML713DL",
                "model": "Stylo 4"
            },
            {
                "regex": "(?:LM-Q720|LGL722DL)(?:[);/ ]|$)",
                "model": "Stylo 5"
            },
            {
                "regex": "LM-Q730(?:[);/ ]|$)",
                "model": "Stylo 6"
            },
            {
                "regex": "LM-G710(N|VM)?",
                "model": "G7 ThinQ"
            },
            {
                "regex": "LM-X210CM",
                "model": "Fortune 2"
            },
            {
                "regex": "LM-X410\\.F(?:[);/ ]|$)",
                "model": "Premier Pro"
            },
            {
                "regex": "LML41[34]DL(?:[);/ ]|$)",
                "model": "Premier Pro LTE"
            },
            {
                "regex": "LM-X525",
                "model": "Q60"
            },
            {
                "regex": "LM-X210K",
                "model": "X2"
            },
            {
                "regex": "LG-K580",
                "model": "X Cam"
            },
            {
                "regex": "LM-X510WM",
                "model": "X Power 3"
            },
            {
                "regex": "LM-X(?:41[05]K|410S)",
                "model": "X4"
            },
            {
                "regex": "LM-X(415[LS]|410L)",
                "model": "X4+"
            },
            {
                "regex": "LM-X410(?:\\.FN|\\(FG\\))",
                "model": "K11"
            },
            {
                "regex": "LM-X(?:320|410[UP]M)",
                "model": "K30"
            },
            {
                "regex": "LMX130IM",
                "model": "W10"
            },
            {
                "regex": "LM-X410",
                "model": "LM-X410"
            },
            {
                "regex": "LM-X420N",
                "model": "X4 (2019)"
            },
            {
                "regex": "LG-X230",
                "model": "K4"
            },
            {
                "regex": "LM-X420",
                "model": "K40"
            },
            {
                "regex": "LM-X430(?:[);/ ]|$)",
                "model": "K40S"
            },
            {
                "regex": "LG-X220",
                "model": "K5"
            },
            {
                "regex": "LM-X540",
                "model": "K50S"
            },
            {
                "regex": "(?:802LG|LM-X520)(?:[);/ ]|$)",
                "model": "K50"
            },
            {
                "regex": "402LG",
                "model": "Spray"
            },
            {
                "regex": "L-02K",
                "model": "JoJo"
            },
            {
                "regex": "(?:LM-X510[KLS]|X5-LG)",
                "model": "X5"
            },
            {
                "regex": "LM-X625N1",
                "model": "X6"
            },
            {
                "regex": "LM-X625N",
                "model": "X6 (2019)"
            },
            {
                "regex": "LGMS210",
                "model": "Aristo"
            },
            {
                "regex": "LM-X210[G]?",
                "model": "Aristo 2"
            },
            {
                "regex": "LM-X220",
                "model": "Aristo 3"
            },
            {
                "regex": "LM-V40(?:5|9N)?(?:[);/ ]|$)",
                "model": "V40 ThinQ"
            },
            {
                "regex": "LM-(?:V500N|V450)(?:[);/ ]|$)",
                "model": "V50 ThinQ"
            },
            {
                "regex": "LM-V510N",
                "model": "V50S ThinQ"
            },
            {
                "regex": "LM-Q920N",
                "model": "Q92"
            },
            {
                "regex": "LM-Q927L",
                "model": "Q9 One"
            },
            {
                "regex": "(?:LG-)?(?:RS987|H901|H96[0128]|VS990)",
                "model": "V10"
            },
            {
                "regex": "LG-X240",
                "model": "K8 (2017)"
            },
            {
                "regex": "(?:LG-)?RS500",
                "model": "K8 4G"
            },
            {
                "regex": "(?:LG-)?VS500",
                "model": "K8 V"
            },
            {
                "regex": "LM-X212\\(G\\)",
                "model": "K8 (2018)"
            },
            {
                "regex": "(?:LG-)?H91[058]",
                "model": "V20"
            },
            {
                "regex": "(?:LG-)?H990(?:DS|N|T)?",
                "model": "V20"
            },
            {
                "regex": "(?:LG-)?LS997",
                "model": "V20"
            },
            {
                "regex": "(?:LG-)?VS995",
                "model": "V20"
            },
            {
                "regex": "(?:LG-)?US999",
                "model": "V20"
            },
            {
                "regex": "(?:VS996|LG-(?:H933|H93[012]|H932PR|[LU]S998|AS998)|LGM-V300[KLS])(?:[);/ ]|$)",
                "model": "V30"
            },
            {
                "regex": "L-01J(?:[);/ ]|$)",
                "model": "V20 Pro"
            },
            {
                "regex": "(?:L-01K|LGV35)(?:[);/ ]|$)",
                "model": "V30 Plus"
            },
            {
                "regex": "L-51A(?:[);/ ]|$)",
                "model": "V60"
            },
            {
                "regex": "(?:L-52A|LM-G900N?)(?:[);/ ]|$)",
                "model": "Velvet 5G"
            },
            {
                "regex": "(?:LM-G910)(?:[);/ ]|$)",
                "model": "Velvet 4G"
            },
            {
                "regex": "(?:A001LG|LM-V600)(?:[);/ ]|$)",
                "model": "V60 ThinQ 5G"
            },
            {
                "regex": "(?:LG-)?VS410PP",
                "model": "Optimus Zone"
            },
            {
                "regex": "LM-V350[N]?",
                "model": "V35"
            },
            {
                "regex": "(?:LG-)?VS415PP",
                "model": "Optimus Zone 2"
            },
            {
                "regex": "(?:LG-)?VS425PP",
                "model": "Optimus Zone 3"
            },
            {
                "regex": "(?:LG-)?K100",
                "model": "K3"
            },
            {
                "regex": "(?:LG-?)?(?:K|MS)33[02]",
                "model": "K7"
            },
            {
                "regex": "(?:LG-?)?(?:K|MS)350",
                "model": "K8"
            },
            {
                "regex": "VS500PP",
                "model": "K8 V"
            },
            {
                "regex": "(?:LG-)?K420(?:N)?",
                "model": "K10"
            },
            {
                "regex": "(?:LG-)?K430(?:DS|DSF|DSY)?",
                "model": "K10"
            },
            {
                "regex": "(?:LG-)?M250N?",
                "model": "K10 (2017)"
            },
            {
                "regex": "(?:LG-)?F670[KLS]?",
                "model": "K10"
            },
            {
                "regex": "(?:LG-)?M255",
                "model": "K20"
            },
            {
                "regex": "(?:LG-?)?[MT]P260",
                "model": "K20 Plus"
            },
            {
                "regex": "LM-K200(?:[);/ ]|$)",
                "model": "K22"
            },
            {
                "regex": "LM-K300(?:[);/ ]|$)",
                "model": "K31"
            },
            {
                "regex": "LM-K420(?:[);/ ]|$)",
                "model": "K42"
            },
            {
                "regex": "LM-K500(?:[);/ ]|$)",
                "model": "K51"
            },
            {
                "regex": "LM-K520(?:[);/ ]|$)",
                "model": "K52"
            },
            {
                "regex": "LM-Q510N(?:[);/ ]|$)",
                "model": "Q51"
            },
            {
                "regex": "LM-Q630N?(?:[);/ ]|$)",
                "model": "Q61"
            },
            {
                "regex": "(?:LG-)?M700(?:N|A|DSK|AN)?",
                "model": "Q6"
            },
            {
                "regex": "LM-X440IM",
                "model": "W30"
            },
            {
                "regex": "(?:LG-?)?K220",
                "model": "X Power"
            },
            {
                "regex": "(?:LG-?)?M320",
                "model": "X Power 2"
            },
            {
                "regex": "(?:LG-)?K371",
                "model": "Phoenix 2"
            },
            {
                "regex": "(?:LG-)?M150",
                "model": "Phoenix 3"
            },
            {
                "regex": "(?:LG-)?F100[SL]",
                "model": "Optimus Vu"
            },
            {
                "regex": "(?:LG-)?P880",
                "model": "Optimus 4X HD"
            },
            {
                "regex": "(?:LG-)?(H340n?|H324)",
                "model": "Leon"
            },
            {
                "regex": "(?:LG-)?H342",
                "model": "Leon 4G LTE"
            },
            {
                "regex": "(?:LG-)?H422",
                "model": "Spirit"
            },
            {
                "regex": "(?:LG)?C660",
                "model": "Optimus Pro"
            },
            {
                "regex": "LML21(?:1B|2V)L",
                "model": "Rebel 4"
            },
            {
                "regex": "VS876",
                "model": "Lucid 3"
            },
            {
                "regex": "VS880(PP)?",
                "model": "G Vista"
            },
            {
                "regex": "VS820",
                "model": "Lancet"
            },
            {
                "regex": "VS870 4G",
                "model": "Lucid 2"
            },
            {
                "regex": "LM-F100N?(?:[);/ ]|$)",
                "device": "phablet",
                "model": "WING"
            },
            {
                "regex": "LGUK410",
                "model": "G Pad 7.0\" LTE",
                "device": "tablet"
            },
            {
                "regex": "LGLK430",
                "model": "G Pad F 7.0\"",
                "device": "tablet"
            },
            {
                "regex": "LGLK430",
                "model": "G Pad F 7.0\"",
                "device": "tablet"
            },
            {
                "regex": "LG-V490",
                "model": "G Pad 8.0",
                "device": "tablet"
            },
            {
                "regex": "LGT01",
                "model": "G Pad 8.0\" LTE",
                "device": "tablet"
            },
            {
                "regex": "LG-V500",
                "model": "G Pad 8.3",
                "device": "tablet"
            },
            {
                "regex": "LG-V700",
                "model": "G Pad 10.1",
                "device": "tablet"
            },
            {
                "regex": "LG-V935",
                "model": "G Pad II 10.1",
                "device": "tablet"
            },
            {
                "regex": "LG-V521",
                "model": "G Pad X 8.0",
                "device": "tablet"
            },
            {
                "regex": "VK810",
                "model": "G Pad 8.3 LTE",
                "device": "tablet"
            },
            {
                "regex": "VK815",
                "model": "G Pad X 8.3",
                "device": "tablet"
            },
            {
                "regex": "LGT32",
                "model": "Qua Tad PZ",
                "device": "tablet"
            },
            {
                "regex": "LGT31",
                "model": "Qua Tad PX",
                "device": "tablet"
            },
            {
                "regex": "webOS.TV",
                "device": "tv",
                "model": "WebOS TV"
            },
            {
                "regex": "LGE_DLNA_SDK|NetCast",
                "device": "tv",
                "model": "NetCast"
            },
            {
                "regex": "LG Watch Urbane",
                "device": "wearable",
                "model": "Watch Urbane"
            },
            {
                "regex": "(LM-[A-Z]{1}[0-9]{3}([A-Z]{1,3})?)",
                "model": "$1"
            },
            {
                "regex": "LGE(?: |-LG| LG-AX|-)([a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "FBDV/LG-([a-z0-9]+);FBSV",
                "model": "$1"
            },
            {
                "regex": "LGE;([a-z0-9\\-]+)",
                "model": "$1"
            },
            {
                "regex": "LG[ _\\-](V90.*|Optimus[ _\\-]Pad.*) Build",
                "device": "tablet",
                "model": "$1"
            },
            {
                "regex": "LG(?:/|-LG| |-)?([^/;]*) Build",
                "model": "$1"
            },
            {
                "regex": "LG(?:/|-LG| |-)?([a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "LG; ([a-z0-9 ]+)",
                "model": "$1"
            },
            {
                "regex": "portalmmm/2.0 ((?:KE|KG|KP|L3)[a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "(VX[0-9]+)",
                "model": "$1"
            },
            {
                "regex": "Web0S; Linux/SmartTV",
                "device": "tv",
                "model": ""
            }
        ]
    },
    "Lingwin": {
        "regex": "Lingwin[ _\\-]",
        "device": "smartphone",
        "models": [
            {
                "regex": "Lingwin[ _\\-]?([^/;]+) Build",
                "model": "$1"
            },
            {
                "regex": "Lingwin[ _\\-]?([^);/ ]+)",
                "model": "$1"
            }
        ]
    },
    "Logicom": {
        "regex": "LOGICOM[_ ]|(TAB950|TAB1062|E731|E812|E912|E1031|POWER BOT|La[ _]Tab[ _](?:7[24]|10[57]|114)|(?:B|C|M|ID|VR)[ _]?BOT[ _]?(?:TAB[ _])?([0-9]+\\+?)(?:PLUS)?|KT712A_4\\.4|L-?IXIR[0-9]*|L-ITE|L-EGANTONE(?:-R)?|L-?EMENT.+|Le[ _]?(?:Hop|Hello|Lift|Hola|Moov[2]?|Must(?: 2)?|Starter L|Fit FR|Spark|Prime|Smooth(?: FR)?))[_);/ ]",
        "device": "tablet",
        "models": [
            {
                "regex": "La[ _]Tab[ _](7[24]|10[57]|114)(?:[);/ ]|$)",
                "model": "La Tab $1"
            },
            {
                "regex": "LOGICOM_LA_TAB_LINK_71",
                "model": "La Tab Link 71"
            },
            {
                "regex": "KT712A_4.4|L-IXIR TAB 701 3G",
                "model": "L-ixir Tab 701 3G"
            },
            {
                "regex": "LIXIR1041",
                "model": "L-ixir Tab 1041"
            },
            {
                "regex": "L-?IXIR[ _]TAB[ _]([^);/]+)( Build|[);])",
                "model": "L-ixir Tab $1"
            },
            {
                "regex": "L-ITE[ _](400M|452|50[46] HD|506R HD|552 HD)(?:[);/ ]|$)",
                "device": "smartphone",
                "model": "L-ite $1"
            },
            {
                "regex": "L-ITE[ _]([^;/]+) Build",
                "model": "L-ite Tab $1"
            },
            {
                "regex": "L-?EMENT[ _]?(350|451|40[103]|55[013]|50[15]|500L?)(?:[);/ ]|$)",
                "model": "L-ement $1",
                "device": "smartphone"
            },
            {
                "regex": "L-?EMENT[ _](?:TAB)[ )]?([^);/]+)(?: Build|[);])",
                "model": "L-ement Tab $1"
            },
            {
                "regex": "L-?EMENT[ _]?(74[01])(?:[);/ ]|$)",
                "model": "L-ement Tab $1"
            },
            {
                "regex": "(TAB950|TAB1062|E731|E812|E912|E1031)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "POWER BOT",
                "model": "POWER BOT"
            },
            {
                "regex": "((?:B|C|M|ID|VR))[ _]?BOT[ _]?((?:TAB[ _])?([0-9]+\\+?)(?:PLUS)?)",
                "model": "$1 BOT $2"
            },
            {
                "regex": "Le Lift",
                "model": "Le Lift",
                "device": "smartphone"
            },
            {
                "regex": "Le Hola FR(?:[);/ ]|$)",
                "model": "Le Hola FR",
                "device": "smartphone"
            },
            {
                "regex": "Le[ _]Hola(?:[);/ ]|$)",
                "model": "Le Hola",
                "device": "smartphone"
            },
            {
                "regex": "LeMoov2(?:[);/ ]|$)",
                "model": "Le Moov 2",
                "device": "smartphone"
            },
            {
                "regex": "LeMoov(?:[);/ ]|$)",
                "model": "Le Moov",
                "device": "smartphone"
            },
            {
                "regex": "Le Fit FR(?:[);/ ]|$)",
                "model": "Le Fit FR",
                "device": "smartphone"
            },
            {
                "regex": "Le Hop(?:[);/ ]|$)",
                "model": "Le Hop",
                "device": "smartphone"
            },
            {
                "regex": "Le[ _]Hello(?:[);/ ]|$)",
                "model": "Le Hello",
                "device": "smartphone"
            },
            {
                "regex": "Le Smooth FR(?:[);/ ]|$)",
                "model": "Le Smooth FR",
                "device": "smartphone"
            },
            {
                "regex": "Le Smooth(?:[);/ ]|$)",
                "model": "Le Smooth",
                "device": "smartphone"
            },
            {
                "regex": "Le Must 2(?:[);/ ]|$)",
                "device": "smartphone",
                "model": "Le Must 2"
            },
            {
                "regex": "Le Must(?:[);/ ]|$)",
                "model": "Le Must",
                "device": "smartphone"
            },
            {
                "regex": "Le Starter L(?:[);/ ]|$)",
                "model": "Le Starter L",
                "device": "smartphone"
            },
            {
                "regex": "Le[ _]Spark(?:[);/ ]|$)",
                "model": "Le Spark",
                "device": "smartphone"
            },
            {
                "regex": "Le[ _]Prime(?:[);/ ]|$)",
                "model": "Le Prime",
                "device": "smartphone"
            },
            {
                "regex": "L-EGANTONE-R",
                "model": "L-Egant One R",
                "device": "smartphone"
            },
            {
                "regex": "L-EGANTONE",
                "model": "L-Egant One",
                "device": "smartphone"
            }
        ]
    },
    "Konka": {
        "regex": "KONKA[_ ]([a-z0-9]+)",
        "device": "smartphone",
        "models": [
            {
                "regex": "E5660S",
                "model": "Viva 5660s"
            },
            {
                "regex": "W830",
                "model": "Tango 830"
            },
            {
                "regex": "W(9[67]0)",
                "model": "Expose $1"
            },
            {
                "regex": "W990",
                "model": "Tuxedo 990"
            },
            {
                "regex": "KONKA[_ ]([a-z0-9]+)",
                "device": "feature phone",
                "model": "$1"
            }
        ]
    },
    "Konrow": {
        "regex": "(?:BIGCOOL|COOLFIVE|COOL-K|Just5|Link5|EASY(?:5|62)|CITY55|Cool55)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "BIGCOOL(?:[);/ ]|$)",
                "model": "BigCool"
            },
            {
                "regex": "COOL-K(?:[);/ ]|$)",
                "model": "Cool-K"
            },
            {
                "regex": "Cool55(?:[);/ ]|$)",
                "model": "Cool 55"
            },
            {
                "regex": "COOLFIVE(?:[);/ ]|$)",
                "model": "Cool Five"
            },
            {
                "regex": "Just5(?:[);/ ]|$)",
                "model": "Just 5"
            },
            {
                "regex": "Link5(?:[);/ ]|$)",
                "model": "Link 5"
            },
            {
                "regex": "EASY(5|62)(?:[);/ ]|$)",
                "model": "Easy $1"
            },
            {
                "regex": "CITY55(?:[);/ ]|$)",
                "model": "City 55"
            }
        ]
    },
    "Karbonn": {
        "regex": "(?:Browser-)?Karbonn|K9 Kavach 4G|K9[_ ]Viraat[_ ]Plus|K9 Music 4G|K9 Smart|K9 VIRAAT 4G|Aura (?:Sleek Plus|Power 4G Plus|Note Play)|Titanium[ _](?:3D-Plex|Octane Plus|Vista 4G|Jumbo|S360|S1[059]|S35|S20[13]|S22|S[15]|S200 HD|Frames S7|MOGHUL|S9)|K-lite F3|Sparkle V|Alfa A114 Quad|Quattro L55 HD",
        "device": "smartphone",
        "models": [
            {
                "regex": "K9 Kavach 4G",
                "model": "K9 Kavach 4G"
            },
            {
                "regex": "K9 Music 4G",
                "model": "K9 Music 4G"
            },
            {
                "regex": "K9 Smart 1GB",
                "model": "K9 Smart"
            },
            {
                "regex": "K9 Smart 4G",
                "model": "K9 Smart 4G"
            },
            {
                "regex": "K9 Smart Grand",
                "model": "K9 Smart Grand"
            },
            {
                "regex": "K9 Smart Yuva",
                "model": "K9 Smart Yuva"
            },
            {
                "regex": "K9 Smart",
                "model": "K9 Smart"
            },
            {
                "regex": "K9[_ ]Viraat[_ ]Plus",
                "model": "K9 Viraat Plus"
            },
            {
                "regex": "Aura Sleek Plus",
                "model": "Aura Sleek Plus"
            },
            {
                "regex": "Aura Power 4G Plus",
                "model": "Aura Power 4G Plus"
            },
            {
                "regex": "Aura Note Play",
                "model": "Aura Note Play"
            },
            {
                "regex": "Alfa A114 Quad",
                "model": "Alfa A114 Quad"
            },
            {
                "regex": "karbonnK9 Viraat 4G|K9 VIRAAT 4G",
                "model": "K9 Viraat 4G"
            },
            {
                "regex": "Titanium[ _]S2[ _]Plus",
                "model": "Titanium S2 Plus"
            },
            {
                "regex": "Titanium_S9Plus",
                "model": "Titanium S9 Plus"
            },
            {
                "regex": "Titanium[_ ]S(99|360|1[059]|109|35|200 HD|20[13]|22|[15])(?:[);/ ]|$)",
                "model": "Titanium S$1"
            },
            {
                "regex": "Titanium Vista 4G",
                "model": "Titanium Vista 4G"
            },
            {
                "regex": "Titanium Jumbo 2",
                "model": "Titanium Jumbo 2"
            },
            {
                "regex": "Titanium Jumbo",
                "model": "Titanium Jumbo"
            },
            {
                "regex": "Titanium[ _]MOGHUL",
                "model": "Titanium Moghul"
            },
            {
                "regex": "Titanium Frames S7",
                "model": "Titanium Frames S7"
            },
            {
                "regex": "Titanium Octane Plus",
                "model": "Titanium Octane Plus"
            },
            {
                "regex": "Titanium[ _]3D-Plex",
                "model": "Titanium 3D Plex"
            },
            {
                "regex": "K-lite F3",
                "model": "K-Lite F3"
            },
            {
                "regex": "Sparkle V",
                "model": "Sparkle V"
            },
            {
                "regex": "Quattro L55 HD",
                "model": "Quattro L55 HD"
            },
            {
                "regex": "Karbonn ([a-z0-9]+) WAP-Browser",
                "model": "$1",
                "device": "feature phone"
            },
            {
                "regex": "WAP Browser-Karbonn ([a-z0-9]+)/",
                "model": "$1",
                "device": "feature phone"
            },
            {
                "regex": "Karbonn_([^;/)]+)",
                "model": "$1"
            },
            {
                "regex": "Karbonn ([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "Karbonn ([^;/]+)/",
                "model": "$1"
            }
        ]
    },
    "Sagem": {
        "regex": "SAGEM|portalmmm/2.0 (?:SG|my)",
        "device": "smartphone",
        "models": [
            {
                "regex": "SAGEM-(my[a-z0-9\\-]+)",
                "model": "$1",
                "device": "feature phone"
            },
            {
                "regex": "SAGEM ([a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "SAGEM-([a-z0-9\\-]+)",
                "model": "$1"
            },
            {
                "regex": "portalmmm/2.0 ((?:SG|my)[a-z0-9]+)",
                "model": "$1"
            }
        ]
    },
    "Coby Kyros": {
        "regex": "(MID(?:1024|1125|1126|1045|1048|1060|1065|4331|7012|7015A?|7016|7022|7032|7035|7036|7042|7047|7048|7052|7065|7120|8024|8042|8048|8065|8125|8127|8128|9724|9740|9742))(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "Mpman": {
        "regex": "(?:MPQC|MPDC)[0-9]+|PH(?:150|340|350|360|451|500|520|544|604)|(?:MID(?:7C|74C|82C|84C|801|811|701|711|170|77C|43C|102C|103C|104C|114C)|MP100i OCTA|MP(?:843|717|718|1010|7007|7008|843|888|959|969)|MGP7)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "((?:MPQC|MPDC)[0-9]+[^/;)]+)(?: Build|\\))",
                "model": "$1"
            },
            {
                "regex": "MP100i OCTA(?:[);/ ]|$)",
                "model": "MP100i OCTA"
            },
            {
                "regex": "(MID(?:7C|74C|82C|84C|801|811|701|711|170|77C|43C|102C|103C|104C|114C)|MP(?:843|717|718|1010|7007|7008|843|888|959|969)|MGP7)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "(PH(?:150|340|350|360|451|500|520|544|604))",
                "model": "$1",
                "device": "smartphone"
            }
        ]
    },
    "Majestic": {
        "regex": "MAJESTIC[ _-]|CRONO ?[0-9]+|TAB (?:647|411) 3G|URANO 77LTE",
        "device": "smartphone",
        "models": [
            {
                "regex": "URANO 77LTE(?:[);/ ]|$)",
                "model": "Urano 77 LTE"
            },
            {
                "regex": "TAB (647|411) 3G",
                "model": "Tab $1 3G",
                "device": "tablet"
            },
            {
                "regex": "MAJESTIC[ _]Tab([^;/]+)Build",
                "model": "Tab$1",
                "device": "tablet"
            },
            {
                "regex": "MAJESTIC[ _]Tab([^);/]+)(?:[);/]|$)",
                "model": "Tab$1",
                "device": "tablet"
            },
            {
                "regex": "MAJESTIC[ _]([^;/]+)Build",
                "model": "$1"
            },
            {
                "regex": "MAJESTIC[ _]([^);/]+)(?:(?:[);/ ]|$)|$)",
                "model": "$1"
            },
            {
                "regex": "CRONO ?([0-9]+) Build",
                "model": "Crono $1"
            },
            {
                "regex": "CRONO ?([0-9]+)(?:[);/]|$)",
                "model": "Crono $1"
            }
        ]
    },
    "Manta Multimedia": {
        "regex": "MSP950(1[245]|2[01])|MSP96017|((?<!/)MID713|MID(?:06[SN]|08[S]?|12|13|14|15|701|702|703|704|705(?:DC)?|706[AS]?|707|708|709|711|712|714|717|781|801|802|901|1001|1002|1003|1004(?: 3G)?|1005|1009|1010|7802|9701|9702))(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "((?<!/)MID713|MID(?:06[SN]|08[S]?|12|13|14|15|701|702|703|704|705(?:DC)?|706[AS]?|707|708|709|711|712|714|717|781|801|802|901|1001|1002|1003|1004(?: 3G)?|1005|1009|1010|7802|9701|9702))(?:[);/ ]|$)",
                "device": "tablet",
                "model": "$1"
            },
            {
                "regex": "MSP95012",
                "model": "Titano 1"
            },
            {
                "regex": "MSP95014",
                "model": "Titano 3"
            },
            {
                "regex": "MSP95015",
                "model": "Mezo 2"
            },
            {
                "regex": "MSP95020",
                "model": "Rocky 2"
            },
            {
                "regex": "MSP95021",
                "model": "Rocky 3 Pro"
            },
            {
                "regex": "MSP96017",
                "model": "Forto 2"
            }
        ]
    },
    "Masstel": {
        "regex": "Masstel",
        "device": "smartphone",
        "models": [
            {
                "regex": "Masstel[_ ](Juno[_ ]Q3|X9|N4[56]0|N5[2458]0|N536|M05|M1[29]0|M1[125]|M250|M25)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "Masstel[_ ]\\((M18)\\)",
                "model": "$1"
            },
            {
                "regex": "Masstel_Tab7LTE(?:[);/ ]|$)",
                "model": "Tab 7 LTE",
                "device": "tablet"
            },
            {
                "regex": "Masstel Tab (840|700i)(?:[);/ ]|$)",
                "model": "Tab $1",
                "device": "tablet"
            }
        ]
    },
    "Maxwest": {
        "regex": "(?:Astro[_ ](?:5N_LTE|X4|5_GO_LTE|X55s)|Virtue Z5|Nitro[_ ](?:4S?|55R|5[XR]|[45] GO|4s LTE)|Gravity_55?_GO|Nitro_Phablet_9s)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Astro_5N_LTE",
                "model": "Astro 5N LTE"
            },
            {
                "regex": "Astro_5_GO_LTE",
                "model": "Astro 5 Go LTE"
            },
            {
                "regex": "Virtue Z5",
                "model": "Virtue Z5"
            },
            {
                "regex": "Astro X55s",
                "model": "Astro X55s"
            },
            {
                "regex": "Astro[_ ]X4",
                "model": "Astro X4"
            },
            {
                "regex": "Nitro ([45]) GO",
                "model": "Nitro $1 GO"
            },
            {
                "regex": "Nitro 4s LTE",
                "model": "Nitro 4s LTE"
            },
            {
                "regex": "Nitro[ _]4s",
                "model": "Nitro 4s"
            },
            {
                "regex": "Nitro_(4|55R|5[XR])",
                "model": "Nitro $1"
            },
            {
                "regex": "Gravity_(55?)_GO",
                "model": "Gravity $1 GO"
            },
            {
                "regex": "Nitro_Phablet_9s",
                "device": "tablet",
                "model": "Nitro Phablet 9s"
            }
        ]
    },
    "True Slim": {
        "regex": "(SSB-508[ED])(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Maze Speed": {
        "regex": "(SSB-(?:500|508)|SSB408D|SSB504R)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "SSB-?(500|508|408D|504R)(?:[);/ ]|$)",
                "model": "SSB-$1"
            }
        ]
    },
    "Maze": {
        "regex": "(?:Alpha[_ ]X|Maze Alpha)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Alpha[_ ]X(?:[);/ ]|$)",
                "model": "Alpha X"
            },
            {
                "regex": "Maze Alpha(?:[);/ ]|$)",
                "model": "Alpha"
            }
        ]
    },
    "Mediacom": {
        "regex": "(?:SmartPad7503G|M-SP10MXA|M-PPAB500|SmartPad970s2(?:3G)?|M[_-][MPS]P[0-9a-z]+|M-IPRO[0-9a-z]+|1AEC)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "SmartPad7503G(?:[);/ ]|$)",
                "model": "SmartPad 750 3G"
            },
            {
                "regex": "SmartPad970s23G(?:[);/ ]|$)",
                "model": "SmartPad 970 S2 3G"
            },
            {
                "regex": "SmartPad970s2(?:[);/ ]|$)",
                "model": "SmartPad 970 S2"
            },
            {
                "regex": "M-MP84[02]M(?:[);/ ]|$)",
                "model": "SmartPad 8.0\" Mobile"
            },
            {
                "regex": "M-MP940M(?:[);/ ]|$)",
                "model": "SmartPad 9.7\" Mobile"
            },
            {
                "regex": "M-SP10MXA(?:[);/ ]|$)",
                "model": "SmartPad MX 10.1\""
            },
            {
                "regex": "M-SP8MXA(?:[);/ ]|$)",
                "model": "SmartPad MX 8.0\""
            },
            {
                "regex": "M-SP7HXAH(?:[);/ ]|$)",
                "model": "SmartPad HX 7.0\""
            },
            {
                "regex": "M-SP8HXAH(?:[);/ ]|$)",
                "model": "SmartPad HX 8.0\" HD"
            },
            {
                "regex": "M-MP(?:710|720|725|726|740)GOx?(?:[);/ ]|$)",
                "model": "SmartPad 7.0\" Go"
            },
            {
                "regex": "M-MP876S2(?:[);/ ]|$)",
                "model": "SmartPad 8.0\" S2"
            },
            {
                "regex": "M-MP8S2[AB]?3G(?:[);/ ]|$)",
                "model": "SmartPad 8.0\" S2 3G"
            },
            {
                "regex": "M-MP(101|102|860|875)S2[BW]?(?:[);/ ]|$)",
                "model": "SmartPad $1 S2"
            },
            {
                "regex": "M-MP85S23G(?:[);/ ]|$)",
                "model": "SmartPad 875 S2 3G"
            },
            {
                "regex": "M-SP1AGO3G",
                "model": "SmartPad 10 Go 3G"
            },
            {
                "regex": "M-SP7xGO3G",
                "model": "SmartPad 10.1\" iPro 3G"
            },
            {
                "regex": "1AEC",
                "model": "SmartPad 10 Eclipse"
            },
            {
                "regex": "M-MP10[45][01]S2(?:[);/ ]|$)",
                "model": "SmartPad 10.1\" S2"
            },
            {
                "regex": "M-MP1S2[AB]3G(?:[);/ ]|$)",
                "model": "SmartPad 10.1\" S2 3G"
            },
            {
                "regex": "M-MP8[24]S4(?:[);/ ]|$)",
                "model": "SmartPad 8.0\" S4"
            },
            {
                "regex": "M-MP8S4[AB]3G(?:[);/ ]|$)",
                "model": "SmartPad 8.0\" S4 3G"
            },
            {
                "regex": "M-MP9S4A3G(?:[);/ ]|$)",
                "model": "SmartPad 9.7\" S4 3G"
            },
            {
                "regex": "M-MP1[02]S4(?:[);/ ]|$)",
                "model": "SmartPad 10.1\" S4"
            },
            {
                "regex": "M-MP1040MC?(?:[);/ ]|$)",
                "model": "SmartPad 10.1\" HD S4 3G"
            },
            {
                "regex": "M-MP1S4[AB]3G(?:[);/ ]|$)",
                "model": "SmartPad 10.1\" S4 3G"
            },
            {
                "regex": "M-SP10HXxH(?:[);/ ]|$)",
                "model": "SmartPad 10.1\" HX HD"
            },
            {
                "regex": "M-SP10MXHA(?:[);/ ]|$)",
                "model": "SmartPad 10.1\" MX"
            },
            {
                "regex": "M-SP10MXHL(?:[);/ ]|$)",
                "model": "SmartPad 10.1\" MX Lite"
            },
            {
                "regex": "M-MP8PA3G(?:[);/ ]|$)",
                "model": "SmartPad 8.0\" HD Pro 3G"
            },
            {
                "regex": "M-MP10PA(?:[);/ ]|$)",
                "model": "SmartPad 10.1\" HD Pro"
            },
            {
                "regex": "M-MP10PA3G(?:[);/ ]|$)",
                "model": "SmartPad 10.1\" HD Pro 3G"
            },
            {
                "regex": "M-IPRO([18][01]0)[BW](?:[);/ ]|$)",
                "model": "SmartPad 8.0\" HD iPro$1 3G"
            },
            {
                "regex": "(?:M-MP75S23G|M-MP72[01]M)(?:[);/ ]|$)",
                "model": "SmartPad 7.0\" Mobile",
                "device": "phablet"
            },
            {
                "regex": "M-MP7S2[ABDK]3G(?:[);/ ]|$)",
                "model": "SmartPad 7.0\" S2 3G",
                "device": "phablet"
            },
            {
                "regex": "M-MP5303G(?:[);/ ]|$)",
                "model": "SmartPad Mini Mobile",
                "device": "phablet"
            },
            {
                "regex": "M-PPxB400(?:[);/ ]|$)",
                "model": "PhonePad Duo B400",
                "device": "phablet"
            },
            {
                "regex": "M-PPAG550(?:[);/ ]|$)",
                "model": "PhonePad Duo G550",
                "device": "phablet"
            },
            {
                "regex": "M-PPAB500(?:[);/ ]|$)",
                "model": "PhonePad Duo B500",
                "device": "phablet"
            },
            {
                "regex": "M-PP2G530(?:[);/ ]|$)",
                "model": "PhonePad Duo G530",
                "device": "phablet"
            },
            {
                "regex": "M-PPG700(?:[);/ ]|$)",
                "model": "PhonePad Duo G700",
                "device": "phablet"
            },
            {
                "regex": "M-PPxS531(?:[);/ ]|$)",
                "model": "PhonePad Duo S531",
                "device": "phablet"
            },
            {
                "regex": "M-PP2S550(?:[);/ ]|$)",
                "model": "PhonePad Duo S550",
                "device": "phablet"
            },
            {
                "regex": "M-PP2S650C?(?:[);/ ]|$)",
                "model": "PhonePad Duo S650",
                "device": "phablet"
            },
            {
                "regex": "M-PPxS551U(?:[);/ ]|$)",
                "model": "PhonePad Duo S551U",
                "device": "phablet"
            },
            {
                "regex": "M-PPAG4(?:[);/ ]|$)",
                "model": "PhonePad Duo G4",
                "device": "smartphone"
            },
            {
                "regex": "M-PP[ABC]G500(?:[);/ ]|$)",
                "model": "PhonePad Duo G500",
                "device": "smartphone"
            },
            {
                "regex": "M-PP2S500[BC]?(?:[);/ ]|$)",
                "model": "PhonePad Duo S500",
                "device": "smartphone"
            },
            {
                "regex": "M[_-]PP[Ax]X(470|510|520)U(?:[);/ ]|$)",
                "model": "PhonePad Duo X$1U",
                "device": "smartphone"
            },
            {
                "regex": "M-PPxG(400|501|[457][PM]?)(?:[);/ ]|$)",
                "model": "PhonePad Duo G$1",
                "device": "smartphone"
            },
            {
                "regex": "M-PPxS(532[LU]|470|501|5P?|7P?)(?:[);/ ]|$)",
                "model": "PhonePad Duo S$1",
                "device": "smartphone"
            },
            {
                "regex": "M-PPxG(450)(?:[);/ ]|$)",
                "model": "PhonePad Duo G$1",
                "device": "smartphone"
            },
            {
                "regex": "M-MP([0-9a-z]+)",
                "model": "SmartPad $1"
            }
        ]
    },
    "Medion": {
        "regex": "Medion|(?:MD_)?LIFETAB|([SPX]10[0-9]{2}X|E10[45]1X|X5001|P851X|P850X|E691X|E1050X)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(?:MD_)?LIFETAB_([a-z0-9]+)",
                "device": "tablet",
                "model": "Lifetab $1"
            },
            {
                "regex": "(E450[46]|E5004|S5004|X5001|E5020|P5015)(?:[);/ ]|$)",
                "model": "Life $1"
            },
            {
                "regex": "(B5530)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "(E10[45]1X)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "$1"
            },
            {
                "regex": "([SPX]10[0-9]{2}X|P851X|P850X|E691X|E1050X)(?:[);/ ]|$)",
                "model": "Lifetab $1",
                "device": "tablet"
            },
            {
                "regex": "Medion(?: Smartphone)? ([^/;]+) Build",
                "model": "$1"
            }
        ]
    },
    "MEEG": {
        "regex": "MEEG[ _-]",
        "device": "smartphone",
        "models": [
            {
                "regex": "MEEG[ _\\-]?([^/;]+) Build",
                "model": "$1"
            },
            {
                "regex": "MEEG[ _\\-]?([^);/ ]+)",
                "model": "$1"
            }
        ]
    },
    "Fourel": {
        "regex": "(?:EASYSMARTM3|EasySmart M2)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "EASY ?SMART ?M([32])",
                "model": "Easy Smart M$1"
            }
        ]
    },
    "Meizu": {
        "regex": "Meizu|MZ-[a-z]|(?<!POCO )(M04[05]|MZ-16th(?: Plus)?|MZ-16[st]|M7[12]1C|M35[1356]|M612C|M578CA|M181[36]|M1822|M681C|M6T|MX[ -]?[2345](?: Pro)?|(?:MZ-)?m[1-6] note|M621C|M1852|M57[18]C|M3[ESX]|M031|m1 metal|M1 E|M2|M2 E|M5s Build|PRO [67]|PRO 7-[HS]| 15 Lite| 16th|MZ-15 (?:Lite|Plus)|MZ-16 X)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(?:MZ-)?M571C",
                "device": "phablet",
                "model": "M2 Note"
            },
            {
                "regex": "(?:MZ-|Meizu-)?m([1-6])[_ ]note(?:[);/ ]|$)",
                "device": "phablet",
                "model": "M$1 Note"
            },
            {
                "regex": "M1 E(?:[);/ ]|$)",
                "model": "M1E"
            },
            {
                "regex": "M2 E(?:[);/ ]|$)",
                "model": "M2E"
            },
            {
                "regex": "(?:MZ-)?M578C[A]?|M2(?:[);/ ]|$)",
                "model": "M2"
            },
            {
                "regex": "M681C(?:[);/ ]|$)",
                "model": "M3 Note",
                "device": "phablet"
            },
            {
                "regex": "M621C(?:[);/ ]|$)",
                "model": "M5 Note",
                "device": "phablet"
            },
            {
                "regex": "M711C(?:[);/ ]|$)",
                "model": "M6"
            },
            {
                "regex": "M721C(?:[);/ ]|$)",
                "model": "M6 Note",
                "device": "phablet"
            },
            {
                "regex": "M6T",
                "model": "M6T"
            },
            {
                "regex": "M3E",
                "model": "M3E"
            },
            {
                "regex": "M3S",
                "model": "M3S"
            },
            {
                "regex": "M3X",
                "model": "M3X"
            },
            {
                "regex": "M5S|M612C",
                "model": "M5S"
            },
            {
                "regex": "(M04[05]|MX[ -]?2)(?:[);/ ]|$)",
                "model": "MX2"
            },
            {
                "regex": "(M35[1356]|MX[ -]?3)(?:[);/ ]|$)",
                "model": "MX3"
            },
            {
                "regex": "MX[ -]?4 Pro(?:[);/ ]|$)",
                "model": "MX4 Pro"
            },
            {
                "regex": "MX[ -]?4(?:[);/ ]|$)",
                "model": "MX4"
            },
            {
                "regex": "(Meizu[- _])?MX[ -]?5(?:[);/ ]|$)",
                "model": "MX5"
            },
            {
                "regex": "m1 metal",
                "model": "M1 Metal"
            },
            {
                "regex": "M1813(?:[);/ ]|$)",
                "model": "M8"
            },
            {
                "regex": "M1816(?:[);/ ]|$)",
                "model": "M8 Lite"
            },
            {
                "regex": "PRO 7-[HS](?:[);/ ]|$)",
                "model": "PRO 7"
            },
            {
                "regex": "PRO 7 Plus(?:[);/ ]|$)",
                "model": "Pro 7 Plus"
            },
            {
                "regex": "PRO ([67])",
                "model": "PRO $1"
            },
            {
                "regex": "M031(?:[);/ ]|$)",
                "model": "MX Dual Core"
            },
            {
                "regex": "M1852(?:[);/ ]|$)",
                "model": "X8"
            },
            {
                "regex": "M1822(?:[);/ ]|$)",
                "model": "Note 8"
            },
            {
                "regex": "MZ-16T(?:[);/ ]|$)",
                "model": "16T"
            },
            {
                "regex": "(?:MZ-)?16th Plus(?:[);/ ]|$)",
                "model": "16th Plus"
            },
            {
                "regex": "MZ-15 Plus(?:[);/ ]|$)",
                "model": "15 Plus"
            },
            {
                "regex": "(?:MZ-15 Lite| 15 Lite)(?:[);/ ]|$)",
                "model": "15 Lite"
            },
            {
                "regex": "MZ-16th| 16th(?:[);/ ]|$)",
                "model": "16th"
            },
            {
                "regex": "MZ-16 X(?:[);/ ]|$)",
                "model": "16X"
            },
            {
                "regex": "Meizu[_ ]note([89])(?:[);/ ]|$)",
                "device": "phablet",
                "model": "Note $1"
            },
            {
                "regex": "(?:MZ-)?Meizu[_ ]([^/;]+) Build",
                "model": "$1"
            },
            {
                "regex": "(?:MZ-)?Meizu[_ ]([a-z0-9_]+)",
                "model": "$1"
            },
            {
                "regex": "MZ-([^/;]+) Build",
                "model": "$1"
            }
        ]
    },
    "Meitu": {
        "regex": "(?:(?<!MI CC 9 )Meitu[2]?|MP1503|MP1512|MP160[235]|MP170[19]|MP171[138])(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "MP1503(?:[);/ ]|$)",
                "model": "M6"
            },
            {
                "regex": "MP1512(?:[);/ ]|$)",
                "model": "M6s"
            },
            {
                "regex": "MP1602(?:[);/ ]|$)",
                "model": "T8"
            },
            {
                "regex": "MP1605(?:[);/ ]|$)",
                "model": "V6"
            },
            {
                "regex": "MP17(?:01|13)(?:[);/ ]|$)",
                "model": "T8s"
            },
            {
                "regex": "MP1718(?:[);/ ]|$)",
                "model": "T9"
            },
            {
                "regex": "MP1603(?:[);/ ]|$)",
                "model": "M8"
            },
            {
                "regex": "MP17(?:09|11)(?:[);/ ]|$)",
                "model": "M8s"
            },
            {
                "regex": "Meitu2(?:[);/ ]|$)",
                "model": "2"
            },
            {
                "regex": "Meitu (Kiss|[MV]4)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Memup": {
        "regex": "SlidePad ?([^;/]*) Build|SPNG?[0-9]{2,4}[a-z]{0,2}(?:[);/ ]|$)|SP704CE?|SP-NG708-3G",
        "device": "tablet",
        "models": [
            {
                "regex": "SlidePad ?([^;/]*) Build",
                "model": "SlidePad $1"
            },
            {
                "regex": "SPNG([0-9]{2,4}[a-z]{0,2})(?:[);/ ]|$)",
                "model": "SlidePad NG$1"
            },
            {
                "regex": "SP-NG(708)-3G(?:[);/ ]|$)",
                "model": "SlidePad NG$1 3G"
            },
            {
                "regex": "SP704(CE?)",
                "model": "SlidePad 704$1"
            }
        ]
    },
    "Mecer": {
        "regex": "(M785|800P3[12]C|101P51C|X1010)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "M785(?:[);/ ]|$)",
                "model": "Xpress Smartlife M785"
            },
            {
                "regex": "X1010(?:[);/ ]|$)",
                "model": "X1010"
            },
            {
                "regex": "800P31C(?:[);/ ]|$)",
                "model": "Xpress Smartlife 800P31C"
            },
            {
                "regex": "800P32C(?:[);/ ]|$)",
                "model": "Xpress Smartlife 800P32C"
            },
            {
                "regex": "101P51C(?:[);/ ]|$)",
                "model": "Xpress Smartlife 101P51C"
            }
        ]
    },
    "Mio": {
        "regex": "MIO(?:/)?([a-z0-9]+)|(?:MOBIX M6|Mio TouchPad 7 Quad Core)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "MOBIX M6(?:[);/ ]|$)",
                "model": "Mobix M6"
            },
            {
                "regex": "MIO(?:/)?([a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "Mio TouchPad 7 Quad Core",
                "device": "tablet",
                "model": "TouchPad 7 Quad Core"
            }
        ]
    },
    "Miray": {
        "regex": "MIDM[_-]|MPM[_-]",
        "device": "smartphone",
        "models": [
            {
                "regex": "MIDM[_-]([a-z0-9]+)",
                "model": "MIDM $1",
                "device": "tablet"
            },
            {
                "regex": "MPM[_-]([a-z0-9]+)",
                "model": "MPM $1"
            }
        ]
    },
    "Mitsubishi": {
        "regex": "MITSU|portalmmm/[12]\\.0 M",
        "device": "feature phone",
        "models": [
            {
                "regex": "MITSU/[a-z0-9.]+ \\(([a-z0-9]+)\\)",
                "model": "$1"
            },
            {
                "regex": "MITSU[ \\-]?([a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "portalmmm/[12]\\.0 (M[a-z0-9]+)",
                "model": "$1"
            }
        ]
    },
    "MIXC": {
        "regex": "(M35|M20|G7106|G7108)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(M[0-9]{1,2})(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "(G[0-9]{4})(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Mobiola": {
        "regex": "(MS5[50]X6|MS55X5|MS55L1|MS50L1|MS43A3000|MS45A4000|MS50A4000|MS50A4500|MS50A5000|MS50A6000|MS50B11000|MB-2900 QUATTRO|POLYS[_ ]MS45L1|Wave5)",
        "device": "smartphone",
        "models": [
            {
                "regex": "MS50X6",
                "model": "Atmos II"
            },
            {
                "regex": "POLYS[_ ]MS45L1",
                "model": "Polys MS45L1"
            },
            {
                "regex": "MS55X6",
                "model": "Atmos Pro II"
            },
            {
                "regex": "MS55X5",
                "model": "Atmos Pro"
            },
            {
                "regex": "MS55L1",
                "model": "GAIA"
            },
            {
                "regex": "MS50L1",
                "model": "Inti"
            },
            {
                "regex": "MB-2900 QUATTRO",
                "model": "MB-2900 Quattro"
            },
            {
                "regex": "MS43A3000",
                "model": "MS43A3000"
            },
            {
                "regex": "MS45A4000",
                "model": "eOn 45"
            },
            {
                "regex": "MS50A4000",
                "model": "eOn 50"
            },
            {
                "regex": "MS50A4500",
                "model": "eOn 50 Elegance"
            },
            {
                "regex": "MS50A5000",
                "model": "eOn 50 Quad 8"
            },
            {
                "regex": "MS50A6000",
                "model": "eOn 50 Quad 16"
            },
            {
                "regex": "MS50B11000",
                "model": "MS50B11000"
            },
            {
                "regex": "Wave5",
                "model": "Wave 5"
            }
        ]
    },
    "Mobicel": {
        "regex": "Mobicel_(R1)|(?:HYPE|DANDY(?:_ZAM)?|(?<!SMADL )Matrix|TRENDY PLUS|FENDY_DS|TANGO_LITE|BLINK|BERRY[_ ]?(?:[12]|PLUS))(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Matrix",
                "model": "Matrix"
            },
            {
                "regex": "BLINK",
                "model": "Blink"
            },
            {
                "regex": "BERRY1",
                "model": "Berry 1"
            },
            {
                "regex": "BERRY 2",
                "model": "Berry 2"
            },
            {
                "regex": "BERRY[_ ]PLUS",
                "model": "Berry Plus"
            },
            {
                "regex": "TRENDY PLUS",
                "model": "Trendy Plus"
            },
            {
                "regex": "HYPE",
                "model": "Hype"
            },
            {
                "regex": "DANDY_ZAM",
                "model": "Dandy Zam"
            },
            {
                "regex": "DANDY",
                "model": "Dandy"
            },
            {
                "regex": "TANGO_LITE",
                "model": "Tango Lite"
            },
            {
                "regex": "FENDY_DS",
                "model": "Fendy DS"
            },
            {
                "regex": "Mobicel_(R1)",
                "model": "$1"
            }
        ]
    },
    "Mobiistar": {
        "regex": "Mobiistar|Zumbo[ _]S|PRIME X MAX|X1 Notch|E1 Selfie",
        "device": "smartphone",
        "models": [
            {
                "regex": "X1 Notch(?:[);/ ]|$)",
                "model": "X1 Notch"
            },
            {
                "regex": "E1 Selfie(?:[);/ ]|$)",
                "model": "E1 Selfie"
            },
            {
                "regex": "mobiistar_LAI_Zumbo(?:[);/ ]|$)",
                "model": "Zumbo"
            },
            {
                "regex": "Zumbo[_ ]S[_ ]2017[_ ]Lite(?:[);/ ]|$)",
                "model": "Lai Zumbo S Lite (2017)"
            },
            {
                "regex": "Zumbo[_ ]S[_ ]2017(?:[);/ ]|$)",
                "model": "Lai Zumbo S (2017)"
            },
            {
                "regex": "mobiistar ZUMBO S2( Dual)",
                "model": "Zumbo S2"
            },
            {
                "regex": "Mobiistar_LAI_Zumbo_J_2017(?:[);/ ]|$)",
                "model": "Lai Zumbo J (2017)"
            },
            {
                "regex": "Mobiistar_LAI_Zumbo_J(?:[);/ ]|$)",
                "model": "Lai Zumbo J"
            },
            {
                "regex": "Mobiistar_Zumbo_J2",
                "model": "Lai Zumbo J2"
            },
            {
                "regex": "Mobiistar[_ ]Zumbo[_ ]S2",
                "model": "Zumbo S2"
            },
            {
                "regex": "mobiistar ZUMBO Power",
                "model": "Zumbo Power"
            },
            {
                "regex": "mobiistar[ _]LAI[ _]YUNA[ _]([1XCS])",
                "model": "Lai Yuna $1"
            },
            {
                "regex": "mobiistar LAI Yuki",
                "model": "Lai Yuki"
            },
            {
                "regex": "mobiistar[ _]LAI[ _]ZORO[ _]([23])",
                "model": "Lai Zoro $1"
            },
            {
                "regex": "mobiistar[ _]LAI[ _]Zena",
                "model": "Lai Zena"
            },
            {
                "regex": "mobiistar[ _]LAI[ _]Z([12])?",
                "model": "Lai Z$1"
            },
            {
                "regex": "mobiistar_ZORO_4G",
                "model": "Zoro 4G"
            },
            {
                "regex": "mobiistar touch LAI 512",
                "model": "Touch Lai 512"
            },
            {
                "regex": "mobiistar LAI 504([KC])",
                "model": "Lai 504$1"
            },
            {
                "regex": "mobiistar LAI Y",
                "model": "Lai Y"
            },
            {
                "regex": "mobiistar PRIME X 2017",
                "model": "Prime X"
            },
            {
                "regex": "mobiistar[ _]PRIME[ _](X1|558|X[ _]Plus|X[ _]Grand) Build",
                "model": "Prime $1"
            },
            {
                "regex": "PRIME X MAX 2018",
                "model": "Prime X Max"
            },
            {
                "regex": "mobiistar[ _]KOOL([ _]Lite)?",
                "model": "Kool$1"
            },
            {
                "regex": "mobiistar KAT 452 Build",
                "model": "Kat 452"
            },
            {
                "regex": "mobiistar_LAI_YOLLO Build",
                "model": "Lai Yollo"
            },
            {
                "regex": "Mobiistar[ _]([^;/)]+)(?: Build|[;)])",
                "model": "$1"
            }
        ]
    },
    "MSI": {
        "regex": "Primo ?(?:9[13]|81|76)|Enjoy 7 Plus",
        "device": "tablet",
        "models": [
            {
                "regex": "Primo ?(9[13]|76|81)",
                "model": "Primo $1"
            },
            {
                "regex": "Enjoy 7 Plus",
                "model": "Enjoy 7 Plus"
            }
        ]
    },
    "MLLED": {
        "regex": "MLLED[ _]",
        "device": "smartphone",
        "models": [
            {
                "regex": "MLLED[ _]([^;/]+)Build",
                "model": "$1"
            },
            {
                "regex": "MLLED[ _]([^);/]+)(?:[);/]|$)",
                "model": "$1"
            }
        ]
    },
    "Mobistel": {
        "regex": "(Cynus[ _][^/;)]+)( Build|\\))",
        "device": "smartphone",
        "model": "$1"
    },
    "Mecool": {
        "regex": "(?:KM9PRO|BB2 PRO|K[I]{1,3} PRO|KI PLUS|KM9_TV_BOX|M8SPROW|M8S (?:PLUS|PRO) (?:DVB|[LW]|PLUS)|M8S (?:MAX|PRO))(?:[);/ ]|$)",
        "device": "tv",
        "models": [
            {
                "regex": "KM9PRO(?:[);/ ]|$)",
                "model": "KM9 Pro"
            },
            {
                "regex": "KM9_TV_BOX(?:[);/ ]|$)",
                "model": "KM9 TV Box"
            },
            {
                "regex": "BB2 PRO(?:[);/ ]|$)",
                "model": "BB2 Pro"
            },
            {
                "regex": "(K[I]{1,3}) PRO(?:[);/ ]|$)",
                "model": "$1 Pro"
            },
            {
                "regex": "KI PLUS(?:[);/ ]|$)",
                "model": "KI Plus"
            },
            {
                "regex": "M8SPROW(?:[);/ ]|$)",
                "model": "M8S Pro W"
            },
            {
                "regex": "M8S PLUS DVB(?:[);/ ]|$)",
                "model": "M8S Plus DVB"
            },
            {
                "regex": "M8S PLUS ([LW])(?:[);/ ]|$)",
                "model": "M8S Plus $1"
            },
            {
                "regex": "M8S PRO ([LW])(?:[);/ ]|$)",
                "model": "M8S Pro $1"
            },
            {
                "regex": "M8S PRO PLUS(?:[);/ ]|$)",
                "model": "M8S Pro Plus"
            },
            {
                "regex": "M8S PRO(?:[);/ ]|$)",
                "model": "M8S Pro"
            },
            {
                "regex": "M8S MAX(?:[);/ ]|$)",
                "model": "M8S Max"
            }
        ]
    },
    "Modecom": {
        "regex": "Modecom|Free(?:Way )?Tab|xino z[\\d]+ x[\\d]+",
        "device": "tablet",
        "models": [
            {
                "regex": "xino Z([\\d]+) X([\\d]+)",
                "model": "Xino Z$1 X$2",
                "device": "smartphone"
            },
            {
                "regex": "FreeTab[ _\\-]?([^/;]+)? Build",
                "model": "FreeTab $1"
            },
            {
                "regex": "FreeTab[ _\\-]?((?!Build)[^);/ ]+)?",
                "model": "FreeTab $1"
            },
            {
                "regex": "FreeWay Tab[ _\\-]?([^/;]+) Build",
                "model": "FreeWay Tab $1"
            },
            {
                "regex": "FreeWay Tab[ _\\-]?((?!Build)[^);/ ]+)",
                "model": "FreeWay Tab $1"
            }
        ]
    },
    "Mofut": {
        "regex": "Mofut",
        "device": "smartphone",
        "models": [
            {
                "regex": "Mofut[ _\\-]?([^/;]+) Build",
                "model": "$1"
            },
            {
                "regex": "Mofut[ _\\-]?((?!Build)[^);/ ]+)",
                "model": "$1"
            }
        ]
    },
    "BrightSign": {
        "regex": "BrightSign",
        "device": "tv",
        "models": [
            {
                "regex": "(HD1023|XD233|XD230|XD1030|XT114[34]|4K1142|LS424)",
                "model": "$1"
            }
        ]
    },
    "Motorola": {
        "regex": "MOT(?!TO)|(?<!AN)DROID ?(?:Build|[a-z0-9]+)|portalmmm/2.0 (?:E378i|L6|L7|v3)|XOOM [^;/]*Build|XT1941-2|XT1924-9|XT1925-10|XT1965-6|XT1970-5|XT1799-2|XT1021|XT1710-08|XT180[3-5]|XT194[23]-1|XT1929-15|(?:XT|MZ|MB|ME)[0-9]{3,4}[a-z]?(?:\\(Defy\\)|-0[1-5])?(?:[;]? Build|\\))",
        "device": "smartphone",
        "models": [
            {
                "regex": "MOT-V360",
                "model": "V360",
                "device": "feature phone"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT300",
                "model": "Spice"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT30[35]",
                "model": "Motosmart ME"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT31[16]",
                "model": "Fire"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT32[01]",
                "model": "Defy Mini"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT3(?:89|90)",
                "model": "Motosmart"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT502",
                "model": "Quench"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT531",
                "model": "Fire XT"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT535",
                "model": "Defy"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT55[567]C?",
                "model": "Defy XT"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT603",
                "model": "Admiral"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT610",
                "model": "Droid Pro"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT615",
                "model": "Motoluxe"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT621",
                "model": "Primus"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT626",
                "model": "IronRock"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT682",
                "model": "Atrix"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT685",
                "model": "Motoluxe Dual-SIM"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT687",
                "model": "Atrix TV"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT720",
                "model": "Milestone"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT800W",
                "model": "Glam"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT860",
                "model": "Milestone 3"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT881",
                "model": "Electrify 2"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT882",
                "model": "Moto XT882"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT88[56]",
                "model": "Droid RAZR V"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT890",
                "model": "Droid RAZR i"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT894",
                "model": "Droid 4"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT897",
                "model": "Photon Q"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT901",
                "model": "Electrify M"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT90[567]",
                "model": "Droid RAZR M"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT910S?",
                "model": "Droid RAZR"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT91[4568]",
                "model": "Droid RAZR D1"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT9(?:19|20)",
                "model": "Droid RAZR D3"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT925",
                "model": "Droid RAZR HD"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT102[12]",
                "model": "Moto E"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT1706",
                "model": "Moto E3 Power"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT1924-9|moto e5 plus",
                "model": "Moto E5 Plus"
            },
            {
                "regex": "Moto E5 Play",
                "model": "Moto E5 Play"
            },
            {
                "regex": "Moto E5 go(?:[);/ ]|$)",
                "model": "Moto E5 Go"
            },
            {
                "regex": "Moto E6(?:[);/ ]|$)",
                "model": "Moto E6"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT1030",
                "model": "Droid Mini"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT1096",
                "model": "Moto X2"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT1254",
                "model": "Droid Turbo"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT10(?:28|3[1234]|68|69)",
                "model": "Moto G"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT1799-2",
                "model": "Moto G5s"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT180[3-5]",
                "model": "Moto G5s Plus"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT168[57]",
                "model": "Moto G5 Plus"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT1670",
                "model": "Moto G5"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT1925-10",
                "model": "Moto G6"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT1965-6",
                "model": "Moto G7 Plus"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT10(?:49|5[23568]|60|9[23457])",
                "model": "Moto X"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT1562",
                "model": "Moto X Play"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT1572",
                "model": "Moto X Style"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT1080",
                "model": "Droid Ultra"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT1650-05",
                "model": "Moto Z"
            },
            {
                "regex": "moto z4(?:[);/ ]|$)",
                "model": "Moto Z4"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT1635(?:-0[1-3])?",
                "model": "Moto Z Play"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT1710-(?:-0[28])?",
                "model": "Moto Z2 Play"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT1789-05",
                "model": "Moto Z2 Force"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT1929-15",
                "model": "Moto Z3"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT1663",
                "model": "Moto M"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT1943-1",
                "model": "P30"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT1941-2",
                "model": "P30 Play"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT1942-1",
                "model": "P30 Note"
            },
            {
                "regex": "(?:MOT-|Motorola-)?XT1970-5",
                "model": "One Vision"
            },
            {
                "regex": "(?:MOT-|Motorola-)?one zoom",
                "model": "One Zoom"
            },
            {
                "regex": "motorola one action",
                "model": "One Action"
            },
            {
                "regex": "motorola one macro",
                "model": "One Macro"
            },
            {
                "regex": "motorola one 5G UW",
                "model": "One 5G UW"
            },
            {
                "regex": "moto g power",
                "model": "Moto G Power"
            },
            {
                "regex": "moto g\\(([0-9])\\) power lite",
                "model": "Moto G$1 Power Lite"
            },
            {
                "regex": "moto g\\(([0-9])\\) power",
                "model": "Moto G$1 Power"
            },
            {
                "regex": "moto g\\(([0-9])\\) supra",
                "model": "Moto G$1 Supra"
            },
            {
                "regex": "moto g\\(([0-9])\\) plus",
                "model": "Moto G$1 Plus"
            },
            {
                "regex": "moto g\\(([0-9]+)\\) play",
                "model": "Moto G$1 Play"
            },
            {
                "regex": "moto g\\(([0-9]+)\\)",
                "model": "Moto G$1"
            },
            {
                "regex": "Moto ([CGEXZ]) ?\\(([a-z0-9]+)\\)( Plus| Play)?",
                "model": "Moto $1$2$3"
            },
            {
                "regex": "Moto ?([CGEXZ])([0-9]+)( Plus| Play)?",
                "model": "Moto $1$2$3"
            },
            {
                "regex": "(?:Motorola-)?Moto[ _]?([CGEXZ])([ _](?:Plus|Play))?",
                "model": "Moto $1$2"
            },
            {
                "regex": "MotoroiX",
                "model": "MotoroiX"
            },
            {
                "regex": "Motorola[ _\\-]([^/;]+)(?: Build|[);])",
                "model": "$1"
            },
            {
                "regex": "MOTORAZR[ _\\-]([a-z0-9]+)",
                "model": "RAZR $1"
            },
            {
                "regex": "MOTORIZR[ _\\-]([a-z0-9]+)",
                "model": "RIZR $1"
            },
            {
                "regex": "MOT(?!OROLA)[O]?[_\\-]?([a-z0-9.]+)",
                "model": "$1"
            },
            {
                "regex": "(?<!AN)DROID ?([a-z0-9 ]*) Build",
                "model": "DROID $1"
            },
            {
                "regex": "(?<!AN)DROID ?([a-z0-9]+)",
                "model": "DROID $1"
            },
            {
                "regex": "portalmmm/2.0 ((?:E378i|L6|L7|V3)[a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "(XOOM [^;/]*)Build",
                "device": "tablet",
                "model": "$1"
            },
            {
                "regex": "(MZ[0-9]{3})(?:[);/ ]|$)",
                "device": "tablet",
                "model": "$1"
            },
            {
                "regex": "((?:ME|MB|XT)[0-9]{3,4}(?:\\(Defy\\))?)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Movic": {
        "regex": "MOVIC",
        "device": "smartphone",
        "models": [
            {
                "regex": "MOVIC[ -]W([2345])",
                "model": "W$1"
            }
        ]
    },
    "MTN": {
        "regex": "MTN-",
        "device": "smartphone",
        "models": [
            {
                "regex": "MTN-(L860|S620)",
                "model": "Sm@rt Mini $1"
            },
            {
                "regex": "MTN-8978P",
                "device": "tablet",
                "model": "Steppa"
            },
            {
                "regex": "MTN-([a-z0-9_\\-]+)",
                "model": "MTN-$1"
            }
        ]
    },
    "MyTab": {
        "regex": "MyTAB[ _-](?:8 MINI Dual Core|U55GT)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "MyTAB[ _-]8 MINI Dual Core(?:[);/ ]|$)",
                "model": "8 Mini Dual Core"
            },
            {
                "regex": "MyTAB-U55GT(?:[);/ ]|$)",
                "model": "U55GT"
            }
        ]
    },
    "MyPhone": {
        "regex": "(?:MyPhone|MyPad|MyTab)[ _][^;/)]+( Build|\\))|Cube_LTE|myTab10II|HAMMER[ _](Blade2_PRO|IRON_3|Explorer|ENERGY|Titan 2|AXE[_ ](?:M[_ ]LTE|Pro)|Iron 2|Active[2]?(_LTE)?)|C-Smart_pix|myPhone_Fun_8|FUN_18x9|MyPhoneMY27|Prime_4_Lite|myA17|myX12|Pocket_2|Prime_18x9|Prime3Lite|myT6 DTV|myXI1 (?:Plus|Pro)|myXI3|Q_Smart_BE",
        "device": "smartphone",
        "models": [
            {
                "regex": "MyPad[ _]([^;/]+) Build",
                "model": "MyPad $1",
                "device": "tablet"
            },
            {
                "regex": "MyTab[ _]([^;/]+) Build",
                "model": "MyTab $1",
                "device": "tablet"
            },
            {
                "regex": "myTab_10III",
                "model": "MyTab 10 III",
                "device": "tablet"
            },
            {
                "regex": "myTab10II",
                "model": "MyTab 10 II",
                "device": "tablet"
            },
            {
                "regex": "myT6 DTV",
                "model": "myT6 DTV",
                "device": "tablet"
            },
            {
                "regex": "Cube_LTE",
                "model": "Cube LTE"
            },
            {
                "regex": "Prime_4_Lite",
                "model": "Prime 4 Lite"
            },
            {
                "regex": "HAMMER[ _]ENERGY",
                "model": "Hammer Energy"
            },
            {
                "regex": "HAMMER_AXE[_ ]M[_ ]LTE",
                "model": "Hammer Axe M LTE"
            },
            {
                "regex": "Hammer Titan 2",
                "model": "Hammer Titan 2"
            },
            {
                "regex": "Hammer[ _]Iron[ _](2|3)",
                "model": "Hammer Iron $1"
            },
            {
                "regex": "Hammer[ _]Explorer",
                "model": "Hammer Explorer"
            },
            {
                "regex": "Hammer Active(?:[);/ ]|$)",
                "model": "Hammer Active"
            },
            {
                "regex": "Hammer_Active2(?:[);/ ]|$)",
                "model": "Hammer Active 2"
            },
            {
                "regex": "Hammer_Active2_LTE(?:[);/ ]|$)",
                "model": "Hammer Active 2 LTE"
            },
            {
                "regex": "Hammer AXE Pro",
                "model": "Hammer Axe Pro"
            },
            {
                "regex": "Hammer_Blade2_PRO",
                "model": "Hammer Blade 2 Pro"
            },
            {
                "regex": "C-Smart_pix",
                "model": "C-Smart Pix"
            },
            {
                "regex": "Pocket_2(?:[);/ ]|$)",
                "model": "Pocket 2"
            },
            {
                "regex": "myA17(?:[);/ ]|$)",
                "model": "myA17"
            },
            {
                "regex": "(?:myPhone_)?Fun_(8|18x9)",
                "model": "FUN $1"
            },
            {
                "regex": "MyPhoneMY27",
                "model": "my27"
            },
            {
                "regex": "myX12(?:[);/ ]|$)",
                "model": "myX12"
            },
            {
                "regex": "myXI1 Plus(?:[);/ ]|$)",
                "model": "myXI1 Plus"
            },
            {
                "regex": "myXI1 Pro(?:[);/ ]|$)",
                "model": "myXI1 Pro"
            },
            {
                "regex": "myXI3(?:[);/ ]|$)",
                "model": "myXI3"
            },
            {
                "regex": "Q_Smart_BE(?:[);/ ]|$)",
                "model": "Q-Smart BE"
            },
            {
                "regex": "Prime_18x9(_LTE)?(?:[);/ ]|$)",
                "model": "Prime 18x9$1"
            },
            {
                "regex": "Prime3Lite(?:[);/ ]|$)",
                "model": "Prime 3 Lite"
            },
            {
                "regex": "MyPhone[ _]([^;/)]+)( Build|\\))",
                "model": "$1"
            }
        ]
    },
    "MyWigo": {
        "regex": "MyWigo ",
        "device": "smartphone",
        "models": [
            {
                "regex": "City3",
                "model": "City 3"
            }
        ]
    },
    "Myros": {
        "regex": "(E70 Ultra)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Myria": {
        "regex": " (Myria_[^/;]+|502M|Cozy|Easy tab 9|Grand) Build|Myria_FIVE|Myria_Grand_4G|Myria_Wide_2|Myria_Wide_4G",
        "device": "smartphone",
        "models": [
            {
                "regex": "Myria_([^/;]+) Build",
                "model": "$1"
            },
            {
                "regex": "Myria_FIVE",
                "model": "Five"
            },
            {
                "regex": "(502M|Grand)",
                "model": "$1"
            },
            {
                "regex": "Myria_Grand_4G",
                "model": "Grand 4G"
            },
            {
                "regex": "Myria_Wide_4G",
                "model": "Wide 4G"
            },
            {
                "regex": "Myria_Wide_2",
                "model": "Wide 2"
            },
            {
                "regex": "(Cozy|Easy tab 9)",
                "model": "$1",
                "device": "tablet"
            }
        ]
    },
    "M.T.T.": {
        "regex": "M.T.T.",
        "device": "smartphone",
        "models": [
            {
                "regex": "M.T.T. ?Tablet([^;/]+)? Build",
                "device": "tablet",
                "model": "Tablet$1"
            },
            {
                "regex": "M.T.T. ?([^;/]+) Build",
                "model": "$1"
            }
        ]
    },
    "Navon": {
        "regex": "Navon[_ ]|(?:Vision_Tab_(?:7|10)|Superme_Max|Predator (?:10|3G)|Platinum[_ ]10[_ ]3G(?:_V2)?|Supreme[_ ](?:Fine(?:[_ ]Micro)?|Chief|Pure|Pro)(?:[_ ](?:Mini|Plus))?)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "M505 LTE(?:[);/ ]|$)",
                "model": "Mizu M505 LTE"
            },
            {
                "regex": "(F552|D504)(?:[);/ ]|$)",
                "model": "Mizu $1"
            },
            {
                "regex": "Superme_Max(?:[);/ ]|$)",
                "model": "Supreme Max"
            },
            {
                "regex": "Supreme_Fine_Mini(?:[);/ ]|$)",
                "model": "Supreme Fine Mini"
            },
            {
                "regex": "Supreme_Fine_Micro(?:[);/ ]|$)",
                "model": "Supreme Fine Micro"
            },
            {
                "regex": "Supreme[_ ]Fine[_ ]Plus(?:[);/ ]|$)",
                "model": "Supreme Fine Plus"
            },
            {
                "regex": "Supreme_Fine(?:[);/ ]|$)",
                "model": "Supreme Fine"
            },
            {
                "regex": "Supreme[_ ]Chief(?:[);/ ]|$)",
                "model": "Supreme Chief"
            },
            {
                "regex": "Supreme[_ ]Pure[ _]Plus(?:[);/ ]|$)",
                "model": "Supreme Pure Plus"
            },
            {
                "regex": "Supreme Pure(?:[);/ ]|$)",
                "model": "Supreme Pure"
            },
            {
                "regex": "Supreme[_ ]Pro(?:[);/ ]|$)",
                "model": "Supreme Pro"
            },
            {
                "regex": "Navon[_ ]Infinity(?:[);/ ]|$)",
                "model": "Infinity"
            },
            {
                "regex": "(?:Navon[_ ])?Platinum[ _]10[ _]3G(_V2)?(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Platinum 10.1\" 3G$1"
            },
            {
                "regex": "(?:Navon[_ ])?Orpheus[ _]3G(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Orpheus 3G"
            },
            {
                "regex": "Predator (3G|10)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Predator $1"
            },
            {
                "regex": "Vision_Tab_(7|10)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Vision Tab $1"
            }
        ]
    },
    "NOA": {
        "regex": "(?:NOA-)?NOA[ _]|Hummer(?:_2019_R|LE)|NextSE",
        "device": "smartphone",
        "models": [
            {
                "regex": "NOA[_ ]CORE[_ ]FORTE",
                "model": "Core Forte"
            },
            {
                "regex": "HummerLE",
                "model": "Hummer LE"
            },
            {
                "regex": "Hummer_2019_R",
                "model": "Hummer R (2019)"
            },
            {
                "regex": "NOA[_ ]Hummer[_ ]Lite",
                "model": "Hummer Lite"
            },
            {
                "regex": "NOA[_ ]MOVEse",
                "model": "Move SE"
            },
            {
                "regex": "NextSE",
                "model": "Next SE"
            },
            {
                "regex": "NOA[_ ]NEXT",
                "model": "Next"
            },
            {
                "regex": "NOA[_ ]VISION[_ ]H3SE",
                "model": "Vision H3SE"
            },
            {
                "regex": "NOA[_ ]VISION[_ ]H3",
                "model": "Vision H3"
            },
            {
                "regex": "NOA[_ ]LOOP",
                "model": "Loop"
            },
            {
                "regex": "(?:NOA-)?NOA[_ ]H4se",
                "model": "H4SE"
            },
            {
                "regex": "NOA[_ ]Mg12",
                "model": "MG12"
            },
            {
                "regex": "NOA[_ ]H44SE",
                "model": "H44SE"
            },
            {
                "regex": "NOA[_ ]H10LE",
                "model": "H10LE"
            },
            {
                "regex": "NOA[_ ]([^;)/ ]+)",
                "model": "$1"
            }
        ]
    },
    "Nobby": {
        "regex": "Nobby[ ](S500|X800|S300(?: Pro)?)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "NEC": {
        "regex": "NEC[ _\\-]|KGT/2\\.0|PC-TE508(?:S1|[BH]AW)|PC-TE510(?:HAW|BAL|S1)|PC-TS708T1W|PC-TS507N1S|PC-TE507JAW|portalmmm/1\\.0 (?:DB|N)|(?:portalmmm|o2imode)/2.0[ ,]N|(?:N-06C|N-02E|LAVIE Tab E (?:10|8)FHD1)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "N-06C(?:[);/ ]|$)",
                "model": "Medias Amadana"
            },
            {
                "regex": "N-02E(?:[);/ ]|$)",
                "model": "Medias U"
            },
            {
                "regex": "(?:NEC[ _\\-]|KGT/2\\.0 )([a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "portalmmm/1\\.0 ((?:DB|N)[a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "(?:portalmmm|o2imode)/2\\.0[ ,](N[a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "PC-TE508[BH]AW(?:[);/ ]|$)",
                "device": "tablet",
                "model": "LAVIE Tab E TE508"
            },
            {
                "regex": "PC-TE510(?:BAL|HAW|S1)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "LAVIE Tab E TE510"
            },
            {
                "regex": "PC-TS507N1S(?:[);/ ]|$)",
                "device": "tablet",
                "model": "LAVIE Tab S TS507"
            },
            {
                "regex": "PC-TS708T1W(?:[);/ ]|$)",
                "device": "tablet",
                "model": "LAVIE Tab S TS708"
            },
            {
                "regex": "PC-TE507JAW(?:[);/ ]|$)",
                "device": "tablet",
                "model": "LAVIE Tab E TS507"
            },
            {
                "regex": "PC-TE508S1(?:[);/ ]|$)",
                "device": "tablet",
                "model": "LAVIE Tab E TE508"
            },
            {
                "regex": "(LAVIE Tab E (?:10|8)FHD1)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "$1"
            }
        ]
    },
    "Neffos": {
        "regex": "Neffos|(TP601A)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "TP601A(?:[);/ ]|$)",
                "model": "C5L"
            },
            {
                "regex": "Neffos[_ ]Y50",
                "model": "Y50"
            },
            {
                "regex": "Neffos X20 Pro",
                "model": "X20 Pro"
            },
            {
                "regex": "Neffos[_ ]((?:[YNCXA])[0-9][A-Z]?(?: Max| Lite)?)",
                "model": "$1"
            }
        ]
    },
    "Nextbit": {
        "regex": "Nextbit|(?:Robin)(?: Build|\\))",
        "device": "smartphone",
        "models": [
            {
                "regex": "(Robin)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Newgen": {
        "regex": "NEWGEN\\-([a-z0-9]+)",
        "device": "feature phone",
        "model": "$1"
    },
    "Netgear": {
        "regex": "GTV100",
        "device": "tv",
        "models": [
            {
                "regex": "GTV100",
                "model": "NeoTV Prime"
            }
        ]
    },
    "NeuImage": {
        "regex": "NIM-",
        "device": "smartphone",
        "models": [
            {
                "regex": "NIM-(450D|550O|600Q)",
                "model": "$1"
            }
        ]
    },
    "NextBook": {
        "regex": "Next[0-9]|NX(?:785QC8G|M900MC|008HD8G|010HI8G|M908HC|M726|16A8116K|A8QC116|16A10132S|16A11264)",
        "device": "tablet",
        "models": [
            {
                "regex": "NX008HD8G",
                "model": "Premium 8 HD"
            },
            {
                "regex": "NXA8QC116",
                "model": "Ares 8"
            },
            {
                "regex": "NX16A8116K",
                "model": "Ares A8"
            },
            {
                "regex": "NX16A10132S",
                "model": "Ares A10"
            },
            {
                "regex": "NX16A11264",
                "model": "Ares A11"
            },
            {
                "regex": "NXM908HC",
                "model": "Premium 9"
            },
            {
                "regex": "NX010HI8G",
                "model": "Premium 10 Hi"
            },
            {
                "regex": "NX785QC8G",
                "model": "8"
            },
            {
                "regex": "NXM900MC",
                "model": "8 HD"
            },
            {
                "regex": "NXM726",
                "model": "Premium 7"
            },
            {
                "regex": "Next([0-9]+[^;/]+) Build",
                "model": "Next $1"
            }
        ]
    },
    "NGM": {
        "regex": "NGM[_ ][a-z0-9]+|(Forward|Dynamic)[ _]?[^/;]+(?:Build|/)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(Forward|Dynamic)[ _]?([^/;]+) Build",
                "model": "$1 $2"
            },
            {
                "regex": "(Forward|Dynamic)[ _]?([^/;]+)/",
                "model": "$1 $2"
            },
            {
                "regex": "NGM ([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "NGM_([a-z0-9]+)",
                "model": "$1"
            }
        ]
    },
    "Nexian": {
        "regex": "S?Nexian",
        "device": "smartphone",
        "models": [
            {
                "regex": "S?Nexian[ ]?([a-z0-9\\-]+)",
                "model": "$1"
            },
            {
                "regex": "S?Nexian-([a-z0-9]+)",
                "model": "$1"
            }
        ]
    },
    "Noain": {
        "regex": "NOAIN",
        "device": "smartphone",
        "models": [
            {
                "regex": "NOAIN[ _-]([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "NOAIN[ _-]([^;/)]+)(?:[;/)]|$)",
                "model": "$1"
            }
        ]
    },
    "Noblex": {
        "regex": "Noblex|NBX-",
        "device": "smartphone",
        "models": [
            {
                "regex": "(?:Noblex|NBX)[ _-]([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "(?:Noblex|NBX)[ _-]([^;/)]+)(?:[;/)]|$)",
                "model": "$1"
            }
        ]
    },
    "Nomu": {
        "regex": "Nomu[ _-](?:T18|S30mini|S10)(?:[);/ ]|$)|V1600S(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Nomu[ _-]T18(?:[);/ ]|$)",
                "model": "T18"
            },
            {
                "regex": "Nomu[ _-]s10(?:[);/ ]|$)",
                "model": "S10"
            },
            {
                "regex": "Nomu[ _-]S30mini(?:[);/ ]|$)",
                "model": "S30 Mini"
            },
            {
                "regex": "(V1600S)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Nomi": {
        "regex": "Nomi[ _-]|(C101010 Ultra2|i501[234]|i5032|i50[57]0|i5510|i5532|C0(700(?:[02358]|2HD)|7850)|C1010[0-3])(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "i501[23](?:[);/ ]|$)",
                "model": "Evo M2"
            },
            {
                "regex": "I5070(?:[);/ ]|$)",
                "model": "Iron-X"
            },
            {
                "regex": "i5014(?:[);/ ]|$)",
                "model": "Evo M4"
            },
            {
                "regex": "i5032(?:[);/ ]|$)",
                "model": "Evo X2"
            },
            {
                "regex": "i5050(?:[);/ ]|$)",
                "model": "Evo Z"
            },
            {
                "regex": "Nomi_i4510(?:[);/ ]|$)",
                "model": "Beat M"
            },
            {
                "regex": "i5532(?:[);/ ]|$)",
                "model": "Space X2"
            },
            {
                "regex": "i5510(?:[);/ ]|$)",
                "model": "Space M"
            },
            {
                "regex": "C101010 Ultra2",
                "model": "Ultra 2",
                "device": "tablet"
            },
            {
                "regex": "(C0(?:700(?:[02358]|2HD)|7850)|C1010[0-3])(?:[);/ ]|$)",
                "model": "$1",
                "device": "tablet"
            },
            {
                "regex": "Nomi[ _-]([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "Nomi[ _-]([^;/)]+)(?:[;/)]|$)",
                "model": "$1"
            }
        ]
    },
    "NUU Mobile": {
        "regex": "NUU_|(?:(?:N(5702|5001)L|S6001L)|A6L-[CG])(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "NUU_A([13])",
                "model": "A$1"
            },
            {
                "regex": "NUU_M([32])(?:[);/ ]|$)",
                "model": "M$1"
            },
            {
                "regex": "NUU_X5(?:[);/ ]|$)",
                "model": "X5"
            },
            {
                "regex": "S6001L",
                "model": "G2"
            },
            {
                "regex": "N5702L",
                "model": "G3"
            },
            {
                "regex": "(N5001L|A6L-[CG])(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "NYX Mobile": {
        "regex": "NYX[ _]",
        "device": "smartphone",
        "models": [
            {
                "regex": "NYX_A1",
                "model": "A1"
            },
            {
                "regex": "NYX_Blink",
                "model": "Blink"
            },
            {
                "regex": "NYX_EGO",
                "model": "Ego"
            },
            {
                "regex": "NYX_FENIX",
                "model": "Fenix"
            },
            {
                "regex": "NYX_FLY_II",
                "model": "Fly II"
            },
            {
                "regex": "NYX_FLY_MINI",
                "model": "Fly Mini"
            },
            {
                "regex": "NYX_FLY",
                "model": "Fly"
            },
            {
                "regex": "NYX_HIT",
                "model": "Hit"
            },
            {
                "regex": "NYX_JAK",
                "model": "Jak"
            },
            {
                "regex": "NYX_JOIN",
                "model": "Join"
            },
            {
                "regex": "NYX_NOBA_II",
                "model": "Noba II"
            },
            {
                "regex": "NYX_NOBA",
                "model": "Noba"
            },
            {
                "regex": "NYX_ORBIS",
                "model": "Orbis"
            },
            {
                "regex": "NYX[ _]REX",
                "model": "Rex"
            },
            {
                "regex": "NYX_SHADE",
                "model": "Shade"
            },
            {
                "regex": "NYX_SKY",
                "model": "Sky"
            },
            {
                "regex": "NYX_SPARK",
                "model": "Spark"
            },
            {
                "regex": "NYX_VOX",
                "model": "Vox"
            },
            {
                "regex": "NYX_ZEUZ_HD",
                "model": "Zeus HD"
            }
        ]
    },
    "Nous": {
        "regex": "(?:NS(?:[356]|500[2-68]|5511|5502)|NS 5511)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "NS 5511(?:[);/ ]|$)",
                "model": "NS5511"
            },
            {
                "regex": "(NS[0-9]{1,4})(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Nvidia": {
        "regex": "NVIDIA|SHIELD Tablet K1|SHIELD Android TV|Tegra(Note-P1640| Note 7)",
        "device": "tablet",
        "models": [
            {
                "regex": "TegraNote-P1640",
                "model": "Tegra Note P1640"
            },
            {
                "regex": "(SHIELD Tablet K1|Tegra Note 7)(?: Build|[;)])",
                "model": "$1"
            },
            {
                "regex": "SHIELD Android TV",
                "model": "SHIELD Android TV",
                "device": "tv"
            },
            {
                "regex": "NVIDIA_SHIELD_PRO_2019",
                "model": "SHIELD TV Pro",
                "device": "tv"
            },
            {
                "regex": "NVIDIA Shield",
                "model": "SHIELD TV",
                "device": "tv"
            }
        ]
    },
    "O+": {
        "regex": "O\\+[ _](Air|Crunch|Ultra|Upsized|Venti|360 ALPHA PLUS 2.0)",
        "device": "smartphone",
        "models": [
            {
                "regex": "O\\+[ _](Crunch|Ultra 2.0|Ultra|Upsized|Venti 4G)",
                "model": "$1"
            },
            {
                "regex": "O\\+_AIR",
                "model": "Air"
            },
            {
                "regex": "O\\+ 360 ALPHA PLUS 2.0",
                "model": "360 Alpha Plus 2.0"
            }
        ]
    },
    "O2": {
        "regex": "Xda|(?<!FBCR/)O2[ \\-]|COCOON",
        "device": "smartphone",
        "models": [
            {
                "regex": "(Xda[ _][a-z0-9_]+)",
                "model": "$1"
            },
            {
                "regex": "(COCOON)",
                "model": "$1"
            },
            {
                "regex": "O2 ([a-z0-9 ]+)",
                "model": "$1"
            },
            {
                "regex": "O2-([a-z0-9]+)",
                "model": "$1"
            }
        ]
    },
    "Odys": {
        "regex": "ODYS[ _-]|IEOS[_ ]([^/;]+)|NOON(?:_PRO)? Build|SPACE10_(?:PLUS|PRO)_3G|THOR_?10|TAO_X10|RAPID_?(?:10|7)_?LTE|MAVEN_?10_|CONNECT[78]|ELEMENT10_PLUS_3G|XELIO[_0-9P]|ADM816HC|ADM8000KP|NEO6_LTE|EOS10|AEON|FALCON_10_PLUS_3G|FUSION|THANOS_10|MAVEN_X10_HD(?:_LTE)?|JUNIOR_8_PRO|JuniorT8Pro|(?:NOVA|PYRO)_X?7_PLUS_3G|UNO_X8|SLADE_X55|XELIO_PRO_10_LTE|NOTOSplus3G",
        "device": "tablet",
        "models": [
            {
                "regex": "XELIO[_ ]A10",
                "model": "Xelio A10"
            },
            {
                "regex": "XELIOPHONETAB3",
                "model": "Xelio Phonetab 3"
            },
            {
                "regex": "THANOS_10",
                "model": "Thanos 10"
            },
            {
                "regex": "XELIO_NEXT_10_PLUS_3G",
                "model": "Xelio Next 10 Plus 3G"
            },
            {
                "regex": "XELIO_PHONE_TAB7_3G",
                "model": "Xelio Phonetab 7 3G"
            },
            {
                "regex": "XELIO10EXTREME",
                "model": "Xelio 10 Xtreme"
            },
            {
                "regex": "XELIO10_PLUS_3G",
                "model": "Xelio 10 Plus 3G"
            },
            {
                "regex": "XELIO10_HD_PLUS_3G",
                "model": "Xelio 10 HD Plus 3G"
            },
            {
                "regex": "XELIO_10_HD",
                "model": "Xelio 10 HD"
            },
            {
                "regex": "XELIO_PRO_10_LTE",
                "model": "Xelio Pro 10 LTE"
            },
            {
                "regex": "ELEMENT10_PLUS_3G",
                "model": "Element 10 Plus 3G"
            },
            {
                "regex": "CONNECT8PLUS",
                "model": "Connect 8 Plus"
            },
            {
                "regex": "CONNECT7PRO",
                "model": "Connect 7 Pro"
            },
            {
                "regex": "MAVEN_X10_HD_LTE",
                "model": "Maven X10 HD LTE"
            },
            {
                "regex": "MAVEN_X10_HD",
                "model": "Maven X10 HD"
            },
            {
                "regex": "MAVEN_?10_PRO_PLUS_3G",
                "model": "Maven 10 Pro Plus 3G"
            },
            {
                "regex": "MAVEN_?10_?PLUS",
                "model": "Maven 10 Plus"
            },
            {
                "regex": "MAVEN_?10_?PRO",
                "model": "Maven 10 Pro"
            },
            {
                "regex": "MAVEN_?10_?HD",
                "model": "Maven 10 HD"
            },
            {
                "regex": "RAPID_?7_?LTE",
                "model": "Rapid 7 LTE"
            },
            {
                "regex": "RAPID_?10_?LTE",
                "model": "Rapid 10 LTE"
            },
            {
                "regex": "TAO_X10",
                "model": "Tao X10"
            },
            {
                "regex": "SPACE10_PLUS_3G",
                "model": "Space 10 Plus 3G"
            },
            {
                "regex": "SPACE10_PRO_3G",
                "model": "Space 10 Pro 3G"
            },
            {
                "regex": "THOR_?10 Build",
                "model": "Thor 10"
            },
            {
                "regex": "JuniorT8Pro",
                "model": "Junior T8 Pro"
            },
            {
                "regex": "JUNIOR_8_PRO",
                "model": "Junior 8 Pro"
            },
            {
                "regex": "ADM816HC",
                "model": "Neo X"
            },
            {
                "regex": "EOS10",
                "model": "EOS 10"
            },
            {
                "regex": "AEON",
                "model": "Aeon"
            },
            {
                "regex": "FALCON_10_PLUS_3G",
                "model": "Falcon 10 Plus 3G"
            },
            {
                "regex": "FUSION",
                "model": "Fusion 7"
            },
            {
                "regex": "UNO_X8",
                "model": "Uno X8"
            },
            {
                "regex": "ADM8000KP",
                "model": "Titan"
            },
            {
                "regex": "PYRO_7_PLUS_3G",
                "model": "Pyro 7 Plus 3G"
            },
            {
                "regex": "NOVA_X7_PLUS_3G",
                "model": "Nova X7 Plus 3G"
            },
            {
                "regex": "ODYS_NOVA_X7",
                "model": "Nova X7"
            },
            {
                "regex": "THOR_?10_PLUS_3G",
                "model": "Thor 10 Plus 3G"
            },
            {
                "regex": "NOTOSplus3G",
                "model": "Notos Plus 3G"
            },
            {
                "regex": "NEO6[_ ]LTE",
                "model": "Neo 6 LTE",
                "device": "smartphone"
            },
            {
                "regex": "SLADE_X55",
                "model": "Slade X55",
                "device": "smartphone"
            },
            {
                "regex": "IEOS[ _]([^/;]+) Build",
                "model": "Ieos $1"
            },
            {
                "regex": "(?:ODYS[ _-])?NOON Build",
                "model": "Noon"
            },
            {
                "regex": "(?:ODYS[ _-])?NOON_PRO Build",
                "model": "Noon Pro"
            },
            {
                "regex": "Odys[ _-]([^/;]+) Build",
                "model": "$1"
            }
        ]
    },
    "Obi": {
        "regex": "Obi[ _-]|(SJ1\\.5|SJ2\\.6|S400|S452\\+|S451|S453|S501|S502|S503\\+?|S507|S520|S550|S551|falcon)[ _]",
        "device": "smartphone",
        "models": [
            {
                "regex": "SJ2\\.6",
                "model": "SJ2.6"
            },
            {
                "regex": "SJ1\\.5",
                "model": "SJ1.5"
            },
            {
                "regex": "(?:Obi_)?S400",
                "model": "Skipper"
            },
            {
                "regex": "(?:Obi_)?S451|falcon",
                "model": "Flacon"
            },
            {
                "regex": "(?:Obi_)?S452\\+",
                "model": "Python"
            },
            {
                "regex": "(?:Obi_)?S453",
                "model": "Fox"
            },
            {
                "regex": "(?:Obi_)?S501",
                "model": "Wolverine"
            },
            {
                "regex": "(?:Obi_)?S502",
                "model": "Leopard"
            },
            {
                "regex": "(?:Obi_)?S503\\+",
                "model": "Boa Plus"
            },
            {
                "regex": "(?:Obi_)?S503",
                "model": "Boa"
            },
            {
                "regex": "(?:Obi_)?S507",
                "model": "Pelican"
            },
            {
                "regex": "(?:Obi_)?S520",
                "model": "Octopus"
            },
            {
                "regex": "(?:Obi_)?S550",
                "model": "Crane"
            },
            {
                "regex": "(?:Obi_)?S551",
                "model": "Hornbill"
            },
            {
                "regex": "(?:Obi_)?S454",
                "model": "Alligator"
            },
            {
                "regex": "Obi[ _-]([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "Obi[ _-]([^;/)]+)(?:[;/)]|$)",
                "model": "$1"
            }
        ]
    },
    "Okapia": {
        "regex": "Signature (?:L|Pro|Touch)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Signature (L|Pro|Touch)",
                "model": "Signature $1"
            }
        ]
    },
    "Onda": {
        "regex": "Onda|(?:(?:V820w|V891[w]?|V919(?: 3G)? Air)[ _-]DualOS|(?:V919 4G Air|V989 Air|V972) Core[48]|T2101L2B1C| V10 4G|OBOOK (?:20 SE|20 PLUS DUALOS)|OBOOK10 SE|V919 Air CH DualOS)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "ONDA[ _]MID",
                "model": "MID",
                "device": "tablet"
            },
            {
                "regex": "V820w[ _-]DualOS",
                "model": "V820W Dual OS",
                "device": "tablet"
            },
            {
                "regex": "V891W[ _-]DualOS",
                "model": "V891W Dual OS",
                "device": "tablet"
            },
            {
                "regex": "V891[ _-]DualOS",
                "model": "V891 Dual OS",
                "device": "tablet"
            },
            {
                "regex": "V919 3G Air[ _-]DualOS",
                "model": "V919 3G Air Dual OS",
                "device": "tablet"
            },
            {
                "regex": "V919 Air[ _-]DualOS",
                "model": "V919 Air Dual OS",
                "device": "tablet"
            },
            {
                "regex": "V919 Air CH DualOS",
                "model": "V919 Air CH Dual OS",
                "device": "tablet"
            },
            {
                "regex": "V919 4G Air Core8",
                "model": "V919 4G Air Core 8",
                "device": "tablet"
            },
            {
                "regex": "V972 Core4",
                "model": "V919 Core 4",
                "device": "tablet"
            },
            {
                "regex": "V989 Air Core8",
                "model": "V989 Air Core 8",
                "device": "tablet"
            },
            {
                "regex": "T2101L2B1C|OBOOK 20 PLUS DUALOS",
                "model": "oBook 20 Plus Dual OS",
                "device": "tablet"
            },
            {
                "regex": " V10 4G(?:[);/ ]|$)",
                "model": "V10 10.1\" 4G",
                "device": "tablet"
            },
            {
                "regex": "OBOOK 20 SE(?:[);/ ]|$)",
                "model": "oBook 20 SE",
                "device": "tablet"
            },
            {
                "regex": "OBOOK10 SE(?:[);/ ]|$)",
                "model": "oBook 10 SE",
                "device": "tablet"
            },
            {
                "regex": "([a-z0-9]+)[ _]Onda",
                "model": "$1"
            },
            {
                "regex": "Onda ([a-z0-9]+)",
                "model": "$1"
            }
        ]
    },
    "OnePlus": {
        "regex": "(?:du_)?ONEPLUS|(?:A0001|A200[135]|AC200[13]|A300[03]|A3010|A5000|A600[03]|A601[03]|BE201[1235]|BE2025|BE202[89]|E100[135]|GM191[03517]|GM190[0135]|GM192[05]|HD191[013]|HD190[01357]|HD1925|IN201[013579]|IN202[0135]|KB200[01357]|LE2117|LE212[03])(?: Build|\\))",
        "device": "smartphone",
        "models": [
            {
                "regex": "AC200[13](?:[);/ ]|$)",
                "model": "Nord 5G"
            },
            {
                "regex": "BE2012(?:[);/ ]|$)",
                "model": "Clover"
            },
            {
                "regex": "BE201[135](?:[);/ ]|$)",
                "model": "Nord N100"
            },
            {
                "regex": "BE2025(?:[);/ ]|$)",
                "model": "Nord N10"
            },
            {
                "regex": "BE2029(?:[);/ ]|$)",
                "model": "Nord N10 5G"
            },
            {
                "regex": "BE2028(?:[);/ ]|$)",
                "model": "Nord N10 Lite"
            },
            {
                "regex": "IN202[0135](?:[);/ ]|$)",
                "model": "8 Pro"
            },
            {
                "regex": "KB2000(?:[);/ ]|$)",
                "model": "8T"
            },
            {
                "regex": "IN201[01357](?:[);/ ]|$)",
                "model": "8"
            },
            {
                "regex": "IN2019(?:[);/ ]|$)",
                "model": "8 5G UW"
            },
            {
                "regex": "LE2117(?:[);/ ]|$)",
                "model": "9"
            },
            {
                "regex": "LE212[03](?:[);/ ]|$)",
                "model": "9 Pro"
            },
            {
                "regex": "(?:GM191[01357]|OnePlus 7 Pro)(?:[);/ ]|$)",
                "model": "7 Pro"
            },
            {
                "regex": "GM190[0135](?:[);/ ]|$)",
                "model": "7"
            },
            {
                "regex": "HD190[01357](?:[);/ ]|$)",
                "model": "7T"
            },
            {
                "regex": "(?:HD191[013]|OnePlus 7T Pro)(?:[);/ ]|$)",
                "model": "7T Pro"
            },
            {
                "regex": "(?:HD1925|GM192[05])(?:[);/ ]|$)",
                "model": "7T Pro 5G"
            },
            {
                "regex": "KB200[1357](?:[);/ ]|$)",
                "model": "8T"
            },
            {
                "regex": "A0001(?:[);/ ]|$)",
                "model": "One"
            },
            {
                "regex": "A200[135]|OnePlus2",
                "model": "2"
            },
            {
                "regex": "E100[135](?:[);/ ]|$)",
                "model": "X"
            },
            {
                "regex": "A3010|OnePlus3T",
                "model": "3T"
            },
            {
                "regex": "A300[03]|OnePlus3",
                "model": "3"
            },
            {
                "regex": "A5010|OnePlus[ ]?5T",
                "model": "5T"
            },
            {
                "regex": "A5000|OnePlus5",
                "model": "5"
            },
            {
                "regex": "A600[03]|OnePlus6",
                "model": "6"
            },
            {
                "regex": "A601[03](?:[);/ ]|$)",
                "model": "6T"
            },
            {
                "regex": "(?:du_)?ONEPLUS ?([^;/]+) Build",
                "model": "$1"
            }
        ]
    },
    "Realme": {
        "regex": "Realme[ _]|(?:RMX(?:19(03|4[1235]|19|9[23]|2[157]|[01379]1|73)|20(?:[025-7]1|[037]2|2[57]|63|7[56]|8[156]|[2-5]0)|21(?:0[13]|17|[12]1|4[24]|5[15]|6[13]|7[60]|8[059]|9[35])|2200|18(0[1579]|11|3[13]|2[157]|[45]1|45)|3201|30[38]1|3063)|(?:OPPO[ _]?)?CPH1861)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(?:OPPO[ _]?)?CPH1861(?:[);/ ]|$)",
                "model": "1"
            },
            {
                "regex": "RMX180[59]",
                "model": "2"
            },
            {
                "regex": "RMX180[17]",
                "model": "2 Pro"
            },
            {
                "regex": "RMX(1833|182[15])",
                "model": "3"
            },
            {
                "regex": "RMX1827",
                "model": "3i"
            },
            {
                "regex": "RMX1851",
                "model": "3 Pro"
            },
            {
                "regex": "RMX190[13]|Realme X(?:[);/ ]|$)",
                "model": "X"
            },
            {
                "regex": "RMX199[12]",
                "model": "X2"
            },
            {
                "regex": "RMX1993",
                "model": "X2 Dual"
            },
            {
                "regex": "RMX1931|Realme X2 Pro(?:[);/ ]|$)",
                "model": "X2 Pro"
            },
            {
                "regex": "RMX2117",
                "model": "Q2 5G"
            },
            {
                "regex": "RMX2176(?:[);/ ]|$)",
                "model": "X7 5G"
            },
            {
                "regex": "RMX2121(?:[);/ ]|$)",
                "model": "X7 Pro 5G"
            },
            {
                "regex": "RMX1921|Realme XT(?:[);/ ]|$)",
                "model": "XT"
            },
            {
                "regex": "RMX19(1[19]|27)",
                "model": "5"
            },
            {
                "regex": "RMX197[13]",
                "model": "5 Pro"
            },
            {
                "regex": "RMX203[02]",
                "model": "5i"
            },
            {
                "regex": "RMX2001",
                "model": "6"
            },
            {
                "regex": "RMX2040",
                "model": "6I"
            },
            {
                "regex": "RMX2002",
                "model": "6S"
            },
            {
                "regex": "RMX206[13](?:[);/ ]|$)",
                "model": "6 Pro"
            },
            {
                "regex": "RMX215[15]",
                "model": "7"
            },
            {
                "regex": "RMX2103",
                "model": "7I"
            },
            {
                "regex": "RMX2170",
                "model": "7 Pro"
            },
            {
                "regex": "RMX3081",
                "model": "8 Pro"
            },
            {
                "regex": "RMX1925(?:[);/ ]|$)",
                "model": "5S"
            },
            {
                "regex": "RMX1811(?:[);/ ]|$)",
                "model": "C1"
            },
            {
                "regex": "RMX194[1235](?:[);/ ]|$)",
                "model": "C2"
            },
            {
                "regex": "RMX202[107](?:[);/ ]|$)",
                "model": "C3"
            },
            {
                "regex": "RMX2185(?:[);/ ]|$)",
                "model": "C11"
            },
            {
                "regex": "RMX2189(?:[);/ ]|$)",
                "model": "C12"
            },
            {
                "regex": "RMX2180(?:[);/ ]|$)",
                "model": "C15"
            },
            {
                "regex": "RMX2195(?:[);/ ]|$)",
                "model": "C15 Qualcomm Edition"
            },
            {
                "regex": "RMX2101(?:[);/ ]|$)",
                "model": "C17"
            },
            {
                "regex": "RMX3063(?:[);/ ]|$)",
                "model": "C20"
            },
            {
                "regex": "RMX3031(?:[);/ ]|$)",
                "model": "GT Neo"
            },
            {
                "regex": "RMX216[13](?:[);/ ]|$)",
                "model": "Narzo 20 Pro"
            },
            {
                "regex": "RMX2193(?:[);/ ]|$)",
                "model": "Narzo 20"
            },
            {
                "regex": "RMX2050(?:[);/ ]|$)",
                "model": "Narzo 20A"
            },
            {
                "regex": "RMX1831(?:[);/ ]|$)",
                "model": "U1"
            },
            {
                "regex": "RMX(?:20(?:51|[2]5)|2144)(?:[);/ ]|$)",
                "model": "X50 5G"
            },
            {
                "regex": "RMX2071(?:[);/ ]|$)",
                "model": "X50 Pro"
            },
            {
                "regex": "RMX207[56](?:[);/ ]|$)",
                "model": "X50 Pro 5G"
            },
            {
                "regex": "RMX2072(?:[);/ ]|$)",
                "model": "X50 Pro Player"
            },
            {
                "regex": "RMX(2142|2081)(?:[);/ ]|$)",
                "model": "X3"
            },
            {
                "regex": "RMX208[56](?:[);/ ]|$)",
                "model": "X3 Super Zoom"
            },
            {
                "regex": "RMX2111",
                "model": "V5 5G"
            },
            {
                "regex": "RMX2200",
                "model": "V3 5G"
            },
            {
                "regex": "RMX3201",
                "model": "C21"
            },
            {
                "regex": "Realme[ _]([1-9])[ _]Pro(?:[);/ ]|$)",
                "model": "$1 Pro"
            }
        ]
    },
    "OPPO": {
        "regex": "(?:OB-)?OPPO[ _]?([a-z0-9]+)|N1T|R8001|OPG01|A00[12]OP|(?:X90[07][0679]|U70[57]T?|X909T?|R(?:10[01]1|2001|201[07]|6007|7005|7007|80[13579]|81[13579]|82[01379]|83[013]|800[067]|8015|810[679]|811[13]|820[057])[KLSTW]?|N520[79]|N5117|A33f|A33fw|A37fw?|PAAM00|PAAT00|PAC[TM]00)(?:[);/ ]|$)|R7kf|R7plusf|R7Plusm|A1601|CPH[0-9]{4}|CPH19(69|79|23|1[179])|PB(A[TM]00|CT10|BT30|CM[13]0|[FD]M00)|P(DAM10|ADM00|AF[TM]00|ADT00|AHM00|BBM[03]0|BBT00|BDT00|BFT00|[CB]E[MT]00|CA[MT]00|C[CDG]M00|CA[MT]10|[CD]PM00|CRM00|CDT00|CD[TM]10|CHM[013]0|CKM[08]0|CLM[15]0|DEM[13]0|DHM00|DK[TM]00|DPT00|DB[TM]00|DCM00|[CD]NM00|DVM00|DY[TM]20|DNT00|EA[TM]00)|PDSM00",
        "device": "smartphone",
        "models": [
            {
                "regex": "PCHM10(?:[);/ ]|$)",
                "model": "A11"
            },
            {
                "regex": "(?:CPH2083|CPH2077)(?:[);/ ]|$)",
                "model": "A12"
            },
            {
                "regex": "PCHM00(?:[);/ ]|$)",
                "model": "A11x"
            },
            {
                "regex": "CPH1923(?:[);/ ]|$)",
                "model": "A1K"
            },
            {
                "regex": "(?:OPPO[ _]?)?(CPH1837|PAD[TM]00)(?:[);/ ]|$)",
                "model": "A3"
            },
            {
                "regex": "(?:OPPO[ _]?)?A37f(w)(?:[);/ ]|$)",
                "model": "A37f$1"
            },
            {
                "regex": "A37f(?:[);/ ]|$)",
                "model": "A37f"
            },
            {
                "regex": "(?:OPPO[ _]?)?CPH1605(?:[);/ ]|$)",
                "model": "A39"
            },
            {
                "regex": "CPH20(?:81|73|31|15)(?:[);/ ]|$)",
                "model": "A31"
            },
            {
                "regex": "(?:OPPO[ _]?)?(?:CPH18(?:0[35]|53)|PBBM30)(?:[);/ ]|$)",
                "model": "A3s"
            },
            {
                "regex": "(?:OPPO[ _]?)?(?:CPH1809|PBA[TM]00|PBBT30)(?:[);/ ]|$)",
                "model": "A5"
            },
            {
                "regex": "CPH19(?:3[13]|43)(?:[);/ ]|$)",
                "model": "A5 (2020)"
            },
            {
                "regex": "PDVM00(?:[);/ ]|$)",
                "model": "A32"
            },
            {
                "regex": "(?:PDAM10|CPH2069|CPH2061)(?:[);/ ]|$)",
                "model": "A52"
            },
            {
                "regex": "CPH2127(?:[);/ ]|$)",
                "model": "A53"
            },
            {
                "regex": "(?:OPPO[ _]?)?CPH170[15](?:[);/ ]|$)",
                "model": "A57"
            },
            {
                "regex": "CPH19(?:09|1[02])(?:[);/ ]|$)",
                "model": "A5S"
            },
            {
                "regex": "(?:OPPO[ _]?)?(?:CPH190[15]|PBF[TM]00)(?:[);/ ]|$)",
                "model": "A7"
            },
            {
                "regex": "(?:OPPO[ _]?)?(CPH1801|CPH1717)(?:[);/ ]|$)",
                "model": "A71"
            },
            {
                "regex": "(?:OPPO[ _]?)?(?:PDY[TM]20|CPH2067)(?:[);/ ]|$)",
                "model": "A72"
            },
            {
                "regex": "(?:OPPO[ _]?)?CPH1715(?:[);/ ]|$)",
                "model": "A77"
            },
            {
                "regex": "PCD[TM]00(?:[);/ ]|$)",
                "model": "A7n"
            },
            {
                "regex": "(PBBT00|PBBM00)(?:[);/ ]|$)",
                "model": "A7x"
            },
            {
                "regex": "(?:OPPO[ _]?)?(?:PDBM00)(?:[);/ ]|$)",
                "model": "A8"
            },
            {
                "regex": "(?:OPPO[ _]?)?CPH1729(?:[);/ ]|$)",
                "model": "A83"
            },
            {
                "regex": "(?:OPPO[ _]?)?CPH1827(?:[);/ ]|$)",
                "model": "A83 (2018)"
            },
            {
                "regex": "PCA[TM]10(?:[);/ ]|$)",
                "model": "A9"
            },
            {
                "regex": "(CPH1937|CPH1941|PCHM30)(?:[);/ ]|$)",
                "model": "A9 (2020)"
            },
            {
                "regex": "(?:OPPO[ _]?)?CPH1938(?:[);/ ]|$)",
                "model": "A9 (EN)"
            },
            {
                "regex": "(?:PCPM00|CPH20[02]1)(?:[);/ ]|$)",
                "model": "A91"
            },
            {
                "regex": "CPH2059(?:[);/ ]|$)",
                "model": "A92"
            },
            {
                "regex": "PDKT00(?:[);/ ]|$)",
                "model": "A92s"
            },
            {
                "regex": "PDKM00(?:[);/ ]|$)",
                "model": "A93s"
            },
            {
                "regex": "PCE[TM]00(?:[);/ ]|$)",
                "model": "A9x"
            },
            {
                "regex": "(?:OPPO[ _]?)?CPH1851(?:[);/ ]|$)",
                "model": "AX5"
            },
            {
                "regex": "CPH1920(?:[);/ ]|$)",
                "model": "AX5s"
            },
            {
                "regex": "(?:OPPO[ _]?)?CPH1903(?:[);/ ]|$)",
                "model": "AX7"
            },
            {
                "regex": "(?:OPPO[ _]?)?X9009(?:[);/ ]|$)",
                "model": "F1 Plus"
            },
            {
                "regex": "CPH191[135](?:[);/ ]|$)",
                "model": "F11"
            },
            {
                "regex": "CPH19(?:69|87)(?:[);/ ]|$)",
                "model": "F11 Pro"
            },
            {
                "regex": "(?:OPPO[ _]?)?(?:CPH2119)(?:[);/ ]|$)",
                "model": "F17 Pro"
            },
            {
                "regex": "(?:OPPO[ _]?)?A1601(?:[);/ ]|$)",
                "model": "F1s"
            },
            {
                "regex": "(?:OPPO[ _]?)?CPH1609(?:[);/ ]|$)",
                "model": "F3"
            },
            {
                "regex": "(?:OPPO[ _]?)?CPH1613(?:[);/ ]|$)",
                "model": "F3 Plus"
            },
            {
                "regex": "(?:OPPO[ _]?)?CPH172[37](?:[);/ ]|$)",
                "model": "F5"
            },
            {
                "regex": "(?:OPPO[ _]?)?CPH1725(?:[);/ ]|$)",
                "model": "F5 Youth"
            },
            {
                "regex": "(?:OPPO[ _]?)?CPH(1859|18(?:19|21))(?:[);/ ]|$)",
                "model": "F7"
            },
            {
                "regex": "(?:OPPO[ _]?)?(?:CPH1825|CPH1881)(?:[);/ ]|$)",
                "model": "F9"
            },
            {
                "regex": "(?:OPPO[ _]?)?CPH1823(?:[);/ ]|$)",
                "model": "F9 Pro"
            },
            {
                "regex": "(?:OPPO[ _]?)?X909T?(?:[);/ ]|$)",
                "model": "Find 5"
            },
            {
                "regex": "(?:OPPO[ _]?)?R827T?(?:[);/ ]|$)",
                "model": "Find 5 Mini"
            },
            {
                "regex": "(?:OPPO[ _]?)?X907[067](?:[);/ ]|$)",
                "model": "Find 7"
            },
            {
                "regex": "(?:OPPO[ _]?)?X900[067](?:[);/ ]|$)",
                "model": "Find 7a"
            },
            {
                "regex": "(?:OPPO[ _]?)?R815[TW]?(?:[);/ ]|$)",
                "model": "Find Clover"
            },
            {
                "regex": "(?:OPPO[ _]?)?R8015(?:[);/ ]|$)",
                "model": "Find Guitar"
            },
            {
                "regex": "(?:OPPO[ _]?)?R8111(?:[);/ ]|$)",
                "model": "Find Melody"
            },
            {
                "regex": "(?:OPPO[ _]?)?R821T?(?:[);/ ]|$)",
                "model": "Find Muse"
            },
            {
                "regex": "(?:OPPO[ _]?)?U707T?(?:[);/ ]|$)",
                "model": "Find Way S"
            },
            {
                "regex": "(?:OPPO[ _]?)?U705T(?:[);/ ]|$)",
                "model": "Ulike 2"
            },
            {
                "regex": "(?:OPPO[ _]?)?(?:CPH187[15]|PAF[MT]00)(?:[);/ ]|$)",
                "model": "Find X"
            },
            {
                "regex": "(?:OPPO[ _]?)?(?:PAHM00)(?:[);/ ]|$)",
                "model": "Find X Lamborghini"
            },
            {
                "regex": "(?:OPPO[ _]?)?(?:PDEM10|CPH2023)(?:[);/ ]|$)",
                "model": "Find X2"
            },
            {
                "regex": "(?:OPPO[ _]?)?CPH2005(?:[);/ ]|$)",
                "model": "Find X2 Lite"
            },
            {
                "regex": "(?:OPPO[ _]?)?(?:PDEM30|OPG01|CPH2025)(?:[);/ ]|$)",
                "model": "Find X2 Pro"
            },
            {
                "regex": "(?:OPPO[ _]?)?R1011 Build",
                "model": "Joy Plus"
            },
            {
                "regex": "(?:OPPO[ _]?)?(PBC(?:M30|T10))(?:[);/ ]|$)",
                "model": "K1"
            },
            {
                "regex": "CPH1955(?:[);/ ]|$)",
                "model": "K3"
            },
            {
                "regex": "(?:OPPO[ _]?)?(PCNM00)(?:[);/ ]|$)",
                "model": "K5"
            },
            {
                "regex": "(?:OPPO[ _]?)?(PCLM50)(?:[);/ ]|$)",
                "model": "K7"
            },
            {
                "regex": "(?:OPPO[ _]?)?N5117(?:[);/ ]|$)",
                "model": "N1 Mini"
            },
            {
                "regex": "(?:OPPO[ _]?)?N520[79](?:[);/ ]|$)",
                "model": "N3"
            },
            {
                "regex": "(?:OPPO[ _]?)?R831T?(?:[);/ ]|$)",
                "model": "Neo"
            },
            {
                "regex": "(?:OPPO[ _]?)?R831K(?:[);/ ]|$)",
                "model": "Neo 3"
            },
            {
                "regex": "(?:OPPO[ _]?)?R831[SL](?:[);/ ]|$)",
                "model": "Neo 5"
            },
            {
                "regex": "(?:OPPO[ _]?)?A33f(?:[);/ ]|$)",
                "model": "Neo 7"
            },
            {
                "regex": "(?:OPPO[ _]?)?A33fw(?:[);/ ]|$)",
                "model": "Neo 7s"
            },
            {
                "regex": "(?:OPPO[ _]?)?R8113(?:[);/ ]|$)",
                "model": "Piano"
            },
            {
                "regex": "(?:OPPO[ _]?)?CPH1707(?:[);/ ]|$)",
                "model": "R11"
            },
            {
                "regex": "R8001(?:[);/ ]|$)",
                "model": "R1K"
            },
            {
                "regex": "(?:OPPO[ _]?)?CPH1719(?:[);/ ]|$)",
                "model": "R11s"
            },
            {
                "regex": "(?:OPPO[ _]?)?CPH1721(?:[);/ ]|$)",
                "model": "R11s Plus"
            },
            {
                "regex": "(?:OPPO[ _]?)?(?:CPH1835|PAC[TM]00|PAAM00)(?:[);/ ]|$)",
                "model": "R15"
            },
            {
                "regex": "(?:OPPO[ _]?)?(?:CPH183[13]|PAAT00)(?:[);/ ]|$)",
                "model": "R15 Pro"
            },
            {
                "regex": "PBCM10(?:[);/ ]|$)",
                "model": "R15x"
            },
            {
                "regex": "(?:OPPO[ _]?)?(?:CPH1879|PBE[MT]00)(?:[);/ ]|$)",
                "model": "R17"
            },
            {
                "regex": "(?:OPPO[ _]?)?(?:CPH1893)(?:[);/ ]|$)",
                "model": "R17 Neo"
            },
            {
                "regex": "(?:OPPO[ _]?)?(?:CPH1877|PBD[MT]00)(?:[);/ ]|$)",
                "model": "R17 Pro"
            },
            {
                "regex": "(?:OPPO[ _]?)?R8006(?:[);/ ]|$)",
                "model": "R1L"
            },
            {
                "regex": "(?:OPPO[ _]?)?R800[07](?:[);/ ]|$)",
                "model": "R1S"
            },
            {
                "regex": "(?:OPPO[ _]?)?R810[679](?:[);/ ]|$)",
                "model": "R5"
            },
            {
                "regex": "(?:OPPO[ _]?)?R7kf(?:[);/ ]|$)",
                "model": "R7 Lite"
            },
            {
                "regex": "(?:OPPO[ _]?)?R7Plusm(?:[);/ ]|$)",
                "model": "R7 Plus"
            },
            {
                "regex": "(?:OPPO[ _]?)?R7Plusf(?:[);/ ]|$)",
                "model": "R7 Plus F"
            },
            {
                "regex": "(?:OPPO[ _]?)?X9079(?:[);/ ]|$)",
                "model": "R9 Plus"
            },
            {
                "regex": "(?:OPPO[ _]?)?CPH1607(?:[);/ ]|$)",
                "model": "R9s"
            },
            {
                "regex": "(?:OPPO[ _]?)?(?:CPH1611|R9s Plus)(?:[);/ ]|$)",
                "model": "R9s Plus"
            },
            {
                "regex": "(?:CPH1917|PCA[MT]00)(?:[);/ ]|$)",
                "model": "Reno"
            },
            {
                "regex": "CPH1983(?:[);/ ]|$)",
                "model": "Reno A"
            },
            {
                "regex": "PCCM00(?:[);/ ]|$)",
                "model": "Reno 10X"
            },
            {
                "regex": "CPH1919(?:[);/ ]|$)",
                "model": "Reno 10X Zoom"
            },
            {
                "regex": "(?:PCKM00|CPH1907)(?:[);/ ]|$)",
                "model": "Reno 2"
            },
            {
                "regex": "CPH1989(?:[);/ ]|$)",
                "model": "Reno 2F"
            },
            {
                "regex": "(?:PCKM80|CPH1945|CPH1951)(?:[);/ ]|$)",
                "model": "Reno 2Z"
            },
            {
                "regex": "CPH2043(?:[);/ ]|$)",
                "model": "Reno 3"
            },
            {
                "regex": "(?:CPH2013|A002OP)(?:[);/ ]|$)",
                "model": "Reno 3A"
            },
            {
                "regex": "(?:PDCM00|A001OP)(?:[);/ ]|$)",
                "model": "Reno 3 5G"
            },
            {
                "regex": "(?:PCRM00|CPH203[57]|CPH2009)",
                "model": "Reno 3 Pro"
            },
            {
                "regex": "CPH2113(?:[);/ ]|$)",
                "model": "Reno 4 4G"
            },
            {
                "regex": "CPH2125(?:[);/ ]|$)",
                "model": "Reno 4 Lite"
            },
            {
                "regex": "CPH2109(?:[);/ ]|$)",
                "model": "Reno 4 Pro 4G"
            },
            {
                "regex": "PDP[TM]00(?:[);/ ]|$)",
                "model": "Reno 4 5G"
            },
            {
                "regex": "PEA[TM]00(?:[);/ ]|$)",
                "model": "Reno 4 SE 5G "
            },
            {
                "regex": "PDN[TM]00(?:[);/ ]|$)",
                "model": "Reno 4 Pro 5G"
            },
            {
                "regex": "PDSM00(?:[);/ ]|$)",
                "model": "Reno 5 Pro 5G"
            },
            {
                "regex": "CPH192[15]",
                "model": "Reno 5G"
            },
            {
                "regex": "PCLM10(?:[);/ ]|$)",
                "model": "Reno Ace"
            },
            {
                "regex": "PDHM00(?:[);/ ]|$)",
                "model": "Reno Ace 2"
            },
            {
                "regex": "PCGM00(?:[);/ ]|$)",
                "model": "Reno K3"
            },
            {
                "regex": "(?:OPPO[ _]?)?(?:CPH1979|PCD[TM]10)(?:[);/ ]|$)",
                "model": "Reno Z"
            },
            {
                "regex": "N1T?(?:[);/ ]|$)",
                "model": "N1T",
                "device": "phablet"
            },
            {
                "regex": "R([0-9]{3,4}[KSTW]?)(?:[);/ ]|$)",
                "model": "R$1"
            },
            {
                "regex": "(CPH[0-9]{4})",
                "model": "$1"
            },
            {
                "regex": "(?:OB-)?OPPO[ _]?((?!Browser)[a-z0-9]+)",
                "model": "$1"
            }
        ]
    },
    "Opsson": {
        "regex": "Opsson|IUSAI",
        "device": "smartphone",
        "models": [
            {
                "regex": "IUSAI[ _]([^/;]+) Build",
                "model": "Iusai $1"
            },
            {
                "regex": "IUSAI[ _]([^/;\\)]+)[/;\\)]",
                "model": "Iusai $1"
            },
            {
                "regex": "Opsson[ _]([^/;]+) Build",
                "model": "$1"
            },
            {
                "regex": "(?:Opsson-)?Opsson[ -_]([^/;]+)/",
                "model": "$1"
            }
        ]
    },
    "Orange": {
        "regex": "SPV[ \\-]?([a-z0-9]+)|(?<!FBCR/)Orange[ _-](?!Tahiti-LS\\))([^/;]+)( Build|\\))(?<!Tahiti-LS\\))|SC/IHD92|Nura 2|FunTab ([^/;]+) Build|Orange Mahpee",
        "device": "smartphone",
        "models": [
            {
                "regex": "SC/IHD92",
                "model": "Livebox Play",
                "device": "tv"
            },
            {
                "regex": "Nura 2",
                "model": "Nura 2"
            },
            {
                "regex": "Sego",
                "model": "Sego",
                "device": "tablet"
            },
            {
                "regex": "FunTab ([^/;]+) Build",
                "model": "FunTab $1",
                "device": "tablet"
            },
            {
                "regex": "Orange[ _-](Rise)(3[12]|5[12])(?:[);/ ]|$)",
                "model": "$1 $2"
            },
            {
                "regex": "Orange[ _-](Dive)72(?:[);/ ]|$)",
                "model": "$1 72"
            },
            {
                "regex": "Orange[ _-](Daytona|Dive (30|7[13])|Dublin|Fova|Gova|Hi 4G|Hiro|Kivo|Monte Carlo|Neva 80|Neva play|Nura|Reyo|Rise[_ ](3[034]|40|5[345])|Rono|Roya|San Francisco|Tactile internet 2|Tado|Yomi|Yumo|Zali|Mahpee)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "SPV[ \\-]?([a-z0-9]+)",
                "model": "SPV $1"
            }
        ]
    },
    "Oukitel": {
        "regex": "OUKITEL|(?:(?:C15|C16|K10000|K[46]000)(?:[ _]Pro)|K4000Pro|U16 Max|U7 Max|U7 Plus|U11[_ ]Plus|U20_Plus|OK6000 Plus|WP5000|WP[58] Pro|K[46]000[ _](?:Plus|Lite)|Y4800|K10000|K[4-8]000| WP[5-7])(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(C15|C16|K10000|K[46]000|WP[258]|U15)[ _]?Pro(?:[);/ ]|$)",
                "model": "$1 Pro"
            },
            {
                "regex": "OUKITEL K([37])(?:[);/ ]|$)",
                "model": "K$1"
            },
            {
                "regex": "(K10000|U16|U7)[ _]Max(?:[);/ ]|$)",
                "model": "$1 Max"
            },
            {
                "regex": "(U20|K[46]000|U7|U11|OK6000)[_ ]?Plus(?:[);/ ]|$)",
                "model": "$1 Plus"
            },
            {
                "regex": "(K4000)[_ ]Lite(?:[);/ ]|$)",
                "model": "$1 Lite"
            },
            {
                "regex": "(Y4800|K10000|K[4-8]000|WP2|WP5000|[CU]2|U10)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": " (WP[5-7])(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "OUKITEL; ([^;]+);",
                "model": "$1"
            }
        ]
    },
    "OKWU": {
        "regex": "OKWU SIGMA(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "Sigma"
    },
    "Ouki": {
        "regex": "OUKI|OK[AU][0-9]+[a-z]* Build",
        "device": "smartphone",
        "models": [
            {
                "regex": "OUKI[ _-]?([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "([^;/)]+) Build/OUKI",
                "model": "$1"
            },
            {
                "regex": "OUKI[ _-]?([^;/)]+)(?:[;/)]|$)",
                "model": "$1"
            },
            {
                "regex": "(OK[AU][0-9]+[a-z]*) Build",
                "model": "$1"
            }
        ]
    },
    "Overmax": {
        "regex": "OV-[a-z]+(?:[^;(/]*)[();/ ]|Qualcore 10(?:10|27)|Vertis 5021 Aim|OV1027[34]G",
        "device": "tablet",
        "models": [
            {
                "regex": "OV-V10",
                "model": "Vertis Yard",
                "device": "smartphone"
            },
            {
                "regex": "Vertis 5021 Aim",
                "model": "Vertis 5021 Aim",
                "device": "smartphone"
            },
            {
                "regex": "OV-Vertis[ _-]([^;/]+) Build",
                "model": "Vertis $1",
                "device": "smartphone"
            },
            {
                "regex": "Qualcore 1010",
                "model": "Qualcore 1010",
                "device": "tablet"
            },
            {
                "regex": "Qualcore 1027 4G",
                "model": "Qualcore 1027 4G",
                "device": "tablet"
            },
            {
                "regex": "OV1027([34])G",
                "model": "Qualcore 1027 $1G",
                "device": "tablet"
            },
            {
                "regex": "(OV-[a-z]+(?:[^;(/]*))(?<!Build)[();/ ]",
                "model": "$1"
            }
        ]
    },
    "Oysters": {
        "regex": "Oysters|T84ERI[ _]3G|T72HM(s_)?3G|T102MS_3G|T102ER3G|(T74HMi|T84Bi)[_ ]4G|T74MR4G|T84Ni[_ ][34]G|Pacific[ ]?800i|T104B_[34]G|IndianV|T72HA_3G|T74D_3G|T74N_3G|T74SC_3G|T74Mai_3G|T104MBi_3G|T104SCi_3G|T104ER4G|Atlantic4G|AntarcticE|T7V 3G",
        "device": "tablet",
        "models": [
            {
                "regex": "Pacific[ ]?800i",
                "device": "smartphone",
                "model": "Pacific 800i"
            },
            {
                "regex": "Atlantic4G",
                "device": "smartphone",
                "model": "Atlantic 4G"
            },
            {
                "regex": "AntarcticE",
                "device": "smartphone",
                "model": "Antarctic E"
            },
            {
                "regex": "IndianV",
                "device": "smartphone",
                "model": "Indian V"
            },
            {
                "regex": "Oysters[_ ]PacificV",
                "device": "smartphone",
                "model": "Pacific V"
            },
            {
                "regex": "Oysters ((?:Arctic|Indian|Atlantic|Pacific)[^/;]+) Build",
                "device": "smartphone",
                "model": "$1"
            },
            {
                "regex": "(T84ERI[ _]3G|T72HM(s_)?3G|(?:(T74HMi|T84Bi)[_ ]4G)|T84Ni[_ ][34]G)",
                "model": "$1"
            },
            {
                "regex": "(T74MR|T102MS|T104B|T74SC|T74[DN]|T72HA|T102ER|T104SCi|T104MBi|T104ER|T7V)[ _]?([34]G)",
                "model": "$1 $2"
            },
            {
                "regex": "T74Mai_3G",
                "model": "T74MAi 3G"
            },
            {
                "regex": "Oysters ([^/;]+)( Build|\\))",
                "model": "$1"
            }
        ]
    },
    "öwn": {
        "regex": "OWN[ _]|(?:S4035[ _][34]G|Smart8|SMART PLUS LTE)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "FUN ([67])",
                "model": "Fun $1"
            },
            {
                "regex": "FUN 5\\(4G\\)",
                "model": "Fun 5 4G"
            },
            {
                "regex": "(?:OWN[ _])?(S3000D|S3010|S3020D|S4010|S4025|S4035[ _][34]G)",
                "model": "$1"
            },
            {
                "regex": "One Plus",
                "model": "One Plus"
            },
            {
                "regex": "One",
                "model": "One"
            },
            {
                "regex": "SMART 9",
                "model": "Smart 9"
            },
            {
                "regex": "Smart8(?:[);/ ]|$)",
                "model": "Smart 8"
            },
            {
                "regex": "SMART PLUS LTE(?:[);/ ]|$)",
                "model": "Smart Plus LTE"
            }
        ]
    },
    "Panacom": {
        "regex": "T-i708D",
        "device": "smartphone",
        "models": [
            {
                "regex": "T-i708D",
                "device": "tablet",
                "model": "T-i708D"
            }
        ]
    },
    "Panasonic": {
        "regex": "Panasonic|PANATV[0-9]+|Viera/|(?:P902i|P-0[28]D|FZ-B2D|FZ-[NX]1|DMC-CM1|P-01J|P-03E)(?:[);/ ]|$)|Eluga[ _]|P55 Novo 4G",
        "device": "smartphone",
        "models": [
            {
                "regex": "P-01J(?:[);/ ]|$)",
                "model": "P-smart Keitai"
            },
            {
                "regex": "P-03E(?:[);/ ]|$)",
                "model": "Eluga P"
            },
            {
                "regex": "Eluga[ _-]([^;/]+) Build",
                "model": "Eluga $1"
            },
            {
                "regex": "Eluga[ _-]([^);/]+)[;/)]",
                "model": "Eluga $1"
            },
            {
                "regex": "(DMC-CM1)",
                "model": "Lumix $1",
                "device": "camera"
            },
            {
                "regex": "P-02D(?:[);/ ]|$)",
                "model": "Lumix Phone P-02D"
            },
            {
                "regex": "(FZ-[XN]1)(?:[);/ ]|$)",
                "model": "Toughpad $1"
            },
            {
                "regex": "FZ-B2D(?:[);/ ]|$)",
                "model": "Toughpad FZ-B2D",
                "device": "tablet"
            },
            {
                "regex": "P55 Novo 4G",
                "model": "P55 Novo 4G"
            },
            {
                "regex": "P902i(?:[);/ ]|$)",
                "device": "feature phone",
                "model": "P902i"
            },
            {
                "regex": "Panasonic MIL DLNA",
                "device": "tv",
                "model": "Viera Cast"
            },
            {
                "regex": "PANATV[0-9]+|Viera/",
                "device": "tv",
                "model": "Smart TV"
            },
            {
                "regex": "(43D1200|(?:32|43)D1270|32D1280)",
                "device": "tv",
                "model": "$1"
            },
            {
                "regex": "Panasonic[ _\\-]?([a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "portalmmm/2.0 (P[a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "P-08D(?:[);/ ]|$)",
                "model": "Eluga Live 10.1\"",
                "device": "tablet"
            }
        ]
    },
    "PCBOX": {
        "regex": "Art-PCB-V116|Bee-PCB-V216|Clap-PCB-I316|PCB-T(103|715)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Art-PCB-V116",
                "model": "Art"
            },
            {
                "regex": "Bee-PCB-V216",
                "model": "Bee"
            },
            {
                "regex": "Clap-PCB-I316",
                "model": "Clap"
            },
            {
                "regex": "PCB-T103",
                "device": "tablet",
                "model": "Curi Lite"
            },
            {
                "regex": "PCB-T715",
                "device": "tablet",
                "model": "T715"
            }
        ]
    },
    "PCD": {
        "regex": "PH4001",
        "device": "smartphone",
        "models": [
            {
                "regex": "PH4001",
                "model": "PH4001"
            }
        ]
    },
    "PCD Argentina": {
        "regex": "PCD[ ]?50[689]",
        "device": "smartphone",
        "models": [
            {
                "regex": "PCD[ ]?(50[689])",
                "model": "$1"
            }
        ]
    },
    "ArmPhone": {
        "regex": "(TSD Octa A0520P|TSD Quadra A050[59]P)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Pentagram": {
        "regex": "Pentagram|Quadra|Monster X5",
        "device": "tablet",
        "models": [
            {
                "regex": "(?:PENTAGRAM[_ ])?EON[_ ]PRIX",
                "model": "Eon Prix"
            },
            {
                "regex": "Quadra 7(?: UltraSlim)?",
                "model": "Quadra 7 UltraSlim"
            },
            {
                "regex": "Monster X5?",
                "model": "Monster X5",
                "device": "smartphone"
            },
            {
                "regex": "Quadra ?([^);/]*) Build",
                "model": "Quadra $1"
            },
            {
                "regex": "Pentagram ?TAB ?([^);/]*) Build",
                "model": "Tab $1"
            },
            {
                "regex": "Pentagram ?([^);/]*) Build",
                "model": "$1",
                "device": "smartphone"
            }
        ]
    },
    "TCL": {
        "regex": "TCL,|TCL[_ -][a-z0-9]+|(?:TCL[_ -][^;/]+ Build)|(?:TCLGalaG60(?:\\(9108A\\))?|A502DL|T78[02]H|5199I|7040N|5133A|5159[AJ]|5152D|T770H|T671H|T790Y|T766H_EEA|T766A|T799[BH]|Percee TV)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "TCLGalaG60(?:\\(9108A\\))?(?:[);/ ]|$)",
                "model": "Gala G60"
            },
            {
                "regex": "5199I(?:[);/ ]|$)",
                "model": "C9"
            },
            {
                "regex": "5152D(?:[);/ ]|$)",
                "model": "C5"
            },
            {
                "regex": "5133A(?:[);/ ]|$)",
                "model": "L5"
            },
            {
                "regex": "T780H(?:[);/ ]|$)",
                "model": "Plex"
            },
            {
                "regex": "T782H(?:[);/ ]|$)",
                "model": "10 Plus"
            },
            {
                "regex": "T790Y(?:[);/ ]|$)",
                "model": "10 5G"
            },
            {
                "regex": "TCL[_ -]([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "TCL[_ -]([a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "(7040N)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "A502DL(?:[);/ ]|$)",
                "model": "LX"
            },
            {
                "regex": "5159[AJ](?:[);/ ]|$)",
                "model": "L9"
            },
            {
                "regex": "T770H(?:[);/ ]|$)",
                "model": "10L"
            },
            {
                "regex": "T766[HA](?:_EEA)?(?:[);/ ]|$)",
                "model": "10 SE"
            },
            {
                "regex": "T671H(?:_EEA)?(?:[);/ ]|$)",
                "model": "20 SE"
            },
            {
                "regex": "T799[BH](?:[);/ ]|$)",
                "model": "10 Pro"
            },
            {
                "regex": "(Percee TV)(?:[);/ ]|$)",
                "device": "tv",
                "model": "$1"
            },
            {
                "regex": "((?:43|55)S62FS|(?:43|50)P65US|U(?:43|60)P60|65P4USM|(?:40|49)S62|L32S6|55P610|50P8S|55S62|(?:43|50)P6US|55DP60)",
                "device": "tv",
                "model": "$1"
            }
        ]
    },
    "JVC": {
        "regex": "JVC[;,]|JVC Shell",
        "device": "tv",
        "models": [
            {
                "regex": "JVC Shell [0-9A-Z]+",
                "model": ""
            },
            {
                "regex": "JVC[;,](32D1290)",
                "model": "$1"
            }
        ]
    },
    "phoneOne": {
        "regex": "phoneOne[ \\-]?([a-z0-9]+)",
        "device": "smartphone",
        "model": "$1"
    },
    "Primepad": {
        "regex": "PD\\-(3127NC|3127) Build",
        "device": "tablet",
        "models": [
            {
                "regex": "PD\\-3127NC Build",
                "model": "PD-3127NC"
            },
            {
                "regex": "PD\\-3127 Build",
                "model": "PD-3127"
            }
        ]
    },
    "Pioneer": {
        "regex": "Pioneer|.*; R1 Build|dvr700pi|XDP-300R",
        "device": "smartphone",
        "models": [
            {
                "regex": ".*; R1 Build",
                "model": "R1",
                "device": "tablet"
            },
            {
                "regex": "Pioneer[ _-]?([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "Pioneer[ _-]?([^;/)]+)(?:[;/)]|$)",
                "model": "$1"
            },
            {
                "regex": "dvr700pi",
                "model": "DVR700PI 7.0\"",
                "device": "tablet"
            },
            {
                "regex": "(XDP-300R)",
                "device": "portable media player",
                "model": "$1"
            }
        ]
    },
    "Pixus": {
        "regex": "Pixus|(hiPower|Ride[_ ][34]G|Play[ _]Three[ _]v(?:[24].0|3.1))(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "Ride[_ ]([34])G",
                "model": "Ride $1G"
            },
            {
                "regex": "Play[ _]Three[ _]v([24].0|3.1)",
                "model": "Play Three v$1"
            },
            {
                "regex": "Play Two",
                "model": "Play Two"
            },
            {
                "regex": "Play Five",
                "model": "Play Five"
            },
            {
                "regex": "hiPower",
                "model": "hiPower"
            },
            {
                "regex": "Pixus[ _]Sprint",
                "model": "Sprint"
            },
            {
                "regex": "Touch 7 3G",
                "model": "Touch 7 3G"
            },
            {
                "regex": "Pixus[ _]hiMax",
                "model": "HiMax 9.6\""
            },
            {
                "regex": "Pixus[ _]Blast",
                "model": "Blast 10.1\""
            },
            {
                "regex": "Pixus[ _]Joker",
                "model": "Joker 10.1\""
            },
            {
                "regex": "Pixus Blaze 10.1 3G",
                "model": "Blaze 10.1\" 3G"
            },
            {
                "regex": "Raze",
                "model": "Raze",
                "device": "smartphone"
            },
            {
                "regex": "Pixus_Jet",
                "model": "Jet",
                "device": "smartphone"
            },
            {
                "regex": "pixus hit 2",
                "model": "Hit 2",
                "device": "smartphone"
            }
        ]
    },
    "PULID": {
        "regex": "PULID[ _]|F1[01357]\\+? Build",
        "device": "smartphone",
        "models": [
            {
                "regex": "F(1[01357]\\+?) Build",
                "model": "F$1"
            },
            {
                "regex": "PULID[ _]([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "PULID[ _]([a-z0-9_]+)\\)",
                "model": "$1"
            }
        ]
    },
    "Point of View": {
        "regex": "POV_TV|POV_TAB|MOB-5045",
        "device": "tablet",
        "models": [
            {
                "regex": "POV_TV-HDMI-KB-01",
                "model": "HDMI Smart TV Dongle",
                "device": "tv"
            },
            {
                "regex": "POV_TV-HDMI-200BT",
                "model": "Mini PC HDMI Dongle",
                "device": "tv"
            },
            {
                "regex": "MOB-5045",
                "model": "Mobii Phone 5045",
                "device": "smartphone"
            },
            {
                "regex": "POV_TAB-P506",
                "model": "ONYX 506 Navi"
            },
            {
                "regex": "POV_TAB[_-]NAVI7[_-]3G[_-]M",
                "model": "ONYX 507 Navi"
            },
            {
                "regex": "POV_TAB-P527S",
                "model": "ONYX 527S"
            },
            {
                "regex": "POV_TAB-P547",
                "model": "ONYX 547 Navi"
            },
            {
                "regex": "POV_TAB-PROTAB26",
                "model": "ProTab 26 XXL IPS"
            },
            {
                "regex": "POV_TAB-PROTAB25XXL8?",
                "model": "ProTab 25XXL"
            },
            {
                "regex": "POV_TAB-PL1015",
                "model": "Mobii 1015"
            },
            {
                "regex": "POV_TAB-PROTAB([a-z0-9]+)",
                "model": "ProTab $1"
            },
            {
                "regex": "POV_TAB-P?([a-z0-9]+)",
                "model": "Mobii $1"
            }
        ]
    },
    "Pomp": {
        "regex": "POMP[ _-]",
        "device": "smartphone",
        "models": [
            {
                "regex": "POMP[ _-]([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "POMP[ _-]([^;/)]+)(?:[;/)]|$)",
                "model": "$1"
            }
        ]
    },
    "PPTV": {
        "regex": "(KING 7S?)",
        "device": "smartphone",
        "model": "$1"
    },
    "ProScan": {
        "regex": "PLT([^;/]+) Build|PLT(?:777[45]|9774|1074)G(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "PLT([^;/]+) Build",
                "model": "PLT$1"
            },
            {
                "regex": "PLT(777[45]|9774|1074)G",
                "model": "PLT$1G"
            }
        ]
    },
    "RCA Tablets": {
        "regex": "RCT([^;/]+) Build|RCA RLTP4028|RCT6513W87|RCT6973W43(?:MD)?|RCT6703W12|RCT(?:6A06P22|6603W47M7|6303W87(?:DK|M)|6773W22B|6203W46L|6873W42M|6K03W13)",
        "device": "smartphone",
        "models": [
            {
                "regex": "RCA RLTP4028",
                "model": "RLTP4028"
            },
            {
                "regex": "RCT6513W87",
                "device": "tablet",
                "model": "Galileo Pro 11.5\""
            },
            {
                "regex": "RCT6873W42M",
                "device": "tablet",
                "model": "Voyager 7.0\""
            },
            {
                "regex": "RCT6973W43(?:MD)?",
                "device": "tablet",
                "model": "Voyager III 7.0\""
            },
            {
                "regex": "RCT6773W22B",
                "device": "tablet",
                "model": "Voyager II 7.0\""
            },
            {
                "regex": "RCT6703W12",
                "device": "tablet",
                "model": "Atlas 10.0\""
            },
            {
                "regex": "RCT6203W46L",
                "device": "tablet",
                "model": "Pro 10 Edition II"
            },
            {
                "regex": "RCT6A06P22",
                "device": "tablet",
                "model": "Endeavor 10.0\" HD"
            },
            {
                "regex": "RCT(?:6303W87(?:DK|M)|6K03W13)",
                "device": "tablet",
                "model": "Viking Pro 10.0\""
            },
            {
                "regex": "RCT6603W47M7",
                "device": "tablet",
                "model": "Viking II 10.0\""
            },
            {
                "regex": "RCT([^;/)]+)(?: Build|[;)])",
                "device": "tablet",
                "model": "RCT$1"
            }
        ]
    },
    "Readboy": {
        "regex": "Readboy[ _-]",
        "device": "tablet",
        "models": [
            {
                "regex": "Readboy[ _-]([^;/)]+)(?: Build|[;)])",
                "model": "$1"
            }
        ]
    },
    "Roku": {
        "regex": "Roku/DVP",
        "device": "tv",
        "model": "Digital Video Player"
    },
    "Rokit": {
        "regex": "IO Pro",
        "device": "smartphone",
        "models": [
            {
                "regex": "IO Pro",
                "model": "IO Pro"
            }
        ]
    },
    "Rombica": {
        "regex": "(Rombica|SSQ-A0500|Smart Box v[0-9]+)(?:[);/ ]|$)",
        "device": "tv",
        "models": [
            {
                "regex": "SSQ-A0500(?:[);/ ]|$)",
                "model": "Smart Stick 4K"
            },
            {
                "regex": "(Infinity K8|Smart Box (?:v00[345789]|Quad|Ultra HD v002|4K V001))(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Rover": {
        "regex": "Rover ([0-9]+)",
        "device": "feature phone",
        "model": "$1"
    },
    "RoverPad": {
        "regex": "(?:Rover[ ]?Pad|RoverPhone|Sky 7.85 3G)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "RoverPhone(?:[);/ ]|$)",
                "model": "",
                "device": "smartphone"
            },
            {
                "regex": "(?:Rover[ ]?Pad)?sky 7.85(?: 3G)?(?:[);/ ]|$)",
                "model": "Sky 7.85\" 3G"
            },
            {
                "regex": "RoverPad (10.4|Air S70|9.7 3G|Sky 9.7|3W A73|3W7|3WT70|3W9.4)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Roadrover": {
        "regex": "Roadrover ChangAn S",
        "device": "tablet",
        "model": "ChangAn S"
    },
    "RT Project": {
        "regex": "Shock 5",
        "device": "smartphone",
        "models": [
            {
                "regex": "Shock 5",
                "model": "Shock 5"
            }
        ]
    },
    "S-TELL": {
        "regex": "S-TELL",
        "device": "smartphone",
        "models": [
            {
                "regex": "(?:S-TELL[ _])?M4(61|77|80)",
                "model": "M4$1"
            }
        ]
    },
    "Safaricom": {
        "regex": "NEON_RAY",
        "device": "smartphone",
        "models": [
            {
                "regex": "NEON_RAY",
                "model": "Neon Ray"
            }
        ]
    },
    "Santin": {
        "regex": "(?:SANTIN|BiTBiZ_V58|ACTOMA ACE|DREAMPLUS03A|Candy U7 Pro)[);/_ ]",
        "device": "smartphone",
        "models": [
            {
                "regex": "BiTBiZ_V58(?:[);/ ]|$)",
                "model": "BiTBiZ V58"
            },
            {
                "regex": "Candy U7 Pro(?:[);/ ]|$)",
                "model": "Candy U7 Pro"
            },
            {
                "regex": "DREAMPLUS03A(?:[);/ ]|$)",
                "model": "Dream Plus 03A"
            },
            {
                "regex": "GreenOrange(?:[);/ ]|$)",
                "model": "Green Orange"
            },
            {
                "regex": "halove(?:[);/ ]|$)",
                "model": "Halove"
            },
            {
                "regex": "ACTOMA ACE(?:[);/ ]|$)",
                "model": "Actoma Ace"
            },
            {
                "regex": "monica(?:[);/ ]|$)",
                "model": "Monica"
            },
            {
                "regex": "POWER(?:[);/ ]|$)",
                "model": "Power"
            },
            {
                "regex": "GALAZ(?:[);/ ]|$)",
                "model": "Galaz"
            },
            {
                "regex": "NEWDUN(?:[);/ ]|$)",
                "model": "Newdun"
            },
            {
                "regex": "SANTIN[ _][#]?(Dante|Candy U7|Armor|YSL-Y7|ANT.W|N[13]|JS|S6|V9|Life Shine)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Siemens": {
        "regex": "SIEMENS|SIE-|portalmmm/2\\.0 SI|(?:S55|SL45i)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(?:SIE-)?(S[56]5|S40|C45|ME45|SLIK)(?:[);/ ]|$)",
                "device": "feature phone",
                "model": "$1"
            },
            {
                "regex": "SIEMENS[ \\-]([a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "SIE(?:MENS )?[\\-]?([a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "(?:SIE-)?(SL45i)",
                "model": "$1"
            },
            {
                "regex": "portalmmm/2.0 (SI[a-z0-9]+)",
                "model": "$1"
            }
        ]
    },
    "Soundmax": {
        "regex": "((?:SM-LED32M(?:11|04)S)(?:_-Ver01)?|SM-LED40M04S)(?:[);/ ]|$)",
        "device": "tv",
        "model": "$1"
    },
    "Samsung": {
        "regex": "SAMSUNG(?! ?Browser)|Maple (?!III)|SC-(?:01[FGHKLM]|02[CGHJKLM]|03[JKL]|04[EJL]|05[GL]|(?:4[12]|5[1234])A)|N[57]100|N5110|N9100|S(?:CH|GH|PH|EC|AM|HV|HW|M)-|SMART-TV|GT-|(?<!GOG|GOG )Galaxy|(?:portalmmm|o2imode)/2\\.0 [SZ]|sam[rua]|vollo Vi86(?:[);/ ]|$)|(?:OTV-)?SMT-E5015|ISW11SC|SCV4[0-9]|SCV3[1-9]|40[34]SC|SCL2[234]|SCG0[1-7]|SCT21",
        "device": "smartphone",
        "models": [
            {
                "regex": "GT-B9150",
                "device": "tv",
                "model": "Home Sync"
            },
            {
                "regex": "(?:OTV-)?SMT-E5015",
                "device": "tv",
                "model": "SMT-E5015"
            },
            {
                "regex": "Maple ",
                "device": "tv",
                "model": ""
            },
            {
                "regex": "(?:SAMSUNG-)?(?:GT-)?N5100",
                "device": "tablet",
                "model": "Galaxy Note 8.0\""
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T677(?:[ATV]|N[KL])?(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy View 18.4\" LTE"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T670(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy View 18.4\" WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?(?:GT-)?N5110",
                "device": "tablet",
                "model": "Galaxy Note 8.0\" WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-N5120",
                "device": "tablet",
                "model": "Galaxy Note 8.0\" LTE"
            },
            {
                "regex": "(?:SAMSUNG-)?(?:GT|SM)-N8000",
                "device": "tablet",
                "model": "Galaxy Note 10.1\""
            },
            {
                "regex": "(?:SAMSUNG-)?GT-N8010",
                "device": "tablet",
                "model": "Galaxy Note 10.1\" WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-N8020",
                "device": "tablet",
                "model": "Galaxy Note 10.1\" LTE"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-P1000M?|SCH-I800",
                "device": "tablet",
                "model": "Galaxy Tab"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-P3100B?",
                "device": "tablet",
                "model": "Galaxy Tab 2 7\""
            },
            {
                "regex": "(?:SAMSUNG-)?GT-P311[03]",
                "device": "tablet",
                "model": "Galaxy Tab 2 7\" WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-P5100|SCH-I915",
                "device": "tablet",
                "model": "Galaxy Tab 2 10.1\""
            },
            {
                "regex": "(?:SAMSUNG-)?GT-P511[03]",
                "device": "tablet",
                "model": "Galaxy Tab 2 10.1\" WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T217T",
                "device": "tablet",
                "model": "Galaxy Tab 3 7.0\" 4G"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-P5200",
                "device": "tablet",
                "model": "Galaxy Tab 3 10.1\""
            },
            {
                "regex": "(?:SAMSUNG-)?GT-P5210",
                "device": "tablet",
                "model": "Galaxy Tab 3 10.1\" WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-P5220",
                "device": "tablet",
                "model": "Galaxy Tab 3 10.1\" LTE"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-P6200",
                "device": "tablet",
                "model": "Galaxy Tab 7\" Plus"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-P6201",
                "device": "tablet",
                "model": "Galaxy Tab 7\" Plus N"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-P6810",
                "device": "tablet",
                "model": "Galaxy Tab 7.7\""
            },
            {
                "regex": "(?:SAMSUNG-)?GT-P7100",
                "device": "tablet",
                "model": "Galaxy Tab 10.1v"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-P75[01]0",
                "device": "tablet",
                "model": "Galaxy Tab 10.1\""
            },
            {
                "regex": "(?:SAMSUNG-)?SM-P600",
                "device": "tablet",
                "model": "Galaxy Note 10.1\" 2014 Edition WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-P60[12]",
                "device": "tablet",
                "model": "Galaxy Note 10.1\" 2014 Edition"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:P605|P607T)",
                "device": "tablet",
                "model": "Galaxy Note 10.1\" 2014 Edition LTE"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-P900",
                "device": "tablet",
                "model": "Galaxy NotePRO 12.2\" WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-P901",
                "device": "tablet",
                "model": "Galaxy NotePRO 12.2\""
            },
            {
                "regex": "(?:SAMSUNG-)?SM-P905",
                "device": "tablet",
                "model": "Galaxy NotePRO 12.2\" LTE"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:P587|P588C)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab A 10.1\" with S Pen (2016) LTE"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-P583(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab A 10.1\" with S Pen (2016) WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-P205(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab A 8.0\" with S Pen (2019) LTE"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-P200(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab A 8.0\" with S Pen (2019) WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T307U(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab A 8.4\" (2020) LTE"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:T505[CN]?|T507)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab A7 10.4\" (2020) LTE"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T500(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab A7 10.4\" (2020) WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T11[03]",
                "device": "tablet",
                "model": "Galaxy Tab 3 Lite 7.0\" WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:T830[X]?|T837R4)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab S4 10.5\" WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T837P(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab S4 10.5\" with S Pen"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:T835[CN]?|T837[VTA]?)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab S4 10.5\""
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:T725[CN]?|T720X|T727(?:R4|[AUV])?)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab S5e 10.5\""
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T720(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab S5e 10.5\" WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T865N?(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab S6 10.5\""
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T867(?:R4|[VU])?(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab S6 10.5\" LTE"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:P615[CN]?|P617)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab S6 Lite 10.4\""
            },
            {
                "regex": "(?:SAMSUNG-)?SM-P610X?(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab S6 Lite 10.4\" WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T860(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab S6 10.5\" WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T866N(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab S6 10.5\" 5G"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T111[M]?(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab 3 Lite 7.0\""
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T116(?:BU|NU|NY)?",
                "device": "tablet",
                "model": "Galaxy Tab 3 V"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:T2105|T212)",
                "device": "tablet",
                "model": "Galaxy Tab 3 7.0\" Kids"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T210R?",
                "device": "tablet",
                "model": "Galaxy Tab 3 7.0\" WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T21(?:[15]|7[AS])",
                "device": "tablet",
                "model": "Galaxy Tab 3 7.0\""
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T230(?:NU)?",
                "device": "tablet",
                "model": "Galaxy Tab 4 7.0\" WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T23[15]|403SC",
                "device": "tablet",
                "model": "Galaxy Tab 4 7.0\" 3G"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:T239|T237[PV])",
                "device": "tablet",
                "model": "Galaxy Tab 4 7.0\" LTE"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T232(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab 4 7.0\" WiFi + 3G"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T310",
                "device": "tablet",
                "model": "Galaxy Tab 3 8.0\" WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T311",
                "device": "tablet",
                "model": "Galaxy Tab 3 8.0\""
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T315",
                "device": "tablet",
                "model": "Galaxy Tab 3 8.0\" LTE"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T330",
                "device": "tablet",
                "model": "Galaxy Tab 4 8.0\" WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:T33[215]|T337[AVT])",
                "device": "tablet",
                "model": "Galaxy Tab 4 8.0\" LTE"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T520",
                "device": "tablet",
                "model": "Galaxy TabPRO 10.1\" WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:T53[15]|T537[AV])",
                "device": "tablet",
                "model": "Galaxy Tab 4 10.1\" LTE"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T536(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab 4 10.1\" Advanced"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T532(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab 4 10.1\""
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T320",
                "device": "tablet",
                "model": "Galaxy TabPRO 8.4\" WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T325",
                "device": "tablet",
                "model": "Galaxy TabPRO 8.4\" LTE"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T525",
                "device": "tablet",
                "model": "Galaxy TabPRO 10.1\" LTE"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:T530(?:NU)?|T533)",
                "device": "tablet",
                "model": "Galaxy Tab 4 10.1\" WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T700",
                "device": "tablet",
                "model": "Galaxy Tab S 8.4\" WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:T705|T707[AV])",
                "device": "tablet",
                "model": "Galaxy Tab S 8.4\" LTE"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T800",
                "device": "tablet",
                "model": "Galaxy Tab S 10.5\" WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?(?:SM-(?:T805|T807[PV]?)|SCT21)",
                "device": "tablet",
                "model": "Galaxy Tab S 10.5\" LTE"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:T813|T810)",
                "device": "tablet",
                "model": "Galaxy Tab S2 9.7\" WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:T719[CY]?|T715(?:N0|[CY])?)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab S2 8.0\" LTE"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:T71[03]|T710X)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab S2 8.0\" WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:T815|T817[ATW]?|T818[ATVW]?|T819[Y]?)",
                "device": "tablet",
                "model": "Galaxy Tab S2 9.7\" LTE"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T820",
                "device": "tablet",
                "model": "Galaxy Tab S3 9.7\" WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T82[57]",
                "device": "tablet",
                "model": "Galaxy Tab S3 9.7\" LTE"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T975N?(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab S7+ 12.4\" LTE"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T970(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab S7+ 12.4\" WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:T976[BN]|T978U)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab S7+ 12.4\" 5G"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T870(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab S7 11.0\" WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T875N?(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab S7 11.0\" LTE"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T878U(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab S7 11.0\" 5G"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T900",
                "device": "tablet",
                "model": "Galaxy TabPRO 12.2\" WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T280",
                "device": "tablet",
                "model": "Galaxy Tab A 7.0\" WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T285",
                "device": "tablet",
                "model": "Galaxy Tab A 7.0\" LTE"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T3[58]0",
                "device": "tablet",
                "model": "Galaxy Tab A 8.0\" WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:P350|T3[58]5|T357[TW])",
                "device": "tablet",
                "model": "Galaxy Tab A 8.0\" LTE"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T387(?:AA|R4|VK|[TVW])",
                "device": "tablet",
                "model": "Galaxy Tab A 8.0\" LTE (2018)"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T295",
                "device": "tablet",
                "model": "Galaxy Tab A 8.0\" LTE (2019)"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T290",
                "device": "tablet",
                "model": "Galaxy Tab A 8.0\" WiFi (2019)"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:P355([MY])?|T550)",
                "device": "tablet",
                "model": "Galaxy Tab A 9.7\" WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:P550|P555(M)?|T555)",
                "device": "tablet",
                "model": "Galaxy Tab A 9.7\" LTE"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:T58[05]|P58[05])",
                "device": "tablet",
                "model": "Galaxy Tab A 10.1\" WiFi (2016)"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T587[P]?",
                "device": "tablet",
                "model": "Galaxy Tab A 10.1\" LTE (2016)"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T59[057]",
                "device": "tablet",
                "model": "Galaxy Tab A 10.5\" LTE (2018)"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T51(?:7P|[057])",
                "device": "tablet",
                "model": "Galaxy Tab A 10.1\" (2019)"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:T37[57]|T378[KLSV])",
                "device": "tablet",
                "model": "Galaxy Tab E 8.0\""
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T56[02]",
                "device": "tablet",
                "model": "Galaxy Tab E 9.6\" WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T561",
                "device": "tablet",
                "model": "Galaxy Tab E 9.6\" 3G"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T567V",
                "device": "tablet",
                "model": "Galaxy Tab E 9.6\" 4G"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T365(?:F0|[MY])?(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab Active 8.0\""
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T360(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab Active 8.0\" WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:T395[CN]?|T397U)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab Active 2 8.0\""
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T390(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab Active 2 8.0\" WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:T575N?|T577U?)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab Active 3 8.0\""
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T570(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab Active 3 8.0\" WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:T545|T547U?)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab Active Pro 10.1\""
            },
            {
                "regex": "(?:SAMSUNG-)?SM-T540(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Tab Active Pro 10.1\" WiFi"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:P902|P907A)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Galaxy Note Pro 12.2\""
            },
            {
                "regex": "(?:SAMSUNG[- ])?SM-T2519",
                "model": "Galaxy Tab Q",
                "device": "tablet"
            },
            {
                "regex": "(?:SAMSUNG )?SM-R820",
                "device": "wearable",
                "model": "Galaxy Watch Active 2"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G850(?:8S|[AFKLMSWXY])",
                "model": "Galaxy Alpha"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-B5330",
                "model": "Galaxy Chat"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A605K",
                "model": "Galaxy Jean"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A202K",
                "model": "Galaxy Jean 2"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-B5510",
                "model": "Galaxy Y Pro"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-B5512",
                "model": "Galaxy Y Pro Duos"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-B7510",
                "model": "Galaxy Pro"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-I5700",
                "model": "Galaxy Spica"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-I5801",
                "model": "Galaxy Apollo"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-I5800",
                "model": "Galaxy 3"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-I8000",
                "model": "Omnia II"
            },
            {
                "regex": "(?:SAMSUNG-)?(?:GT-I8150|SM-T255S)",
                "model": "Galaxy W"
            },
            {
                "regex": "SC-01H(?:[);/ ]|$)",
                "model": "Galaxy Active Neo"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-S5830",
                "model": "Galaxy Ace"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:G357(?:FZ|M)|G310HN)",
                "model": "Galaxy Ace Style"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-I8160",
                "model": "Galaxy Ace 2"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G313(?:HY|M[LUY]|[FM])",
                "model": "Galaxy Ace 4"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G316M",
                "model": "Galaxy Ace 4 Duos"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G313[HU]",
                "model": "Galaxy Ace 4 Lite"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:G316U|G318(?:H|ML))(?:[);/ ]|$)",
                "model": "Galaxy Ace 4 Neo"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G9100(?:[);/ ]|$)",
                "model": "Galaxy S II"
            },
            {
                "regex": "(?:SAMSUNG-)?SHV-E120S(?:[);/ ]|$)",
                "model": "Galaxy S II HD LTE"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-I8190",
                "model": "Galaxy S III mini"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-I8200",
                "model": "Galaxy S III mini Value Edition"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-I826[02]",
                "model": "Galaxy Core"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G386W",
                "model": "Galaxy Core LTE"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G3589W",
                "model": "Galaxy Core Lite LTE"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-I8320",
                "model": "H1"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-I85[23]0",
                "model": "Galaxy Beam"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G3858",
                "model": "Galaxy Beam 2"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G600S",
                "model": "Galaxy Wide"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-I855[028]",
                "model": "Galaxy Win"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G381[28]",
                "model": "Galaxy Win Pro"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-I8580",
                "model": "Galaxy Core Advance"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-I8730",
                "model": "Galaxy Express"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:E500(?:HQ|YZ|[0FHM])|S978L)",
                "model": "Galaxy E5"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-E700[09FHM]",
                "model": "Galaxy E7"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-I90(?:00|08|18|88)",
                "model": "Galaxy S"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-I9001",
                "model": "Galaxy S Plus"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-I9003",
                "model": "Galaxy SL"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-I9010",
                "model": "Galaxy S Giorgio Armani"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-I9070",
                "model": "Galaxy S Advance"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-I910[08]",
                "model": "Galaxy S II"
            },
            {
                "regex": "(?:SAMSUNG-)?ISW11SC",
                "model": "Galaxy S II WiMAX"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-I9103",
                "model": "Galaxy R"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-I9105",
                "model": "Galaxy S II Plus"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G730(?:W8|[AV])",
                "model": "Galaxy S3 mini"
            },
            {
                "regex": "(?:SAMSUNG-)?(?:GT-I919[025]|SCH-I435)",
                "model": "Galaxy S4 mini"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-I9515",
                "model": "Galaxy S4 Value Edition"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-I9295",
                "model": "Galaxy S4 ACTIVE"
            },
            {
                "regex": "(?:SAMSUNG-)?(?:GT-I9300|SCH-(?:I535|I939|L710))",
                "model": "Galaxy S III"
            },
            {
                "regex": "(?:SAMSUNG-)?(?:GT-I9305|SCH-R530)",
                "model": "Galaxy S III LTE"
            },
            {
                "regex": "(?:SAMSUNG-)?(?:SM-S975L|GT-I950[025]|SC-04E|SCH-(?:I545|I959|R970)|SGH-M919N?|Galaxy-S4)",
                "model": "Galaxy S4"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-I9506",
                "model": "Galaxy S4 with LTE+"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-S5280",
                "model": "Galaxy STAR"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-S5301",
                "model": "Galaxy POCKET Plus"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-S5310",
                "model": "Galaxy POCKET Neo"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G110[BHM]",
                "model": "Galaxy POCKET 2"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-S5360",
                "model": "Galaxy Y Hello Kitty"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-S6310",
                "model": "Galaxy Young"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-S6312",
                "model": "Galaxy Young DUOS"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G130(?:BT|HN|[EHMU])",
                "model": "Galaxy Young 2"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G130BU",
                "model": "Galaxy Young 2 Pro"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-S6790",
                "model": "Galaxy FAME Lite with NFC"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-S6810",
                "model": "Galaxy FAME"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-S6812",
                "model": "Galaxy FAME Duos"
            },
            {
                "regex": "SC-04J(?:[);/ ]|$)",
                "model": "Galaxy Feel"
            },
            {
                "regex": "SC-02L(?:[);/ ]|$)",
                "model": "Galaxy Feel 2"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-S7275",
                "model": "Galaxy ACE 3"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-S7500",
                "model": "Galaxy ACE Plus"
            },
            {
                "regex": "(?:SAMSUNG-)?(?:GT-S7560|SCH-I699)",
                "model": "Galaxy Trend"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-S7390",
                "model": "Galaxy Trend Lite"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-S7580",
                "model": "Galaxy Trend Plus"
            },
            {
                "regex": "(?:SAMSUNG-)?SCH-I739",
                "model": "Galaxy Trend 2"
            },
            {
                "regex": "(?:SAMSUNG-)?(?:GT-S7562|SCH-I919)",
                "model": "Galaxy S DUOS"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-S7582",
                "model": "Galaxy S DUOS 2"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:G31[36]HU|G313HZ)",
                "model": "Galaxy S DUOS 3"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-S7710",
                "model": "Galaxy Xcover 2"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G388F",
                "model": "Galaxy Xcover 3"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G389F",
                "model": "Galaxy Xcover 3 VE"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G390[FWY]",
                "model": "Galaxy Xcover 4"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G398FN",
                "model": "Galaxy Xcover 4s"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G715(?:FN|U1|[AUW])",
                "model": "Galaxy Xcover Pro"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G889(?:YB|[AFG])",
                "model": "Galaxy Xcover FieldPro"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-S8500",
                "model": "Wave"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-S8530",
                "model": "Wave II"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-S8600",
                "model": "Wave 3"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-S5380",
                "model": "Wave Y"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-S7250",
                "model": "Wave M"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-S5250",
                "model": "Wave 525"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-S5330",
                "model": "Wave 533"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-S5780",
                "model": "Wave 578"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-S7230",
                "model": "Wave 723"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:C101|C105([AL])?)",
                "model": "Galaxy S4 zoom"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:C111(M)?|C115)",
                "model": "Galaxy K zoom"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G318[HM]Z",
                "model": "Galaxy V Plus"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G355(?:6D|[89HM])",
                "model": "Galaxy Core 2"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G350",
                "model": "Galaxy Core Plus"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:G360[FH]?|S820L)",
                "model": "Galaxy Core Prime"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G361[FH]?",
                "model": "Galaxy Core Prime Value Edition"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:G386F|G3518(?:_TD)?|G3586V)",
                "model": "Galaxy Core LTE"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G3568V",
                "model": "Galaxy Core Mini 4G"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G5108Q?",
                "model": "Galaxy Core Max"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G3815",
                "model": "Galaxy EXPRESS II"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G800",
                "model": "Galaxy S5 mini"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G9009D",
                "model": "Galaxy S5 Dual-SIM"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:G900|G906[KLS]|S902L|S903VL)|Galaxy-S5|SCL23",
                "model": "Galaxy S5"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G901F",
                "model": "Galaxy S5 LTE+"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G870[AFW]|SC-02G",
                "model": "Galaxy S5 Active"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G903[FMW]",
                "model": "Galaxy S5 Neo"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G860P",
                "model": "Galaxy S5 K Sport"
            },
            {
                "regex": "(?:SAMSUNG-)?(?:SM-G920(?:F[DQ]|W8|[089AFIKLPRSTVX])?|SM-S906L|SM-S907VL)|SC-05G",
                "model": "Galaxy S6"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G890A",
                "model": "Galaxy S5 Active"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G925[0ADFIKLPRSTVWX]|SCV31|404SC",
                "model": "Galaxy S6 Edge"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G928(?:7C|N0|R4|W8|[07ACFGIKLPSTVX])",
                "model": "Galaxy S6 Edge+"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G930(?:AZ|FD|R[467]|T1|V[CL]|W8|[0AFKLPRSTUVX8])?(?:[);/ ]|$)",
                "model": "Galaxy S7"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G935(?:R4|W8|[0AFKLPSTUVX])|SC-02H|SCV33",
                "model": "Galaxy S7 Edge"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G891A",
                "model": "Galaxy S7 Active"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G950[08FNUW]?|SCV36|SC-02J",
                "model": "Galaxy S8"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G8750",
                "model": "Galaxy S8 Lite"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G892[AU]",
                "model": "Galaxy S8 Active"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G955[0AFKLNPRTUVWX]?|SCV35|SC-03J",
                "model": "Galaxy S8+"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G960[0FNUWX]?|SCV38|SC-02K",
                "model": "Galaxy S9"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G965[0FNUWX]|SCV39|SC-03K",
                "model": "Galaxy S9+"
            },
            {
                "regex": "(?:SAMSUNG-)?(?:SM-(?:G973|G977[BNPTU])|SCV41|SC-03L)",
                "model": "Galaxy S10"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:S10 Lite|G770(?:U1|F))",
                "model": "Galaxy S10 Lite"
            },
            {
                "regex": "(?:SAMSUNG-)?(?:SM-G975[08FUNWX]|SCV42|SC-04L)",
                "model": "Galaxy S10+"
            },
            {
                "regex": "SC-05L",
                "model": "Galaxy S10+ Olympic Games Edition"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G970(?:U1|[08FUNWX])(?:[);/ ]|$)",
                "model": "Galaxy S10e"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G980F",
                "model": "Galaxy S20"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G981(?:U1|[0BNUVW])|SCG01|SC-51A",
                "model": "Galaxy S20 5G"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G780F",
                "model": "Galaxy S20 FE"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G781(?:U1|[0BNUVW])",
                "model": "Galaxy S20 FE 5G"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G988(?:U1|[0BNQUW])|SCG03",
                "model": "Galaxy S20 Ultra 5G"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G985F|SCG02",
                "model": "Galaxy S20+"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G986(?:U1|[0BNUW])|SC-52A",
                "model": "Galaxy S20+ 5G"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G991(?:U1|[0BNW])",
                "model": "Galaxy S21 5G"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G998(?:U1|[0BNUW])",
                "model": "Galaxy S21 Ultra 5G"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G996(?:U1|[0BNWU])",
                "model": "Galaxy S21+ 5G"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-F700(?:U1|[0FNUW])|SCV47",
                "model": "Galaxy Z Flip"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-F707(?:U1|[0BNUW])|SCG04",
                "model": "Galaxy Z Flip 5G"
            },
            {
                "regex": "(?:SAMSUNG-)?SCH-I200",
                "model": "Galaxy Stellar"
            },
            {
                "regex": "(?:SAMSUNG-)?SCH-I829",
                "model": "Galaxy Style Duos"
            },
            {
                "regex": "(?:SAMSUNG-)?(?:SCH-R740|SGH-S730)",
                "model": "Galaxy Discover"
            },
            {
                "regex": "(?:SAMSUNG-)?SCH-S738",
                "model": "Galaxy Centura"
            },
            {
                "regex": "vollo Vi86(?:[);/ ]|$)",
                "model": "Vollo Vi86"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G386(?:T1|T)",
                "model": "Galaxy Avant"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A716S",
                "model": "Galaxy A Quantum"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:A015(?:DL|[TU]1|[AFGMUV])|S111DL)",
                "model": "Galaxy A01"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A013[FGM]",
                "model": "Galaxy A01 Core"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A025[FGM]",
                "model": "Galaxy A02s"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A022G",
                "model": "Galaxy A02"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A260[FG]",
                "model": "Galaxy A2 Core"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A300(?:FU|YZ|XU|XZ|[09FGHMXY])",
                "model": "Galaxy A3 (2015)"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A310(?:N0|[FMXY])",
                "model": "Galaxy A3 (2016)"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A320(?:FL|[FXY])",
                "model": "Galaxy A3 (2017)"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A430F",
                "model": "Galaxy A4"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A500(?:YZ|[9LSWY])?(?:[);/ ]|$)",
                "model": "Galaxy A5"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A500[0FGHKM]",
                "model": "Galaxy A5 Duos"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A500(?:FU|X)",
                "model": "Galaxy A5 (2015)"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A510[08FKLMSXY]",
                "model": "Galaxy A5 (2016)"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A520[FKLSWX]",
                "model": "Galaxy A5 (2017)"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A525F",
                "model": "Galaxy A52"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A600(?:AZ|FN|GN|T1|[AFGNPTUX])",
                "model": "Galaxy A6"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A605(?:[FG]N|[08FGX])",
                "model": "Galaxy A6+"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G6200",
                "model": "Galaxy A6s (2018)"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A606[0Y]",
                "model": "Galaxy A60"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A700(?:YD|[09FHKLSX])",
                "model": "Galaxy A7"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A710[08FKLMSXY]",
                "model": "Galaxy A7 (2016)"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A720[FMSX]",
                "model": "Galaxy A7 (2017)"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A750(?:GN|[CFGNX])",
                "model": "Galaxy A7 (2018)"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A71(?:5[FW])?(?:[);/ ]|$)",
                "model": "Galaxy A71"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A725F(?:[);/ ]|$)",
                "model": "Galaxy A72"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A716(?:U1|[0BUV])(?:[);/ ]|$)",
                "model": "Galaxy A71 5G"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A(?:530F|800[0FISXY])|SCV32",
                "model": "Galaxy A8"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G885[FSY]",
                "model": "Galaxy A8 Star"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A810(?:YZ|[FS])",
                "model": "Galaxy A8 (2016)"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:A8s|G887[0F])",
                "model": "Galaxy A8s"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A530[MNWX]",
                "model": "Galaxy A8 (2018)"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A730[FX]",
                "model": "Galaxy A8+ (2018)"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A900[0F]",
                "model": "Galaxy A9"
            },
            {
                "regex": "SM-A9\\[7\\]",
                "model": "Galaxy A9 7"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A920[0FXN]",
                "model": "Galaxy A9 (2018)"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G885[08]",
                "model": "Galaxy A9 Star"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:A9 Pro|A910[0F]|G887N)",
                "model": "Galaxy A9 Pro"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A105[FGMN]",
                "model": "Galaxy A10"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A107[FM]",
                "model": "Galaxy A10s"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:S102DL|A102[NUW])",
                "model": "Galaxy A10e"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A115(?:A[PZ]|U1|[AFMUW])",
                "model": "Galaxy A11"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A125[FM]",
                "model": "Galaxy A12"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:A205(?:U1|YN|[FGSWU])|S205DL)|SC-02M|SCV46",
                "model": "Galaxy A20"
            },
            {
                "regex": "(?:SAMSUNG-)?(?:SM-A215(?:DL|U1|[UW])|SC-42A|SCV49)(?:[);/ ]|$)",
                "model": "Galaxy A21"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A217[FMN](?:[);/ ]|$)",
                "model": "Galaxy A21s"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A202[FG]",
                "model": "Galaxy A20e"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A207[0FM]",
                "model": "Galaxy A20s"
            },
            {
                "regex": "(?:SAMSUNG-)?(?:SM-A305(?:[FGY]N|GT|[FGN])|SCV43)",
                "model": "Galaxy A30"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A307(?:FN|GN|GT|[FG])",
                "model": "Galaxy A30s"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A315[FGN](?:[);/ ]|$)",
                "model": "Galaxy A31"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A325F(?:[);/ ]|$)",
                "model": "Galaxy A32"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A326(?:BR|B)",
                "model": "Galaxy A32 5G"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A405(?:F[NM]|S)",
                "model": "Galaxy A40"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A305[018](?:[);/ ]|$)",
                "model": "Galaxy A40s"
            },
            {
                "regex": "(?:SAMSUNG-)?(?:SM-A415F|SC-41A|SCV48)(?:[);/ ]|$)",
                "model": "Galaxy A41"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A426[0BN](?:[);/ ]|$)",
                "model": "Galaxy A42 5G"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:A505(?:F[GN]|U1|YN|[FGNUWX])|S506DL|505FN)",
                "model": "Galaxy A50"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A5070(?:[);/ ]|$)",
                "model": "Galaxy A50s"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A507FN",
                "model": "Galaxy A50s"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:A515[FUW]|S515DL)",
                "model": "Galaxy A51"
            },
            {
                "regex": "(?:SAMSUNG-)?(?:SM-A516(?:U1|[0BNUV])|SC-54A|SCG07)",
                "model": "Galaxy A51 5G"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A705(?:GM|[MYF]N|[0FUWX])",
                "model": "Galaxy A70"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A707[0F]",
                "model": "Galaxy A70s"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A805[0FNX]",
                "model": "Galaxy A80"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A908[BN]",
                "model": "Galaxy A90"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-A9080",
                "model": "Galaxy A90 5G"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-F127G(?:[);/ ]|$)",
                "model": "Galaxy F12"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-F415F(?:[);/ ]|$)",
                "model": "Galaxy F41"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-I9301I",
                "model": "Galaxy S III Neo"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-S120VL(?:[);/ ]|$)",
                "model": "Galaxy Luna"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:J100(?:FN|VPP|MU|[FGHMY])|S777C)",
                "model": "Galaxy J1"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-J105[BFHYM]",
                "model": "Galaxy J1 Mini"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-J106[BFHM]",
                "model": "Galaxy J1 mini Prime"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:J120(?:ZN|[AFGHMPTW])|J05H)",
                "model": "Galaxy J1 (2016)"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:J110[FGHLM]|J111[FM])",
                "model": "Galaxy J1 Ace"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-J200[FGHMY]",
                "model": "Galaxy J2"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-J200BT",
                "model": "Galaxy J2 Duos"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-J260AZ",
                "model": "Galaxy J2 Pure"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:J260([AFGMY]|T1)|S260DL)",
                "model": "Galaxy J2 Core"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-J(?:210F|250[FGMNY])",
                "model": "Galaxy J2 Pro"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:S367VL|S357BL)",
                "model": "Galaxy J3 Orbit"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-J311(?:[09]|9S)",
                "model": "Galaxy J3 Pro"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-S320VL(?:[);/ ]|$)",
                "model": "Galaxy J3 Sky"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-S327VL(?:[);/ ]|$)",
                "model": "Galaxy J3 Luna Pro"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-J327(?:T1|[ATW])(?:[);/ ]|$)",
                "model": "Galaxy J3 Prime"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-J327(?:[FP]|R4)",
                "model": "Galaxy J3 Emerge"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-J337R4(?:[);/ ]|$)",
                "model": "Galaxy J3 Aura"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-J337T(?:[);/ ]|$)",
                "model": "Galaxy J3 Star"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-J327V",
                "model": "Galaxy J3 Eclipse"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-S337TL",
                "model": "Galaxy J3 Luna Pro"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-J3109",
                "model": "Galaxy J3 (2015)"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:J300H|J320(?:[ZF]N|R4|W8|YZ|[AFGHMNPVY]))",
                "model": "Galaxy J3 (2016)"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:J330(?:FN|[08FGLN])|J327(?:R7|U))(?:[);/ ]|$)",
                "model": "Galaxy J3 (2017)"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-J337(?:VPP|[AWPUV])(?:[);/ ]|$)",
                "model": "Galaxy J3 (2018)"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:G400[FG]|J400[FGM])",
                "model": "Galaxy J4"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-J500(?:FN|N0|[78FGHMY])(?:[);/ ]|$)",
                "model": "Galaxy J5 (2015)"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:J510(?:[FGMU]N|FQ|[8FGHKLSY])|5108|G510H)(?:[);/ ]|$)",
                "model": "Galaxy J5 (2016)"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-J530",
                "model": "Galaxy J5 (2017)"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G570[0FMY]",
                "model": "Galaxy J5 Prime"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-J600[FGLN]",
                "model": "Galaxy J6"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-J610[FG]",
                "model": "Galaxy J6+"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-J410[FG]",
                "model": "Galaxy J4 Core"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-J415(?:[FG]N|[FGN])",
                "model": "Galaxy J4+"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-J7[01]0(?:FN|MN|[8FHKM])",
                "model": "Galaxy J7"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-J700P",
                "model": "Galaxy J7 (2015)"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:J700(?:T1|T)|J710GN|J7109)",
                "model": "Galaxy J7 (2016)"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:J730[K]|J727(?:R4|[AFSU]))(?:[);/ ]|$)",
                "model": "Galaxy J7 (2017)"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-J737(?:R4|T1|VPP|[APSTUV])(?:[);/ ]|$)",
                "model": "Galaxy J7 (2018)"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-C710F",
                "model": "Galaxy J7+ (C7)"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G610[FMY]",
                "model": "Galaxy J7 Prime"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G611(?:FF|MT|M)",
                "model": "Galaxy J7 Prime 2"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G615F",
                "model": "Galaxy J7 Max"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:S727VL|S737TL)",
                "model": "Galaxy J7 Sky Pro"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:J7 Pro|J730(?:GM|[FG]))",
                "model": "Galaxy J7 Pro"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-J701[FM]",
                "model": "Galaxy J7 Core"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-J720[FM]",
                "model": "Galaxy J7 Duo"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:S767VL|S757BL)",
                "model": "Galaxy J7 Crown"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-J727[PTV]",
                "model": "Galaxy J7 V"
            },
            {
                "regex": "SM-J7\\[7\\]",
                "model": "Galaxy J7 7"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-J810",
                "model": "Galaxy J8"
            },
            {
                "regex": "SM-J8 Plus",
                "model": "Galaxy J8 Plus"
            },
            {
                "regex": "SM-J8 Pro",
                "model": "Galaxy J8 Pro"
            },
            {
                "regex": "SM-J9\\[7\\] Prime",
                "model": "Galaxy J9 7 Prime"
            },
            {
                "regex": "SM-J9\\[8\\] Pro",
                "model": "Galaxy J9 8 Pro"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-M015[FG]",
                "model": "Galaxy M01"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-M013F",
                "model": "Galaxy M01 Core"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-M017F",
                "model": "Galaxy M01s"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-M025F",
                "model": "Galaxy M02s"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-M105[FGMY]",
                "model": "Galaxy M10"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-M107F",
                "model": "Galaxy M10s"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-M115[FM]",
                "model": "Galaxy M11"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-M127[FG]",
                "model": "Galaxy M12"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:M205(?:[FGMN]|FN)|M20)(?:[);/ ]|$)",
                "model": "Galaxy M20"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-M215F",
                "model": "Galaxy M21"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:M307(?:FN|[0F])|M30s)",
                "model": "Galaxy M30s"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-M305?",
                "model": "Galaxy M30"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-M315F",
                "model": "Galaxy M31"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-M317F",
                "model": "Galaxy M31s"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-M405",
                "model": "Galaxy M40"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-M515F",
                "model": "Galaxy M51"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-W201([689])",
                "model": "Galaxy W201$1"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-W2015",
                "model": "Galaxy Golden 2"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G910S",
                "model": "Galaxy Round"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:G550(?:FY|T[12]?)|S550TL|G55[01]0|G5520)",
                "model": "Galaxy On5"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G5528",
                "model": "Galaxy On5 (2016)"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G600(?:FY|[0F])",
                "model": "Galaxy On7"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G610[0KLS]",
                "model": "Galaxy On7 (2016)"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G611[FKLS]",
                "model": "Galaxy On7 Prime"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-C500[0X]",
                "model": "Galaxy C5"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-C501[08]",
                "model": "Galaxy C5 Pro"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-C700[0X]",
                "model": "Galaxy C7"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-C701[08FX]",
                "model": "Galaxy C7 Pro"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-C710[08]",
                "model": "Galaxy C8"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-C900[08FY]",
                "model": "Galaxy C9 Pro"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-Z130H",
                "model": "Galaxy Z1"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-Z200[FMY]",
                "model": "Galaxy Z2"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-Z300H",
                "model": "Galaxy Z3"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-Z400[FY]",
                "model": "Galaxy Z4"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:G150(?:N[0KLS])?|G155S)(?:[);/ ]|$)",
                "model": "Galaxy Folder"
            },
            {
                "regex": "(?:SAMSUNG-)?(?:SM-G16(?:0N|[05]0?|5N))(?:[);/ ]|$)",
                "model": "Galaxy Folder 2"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-J321AZ(?:[);/ ]|$)",
                "model": "Galaxy Sol"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-J326AZ(?:[);/ ]|$)",
                "model": "Galaxy Sol 2"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-J336AZ(?:[);/ ]|$)",
                "model": "Galaxy Sol 3"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-J727AZ(?:[);/ ]|$)",
                "model": "Galaxy Halo"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-J327AZ",
                "model": "Galaxy Amp Prime 2"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-J337AZ",
                "model": "Galaxy Amp Prime 3 (2018)"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:F900[0FUW]|F907[BN])|SCV44",
                "model": "Galaxy Fold",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-F916(?:U1|[0BNQUW])|SCG05",
                "model": "Galaxy Z Fold 2 5G",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-I9060(?:[);/ ]|$)",
                "model": "Galaxy Grand Neo",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-I9063",
                "model": "Galaxy Grand Neo Duos",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-I9(?:080|128)",
                "model": "Galaxy Grand",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-I9168",
                "model": "Galaxy Grand Neo+",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-I9082",
                "model": "Galaxy Grand Duos",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-N7000",
                "model": "Galaxy Note",
                "device": "phablet"
            },
            {
                "regex": "(?:SC-01G|SCL24)(?:[);/ ]|$)",
                "model": "Galaxy Note Edge",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-N935[FSLK](?:[);/ ]|$)",
                "model": "Galaxy Note Fan Edition",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG-)?(?:(?:GT-)?N7100|SCH-(?:I605|N719|R950)|SPH-L900)(?:[);/ ]|$)",
                "model": "Galaxy Note II",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-N7105",
                "model": "Galaxy Note II LTE",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G710(?:2T|5[HL]|[25689KLS])?(?:[);/ ]|$)",
                "model": "Galaxy Grand 2",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G720(?:AX|N0|[02])(?:[);/ ]|$)",
                "model": "Galaxy Grand Max"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:G530(?:[689]W|[AF]Z|BT|MU|R[47]|[AFHMPTWY])|G531[FH]|S920L)(?:[);/ ]|$)",
                "model": "Galaxy Grand Prime",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G531(?:BT|[MY])(?:[);/ ]|$)",
                "model": "Galaxy Grand Prime VE Duos",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G532F(?:[);/ ]|$)",
                "model": "Galaxy Grand Prime Plus",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G532MT(?:[);/ ]|$)",
                "model": "Galaxy J2 Prime (TV)",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G532[MG](?:[);/ ]|$)",
                "model": "Galaxy J2 Prime",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-N7502(?:[);/ ]|$)",
                "model": "Galaxy Note 3 Neo Duos",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-N750[05LQ]?",
                "model": "Galaxy Note 3 Neo",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-N9002(?:[);/ ]|$)",
                "model": "Galaxy Note 3 Duos",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-9005(?:[);/ ]|$)",
                "model": "Galaxy Note 3 LTE",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG-)?(?:SM-N900(?:W8|[05689][VQ]?|[AKLPSTV])?|SCL22|SC-01F(?:[);/ ]|$))",
                "model": "Galaxy Note 3",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-(?:N910|910U)|N9100(?:[);/ ]|$)",
                "model": "Galaxy Note 4",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-N916[KLS]",
                "model": "Galaxy Note 4 LTE",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-N915",
                "model": "Galaxy Note 4 Edge",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-N920(?:R[467]|W8|[0ACFGIKLPSTVX])",
                "model": "Galaxy Note 5",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-N9208",
                "model": "Galaxy Note 5 Duos",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G750(?:8Q|[9AFH])",
                "model": "Galaxy Mega 2",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-I915[028]",
                "model": "Galaxy Mega 5.8",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-I920[05]",
                "model": "Galaxy Mega 6.3",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG-)?(?:SM-N930(?:R[467]|W8|[0AFKLPSTUVX])|SCV34)",
                "model": "Galaxy Note 7",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG[- ])?SM-N950|SCV37|SC-01K",
                "model": "Galaxy Note 8",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG[- ])?(?:SM-N960|SCV40|SC-01L)",
                "model": "Galaxy Note 9",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG[- ])?SM-(?:N970(?:U1|W8|[089DFJKLSUWX])|N971N)",
                "model": "Galaxy Note 10",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG[- ])?SM-N770[FX]",
                "model": "Galaxy Note 10 Lite",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG[- ])?(?:SM-N975(?:U1|XU|[0CFUWX])|SM-N976[0BVNQU]|SC-01M|SCV45)",
                "model": "Galaxy Note 10+",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG[- ])?(?:SM-N980(?:F/DS|F))",
                "model": "Galaxy Note 20",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG[- ])?(?:SM-N981(?:B/DS|U1|[0BNUW]))",
                "model": "Galaxy Note 20 5G",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG[- ])?(?:SM-N985(?:F/DS|F))",
                "model": "Galaxy Note 20 Ultra",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG[- ])?(?:SM-N986(?:B/DS|U1|[0BNUW])|SC-53A|SCG06)",
                "model": "Galaxy Note 20 Ultra 5G",
                "device": "phablet"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-W750V",
                "model": "Ativ SE"
            },
            {
                "regex": "(?:SAMSUNG-)?SM-G310R5",
                "model": "GreatCall Touch 3"
            },
            {
                "regex": "(?:SAMSUNG-)?GT-E2152",
                "model": "E2152",
                "device": "feature phone"
            },
            {
                "regex": "(?:SAMSUNG-)?(GT-(P|N8|N5)[0-9]+[a-z]?)",
                "device": "tablet",
                "model": "$1"
            },
            {
                "regex": "SC-02C",
                "model": "Galaxy S II"
            },
            {
                "regex": "(?:SAMSUNG-)?((?:SM-[TNP]|GT-P)[a-z0-9_\\-]+)",
                "device": "tablet",
                "model": "$1"
            },
            {
                "regex": "((?:SCH|SGH|SPH|SHV|SHW|GT|SM)-[a-z0-9_\\-]+)",
                "model": "$1"
            },
            {
                "regex": "SMART-TV",
                "device": "tv",
                "model": "Smart TV"
            },
            {
                "regex": "Galaxy ([^/;]+) Build",
                "model": "Galaxy $1"
            },
            {
                "regex": "Galaxy ([a-z0-9]+)",
                "model": "Galaxy $1"
            },
            {
                "regex": "SAMSUNG[\\-][ ]?([a-z0-9]+[\\-_][a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "SAMSUNG;[ ]?([a-z0-9]+[\\-_][a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "SAMSUNG[ _/\\-]?([a-z0-9\\-]+)",
                "model": "$1"
            },
            {
                "regex": "SAMSUNG;[ ]?([a-z0-9 ]+)",
                "model": "$1"
            },
            {
                "regex": "SEC-([a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "SAM-([a-z0-9]+)",
                "model": "SCH-$1"
            },
            {
                "regex": "(?:portalmmm|o2imode)/2\\.0 ([SZ][a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "sam([rua][0-9]+)",
                "model": "SCH-$1"
            }
        ]
    },
    "Sanei": {
        "regex": "(?:8312D|G(60[25]|70[3568]G?|785|803)|N83(?:-2cpu)?|N91) Build",
        "device": "smartphone",
        "models": [
            {
                "regex": "N83",
                "model": "N83"
            },
            {
                "regex": "N91",
                "model": "N91"
            },
            {
                "regex": "8312D",
                "model": "G101"
            },
            {
                "regex": "G(60[25]|70[3568]G?|785|803)",
                "model": "G$1"
            }
        ]
    },
    "Selfix": {
        "regex": "SELFIX",
        "device": "smartphone",
        "models": [
            {
                "regex": "SELFIX_SLASH6",
                "model": "Slash 6"
            },
            {
                "regex": "VOYAGER-V45",
                "model": "Voyager V45"
            }
        ]
    },
    "Sencor": {
        "regex": "Sencor|ELEMENT[ _]?(?:7|8|9\\.7|10[ _]1)(?:[ _]?V[23])?(?:[);/ ]|$)|ELEMENT[ _]?(?:P[0-9]+|10.1 Q001)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "(?:SENCOR[ _])?Element (10\\.1) (Q001)",
                "model": "Element $1\" $2"
            },
            {
                "regex": "(?:SENCOR[ _])?ELEMENT[ _]?7[ _]?V3",
                "model": "Element 7 V3"
            },
            {
                "regex": "(?:SENCOR[ _])?ELEMENT[ _]?7[ _]?V2[ _]BASIC",
                "model": "Element 7 V2 Basic"
            },
            {
                "regex": "(?:SENCOR[ _])?ELEMENT[ _]?7[ _]?V2",
                "model": "Element 7 V2"
            },
            {
                "regex": "(?:SENCOR[ _])?ELEMENT[ _]8[ _]?V3",
                "model": "Element 8 V3"
            },
            {
                "regex": "(?:SENCOR[ _])?ELEMENT[ _]8[ _]?V2",
                "model": "Element 8 V2"
            },
            {
                "regex": "(?:SENCOR[ _])?ELEMENT8",
                "model": "Element 8"
            },
            {
                "regex": "(?:SENCOR[ _])?ELEMENT[ _]9\\.7[ _]?V3",
                "model": "Element 9.7 V3"
            },
            {
                "regex": "(?:SENCOR[ _])?ELEMENT[ _]9\\.7[ _]?V2",
                "model": "Element 9.7 V2"
            },
            {
                "regex": "(?:SENCOR[ _])?ELEMENT[ _]9\\.7",
                "model": "Element 9.7"
            },
            {
                "regex": "(?:SENCOR[ _])?ELEMENT10[ _]1",
                "model": "Element 10.1"
            },
            {
                "regex": "(?:SENCOR[ _])?(7Q105)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "(?:SENCOR[ _])?ELEMENT[ _]?P([0-9]+)",
                "model": "Element P$1",
                "device": "smartphone"
            },
            {
                "regex": "Sencor[ _]([^;/]+) Build",
                "model": "$1"
            }
        ]
    },
    "Senwa": {
        "regex": "Senwa|(?:S\\-?(?:471|7[12]5|6[01]5|915|905TL|1000|5018)|V705B|LS50F| LS50?|LS5518H|LS5718)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "S-?471(?:[);/ ]|$)",
                "model": "Jazz"
            },
            {
                "regex": "S-?605(?:[);/ ]|$)",
                "model": "City"
            },
            {
                "regex": "S-?905TL(?:[);/ ]|$)",
                "model": "Odin"
            },
            {
                "regex": "S-?1000(?:[);/ ]|$)",
                "model": "Odin"
            },
            {
                "regex": "S-?5018(?:[);/ ]|$)",
                "model": "Inizio"
            },
            {
                "regex": "S-?(615|7[12]5|915)(?:[);/ ]|$)",
                "model": "S$1"
            },
            {
                "regex": "V705B(?:[);/ ]|$)",
                "model": "V705B"
            },
            {
                "regex": "LS50F(?:[);/ ]|$)",
                "model": "Evolution"
            },
            {
                "regex": " LS50(?:[);/ ]|$)",
                "model": "Pegasus"
            },
            {
                "regex": " LS5(?:[);/ ]|$)",
                "model": "Thor"
            },
            {
                "regex": "LS5518H(?:[);/ ]|$)",
                "model": "Sparkly"
            },
            {
                "regex": "LS5718(?:[);/ ]|$)",
                "model": "Diamond"
            },
            {
                "regex": "Senwa[ _-]*([^;/]+)(?: Build|[;)])",
                "model": "$1"
            }
        ]
    },
    "Sky": {
        "regex": "(?:SKY|Elite|Fuego)[_ ][3-7]\\.[05](?:[A-Z]{1,2})?|Elite[ _](?:5_0T|M45|[45]T|45T|GO|M5Plus|T55|T[46]|[AB]5|5_5_Octa)|Platinum[_ ](?:5.0M|5\\.5C|5\\.0+|M5|[ABCM]4|[ACG]55|[ABDEFG]5|A57|P9|X|UNO|VIEW2|O|4\\.0|4_0Plus)|PLATINUMVIEW|SKY[ _](?:55M|Pro_III)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Platinum[_ ](?:5\\.0M|M5)",
                "model": "Platinum 5.0M"
            },
            {
                "regex": "Platinum[_ ]5\\.5C",
                "model": "Platinum 5.5C"
            },
            {
                "regex": "Platinum[_ ]5\\.0\\+",
                "model": "Platinum 5.0 Plus"
            },
            {
                "regex": "Platinum_4_0Plus",
                "model": "Platinum 4.0 Plus"
            },
            {
                "regex": "Platinum[ _]4\\.0",
                "model": "Platinum 4.0"
            },
            {
                "regex": "Platinum[_ ](P9) Pro(?:[);/ ]|$)",
                "model": "Platinum $1 Pro"
            },
            {
                "regex": "Platinum[_ ]([ABCM]4|[ACG]55|[ABDEFG]5|A57|P9|X|UNO|O)(?:[);/ ]|$)",
                "model": "Platinum $1"
            },
            {
                "regex": "Platinum[_ ]?VIEW(2)?(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Platinum View$1"
            },
            {
                "regex": "Elite_5_5_Octa",
                "model": "Elite 5.5 Octa"
            },
            {
                "regex": "Elite M5Plus",
                "model": "Elite M5 Plus"
            },
            {
                "regex": "Elite[ _](M45|Go|[45]T|T[46]|T55|[AB]5|A55|45T)(?:[);/ ]|$)",
                "model": "Elite $1"
            },
            {
                "regex": "Elite[_ ]([3-7])[\\._]([05](?:[A-Z]{1,2})?\\+?)",
                "model": "Elite $1.$2"
            },
            {
                "regex": "Fuego[_ ]([3-7]\\.[05](?:[A-Z]{1,2})?\\+?)",
                "model": "Fuego $1"
            },
            {
                "regex": "SKY_Pro_III",
                "model": "Pro III"
            },
            {
                "regex": "SKY 55M",
                "model": "55M"
            },
            {
                "regex": "SKY[_ ]([3-7]\\.[05](?:[A-Z]{1,2})?)",
                "model": "$1"
            }
        ]
    },
    "Skyworth": {
        "regex": "Sky_?worth",
        "device": "tv",
        "models": [
            {
                "regex": "Sky_?worth ([^;/]+) Build",
                "model": "$1"
            }
        ]
    },
    "Smartfren": {
        "regex": "Smartfren|Androtab|Andromax|PD6D1J|AD682J|AD68[89]G|AD6B1H|AD9A1H|AD682H|AD683G",
        "device": "smartphone",
        "models": [
            {
                "regex": "S7 Build",
                "model": "Andromax Tab 7.0",
                "device": "tablet"
            },
            {
                "regex": "PD6D1J",
                "model": "Andromax V3s"
            },
            {
                "regex": "AD682J",
                "model": "Andromax T"
            },
            {
                "regex": "AD688G",
                "model": "Andromax C2"
            },
            {
                "regex": "AD689G",
                "model": "Andromax i3"
            },
            {
                "regex": "AD682H",
                "model": "Andromax i3s"
            },
            {
                "regex": "AD6B1H",
                "model": "Andromax G2 Hot"
            },
            {
                "regex": "AD9A1H",
                "model": "Andromax G2"
            },
            {
                "regex": "AD683G",
                "model": "Andromax I"
            },
            {
                "regex": "New Andromax-i",
                "model": "New Andromax I"
            },
            {
                "regex": "Andromax[ _\\-]([^/;]+) Build",
                "model": "Andromax $1"
            },
            {
                "regex": "Andromax[ _\\-]([a-z0-9]+)",
                "model": "Andromax $1"
            },
            {
                "regex": "Smartfren[ _\\-]([^/;\\)]+)(?: Build|[/;\\)])",
                "model": "$1"
            },
            {
                "regex": "(Androtab[^/;]+) Build",
                "model": "$1",
                "device": "tablet"
            }
        ]
    },
    "Smartisan": {
        "regex": "(SM(?:70[15]|[89]01|919)|OD10[35]|YQ60[1357]|DE106|DT1901A|O[CE]106|OS103|O[CS]105)[ /;\\)]",
        "device": "smartphone",
        "models": [
            {
                "regex": "SM70[15]",
                "model": "T1"
            },
            {
                "regex": "SM801",
                "model": "T2"
            },
            {
                "regex": "SM901",
                "model": "M1"
            },
            {
                "regex": "SM919",
                "model": "M1L"
            },
            {
                "regex": "YQ60[1357]",
                "model": "U1"
            },
            {
                "regex": "OD10[35]",
                "model": "Pro"
            },
            {
                "regex": "OS105[ /;\\)]",
                "model": "Pro 2"
            },
            {
                "regex": "DE106[ /;\\)]",
                "model": "R1"
            },
            {
                "regex": "OE106[ /;\\)]",
                "model": "Nut Pro 2s"
            },
            {
                "regex": "OC10[56][ /;\\)]",
                "model": "Nut 3"
            },
            {
                "regex": "DT1901A[ /;\\)]",
                "model": "Nut Pro 3"
            },
            {
                "regex": "OS103[ /;\\)]",
                "model": "JianGuo Pro 2"
            }
        ]
    },
    "STF Mobile": {
        "regex": "(?:Fractal|AERIAL PLUS|Origins Pro|BLOCK_MINI|BLOCK GO|BLOCK 3|SLAY II)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Fractal",
                "model": "Fractal"
            },
            {
                "regex": "Origins Pro",
                "model": "Origins Pro"
            },
            {
                "regex": "AERIAL PLUS",
                "model": "Aerial Plus"
            },
            {
                "regex": "BLOCK_MINI",
                "model": "Block Mini"
            },
            {
                "regex": "BLOCK 3 mini",
                "model": "Block 3 Mini"
            },
            {
                "regex": "BLOCK 3",
                "model": "Block 3"
            },
            {
                "regex": "BLOCK GO",
                "model": "Block Go"
            },
            {
                "regex": "SLAY II",
                "model": "Slay II"
            }
        ]
    },
    "STK": {
        "regex": "STK[_ ]",
        "device": "smartphone",
        "models": [
            {
                "regex": "STK[_ ]([^/;]+) Build",
                "model": "$1"
            },
            {
                "regex": "STK[_ ]([a-z0-9]+)",
                "model": "$1"
            }
        ]
    },
    "Stonex": {
        "regex": "STX[ -]([^;/]+)|STONE EVO",
        "device": "smartphone",
        "models": [
            {
                "regex": "STX[ -]([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "STX[ -]([a-z0-9_-]+)",
                "model": "$1"
            }
        ]
    },
    "SuperSonic": {
        "regex": "(SC-[0-9]+[a-z]+)",
        "device": "tablet",
        "model": "$1"
    },
    "Supra": {
        "regex": "SUPRA|NVTAB 7.0 3G",
        "device": "smartphone",
        "models": [
            {
                "regex": "SUPRA[ _]([^;/)]+)(?: Build|[;)])",
                "device": "tablet",
                "model": "$1"
            },
            {
                "regex": "(NVTAB 7.0 3G)",
                "device": "tablet",
                "model": "$1"
            }
        ]
    },
    "Sumvision": {
        "regex": "(Cyclone [^/;]+) Build",
        "device": "tablet",
        "model": "$1"
    },
    "SunVan": {
        "regex": "SUNVAN[ _\\-]",
        "device": "smartphone",
        "models": [
            {
                "regex": "SUNVAN[ _\\-]?([^/;]+) Build",
                "model": "$1"
            },
            {
                "regex": "SUNVAN[ _\\-]?([^);/ ]+)",
                "model": "$1"
            }
        ]
    },
    "SWISSMOBILITY": {
        "regex": "(?:SCHR9GR|PRA(?:U8|100)|ZUR(?:722M|70016W?|700[RW]|805A))(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "SCHR9GR",
                "model": "SCHON R9"
            },
            {
                "regex": "PRA(U8|100)",
                "model": "PRA$1"
            },
            {
                "regex": "ZUR722M",
                "device": "phablet",
                "model": "Z72 go"
            },
            {
                "regex": "ZUR(70016W?|700[RW]|805A)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "ZUR$1"
            }
        ]
    },
    "Thomson": {
        "regex": "(?:Primo(7|8)|QM73[45]-8G|QM734-2|QM736-8G\\(HD\\)|(?<!\\.)8950|MID1002|5014G|TEO7RK|Friendly_TH101|Delight TH201)(?:[);/ ]|$)|(?:Tlink|Every)[0-9]+|THOMSON,",
        "device": "tablet",
        "models": [
            {
                "regex": "Primo(7|8)",
                "model": "Primo $1"
            },
            {
                "regex": "QM734-2",
                "model": "QM734-2"
            },
            {
                "regex": "QM734-8G",
                "model": "QM734-8G"
            },
            {
                "regex": "QM735-8G",
                "model": "QM735-8G"
            },
            {
                "regex": "QM736-8G\\(HD\\)",
                "model": "QM736-8G (HD)"
            },
            {
                "regex": "(?<!\\.)8950(?:[);/ ]|$)",
                "model": "3G 8950"
            },
            {
                "regex": "TEO7RK(?:[);/ ]|$)",
                "model": "TEO7RK"
            },
            {
                "regex": "5014G(?:[);/ ]|$)",
                "device": "smartphone",
                "model": "5014G"
            },
            {
                "regex": "Tlink([0-9]+)",
                "device": "smartphone",
                "model": "Tlink$1"
            },
            {
                "regex": "Every([0-9]+)",
                "device": "smartphone",
                "model": "Every$1"
            },
            {
                "regex": "Delight TH201",
                "device": "smartphone",
                "model": "Delight TH201"
            },
            {
                "regex": "Friendly_TH101",
                "device": "smartphone",
                "model": "Friendly TH101"
            },
            {
                "regex": "((?:49|55)UC6306|55UC63|32HD55|40FD54|55UD63)",
                "device": "tv",
                "model": "$1"
            }
        ]
    },
    "Tanix": {
        "regex": "TX5 MAX(?:[);/ ]|$)",
        "device": "tv",
        "model": "TX5 Max"
    },
    "Pantech": {
        "regex": "Pantech|P[GN]-|PT-[a-z0-9]{3,}|(?<!Winmax )TX[T]?[0-9]+|IM-[a-z0-9]+(?:[);/ ]|$)|ADR910L",
        "device": "smartphone",
        "models": [
            {
                "regex": "ADR910L",
                "model": "Star Q"
            },
            {
                "regex": "IM-A870K",
                "model": "Vega Iron"
            },
            {
                "regex": "Pantech[ \\-]?([a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "Pantech_([a-z0-9\\-]+)",
                "model": "$1"
            },
            {
                "regex": "(P[GTN]-[a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "(TX[T]?[0-9]+)",
                "model": "$1"
            },
            {
                "regex": "(IM-[a-z0-9]+)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Ployer": {
        "regex": "MOMO([0-9]|[ ]?mini[ ]?(?:S|3GS)?)",
        "device": "tablet",
        "models": [
            {
                "regex": "MOMOminiS",
                "model": "MOMO mini S"
            },
            {
                "regex": "momo mini 3GS",
                "model": "MOMO Mini 3GS"
            },
            {
                "regex": "MOMO([0-9][^);/]*) Build",
                "model": "MOMO $1"
            },
            {
                "regex": "MOMO([0-9][^);/]*)(?:[);/]|$)",
                "model": "MOMO $1"
            }
        ]
    },
    "Plum": {
        "regex": "PLUM[ _]",
        "device": "smartphone",
        "models": [
            {
                "regex": "Z405",
                "model": "Gator 3"
            },
            {
                "regex": "Z407",
                "model": "Axe 4"
            },
            {
                "regex": "Z516",
                "model": "Compass"
            },
            {
                "regex": "Z811",
                "device": "tablet",
                "model": "Optimax 11"
            },
            {
                "regex": "Z709",
                "device": "tablet",
                "model": "Optimax 7"
            },
            {
                "regex": "Z712",
                "device": "tablet",
                "model": "Optimax 12"
            }
        ]
    },
    "Polaroid": {
        "regex": "Polaroid|(?:PMID|MIDC)[0-9a-z]+(?:[);/ ]|$)|MID(?:1014|0714)|PRO[VG]?(?:[0-9]{3,}[a-z]*|[0-9]{2}[a-z])|(?:P(?:400[56]|4526|500[56]|502[56]|504[67]|552[56])A|P902/A900|PSPC(?:505|550|[MZL]20A0|K2[01]NA)|SIGMA 5|MIDS145PXE|MIDS747PX|MID4004JBL|MIDS185PR|PSPTD21NA)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "P4005A",
                "model": "Turbo C4"
            },
            {
                "regex": "P5005A",
                "model": "Turbo C5"
            },
            {
                "regex": "P4006A",
                "model": "Turbo D4"
            },
            {
                "regex": "PSPTD21NA",
                "model": "Turbo D2 Plus"
            },
            {
                "regex": "P4526A",
                "model": "Turbo E"
            },
            {
                "regex": "PSPC(505|550)",
                "model": "Cosmo $1"
            },
            {
                "regex": "P5006A",
                "model": "Cosmo K"
            },
            {
                "regex": "P5526A",
                "model": "Cosmo K Plus"
            },
            {
                "regex": "PSPCK20NA",
                "model": "Cosmo K2"
            },
            {
                "regex": "PSPCK21NA",
                "model": "Cosmo K2 Plus"
            },
            {
                "regex": "P5026A",
                "model": "Cosmo L"
            },
            {
                "regex": "PSPCL20A0",
                "model": "Cosmo L2"
            },
            {
                "regex": "PSPCM20A0",
                "model": "Cosmo M2"
            },
            {
                "regex": "P5046A",
                "model": "Cosmo P5s"
            },
            {
                "regex": "P5525A",
                "model": "Cosmo Q5s"
            },
            {
                "regex": "P5047A",
                "model": "Cosmo Z"
            },
            {
                "regex": "PSPCZ20A0",
                "model": "Cosmo Z2"
            },
            {
                "regex": "P5025A",
                "model": "L5s"
            },
            {
                "regex": "PRO4611(?:PR201)?",
                "model": "Pro4611"
            },
            {
                "regex": "PROV400",
                "model": "Agate"
            },
            {
                "regex": "PROV350",
                "model": "ProV350"
            },
            {
                "regex": "SIGMA 5(?:[);/ ]|$)",
                "model": "Sigma 5"
            },
            {
                "regex": "PRO([VG]?(?:[0-9]{3,}[a-z]*|[0-9]{2}[a-z]))",
                "model": "Pro$1"
            },
            {
                "regex": "PSPT350",
                "model": "Turbo 350 Gris"
            },
            {
                "regex": "MIDS185PR",
                "model": "Pure 10.1\""
            },
            {
                "regex": "(MID(?:1014|0714|S145PXE|S747PX|4004JBL))",
                "model": "$1",
                "device": "tablet"
            },
            {
                "regex": "((?:PMID|MIDC)[0-9a-z]+)(?:[);/ ]|$)",
                "model": "$1",
                "device": "tablet"
            },
            {
                "regex": "(P902/A900)(?:[);/ ]|$)",
                "model": "$1",
                "device": "tablet"
            },
            {
                "regex": "Polaroid",
                "model": "",
                "device": "tablet"
            }
        ]
    },
    "PolyPad": {
        "regex": "POLY ?PAD",
        "device": "tablet",
        "models": [
            {
                "regex": "POLY ?PAD[_ \\.]([a-z0-9]+)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "POLY ?PAD[_\\.]([a-z0-9]+)",
                "model": "$1"
            }
        ]
    },
    "Polytron": {
        "regex": "POLYTRON|W8480|W7430|PW1100S|W7550|W7531|W8570|R2401",
        "device": "smartphone",
        "models": [
            {
                "regex": "R2508",
                "model": "Rocket"
            },
            {
                "regex": "POLYTRON[ _]R1500",
                "model": "Rocket Q-Five"
            },
            {
                "regex": "R2401",
                "model": "Rocket 2X"
            },
            {
                "regex": "POLYTRON[ _-]R2402",
                "model": "Rocket R2402"
            },
            {
                "regex": "POLYTRON[ _-]R2403",
                "model": "Rocket R1"
            },
            {
                "regex": "POLYTRON[ _-]R2406",
                "model": "Rocket R2"
            },
            {
                "regex": "POLYTRON[ _-]R2407",
                "model": "Rocket R3"
            },
            {
                "regex": "POLYTRON[ _-]R2452",
                "model": "Rocket S1"
            },
            {
                "regex": "POLYTRON[ _-]R2457",
                "model": "Rocket S2"
            },
            {
                "regex": "POLYTRON[ _-]R2501",
                "model": "Rocket T1"
            },
            {
                "regex": "POLYTRON[ _-]R2507",
                "model": "Rocket T3"
            },
            {
                "regex": "POLYTRON[ _-]R2509SE",
                "model": "Rocket T6 SE"
            },
            {
                "regex": "POLYTRON[ _-]R2509",
                "model": "Rocket T6"
            },
            {
                "regex": "POLYTRON[ _-]R250A",
                "model": "Rocket T7"
            },
            {
                "regex": "POLYTRON[ _-]R2506",
                "model": "Rocket T4"
            },
            {
                "regex": "POLYTRON[ _-](?:R3450|W1400)",
                "model": "Rocket Jetz"
            },
            {
                "regex": "POLYTRON[ _-]R3500",
                "model": "Rocket Jetz 5.0"
            },
            {
                "regex": "4G450",
                "model": "Zap 5"
            },
            {
                "regex": "W6500",
                "model": "Quadra Rocket"
            },
            {
                "regex": "W7452",
                "model": "Quadra S2"
            },
            {
                "regex": "W7550",
                "model": "Quadra V5"
            },
            {
                "regex": "W8570",
                "model": "Quadra V7"
            },
            {
                "regex": "POLYTRON[ _]L501",
                "model": "Rocket L501"
            },
            {
                "regex": "POLYTRON[ _]P520",
                "model": "Prime 7S"
            },
            {
                "regex": "POLYTRON[ _]P552",
                "model": "Prime 7 Pro"
            },
            {
                "regex": "4G500",
                "model": "Zap 6 Cleo"
            },
            {
                "regex": "W8480",
                "model": "Crystal 4 W8480"
            },
            {
                "regex": "W7531",
                "model": "Wizard V"
            },
            {
                "regex": "W7430",
                "model": "Quadra Mini W7430"
            },
            {
                "regex": "POLYTRON[ _]4G501",
                "model": "Zap 6 Posh"
            },
            {
                "regex": "POLYTRON[ _]4G502",
                "model": "Zap 6 Power"
            },
            {
                "regex": "POLYTRON[ _]4G503",
                "model": "Zap 6 Flaz"
            },
            {
                "regex": "POLYTRON[_ ]?[ _]4G550",
                "model": "Zap 6 Note"
            },
            {
                "regex": "POLYTRON[ _]4G551",
                "model": "Zap 6 Posh Note"
            },
            {
                "regex": "PW1100S",
                "model": "PW1100S"
            },
            {
                "regex": "Q2352",
                "model": "Q2352"
            },
            {
                "regex": "POLYTRON[ _]T7800",
                "model": "Cosmica T7800",
                "device": "tablet"
            },
            {
                "regex": "POLYTRON[ _]T7700",
                "model": "Rocket Pad",
                "device": "tablet"
            }
        ]
    },
    "Positivo": {
        "regex": "YPY_S450",
        "device": "smartphone",
        "models": [
            {
                "regex": "YPY_S450",
                "model": "YPY S450"
            }
        ]
    },
    "Prestigio": {
        "regex": "Prestigio 5500 DUO|(?:PMP|PAP|PMT|PSP|PGPS)[0-9]+[a-z0-9_]+(?:[);/ ]|$)|Muze C3",
        "device": "tablet",
        "models": [
            {
                "regex": "PMT3277_3G",
                "model": "MultiPad Ranger 7.0 3G"
            },
            {
                "regex": "PGPS7799CIS08GBPG(?:[);/ ]|$)",
                "model": "Geo Vision Tour 3"
            },
            {
                "regex": "PGPS7797(?:[);/ ]|$)",
                "model": "Geo Vision Tour 2"
            },
            {
                "regex": "PMT3201_4G",
                "model": "Grace 4G"
            },
            {
                "regex": "PSP5551DUO",
                "model": "Grace S5"
            },
            {
                "regex": "PMT3287_3G",
                "model": "MultiPad Ranger 8.0 3G"
            },
            {
                "regex": "Prestigio 5500 DUO",
                "model": "MultiPhone 5500 Duo",
                "device": "smartphone"
            },
            {
                "regex": "PMT3208[_ ]3G",
                "model": "MultiPad Wize 8.0 3G"
            },
            {
                "regex": "PSP7546DUO([ _][A-Z]{2})?",
                "model": "X Pro"
            },
            {
                "regex": "PSP7610DUO([ _][A-Z]{2})?",
                "model": "S Max"
            },
            {
                "regex": "(PM[PT][0-9]+[a-z0-9_]+)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "((?:PAP|PSP)[0-9]+[a-z0-9_]+(?: DUO)?)(?:[);/ ]|$)",
                "model": "$1",
                "device": "smartphone"
            },
            {
                "regex": "Muze C3(?:[);/ ]|$)",
                "model": "Muze C3",
                "device": "smartphone"
            }
        ]
    },
    "Sanyo": {
        "regex": "Sanyo|MobilePhone[ ;]",
        "device": "feature phone",
        "models": [
            {
                "regex": "SCP-?6750",
                "model": "Katana Eclipse X"
            },
            {
                "regex": "SCP-?6760",
                "model": "Incognito"
            },
            {
                "regex": "SCP-?6780",
                "model": "Innuendo"
            },
            {
                "regex": "SANYO[ /_-]([a-z0-9\\-]+)",
                "model": "$1"
            },
            {
                "regex": "MobilePhone ([a-z0-9\\-]+)",
                "model": "$1"
            }
        ]
    },
    "Qilive": {
        "regex": "Qilive [0-9][^;/]*|Q(7S5[5]?IN4G|7T10INP|8T10IN|8S55IN4G2|8S[56]IN4G|9T9IN|9T(?:7|10)IN4G|9S55IN4G|10S5[37]IN4G|10S[56]IN4G|10S[56]IN4GR|9S5IN4G|6T7IN)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Q7S5IN4G",
                "model": "Q7 5.0\" 4G"
            },
            {
                "regex": "Q7S55IN4G",
                "model": "Q7 5.5\" 4G"
            },
            {
                "regex": "Q8S55IN4G2",
                "model": "Q8 5.5\" 4G"
            },
            {
                "regex": "Q8S6IN4G",
                "model": "Q8 6.0\" 4G"
            },
            {
                "regex": "Q8S5IN4GP",
                "model": "Q8 5.1\" 4G"
            },
            {
                "regex": "Q9S5IN4G",
                "model": "Q9 5.0\" 4G"
            },
            {
                "regex": "Q9S55IN4G",
                "model": "Q9 5.5\" 4G"
            },
            {
                "regex": "Q10S53IN4G",
                "model": "Q10 5.3\" 4G"
            },
            {
                "regex": "Q10S57IN4G",
                "model": "Q10 5.7\" 4G"
            },
            {
                "regex": "Q10S5IN4G[R]?",
                "model": "Q10 5.0\" 4G"
            },
            {
                "regex": "Q10S6IN4G",
                "model": "Q10 6.0\" 4G"
            },
            {
                "regex": "Q6T7IN",
                "model": "Q6",
                "device": "tablet"
            },
            {
                "regex": "Q7T10INP",
                "model": "Q7 10.1\"",
                "device": "tablet"
            },
            {
                "regex": "Q8T10IN",
                "model": "Q8 10.1\"",
                "device": "tablet"
            },
            {
                "regex": "Q9T7IN4G",
                "model": "Q9 7.0\" 4G",
                "device": "tablet"
            },
            {
                "regex": "Q9T9IN",
                "model": "Q9 9.0\"",
                "device": "tablet"
            },
            {
                "regex": "Q9T10IN4G",
                "model": "Q9 10.1\" 4G",
                "device": "tablet"
            },
            {
                "regex": "Qilive ([0-5][^;/]*) Build",
                "model": "$1"
            },
            {
                "regex": "Qilive ([0-5][^;/]*)/",
                "model": "$1"
            },
            {
                "regex": "Qilive ([6-9][^;/]*) Build",
                "model": "$1",
                "device": "tablet"
            },
            {
                "regex": "Qilive ([6-9][^;/]*)/",
                "model": "$1",
                "device": "tablet"
            }
        ]
    },
    "QMobile": {
        "regex": "QMobile|QTab(?!10EW)|Q-Smart|(?:E2 Noir|Noir X1S|LUNA PRO|NICE S|JazzX JS(?:[27]|10)(?: PRO)?|LT[57]00 PRO|LT950|Q Infinity|Hot Pro 2)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "JazzX JS7 PRO",
                "model": "Jazz X JS7 Pro"
            },
            {
                "regex": "JazzX JS(2|10)",
                "model": "Jazz X JS$1"
            },
            {
                "regex": "Noir X1S",
                "model": "Noir X1S"
            },
            {
                "regex": "E2 Noir",
                "model": "E2 Noir"
            },
            {
                "regex": "Hot Pro 2",
                "model": "Hot Pro 2"
            },
            {
                "regex": "LT([57]00) PRO",
                "model": "Noir LT$1 Pro"
            },
            {
                "regex": "LUNA PRO",
                "model": "Luna Pro"
            },
            {
                "regex": "NICE S",
                "model": "Nice S"
            },
            {
                "regex": "Q Infinity PRO",
                "model": "Q Infinity Pro"
            },
            {
                "regex": "Q Infinity E Lite",
                "model": "Q Infinity E Lite"
            },
            {
                "regex": "Q Infinity ([FCB])",
                "model": "Q Infinity $1"
            },
            {
                "regex": "(LT950)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "QTab[ _-]([^;/]+) Build",
                "model": "$1 Tab",
                "device": "tablet"
            },
            {
                "regex": "QTab[ _-]([^;/)]+)(?:[;/)]|$)",
                "model": "$1 Tab",
                "device": "tablet"
            },
            {
                "regex": "(?:QMobile|Q-Smart)[ _-]([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "(?:QMobile|Q-Smart)[ _-]([^;/)]+)(?:[;/)]|$)",
                "model": "$1"
            },
            {
                "regex": "Q Infinity",
                "model": "Q Infinity"
            }
        ]
    },
    "Maximus": {
        "regex": "Maximus[ _]|(Noir X)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(?:Maximus[ _])?(Noir X|D1)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "Maximus_(T2)",
                "device": "tablet",
                "model": "$1"
            }
        ]
    },
    "Quantum": {
        "regex": "Quantum (?:Fit|MUV (?:PRO|UP)|Go|Mini|V|You [2E])(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Quantum (Fit|Go|Mini|V|You [2E])(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "Quantum MUV PRO(?:[);/ ]|$)",
                "model": "MUV Pro"
            },
            {
                "regex": "Quantum MUV UP(?:[);/ ]|$)",
                "model": "MUV Up"
            }
        ]
    },
    "Quechua": {
        "regex": "Quechua Phone 5",
        "device": "smartphone",
        "model": "Quechua Phone 5"
    },
    "Ramos": {
        "regex": "Ramos ?([^/;]+) Build|MOS1(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "(MOS1)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "Ramos ?([^/;]+) Build",
                "model": "$1"
            }
        ]
    },
    "Razer": {
        "regex": "Phone 2",
        "device": "smartphone",
        "model": "Phone 2"
    },
    "Sendo": {
        "regex": "Sendo([a-z0-9]+)",
        "device": "feature phone",
        "model": "$1"
    },
    "Turbo-X": {
        "regex": "Turbo-X|Hive V 3G|Turbox_S3",
        "device": "smartphone",
        "models": [
            {
                "regex": "Turbox_S3",
                "model": "S3"
            },
            {
                "regex": "Turbo-X_A2(?:[);/ ]|$)",
                "model": "A2"
            },
            {
                "regex": "Turbo-X_e3(?:[);/ ]|$)",
                "model": "E3"
            },
            {
                "regex": "Turbo-X Ice(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Ice"
            },
            {
                "regex": "Hive V 3G(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Hive V 3G"
            },
            {
                "regex": "Turbo-X Tablet([^;/]+) Build",
                "device": "tablet",
                "model": "Tablet$1"
            },
            {
                "regex": "Turbo-X ([^;/]+) Build",
                "model": "$1"
            }
        ]
    },
    "Turbo": {
        "regex": "Turbo[_ ]?(?:X5[_ ]?(?:Max|Black|Space|Hero)|X8|X6[ _]Z|X[ _]Ray)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "TurboX5Space(?:[);/ ]|$)",
                "model": "X5 Space"
            },
            {
                "regex": "TurboX5Hero(?:[);/ ]|$)",
                "model": "X5 Hero"
            },
            {
                "regex": "TurboX[ _]Ray(?:[);/ ]|$)",
                "model": "X Ray"
            },
            {
                "regex": "Turbo[ _]?X5[ _]?Black(?:[);/ ]|$)",
                "model": "X5 Black"
            },
            {
                "regex": "Turbo[ _]X5[ _]Max(?:[);/ ]|$)",
                "model": "X5 Max"
            },
            {
                "regex": "Turbo[ _]X6[ _]Z(?:[);/ ]|$)",
                "model": "X6 Z"
            },
            {
                "regex": "Turbo[ _]X8(?:[);/ ]|$)",
                "model": "X8"
            }
        ]
    },
    "Silent Circle": {
        "regex": "Blackphone 2",
        "device": "smartphone",
        "model": "Blackphone 2"
    },
    "Sigma": {
        "regex": "Sigma(?!Designs)|(X[_-]Style[ _]?Tab[_ ]A([0-9]{2,3})|X[-_ ]?treme[-_ ]?PQ[0-9]{2}|X-Style_S5501)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(?:Sigma[ _])?X[-_ ]?treme[-_ ]?PQ([0-9]{2})(?:[);/ ]|$)",
                "model": "X-Treme PQ$1"
            },
            {
                "regex": "X[_-]Style[ _]?Tab[_ ]A([0-9]{2,3})(?:[);/ ]|$)",
                "model": "X-Style Tab A$1",
                "device": "tablet"
            },
            {
                "regex": "(?:Sigma[ _])?X[-_ ]?Style[-_ ]S([0-9]+)(?:[);/ ]|$)",
                "model": "X-Style S$1"
            }
        ]
    },
    "Spice": {
        "regex": "(?<![\\d];)Spice",
        "device": "smartphone",
        "models": [
            {
                "regex": "Spice[ _\\-]?([^/;]+)(?:[\\)]| Build)",
                "model": "$1"
            },
            {
                "regex": "Spice[ _\\-]?([^/;]+)(?:/|$)",
                "model": "$1"
            }
        ]
    },
    "Sharp": {
        "regex": "SHARP|SBM|SH-?[0-9]+[a-z]?(?:[);/ ]|$)|AQUOS|(?:SH-M0[14-9]|SH-M1[1-6](?:-y)?|S[357]-SH|SH-[MZ](10|01)|SH-[CL]02|SH-RM(?:1[125]|02)|[34]04SH|401SH|[45]02SH|306SH|[36]05SH|70[1246]SH|80[138]SH|90[1678]SH|50[3679]SH|SHL2[25]|SHV4[0-8]|SHV3[1-9](?:[-_]u)?|FS80(?:1[08]|32|28|0[29])|TG-L900S|NP601SH|403SH|603SH|SHF3[134]|SHV4[035][-_]u|SW001SH|SHG0[123]|X4-SH|A002SH|SH-A01|DM-01J|SH-D01|A001SH|606SH|FS801[56]|d-41A|NP80[567]SH|NP501SH)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "506SH(?:[);/ ]|$)",
                "model": "Aquos Xx3"
            },
            {
                "regex": "603SH(?:[);/ ]|$)",
                "model": "Aquos Xx3 Mini"
            },
            {
                "regex": "50[23]SH(?:[);/ ]|$)",
                "model": "Aquos Xx2"
            },
            {
                "regex": "[34]04SH(?:[);/ ]|$)",
                "model": "Aquos Xx"
            },
            {
                "regex": "606SH(?:[);/ ]|$)",
                "model": "Aquos ea"
            },
            {
                "regex": "507SH(?:[);/ ]|$)",
                "model": "Android One"
            },
            {
                "regex": "S3-SH(?:[);/ ]|$)",
                "model": "Android One S3"
            },
            {
                "regex": "S5-SH(?:[);/ ]|$)",
                "model": "Android One S5"
            },
            {
                "regex": "S7-SH(?:[);/ ]|$)",
                "model": "Android One S7"
            },
            {
                "regex": "X4-SH(?:[);/ ]|$)",
                "model": "Android One X4"
            },
            {
                "regex": "SH-M04(?:[);/ ]|$)",
                "model": "Aquos SH-M04"
            },
            {
                "regex": "SH-M05(?:[);/ ]|$)",
                "model": "Aquos Sense Lite"
            },
            {
                "regex": "SH-M07(?:[);/ ]|$)",
                "model": "Aquos Sense Plus"
            },
            {
                "regex": "SHG03(?:[);/ ]|$)",
                "model": "Aquos Sense 5G"
            },
            {
                "regex": "SH-D01(?:[);/ ]|$)",
                "model": "Aquos D10"
            },
            {
                "regex": "SH-M12(?:-y)?(?:[);/ ]|$)",
                "model": "Aquos S3 Dual SIM"
            },
            {
                "regex": "(?:SH-[MZ]10|801SH)(?:[);/ ]|$)",
                "model": "Aquos Zero"
            },
            {
                "regex": "(?:SH-M13|906SH)(?:[);/ ]|$)",
                "model": "Aquos Zero 2"
            },
            {
                "regex": "(?:SHG02|A002SH)(?:[);/ ]|$)",
                "model": "Aquos Zero 5G Basic"
            },
            {
                "regex": "SH-Z01(?:[);/ ]|$)",
                "model": "Aquos S2 (C10)"
            },
            {
                "regex": "(?:SH-02E|SH-01[GH]|SH-04H)(?:[);/ ]|$)",
                "model": "Aquos Phone Zeta"
            },
            {
                "regex": "SH-?06D(?:[);/ ]|$)",
                "model": "Aquos Phone SH-06D"
            },
            {
                "regex": "(SHL2[25]|SH-M01)(?:[);/ ]|$)",
                "model": "Aquos Phone $1"
            },
            {
                "regex": "SH-L02(?:[);/ ]|$)",
                "model": "Aquos L2"
            },
            {
                "regex": "(?:605SH|SH-03J|SHV39)(?:[);/ ]|$)",
                "model": "Aquos R"
            },
            {
                "regex": "(?:605SH|SH-03J|SHV39)(?:[);/ ]|$)",
                "model": "Aquos R"
            },
            {
                "regex": "(?:701SH|SHV41|SH-M06)(?:[);/ ]|$)",
                "model": "Aquos R Compact"
            },
            {
                "regex": "(?:SH-M09|SHV42|706SH|SH-03K)(?:[);/ ]|$)",
                "model": "Aquos R2"
            },
            {
                "regex": "803SH(?:[);/ ]|$)",
                "model": "Aquos R2 Compact"
            },
            {
                "regex": "(?:908SH|SHG01|SH-M14)(?:[);/ ]|$)",
                "model": "Aquos R5G"
            },
            {
                "regex": "SH-M16(?:[);/ ]|$)",
                "model": "Aquos S4 Plus"
            },
            {
                "regex": "SH-M15(?:[);/ ]|$)",
                "model": "Aquos S4 SIM"
            },
            {
                "regex": "SH-C02(?:[);/ ]|$)",
                "model": "Aquos V"
            },
            {
                "regex": "(SHF3[134])(?:[);/ ]|$)",
                "model": "Aquos K $1"
            },
            {
                "regex": "704SH(?:[);/ ]|$)",
                "model": "Simple Smartphone 4"
            },
            {
                "regex": "(?:SH-02J|SH-04G)(?:[);/ ]|$)",
                "model": "Aquos Ever"
            },
            {
                "regex": "SH-02H(?:[);/ ]|$)",
                "model": "Aquos Compact"
            },
            {
                "regex": "30[56]SH(?:[);/ ]|$)",
                "model": "Aquos Crystal"
            },
            {
                "regex": "402SH(?:[);/ ]|$)",
                "model": "Aquos Crystal X"
            },
            {
                "regex": "403SH(?:[);/ ]|$)",
                "model": "Aquos Crystal 2"
            },
            {
                "regex": "509SH(?:[);/ ]|$)",
                "model": "Aquos 3"
            },
            {
                "regex": "NP601SH(?:[);/ ]|$)",
                "model": "Aquos 2"
            },
            {
                "regex": "(?:SHV40|SHV3[24])(?:_u)?(?:[);/ ]|$)",
                "model": "Aquos S"
            },
            {
                "regex": "702SH(?:[);/ ]|$)",
                "model": "Aquos S Basic"
            },
            {
                "regex": "SHV3[183](?:[);/ ]|$)",
                "model": "Aquos S Mini"
            },
            {
                "regex": "(?:FS8010|SH-M08|SHV43(?:-u)?|SH-01[KL])(?:[);/ ]|$)",
                "model": "Aquos S2"
            },
            {
                "regex": "FS8016(?:[);/ ]|$)",
                "model": "Aquos S2 Plus"
            },
            {
                "regex": "(?:SHV46|SH-R?M11|901SH)(?:[);/ ]|$)",
                "model": "Aquos S3 Plus"
            },
            {
                "regex": "(?:SHV44|808SH|SH-04L|SH-03G)(?:[);/ ]|$)",
                "model": "Aquos R3"
            },
            {
                "regex": "(?:FS8032|FS8015|SH-02M|SHV45(?:-U)?)(?:[);/ ]|$)",
                "model": "Aquos S3"
            },
            {
                "regex": "FS8018(?:[);/ ]|$)",
                "model": "Aquos S3 Mini"
            },
            {
                "regex": "(?:SHV48|907SH)(?:[);/ ]|$)",
                "model": "Aquos S3 Basic"
            },
            {
                "regex": "SH-RM12(?:[);/ ]|$)",
                "model": "Aquos S3 Lite"
            },
            {
                "regex": "SH-RM15(?:[);/ ]|$)",
                "model": "Aquos S4 Lite"
            },
            {
                "regex": "SH-51A(?:[);/ ]|$)",
                "model": "Aquos R5G"
            },
            {
                "regex": "SHV3[57](?:_u)?(?:[);/ ]|$)",
                "model": "Aquos U"
            },
            {
                "regex": "TG-L900S(?:[);/ ]|$)",
                "model": "Luna S"
            },
            {
                "regex": "SH-A01(?:[);/ ]|$)",
                "model": "B10"
            },
            {
                "regex": "(?:FS8002|SHV47)(?:[);/ ]|$)",
                "model": "Z2"
            },
            {
                "regex": "FS8009(?:[);/ ]|$)",
                "model": "Z3"
            },
            {
                "regex": "FS8028(?:[);/ ]|$)",
                "model": "R1S"
            },
            {
                "regex": "SHV36(?:[);/ ]|$)",
                "model": "Basio 2"
            },
            {
                "regex": "SW001SH(?:[);/ ]|$)",
                "model": "Star Wars"
            },
            {
                "regex": "SH-RM02",
                "model": "Aquos SH-RM02"
            },
            {
                "regex": "401SH",
                "model": "Aquos 401SH"
            },
            {
                "regex": "DM-01J",
                "model": "Disney Mobile DM-01J"
            },
            {
                "regex": "A001SH",
                "model": "Simple Sumaho 5"
            },
            {
                "regex": "NP80[56]SH",
                "model": "Aquos Keitai 3"
            },
            {
                "regex": "NP807SH",
                "model": "Aquos Keitai 10"
            },
            {
                "regex": "NP501SH",
                "model": "Aquos Keitai"
            },
            {
                "regex": "SH-08E(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Sharp Aquos Pad"
            },
            {
                "regex": "d-41A(?:[);/ ]|$)",
                "device": "tablet",
                "model": "dtab d-41A"
            },
            {
                "regex": "(LC-(?:[0-9]{2})[a-z0-9]+)(?:[);/ ]|$)",
                "device": "tv",
                "model": "$1"
            },
            {
                "regex": "SHARP-AQUOS|AQUOSBrowser",
                "device": "tv",
                "model": "Aquos Net Plus"
            },
            {
                "regex": "SHARP[ \\-]([a-z0-9\\-]+)",
                "model": "$1"
            },
            {
                "regex": "(?:SHARP|SBM)([a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "(SH-?[0-9]+[a-z]?)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Simply": {
        "regex": "(NP602SI)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Softbank": {
        "regex": "Softbank|J-PHONE",
        "device": "smartphone",
        "models": [
            {
                "regex": "Softbank/[12]\\.0/([a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "([a-z0-9]+);Softbank;",
                "model": "$1"
            },
            {
                "regex": "J-PHONE/[0-9]\\.[0-9]/([a-z0-9\\-]+)",
                "model": "$1"
            }
        ]
    },
    "Sonim": {
        "regex": "Sonim[ -]|XP(?:[67]7|88)00(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Sonim-XP3400",
                "model": "XP3400",
                "device": "feature phone"
            },
            {
                "regex": "XP6700(?:[);/ ]|$)",
                "model": "XP6700"
            },
            {
                "regex": "XP7700(?:[);/ ]|$)",
                "model": "XP7700"
            },
            {
                "regex": "XP8800(?:[);/ ]|$)",
                "model": "XP8"
            }
        ]
    },
    "Star": {
        "regex": "N(9[5678]00|8800|9000|9977)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "N9000",
                "model": "Note 3"
            },
            {
                "regex": "N(9[5678]00|8800|9977)(?:[);/ ]|$)",
                "model": "N$1"
            }
        ]
    },
    "Starmobile": {
        "regex": "(?:Starmobile (?:Play (?:Neo|Spark|GO|Click))|UP[_ ](?:Sense|Rave|Groove|Prime|Selfie|Xtreme|XL))(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Starmobile PLAY GO(?:[);/ ]|$)",
                "model": "Play Go"
            },
            {
                "regex": "Starmobile Play (Neo|Spark|Click)(?:[);/ ]|$)",
                "model": "Play $1"
            },
            {
                "regex": "UP[_ ](Sense|Rave|Groove|Prime|Selfie|Xtreme|XL)(?:[);/ ]|$)",
                "model": "UP $1"
            }
        ]
    },
    "Amazon": {
        "regex": "KF(?:OT|ONWI|TT|JWI|JWA|[DFS]OWI|A[PRSU]WI|T[BH]WI|SAW[IA]|GIWI|KAWI|MAWI|MEWI|MUWI|SUWI)(?:[);/ ]|$)|Kindle|Silk/\\d+\\.\\d+|AlexaMediaPlayer|Amazon (?:Tate|Jem)|AFT[ABMNRST]|SD4930UR|AEO(BC|KN)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "AFTA",
                "model": "Fire TV Cube (Gen 1)",
                "device": "tv"
            },
            {
                "regex": "AFTR",
                "model": "Fire TV Cube (Gen 2)",
                "device": "tv"
            },
            {
                "regex": "AFTN",
                "model": "Fire TV (Gen 3)",
                "device": "tv"
            },
            {
                "regex": "AFTB",
                "model": "Fire TV",
                "device": "tv"
            },
            {
                "regex": "AFT[MST]",
                "model": "Fire TV stick",
                "device": "tv"
            },
            {
                "regex": "KFFOWI(?:[);/ ]|$)",
                "model": "Fire 7\""
            },
            {
                "regex": "KFMUWI(?:[);/ ]|$)",
                "model": "Fire 7\" (2019)"
            },
            {
                "regex": "KFTT(?:[);/ ]|$)",
                "model": "Kindle Fire HD"
            },
            {
                "regex": "KFJWI(?:[);/ ]|$)|Amazon Jem",
                "model": "Kindle Fire HD 8.9\" WiFi"
            },
            {
                "regex": "KFJWA(?:[);/ ]|$)",
                "model": "Kindle Fire HD 8.9\" 4G"
            },
            {
                "regex": "KFSOWI(?:[);/ ]|$)|Amazon Tate",
                "model": "Kindle Fire HD 7\" WiFi"
            },
            {
                "regex": "KFTHWI(?:[);/ ]|$)",
                "model": "Kindle Fire HDX 7\" WiFi"
            },
            {
                "regex": "KFTHWA(?:[);/ ]|$)",
                "model": "Kindle Fire HDX 7\" 4G"
            },
            {
                "regex": "KFAPWI(?:[);/ ]|$)",
                "model": "Kindle Fire HDX 8.9\" WiFi"
            },
            {
                "regex": "KFAPWA(?:[);/ ]|$)",
                "model": "Kindle Fire HDX 8.9\" 4G"
            },
            {
                "regex": "KFARWI(?:[);/ ]|$)",
                "model": "Fire HD 6"
            },
            {
                "regex": "KFASWI(?:[);/ ]|$)",
                "model": "Fire HD 7"
            },
            {
                "regex": "KFAUWI(?:[);/ ]|$)",
                "model": "Fire HD 7 (2017)"
            },
            {
                "regex": "KFMEWI(?:[);/ ]|$)",
                "model": "Fire HD 8 (2015)"
            },
            {
                "regex": "KFGIWI(?:[);/ ]|$)",
                "model": "Fire HD 8 (2016)"
            },
            {
                "regex": "KFDOWI(?:[);/ ]|$)",
                "model": "Fire HD 8 (2017)"
            },
            {
                "regex": "KFKAWI(?:[);/ ]|$)",
                "model": "Fire HD 8 (2018)"
            },
            {
                "regex": "KFONWI(?:[);/ ]|$)",
                "model": "Fire HD 8 (2020)"
            },
            {
                "regex": "KFSAWI(?:[);/ ]|$)",
                "model": "Fire HDX 8.9"
            },
            {
                "regex": "KFSAWA(?:[);/ ]|$)",
                "model": "Fire HDX 8.9 4G"
            },
            {
                "regex": "KFTBWI(?:[);/ ]|$)",
                "model": "Fire HD 10"
            },
            {
                "regex": "KFSUWI(?:[);/ ]|$)",
                "model": "Fire HD 10 (2017)"
            },
            {
                "regex": "KFMAWI(?:[);/ ]|$)",
                "model": "Fire HD 10 (2019)"
            },
            {
                "regex": "SD4930UR",
                "model": "Fire Phone",
                "device": "smartphone"
            },
            {
                "regex": "KFOT|Kindle Fire|Silk/\\d+\\.\\d+",
                "model": "Kindle Fire"
            },
            {
                "regex": "Kindle",
                "model": "Kindle"
            },
            {
                "regex": "AEO(BC|KN)(?:[);/ ]|$)",
                "model": "Echo",
                "device": "smart speaker"
            },
            {
                "regex": "AlexaMediaPlayer",
                "model": "Alexa",
                "device": "smart speaker"
            }
        ]
    },
    "Symphony": {
        "regex": "SYMPHONY[ \\_]([a-z0-9]+)|(?:roar[ _](?:V20|V78|E80|V150))|roarV95|ZVIII(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "ZVIII",
                "model": "ZVIII"
            },
            {
                "regex": "roar[ _]?(V20|E80|V78|V95|V150)(?:[);/ ]|$)",
                "model": "Roar $1"
            },
            {
                "regex": "SYMPHONY[ \\_]([a-z0-9]+)",
                "model": "$1"
            }
        ]
    },
    "Helio": {
        "regex": "Helio (S60|S5)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Syrox": {
        "regex": "(SYX-(?:T70[04]|T10[12]))(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "Qtek": {
        "regex": "Qtek[ _]?([a-z0-9]+)",
        "device": "smartphone",
        "model": "$1"
    },
    "Q-Touch": {
        "regex": "( Q09|QS701|Q05A)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "G-Touch": {
        "regex": "GTouch[_ ]|(?:Stella (?:XS?|Omega))(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Stella (XS?|Omega)",
                "model": "Stella $1"
            },
            {
                "regex": "GTouch[_ ]STELLA",
                "model": "Stella"
            }
        ]
    },
    "T-Mobile": {
        "regex": "T-Mobile[ _][a-z0-9 ]+|(?:REVVLRY|REVVL 2(?: PLUS)?|5062[ZW]|5007[ZW])(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "5062[ZW]",
                "model": "REVVL 4 Plus"
            },
            {
                "regex": "5007[ZW]",
                "model": "REVVL 4"
            },
            {
                "regex": "REVVL 2 PLUS",
                "model": "REVVL 2 Plus"
            },
            {
                "regex": "REVVLRY",
                "model": "REVVL Plus"
            },
            {
                "regex": "REVVL 2(?:[);/ ]|$)",
                "model": "REVVL 2"
            },
            {
                "regex": "T-Mobile[ _]([a-z0-9_ ]+) Build",
                "model": "$1"
            },
            {
                "regex": "T-Mobile[ _]([a-z0-9_ ]+)",
                "model": "$1"
            }
        ]
    },
    "TB Touch": {
        "regex": "Ignis [89]",
        "device": "tablet",
        "models": [
            {
                "regex": "Ignis ([89])",
                "model": "Ignis $1"
            }
        ]
    },
    "Teclast": {
        "regex": "Teclast|TLA002|TLA016|X98 Air III|M20_4G|X98 Air II\\(HG5N\\)|Tbook|X80 Power\\(B2N4\\)|(?:T30|P80X|P20HD|P10_HD|M40)_(?:ROW|EEA)|T10\\(E3C6\\)|P10S\\(N4H5\\)|98\\(M1E[45789]\\)|98\\(M3E3\\)",
        "device": "tablet",
        "models": [
            {
                "regex": "Tbook[_ -]([^;/]+) Build",
                "model": "Tbook $1"
            },
            {
                "regex": "T10\\(E3C6\\)",
                "model": "T10"
            },
            {
                "regex": "T30_(ROW|EEA)",
                "model": "T30 $1"
            },
            {
                "regex": "P80X_(ROW|EEA)",
                "model": "P80X $1"
            },
            {
                "regex": "P10S\\(N4H5\\)",
                "model": "P10S"
            },
            {
                "regex": "P20HD_(?:ROW|EEA)",
                "model": "P20HD"
            },
            {
                "regex": "M40_(?:ROW|EEA)",
                "model": "M40"
            },
            {
                "regex": "P10_HD_(?:ROW|EEA)",
                "model": "P10HD"
            },
            {
                "regex": "M20_4G",
                "model": "M20 4G"
            },
            {
                "regex": "TLA002",
                "model": "M30 4G"
            },
            {
                "regex": "TLA016",
                "model": "M16"
            },
            {
                "regex": "98\\(M1E[45789]\\)|98\\(M3E3\\)",
                "model": "TPad 98 10.1\" 4G"
            },
            {
                "regex": "X80 Power\\(B2N4\\)(?:[);/ ]|$)",
                "model": "X98 Power"
            },
            {
                "regex": "X98 Air III(?:[);/ ]|$)",
                "model": "X98 Air III"
            },
            {
                "regex": "X98 Air II\\(HG5N\\)(?:[);/ ]|$)",
                "model": "X98 Air II"
            },
            {
                "regex": "Teclast[_ -]([^;/]+) Build",
                "model": "$1"
            }
        ]
    },
    "Tecno Mobile": {
        "regex": "Tecno|Phantom6|Phantom6-Plus|DP7C Pro-SGA1",
        "device": "smartphone",
        "models": [
            {
                "regex": "Phantom6-Plus",
                "model": "Phantom 6 Plus"
            },
            {
                "regex": "Phantom6",
                "model": "Phantom 6"
            },
            {
                "regex": "TECNO-W3",
                "model": "W3"
            },
            {
                "regex": "TECNO[ _]F4 Pro(?:[);/ ]|$)",
                "model": "F4 Pro"
            },
            {
                "regex": "TECNO[ _]KE5j(?:[);/ ]|$)",
                "model": "Spark 6 Go"
            },
            {
                "regex": "TECNO[ _]ID6(?:[);/ ]|$)",
                "model": "Camon iClick 2"
            },
            {
                "regex": "Tecno[ _]7C(?:[);/ ]|$)",
                "model": "DroidPad 7C",
                "device": "tablet"
            },
            {
                "regex": "DP7C Pro-SGA1(?:[);/ ]|$)",
                "model": "DroidPad 7C Pro",
                "device": "tablet"
            },
            {
                "regex": "TECNO[ _]DP10A Pro(?:[);/ ]|$)",
                "model": "DroidPad 10A Pro",
                "device": "tablet"
            },
            {
                "regex": "TECNO[ _]P904(?:[);/ ]|$)",
                "model": "DroidPad 10D 4G",
                "device": "tablet"
            },
            {
                "regex": "Tecno[ _]CB7[j]?",
                "model": "Camon i4"
            },
            {
                "regex": "Tecno[ _]CF7(?:[);/ ]|$)",
                "model": "Camon 11"
            },
            {
                "regex": "Tecno[ _]CC6(?:[);/ ]|$)",
                "model": "Camon 12 Air"
            },
            {
                "regex": "Tecno[ _]CC7(?:[);/ ]|$)",
                "model": "Camon 12"
            },
            {
                "regex": "Tecno[ _]KC8(?:[);/ ]|$)",
                "model": "Spark 4"
            },
            {
                "regex": "Tecno[ _]BB4k(?:[);/ ]|$)",
                "model": "Spark 4 Lite"
            },
            {
                "regex": "Tecno[ _]KD7(?:[);/ ]|$)",
                "model": "Spark 5 Pro"
            },
            {
                "regex": "Tecno[ _]DP8D(?:[);/ ]|$)",
                "model": "DroidPad 8D",
                "device": "tablet"
            },
            {
                "regex": "Tecno[ _]LB8",
                "model": "Pouvoir 3 Plus"
            },
            {
                "regex": "TECNO S9",
                "device": "phablet",
                "model": "S9"
            },
            {
                "regex": "Tecno ([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "Tecno[ _]?([a-z0-9_\\-]+)",
                "model": "$1"
            }
        ]
    },
    "TechPad": {
        "regex": "Tech ?pad|XTAB[ _-]|Dual C1081HD|S813G",
        "device": "tablet",
        "models": [
            {
                "regex": "S813G",
                "model": "S813G"
            },
            {
                "regex": "TechPad10QC",
                "model": "10QC"
            },
            {
                "regex": "TechPad_10xx",
                "model": "10XX"
            },
            {
                "regex": "TechPad703",
                "model": "703"
            },
            {
                "regex": "TechPad[_ ](10Y|9x|i700)",
                "model": "$1"
            },
            {
                "regex": "TechPad[_ ]3Gx",
                "model": "3Gx"
            },
            {
                "regex": "Dual C1081HD",
                "model": "XTAB C1081HD"
            },
            {
                "regex": "XTAB[ _-]([^/;]+) Build",
                "model": "XTAB $1"
            },
            {
                "regex": "Tech ?pad[ _-]([^/;)]+)(?: Build|[;)])",
                "model": "$1",
                "device": "smartphone"
            }
        ]
    },
    "Tesco": {
        "regex": "Hudl (?:[^/;]+)(?: Build|[;)])|W032i-C3(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "Hudl ([^/;]+)(?: Build|[;)])",
                "model": "Hudl $1"
            },
            {
                "regex": "W032i-C3(?:[);/ ]|$)",
                "model": "Op3n Dott"
            }
        ]
    },
    "teXet": {
        "regex": "Texet|(NaviPad [^/;]*) Build|TM-(?:1046|1058|1057|1067|3000|3200R|3500|4003|4071|450[34]|451[30]|4377|4082R|508[34]|550[358]|5010|5017|507[4-7]|5[23]77|6003|6906|702[13]|7055HD|709[69]|9747BT|9758|9751HD|9767|5513|520[410]|507[13]|558[134]|570[23]|7859|8044|9748[ _]3G|9720|7047HD[ _]3G|9757|9740|4982|4515|4083|500[3567]|5571|3204R|5016|8043|7047HD[ _]3G|8041HD|8066|705[23]|7041|8041HD|8051|8048|974[96]|973[78]W|974[170]|9743W|9750HD|7043XD|7049|7887|7037W|702[46])|TB-(771A|711A)|X-Force[\\(-]?TM-5009\\)?|X-Plus[\\(-]?TM-5577\\)?|TM_5011|X-pad (?:AIR 8|iX 7|NAVI 7.[35]|NAVI 10|SKY 8.1|STYLE 8|STYLE 10.1|HIT|LITE 7.[12]|QUAD (?:7.2|10|7)|SHINE 8.1)(?: 3G)?",
        "device": "tablet",
        "models": [
            {
                "regex": "TM-9749",
                "model": "X-pad Plus 7.1 3G"
            },
            {
                "regex": "TM-9746",
                "model": "X-pad Plus 7 3G"
            },
            {
                "regex": "TM-8051",
                "model": "X-pad Force 8i 3G"
            },
            {
                "regex": "TM-1058",
                "model": "X-Force 10 3G"
            },
            {
                "regex": "TM-8048",
                "model": "X-pad Force 8 3G"
            },
            {
                "regex": "(NaviPad [^/;]*) Build",
                "model": "$1"
            },
            {
                "regex": "TM-7055HD",
                "model": "NaviPad 3G"
            },
            {
                "regex": "TM-9758",
                "model": "X-pad Style 10.0\""
            },
            {
                "regex": "X-pad SHINE 8.1 3G",
                "model": "X-pad Shine 8.1\" 3G"
            },
            {
                "regex": "X-pad QUAD 10 3G",
                "model": "X-pad Quad 10.0\" 3G"
            },
            {
                "regex": "X-pad QUAD 7 3G",
                "model": "X-pad Quad 7.0\" 3G"
            },
            {
                "regex": "X-pad QUAD 7.2 3G",
                "model": "X-pad Quad 7.2\" 3G"
            },
            {
                "regex": "X-pad STYLE 8 3G",
                "model": "X-pad Style 8.0\" 3G"
            },
            {
                "regex": "X-pad HIT 3G",
                "model": "X-pad Hit 3G"
            },
            {
                "regex": "X-pad LITE (7.[12])",
                "model": "X-pad Lite $1\""
            },
            {
                "regex": "TM-9767",
                "model": "X-pad Style 10.0\" 3G"
            },
            {
                "regex": "X-pad STYLE 10.1 3G",
                "model": "X-pad Style 10.1\" 3G"
            },
            {
                "regex": "(X-pad (?:AIR 8|iX 7) 3G)",
                "model": "$1"
            },
            {
                "regex": "TM-7096|X-pad NAVI 7.3 3G",
                "model": "X-pad NAVI 7.3\" 3G"
            },
            {
                "regex": "TM-7099",
                "model": "X-pad NAVI 7.4\" 3G"
            },
            {
                "regex": "X-pad NAVI 7.5 3G",
                "model": "X-pad NAVI 7.5\" 3G"
            },
            {
                "regex": "X-pad SKY 8.1 3G",
                "model": "X-pad Sky 8.1\" 3G"
            },
            {
                "regex": "TM-1046|X-pad NAVI 10 3G",
                "model": "X-pad NAVI 10.0\" 3G"
            },
            {
                "regex": "TM-7859",
                "model": "X-pad NAVI 8.2\" 3G"
            },
            {
                "regex": "TM-7049",
                "model": "NaviPad TM-7049 3G"
            },
            {
                "regex": "TM-7887",
                "model": "NaviPad TM-7887 3G"
            },
            {
                "regex": "TM-8066",
                "model": "X-pad Rapid 8.2\" 4G"
            },
            {
                "regex": "(TM-(?:1057|1067|6906|8043|9748[_ ]3G|9740|9757(?:[_ ]3G)?|7047HD[ _]3G|9747BT|9751HD|702[13]|705[23]|7041|9720|7047HD[_ ]3G|8044|8041HD|973[78]W|9743W|974[710]|9750HD|7043XD|7037W|702[46])|TB-(?:771A|711A|8041HD))",
                "model": "$1"
            },
            {
                "regex": "TM-4503",
                "device": "smartphone",
                "model": "X-Quad"
            },
            {
                "regex": "(TM-(?:3000|3200R|4003|4083|4377|4504|451[30]|500[35]|5[23]77|5571|3204R|520[04]|5581|5505|5017|507[13-7]|5081|6003|5513))",
                "device": "smartphone",
                "model": "$1"
            },
            {
                "regex": "TM-5006",
                "device": "smartphone",
                "model": "X-Line"
            },
            {
                "regex": "TM-5007",
                "device": "smartphone",
                "model": "X-Shine"
            },
            {
                "regex": "TM_5011",
                "device": "smartphone",
                "model": "X-Omega"
            },
            {
                "regex": "TM-508(3|4)",
                "device": "smartphone",
                "model": "Pay $1G"
            },
            {
                "regex": "TM-558(3|4)",
                "device": "smartphone",
                "model": "Pay 5.5\" $1G"
            },
            {
                "regex": "TM-5702",
                "device": "smartphone",
                "model": "Pay 5.7\" 3G"
            },
            {
                "regex": "TM-5703",
                "device": "smartphone",
                "model": "Pay 5.7\" Pro"
            },
            {
                "regex": "TM-5201",
                "device": "smartphone",
                "model": "Rock"
            },
            {
                "regex": "TM-4515",
                "device": "smartphone",
                "model": "X-Style"
            },
            {
                "regex": "TM-(5016|3500)",
                "device": "smartphone",
                "model": "X-Maxi 2"
            },
            {
                "regex": "TM-4071",
                "device": "smartphone",
                "model": "X-Smart"
            },
            {
                "regex": "TM-4982",
                "device": "smartphone",
                "model": "iX-Maxi"
            },
            {
                "regex": "TEXET[_ ]IX-MINI",
                "device": "smartphone",
                "model": "iX-Mini"
            },
            {
                "regex": "TM-5010",
                "device": "smartphone",
                "model": "X-Selfie"
            },
            {
                "regex": "TM-4082R",
                "device": "smartphone",
                "model": "X-Driver Quad"
            },
            {
                "regex": "TM-5503",
                "device": "smartphone",
                "model": "X-Mage"
            },
            {
                "regex": "TM-5508",
                "device": "smartphone",
                "model": "X-Cosmo"
            },
            {
                "regex": "X-Force[\\(-]?TM-5009\\)?",
                "device": "smartphone",
                "model": "X-Force"
            },
            {
                "regex": "X-Plus[\\(-]?TM-5577\\)?",
                "device": "smartphone",
                "model": "X-Plus"
            },
            {
                "regex": "teXet[_ ]X-medium[_ ]plus",
                "device": "smartphone",
                "model": "X-Medium Plus"
            }
        ]
    },
    "Telefunken": {
        "regex": "TELEFUNKEN|Foxtrot|TEL-1013GIQA|TF-SP(?:500[12]|4002)|(TF-MID(?:[78]02G|70[78]G|80[46]G|9705RG|9707G|7805G|100[57]G|1010G))|(TF-LED(?:65S75T2SU|32S39T2S|32S5[289]T2S|32S70T2S|40S63T2S))",
        "device": "tablet",
        "models": [
            {
                "regex": "TEL-1013GIQA",
                "model": "Giqa 10.1 3G"
            },
            {
                "regex": "Foxtrot2",
                "model": "Foxtrot 2",
                "device": "smartphone"
            },
            {
                "regex": "(?:TELEFUNKEN)?(TF-SP(?:500[12]|4002)|Outdoor LTE|Foxtrot)",
                "model": "$1",
                "device": "smartphone"
            },
            {
                "regex": "(TF-MID(?:[78]02G|70[78]G|80[46]G|9705RG|9707G|7805G|100[57]G|1010G))",
                "model": "$1"
            },
            {
                "regex": "(TF-LED(?:65S75T2SU|32S39T2S|32S5[289]T2S|32S70T2S|40S63T2S))",
                "model": "$1",
                "device": "tv"
            }
        ]
    },
    "Telego": {
        "regex": "TELEGO",
        "device": "smartphone",
        "models": [
            {
                "regex": "TELEGO-W503",
                "model": "W503"
            },
            {
                "regex": "Telego_JOY2",
                "model": "Joy 2"
            },
            {
                "regex": "Telego[ _]Mate",
                "model": "Mate"
            }
        ]
    },
    "Telenor": {
        "regex": "(?<!FBCR/)Telenor|Infinity E5",
        "device": "smartphone",
        "models": [
            {
                "regex": "Telenor[ _]([^;/]+)(?: Build|[;)])",
                "model": "$1"
            },
            {
                "regex": "Infinity E5",
                "model": "Infinity E5"
            }
        ]
    },
    "Telit": {
        "regex": "Telit",
        "device": "feature phone",
        "models": [
            {
                "regex": "Telit_Mobile_Terminals-([a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "Telit[\\-_]?([a-z0-9]+)",
                "model": "$1"
            }
        ]
    },
    "ThL": {
        "regex": "ThL[ _-]|Knight 2",
        "device": "smartphone",
        "models": [
            {
                "regex": "Knight 2",
                "model": "Knight 2"
            },
            {
                "regex": "ThL[ _-]*([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "ThL[ _-]*([^ ;/)]+)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "TIANYU": {
        "regex": "TIANYU",
        "device": "feature phone",
        "models": [
            {
                "regex": "TIANYU ([a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "TIANYU-KTOUCH/([a-z0-9]+)",
                "model": "$1"
            }
        ]
    },
    "Timovi": {
        "regex": "Tmovi[ _]|(?:Infinit_Lite_2|Vision_PRO|VISION ULTRA|Epix)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Tmovi[ _]Prime",
                "model": "Prime"
            },
            {
                "regex": "TMOVI_YEAH_BEAT",
                "model": "Yeah Beat"
            },
            {
                "regex": "YeahLIVE",
                "model": "Yeah LIVE"
            },
            {
                "regex": "Infinit Lite",
                "model": "Infinit Lite"
            },
            {
                "regex": "Infinit_Lite_2",
                "model": "Infinit Lite 2"
            },
            {
                "regex": "Vision_PRO",
                "model": "Vision Pro"
            },
            {
                "regex": "VISION ULTRA",
                "model": "Vision Ultra"
            },
            {
                "regex": "Epix",
                "model": "Epix"
            }
        ]
    },
    "Tooky": {
        "regex": "TOOKY",
        "device": "smartphone",
        "models": [
            {
                "regex": "TOOKY (A19|W1|T8[368]|T1982)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "TOOKY A9PLUS(?:[);/ ]|$)",
                "model": "A9 Plus"
            }
        ]
    },
    "Tolino": {
        "regex": "Tolino Tab ([^/;]+) Build",
        "device": "tablet",
        "model": "Tolino Tab $1"
    },
    "Top House": {
        "regex": "Coto[_ ]|X1013",
        "device": "smartphone",
        "models": [
            {
                "regex": "Coto W418",
                "model": "Coto W418"
            },
            {
                "regex": "COTO_T117",
                "model": "Coto T117",
                "device": "tablet"
            },
            {
                "regex": "X1013",
                "model": "X1013",
                "device": "tablet"
            },
            {
                "regex": "COTO_T40017",
                "model": "T40017"
            }
        ]
    },
    "Toplux": {
        "regex": "Toplux ([a-z0-9]+)",
        "device": "feature phone",
        "model": "$1"
    },
    "Touchmate": {
        "regex": "TOUCHMATE|(TM-(?:MID1050WKD|MID1020A|MID794C|MID795|MID710|MID798|MID792|MID788D|SM500N|SM410|MID1010))",
        "device": "smartphone",
        "models": [
            {
                "regex": "(TM-(?:MID1020A|MID794C|MID795|MID710|MID798|MID792|MID788D|MID1010))",
                "device": "tablet",
                "model": "$1"
            },
            {
                "regex": "TM-MID1050WKD",
                "device": "tablet",
                "model": "Mega Matrix 10.1\" 3G"
            },
            {
                "regex": "(TM-(?:SM500N|SM410))",
                "model": "$1"
            },
            {
                "regex": "Touchmate ([^/;]+) Build",
                "model": "$1"
            }
        ]
    },
    "TrekStor": {
        "regex": "(?:TrekStor|Surftab) ([^/;]+)(?: Build|\\))|Surftab[^;\\)]*(?:[;\\)]|$)|ST10216-2A|ST13416-1|VT10416-[12]|TFMTKAW01232",
        "device": "tablet",
        "models": [
            {
                "regex": "ST10216-2A|VT10416-[12]",
                "model": "SurfTab 10.1"
            },
            {
                "regex": "ST13416-1",
                "model": "SurfTab Theatre 13.4\""
            },
            {
                "regex": "PrimeTab P10",
                "model": "PrimeTab P10"
            },
            {
                "regex": "TrekStor ([^/;]+) Build",
                "model": "$1"
            },
            {
                "regex": "Surftab ([^/;]+) Build",
                "model": "SurfTab $1"
            },
            {
                "regex": "Surftab[ _]([^;\\)]*)(?:[;\\)]|$)",
                "model": "SurfTab $1"
            },
            {
                "regex": "TFMTKAW01232",
                "model": "SurfTab L15"
            }
        ]
    },
    "Trevi": {
        "regex": "Trevi[ _]|TAB[ _]10[ _]3G[ _]V16|TAB[ _](7|8)[ _]3G[ _]V8|TAB9 3G|MINITAB 3GV|Phablet[ _](?:4|4\\.5|5|5\\,3|6)[ _]?[CSQ]|REVERSE[ _]5\\.5[ _]?Q",
        "device": "tablet",
        "models": [
            {
                "regex": "TAB[ _]?(7|8)[ _]3G[ _]V8",
                "model": "TAB $1 3G V8"
            },
            {
                "regex": "TAB[ _]?9[ _]3G[ _]V8",
                "model": "TAB 9 3G V8"
            },
            {
                "regex": "TAB[ _]?9[ _]3G",
                "model": "TAB 9 3G"
            },
            {
                "regex": "TAB[ _]10[ _]3G[ _]V16",
                "model": "TAB 10 3G V16"
            },
            {
                "regex": "MINITAB 3GV",
                "model": "MINITAB 3G V"
            },
            {
                "regex": "(?:Trevi_)?Phablet[ _]4[ _]?C",
                "model": "Phablet 4 C",
                "device": "smartphone"
            },
            {
                "regex": "(?:Trevi_)?Phablet[ _]4[ _]?S",
                "model": "Phablet 4 S",
                "device": "smartphone"
            },
            {
                "regex": "(?:Trevi_)?Phablet[ _]4.5[ _]?Q",
                "model": "Phablet 4.5Q",
                "device": "smartphone"
            },
            {
                "regex": "(?:Trevi_)?PHABLET[ _]5[ _]?S",
                "model": "Phablet 5 S",
                "device": "smartphone"
            },
            {
                "regex": "(?:Trevi_)?PHABLET[ _]5,3[ _]?Q",
                "model": "Phablet 5.3 Q",
                "device": "phablet"
            },
            {
                "regex": "(?:Trevi_)?REVERSE[ _]5.5[ _]?Q",
                "model": "Phablet 5.5 Q REVERSE",
                "device": "phablet"
            },
            {
                "regex": "(?:Trevi_)?PHABLET[ _]6[ _]?S",
                "model": "Phablet 6 S",
                "device": "phablet"
            },
            {
                "regex": "Trevi[_]([^;/]+) Build",
                "model": "$1"
            }
        ]
    },
    "TVC": {
        "regex": "(NuclearSX-SP5)",
        "device": "smartphone",
        "model": "Nuclear SX-SP5"
    },
    "U.S. Cellular": {
        "regex": "USCC-",
        "device": "smartphone",
        "models": [
            {
                "regex": "USCC-E6762",
                "model": "Kyocera DuraForce"
            },
            {
                "regex": "(USCC-[a-z0-9_\\-]+)",
                "model": "$1"
            }
        ]
    },
    "Uhappy": {
        "regex": "Uhappy|UP?580|UP350|UP[35679]20",
        "device": "smartphone",
        "models": [
            {
                "regex": "UP([35679]20)",
                "model": "UP$1"
            },
            {
                "regex": "UP350",
                "model": "UP350"
            },
            {
                "regex": "UP580",
                "model": "UP580"
            },
            {
                "regex": "U580",
                "model": "U580"
            },
            {
                "regex": "Uhappy[ _-]([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "Uhappy[ _-]?([^;/)]+)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Unimax": {
        "regex": "U((?:67[013]|680)C|(?:452|504)TL|683CL|693CL)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "U$1"
    },
    "Unowhy": {
        "regex": "QOOQ ",
        "device": "tablet",
        "model": "QOOQ"
    },
    "UTStarcom": {
        "regex": "utstar[ _-]?([a-z0-9]+)",
        "device": "feature phone",
        "model": "$1"
    },
    "Ulefone": {
        "regex": "Ulefone|Gemini Pro|Power_[356]|S10_Pro|(?:Note [89]P|Armor(?:[ _](?:[2367]|2S|X[236]|6[SE]|3WT|3W|X))?|U00[78][ _]Pro|Be[ _]X|Be[ _]Touch(?:[ _][23])?|Be[ _](?:One|Pure)(?:[ _]Lite)?)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "S10_Pro",
                "model": "S10 Pro"
            },
            {
                "regex": "Be[ _]Touch([ _][23])?",
                "model": "Be Touch$1"
            },
            {
                "regex": "Be[ _]Pure[ _]Lite",
                "model": "Be Pure Lite"
            },
            {
                "regex": "Be[ _]Pure",
                "model": "Be Pure"
            },
            {
                "regex": "Be[ _]One[ _]Lite",
                "model": "Be One Lite"
            },
            {
                "regex": "Be[ _]One",
                "model": "Be One"
            },
            {
                "regex": "Be[ _]X",
                "model": "Be X"
            },
            {
                "regex": "U007 Pro",
                "model": "U007 Pro"
            },
            {
                "regex": "U008 Pro",
                "model": "U008 Pro"
            },
            {
                "regex": "Armor[ _]X([236])(?:[);/ ]|$)",
                "model": "Armor X$1"
            },
            {
                "regex": "Armor[ _]6S(?:[);/ ]|$)",
                "model": "Armor 6S"
            },
            {
                "regex": "Armor[ _]([679])E(?:[);/ ]|$)",
                "model": "Armor $1E"
            },
            {
                "regex": "Armor[ _]X([57]) Pro(?:[);/ ]|$)",
                "model": "Armor X$1 Pro"
            },
            {
                "regex": "Armor[ _]X([578])(?:[);/ ]|$)",
                "model": "Armor X$1"
            },
            {
                "regex": "Armor[ _]([25])S(?:[);/ ]|$)",
                "model": "Armor $1S"
            },
            {
                "regex": "Armor[ _]X(?:[);/ ]|$)",
                "model": "Armor X"
            },
            {
                "regex": "Armor[ _]3W(?:[);/ ]|$)",
                "model": "Armor 3W"
            },
            {
                "regex": "Armor[ _]3WT(?:[);/ ]|$)",
                "model": "Armor 3WT"
            },
            {
                "regex": "Armor[ _]([236789])(?:[);/ ]|$)",
                "model": "Armor $1"
            },
            {
                "regex": "Armor(?:[);/ ]|$)",
                "model": "Armor"
            },
            {
                "regex": "Gemini Pro(?:[);/ ]|$)",
                "model": "Gemini Pro"
            },
            {
                "regex": "Power[_ ]([356])",
                "model": "Power $1"
            },
            {
                "regex": "Note 8P(?:[);/ ]|$)",
                "model": "Note 8P"
            },
            {
                "regex": "Note 9P(?:[);/ ]|$)",
                "device": "phablet",
                "model": "Note 9P"
            },
            {
                "regex": "Ulefone[_ ](X|S1[ _]Pro|S[17])(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "Ulefone[ _-]?([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "Ulefone[ _-]?([^;/)]+)(?:[;/)]|$)",
                "model": "$1"
            }
        ]
    },
    "UMIDIGI": {
        "regex": "UMI(?:DIGI)?[ _]|(?:A5_Pro|ROME[_ ]X|BISON|hammer_S)(?:[;/) ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "A5_Pro",
                "model": "A5 Pro"
            },
            {
                "regex": "UMI TOUCH X",
                "model": "Touch X"
            },
            {
                "regex": "UMI TOUCH",
                "model": "Touch"
            },
            {
                "regex": "ROME[_ ]X",
                "model": "Rome X"
            },
            {
                "regex": "BISON",
                "model": "Bison"
            },
            {
                "regex": "hammer_S",
                "model": "Hammer S"
            },
            {
                "regex": "UMI_(London|Diamond(?:_X)?)",
                "model": "$1"
            },
            {
                "regex": "UMI(?:DIGI)?[ _]([^/;)]+)(?: Build|[;)])",
                "model": "$1"
            }
        ]
    },
    "Uniscope": {
        "regex": "Uniscope",
        "device": "smartphone",
        "models": [
            {
                "regex": "Uniscope[ _\\-]?([^/;]+) Build",
                "model": "$1"
            },
            {
                "regex": "Uniscope[ _\\-]?([^);/ ]+)",
                "model": "$1"
            }
        ]
    },
    "Unnecto": {
        "regex": "Unnecto|(?:U513|U5151|U61[1356]|U7[12]0|U-830|U90[35])(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "U513",
                "model": "Drone XT"
            },
            {
                "regex": "U5151",
                "model": "Bolt"
            },
            {
                "regex": "U611",
                "model": "Quattro X"
            },
            {
                "regex": "U613",
                "model": "Quattro S"
            },
            {
                "regex": "U615",
                "model": "Quattro M"
            },
            {
                "regex": "U616",
                "model": "U616"
            },
            {
                "regex": "U710",
                "model": "Quattro U710"
            },
            {
                "regex": "U720",
                "model": "Quattro Z"
            },
            {
                "regex": "U-830",
                "model": "Rush"
            },
            {
                "regex": "U903",
                "model": "Air"
            },
            {
                "regex": "U905",
                "model": "Air 5.5"
            }
        ]
    },
    "Unonu": {
        "regex": "Unonu[ _-]",
        "device": "smartphone",
        "models": [
            {
                "regex": "Unonu[ _-]([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "Unonu[ _-]?([^;/)]+)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "UTOK": {
        "regex": "UTOK (45[01]D)",
        "device": "smartphone",
        "model": "$1"
    },
    "Vastking": {
        "regex": "(M910A|M783K|M783K-16G|M1072R-3G)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "ViewSonic": {
        "regex": "ViewSonic|VSD[0-9]+(?:[);/ ]|$)|ViewPad|ViewPhone",
        "device": "smart display",
        "models": [
            {
                "regex": "(?:ViewSonic-)?V500(?:[);/ ]|$)",
                "model": "V500",
                "device": "smartphone"
            },
            {
                "regex": "ViewSonic A8\\+(?:[);/ ]|$)",
                "model": "A8 Plus",
                "device": "smartphone"
            },
            {
                "regex": "(?:ViewSonic-)?ViewPhone ?([^;/]+) Build",
                "model": "ViewPhone $1",
                "device": "smartphone"
            },
            {
                "regex": "(?:ViewSonic-)?ViewPad ?([^;/]+) Build",
                "model": "ViewPad $1",
                "device": "tablet"
            },
            {
                "regex": "(VSD[0-9]+)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Vitelcom": {
        "regex": "Vitelcom|portalmmm/[12].0 TSM",
        "device": "feature phone",
        "models": [
            {
                "regex": "TSM-([a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "TSM([a-z0-9\\-]+)",
                "model": "$1"
            },
            {
                "regex": "portalmmm/[12].0 (TSM[a-z0-9 ]+)",
                "model": "$1"
            }
        ]
    },
    "Fengxiang": {
        "regex": "(vKB011B|vKB004L)",
        "device": "tablet",
        "model": "$1"
    },
    "VK Mobile": {
        "regex": "VK-VK([79]00)(?:[);/ ]|$)",
        "device": "feature phone",
        "model": "VK$1"
    },
    "Vernee": {
        "regex": "Vernee|Mars Pro|Apollo[ _](?:Lite|X)|Thor (?:E|Plus)|X2_euro",
        "device": "smartphone",
        "models": [
            {
                "regex": "Apollo[ _]Lite",
                "model": "Apollo Lite"
            },
            {
                "regex": "Apollo[ _]X",
                "model": "Apollo X"
            },
            {
                "regex": "Mars Pro",
                "model": "Mars Pro"
            },
            {
                "regex": "Thor E",
                "model": "Thor E"
            },
            {
                "regex": "Thor Plus",
                "model": "Thor Plus"
            },
            {
                "regex": "Vernee_M5",
                "model": "M5"
            },
            {
                "regex": "X2_euro",
                "model": "X2"
            },
            {
                "regex": "Vernee[ _-]([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "Vernee[ _-]([a-z0-9_-]+)",
                "model": "$1"
            }
        ]
    },
    "Vertu": {
        "regex": "Vertu[ ]?([a-z0-9]+)|(?:ASTER P|CONSTELLATION X)(?:[);/ ]|$)",
        "device": "feature phone",
        "models": [
            {
                "regex": "ASTER P(?:[);/ ]|$)",
                "device": "smartphone",
                "model": "Aster P"
            },
            {
                "regex": "CONSTELLATION X(?:[);/ ]|$)",
                "device": "smartphone",
                "model": "Constellation X"
            },
            {
                "regex": "Vertu[ ]?([a-z0-9]+)",
                "model": "$1"
            }
        ]
    },
    "Venso": {
        "regex": "(?:CX-508|CX-551|Reiv 500|Creon F5|Isprit U50|Isprit-U50-LTE)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Isprit U50|Isprit-U50-LTE",
                "model": "Isprit U50"
            },
            {
                "regex": "(CX-508|CX-551|Reiv 500|Creon F5)",
                "model": "$1"
            }
        ]
    },
    "Verizon": {
        "regex": "QTA(SUN[12]|XIA1|QZ3|QZ3KID|IR7)|QMV7B",
        "device": "tablet",
        "models": [
            {
                "regex": "QTAXIA1|QTAIR7",
                "model": "Ellipsis 10"
            },
            {
                "regex": "QTASUN1",
                "model": "Ellipsis 8 HD"
            },
            {
                "regex": "QTASUN2",
                "model": "Ellipsis 8 HD 4G LTE"
            },
            {
                "regex": "QTAQZ3KID",
                "model": "Ellipsis Kids LTE"
            },
            {
                "regex": "QTAQZ3",
                "model": "Ellipsis 8"
            },
            {
                "regex": "QMV7B",
                "model": "Ellipsis 7"
            }
        ]
    },
    "Verykool": {
        "regex": "verykool",
        "device": "smartphone",
        "models": [
            {
                "regex": "verykoolS5004",
                "model": "Lotus JR."
            },
            {
                "regex": "verykoolS5005",
                "model": "Lotus II"
            },
            {
                "regex": "verykool[ _]?([^/;]+) Build",
                "model": "$1"
            },
            {
                "regex": "verykool[ _]?([^/;]+);",
                "model": "$1"
            }
        ]
    },
    "Vestel": {
        "regex": "Vestel[ _]|Venus[ _](GO|E[2-5]|V[1-9]|Z[1-9]0)|(?<!Winnovo )V_?TAB|VP74|VT97PRO|VSP145M|VSP250[GS]|VSP355[GS]|V3_5580_Dual",
        "device": "smartphone",
        "models": [
            {
                "regex": "VT97PRO",
                "model": "VTab 9.7 Pro",
                "device": "tablet"
            },
            {
                "regex": "VP74",
                "model": "VTab Lite II",
                "device": "tablet"
            },
            {
                "regex": "VTAB10",
                "model": "VTab 10",
                "device": "tablet"
            },
            {
                "regex": "V_TAB_7_ECO_III ",
                "model": "VTab 7 Eco 3",
                "device": "tablet"
            },
            {
                "regex": "V_TAB_([0-9]{4}[A-Z]?)",
                "model": "VTab $1",
                "device": "tablet"
            },
            {
                "regex": "V3_5580_Dual",
                "model": "Venus V3 5580 Dual"
            },
            {
                "regex": "VSP145M",
                "model": "Venus 4.5"
            },
            {
                "regex": "VSP250G",
                "model": "Venus 5.0V"
            },
            {
                "regex": "VSP250S",
                "model": "Venus 5.0X"
            },
            {
                "regex": "VSP355G",
                "model": "Venus 5.5V"
            },
            {
                "regex": "VSP355S",
                "model": "Venus 5.5X"
            },
            {
                "regex": "Venus[ _]V([1-9])",
                "model": "Venus V$1"
            },
            {
                "regex": "Venus[ _]Z([1-9]0)",
                "model": "Venus Z$1"
            },
            {
                "regex": "Venus[ _]GO",
                "model": "Venus Go"
            },
            {
                "regex": "Venus E2 Plus Dual",
                "model": "Venus E2 Plus Dual"
            },
            {
                "regex": "Venus[ _]E2 Plus",
                "model": "Venus E2 Plus"
            },
            {
                "regex": "Venus[ _]E([345])",
                "model": "Venus E$1"
            },
            {
                "regex": "Vestel_5000_Dual",
                "model": "5000 Dual"
            },
            {
                "regex": "Vestel_5000_2gb",
                "model": "5000 2GB"
            },
            {
                "regex": "Vestel[ _](5000|5530)",
                "model": "$1"
            }
        ]
    },
    "Videocon": {
        "regex": "Videocon[_ \\-]|(?:VT75C|V406018|V512930|V50(?:2430|3630|5024|5430|4630|5[98]20)|Z45_(?:Dazzle|Amaze)|Z45Q Star|Z50 Nova)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Videocon_V1585",
                "device": "feature phone",
                "model": "V1585"
            },
            {
                "regex": "Videocon[_ \\-]([a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "VT75C(?:[);/ ]|$)",
                "model": "VT75C",
                "device": "tablet"
            },
            {
                "regex": "V502430(?:[);/ ]|$)",
                "model": "Kryton 3"
            },
            {
                "regex": "V505024(?:[);/ ]|$)",
                "model": "Kryton 22 Plus"
            },
            {
                "regex": "V503630(?:[);/ ]|$)",
                "model": "Delite 11"
            },
            {
                "regex": "V505920(?:[);/ ]|$)",
                "model": "Metal Pro 2"
            },
            {
                "regex": "V505820(?:[);/ ]|$)",
                "model": "Metal Pro 1"
            },
            {
                "regex": "V406018(?:[);/ ]|$)",
                "model": "Starr 100"
            },
            {
                "regex": "V512930(?:[);/ ]|$)",
                "model": "Ultra 50"
            },
            {
                "regex": "(V505430|V504630)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "Z45_(Dazzle|Amaze)(?:[);/ ]|$)",
                "model": "Infinium Z45 $1"
            },
            {
                "regex": "Z45Q Star(?:[);/ ]|$)",
                "model": "Infinium Z45Q Star"
            },
            {
                "regex": "Z50 Nova(?:[);/ ]|$)",
                "model": "Infinium Z50 Nova"
            }
        ]
    },
    "Vodafone": {
        "regex": "(?<!FBCR/)Vodafone[ _-]|Smart ?Tab ?(?:III? ?)?(?:|4G|7|10)[);/ -]|VodafoneSmartChat|VFD[ _][0-9]+(?:[);/ ]|$)|VFD320|VF6[89]5|VF-(696|1397|795|895N|1497)",
        "device": "smartphone",
        "models": [
            {
                "regex": "VFD 100",
                "model": "Smart Mini"
            },
            {
                "regex": "VF685",
                "model": "Smart Kicka"
            },
            {
                "regex": "VF695",
                "model": "Smart First 6"
            },
            {
                "regex": "VFD 200",
                "model": "Smart First 7"
            },
            {
                "regex": "VFD 300",
                "model": "Smart Mini 7"
            },
            {
                "regex": "VFD 50[02]",
                "model": "Smart Turbo 7"
            },
            {
                "regex": "VFD 51[013]",
                "model": "Smart E8"
            },
            {
                "regex": "VFD 600",
                "model": "Smart Prime 7"
            },
            {
                "regex": "VFD320",
                "model": "Smart C9"
            },
            {
                "regex": "VFD 820",
                "model": "Smart X9"
            },
            {
                "regex": "VFD 610",
                "model": "Smart N8"
            },
            {
                "regex": "Smart ultra 6",
                "model": "Smart Ultra 6"
            },
            {
                "regex": "VFD 700",
                "model": "Smart Ultra 7"
            },
            {
                "regex": "VFD 720",
                "model": "Smart N9"
            },
            {
                "regex": "VFD 900",
                "model": "Smart Platinum 7"
            },
            {
                "regex": "Vodafone[ _]875(?:[);/ ]|$)",
                "model": "Smart Mini"
            },
            {
                "regex": "Vodafone[ _]975N?(?:[);/ ]|$)",
                "model": "Smart 3"
            },
            {
                "regex": "Vodafone[ _]785(?:[);/ ]|$)",
                "model": "Smart 4 Mini"
            },
            {
                "regex": "Vodafone[ _]890N(?:[);/ ]|$)",
                "model": "Smart 4 Turbo"
            },
            {
                "regex": "Vodafone[ _]985N(?:[);/ ]|$)",
                "model": "Smart 4 Power"
            },
            {
                "regex": "VodafoneSmartChat",
                "model": "Smart Chat"
            },
            {
                "regex": "VF-696",
                "model": "Smart Grand 6"
            },
            {
                "regex": "VF-795",
                "model": "Smart Speed 6"
            },
            {
                "regex": "VF-895N",
                "model": "Smart Prime 6"
            },
            {
                "regex": "VF-1397",
                "model": "Tab Speed 6",
                "device": "tablet"
            },
            {
                "regex": "VF-1497",
                "model": "Tab Prime 6",
                "device": "tablet"
            },
            {
                "regex": "VFD 1100",
                "device": "tablet",
                "model": "Tab Mini 7"
            },
            {
                "regex": "VFD 1300",
                "device": "tablet",
                "model": "Smart Tab N8"
            },
            {
                "regex": "VFD 1400",
                "device": "tablet",
                "model": "Tab Prime 7"
            },
            {
                "regex": "Smart ?Tab ?3G",
                "device": "tablet",
                "model": "Smart Tab 3G"
            },
            {
                "regex": "Smart ?Tab ?4G",
                "device": "tablet",
                "model": "Smart Tab 4G"
            },
            {
                "regex": "Smart ?Tab ?4",
                "model": "Smart Tab 4",
                "device": "tablet"
            },
            {
                "regex": "SmartTab7[);/ -]",
                "model": "Smart Tab 7",
                "device": "tablet"
            },
            {
                "regex": "SmartTab10[);/ -]",
                "model": "Smart Tab 10",
                "device": "tablet"
            },
            {
                "regex": "Smart ?Tab ?II ?7",
                "model": "Smart Tab II 7",
                "device": "tablet"
            },
            {
                "regex": "Smart ?Tab ?II ?10",
                "model": "Smart Tab II 10",
                "device": "tablet"
            },
            {
                "regex": "Smart ?Tab ?III ?7",
                "model": "Smart Tab III 7",
                "device": "tablet"
            },
            {
                "regex": "Smart ?Tab ?III ?10",
                "model": "Smart Tab III 10",
                "device": "tablet"
            },
            {
                "regex": "VFD[ _]([0-9]+)(?:[);/ ]|$)",
                "model": "VFD $1"
            },
            {
                "regex": "Vodafone[ _-]([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "Vodafone[ _-]([a-z0-9_-]+)",
                "model": "$1"
            }
        ]
    },
    "Vonino": {
        "regex": "Vonino|Epic (?:E8|P7)|Gyga[ _](?:X|S|QS|XS)|Jax[ _](?:QS|[SXQN]|Mini)|Magnet[ _]M[19]|Navo_QS|Onyx_(QS|Z)|Orin_QS|Pluri[ _](?:[BCMQ]7|[CQM]8)|Sirius_QS|Volt[ _][XS]|Xavy_(T7|L8)|Xylo[ _][XSPQT]|Zun[ _]X[OS]?|JAX_N",
        "device": "smartphone",
        "models": [
            {
                "regex": "Zun[ _]XO",
                "model": "Zun XO"
            },
            {
                "regex": "Zun[ _]XS",
                "model": "Zun XS"
            },
            {
                "regex": "Zun[ _]X",
                "model": "Zun X"
            },
            {
                "regex": "Xylo[ _]([PSXQT])",
                "model": "Xylo $1"
            },
            {
                "regex": "Volt[ _]S_A7",
                "model": "Volt S A7"
            },
            {
                "regex": "Volt[ _]S",
                "model": "Volt S"
            },
            {
                "regex": "Volt[ _]X",
                "model": "Volt X"
            },
            {
                "regex": "Gyga[ _]QS",
                "model": "Gyga QS"
            },
            {
                "regex": "Gyga[ _]XS",
                "model": "Gyga XS"
            },
            {
                "regex": "Gyga[ _](S|X)",
                "model": "Gyga $1"
            },
            {
                "regex": "Jax[ _]QS",
                "model": "Jax QS"
            },
            {
                "regex": "Jax[ _]S_A7",
                "model": "Jax S A7"
            },
            {
                "regex": "Jax[ _]([SQXN])",
                "model": "Jax $1"
            },
            {
                "regex": "Jax[ _]Mini",
                "model": "Jax Mini"
            },
            {
                "regex": "Epic (E8|P7)",
                "model": "Epic $1",
                "device": "tablet"
            },
            {
                "regex": "Pluri[ _](B|C|M|Q)7",
                "model": "Pluri $17",
                "device": "tablet"
            },
            {
                "regex": "Pluri[ _]([CQM])8",
                "model": "Pluri $18",
                "device": "tablet"
            },
            {
                "regex": "Magnet[ _]M([19])",
                "model": "Magnet M$1",
                "device": "tablet"
            },
            {
                "regex": "Navo_QS",
                "model": "Navo QS",
                "device": "tablet"
            },
            {
                "regex": "Onyx_(QS|Z)",
                "model": "Onyx $1",
                "device": "tablet"
            },
            {
                "regex": "Orin_QS",
                "model": "Orin QS",
                "device": "tablet"
            },
            {
                "regex": "Sirius_QS",
                "model": "Sirius QS",
                "device": "tablet"
            },
            {
                "regex": "Xavy_T7",
                "model": "Xavy T7",
                "device": "tablet"
            },
            {
                "regex": "Xavy_L8",
                "model": "Xavy L8",
                "device": "tablet"
            }
        ]
    },
    "Vorago": {
        "regex": "VORAGO",
        "device": "smartphone",
        "models": [
            {
                "regex": "CELL-500",
                "model": "CELL-500"
            }
        ]
    },
    "Voto": {
        "regex": "VOTO[ _\\-]|VT8[89]8(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "VOTO[ _\\-]?([^/;]+) Build",
                "model": "$1"
            },
            {
                "regex": "VOTO[ _\\-]?([^);/ ]+)",
                "model": "$1"
            },
            {
                "regex": "(VT8[89]8)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Voxtel": {
        "regex": "Voxtel_([a-z0-9]+)",
        "device": "feature phone",
        "model": "$1"
    },
    "Vulcan": {
        "regex": "(?:VP5004A|HT0704W08|VS5513|VS4011|VR6031)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(VP5004A|VR6031)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "VS5513(?:[);/ ]|$)",
                "model": "Tempo S13"
            },
            {
                "regex": "VS4011(?:[);/ ]|$)",
                "model": "Tempo S11"
            },
            {
                "regex": "HT0704W08",
                "model": "Koral 7W4",
                "device": "tablet"
            }
        ]
    },
    "Walton": {
        "regex": "Walton|Walpad|Primo[ _](C[1234]?|D[1-689]|E[1-589]|F8s|S7|EF[23]?|EM|F[1-79]i?|G[1-9]|GF[234]?|GH[23]?|GM|H[2-689M]|H7s|N[F1]?|NX2?|R[1-46]|R[HM]2?|RX[23]?|S[1-6]|V1|X[1-4]|Z|ZX)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Walpad ([^;/]+) Build",
                "device": "tablet",
                "model": "Primo Walpad $1"
            },
            {
                "regex": "Primo S6 infinity",
                "model": "Primo S6 Infinity"
            },
            {
                "regex": "Primo H8 Pro(?:[);/ ]|$)",
                "model": "Primo H8 Pro"
            },
            {
                "regex": "Primo H8(?:[);/ ]|$)",
                "model": "Primo H8 Turbo"
            },
            {
                "regex": "Primo[ _]([^;/)]+)(?: Build|[;)])",
                "model": "Primo $1"
            },
            {
                "regex": "Walton[ _]([^;/)]+)(?: Build|[;)])",
                "model": "$1"
            }
        ]
    },
    "WellcoM": {
        "regex": "WELLCOM[ _\\-/]([a-z0-9]+)",
        "device": "smartphone",
        "model": "$1"
    },
    "Wexler": {
        "regex": "Wexler|(?:ULTIMA 7 TWIST PLUS|TAB10iQ3G|TAB[ _]10Q)(?:[);/ ]|$)|ZEN[ _](?:4\\.[57]|5)",
        "device": "tablet",
        "models": [
            {
                "regex": "(?:Wexler[ _\\-\\.])?ZEN[ _]4\\.5(?:[);/ ]|$)",
                "model": "ZEN 4.5",
                "device": "smartphone"
            },
            {
                "regex": "(?:Wexler[ _\\-\\.])?ZEN[ _]4\\.7(?:[);/ ]|$)",
                "model": "ZEN 4.7",
                "device": "smartphone"
            },
            {
                "regex": "(?:Wexler[ _\\-\\.])?ZEN[ _]5(?:[);/ ]|$)",
                "model": "ZEN 5",
                "device": "smartphone"
            },
            {
                "regex": "(?:Wexler[ _\\-\\.])?TAB[ _]10Q(?:[);/ ]|$)",
                "model": "TAB 10Q"
            },
            {
                "regex": "TAB10iQ3G(?:[);/ ]|$)",
                "model": "TAB 10iQ 3G"
            },
            {
                "regex": "Wexler[ _\\-\\.]([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "Wexler[ _\\-\\.]([^);/]+)(?:[);/]|$)",
                "model": "$1"
            },
            {
                "regex": "(ULTIMA 7 TWIST PLUS)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Inco": {
        "regex": "(?:Inco[ _](Aurora II|Sphere|Plain[_ ]2S?|Plain2 S|Flex 2S|S4|Bloom 2)|(Horizon_S|Horizon \\+))(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(?:Inco[ _])?(Plain[_ ]2S?|Horizon_S|Horizon \\+|Aurora II|Flex 2S|S4|Bloom 2)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "Plain2 S",
                "model": "Plain 2S"
            },
            {
                "regex": "Inco Sphere(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Sphere"
            }
        ]
    },
    "Wink": {
        "regex": "(?<!mismart )Wink[_ ]",
        "device": "smartphone",
        "models": [
            {
                "regex": "Wink[_ ]((?:Glory|World|Share|City)(?:[_ ](?:SE|S))?)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "Wink[ _](Primo|Highway)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "$1"
            }
        ]
    },
    "Wiko": {
        "regex": "(?:WIKO[_ -])|(?:WIKO-)?CINK_[a-z0-9_]+|(?:WIKO-)?HIGHWAY_[a-z0-9_]+|(?:WIKO-)?(?:Cink([^/;]*)|Highway([^/;]*)|Iggy|Stairway|Rainbow ?(Jam|Lite|Up)?|Darkside|Darkmoon|Darkfull|Darknight|Freddy|FEVER|Jerry[2]?|Sublim|Ozzy|JIMMY|Barry|Birdy|Bloom|Getaway|Goa(?!nna)|Kite|Robby|Lenny[2-9]?|Slide|Sunset[2-9]?|Sunny[2-9]?|Tommy[2-9]?|PULP(?: Fab)?|Wax|HARRY|Ridge(?: Fab)?|U FEEL(?: Prime| Lite)?|U PULSE(?: LITE)?|View2 Go|View XL|View Prime)(?: Plus)?(?: 4G)?(?: Build|$)?|W_(?:C8[06]0|K[346]00|P130|P200|C200SN)|WC300|W-(?:V7[23]0|V8[30]0|P[36]11|K420|K510S?|K130)-(?:TMV|TIM|TVM|EEA|SUN|SWI|OPE|BYT|DU|TH)|WIM Lite|W-K360-TV|W-V600|W-K200|W-U300|U307AS|CINK PEAX 2|HIGHWAY PURE|C210AE|v3913",
        "device": "smartphone",
        "models": [
            {
                "regex": "(?:WIKO-)?Cink Peax 2",
                "model": "Cink Peax 2"
            },
            {
                "regex": "(?:Wiko-)?Cink([^/;]*) Build",
                "model": "Cink$1"
            },
            {
                "regex": "(?:WIKO-)?CINK_([a-z0-9_]+)",
                "model": "Cink $1"
            },
            {
                "regex": "(?:WIKO-)?HIGHWAY PURE",
                "model": "Highway Pure"
            },
            {
                "regex": "(?:WIKO-)?HIGHWAY STAR",
                "model": "Highway Star"
            },
            {
                "regex": "(?:Wiko-)?Highway([^/;]*) Build",
                "model": "Highway$1"
            },
            {
                "regex": "(?:WIKO-)?HIGHWAY_([a-z0-9_]+)",
                "model": "Highway $1"
            },
            {
                "regex": "(?:WIKO-)?Iggy",
                "model": "Iggy"
            },
            {
                "regex": "(?:WIKO-)?FEVER",
                "model": "Fever"
            },
            {
                "regex": "(?:WIKO-)?Stairway",
                "model": "Stairway"
            },
            {
                "regex": "(?:WIKO-)?Ridge Fab 4G",
                "model": "Ridge Fab 4G"
            },
            {
                "regex": "(?:WIKO-)?Ridge 4G",
                "model": "Ridge 4G"
            },
            {
                "regex": "(?:WIKO-)?Ridge",
                "model": "Ridge"
            },
            {
                "regex": "(?:WIKO-)?Rainbow ([^/;]+) Build",
                "model": "Rainbow $1"
            },
            {
                "regex": "(?:WIKO-)?Rainbow",
                "model": "Rainbow"
            },
            {
                "regex": "(?:WIKO-)?Darkside",
                "model": "Darkside"
            },
            {
                "regex": "(?:WIKO-)?Darkmoon",
                "model": "Darkmoon"
            },
            {
                "regex": "(?:WIKO-)?Darkfull",
                "model": "Darkfull"
            },
            {
                "regex": "(?:WIKO-)?Darknight",
                "model": "Darknight"
            },
            {
                "regex": "(?:WIKO-)?Sublim",
                "model": "Sublim"
            },
            {
                "regex": "(?:WIKO-)?Ozzy",
                "model": "Ozzy"
            },
            {
                "regex": "(?:WIKO-)?Barry",
                "model": "Barry"
            },
            {
                "regex": "(?:WIKO-)?Birdy",
                "model": "Birdy"
            },
            {
                "regex": "(?:WIKO-)?Bloom",
                "model": "Bloom"
            },
            {
                "regex": "(?:WIKO-)?JIMMY",
                "model": "Jimmy"
            },
            {
                "regex": "JERRY MAX",
                "model": "Jerry Max"
            },
            {
                "regex": "W_K300",
                "model": "Jerry 3"
            },
            {
                "regex": "W-K420-TVM",
                "model": "Jerry 4"
            },
            {
                "regex": "(?:WIKO-)?Jerry2",
                "model": "Jerry 2"
            },
            {
                "regex": "(?:WIKO-)?Jerry",
                "model": "Jerry"
            },
            {
                "regex": "(?:WIKO-)?Getaway",
                "model": "Getaway"
            },
            {
                "regex": "(?:WIKO-)?Goa(?!nna)",
                "model": "Goa"
            },
            {
                "regex": "(?:WIKO-)?Freddy",
                "model": "Freddy"
            },
            {
                "regex": "(?:WIKO-)?Kite",
                "model": "Kite"
            },
            {
                "regex": "W_K400",
                "model": "Lenny 5"
            },
            {
                "regex": "C210AE(?:[);/ ]|$)",
                "model": "Life"
            },
            {
                "regex": "U307AS(?:[);/ ]|$)",
                "model": "Life 2"
            },
            {
                "regex": "v3913(?:[);/ ]|$)",
                "model": "Kenny"
            },
            {
                "regex": "(?:WIKO-)?Lenny[ -_]?3 Max",
                "model": "Lenny 3 Max"
            },
            {
                "regex": "(?:WIKO-)?Lenny[ -_]?4 Plus",
                "model": "Lenny 4 Plus"
            },
            {
                "regex": "(?:WIKO-)?Lenny[ -_]?([2-9])",
                "model": "Lenny $1"
            },
            {
                "regex": "(?:WIKO-)?Lenny",
                "model": "Lenny"
            },
            {
                "regex": "(?:WIKO-)?Slide",
                "model": "Slide"
            },
            {
                "regex": "(?:WIKO-)?Sunset2",
                "model": "Sunset 2"
            },
            {
                "regex": "(?:WIKO-)?Sunset",
                "model": "Sunset"
            },
            {
                "regex": "(?:WIKO-)?Sunny3",
                "model": "Sunny 3"
            },
            {
                "regex": "W-K200",
                "model": "Sunny 3 Plus"
            },
            {
                "regex": "(?:WIKO-)?Sunny2 Plus",
                "model": "Sunny 2 Plus"
            },
            {
                "regex": "(?:WIKO-)?Sunny2",
                "model": "Sunny 2"
            },
            {
                "regex": "W-K360-TV",
                "model": "Sunny 4 Plus"
            },
            {
                "regex": "W-K130-TMV",
                "model": "Sunny 4"
            },
            {
                "regex": "(?:WIKO-)?Sunny",
                "model": "Sunny"
            },
            {
                "regex": "(?:W_K600|Tommy3|W_C200SN)",
                "model": "Tommy 3"
            },
            {
                "regex": "(?:WIKO-)?Tommy2 Plus",
                "model": "Tommy 2 Plus"
            },
            {
                "regex": "(?:WIKO-)?Tommy2",
                "model": "Tommy 2"
            },
            {
                "regex": "(?:WIKO-)?Tommy",
                "model": "Tommy"
            },
            {
                "regex": "(?:WIKO-)?Wax",
                "model": "Wax"
            },
            {
                "regex": "(?:WIKO-)?HARRY",
                "model": "Harry"
            },
            {
                "regex": "W-V600",
                "model": "Harry 2"
            },
            {
                "regex": "(?:WIKO-)?WIM Lite",
                "model": "WIM Lite"
            },
            {
                "regex": "(?:WIKO-)?Pulp Fab 4G",
                "model": "Pulp Fab 4G"
            },
            {
                "regex": "(?:WIKO-)?Pulp Fab",
                "model": "Pulp Fab"
            },
            {
                "regex": "(?:WIKO-)?Pulp 4G",
                "model": "Pulp 4G"
            },
            {
                "regex": "(?:WIKO-)?Pulp",
                "model": "Pulp"
            },
            {
                "regex": "(?:WIKO-)?Robby",
                "model": "Robby"
            },
            {
                "regex": "(?:WIKO-)?U PULSE LITE",
                "model": "U Pulse Lite"
            },
            {
                "regex": "(?:WIKO-)?U PULSE",
                "model": "U Pulse"
            },
            {
                "regex": "(?:WIKO-)?U FEEL LITE",
                "model": "U Feel Lite"
            },
            {
                "regex": "(?:WIKO-)?U FEEL PRIME",
                "model": "U Feel Prime"
            },
            {
                "regex": "(?:WIKO-)?U FEEL",
                "model": "U Feel"
            },
            {
                "regex": "(?:WIKO-)?View2 Go",
                "model": "View 2 Go"
            },
            {
                "regex": "(?:WIKO-)?View XL",
                "model": "View XL"
            },
            {
                "regex": "(?:WIKO-)?View Prime",
                "model": "View Prime"
            },
            {
                "regex": "WC300",
                "model": "View Lite"
            },
            {
                "regex": "W_C800",
                "model": "View 2"
            },
            {
                "regex": "W_C860",
                "model": "View 2 Pro"
            },
            {
                "regex": "W_P200",
                "model": "View Max"
            },
            {
                "regex": "W_P130",
                "model": "View Go"
            },
            {
                "regex": "W-V800-(TVM|TIM|EEA|OPE|DU)",
                "model": "View 3 Lite"
            },
            {
                "regex": "W-K130-(?:OPE|EEA)",
                "model": "Y50"
            },
            {
                "regex": "W-K420-EEA",
                "model": "Y50"
            },
            {
                "regex": "W-K510-(?:TVM|EEA|OPE|SUN|BYT|TH)|W-K510S-SWI",
                "model": "Y60"
            },
            {
                "regex": "W-V720-(?:EEA|OPE|SUN)",
                "model": "Y80"
            },
            {
                "regex": "W-P311-(?:EEA|OPE|TVM)",
                "model": "View 3"
            },
            {
                "regex": "W-P611-(?:EEA|OPE)",
                "model": "View 3 Pro"
            },
            {
                "regex": "W-V830-EEA",
                "model": "View 4"
            },
            {
                "regex": "W-V730-EEA",
                "model": "View 4 Lite"
            },
            {
                "regex": "W-U300(?:[);/ ]|$)",
                "model": "Ride"
            },
            {
                "regex": "Wiko ([^/;]+) Build",
                "model": "$1"
            },
            {
                "regex": "Wiko ([^/; ]+)",
                "model": "$1"
            }
        ]
    },
    "Wieppo": {
        "regex": "Wieppo (S6 Lite|S[658]|E1)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Weimei": {
        "regex": "weplus_3",
        "device": "smartphone",
        "models": [
            {
                "regex": "weplus_3",
                "model": "WePlus 3"
            }
        ]
    },
    "Wileyfox": {
        "regex": "(?:Wileyfox [^/]+)|Swift 2(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Wileyfox Spark \\+",
                "model": "Spark +"
            },
            {
                "regex": "Wileyfox Spark X",
                "model": "Spark X"
            },
            {
                "regex": "Wileyfox (Spark|Swift)",
                "model": "$1"
            },
            {
                "regex": "Wileyfox ([^/)]+)(?: Build|\\))",
                "model": "$1"
            },
            {
                "regex": "Swift 2 (X|Plus)(?:[);/ ]|$)",
                "model": "Swift 2 $1"
            },
            {
                "regex": "Swift 2(?:[);/ ]|$)",
                "model": "Swift 2"
            }
        ]
    },
    "Wolder": {
        "regex": "miSmart|miTab|WIAM \\#24|WOLDER",
        "device": "smartphone",
        "models": [
            {
                "regex": "WIAM \\#24",
                "model": "Wiam #24"
            },
            {
                "regex": "WOLDER_WIAM_65",
                "model": "Wiam #65"
            },
            {
                "regex": "miSmart[ \\-_]?([^/]+) Build",
                "model": "miSmart $1"
            },
            {
                "regex": "miTab[ \\-_]?([^/)]+)(?: Build|\\))",
                "device": "tablet",
                "model": "miTab $1"
            }
        ]
    },
    "Wolfgang": {
        "regex": "AT[ _-]AS([0-9A-Z]+)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "AT-AS$1"
    },
    "Wonu": {
        "regex": "Wonu ([a-z0-9]+)",
        "device": "feature phone",
        "model": "$1"
    },
    "Woo": {
        "regex": "SP5026i-Scorpio",
        "device": "smartphone",
        "models": [
            {
                "regex": "SP5026i-Scorpio",
                "model": "Scorpio"
            }
        ]
    },
    "Woxter": {
        "regex": "Woxter[ _]([^/;)]+)(?: Build|\\))|Nimbus 101Q",
        "device": "tablet",
        "models": [
            {
                "regex": "Nimbus 101Q",
                "model": "Nimbus 101Q"
            },
            {
                "regex": "Woxter[ _]([^/;)]+)(?: Build|\\))",
                "model": "$1"
            }
        ]
    },
    "X-View": {
        "regex": "ZEN_U5\\+|AMBER_KIDS|Proton_Amber_HD|PROTON_JADE_2_PRO",
        "device": "smartphone",
        "models": [
            {
                "regex": "ZEN_U5\\+",
                "model": "ZEN U5+"
            },
            {
                "regex": "AMBER_KIDS",
                "device": "tablet",
                "model": "Amber Kids"
            },
            {
                "regex": "Proton_Amber_HD",
                "device": "tablet",
                "model": "Proton Amber HD"
            },
            {
                "regex": "PROTON_JADE_2_PRO",
                "device": "tablet",
                "model": "Proton Jade 2 Pro"
            }
        ]
    },
    "POCO": {
        "regex": "(?:POCO[ _](?:X[23]|F2 PRO|M[32])|M2010J19C[GI]|M2007J20C[ITG]|M2012K11AG|M2006C3MI|M2004J19PI|M2102J20SG)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "POCO X2(?:[);/ ]|$)",
                "model": "X2"
            },
            {
                "regex": "(?:M2010J19C[GI]|POCO M3)(?:[);/ ]|$)",
                "model": "M3"
            },
            {
                "regex": "M2012K11AG(?:[);/ ]|$)",
                "model": "F3"
            },
            {
                "regex": "M2004J19PI(?:[);/ ]|$)",
                "model": "M2"
            },
            {
                "regex": "POCO M2 Pro(?:[);/ ]|$)",
                "model": "M2 Pro"
            },
            {
                "regex": "(?:M2007J20C[GT]|POCO X3 NFC)(?:[);/ ]|$)",
                "model": "X3 NFC"
            },
            {
                "regex": "(?:M2007J20CI|POCO X3)(?:[);/ ]|$)",
                "model": "X3"
            },
            {
                "regex": "M2102J20SG(?:[);/ ]|$)",
                "model": "X3 Pro"
            },
            {
                "regex": "M2006C3MI(?:[);/ ]|$)",
                "model": "C3"
            },
            {
                "regex": "POCO F2 PRO(?:[);/ ]|$)",
                "model": "F2 Pro"
            }
        ]
    },
    "Xiaomi": {
        "regex": "Xiaomi(?!/(?:Miui|Mint[ ])Browser)|Mi9 Pro 5G|(?:MI [a-z0-9]+|Mi-4c|MI-One[ _]?[a-z0-9]+|MIX(?: 2S?)?)(?:[);/ ]|$)|HM (?:[^/;]+) (?:Build|MIUI)|(?:2014501|2014011|201481[12378]|201302[23]|2013061) Build|Redmi|POCOPHONE|(?:SHARK )?(KLE|MBU)-[AH]0|SKR-[AH]0|SKW-[AH]0|POCO F1|DLT-[AH]0|MIBOX[234]([_ ]PRO)?|MiTV4[CSX]?|MiTV-(MSSP[01]|AXSO0)|AWM-A0|MI CC 9 Meitu Edition|MiBOX1S|MiTV4A|M2006J10C|M2006C3(?:L[IGC]|LVG|MN?G)|M2007J1(?:7[CGI]|SC)|M2002J9[EG]|HM2014819|WT88047|M2004J(?:7[AB]|19)C|M2012K11C|M2011K2[CG]|M2006C3[ML]II|M2003J15SC|M2007J3S[ICYGP]|M2007J22[CG]|M2101K6[GI]|M2101K(?:[79]AG|7AI)|M2010J19S[CGYI]|HM NOTE 1(?:LTE|W)|MI[_ ]PLAY|XIG01|MI_(NOTE_Pro|5X|4i|(?:A2|8)_Lite)",
        "device": "smartphone",
        "models": [
            {
                "regex": "SKR-[AH]0",
                "model": "Black Shark"
            },
            {
                "regex": "AWM-A0",
                "model": "Black Shark Helo"
            },
            {
                "regex": "SKW-[AH]0",
                "model": "Black Shark 2"
            },
            {
                "regex": "DLT-[AH]0",
                "model": "Black Shark 2 Pro"
            },
            {
                "regex": "(?:SHARK )?KLE-A0",
                "model": "Black Shark 3"
            },
            {
                "regex": "(?:SHARK )?KLE-H0",
                "model": "Black Shark 3 5G"
            },
            {
                "regex": "(?:SHARK )?MBU-H0",
                "model": "Black Shark 3 Pro 5G"
            },
            {
                "regex": "(?:SHARK )?MBU-A0",
                "model": "Black Shark 3 Pro"
            },
            {
                "regex": "Xiaomi_2014501|2014501 Build",
                "model": "Hongmi 4G"
            },
            {
                "regex": "Xiaomi_2014011|2014011 Build",
                "model": "Hongmi 1S"
            },
            {
                "regex": "Xiaomi_201302[23]|201302[23] Build",
                "model": "Hongmi"
            },
            {
                "regex": "Xiaomi[_-]2014818|2014818 Build",
                "model": "Hongmi 2 3G"
            },
            {
                "regex": "Xiaomi_2014817|2014817 Build",
                "model": "Hongmi 2"
            },
            {
                "regex": "Xiaomi[_-]201481[123]|201481[123] Build",
                "model": "Hongmi 2 4G"
            },
            {
                "regex": "MI[_ ]PLAY(?:[);/ ]|$)",
                "model": "MI Play"
            },
            {
                "regex": "Mi 9 SE(?:[);/ ]|$)",
                "model": "MI 9 SE"
            },
            {
                "regex": "MI CC 9 Meitu Edition(?:[);/ ]|$)",
                "model": "MI CC 9"
            },
            {
                "regex": "Xiaomi_M2001J2E_TD-LTE(?:[);/ ]|$)",
                "model": "MI 10"
            },
            {
                "regex": "M2011K2[CG](?:[);/ ]|$)",
                "model": "MI 11"
            },
            {
                "regex": "M2007J3S[CYP](?:[);/ ]|$)",
                "model": "MI 10T 5G"
            },
            {
                "regex": "M2007J3S[GI](?:[);/ ]|$)",
                "model": "MI 10T Pro 5G"
            },
            {
                "regex": "M2007J17G(?:[);/ ]|$)",
                "model": "MI 10T Lite 5G"
            },
            {
                "regex": "M2007J1SC(?:[);/ ]|$)",
                "model": "MI 10 Ultra 5G"
            },
            {
                "regex": "(?:M2002J9[EG]|XIG01)(?:[);/ ]|$)",
                "model": "MI 10 Lite 5G"
            },
            {
                "regex": "M2007J17I(?:[);/ ]|$)",
                "model": "MI 10i"
            },
            {
                "regex": "M2101K9AG(?:[);/ ]|$)",
                "model": "MI 11 Lite"
            },
            {
                "regex": "MI[ _]8[ _]Lite(?:[);/ ]|$)",
                "model": "MI 8 Lite"
            },
            {
                "regex": "Mi 9T Pro(?:[);/ ]|$)",
                "model": "MI 9T Pro"
            },
            {
                "regex": "Mi9 Pro 5G(?:[);/ ]|$)",
                "model": "MI 9 Pro 5G"
            },
            {
                "regex": "MI ([89]) Lite(?:[);/ ]|$)",
                "model": "MI $1 Lite"
            },
            {
                "regex": "MI 5s Plus(?:[);/ ]|$)",
                "model": "MI 5s Plus"
            },
            {
                "regex": "Xiaomi_2013061|2013061 Build",
                "model": "MI 3"
            },
            {
                "regex": "Mi-4c(?:[);/ ]|$)",
                "model": "MI 4C"
            },
            {
                "regex": "MI 4W(?:[);/ ]|$)",
                "model": "MI 4W"
            },
            {
                "regex": "Mi_4i(?:[);/ ]|$)",
                "model": "MI 4i"
            },
            {
                "regex": "MI MAX 3(?:[);/ ]|$)",
                "model": "MI MAX 3"
            },
            {
                "regex": "MI MAX 2(?:[);/ ]|$)",
                "model": "MI MAX 2"
            },
            {
                "regex": "Mi[ _]A2[ _]Lite(?:[);/ ]|$)",
                "model": "MI A2 Lite"
            },
            {
                "regex": "MIX 3(?:[);/ ]|$)",
                "model": "MI MIX 3"
            },
            {
                "regex": "MIX 2S(?:[);/ ]|$)",
                "model": "MI MIX 2S"
            },
            {
                "regex": "MIX 2(?:[);/ ]|$)",
                "model": "MI MIX 2"
            },
            {
                "regex": "MIX(?:[);/ ]|$)",
                "model": "MI MIX"
            },
            {
                "regex": "POCOPHONE[ _]F1|POCO F1",
                "model": "Pocophone F1"
            },
            {
                "regex": "Redmi 5 Plus",
                "model": "Redmi 5 Plus"
            },
            {
                "regex": "WT88047(?:[);/ ]|$)",
                "model": "Redmi 2"
            },
            {
                "regex": "HM2014819(?:[);/ ]|$)",
                "model": "Redmi 2 Pro"
            },
            {
                "regex": "HM 2A(?:[);/ ]|$)",
                "model": "Redmi 2A"
            },
            {
                "regex": "HM 1S[CW]?(?:[);/ ]|$)",
                "model": "Redmi 1S"
            },
            {
                "regex": "HM 1(?:[);/ ]|$)",
                "model": "Redmi 1"
            },
            {
                "regex": "M2004J7AC(?:[);/ ]|$)",
                "model": "Redmi 10X"
            },
            {
                "regex": "(?:M2004J19C|M2006C3MII)(?:[);/ ]|$)",
                "model": "Redmi 9"
            },
            {
                "regex": "M2010J19SI(?:[);/ ]|$)",
                "model": "Redmi 9 Power"
            },
            {
                "regex": "M2006C3L[GCI](?:[);/ ]|$)",
                "model": "Redmi 9A"
            },
            {
                "regex": "M2006C3LII(?:[);/ ]|$)",
                "model": "Redmi 9i"
            },
            {
                "regex": "M2006C3MG(?:[);/ ]|$)",
                "model": "Redmi 9C"
            },
            {
                "regex": "M2006C3LVG(?:[);/ ]|$)",
                "model": "Redmi 9AT"
            },
            {
                "regex": "M2010J19S[GY](?:[);/ ]|$)",
                "model": "Redmi 9T"
            },
            {
                "regex": "M2006C3MNG(?:[);/ ]|$)",
                "model": "Redmi 9C NFC"
            },
            {
                "regex": "M2004J7BC(?:[);/ ]|$)",
                "model": "Redmi 10X Pro"
            },
            {
                "regex": "Redmi K20 Pro(?:[);/ ]|$)",
                "model": "Redmi K20 Pro"
            },
            {
                "regex": "Redmi K30 5G(?:[);/ ]|$)",
                "model": "Redmi K30 5G"
            },
            {
                "regex": "M2012K11C(?:[);/ ]|$)",
                "model": "Redmi K40 Pro"
            },
            {
                "regex": "M2006J10C(?:[);/ ]|$)",
                "model": "Redmi K30 Ultra"
            },
            {
                "regex": "Redmi Y1 Lite(?:[);/ ]|$)",
                "model": "Redmi Y1 Lite"
            },
            {
                "regex": "MI PAD 4 PLUS(?:[);/ ]|$)",
                "model": "Mi Pad 4 Plus",
                "device": "tablet"
            },
            {
                "regex": "MI PAD 4(?:[);/ ]|$)",
                "model": "Mi Pad 4",
                "device": "tablet"
            },
            {
                "regex": "MI PAD 3(?:[);/ ]|$)",
                "model": "Mi Pad 3",
                "device": "tablet"
            },
            {
                "regex": "MI PAD 2(?:[);/ ]|$)",
                "model": "Mi Pad 2",
                "device": "tablet"
            },
            {
                "regex": "MI PAD(?:[);/ ]|$)",
                "model": "Mi Pad",
                "device": "tablet"
            },
            {
                "regex": "MIBOX3[ _]Pro(?:[);/ ]|$)",
                "model": "Mi Box 3 Pro",
                "device": "tv"
            },
            {
                "regex": "MIBOX([234])(?:[);/ ]|$)",
                "model": "Mi Box $1",
                "device": "tv"
            },
            {
                "regex": "MiBOX1S(?:[);/ ]|$)",
                "model": "Mi Box 1S",
                "device": "tv"
            },
            {
                "regex": "MiTV-MSSP1(?:[);/ ]|$)",
                "model": "MiTV 4S",
                "device": "tv"
            },
            {
                "regex": "(?:MiTV-AXSO0|MiTV4(?:A|-ANSM0)|MiTV-MSSP0)(?:[);/ ]|$)",
                "model": "MiTV 4A",
                "device": "tv"
            },
            {
                "regex": "(MiTV)(4[CSX]|4)(?:[);/ ]|$)",
                "model": "$1 $2",
                "device": "tv"
            },
            {
                "regex": "HM NOTE 1W(?:[);/ ]|$)",
                "device": "phablet",
                "model": "Redmi Note"
            },
            {
                "regex": "HM NOTE 1TD (?:Build|MIUI)",
                "device": "phablet",
                "model": "Hongmi Note 1TD"
            },
            {
                "regex": "HM NOTE 1(?:LTE|S)(?:W|GLOBAL|TD)? (?:Build|MIUI)|HM NOTE 1LTE",
                "device": "phablet",
                "model": "Redmi Note 4G"
            },
            {
                "regex": "Redmi Note 5A Prime",
                "model": "Redmi Note 5A Prime",
                "device": "phablet"
            },
            {
                "regex": "M2010J19SC(?:[);/ ]|$)",
                "model": "Redmi Note 9",
                "device": "phablet"
            },
            {
                "regex": "M2003J15SC(?:[);/ ]|$)",
                "model": "Redmi Note 10X",
                "device": "phablet"
            },
            {
                "regex": "M2007J22G(?:[);/ ]|$)",
                "model": "Redmi Note 9T 5G",
                "device": "phablet"
            },
            {
                "regex": "M2007J22C(?:[);/ ]|$)",
                "model": "Redmi Note 9 5G",
                "device": "phablet"
            },
            {
                "regex": "M2007J17C(?:[);/ ]|$)",
                "model": "Redmi Note 9 Pro 5G",
                "device": "phablet"
            },
            {
                "regex": "M2101K7A[GI]",
                "model": "Redmi Note 10",
                "device": "phablet"
            },
            {
                "regex": "M2101K6[GI]",
                "model": "Redmi Note 10 Pro",
                "device": "phablet"
            },
            {
                "regex": "Redmi Note ([5-9]) Pro(?:[);/ ]|$)",
                "model": "Redmi Note $1 Pro",
                "device": "phablet"
            },
            {
                "regex": "Redmi[ _]Note[ _]([^;/) ]+)?",
                "model": "Redmi Note $1",
                "device": "phablet"
            },
            {
                "regex": "Redmi[ _]([^;/) ]+)?",
                "model": "Redmi $1"
            },
            {
                "regex": "MI_NOTE_Pro",
                "model": "MI Note Pro",
                "device": "phablet"
            },
            {
                "regex": "Mi Note 10 Pro",
                "model": "Mi Note 10 Pro",
                "device": "phablet"
            },
            {
                "regex": "MI[ _]Note[ _]([^;/) ]+)?",
                "model": "MI Note $1",
                "device": "phablet"
            },
            {
                "regex": "(MI(?:-One)?[ _](?:[^;/]*))Build",
                "model": "$1"
            },
            {
                "regex": "(MI[ _][a-z0-9]+|MI-One[ _]?[a-z0-9]+)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "HM Note ([^/;]+) (?:Build|MIUI)",
                "device": "phablet",
                "model": "Note"
            },
            {
                "regex": "HM ([^/;]+) (?:Build|MIUI)",
                "model": "HM $1"
            },
            {
                "regex": "Xiaomi[ _-]([^/;]+)(?: Build|$)",
                "model": "$1"
            }
        ]
    },
    "Xion": {
        "regex": "XI-CE(?:655|U[48])",
        "device": "smartphone",
        "models": [
            {
                "regex": "XI-CE(655|U[48])",
                "model": "CE$1"
            }
        ]
    },
    "Xolo": {
        "regex": "Xolo|(?:Q600|Q700s?|Q800|Q1000s|Q1000[ _]Opus|Q1010i|Q2000|Omega[ _]5.[05]|Era5X|Era[ _]2V|Era_4X(?:_2GB)?)(?:[);/ ]|$)|BLACK-1XM",
        "device": "smartphone",
        "models": [
            {
                "regex": "BLACK-1XM",
                "model": "Black 1X"
            },
            {
                "regex": "Era_?([45])X(?:[);/ ]|$)",
                "model": "Era $1X"
            },
            {
                "regex": "Era_4X_2GB(?:[);/ ]|$)",
                "model": "Era 4X 2GB"
            },
            {
                "regex": "Era[ _]2V(?:[);/ ]|$)",
                "model": "Era 2V"
            },
            {
                "regex": "(Q600 Club|Q600|Q700s?|Q800|Q1000s Plus|Q1000s|Q1000[ _]Opus|Q1010i|Q2000|Omega[ _]5.[05])(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "Xolo[ _]?([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "Xolo[ _]?([a-z0-9_-]+)",
                "model": "$1"
            }
        ]
    },
    "Yarvik": {
        "regex": "Xenta[ \\-]Tab|Luna TAB|TAB09-410|TAB10-410|TAB07-485|TAB0[78]-200|TAB08-201-3G|TAB9-200|TAB09-211|TAB10-2[01]1|TAB13-201",
        "device": "tablet",
        "models": [
            {
                "regex": "Luna TAB07-920N",
                "model": "Luna 7"
            },
            {
                "regex": "Luna TAB07-10[01]",
                "model": "Luna 7c"
            },
            {
                "regex": "Luna TAB274",
                "model": "Luna 7c"
            },
            {
                "regex": "Luna TAB474",
                "model": "Luna 10"
            },
            {
                "regex": "Luna TAB10-150",
                "model": "Luna 10c"
            },
            {
                "regex": "TAB09-410",
                "model": "Noble 9.7"
            },
            {
                "regex": "TAB10-410",
                "model": "Noble 10.1"
            },
            {
                "regex": "TAB07-485",
                "model": "Noble Mini"
            },
            {
                "regex": "Xenta-TAB07-21[01]",
                "model": "Xenta 7c"
            },
            {
                "regex": "TAB07-200",
                "model": "Xenta 7ic"
            },
            {
                "regex": "TAB08-200",
                "model": "Xenta 8ic"
            },
            {
                "regex": "TAB08-201-3G",
                "model": "Xenta 8c"
            },
            {
                "regex": "TAB9-200",
                "model": "Xenta 9.7ic"
            },
            {
                "regex": "TAB09-211",
                "model": "Xenta 9.7ic+"
            },
            {
                "regex": "TAB10-2[01]1",
                "model": "Xenta 10ic"
            },
            {
                "regex": "TAB13-201",
                "model": "Xenta 13c"
            }
        ]
    },
    "Yes": {
        "regex": "M631Y|M685Y4|M651G_MY|YES (MPY(?:48|54))",
        "device": "smartphone",
        "models": [
            {
                "regex": "M631Y",
                "model": "Altitude"
            },
            {
                "regex": "M651G_MY",
                "model": "Altitude 2"
            },
            {
                "regex": "M685Y4",
                "model": "Altitude 3"
            },
            {
                "regex": "YES (MPY(?:48|54))",
                "model": "$1"
            }
        ]
    },
    "Yezz": {
        "regex": "Yezz|ANDY[ _]|(?:4E4|A5EI|LIV1)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "4E4",
                "model": "Andy 4E4"
            },
            {
                "regex": "Yezz-AC4EI",
                "model": "Andy 4EI"
            },
            {
                "regex": "YEZZ-A4M",
                "model": "Andy A4M"
            },
            {
                "regex": "A5EI",
                "model": "Andy A5EI"
            },
            {
                "regex": "LIV1",
                "model": "LIV1"
            },
            {
                "regex": "Andy[ _]([A-Z]*[0-9]\\.[0-9][A-Z0-9]*)(?:[ _](LTE))?",
                "model": "Andy $1 $2"
            },
            {
                "regex": "Andy[ _]([A-Z]*[0-9])([0-9][A-Z0-9]*)(?:[ _](LTE))?",
                "model": "Andy $1.$2 $3"
            },
            {
                "regex": "Andy[ _]([A-Z]*[0-9][A-Z0-9]+)(?:[ _](LTE))?",
                "model": "Andy $1 $2"
            }
        ]
    },
    "Yu": {
        "regex": "(?:YU5[05]10[A]?|YU4711|AO5510|YU5050|YU501[14]|YU5551)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "YU5010[A]?(?:[);/ ]|$)",
                "model": "Yuphoria"
            },
            {
                "regex": "YU5011(?:[);/ ]|$)",
                "model": "Yunique 2"
            },
            {
                "regex": "YU5050(?:[);/ ]|$)",
                "model": "Yutopia"
            },
            {
                "regex": "AO5510(?:[);/ ]|$)",
                "model": "Yureka"
            },
            {
                "regex": "YU5551(?:[);/ ]|$)",
                "model": "Yureka 2"
            },
            {
                "regex": "YU5510[A]?(?:[);/ ]|$)",
                "model": "Yureka Plus"
            },
            {
                "regex": "YU4711(?:[);/ ]|$)",
                "model": "Yunique"
            },
            {
                "regex": "YU5014(?:[);/ ]|$)",
                "model": "Ace"
            }
        ]
    },
    "Yuandao": {
        "regex": "N101[ _]DUAL(?:[ _]CORE)?(?:[ _]?2|\\|\\|)?(?:[ _]V11)?(?:[);/ ]|$)",
        "device": "tablet",
        "model": "N101"
    },
    "Yusun": {
        "regex": "Yusun|LA2-T",
        "device": "smartphone",
        "models": [
            {
                "regex": "LA2-T",
                "model": "LA2-T"
            },
            {
                "regex": "Yusun ([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "Yusun ([a-z0-9_\\-\\+]+)",
                "model": "$1"
            }
        ]
    },
    "Ytone": {
        "regex": "YTONE[ _\\-]",
        "device": "smartphone",
        "models": [
            {
                "regex": "YTONE[ _\\-]?([^/;]+) Build",
                "model": "$1"
            },
            {
                "regex": "YTONE[ _\\-]?([^);/ ]+)",
                "model": "$1"
            }
        ]
    },
    "Zonda": {
        "regex": "(ZM(?:CK|EM|TFTV|TN)[a-z0-9]+)|ZA409",
        "device": "feature phone",
        "models": [
            {
                "regex": "ZA409",
                "device": "smartphone",
                "model": "Muzic"
            },
            {
                "regex": "(ZM(?:CK|EM|TFTV|TN)[a-z0-9]+)",
                "model": "$1"
            }
        ]
    },
    "ZYQ": {
        "regex": "ZYQ-Q88|(Q(?:2602|262[436]|638|2688|272[89]|3022|3623|TAB Tab4|328 m9|328|668)|J77|Q[._](?:Up|Boss P99|Dee R09|Good M9|Hi|Hot|Me|Mate R99|Next|TOP-X8|You))(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Q638",
                "model": "I7"
            },
            {
                "regex": "Q2624",
                "model": "Cheer 3G"
            },
            {
                "regex": "Q2602",
                "model": "TV Next"
            },
            {
                "regex": "Q2728",
                "model": "Zone 3G"
            },
            {
                "regex": "Q2729",
                "model": "Q2729"
            },
            {
                "regex": "Q2688",
                "model": "Q2688"
            },
            {
                "regex": "Q2623",
                "model": "Win 3G"
            },
            {
                "regex": "Q2626",
                "model": "Care 3G"
            },
            {
                "regex": "Q3623",
                "model": "Braw 3G"
            },
            {
                "regex": "Q3022",
                "model": "Q3022"
            },
            {
                "regex": "Q328 m9",
                "model": "Q328 M9"
            },
            {
                "regex": "Q328",
                "model": "Speed 3G"
            },
            {
                "regex": "Q668",
                "model": "TV I4"
            },
            {
                "regex": "J77",
                "model": "Q.Dee"
            },
            {
                "regex": "Q\\.Up C5",
                "model": "Q.Up C5"
            },
            {
                "regex": "Q\\.Up",
                "model": "Q.Up"
            },
            {
                "regex": "Q\\.Boss P99",
                "model": "Q.Boss P99"
            },
            {
                "regex": "Q[._]Dee R09",
                "model": "Q.Dee R09"
            },
            {
                "regex": "Q\\.Good M9",
                "model": "Q.Good M9"
            },
            {
                "regex": "Q[._]Hi S1",
                "model": "Q.Hi S1"
            },
            {
                "regex": "Q[._]Hi",
                "model": "Q.Hi"
            },
            {
                "regex": "Q[._]Hot P7 3G",
                "model": "Q.Hot P7 3G"
            },
            {
                "regex": "Q[._]Hot",
                "model": "Q.Hot"
            },
            {
                "regex": "Q[._]Me Phone7 3G",
                "model": "Q.Me Phone 7 3G"
            },
            {
                "regex": "Q[._]Me Plus7",
                "model": "Q.Me Plus 7"
            },
            {
                "regex": "Q[._]Me",
                "model": "Q.Me"
            },
            {
                "regex": "Q[._]Mate R99",
                "model": "Q.Mate R99"
            },
            {
                "regex": "Q[._]Next B7",
                "model": "Q.Next B7"
            },
            {
                "regex": "Q[._]Next J2",
                "model": "Q.Next J2"
            },
            {
                "regex": "Q[._]TOP-X8",
                "model": "Q.Top X8"
            },
            {
                "regex": "Q[._]You",
                "model": "Q.You"
            },
            {
                "regex": "QTAB Tab4",
                "model": "QTab Tab4",
                "device": "tablet"
            },
            {
                "regex": "ZYQ-Q88",
                "model": "Q88",
                "device": "tablet"
            }
        ]
    },
    "Toshiba": {
        "regex": "Toshiba|TSBNetTV/|portalmmm/[12].0 TS|T-01C|T-0[12]D|IS04|IS11T|AT1S0|AT300SE|AT(7-C|10-A|10[PL]E-A|100|200|270|300|330|374|400|470|500|503|570|703|830)",
        "device": "smartphone",
        "models": [
            {
                "regex": "T-01C",
                "model": "Regza T-01C"
            },
            {
                "regex": "T-01D",
                "model": "Regza T-01D"
            },
            {
                "regex": "T-02D",
                "model": "Regza T-02D"
            },
            {
                "regex": "IS04",
                "model": "Regza IS04"
            },
            {
                "regex": "IS11T",
                "model": "Regza IS11T"
            },
            {
                "regex": "AT7-C",
                "model": "Excite",
                "device": "tablet"
            },
            {
                "regex": "AT1S0",
                "model": "Regza AT1S0",
                "device": "tablet"
            },
            {
                "regex": "AT300SE",
                "model": "Regza AT300SE",
                "device": "tablet"
            },
            {
                "regex": "AT500a",
                "model": "Regza AT500a",
                "device": "tablet"
            },
            {
                "regex": "AT(100|200|270|300|330|374|400|470|500|503|570|703|830)",
                "model": "Regza AT$1",
                "device": "tablet"
            },
            {
                "regex": "AT10([PL])E-A",
                "model": "Excite AT10$1-A",
                "device": "tablet"
            },
            {
                "regex": "AT10-A",
                "model": "Excite AT10-A",
                "device": "tablet"
            },
            {
                "regex": "TSBNetTV/",
                "device": "tv",
                "model": ""
            },
            {
                "regex": "Toshiba[ /_\\-]?([a-z0-9_ \\-]+) Build",
                "model": "$1"
            },
            {
                "regex": "Toshiba[ /_\\-]?([a-z0-9_\\-]+)",
                "model": "$1"
            },
            {
                "regex": "portalmmm/[12].0 (TS[a-z0-9 ]+)",
                "model": "$1"
            }
        ]
    },
    "Vivax": {
        "regex": "VIVAX|(?:Fly5[_ ]Lite|Fun[_ ]S500|Point_(?:X[12]|X501)|PRO3|32LE77SM|40LE77SM|FUN S1|FUN_S20)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Fly5[_ ]Lite(?:[);/ ]|$)",
                "model": "Fly 5 Lite"
            },
            {
                "regex": "Point_X([12])(?:[);/ ]|$)",
                "model": "Point X$1"
            },
            {
                "regex": "Fun[_ ]S500(?:[);/ ]|$)",
                "model": "Fun S500"
            },
            {
                "regex": "FUN[ _]S(1|20)(?:[);/ ]|$)",
                "model": "Fun S$1"
            },
            {
                "regex": "VIVAX[ _]Fly3(?:[);/ ]|$)",
                "model": "Fly 3"
            },
            {
                "regex": "VIVAX[ _]Fly[ _]V1(?:[);/ ]|$)",
                "model": "Fly V1"
            },
            {
                "regex": "(?:VIVAX )?(Point[ _](?:X551|X501))(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "SMART Point X5010(?:[);/ ]|$)",
                "model": "Smart Point X5010"
            },
            {
                "regex": "VIVAX[_ ]PRO3",
                "model": "Pro 3"
            },
            {
                "regex": "VIVAX TABLET TPC 802 3G(?:[);/ ]|$)",
                "model": "TPC-802 3G"
            },
            {
                "regex": "(32LE77SM|40LE77SM)",
                "device": "tv",
                "model": "$1"
            }
        ]
    },
    "MLS": {
        "regex": "IQ(?:0705|L550|1010w?|1012N?|1019N|1025|1380|1401|1436|1452a|1453|1470|1502a|1511|1552|1553|1568|1570|1805N|1806|1808|1810B_M|1832|1850|1890|5017|9610|181011N|E[12]00|EL41|G550|GW516|L30|L5[01]|M801|M1001|N700|P50|R300|S300|S1001|W503|W511T|W553N?|W60[38]|W626|M960|D700|9013_4N)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "IQ0705(?:[);/ ]|$)",
                "model": "iQTalk Twist"
            },
            {
                "regex": "IQL550(?:[);/ ]|$)",
                "model": "Notch Lite"
            },
            {
                "regex": "IQ1010W?(?:[);/ ]|$)",
                "model": "iQTab 10",
                "device": "tablet"
            },
            {
                "regex": "IQ1012N?(?:[);/ ]|$)",
                "model": "iQTab Brave 3G",
                "device": "tablet"
            },
            {
                "regex": "IQ1025(?:[);/ ]|$)",
                "model": "iQTab Action 4G",
                "device": "tablet"
            },
            {
                "regex": "iQ9013_4N(?:[);/ ]|$)",
                "model": "Life 10.1\"",
                "device": "tablet"
            },
            {
                "regex": "IQ1019N(?:[);/ ]|$)",
                "model": "Alu Plus 4G 10.1\"",
                "device": "tablet"
            },
            {
                "regex": "iQ1805N(?:[);/ ]|$)",
                "model": "iQTab Ideal 8.0\"",
                "device": "tablet"
            },
            {
                "regex": "iQ181011N(?:[);/ ]|$)",
                "model": "iQTab Care 8.0\"",
                "device": "tablet"
            },
            {
                "regex": "IQ1380(?:[);/ ]|$)",
                "model": "Brain 3G"
            },
            {
                "regex": "IQ1401(?:[);/ ]|$)",
                "model": "iQTalk Verse 4G"
            },
            {
                "regex": "IQ1436(?:[);/ ]|$)",
                "model": "Color mini 4G"
            },
            {
                "regex": "IQ1452a(?:[);/ ]|$)",
                "model": "TOP-S 4G"
            },
            {
                "regex": "IQ1453(?:[);/ ]|$)",
                "model": "Trend 4G"
            },
            {
                "regex": "IQ1470(?:[);/ ]|$)",
                "model": "iQTalk Onyx"
            },
            {
                "regex": "IQ1502a(?:[);/ ]|$)",
                "model": "iQTalk Flame"
            },
            {
                "regex": "IQ1511(?:[);/ ]|$)",
                "model": "iQTalk Spicy"
            },
            {
                "regex": "IQ1552(?:[);/ ]|$)",
                "model": "iQTalk Titan 4G"
            },
            {
                "regex": "IQ1553(?:[);/ ]|$)",
                "model": "Color 3 4G"
            },
            {
                "regex": "IQ1568(?:[);/ ]|$)",
                "model": "Range 4G"
            },
            {
                "regex": "IQ1570(?:[);/ ]|$)",
                "model": "iQTalk Color 5.5 4G"
            },
            {
                "regex": "IQ1806(?:[);/ ]|$)",
                "model": "iQTab Rose",
                "device": "tablet"
            },
            {
                "regex": "IQ1808(?:[);/ ]|$)",
                "model": "iQTab Master",
                "device": "tablet"
            },
            {
                "regex": "IQ1810B_M(?:[);/ ]|$)",
                "model": "iQTab Novel 3G",
                "device": "tablet"
            },
            {
                "regex": "IQ1832(?:[);/ ]|$)",
                "model": "Jet 3G",
                "device": "tablet"
            },
            {
                "regex": "IQ1850(?:[);/ ]|$)",
                "model": "Diamond 5.2 4G"
            },
            {
                "regex": "IQ1890(?:[);/ ]|$)",
                "model": "iQTalk Aura"
            },
            {
                "regex": "IQ5017(?:[);/ ]|$)",
                "model": "Slice 4G"
            },
            {
                "regex": "IQ9610(?:[);/ ]|$)",
                "model": "Angel 3G",
                "device": "tablet"
            },
            {
                "regex": "iQM960(?:[);/ ]|$)",
                "model": "Angel 3G (2018)",
                "device": "tablet"
            },
            {
                "regex": "IQG550(?:[);/ ]|$)",
                "model": "Style 4G"
            },
            {
                "regex": "IQGW516(?:[);/ ]|$)",
                "model": "F5"
            },
            {
                "regex": "IQE100(?:[);/ ]|$)",
                "model": "Energy 4G"
            },
            {
                "regex": "iQE200(?:[);/ ]|$)",
                "model": "MX 4G"
            },
            {
                "regex": "IQEL41(?:[);/ ]|$)",
                "model": "Fab 4G"
            },
            {
                "regex": "IQL30(?:[);/ ]|$)",
                "model": "Status 4G"
            },
            {
                "regex": "IQL50(?:[);/ ]|$)",
                "model": "More 4G"
            },
            {
                "regex": "IQL51(?:[);/ ]|$)",
                "model": "Wave 4G"
            },
            {
                "regex": "IQM801(?:[);/ ]|$)",
                "model": "Brace 2018 4G",
                "device": "tablet"
            },
            {
                "regex": "IQM1001(?:[);/ ]|$)",
                "model": "Level 4G",
                "device": "tablet"
            },
            {
                "regex": "IQN700(?:[);/ ]|$)",
                "model": "Inspire 4G"
            },
            {
                "regex": "IQP50(?:[);/ ]|$)",
                "model": "Ruby 4G"
            },
            {
                "regex": "IQS300(?:[);/ ]|$)",
                "model": "DX 4G"
            },
            {
                "regex": "IQS1001(?:[);/ ]|$)",
                "model": "Vista 4G",
                "device": "tablet"
            },
            {
                "regex": "IQR300(?:[);/ ]|$)",
                "model": "R3 4G"
            },
            {
                "regex": "IQW608(?:[);/ ]|$)",
                "model": "Regal"
            },
            {
                "regex": "iQW603(?:[);/ ]|$)",
                "model": "Phab 6.0\"",
                "device": "phablet"
            },
            {
                "regex": "IQW626(?:[);/ ]|$)",
                "model": "D6 4G"
            },
            {
                "regex": "IQW511T(?:[);/ ]|$)",
                "model": "Liberal"
            },
            {
                "regex": "IQW553N?(?:[);/ ]|$)",
                "model": "Alu 5.5\""
            },
            {
                "regex": "IQW503(?:[);/ ]|$)",
                "model": "Flame 4G (2018)"
            },
            {
                "regex": "iQD700(?:[);/ ]|$)",
                "model": "Color Fingerprint 4G"
            }
        ]
    },
    "Fly": {
        "regex": "Fly(?!Flow|touch)|Power Plus 5000|Photo Pro|Slimline|Life (?:Ace|Jet|Sky|Compact 4G|Geo)|FS50[1-9]|FS51[0-8]|FS52[0-9]|FS530|FS55[134]|FS40[1-9]|FS45[1-9]|4FS06|MERIDIAN-|(?:IQ(?!10[134]0)[0-9]{3,}[i]?)[ _]?(?:Quad|Firebird|Quattro|Turbo|Magic)?(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(?:Fly_)?IQ310(?:[);/ ]|$)",
                "model": "Panorama",
                "device": "tablet"
            },
            {
                "regex": "(?:Fly_)?IQ236(?:[);/ ]|$)",
                "model": "Victory"
            },
            {
                "regex": "Slimline(?:[);/ ]|$)",
                "model": "Slimline"
            },
            {
                "regex": "Power Plus 5000(?:[);/ ]|$)",
                "model": "Power Plus 5000"
            },
            {
                "regex": "Photo Pro(?:[);/ ]|$)",
                "model": "Photo Pro"
            },
            {
                "regex": "Life Ace(?:[);/ ]|$)",
                "model": "Life Ace"
            },
            {
                "regex": "Life Jet(?:[);/ ]|$)",
                "model": "Life Jet"
            },
            {
                "regex": "Life Sky(?:[);/ ]|$)",
                "model": "Life Sky"
            },
            {
                "regex": "Life Geo(?:[);/ ]|$)",
                "model": "Life Geo"
            },
            {
                "regex": "Life Compact 4G(?:[);/ ]|$)",
                "model": "Life Compact 4G"
            },
            {
                "regex": "FS506",
                "model": "Cirrus 3"
            },
            {
                "regex": "FS403",
                "model": "Cumulus 1"
            },
            {
                "regex": "FS508",
                "model": "Cirrus 6"
            },
            {
                "regex": "FS511",
                "model": "Cirrus 7"
            },
            {
                "regex": "FS509",
                "model": "Nimbus 9"
            },
            {
                "regex": "FS406",
                "model": "Stratus 5"
            },
            {
                "regex": "FS404",
                "model": "Stratus 3"
            },
            {
                "regex": "FS504",
                "model": "Cirrus 2"
            },
            {
                "regex": "FS407",
                "model": "Stratus 6"
            },
            {
                "regex": "FS401",
                "model": "Stratus 1"
            },
            {
                "regex": "FS402",
                "model": "Stratus 2"
            },
            {
                "regex": "FS501",
                "model": "Nimbus 3"
            },
            {
                "regex": "FS502",
                "model": "Cirrus 1"
            },
            {
                "regex": "FS507",
                "model": "Cirrus 4"
            },
            {
                "regex": "FS505",
                "model": "Nimbus 7"
            },
            {
                "regex": "FS551",
                "model": "Nimbus 4"
            },
            {
                "regex": "FS514",
                "model": "Cirrus 8"
            },
            {
                "regex": "FS553",
                "model": "Cirrus 9"
            },
            {
                "regex": "FS554",
                "model": "Power Plus FHD"
            },
            {
                "regex": "FS517",
                "model": "Cirrus 11"
            },
            {
                "regex": "FS516",
                "model": "Cirrus 12"
            },
            {
                "regex": "FS518",
                "model": "Cirrus 13"
            },
            {
                "regex": "FS520",
                "model": "Selfie 1"
            },
            {
                "regex": "FS521",
                "model": "Power Plus 1"
            },
            {
                "regex": "FS522",
                "model": "Cirrus 14"
            },
            {
                "regex": "FS523",
                "model": "Cirrus 16"
            },
            {
                "regex": "FS524",
                "model": "Knockout"
            },
            {
                "regex": "FS526",
                "model": "Power Plus 2"
            },
            {
                "regex": "FS527",
                "model": "Nimbus 17"
            },
            {
                "regex": "FS528",
                "model": "Memory Plus"
            },
            {
                "regex": "FS529",
                "model": "Сhamp"
            },
            {
                "regex": "FS530",
                "model": "Power Plus XXL"
            },
            {
                "regex": "FS454",
                "model": "Nimbus 8"
            },
            {
                "regex": "FS452",
                "model": "Nimbus 2"
            },
            {
                "regex": "FS512",
                "model": "Nimbus 10"
            },
            {
                "regex": "FS510",
                "model": "Nimbus 12"
            },
            {
                "regex": "FS451",
                "model": "Nimbus 1"
            },
            {
                "regex": "FS405",
                "model": "Stratus 4"
            },
            {
                "regex": "FS408",
                "model": "Stratus 8"
            },
            {
                "regex": "FS409",
                "model": "Stratus 9"
            },
            {
                "regex": "FS455",
                "model": "Nimbus 11"
            },
            {
                "regex": "FS456",
                "model": "Nimbus 14"
            },
            {
                "regex": "FS457",
                "model": "Nimbus 15"
            },
            {
                "regex": "FS459",
                "model": "Nimbus 16"
            },
            {
                "regex": "FS458",
                "model": "Stratus 7"
            },
            {
                "regex": "(?:Fly_)?IQ237(?:[);/ ]|$)",
                "model": "Dynamic"
            },
            {
                "regex": "(?:Fly_)?IQ238(?:[);/ ]|$)",
                "model": "Jazz"
            },
            {
                "regex": "(?:Fly_)?IQ240(?:[);/ ]|$)",
                "model": "Whizz"
            },
            {
                "regex": "(?:Fly_)?IQ255(?:[);/ ]|$)",
                "model": "Pride"
            },
            {
                "regex": "(?:Fly_)?IQ270(?: Firebird)?(?:[);/ ]|$)",
                "model": "Firebird"
            },
            {
                "regex": "(?:Fly_)?IQ275(?:[);/ ]|$)",
                "model": "Marathon"
            },
            {
                "regex": "(?:Fly_)?IQ285(?: Turbo)?(?:[);/ ]|$)",
                "model": "Turbo"
            },
            {
                "regex": "(?:Fly_)?IQ430(?:[);/ ]|$)",
                "model": "Evoke"
            },
            {
                "regex": "(?:Fly_)?IQ431(?:[);/ ]|$)",
                "model": "Glory"
            },
            {
                "regex": "(?:Fly_)?IQ432(?:[);/ ]|$)",
                "model": "Era Nano 1"
            },
            {
                "regex": "(?:Fly_)?IQ436(?:[);/ ]|$)",
                "model": "Era Nano 3"
            },
            {
                "regex": "(?:Fly_)?IQ4490(?:[);/ ]|$)",
                "model": "Era Nano 4"
            },
            {
                "regex": "(?:Fly_)?IQ434(?:[);/ ]|$)",
                "model": "Era Nano 5"
            },
            {
                "regex": "(?:Fly_)?IQ4406(?:[);/ ]|$)",
                "model": "Era Nano 6"
            },
            {
                "regex": "(?:Fly_)?IQ436i(?:[);/ ]|$)",
                "model": "Era Nano 9"
            },
            {
                "regex": "(?:Fly_)?IQ440(?:[);/ ]|$)",
                "model": "Energy"
            },
            {
                "regex": "(?:Fly_)?IQ4401(?:[);/ ]|$)",
                "model": "Era Energy 2"
            },
            {
                "regex": "(?:Fly_)?IQ441(?:[);/ ]|$)",
                "model": "Radiance"
            },
            {
                "regex": "(?:Fly_)?IQ442 Quad(?:[);/ ]|$)",
                "model": "Miracle 2"
            },
            {
                "regex": "(?:Fly_)?IQ442(?:[);/ ]|$)",
                "model": "Miracle"
            },
            {
                "regex": "(?:Fly_)?IQ443(?:[);/ ]|$)",
                "model": "Trend"
            },
            {
                "regex": "(?:Fly_)?IQ444(?: Quattro)?(?:[);/ ]|$)",
                "model": "Diamond 2"
            },
            {
                "regex": "(?:Fly_)?IQ445(?:[);/ ]|$)",
                "model": "Genius"
            },
            {
                "regex": "(?:Fly_)?IQ446(?: Magic)?(?:[);/ ]|$)",
                "model": "Magic"
            },
            {
                "regex": "(?:Fly_)?IQ447(?:[);/ ]|$)",
                "model": "Era Life 1"
            },
            {
                "regex": "(?:Fly_)?IQ456(?:[);/ ]|$)",
                "model": "Era Life 2"
            },
            {
                "regex": "(?:Fly_)?IQ4409 Quad(?:[);/ ]|$)",
                "model": "Era Life 4"
            },
            {
                "regex": "(?:Fly_)?IQ448(?:[);/ ]|$)",
                "model": "Chic"
            },
            {
                "regex": "(?:Fly_)?IQ449(?:[);/ ]|$)",
                "model": "Pronto"
            },
            {
                "regex": "(?:Fly_)?IQ450(?:[);/ ]|$)",
                "model": "Horizon"
            },
            {
                "regex": "(?:Fly_)?IQ450[ _]Quattro(?:[);/ ]|$)",
                "model": "Horizon 2"
            },
            {
                "regex": "(?:Fly_)?IQ451(?:[);/ ]|$)",
                "model": "Vista"
            },
            {
                "regex": "(?:Fly_)?IQ452 Quad(?:[);/ ]|$)",
                "model": "Ego Vision 1"
            },
            {
                "regex": "(?:Fly_)?IQ454(?:[);/ ]|$)",
                "model": "Evo Tech 1"
            },
            {
                "regex": "(?:Fly_)?IQ4403(?:[);/ ]|$)",
                "model": "Energy 3"
            },
            {
                "regex": "(?:Fly_)?IQ4404(?:[);/ ]|$)",
                "model": "Spark"
            },
            {
                "regex": "(?:Fly_)?IQ4410 Quad(?:[);/ ]|$)",
                "model": "Phoenix"
            },
            {
                "regex": "(?:Fly_)?IQ4411(?:[);/ ]|$)",
                "model": "Energy 2"
            },
            {
                "regex": "(?:Fly_)?IQ4412 Quad(?:[);/ ]|$)",
                "model": "Coral"
            },
            {
                "regex": "(?:Fly_)?IQ4413[ _]Quad(?:[);/ ]|$)",
                "model": "Evo Chic 3"
            },
            {
                "regex": "(?:Fly_)?IQ4414 Quad(?:[);/ ]|$)",
                "model": "Evo Tech 3"
            },
            {
                "regex": "(?:Fly_)?IQ4415 Quad(?:[);/ ]|$)",
                "model": "Era Style 3"
            },
            {
                "regex": "(?:Fly_)?IQ4504 Quad(?:[);/ ]|$)",
                "model": "Evo Energy 5"
            },
            {
                "regex": "(?:Fly_)?IQ([0-9]+[i]? ?(?:Quad|Firebird|Quattro)?)(?:[);/ ]|$)",
                "model": "IQ$1"
            },
            {
                "regex": "Fly[ _\\-]?([a-z0-9_]+)/",
                "model": "$1",
                "device": "feature phone"
            },
            {
                "regex": "Flylife[ _\\-]?([^/;]+) Build",
                "model": "Flylife $1",
                "device": "tablet"
            },
            {
                "regex": "Fly[ _\\-]?([a-z0-9]+)",
                "model": "$1"
            },
            {
                "regex": "MERIDIAN-([a-z0-9]+)",
                "model": "$1"
            }
        ]
    },
    "FinePower": {
        "regex": "FinePower[_ ]?([AB][1-9]|[CD][1-9])(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "FinePower[_ ]?([AB][1-9])(?:[);/ ]|$)",
                "model": "$1",
                "device": "tablet"
            },
            {
                "regex": "FinePower[_ ]?([CD][1-9])(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Freetel": {
        "regex": "(?:FTJ17[AC]00|FTJ152[ABCD]|FTU18A00|FT141B|FT142D(?:_LTEXM)?|FT142A?|FTJ161[AB]|FTJ1[56]2E|FTJ162[BD]|FTE161[GE])(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "FTJ152C",
                "model": "Samurai Miyabi"
            },
            {
                "regex": "FTJ17A00(?:[);/ ]|$)",
                "model": "Rei 2 Dual"
            },
            {
                "regex": "FTJ17C00(?:[);/ ]|$)",
                "model": "Priori 5"
            },
            {
                "regex": "FTU18A00(?:[);/ ]|$)",
                "model": "Cricket Wave"
            },
            {
                "regex": "FTJ161A",
                "model": "Musashi"
            },
            {
                "regex": "FTE161E",
                "model": "Ice 2"
            },
            {
                "regex": "FTE161G",
                "model": "Ice 2 Plus"
            },
            {
                "regex": "FTJ162E",
                "model": "Raijin"
            },
            {
                "regex": "FT141B",
                "model": "Nico"
            },
            {
                "regex": "FT142D(?:_LTEXM)?(?:[);/ ]|$)",
                "model": "XM"
            },
            {
                "regex": "FT142A?",
                "model": "Priori 2"
            },
            {
                "regex": "FTJ152A",
                "model": "Priori 3"
            },
            {
                "regex": "FTJ162D",
                "model": "Priori 4"
            },
            {
                "regex": "FTJ152B",
                "model": "Priori 3S LTE"
            },
            {
                "regex": "FTJ152D",
                "model": "Samurai Kiwami"
            },
            {
                "regex": "FTJ162B",
                "model": "Samurai Kiwami 2"
            },
            {
                "regex": "FTJ161B",
                "model": "Samurai Rei"
            },
            {
                "regex": "FTJ152E",
                "model": "Katana 1"
            }
        ]
    },
    "Western Digital": {
        "regex": "Westerndigital|WDSimpleBrowser",
        "device": "tv",
        "models": [
            {
                "regex": "Streamer2010",
                "model": "Streamer (2010)"
            }
        ]
    },
    "Zeemi": {
        "regex": "ZEEMI[ _-]",
        "device": "smartphone",
        "models": [
            {
                "regex": "ZEEMI[ _-]([^/;]+) Build",
                "model": "$1"
            },
            {
                "regex": "ZEEMI[ _-]([^/;)]+)(?:[);/]|$)",
                "model": "$1"
            }
        ]
    },
    "Zenek": {
        "regex": "Z5007|Z551[679]|Z6001",
        "device": "smartphone",
        "models": [
            {
                "regex": "Z5517",
                "model": "Leopardo"
            },
            {
                "regex": "Z6001",
                "model": "Libelula"
            },
            {
                "regex": "Z5007",
                "model": "Oso"
            },
            {
                "regex": "Z5519",
                "model": "Pingûino"
            },
            {
                "regex": "Z5516",
                "model": "Zebra"
            }
        ]
    },
    "Zopo": {
        "regex": "ZOPO|(?:ZOPO[_ ])?(Flash X3|ZP[0-9]{2,}[^/;]+)(?:\\)| Build)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(?:ZOPO_C2_MOD|ZP998)(?:[);/ ]|$)",
                "model": "C2 II"
            },
            {
                "regex": "ZOPO 9xxQuad(?:[);/ ]|$)",
                "model": "9XX Quad"
            },
            {
                "regex": "(?:ZOPO_)?ZP980[+](?:[);/ ]|$)",
                "model": "ZP980+"
            },
            {
                "regex": "(?:ZOPO_)?ZP980(?:[);/ ]|$)",
                "model": "Scorpio"
            },
            {
                "regex": "ZP952(?:[);/ ]|$)",
                "model": "Speed 7 Plus"
            },
            {
                "regex": "ZP951(?:[);/ ]|$)",
                "model": "Speed 7"
            },
            {
                "regex": "ZP955(?:[);/ ]|$)",
                "model": "Speed 8"
            },
            {
                "regex": "ZP950[+h]?(?:[);/ ]|$)",
                "model": "Leader Max"
            },
            {
                "regex": "ZP(?:900H?|910)(?:[);/ ]|$)",
                "model": "Leader"
            },
            {
                "regex": "ZP(?:800H|810)(?:[);/ ]|$)",
                "model": "Libero HD"
            },
            {
                "regex": "ZP500[+]?(?:[);/ ]|$)",
                "model": "Libero"
            },
            {
                "regex": "ZP330(?:[);/ ]|$)",
                "model": "Color C"
            },
            {
                "regex": "ZP370(?:[);/ ]|$)",
                "model": "Color S"
            },
            {
                "regex": "ZP5(63|86)(?:[);/ ]|$)",
                "model": "Color X"
            },
            {
                "regex": "ZP567(?:[);/ ]|$)",
                "model": "Color C5i"
            },
            {
                "regex": "ZP300[S+]?(?:[);/ ]|$)",
                "model": "Field"
            },
            {
                "regex": "ZP200[+]?(?:[);/ ]|$)",
                "model": "Shining"
            },
            {
                "regex": "ZP100(?:[);/ ]|$)",
                "model": "Pilot"
            },
            {
                "regex": "ZP1790(?:[);/ ]|$)",
                "model": "Flash X2"
            },
            {
                "regex": "ZP781(?:[);/ ]|$)",
                "model": "Flash G5 Plus"
            },
            {
                "regex": "(?:ZOPO_)?(Flash X3|X1i|ZP[0-9]{2,}[^/;)]+)(?: Build|\\))",
                "model": "$1"
            }
        ]
    },
    "ZTE": {
        "regex": "ZTE|AxonPhone|([a-z0-9]+)_USA_Cricket|(?:Blade (?:V0820|L110|L[2357]|L7A|S6|V[6789]|V8Q|V8 SE|V9 VITA|X7|A(310|460|465|475|520|530|602)|V580|V Ultra|Q Lux)|N9[15]8St|NX(?:403A|404H|406E|40[X2]|507J|503A|505J|506J|508J|510J|512J|511J|513J|521J|523J_V1|529J|531J|541J|5[48]9J|551J|563J|569[HJ]|573J|575J|59[157]J|60[1689]J|61[12679]J|62[79]J|65[19]J|907J)|N818S|N951[0579]|N91[38]0|N9101|N913[267]|N952[01]|N9560|N9810|N799D|[UV]9180|[UV]9815|Z(?:233V|331|5031O|559DL|6621O|667T|768G|792|81[25]|818L|82[08]|83[12359]|85[125]|851M|992|(557|71[67]|798|836|861|916)BL|986DL|(232|718|828)TL|(?:233|353|558|717|799|837|862|899|917|963)VL|955A|95[678]|965|97[018]|98[1237]|986U|999|936L)|V769M|Apex2|G (LTE|Lux)|Vec|Z959|V865M|Z3001S|902ZT|KIS II Max|Kis 3|K3DX-V5G|Z930L|Optus X|Blade[ _](A510|A[357] 2019-T|A6 MAX|A0622|X9)|Z3153V|Z836F|Nubia Z(?:9|11)|Ctyon-A9|602ZT|MO-01[JK]|Z963U|402ZT|P545|P609|dandelion|901ZT|A001ZT|Z-01K|UZTE V808|ZR01|ZTG01|K8[37]CA)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "602ZT(?:[);/ ]|$)",
                "model": "Libero 2"
            },
            {
                "regex": "A001ZT(?:[);/ ]|$)",
                "model": "Libero 3"
            },
            {
                "regex": "901ZT(?:[);/ ]|$)",
                "model": "Libero S10"
            },
            {
                "regex": "ZR01(?:[);/ ]|$)",
                "model": "Rakuten Big"
            },
            {
                "regex": "ZTG01(?:[);/ ]|$)",
                "model": "A1"
            },
            {
                "regex": "A7020(?:[);/ ]|$)",
                "model": "Blade A7s (2020)"
            },
            {
                "regex": "ZTE 2050(?:RU)?",
                "model": "Blade 20 Smart"
            },
            {
                "regex": "902ZT(?:[);/ ]|$)",
                "model": "Axon 10 Pro 5G"
            },
            {
                "regex": "N799D(?:[);/ ]|$)",
                "model": "Blade Eg"
            },
            {
                "regex": "Z818L(?:[);/ ]|$)",
                "model": "Allstar"
            },
            {
                "regex": "Z3001S(?:[);/ ]|$)",
                "model": "Quest Plus"
            },
            {
                "regex": "Ctyon-A9(?:[);/ ]|$)",
                "model": "Ctyon A9"
            },
            {
                "regex": "K3DX-V5G(?:[);/ ]|$)",
                "model": "V5G"
            },
            {
                "regex": "N918St(?:[);/ ]|$)",
                "model": "V5S"
            },
            {
                "regex": "N958St(?:[);/ ]|$)",
                "model": "V5 Max"
            },
            {
                "regex": "N9101(?:[);/ ]|$)",
                "model": "Imperial"
            },
            {
                "regex": "Z963U(?:[);/ ]|$)",
                "model": "Imperial Max"
            },
            {
                "regex": "N9120(?:[);/ ]|$)",
                "model": "Avid"
            },
            {
                "regex": "N9130(?:[);/ ]|$)",
                "model": "Speed"
            },
            {
                "regex": "N9132(?:[);/ ]|$)",
                "model": "Prestige"
            },
            {
                "regex": "N9136(?:[);/ ]|$)",
                "model": "Prestige 2"
            },
            {
                "regex": "N9137(?:[);/ ]|$)",
                "model": "Tempo X"
            },
            {
                "regex": "N9180(?:[);/ ]|$)",
                "model": "V5"
            },
            {
                "regex": "N9510(?:[);/ ]|$)",
                "model": "Warp 4G"
            },
            {
                "regex": "N9517(?:[);/ ]|$)",
                "model": "Blade Force"
            },
            {
                "regex": "Z559DL(?:[);/ ]|$)",
                "model": "Blade T2"
            },
            {
                "regex": "402ZT(?:[);/ ]|$)",
                "model": "Blade Q+"
            },
            {
                "regex": "Blade Q Lux(?:[);/ ]|$)",
                "model": "Blade Q Lux"
            },
            {
                "regex": "N9515(?:[);/ ]|$)",
                "model": "Warp Sync"
            },
            {
                "regex": "N9519(?:[);/ ]|$)",
                "model": "Warp 7"
            },
            {
                "regex": "N9520(?:[);/ ]|$)",
                "model": "Boost Max"
            },
            {
                "regex": "N9521(?:[);/ ]|$)",
                "model": "Boost Max+"
            },
            {
                "regex": "N9560(?:[);/ ]|$)",
                "model": "Max XL"
            },
            {
                "regex": "N9810(?:[);/ ]|$)",
                "model": "Vital"
            },
            {
                "regex": "NX40[X2](?:[);/ ]|$)",
                "model": "Nubia Z5 Mini"
            },
            {
                "regex": "NX(403[AE]|406E|404H)(?:[);/ ]|$)",
                "model": "Nubia Z5S Mini"
            },
            {
                "regex": "NX503A(?:[);/ ]|$)",
                "model": "Nubia Z5S"
            },
            {
                "regex": "NX506J(?:[);/ ]|$)",
                "model": "Nubia Z7"
            },
            {
                "regex": "NX507J(?:[);/ ]|$)",
                "model": "Nubia Z7 Mini"
            },
            {
                "regex": "NX505J(?:[);/ ]|$)",
                "model": "Nubia Z7 Max"
            },
            {
                "regex": "NX508J(?:[);/ ]|$)",
                "model": "Nubia Z9"
            },
            {
                "regex": "(NX5[12]1J|Nubia Z9 mini)(?:[);/ ]|$)",
                "model": "Nubia Z9 Mini"
            },
            {
                "regex": "(NX510J|Nubia Z9 Max)(?:[);/ ]|$)",
                "model": "Nubia Z9 Max"
            },
            {
                "regex": "NX512J(?:[);/ ]|$)",
                "model": "Nubia Z9 Max Dual"
            },
            {
                "regex": "NX531J(?:[);/ ]|$)",
                "model": "Nubia Z11"
            },
            {
                "regex": "NX523J_V1",
                "model": "Nubia Z11 Max"
            },
            {
                "regex": "(?:NX549J|Nubia Z11 mini S)(?:[);/ ]|$)",
                "model": "Nubia Z11 Mini S"
            },
            {
                "regex": "(?:NX529J|Nubia Z11 mini)(?:[);/ ]|$)",
                "model": "Nubia Z11 Mini"
            },
            {
                "regex": "NX606J(?:[);/ ]|$)",
                "model": "Nubia Z18"
            },
            {
                "regex": "NX616J(?:[);/ ]|$)",
                "model": "Nubia Z18S"
            },
            {
                "regex": "NX611J(?:[);/ ]|$)",
                "model": "Nubia Z18 Mini"
            },
            {
                "regex": "NX551J(?:[);/ ]|$)",
                "model": "Nubia M2"
            },
            {
                "regex": "NX563J(?:[);/ ]|$)",
                "model": "Nubia Z17"
            },
            {
                "regex": "NX569[HJ](?:[);/ ]|$)",
                "model": "Nubia Z17 Mini"
            },
            {
                "regex": "NX589J(?:[);/ ]|$)",
                "model": "Nubia Z17 Mini S"
            },
            {
                "regex": "NX591J(?:[);/ ]|$)",
                "model": "Nubia Z17 Lite"
            },
            {
                "regex": "NX595J(?:[);/ ]|$)",
                "model": "Nubia Z17S"
            },
            {
                "regex": "NX612J(?:[);/ ]|$)",
                "model": "Nubia V18"
            },
            {
                "regex": "NX627J",
                "model": "Nubia Z20"
            },
            {
                "regex": "NX513J(?:[);/ ]|$)",
                "model": "Nubia My Prague"
            },
            {
                "regex": "NX601J(?:[);/ ]|$)",
                "model": "Nubia X6"
            },
            {
                "regex": "NX609J(?:[);/ ]|$)",
                "model": "Nubia Red Magic"
            },
            {
                "regex": "NX659J(?:[);/ ]|$)",
                "model": "Nubia Red Magic 5G"
            },
            {
                "regex": "NX651J(?:[);/ ]|$)",
                "model": "Nubia Play"
            },
            {
                "regex": "NX619J(?:[);/ ]|$)",
                "model": "Nubia Red Magic Mars"
            },
            {
                "regex": "NX629J(?:[);/ ]|$)",
                "model": "Nubia Red Magic 3"
            },
            {
                "regex": "NX573J",
                "model": "Nubia M2 Lite"
            },
            {
                "regex": "NX907J(?:[);/ ]|$)",
                "model": "Nubia M2 Play"
            },
            {
                "regex": "NX541J",
                "model": "Nubia N1"
            },
            {
                "regex": "NX597J",
                "model": "Nubia N1 Lite"
            },
            {
                "regex": "NX575J",
                "model": "Nubia N2"
            },
            {
                "regex": "NX6(08|17)J(?:[);/ ]|$)",
                "model": "Nubia N3"
            },
            {
                "regex": "[UV]9180(?:[);/ ]|$)",
                "model": "V5 Red Bull"
            },
            {
                "regex": "[UV]9815(?:[);/ ]|$)",
                "model": "Grand Memo LTE"
            },
            {
                "regex": "V779M",
                "model": "Joey Jump 2"
            },
            {
                "regex": "V807",
                "model": "Blade C"
            },
            {
                "regex": "V809",
                "model": "Blade C2"
            },
            {
                "regex": "ZTE_Blade_C2_Plus",
                "model": "Blade C2 Plus"
            },
            {
                "regex": "Blade G Plus",
                "model": "Blade G Plus"
            },
            {
                "regex": "ZTE_Blade_III",
                "model": "Blade III"
            },
            {
                "regex": "(?:ZTE_)?V829",
                "model": "Blade G Pro"
            },
            {
                "regex": "V882",
                "model": "Lord"
            },
            {
                "regex": "V967S",
                "model": "Grand X2"
            },
            {
                "regex": "V970M?|V987",
                "model": "Grand X"
            },
            {
                "regex": "Z959(?:[);/ ]|$)",
                "model": "Grand X3"
            },
            {
                "regex": "V8000",
                "model": "Nova 4"
            },
            {
                "regex": "Z95[67]",
                "model": "Grand X4"
            },
            {
                "regex": "Z987",
                "model": "Grand X Max+"
            },
            {
                "regex": "(?:ZTE_)?Grand[ _]Era",
                "model": "Grand Era"
            },
            {
                "regex": "V788D",
                "model": "Kis Plus"
            },
            {
                "regex": "Z331(?:[);/ ]|$)",
                "model": "Z331"
            },
            {
                "regex": "N818S(?:[);/ ]|$)",
                "model": "N818S"
            },
            {
                "regex": "Z5031O(?:[);/ ]|$)",
                "model": "Optus X Spirit 2"
            },
            {
                "regex": "P545(?:[);/ ]|$)",
                "model": "Optus X Power 2"
            },
            {
                "regex": "P609(?:[);/ ]|$)",
                "model": "Optus X Wave"
            },
            {
                "regex": "Optus X Start(?:[);/ ]|$)",
                "model": "Optus X Start"
            },
            {
                "regex": "BA520(?:[);/ ]|$)",
                "model": "Blade A520"
            },
            {
                "regex": "Blade (A(0620|310|321|452|460|462|465|475|510|512|52[012]|530|531|602|612|910)|L[12]10|L130|V0730|V0800|V580|V1000)(?:[);/ ]|$)",
                "model": "Blade $1"
            },
            {
                "regex": "Blade L2 Plus(?:[);/ ]|$)",
                "model": "Blade L2 Plus"
            },
            {
                "regex": "(?:ZTE_)?Blade[ _]L2(?:[);/ ]|$)",
                "model": "Blade L2"
            },
            {
                "regex": "Blade L3 Plus(?:[);/ ]|$)",
                "model": "Blade L3 Plus"
            },
            {
                "regex": "Blade L3 Apex(?:[);/ ]|$)",
                "model": "Blade L3 Apex"
            },
            {
                "regex": "Blade L3(?:[);/ ]|$)",
                "model": "Blade L3"
            },
            {
                "regex": "Blade L5 Plus(?:[);/ ]|$)",
                "model": "Blade L5 Plus"
            },
            {
                "regex": "Blade L5(?:[);/ ]|$)",
                "model": "Blade L5"
            },
            {
                "regex": "Blade L6(?:[);/ ]|$)",
                "model": "Blade L6"
            },
            {
                "regex": "Blade L7A(?:[);/ ]|$)",
                "model": "Blade L7A"
            },
            {
                "regex": "Blade L7(?:[);/ ]|$)",
                "model": "Blade L7"
            },
            {
                "regex": "Blade L8(?:RU)?(?:[);/ ]|$)",
                "model": "Blade L8"
            },
            {
                "regex": "Blade A([357]) (20(?:19|20))",
                "model": "Blade A$1 ($2)"
            },
            {
                "regex": "Blade A3 Lite(?:[);/ ]|$)",
                "model": "Blade A3 Lite"
            },
            {
                "regex": "BLADE A0622(?:[);/ ]|$)",
                "model": "Blade A6"
            },
            {
                "regex": "BLADE A6 MAX(?:[);/ ]|$)",
                "model": "Blade A6 Max"
            },
            {
                "regex": "Blade S6 Plus(?:[);/ ]|$)",
                "model": "Blade S6 Plus"
            },
            {
                "regex": "Blade S6(?:[);/ ]|$)",
                "model": "Blade S6"
            },
            {
                "regex": "T920(?:[);/ ]|$)",
                "model": "Blade S7"
            },
            {
                "regex": "BLADE V Ultra(?:[);/ ]|$)",
                "model": "Blade V Ultra"
            },
            {
                "regex": "Blade V6 MAX(?:[);/ ]|$)",
                "model": "Blade V6 Max"
            },
            {
                "regex": "Blade V6 Plus(?:[);/ ]|$)",
                "model": "Blade V6 Plus"
            },
            {
                "regex": "Blade (?:V7 Lite|V0720)(?:[);/ ]|$)",
                "model": "Blade V7 Lite"
            },
            {
                "regex": "Blade V([67])(?:[);/ ]|$)",
                "model": "Blade V$1"
            },
            {
                "regex": "Blade X([79])(?:[);/ ]|$)",
                "model": "Blade X$1"
            },
            {
                "regex": "Blade V8Q(?:[);/ ]|$)",
                "model": "Blade V8Q"
            },
            {
                "regex": "Blade V8 SE(?:[);/ ]|$)",
                "model": "Blade V8 SE"
            },
            {
                "regex": "Blade V(9|10) Vita(?:[);/ ]|$)",
                "model": "Blade V$1 Vita"
            },
            {
                "regex": "Blade V([89]|10)(?:[);/ ]|$)",
                "model": "Blade V$1"
            },
            {
                "regex": "BLADE V0820(?:[);/ ]|$)",
                "model": "Blade V8 Lite"
            },
            {
                "regex": "BLADE V0850(?:[);/ ]|$)",
                "model": "Blade V8 Mini"
            },
            {
                "regex": "(?:Blade[ _])?(Apex2|G (LTE|Lux)|Vec|A510)",
                "model": "Blade $1"
            },
            {
                "regex": "Z768G(?:[);/ ]|$)",
                "model": "Midnight"
            },
            {
                "regex": "Z820",
                "model": "Obsidian"
            },
            {
                "regex": "Z986U",
                "model": "Blade Max 3"
            },
            {
                "regex": "Z971",
                "model": "Blade Spark"
            },
            {
                "regex": "Z978",
                "model": "Blade V8 Pro"
            },
            {
                "regex": "Z839",
                "model": "Blade Vantage"
            },
            {
                "regex": "Z3153V",
                "model": "Blade Vantage 2"
            },
            {
                "regex": "Z930L",
                "model": "Unico LTE"
            },
            {
                "regex": "Z965",
                "model": "Blade X"
            },
            {
                "regex": "Z981",
                "model": "Blade Z Max Pro"
            },
            {
                "regex": "Z982",
                "model": "Blade Z Max"
            },
            {
                "regex": "Z983",
                "model": "Blade X Max"
            },
            {
                "regex": "Z717[BV]L(?:[);/ ]|$)",
                "model": "Citrine LTE"
            },
            {
                "regex": "Z233VL(?:[);/ ]|$)",
                "model": "Cymbal-C LTE"
            },
            {
                "regex": "Z233V",
                "model": "Cymbal LTE"
            },
            {
                "regex": "Z232TL",
                "model": "Cymbal-G LTE"
            },
            {
                "regex": "Z353VL",
                "model": "Cymbal-T LTE"
            },
            {
                "regex": "Z718TL",
                "model": "Jasper LTE"
            },
            {
                "regex": "KIS II Max",
                "model": "Kis II Max"
            },
            {
                "regex": "Kis 3",
                "model": "Kis 3"
            },
            {
                "regex": "(?:Z798BL|Z799VL)(?:[);/ ]|$)",
                "model": "Majesty Pro LTE"
            },
            {
                "regex": "Z899VL",
                "model": "Majesty Pro Plus LTE"
            },
            {
                "regex": "Z828TL",
                "model": "Midnight Pro LTE"
            },
            {
                "regex": "(?:ZTE-)?Z792",
                "model": "Fanfare"
            },
            {
                "regex": "(?:ZTE-)?Z812",
                "model": "Maven"
            },
            {
                "regex": "Z815",
                "model": "Fanfare 2"
            },
            {
                "regex": "Z831",
                "model": "Maven 2"
            },
            {
                "regex": "Z832",
                "model": "Sonata 3"
            },
            {
                "regex": "Z835",
                "model": "Maven 3"
            },
            {
                "regex": "Z851(?:[);/ ]|$)",
                "model": "Prelude Plus"
            },
            {
                "regex": "Z851M",
                "model": "Overture 3"
            },
            {
                "regex": "Z852",
                "model": "Fanfare 3"
            },
            {
                "regex": "Z828",
                "model": "Avid Plus"
            },
            {
                "regex": "Z855",
                "model": "Avid 4"
            },
            {
                "regex": "Z833",
                "model": "Avid TRIO"
            },
            {
                "regex": "(?:Z836BL|Z837VL|Z836F)(?:[);/ ]|$)",
                "model": "ZFive 2 LTE"
            },
            {
                "regex": "Z558VL",
                "model": "ZFive C LTE"
            },
            {
                "regex": "Z557BL",
                "model": "ZFive G LTE"
            },
            {
                "regex": "(?:Z861BL|Z862VL)(?:[);/ ]|$)",
                "model": "ZFive L LTE"
            },
            {
                "regex": "Z916BL",
                "model": "ZMax Grand LTE"
            },
            {
                "regex": "Z917VL",
                "model": "ZMax Champ LTE"
            },
            {
                "regex": "(?:Z958|Z955A)(?:[);/ ]|$)",
                "model": "ZMax 2"
            },
            {
                "regex": "dandelion(?:[);/ ]|$)",
                "model": "ZMax 3"
            },
            {
                "regex": "Z963VL",
                "model": "Max Duo LTE"
            },
            {
                "regex": "Z970",
                "model": "ZMax Z970"
            },
            {
                "regex": "Z986DL",
                "model": "MAX Blue LTE"
            },
            {
                "regex": "(?:ZTE[-_])?Z992",
                "model": "Avail 2"
            },
            {
                "regex": "(?:Z999|Z-01K)(?:[);/ ]|$)",
                "model": "Axon M"
            },
            {
                "regex": "B2016",
                "model": "Axon Mini"
            },
            {
                "regex": "A2015",
                "model": "Axon Tianji"
            },
            {
                "regex": "A2017[GU]?",
                "model": "Axon 7"
            },
            {
                "regex": "A2018",
                "model": "Axon 7s"
            },
            {
                "regex": "A2019G Pro",
                "model": "Axon Pro"
            },
            {
                "regex": "B2017G",
                "model": "Axon 7 Mini"
            },
            {
                "regex": "A2020G? Pro(?:[);/ ]|$)",
                "model": "Axon 10 Pro"
            },
            {
                "regex": "Z667T",
                "model": "Zinger"
            },
            {
                "regex": "V?975|geek",
                "model": "Geek"
            },
            {
                "regex": "Z716BL",
                "model": "Citrine LTE"
            },
            {
                "regex": "X500",
                "model": "Score"
            },
            {
                "regex": "X501",
                "model": "Groove"
            },
            {
                "regex": "(?:ZTE-)?G-X991",
                "model": "Rio"
            },
            {
                "regex": "F-450",
                "model": "Adamant"
            },
            {
                "regex": "Z936L(?:[);/ ]|$)",
                "model": "Lever"
            },
            {
                "regex": "MO-01[JK](?:[);/ ]|$)",
                "model": "Mono"
            },
            {
                "regex": "V865M(?:[);/ ]|$)",
                "model": "V865M"
            },
            {
                "regex": "Z6621O(?:[);/ ]|$)",
                "model": "Optus X Vista"
            },
            {
                "regex": "V769M(?:[);/ ]|$)",
                "model": "Leo Q2"
            },
            {
                "regex": "K83CA(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Grand X View 3"
            },
            {
                "regex": "K87CA(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Grand X View 4"
            },
            {
                "regex": "AxonPhone ([^;/]+) Build",
                "model": "AxonPhone $1"
            },
            {
                "regex": "([a-z0-9]+)_USA_Cricket",
                "model": "$1"
            },
            {
                "regex": "ZTE[- ](V98|V96A|V81|V70)(?:[);/ ]|$)",
                "model": "$1",
                "device": "tablet"
            },
            {
                "regex": "ZTE[- ]([a-z0-9\\-_ ]+) Build",
                "model": "$1"
            },
            {
                "regex": "ZTE-(?:G |G-)?([a-z0-9 _]+)",
                "model": "$1"
            },
            {
                "regex": "U?ZTE[ _]([a-z0-9]+)",
                "model": "$1"
            }
        ]
    },
    "Zuum": {
        "regex": "Zuum[ _-]|(?:Akus Z|COVET|MAGNO|ONIX S|STEDI|STELLAR(?:[ _](?:Z|PRO|[MP]1))?|GRAVITY Z|AURA X|COVET_PRO_LITE|RONI)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Akus Z",
                "model": "Akus Z"
            },
            {
                "regex": "COVET_PRO_LITE",
                "model": "Covet Pro Lite"
            },
            {
                "regex": "COVET PRO",
                "model": "Covet Pro"
            },
            {
                "regex": "COVET( Z)?",
                "model": "Covet$1"
            },
            {
                "regex": "MAGNO PLUS",
                "model": "Magno Plus"
            },
            {
                "regex": "MAGNO( C)?",
                "model": "Magno$1"
            },
            {
                "regex": "ONIX S",
                "model": "Onix S"
            },
            {
                "regex": "STEDI",
                "model": "Stedi"
            },
            {
                "regex": "STELLAR PRO",
                "model": "Stellar Pro"
            },
            {
                "regex": "STELLAR PLUS",
                "model": "Stellar Plus"
            },
            {
                "regex": "STELLAR ([MP]1|Z)",
                "model": "Stellar $1"
            },
            {
                "regex": "STELLAR MINI",
                "model": "Stellar Mini"
            },
            {
                "regex": "STELLAR MINI",
                "model": "Stellar Mini"
            },
            {
                "regex": "STELLAR",
                "model": "Stellar"
            },
            {
                "regex": "GRAVITY Z",
                "model": "Gravity Z"
            },
            {
                "regex": "AURA X",
                "model": "Aura X"
            },
            {
                "regex": "RONI",
                "model": "Roni"
            },
            {
                "regex": "Zuum[ _-]([^;/]+) Build",
                "model": "$1"
            },
            {
                "regex": "Zuum[ _-]?([^;/)]+)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Zen": {
        "regex": "(Admire[_ ][^/;]+|Cinemax[^/;)]+)(?:Build|\\))|AdmireGlam",
        "device": "smartphone",
        "models": [
            {
                "regex": "AdmireGlam(?:[);/ ]|$)",
                "model": "Admire Glam"
            },
            {
                "regex": "(Admire[_ ][^/;]+|Cinemax[^/;)]+)(?:Build|\\))",
                "model": "$1"
            }
        ]
    },
    "Zync": {
        "regex": "ZYNC|(Cloud Z5|Z1000|Z18|Z99|Z99[_ ][23]G|Z99|Z900[_ ]Plus|Z909|Z930[+]|Z930)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "(Z18| DuZ99al Core|Z99[_ ][23]G|Z99 Dual Core|Z99|Z900[_ ]Plus|Z909|Z930[+]|Z930)",
                "model": "$1"
            },
            {
                "regex": "(Z1000[^/;]+)Build",
                "model": "$1"
            },
            {
                "regex": "Cloud (Z5)",
                "model": "Cloud $1"
            },
            {
                "regex": "ZYNC Cloud[ _]([^;]+)Build",
                "model": "Cloud $1"
            },
            {
                "regex": "ZYNC[ _]([^;]+)Build",
                "model": "$1"
            }
        ]
    },
    "Lemhoov": {
        "regex": "Lemhoov",
        "device": "smartphone",
        "models": [
            {
                "regex": "Lemhoov[ _-]([^/;]+) Build",
                "model": "$1"
            }
        ]
    },
    "MTC": {
        "regex": "MTC[ _](968|97[82]|970|982[OT]?|1078)|(?:MTC[_ ])?SMART[ _]?(?:Race[2]?|Sprint|Run|Surf[2]?)[ _]4G|MTC975|Smart_(?:LINE|Light)|MTC[ _]SMART[ _](?:Run|Start_2)|Redbox Mini 5\\(MTS\\)",
        "device": "smartphone",
        "models": [
            {
                "regex": "MTC[ _]?(97[258]|970[H]?|982[OT]?|968)",
                "model": "$1"
            },
            {
                "regex": "MTC[ _](1078)",
                "model": "$1",
                "device": "tablet"
            },
            {
                "regex": "SMART[ _]Race[ _]4G",
                "model": "Smart Race 4G"
            },
            {
                "regex": "SMART[ _]Race2[ _]4G",
                "model": "Smart Race 2 4G"
            },
            {
                "regex": "(?:MTC_)?SMART[ _]Sprint[ _]4G",
                "model": "Smart Sprint 4G"
            },
            {
                "regex": "(?:MTC_)?SMART[ _]Start_2",
                "model": "Smart Start 2"
            },
            {
                "regex": "Smart_LINE",
                "model": "Smart Line"
            },
            {
                "regex": "Smart_Light",
                "model": "Smart Light"
            },
            {
                "regex": "SMART[ _]?Run(?:[ _]4G)?",
                "model": "Smart Run 4G"
            },
            {
                "regex": "SMART[ _]Surf2[ _]4G",
                "model": "Smart Surf 2 4G"
            },
            {
                "regex": "SMART[ _]Surf[ _]4G",
                "model": "Smart Surf 4G"
            },
            {
                "regex": "Redbox Mini 5\\(MTS\\)",
                "device": "tv",
                "model": "Redbox Mini 5"
            }
        ]
    },
    "MegaFon": {
        "regex": "MegaFon|MFLoginPh|MegLogPh|(MFLogin[34]T?|MS3B|SP-A5|SP-A20i)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "MFLoginPh|MegLogPh",
                "model": "Login+"
            },
            {
                "regex": "MFLogin4",
                "model": "Login 4 LTE"
            },
            {
                "regex": "MFLogin3T|MegaFon Login 3",
                "model": "Login 3",
                "device": "tablet"
            },
            {
                "regex": "MS3B|Megafon Optima",
                "model": "Optima"
            },
            {
                "regex": "SP-A20i",
                "model": "Mint"
            },
            {
                "regex": "(SP-A5)",
                "model": "$1"
            }
        ]
    },
    "Inoi": {
        "regex": "INOI",
        "device": "smartphone",
        "models": [
            {
                "regex": "INOI 2 Lite 2019",
                "model": "2 Lite (2019)"
            },
            {
                "regex": "INOI ([257]) (2019|202[01])(?:[);/ ]|$)",
                "model": "$1 ($2)"
            },
            {
                "regex": "INOI[_ ]([^);/]+)(?: Build|[;)])",
                "model": "$1"
            }
        ]
    },
    "Vertex": {
        "regex": "(?:Luck|Lion_Dual|V709X|Tab [43]G (?:7-2|8-1|10-1)|Pro_P3[01]0_4G|Lion_DC|Baccara|Eagle[ _]4G|ImpressMAX)(?:[);/ ]|$)|Impress[ _]([^;/]+)(?:Build|\\))",
        "device": "smartphone",
        "models": [
            {
                "regex": "(Lion_Dual|Lion_DC|Baccara|Eagle[ _]4G|Luck)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "ImpressMAX(?:[);/ ]|$)",
                "model": "Impress Max"
            },
            {
                "regex": "Pro_P(3[01]0)_4G(?:[);/ ]|$)",
                "model": "Pro P$1 4G"
            },
            {
                "regex": "(Impress[ _]([^;/]+))(?:Build|\\))",
                "model": "$1"
            },
            {
                "regex": "(V709X)",
                "device": "tablet",
                "model": "$1"
            },
            {
                "regex": "Tab ([43])G ([78]|10)-([21])",
                "device": "tablet",
                "model": "Tab $1G $2.$3\""
            }
        ]
    },
    "Unknown": {
        "regex": "WebTV/(\\d+\\.\\d+)",
        "device": "tv",
        "model": "Web TV"
    },
    "Starway": {
        "regex": "Andromeda[ _]S(707|84[05]|850|8)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "Andromeda S$1"
    },
    "Savio": {
        "regex": " (TB-PO1|TB-B01|TB-G01)(?:[);/ ]|$)",
        "device": "tv",
        "model": "$1"
    },
    "Simbans": {
        "regex": "TangoTab|Presto[ )]|Ultimax|Valumax|S72-B|SX2W",
        "device": "tablet",
        "models": [
            {
                "regex": "TangoTab",
                "model": "TangoTab"
            },
            {
                "regex": "Ultimax",
                "model": "Ultimax"
            },
            {
                "regex": "Valumax",
                "model": "Valumax"
            },
            {
                "regex": "Presto",
                "model": "Presto"
            },
            {
                "regex": "S72-B",
                "model": "S72-B"
            },
            {
                "regex": "SX2W",
                "model": "SX2W"
            }
        ]
    },
    "MYFON": {
        "regex": "MYPAD7s",
        "device": "tablet",
        "model": "My Pad 7s"
    },
    "X-TIGI": {
        "regex": "X[-_]TIGI|Hope7[_ ]Mate|Photo_P15_Lite",
        "device": "smartphone",
        "models": [
            {
                "regex": "X-TIGI_JOY10_PRO",
                "model": "Joy 10 Pro",
                "device": "tablet"
            },
            {
                "regex": "Hope7[_ ]Mate",
                "model": "Hope 7 Mate",
                "device": "tablet"
            },
            {
                "regex": "X-TIGI_JOY7_MAX",
                "model": "Joy 7 Max",
                "device": "tablet"
            },
            {
                "regex": "X-TIGI[_ ]V28[_ ]LTE",
                "model": "V28 LTE"
            },
            {
                "regex": "X[-_]TIGI[_ ]V19",
                "model": "V19"
            },
            {
                "regex": "X-TIGI[_ ]Photo[_ ]P11",
                "model": "Photo P11"
            },
            {
                "regex": "Photo_P15_Lite",
                "model": "Photo P15 Lite"
            },
            {
                "regex": "X[-_]TIGI[ _-]([^/;)]+) Build",
                "model": "$1"
            }
        ]
    },
    "Xiaolajiao": {
        "regex": "(LA2-(?:L|S[N]?)|HLA Note3|20190416Q|20170608S|20180322D)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "HLA Note3(?:[);/ ]|$)",
                "model": "Red Pepper Note 3"
            },
            {
                "regex": "(LA2-(?:L|S[N]?))(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "(20190416Q)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "20170608S(?:[);/ ]|$)",
                "model": "Red Pepper Plus"
            },
            {
                "regex": "20180322D(?:[);/ ]|$)",
                "model": "E Sports"
            }
        ]
    },
    "Ritmix": {
        "regex": "(RMD[-_](?:10(?:2[689]|30|5[089])|1121|75[017]|726|85[57]|870)|RMP-(?:450|50[56]|530|600))(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "RMD[-_](10(?:2[689]|30|5[089])|1121|75[017]|726|85[57]|870)(?:[);/ ]|$)",
                "model": "RMD-$1"
            },
            {
                "regex": "RMP[-_](450|50[56]|530|600)(?:[);/ ]|$)",
                "model": "RMP-$1",
                "device": "smartphone"
            }
        ]
    },
    "Ross&Moor": {
        "regex": "RMD[-_](974R|600)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "RMD-$1"
    },
    "R-TV": {
        "regex": "R-TV BOX[ _]?(MINI\\+|X99|X10 PRO|[RXS]10)",
        "device": "tv",
        "model": "Box $1"
    },
    "NEXBOX": {
        "regex": "(MXQ|MXQ-Pro)-NEXBOX|NEXBOX-([^) ]+)",
        "device": "tv",
        "models": [
            {
                "regex": "MXQ-Pro-NEXBOX",
                "model": "MXQ Pro"
            },
            {
                "regex": "MXQ-NEXBOX",
                "model": "MXQ"
            },
            {
                "regex": "NEXBOX-([^) ]+)",
                "model": "$1"
            }
        ]
    },
    "True": {
        "regex": " TRUE BEYOND TAB 3G|SMART[ _](MAX 4.0 PLUS|4G[ _](?:GEN C [45].[05]|Champion|Speedy 5.0 Plus|Speedy[_ ]5inch|P1))",
        "device": "smartphone",
        "models": [
            {
                "regex": "SMART MAX 4.0 PLUS",
                "model": "Smart Max 4.0 Plus"
            },
            {
                "regex": "SMART 4G Gen C ([45].[05])",
                "model": "Smart 4G Gen C $1\""
            },
            {
                "regex": "SMART 4G Speedy 5.0 Plus",
                "model": "Smart 4G Speedy 5.0 Plus"
            },
            {
                "regex": "SMART[ _]4G[_ ]Speedy[_ ]5inch",
                "model": "Smart 4G Speedy 5"
            },
            {
                "regex": "SMART[ _]4G[_ ]Champion",
                "model": "Smart 4G Champion"
            },
            {
                "regex": "SMART[ _]4G[_ ]P1 Prime",
                "model": "Smart 4G P1 Prime"
            },
            {
                "regex": "SMART[ _]4G[_ ]P1",
                "model": "Smart 4G P1"
            },
            {
                "regex": "TRUE BEYOND TAB 3G",
                "model": "Beyond Tab 3G"
            }
        ]
    },
    "Yandex": {
        "regex": "(YNDX-?000SB)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "YNDX-000SB"
    },
    "FORME": {
        "regex": "FORME[ _-]",
        "device": "smartphone",
        "models": [
            {
                "regex": "FORME[ _-]F520(?:[);/ ]|$)",
                "model": "Forever"
            },
            {
                "regex": "FORME[ _-](A[35]7|R7S?|P6)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Shuttle": {
        "regex": "(PNT-704[025])(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "BDF": {
        "regex": "(BDF[- ](?:819|P10|K107H|KT107|MT6753|X20)|K960N_MT(?:6580|6753)(?:_32_N)?)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "BDF[- ](819|P10|K107H|KT107|MT6753|X20)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "K960N_MT(6580|6753)(_32_N)?(?:[);/ ]|$)",
                "model": "BK960N MT$1"
            }
        ]
    },
    "Highscreen": {
        "regex": "HIGHSCREEN|(Easy[- ]Power(?:[- ]Pro)?|Pure[_ ](?:Power|F)|Power Ice(?: Max)?|Easy[_ ]([SFL]|XL)(?:_Pro)?|Razar_Pro|Power Rage(?: Evo)?|Zera-S-Power|Zera[ _][UFS]|Power[ _]?Five[ matrixstyle _]?(?:Pro|Evo|Max|Max(?:Lite|2))|PowerFour|BOOST II(?:se)?|Fest(?:XL)?(?:-Pro)?|Tasty|Boost3|Boost 3(?: Pro)? SE|Expanse|Hercules|Alpha Rage|VergeM)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(Power Ice(?:[ ](?:Max|Evo))?|Power Rage(?: Evo)?|Zera[ _][UFS])(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "PowerFivePro(?:[);/ ]|$)",
                "model": "Power Five Pro"
            },
            {
                "regex": "PowerFiveMaxLite(?:[);/ ]|$)",
                "model": "Power Five Max Lite"
            },
            {
                "regex": "PowerFiveMax2(?:[);/ ]|$)",
                "model": "Power Five Max 2"
            },
            {
                "regex": "Power Five Evo(?:[);/ ]|$)",
                "model": "Power Five Evo"
            },
            {
                "regex": "Power Five Max(?:[);/ ]|$)",
                "model": "Power Five Max"
            },
            {
                "regex": "FestXL-Pro(?:[);/ ]|$)",
                "model": "Fest XL Pro"
            },
            {
                "regex": "FestXL(?:[);/ ]|$)",
                "model": "Fest XL"
            },
            {
                "regex": "Fest-Pro(?:[);/ ]|$)",
                "model": "Fest Pro"
            },
            {
                "regex": "Fest(?:[);/ ]|$)",
                "model": "Fest"
            },
            {
                "regex": "PowerFour(?:[);/ ]|$)",
                "model": "Power Four"
            },
            {
                "regex": "Pure[_ ]Power(?:[);/ ]|$)",
                "model": "Pure Power"
            },
            {
                "regex": "Pure[ _]F(?:[);/ ]|$)",
                "model": "Pure F"
            },
            {
                "regex": "Razar_Pro(?:[);/ ]|$)",
                "model": "Razar Pro"
            },
            {
                "regex": "Easy[_ ]([SLF]|XL)[_ ]Pro(?:[);/ ]|$)",
                "model": "Easy $1 Pro"
            },
            {
                "regex": "Easy[_ ]([SFL]|XL)(?:[);/ ]|$)",
                "model": "Easy $1"
            },
            {
                "regex": "(?:HIGHSCREEN )?Easy[- ]Power[- ]Pro(?:[);/ ]|$)",
                "model": "Easy Power Pro"
            },
            {
                "regex": "(?:HIGHSCREEN )?Easy-Power(?:[);/ ]|$)",
                "model": "Easy Power"
            },
            {
                "regex": "BOOST II(?:[);/ ]|$)",
                "model": "Boost 2"
            },
            {
                "regex": "BOOST IIse(?:[);/ ]|$)",
                "model": "Boost 2 SE"
            },
            {
                "regex": "Boost 3 SE(?:[);/ ]|$)",
                "model": "Boost 3 SE"
            },
            {
                "regex": "Boost 3 Pro SE(?:[);/ ]|$)",
                "model": "Boost 3 Pro SE"
            },
            {
                "regex": "BOOST3(?:[);/ ]|$)",
                "model": "Boost 3"
            },
            {
                "regex": "Zera-S-Power(?:[);/ ]|$)",
                "model": "Zera S Power"
            },
            {
                "regex": "Tasty(?:[);/ ]|$)",
                "model": "Tasty"
            },
            {
                "regex": "VergeM(?:[);/ ]|$)",
                "model": "Verge M"
            },
            {
                "regex": "WinWin(?:[);/ ]|$)",
                "model": "WinWin"
            },
            {
                "regex": "Expanse(?:[);/ ]|$)",
                "model": "Expanse"
            },
            {
                "regex": "Hercules(?:[);/ ]|$)",
                "model": "Hercules"
            },
            {
                "regex": "Alpha Rage(?:[);/ ]|$)",
                "model": "Alpha Rage"
            }
        ]
    },
    "CVTE": {
        "regex": "CVTE[_ ](MSD338_(:?1G|512M))(?:[);/ ]|$)",
        "device": "tv",
        "model": "$1"
    },
    "Globex": {
        "regex": "(GU1011C|GU8012C|GU7013C|GU730C|GU-6012B)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "GU8012C(?:[);/ ]|$)",
                "model": "X8"
            },
            {
                "regex": "GU-6012B(?:[);/ ]|$)",
                "model": "GU6012B"
            },
            {
                "regex": "(GU1011C|GU7013C|GU730C)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Atom": {
        "regex": "ATOM-(108AM|216RK|216AM)(?:[);/ ]|$)",
        "device": "tv",
        "model": "$1"
    },
    "Qumo": {
        "regex": "QUMO[ _]?|(?:Quest[ _]?(?:408|410|45[56]|50[679])|Vega[ _]?78[23]|Sirius[ _]?(?:1001|890))(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "(?:QUMO[ _]?)?Vega[ _]?(78[23]|8002)(?:[);/ ]|$)",
                "model": "Vega $1"
            },
            {
                "regex": "Yooda(?:[);/ ]|$)",
                "model": "Yooda"
            },
            {
                "regex": "Sirius[ _]?(1001|890)(?:[);/ ]|$)",
                "model": "Sirius $1"
            },
            {
                "regex": "(?:QUMO)?(Sirius 101-4G 8GB Black|Altair 701 8GB Black|Altair 71 4GB Black|Altair 700[24]|Altair 706)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "(?:QUMO[ _])?Quest[ _]?(35[34]|40[268]|410|45[2568]|47[46]|50[679]|5[17]0|600)(?:[);/ ]|$)",
                "device": "smartphone",
                "model": "Quest $1"
            }
        ]
    },
    "Umax": {
        "regex": "VisionBook[ _]|[78]Qa_3G(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "(VisionBook[ _](?:10Qi|7Q[i]?|8Q[ei]?|P70|8Qi_3G)(?:[ _](?:[34]G|Plus|LTE))?)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "([78])Qa_3G(?:[);/ ]|$)",
                "model": "VisionBook $1Qa 3G"
            },
            {
                "regex": "VisionBook_10Q_LTE(?:[);/ ]|$)",
                "model": "VisionBook 10Q LTE"
            },
            {
                "regex": "VisionBook_P50Plus_LTE(?:[);/ ]|$)",
                "device": "smartphone",
                "model": "VisionBook P50 Plus LTE"
            },
            {
                "regex": "(VisionBook[ _](?:P50 LTE|P55 X2 LTE|P55 LTE(?: Pro)?))(?:[);/ ]|$)",
                "device": "smartphone",
                "model": "$1"
            }
        ]
    },
    "Mann": {
        "regex": "(ZUG 3|ZUG 5S(?: Q)?)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Unihertz": {
        "regex": "(Titan|Jelly-Pro|Atom_X?L)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(Jelly)-(Pro)(?:[);/ ]|$)",
                "model": "$1 $2"
            },
            {
                "regex": "(Titan|Atom_X?L)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Chuwi": {
        "regex": "(?:CW-(Hi8-super|Hi12|V99i?|V17HD3G|VX8-3G|V88-QUAD|V9-DUAL|V88S|Vi[78]|[HV]i10 (?:pro|plus))|Hi10 (?:Pro|plus)|HiPad X|HiBox-hero|HiBook(?: pro)?|Hi[89] ?(?:Air|Pro)|Hi8SE|Vi10(?: ?Pro)?)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "(CW-Hi8-super)(?:[);/ ]|$)",
                "model": "Hi8"
            },
            {
                "regex": "(?:CW-)?(Vi10|Hi10) plus(?:[);/ ]|$)",
                "model": "$1 Plus"
            },
            {
                "regex": "CW-(V88)-QUAD(?:[);/ ]|$)",
                "model": "$1 Quad"
            },
            {
                "regex": "CW-(V9)-DUAL(?:[);/ ]|$)",
                "model": "$1 Dual"
            },
            {
                "regex": "CW-(V17HD|VX8)-?(3G)(?:[);/ ]|$)",
                "model": "$1 $2"
            },
            {
                "regex": "(?:CW-)?(Hi10) pro(?:[);/ ]|$)",
                "model": "$1 Pro"
            },
            {
                "regex": "HiBook pro(?:[);/ ]|$)",
                "model": "HiBook Pro"
            },
            {
                "regex": "HiBook(?:[);/ ]|$)",
                "model": "HiBook"
            },
            {
                "regex": "HiPad X(?:[);/ ]|$)",
                "model": "HiPad X"
            },
            {
                "regex": "Hi([89]) ?Air(?:[);/ ]|$)",
                "model": "Hi$1 Air"
            },
            {
                "regex": "Hi8SE(?:[);/ ]|$)",
                "model": "Hi8 SE"
            },
            {
                "regex": "Hi([89]) ?pro(?:[);/ ]|$)",
                "model": "Hi$1 Pro"
            },
            {
                "regex": "Vi10 Plus(?:[);/ ]|$)",
                "model": "Vi10 Plus"
            },
            {
                "regex": "Vi10 ?Pro(?:[);/ ]|$)",
                "model": "Vi10 Pro"
            },
            {
                "regex": "Vi10(?:[);/ ]|$)",
                "model": "Vi10"
            },
            {
                "regex": "HiBox-hero(?:[);/ ]|$)",
                "model": "HiBox Hero",
                "device": "desktop"
            },
            {
                "regex": "CW-(Vi[78]|Hi12|V99i?|V88S)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Bezkam": {
        "regex": "BEZKAM (BK-RAM2)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "NG Optics": {
        "regex": "(NG3128HD)(?:[);/ ]|$)",
        "device": "tv",
        "model": "$1"
    },
    "KATV1": {
        "regex": "(KATV-01) PRO(?:[);/ ]|$)",
        "device": "tv",
        "model": "$1 Pro"
    },
    "Ghong": {
        "regex": "GHONG(V10)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Xoro": {
        "regex": "(?:TelePAD(?:795|731|1032|10A3|7A3)|Era 2X|HMT(?:390Q?|400)|HST 260)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "TelePAD(7|10)A3 4G(?:[);/ ]|$)",
                "model": "TelePad $1 A3 4G"
            },
            {
                "regex": "TelePAD(795|731|1032)(?:[);/ ]|$)",
                "model": "TelePad $1"
            },
            {
                "regex": "HMT(390Q?|400)(?:[);/ ]|$)",
                "model": "HMT $1",
                "device": "portable media player"
            },
            {
                "regex": "HST 260(?:[);/ ]|$)",
                "model": "HST 260",
                "device": "tv"
            },
            {
                "regex": "(Era 2X)(?:[);/ ]|$)",
                "model": "$1",
                "device": "smartphone"
            }
        ]
    },
    "Vinga": {
        "regex": "VMP-(015-162|021-82|041-162|011-81|025-324|045-664)(?:[);/ ]|$)",
        "device": "tv",
        "models": [
            {
                "regex": "VMP-021-82",
                "model": "021"
            },
            {
                "regex": "VMP-025-324",
                "model": "025"
            },
            {
                "regex": "VMP-015-162",
                "model": "015"
            },
            {
                "regex": "VMP-041-162",
                "model": "041"
            },
            {
                "regex": "VMP-045-664",
                "model": "045"
            },
            {
                "regex": "VMP-011-81",
                "model": "011"
            }
        ]
    },
    "Viumee": {
        "regex": "SMART[_ ](EV[56])(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "Smart $1"
    },
    "Nuvo": {
        "regex": "Nuvo[_ ](NS35|Blue ND40|Green[_ ]ND[_ ]45)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Nuvo[_ ](NS35)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "Blue (ND40)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "Green[_ ]ND[_ ]45(?:[);/ ]|$)",
                "model": "ND45"
            }
        ]
    },
    "Sunvell": {
        "regex": "(?:T95ZPLUS|T95[KU]PRO)(?:[);/ ]|$)",
        "device": "tv",
        "models": [
            {
                "regex": "T95ZPLUS",
                "model": "T95Z Plus"
            },
            {
                "regex": "(T95[KU])PRO",
                "model": "$1 Pro"
            }
        ]
    },
    "Ugoos": {
        "regex": "UGOOS-AM6(?:[);/ ]|$)",
        "device": "tv",
        "model": "AM6"
    },
    "Yxtel": {
        "regex": "YXTEL_([HU]1|U3)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Proline": {
        "regex": "(H10882M-TN)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "Yota": {
        "regex": "(YD20[16]|YOTA 3[+])(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "YD20[16](?:[);/ ]|$)",
                "model": "Phone 2"
            },
            {
                "regex": "YOTA 3[+](?:[);/ ]|$)",
                "model": "Phone 3 Plus"
            }
        ]
    },
    "Mystery": {
        "regex": "MYSTERY|(MID-(?:123G|72[12]|7[01348]3G|753G|8[23]3G))(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "MID-(123G|72[12]|7[01348]3G|753G|8[23]3G)(?:[);/ ]|$)",
                "model": "MID-$1"
            },
            {
                "regex": "MYSTERY_TV_(D3155CH31)",
                "device": "tv",
                "model": "$1"
            }
        ]
    },
    "Ritzviva": {
        "regex": "(RITZVIVA[_ ]S500C)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "S500C"
    },
    "DeWalt": {
        "regex": "DeWalt (MD501)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Runbo": {
        "regex": "Runbo|F1-TT(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Runbo[ _-]?(X5-W|F1 Plus|F1-TT|F1-EN|F1|TT|Q5-S|X5-King|X6|Q5)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "F1-TT",
                "model": "F1-TT"
            }
        ]
    },
    "NewsMy": {
        "regex": "(CarPad-(?:II|III)-P)(?:[);/ ]|$)",
        "device": "car browser",
        "model": "$1"
    },
    "Newsday": {
        "regex": "Newsday[_ ](E1|A[12])(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Daewoo": {
        "regex": "Daewoo|(DTA-07IDRF)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "(DTA-07IDRF)(?:[);/ ]|$)",
                "model": "Archive 7P"
            },
            {
                "regex": "Daewoo (Android TV 638)(?:[);/ ]|$)",
                "model": "$1",
                "device": "tv"
            }
        ]
    },
    "Vesta": {
        "regex": "VESTA32LD86(?:[);/ ]|$)",
        "device": "tv",
        "model": "SmartT V2.0 32LD86S"
    },
    "Spectrum": {
        "regex": "(CARBONO_5|CUARZO_6|DIAMANTE_5|VULCANO_55)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "VULCANO_55(?:[);/ ]|$)",
                "model": "Vulcano 5.5\""
            },
            {
                "regex": "CARBONO_5(?:[);/ ]|$)",
                "model": "Carbono 5.0\""
            },
            {
                "regex": "CUARZO_6(?:[);/ ]|$)",
                "model": "Cuarzo 6.0\""
            },
            {
                "regex": "DIAMANTE_5(?:[);/ ]|$)",
                "model": "Diamante 5.0\""
            }
        ]
    },
    "Kivi": {
        "regex": "(?:24H600G[RU]|24FR50WU|24HK30B|32HR55GU|4[39]UP50GU|40UR50G[RU]|40U600G[RU]|55U600G[RU]|65UP50GU|55U[PC]50G[RU]|50U600G[RU]|50UX10S|55UR50G[RU]|40FK30G|40FR50BR|43UK35G|50FK30G|50UK35G|32HR50GR|55UC30G|32HK30G|40FK30G|32HR50GU|43UK30G|32FR50(?:WR|BU)|32FK3[02]G)(?:_-Ver0[0-9])?(?:[);/ ]|$)",
        "device": "tv",
        "models": [
            {
                "regex": "(24H600G[RU]|24FR50WU|24HK30B|32HR55GU|4[39]UP50GU|40UR50G[RU]|40U600G[RU]|55U600G[RU]|65UP50GU|55U[PC]50G[RU]|50UX10S|50U600G[RU]|55UR50G[RU]|40FK30G|40FR50BR|43UK35G|50FK30G|50UK35G|32HR50GR|32FR50(?:WR|BU)|32FK3[02]G)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "(55UC30G|40FK30G|43UK30G|32HR50G[RU]|32HK30G|32FK32G)_-Ver0([0-9])(?:[);/ ]|$)",
                "model": "$1 v$2"
            }
        ]
    },
    "Divisat": {
        "regex": "(Divisat)(?:[);/ ]|$)",
        "device": "tv",
        "models": [
            {
                "regex": "(J-Link)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Kanji": {
        "regex": "KJ-(OB03|YUBI)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "KJ-OB03(?:[);/ ]|$)",
                "model": "Cata 7"
            },
            {
                "regex": "KJ-YUBI(?:[);/ ]|$)",
                "model": "Yubi 3G"
            }
        ]
    },
    "Kaiomy": {
        "regex": "KaiCloud[ ]?(7[48]4|942)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "KaiCloud $1"
    },
    "National": {
        "regex": "(NX-32THS100)(?:[);/ ]|$)",
        "device": "tv",
        "model": "$1"
    },
    "Vsun": {
        "regex": "(?:VSUN (?:ILLUSION|RACE|V9)|MARS TOUCH|MARS NOTE|MARS NOCAM|SATURN SELFIE)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "ILLUSION(?:[);/ ]|$)",
                "model": "Illusion"
            },
            {
                "regex": "MARS TOUCH(?:[);/ ]|$)",
                "model": "Mars Touch"
            },
            {
                "regex": "MARS NOTE(?:[);/ ]|$)",
                "model": "Mars Note"
            },
            {
                "regex": "MARS NOCAM(?:[);/ ]|$)",
                "model": "Mars Nocam"
            },
            {
                "regex": "SATURN SELFIE(?:[);/ ]|$)",
                "model": "Saturn Selfie"
            },
            {
                "regex": "RACE(?:[);/ ]|$)",
                "model": "Race"
            },
            {
                "regex": "(V9)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Shift Phones": {
        "regex": "(SHIFT6m)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "RugGear": {
        "regex": "(RugGear|RG(?:310|500|65[05]|702|7[13]0|850))(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(RG[0-9]+)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Aligator": {
        "regex": "ALIGATOR[_ ]",
        "device": "smartphone",
        "models": [
            {
                "regex": "ALIGATOR[ _](S4080|S50(?:6[56]|[678]0)|S5540|RX510|S6500|RX710|RX800|S5520)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Maxcom": {
        "regex": "(MS(?:45[036]|505|514)|MS457(?:PLUS)?)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(MS457)PLUS(?:[);/ ]|$)",
                "model": "$1 Plus"
            },
            {
                "regex": "MS(45[0367]|505|514)(?:[);/ ]|$)",
                "model": "MS$1"
            }
        ]
    },
    "Luna": {
        "regex": "(?:LUNA[ _](?:V55|G[56]0)|TG-L800S)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "LUNA[ _](V55|G[56]0)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "(TG-L800S)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Protruly": {
        "regex": "PROTRULY[ _](D[78]|V10S)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "JFone": {
        "regex": "JFone[ _](JS501)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Uhans": {
        "regex": "(A101S)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Soyes": {
        "regex": "SOYES[ _]([67]S|XS)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Zidoo": {
        "regex": "ZIDOO[ _](H6 Pro|X6 Pro|X9S|Z10|X[158]|X20 Pro|Z9S)(?:[);/ ]|$)",
        "device": "tv",
        "model": "$1"
    },
    "Zfiner": {
        "regex": "ZFINERY900(?:[);/ ]|$)",
        "device": "tablet",
        "model": "Y900"
    },
    "iGet": {
        "regex": "SMART[_ ](G10[12]|S7[02]|G71|G81[H]?)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "Smart $1"
    },
    "iVA": {
        "regex": "(LAMCY (?:L3[05]0|C100|L400)|IVA S6)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "LAMCY (L3[05]0|C100|L400)(?:[);/ ]|$)",
                "model": "Lamcy $1"
            },
            {
                "regex": "IVA S6(?:[);/ ]|$)",
                "model": "S6"
            }
        ]
    },
    "Phicomm": {
        "regex": "(PHICOMM)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "C630 \\(CLUE L\\)(?:[);/ ]|$)",
                "model": "Clue L"
            },
            {
                "regex": "E653\\(ENERGY L\\)(?:[);/ ]|$)",
                "model": "Energy L"
            },
            {
                "regex": "PHICOMM ENERGY 3\\+(?:[);/ ]|$)",
                "model": "Energy 3+"
            },
            {
                "regex": "CLUE (2S|M)(?:[);/ ]|$)",
                "model": "Clue $1"
            }
        ]
    },
    "Huadoo": {
        "regex": "Huadoo[ _](HG0[46]|HG11|V[34])(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Gome": {
        "regex": "(?:GOME[_ ](?:201[678].+|C7_Note(?:_Plus)?|U9|S[17])|Fenmmy_Note)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "GOME[_ ](?:2018X38A)(?:[);/ ]|$)",
                "model": "C72"
            },
            {
                "regex": "GOME[_ ](?:2018X07A)(?:[);/ ]|$)",
                "model": "C7"
            },
            {
                "regex": "GOME[_ ](?:2017X05A)(?:[);/ ]|$)",
                "model": "C71"
            },
            {
                "regex": "GOME[_ ](?:2016G68A)(?:[);/ ]|$)",
                "model": "K1"
            },
            {
                "regex": "GOME[_ ](?:2016M25A)(?:[);/ ]|$)",
                "model": "U1"
            },
            {
                "regex": "GOME[_ ](?:2017M27A)(?:[);/ ]|$)",
                "model": "U7"
            },
            {
                "regex": "GOME[_ ](C7_Note(?:_Plus)?|U9|S[17])(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "(?:GOME[_ ])?(Fenmmy_Note)(?:[);/ ]|$)",
                "device": "phablet",
                "model": "$1"
            }
        ]
    },
    "Voyo": {
        "regex": "(?:VOYO[_ ](?:A15)|i8-Max|Q101-4G)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "i8-Max(?:[);/ ]|$)",
                "model": "i8 Max"
            },
            {
                "regex": "(Q101)-4G(?:[);/ ]|$)",
                "model": "$1 4G"
            },
            {
                "regex": "VOYO[_ ](A15)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Ryte": {
        "regex": "RYTE (U55 LTE)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Vontar": {
        "regex": "(HK1 (?:Max|MINI|PLUS|PRO)|VONTAR[ -](?:Z[58]|V1|KIII|MX-4K|X3))(?:[);/ ]|$)",
        "device": "tv",
        "models": [
            {
                "regex": "VONTAR[ -](Z[58]|V1|KIII|MX-4K|X3)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "HK1 Max(?:[);/ ]|$)",
                "model": "HK1 Max"
            },
            {
                "regex": "HK1 MINI(?:[);/ ]|$)",
                "model": "HK1 Mini"
            },
            {
                "regex": "HK1 PLUS(?:[);/ ]|$)",
                "model": "HK1 Plus"
            },
            {
                "regex": "HK1 PRO(?:[);/ ]|$)",
                "model": "HK1 Pro"
            }
        ]
    },
    "Vorke": {
        "regex": "VORKE (Z[157]|Z6(?: Plus)?)(?:[);/ ]|$)",
        "device": "tv",
        "model": "$1"
    },
    "Wigor": {
        "regex": "Wigor (V4)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "NEXON": {
        "regex": "NEXON (X[1589])(?:[);/ ]|$)",
        "device": "tv",
        "model": "$1"
    },
    "ONN": {
        "regex": "(ONA19TB00[327]|10000520[6-9]|O8HD)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "EXO": {
        "regex": "(Wave_i101G|Wave i101[Hb])(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "Q.Bell": {
        "regex": "(QPHONE[_ ](?:10.1|9.[12]|7.1|5.[46])|QTAB10EW)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(QPHONE[_ ](?:10.1|9.[12]|7.1|5.[46]))(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "QTAB10EW",
                "device": "tablet",
                "model": "QTAB10EW"
            }
        ]
    },
    "Datawind": {
        "regex": "DW-[A-Z0-9]{5}(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(DW-PS3G5)(?:[);/ ]|$)",
                "model": "Pocket Surfer 3G5"
            },
            {
                "regex": "DW-UBT7W",
                "device": "tablet",
                "model": "Ubislate 7W"
            }
        ]
    },
    "Droxio": {
        "regex": "DROXIO (C40|B45)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Goophone": {
        "regex": "Goophone[ _]|(?:M(G492|L7[JK]2)CH|MN(4A2|8J2)ZP|MGA92ZP|MKU82ZP|MNGW2CH|MNH02CH|MNRM2CH)[/]A(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(?:MG492CH|MGA92ZP)[/]A(?:[);/ ]|$)",
                "model": "I6 Plus"
            },
            {
                "regex": "ML7[JK]2CH[/]A(?:[);/ ]|$)",
                "model": "I6"
            },
            {
                "regex": "MKU82ZP[/]A(?:[);/ ]|$)",
                "model": "I6S"
            },
            {
                "regex": "MNGW2CH[/]A(?:[);/ ]|$)",
                "model": "I6S Plus"
            },
            {
                "regex": "(?:MN4A2ZP|MNH02CH)[/]A(?:[);/ ]|$)",
                "model": "I7"
            },
            {
                "regex": "(?:MN8J2ZP|MNRM2CH)[/]A(?:[);/ ]|$)",
                "model": "I7 Plus"
            },
            {
                "regex": "S4 MEGA(?:[);/ ]|$)",
                "model": "S4 Mega"
            },
            {
                "regex": "Goophone i([69])(?:[);/ ]|$)",
                "model": "I$1"
            }
        ]
    },
    "PocketBook": {
        "regex": "PocketBook[ _/]",
        "device": "tablet",
        "models": [
            {
                "regex": "PocketBook SURFpad 3 \\(([\\d]+).([\\d]+)[\"]?\\)(?:[);/ ]|$)",
                "model": "Surfpad 3 $1.$2\""
            },
            {
                "regex": "PocketBook/(62[67]|63[12]|740|840)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Bitmore": {
        "regex": "(?:Mobitab10c-3G|Tab1011Q_II)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "(Mobitab)(10)c-3G(?:[);/ ]|$)",
                "model": "$1 $2C 3G"
            },
            {
                "regex": "Tab1011Q_II(?:[);/ ]|$)",
                "model": "Tab1011Q II"
            }
        ]
    },
    "Kuliao": {
        "regex": "KULIAO (K10)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Sugar": {
        "regex": "SUGAR[ _]",
        "device": "smartphone",
        "models": [
            {
                "regex": "SUGAR Y([78]) MAX(?:[);/ ]|$)",
                "model": "Y$1 Max"
            },
            {
                "regex": "SUGAR F7 Mini(?:[);/ ]|$)",
                "model": "F7 Mini"
            },
            {
                "regex": "SUGAR (C[12]1|C1[23]|S11|C11s|[SF]20s?|[YF]11|[YFS]9|C[679]|[FY]7|Y12s|Y1[256]|P1)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Subor": {
        "regex": "SUBOR[ _]",
        "device": "smartphone",
        "models": [
            {
                "regex": "SUBOR[ _](S5|2019)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "SUBOR[_ ]K10PLUS(?:[);/ ]|$)",
                "model": "K10 Plus"
            }
        ]
    },
    "Xshitou": {
        "regex": "Xshitou[_ ](P7)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "JKL": {
        "regex": "JKL[_ ](A28)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Gree": {
        "regex": "(G0215D|G0245D|G0335D)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Vodacom": {
        "regex": "(?:Vodacom)[ _](Power Tab 10)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "VKworld": {
        "regex": "(?:(?:VK700)-MAX|VK700 Pro|T5SE|VK700X|vk6050|vk6735|vk700)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(VK700)-MAX(?:[);/ ]|$)",
                "model": "$1 Max"
            },
            {
                "regex": "(VK700) Pro(?:[);/ ]|$)",
                "model": "$1 Pro"
            },
            {
                "regex": "T5SE(?:[);/ ]|$)",
                "model": "T5 SE"
            },
            {
                "regex": "VK(700X|6050|6735|700)(?:[);/ ]|$)",
                "model": "VK$1"
            }
        ]
    },
    "Mito": {
        "regex": "MITO[- _](A(?:1[0678]|19_[12]GB|21|3[59]|55|180|2[36]0|313|322|33|3[136]0|[35]50|6[89]|7[235]|82|[356]0|810|950)|T(?:8|10|35|3[03]0|510|888)|311)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "MITO[- _](A(?:1[0678]|19_[12]GB|21|3[59]|55|180|2[36]0|33|322|313|3[136]0|[35]50|6[89]|7[235]|82|[356]0|810|950)|311)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "MITO[ _](T(?:8|10|35|3[03]0|510|888))(?:[);/ ]|$)",
                "device": "tablet",
                "model": "$1"
            }
        ]
    },
    "TWM": {
        "regex": "Amazing[ _](X[35]s|X[123567]|A5S|A3[025]|A[468]|A4S|A5[057]|P6)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Amazing[ _](X[35])s(?:[);/ ]|$)",
                "model": "Amazing $1S"
            },
            {
                "regex": "Amazing[ _](A5S|X[123567]|A[468]|A4S|A5[057]|A3[025])(?:[);/ ]|$)",
                "model": "Amazing $1"
            },
            {
                "regex": "Amazing[ _](P6)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Amazing $1"
            }
        ]
    },
    "TWZ": {
        "regex": "TWZ[-_ ](?:TabPlay|Y6[08]|Y52|A49|A[59]5|A567|AA8|QQ[12])|TWZQQ1",
        "device": "smartphone",
        "models": [
            {
                "regex": "TWZ[_ ]TabPlay[_ ](12[56])",
                "device": "tablet",
                "model": "TabPlay $1"
            },
            {
                "regex": "TWZ[-_ ]?(Y6[08]|Y52|A49|A[59]5|A567|AA8|QQ[12])",
                "model": "$1"
            }
        ]
    },
    "Onix": {
        "regex": "(?:ONIX S405|AT101-1116)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "(AT101-1116)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "(S405)(?:[);/ ]|$)",
                "device": "smartphone",
                "model": "$1"
            }
        ]
    },
    "Oale": {
        "regex": "Apex([13])(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "Apex $1"
    },
    "Mobo": {
        "regex": "(?:(?:MOBO[_ ]MB7005|MT7-421HD)|(?:MT7-442D|MT7-411D))(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "(?:MOBO[_ ])?(MB7005|MT7-421HD|MT7-442D|MT7-411D)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Macoox": {
        "regex": "(MC-X7MINI)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "MC-X7 Mini"
    },
    "Prixton": {
        "regex": "PRIXTON |(T7012Q)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "PRIXTON (C18)",
                "device": "smartphone",
                "model": "$1"
            },
            {
                "regex": "(T7012Q)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Magnus": {
        "regex": "Magnus[ _](?:bravoZ[23]0|Infinity_?(?:G8|G11))(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "bravo(Z[23]0)",
                "model": "Bravo $1"
            },
            {
                "regex": "(?:Magnus[ _])?Infinity_?(G8|G11)",
                "model": "Infinity $1"
            }
        ]
    },
    "Newland": {
        "regex": "(?:NLS-MT90)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "MT90 Orca"
    },
    "Orbic": {
        "regex": "(?:RC555L|RC501L)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "RC555L(?:[);/ ]|$)",
                "model": "Wonder"
            },
            {
                "regex": "RC501L(?:[);/ ]|$)",
                "model": "Slim"
            }
        ]
    },
    "RED": {
        "regex": "(?:H1A1000)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "Hydrogen One"
    },
    "Contixo": {
        "regex": "Contixo|(?:CX-1045 3G|KT995 3G|CX1045)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "CX-?1045(?: 3G)?(?:[);/ ]|$)",
                "model": "CX-1045 3G"
            },
            {
                "regex": "CONTIXO GX 1035(?:[);/ ]|$)",
                "model": "GX-1035 4G 10.1\""
            },
            {
                "regex": "Contixo GT1160 4G(?:[);/ ]|$)",
                "model": "GT1160 4G 10.1\""
            },
            {
                "regex": "B105(?:[);/ ]|$)",
                "model": "B105 3G 10.1\""
            },
            {
                "regex": "B108(?:[);/ ]|$)",
                "model": "B108 4G 10.1\""
            },
            {
                "regex": "KT107(?: 3G)?(?:[);/ ]|$)",
                "model": "KT107 3G 10.1\""
            },
            {
                "regex": "K109(?:[);/ ]|$)",
                "model": "KT109 4G 10.1\""
            },
            {
                "regex": "KT715 3G(?:[);/ ]|$)",
                "model": "KT715 3G 7.0\""
            },
            {
                "regex": "KT995(?:[);/ ]|$)",
                "model": "KT995 3G 10.1\""
            },
            {
                "regex": "KT1100GT(?:[);/ ]|$)",
                "model": "KT1100GT"
            }
        ]
    },
    "E-Ceros": {
        "regex": "(?:CT9716(?:-B)?)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "CT9716(?:[);/ ]|$)",
                "model": "Revolution"
            },
            {
                "regex": "CT9716-B(?:[);/ ]|$)",
                "model": "Revolution HD"
            }
        ]
    },
    "How": {
        "regex": "(?:HT-705XS|705-G Go|1001-G Go)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "(HT-705XS)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "(1001-G Go)(?:[);/ ]|$)",
                "model": "HT-1001G Go Kids"
            },
            {
                "regex": "(705-G Go)(?:[);/ ]|$)",
                "model": "HT-$1"
            }
        ]
    },
    "Multilaser": {
        "regex": "(?:Multilaser[ _](?:[FEGH])(?:[_ ](?:Pro|Max|Lite))?|MS50[LGX]|M7[_-]3G[_-]PLUS|MS5\\.V2|MS45S_A6|ML([02]1|12)-M7s[- ]?Quad-?Core| ML01-M7-SD|MS45S|MS50S|MS60[XZ]|M7SQC_Plus|MS80X|MS40[GS]|MS55M|MS70|M7-3G QUAD CORE|M7sLite|MS50L_4G|MS60F(?:_PLUS)?)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Multilaser[ _]G[_ ]Pro(?:[);/ ]|$)",
                "model": "G Pro"
            },
            {
                "regex": "Multilaser[ _]F[_ ]Pro(?:[);/ ]|$)",
                "model": "F Pro"
            },
            {
                "regex": "Multilaser[ _]G[_ ]Max(?:[);/ ]|$)",
                "model": "G Max"
            },
            {
                "regex": "Multilaser[ _]G(?:[);/ ]|$)",
                "model": "G"
            },
            {
                "regex": "Multilaser[ _]E[_ ]Lite(?:[);/ ]|$)",
                "model": "E Lite"
            },
            {
                "regex": "Multilaser[ _]([FEH])(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "MS60F_PLUS(?:[);/ ]|$)",
                "model": "MS60F Plus"
            },
            {
                "regex": "(MS50[LGX]|MS45S_A6|MS40[GS]|MS55M|MS60F|MS70|MS60[XZ]|MS45S|MS80X|MS50L_4G|MS50S|MS5\\.V2)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "M7[_-]3G[_-]PLUS(?:[);/ ]|$)",
                "device": "tablet",
                "model": "M7 3G Plus"
            },
            {
                "regex": "M7SQC_PLUS(?:[);/ ]|$)",
                "device": "tablet",
                "model": "M7SQC Plus"
            },
            {
                "regex": "M7-3G QUAD CORE(?:[);/ ]|$)",
                "device": "tablet",
                "model": "M7 3G Quad Core"
            },
            {
                "regex": "ML([02]1|12)-M7s[- ]?Quad-?Core(?:[);/ ]|$)",
                "device": "tablet",
                "model": "ML$1-M7s Quad Core"
            },
            {
                "regex": "M7sLite(?:[);/ ]|$)",
                "device": "tablet",
                "model": "M7s Lite"
            },
            {
                "regex": "ML01-M7-SD(?:[);/ ]|$)",
                "device": "tablet",
                "model": "ML01-M7 SD"
            }
        ]
    },
    "Positivo BGH": {
        "regex": "(?:T1060B|S455|Positivo (?:Twist|Ypy|Mini I)|Twist[ _](?:\\(2018\\)|2 (?:Fit|Pro)|Mini|Tab|Metal(?: 32GB)?|Max|XL|SE|[23SM]))(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "S455(?:[);/ ]|$)",
                "model": "Selfie"
            },
            {
                "regex": "Twist \\(2018\\)(?:[);/ ]|$)",
                "model": "Twist (2018)"
            },
            {
                "regex": "Twist Metal 32GB(?:[);/ ]|$)",
                "model": "Twist Metal 32GB"
            },
            {
                "regex": "Twist Metal(?:[);/ ]|$)",
                "model": "Twist Metal"
            },
            {
                "regex": "Twist Max(?:[);/ ]|$)",
                "model": "Twist Max"
            },
            {
                "regex": "Twist[ _]Mini(?:[);/ ]|$)",
                "model": "Twist Mini"
            },
            {
                "regex": "Twist 2 Fit(?:[);/ ]|$)",
                "model": "Twist 2 Fit"
            },
            {
                "regex": "Twist 2 Pro(?:[);/ ]|$)",
                "model": "Twist 2 Pro"
            },
            {
                "regex": "Twist XL(?:[);/ ]|$)",
                "model": "Twist XL"
            },
            {
                "regex": "Twist SE(?:[);/ ]|$)",
                "model": "Twist SE"
            },
            {
                "regex": "(?:Positivo )?Twist ([23SM])(?:[);/ ]|$)",
                "model": "Twist $1"
            },
            {
                "regex": "(?:Positivo )Mini I(?:[);/ ]|$)",
                "model": "Mini I"
            },
            {
                "regex": "T1060B(?:[);/ ]|$)",
                "device": "tablet",
                "model": "T1060B"
            },
            {
                "regex": "Positivo Ypy (AB7F|AB10H|L1050E)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Ypy $1"
            },
            {
                "regex": "Twist Tab(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Twist Tab"
            }
        ]
    },
    "Maxtron": {
        "regex": "Maxtron[ _-](V[327]|S[89]|T1)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Maxtron[ _-](V[327]|S[89])(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "Maxtron[ _-](T1)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "$1"
            }
        ]
    },
    "Philco": {
        "regex": "PHILCO |(PCS01|PH350B|PTB7R|PTB7PAP_PTB7PAB_PTB7PAR)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(PCS01|PH350B)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "(PTB7R)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "$1"
            },
            {
                "regex": "PHILCO Android TV(?:[);/ ]|$)",
                "device": "tv",
                "model": ""
            },
            {
                "regex": "PTB7PAP_PTB7PAB_PTB7PAR(?:[);/ ]|$)",
                "device": "tablet",
                "model": "PTB7PA"
            }
        ]
    },
    "SEMP TCL": {
        "regex": "(?:GO[35]E|5101J|GO3C(?: Plus)?)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "5101J(?:[);/ ]|$)",
                "model": "L9 Plus"
            },
            {
                "regex": "GO3C Plus(?:[);/ ]|$)",
                "model": "GO! 3C Plus"
            },
            {
                "regex": "GO3C(?:[);/ ]|$)",
                "model": "GO! 3C"
            },
            {
                "regex": "(GO[35]E)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Newman": {
        "regex": "Newman[ _](P308|K1)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Swipe": {
        "regex": "(Elite Sense|Elite_VR)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Owwo": {
        "regex": "(?:OWWO[ _]?(?:[1457]S|Q[78])|CARNIVAL B5)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "OWWO[ _]?([1457]S|Q[78])(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "CARNIVAL B5(?:[);/ ]|$)",
                "model": "Carnival B5"
            }
        ]
    },
    "TD Systems": {
        "regex": "(K32DLX9HS|K40DLM8FS|K40DLX9FS)(?:[);/ ]|$)",
        "device": "tv",
        "model": "$1"
    },
    "Ravoz": {
        "regex": "RAVOZ[ _](Z5 (?:Lite|Pro)|Z[3-7]|R[478])(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Tone": {
        "regex": "TONE (e19|m15|e20)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "AT&T": {
        "regex": "(?:U304AA|QS5509A|U705AA)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "U304AA(?:[);/ ]|$)",
                "model": "Radiant Core"
            },
            {
                "regex": "U705AA(?:[);/ ]|$)",
                "model": "Radiant Max"
            },
            {
                "regex": "QS5509A(?:[);/ ]|$)",
                "model": "Axia"
            }
        ]
    },
    "BIHEE": {
        "regex": "(ZBH-)?BIHEE",
        "device": "smartphone",
        "models": [
            {
                "regex": "(A1[12]|A[78]\\+|A[56])(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Enot": {
        "regex": "Enot[ _-](E102|J101)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "Kooper": {
        "regex": "Kooper-mobile-W502(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "Mobile W502"
    },
    "Lesia": {
        "regex": "ANOVA A5(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "Anova A5"
    },
    "Anry": {
        "regex": "ANRY-(RS10|[SX]20)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "Tinai": {
        "regex": "Tinai (K[12])(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Winds": {
        "regex": "NOTE GRACE V(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "Note Grace V"
    },
    "Asano": {
        "regex": "32LF7130S(?:[);/ ]|$)",
        "device": "tv",
        "model": "32LF7130S 32.0\""
    },
    "Phonemax": {
        "regex": "PHONEMAX[_ ](Saturn(?:[_ ]X)?|Mars|Venus X)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "PHONEMAX[_ ]Saturn[_ ]X(?:[);/ ]|$)",
                "model": "Saturn X"
            },
            {
                "regex": "PHONEMAX[_ ]Saturn(?:[);/ ]|$)",
                "model": "Saturn"
            },
            {
                "regex": "PHONEMAX[_ ]Mars(?:[);/ ]|$)",
                "model": "Mars"
            },
            {
                "regex": "PHONEMAX[_ ]Venus X(?:[);/ ]|$)",
                "model": "Venus X"
            }
        ]
    },
    "Vipro": {
        "regex": "VIPRO(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "VIPRO PRO2X(?:[);/ ]|$)",
                "model": "Pro 2X"
            },
            {
                "regex": "VIPRO PRO[ _-]?([0-9])(?:[);/ ]|$)",
                "model": "Pro $1"
            }
        ]
    },
    "OzoneHD": {
        "regex": "Ozone HD|OzoneHD[ _](T2 WiFi|4K(?: TV)?|Fun)(?:[);/ ]|$)",
        "device": "tv",
        "model": "$1"
    },
    "Kzen": {
        "regex": "(?:Ureki_U1|Victor V1|DART KZ07)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Ureki_U1",
                "model": "Ureki U1"
            },
            {
                "regex": "Victor V1",
                "model": "Victor V1"
            },
            {
                "regex": "DART KZ07",
                "device": "tablet",
                "model": "Dart KZ07"
            }
        ]
    },
    "Melrose": {
        "regex": "MELROSE_(?:S9|2019)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "MELROSE_S9(?:[);/ ]|$)",
                "model": "S9"
            },
            {
                "regex": "MELROSE_2019(?:[);/ ]|$)",
                "model": "2019 Ultra Slim 3.4\""
            }
        ]
    },
    "Crony": {
        "regex": "CRONY-7021(?:[);/ ]|$)",
        "device": "tablet",
        "model": "7021"
    },
    "Takara": {
        "regex": "(MID211H)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "Tronsmart": {
        "regex": "(Orion R68G|Draco AW80|Draco H3|Vega S95_(?:Meta|Pro|Telos))(?:[);/ ]|$)",
        "device": "tv",
        "model": "$1"
    },
    "Amigoo": {
        "regex": "AMIGOO (R300|R9 Max|M1 Max|X15)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Lumigon": {
        "regex": "Lumigon[ _](T3)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Neomi": {
        "regex": "NEO[ ]?(90[689])(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "Neo $1"
    },
    "Eltex": {
        "regex": "(?:NV5(?:01|10)WAC|NV501|NV510WB)(?:[);/ ]|$)",
        "device": "tv",
        "models": [
            {
                "regex": "NV5(01|10)WAC(?:[);/ ]|$)",
                "model": "NV-5$1-Wac"
            },
            {
                "regex": "NV501(?:[);/ ]|$)",
                "model": "NV-501"
            },
            {
                "regex": "NV510WB(?:[);/ ]|$)",
                "model": "NV-510-WB"
            }
        ]
    },
    "Senkatel": {
        "regex": "Senkatel[ _](T1009|T8002|T701[12]|T9702)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "Bobarry": {
        "regex": "(?:K10[ ]?SE)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "K10 SE"
    },
    "Wortmann": {
        "regex": "TERRA[ _]PAD[ _](100[45])(?:[);/ ]|$)",
        "device": "tablet",
        "model": "Terra Pad $1"
    },
    "BB Mobile": {
        "regex": "Techno[-_ ](8\\.0|9\\.7|TQ863Q|X595BT|S700BF|I700AJ|10.1 LTE TQ060X|7.0 LTE TQ763I|I785AP|I101BI)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "Techno[_ ]S700BF(?:[);/ ]|$)",
                "model": "Techno 7.0\" Pioneer LTE S700BF"
            },
            {
                "regex": "Techno[_ ]10.1 LTE TQ060X(?:[);/ ]|$)",
                "model": "Techno 10.0\" LTE TQ060X"
            },
            {
                "regex": "Techno[_ ]TQ863Q(?:[);/ ]|$)",
                "model": "Techno 8.0\" Poplar LTE TQ863Q"
            },
            {
                "regex": "Techno[_ ]I700AJ(?:[);/ ]|$)",
                "model": "Techno 7.0\" Mozg LTE I700AJ"
            },
            {
                "regex": "Techno[-_ ]I785AP(?:[);/ ]|$)",
                "model": "Techno 7.85\" Mozg I785AP"
            },
            {
                "regex": "Techno[-_ ]I101BI(?:[);/ ]|$)",
                "model": "Techno 10.1\" Mozg I101BI"
            },
            {
                "regex": "Techno[_ ]7.0 LTE TQ763I(?:[);/ ]|$)",
                "model": "Techno 7.0\" Kalash LTE TQ763I"
            },
            {
                "regex": "Techno[_ ](9\\.7|8\\.0) 3G(?:[);/ ]|$)",
                "model": "Techno $1\" 3G"
            },
            {
                "regex": "Techno[_ ](?:X595BT)(?:[);/ ]|$)",
                "device": "smartphone",
                "model": "Techno Spark 3G X595BT"
            }
        ]
    },
    "2E": {
        "regex": "(?:E500A_2019|2E E450 2018)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "E500A_2019(?:[);/ ]|$)",
                "model": "E500A (2019)"
            },
            {
                "regex": "2E E450 2018(?:[);/ ]|$)",
                "model": "E450 (2018)"
            }
        ]
    },
    "Billion": {
        "regex": "Capture\\+(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "Capture Plus"
    },
    "Tele2": {
        "regex": "Tele2[ _]",
        "device": "smartphone",
        "models": [
            {
                "regex": "Tele2[ _](Mi[dn]i)[ _]1.1(?:[);/ ]|$)",
                "model": "$1 1.1"
            },
            {
                "regex": "Tele2[ _]Midi[ _]2.0(?:[);/ ]|$)",
                "model": "Midi 2.0"
            },
            {
                "regex": "Tele2[ _]((?:Maxi|Midi)(?:[_ ](?:Plus|LTE))?)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "Tele2[ _](Mi[dn]i)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Alfawise": {
        "regex": "(?:Alfawise (?:A9)|(?:A95X_R1))(?:[);/ ]|$)",
        "device": "tv",
        "models": [
            {
                "regex": "(A95X_R1)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "Alfawise (A9)",
                "model": "$1"
            }
        ]
    },
    "Klipad": {
        "regex": "KLIPAD_|(?:KL-V905|KL4889|KL9878|7589AN)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(?:KLIPAD_)?(V355B?|KL600|KL-V905)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "(KL4889|7589AN|KL9878)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "$1"
            }
        ]
    },
    "SQOOL": {
        "regex": "SQOOL-V(41?)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "V$1"
    },
    "TurboKids": {
        "regex": "Turbokids[-_ ]?(TMNT|3G|S4)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "Jinga": {
        "regex": "Jinga[-_ ]|(?:(?:Fresh|Optim)4G|Pass(?:Plus|3G)|PicassoNote|Hotz[_ ]M1|WinPRO|Touch4G|Start3G|IGO[ _]L[41]|Basco (?:L3|L[45]00|XS1|S2))(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Optim4G(?:[);/ ]|$)",
                "model": "Optim 4G"
            },
            {
                "regex": "Fresh4G(?:[);/ ]|$)",
                "model": "Fresh 4G"
            },
            {
                "regex": "Hotz[_ ]M1(?:[);/ ]|$)",
                "model": "Hotz M1"
            },
            {
                "regex": "PassPlus(?:[);/ ]|$)",
                "model": "Pass Plus"
            },
            {
                "regex": "Pass3G(?:[);/ ]|$)",
                "model": "Pass 3G"
            },
            {
                "regex": "WinPRO(?:[);/ ]|$)",
                "model": "Win Pro"
            },
            {
                "regex": "Touch4G(?:[);/ ]|$)",
                "model": "Touch 4G"
            },
            {
                "regex": "Start3G(?:[);/ ]|$)",
                "model": "Start 4G"
            },
            {
                "regex": "PicassoNote(?:[);/ ]|$)",
                "model": "Picasso Note"
            },
            {
                "regex": "Jinga Iron(?:[);/ ]|$)",
                "model": "Iron"
            },
            {
                "regex": "Jinga Basco neo(?:[);/ ]|$)",
                "model": "Basco Neo"
            },
            {
                "regex": "(?:Jinga )?Basco (L3|L[45]00|XS1|S2)(?:[);/ ]|$)",
                "model": "Basco $1"
            },
            {
                "regex": "Jinga Trezor S1 Plus(?:[);/ ]|$)",
                "model": "Trezor S1 Plus"
            },
            {
                "regex": "JINGA_IGO_L2(?:[);/ ]|$)",
                "model": "IGO L2"
            },
            {
                "regex": "(?:JINGA[_ ])?IGO[_ ]([ML]1|L4)(?:[);/ ]|$)",
                "model": "IGO $1"
            },
            {
                "regex": "Jinga Trezor S1 Plus(?:[);/ ]|$)",
                "model": "Trezor S1 Plus"
            }
        ]
    },
    "Twoe": {
        "regex": "(?:TWOE[-_ ]E450R|F534L|F572L|E500A)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "TWOE[-_ ](E450R)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "(F534L|F572L|E500A)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "iTruck": {
        "regex": "iTRUCK 7 3G(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "7.0\" 3G"
    },
    "Torex": {
        "regex": "TOREX[- ](?:PS12|S18|S27)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "TOREX-(PS12)",
                "model": "$1"
            },
            {
                "regex": "TOREX[- ](S18|S27)",
                "device": "smartphone",
                "model": "$1"
            }
        ]
    },
    "Doffler": {
        "regex": "(24DHS54)(?:[);/ ]|$)",
        "device": "tv",
        "model": "$1"
    },
    "Oyyu": {
        "regex": "OYYUT11(?:[);/ ]|$)",
        "device": "tablet",
        "model": "T11 3G"
    },
    "Primux": {
        "regex": "Primux",
        "device": "smartphone",
        "models": [
            {
                "regex": "Primux_ioxphone(?:[);/ ]|$)",
                "model": "IOX Phone"
            },
            {
                "regex": "PrimuxDelta6(?:[);/ ]|$)",
                "model": "Delta 6"
            },
            {
                "regex": "PrimuxKappa(P50[01])(?:[);/ ]|$)",
                "model": "Kappa $1"
            },
            {
                "regex": "PrimuxKappa(?:[);/ ]|$)",
                "model": "Kappa"
            },
            {
                "regex": "Primux_Beta2(?:[);/ ]|$)",
                "model": "Beta 2"
            },
            {
                "regex": "Primux_?Omega([57])(?:[);/ ]|$)",
                "model": "Omega $1"
            },
            {
                "regex": "PrimuxUP(?:[);/ ]|$)",
                "device": "tablet",
                "model": "UP"
            },
            {
                "regex": " PrimuxZonda4(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Zonda 4"
            },
            {
                "regex": "Primux_Siroco6(?:[);/ ]|$)",
                "device": "tablet",
                "model": "Siroco 6"
            }
        ]
    },
    "iMars": {
        "regex": "(?:IMARS VEGA X7|VIVI_8)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "IMARS VEGA X7",
                "model": "Vega X7"
            },
            {
                "regex": "VIVI_8",
                "model": "VIVI 8"
            }
        ]
    },
    "Logic": {
        "regex": "LOGIC[_ ](X[135]|X4M|L5D|X5[AFT]|X60G|L61|X60_PLUS)(:?[_ ]LITE2?)?(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "LOGIC[_ ]X60_PLUS(?:[);/ ]|$)",
                "model": "X60 Plus"
            },
            {
                "regex": "LOGIC[_ ](X5|X4M)[_ ]LITE(?:[);/ ]|$)",
                "model": "$1 Lite"
            },
            {
                "regex": "LOGIC[_ ](X5)[_ ]LITE2(?:[);/ ]|$)",
                "model": "$1 Lite 2"
            },
            {
                "regex": "LOGIC[_ ](X[13]|X4M|L5D|X5[AFT]|X60G|L61)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Cloudpad": {
        "regex": "\\+VT(?:107C|Q007C)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "\\+VT(107C)",
                "model": "+VT$1"
            },
            {
                "regex": "\\+VTQ(007C)",
                "model": "+VTQ$1"
            }
        ]
    },
    "Cloudfone": {
        "regex": "(?:Thrill (?:Boost[ ]?(2|3)?|Snap|Access)|Excite Prime 2 Pro|NBA Edition)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Thrill Boost (2|3)(?:[);/ ]|$)",
                "model": "Thrill Boost $1"
            },
            {
                "regex": "Thrill Boost(?:[);/ ]|$)",
                "model": "Thrill Boost"
            },
            {
                "regex": "Thrill Snap(?:[);/ ]|$)",
                "model": "Thrill Snap"
            },
            {
                "regex": "Thrill Access(?:[);/ ]|$)",
                "model": "Thrill Access"
            },
            {
                "regex": "Excite Prime 2 Pro(?:[);/ ]|$)",
                "model": "Excite Prime 2 Pro"
            },
            {
                "regex": "NBA Edition(?:[);/ ]|$)",
                "model": "NBA Edition"
            }
        ]
    },
    "Vsmart": {
        "regex": "(?:Joy 1\\+|Active 1\\+|Bee 3|Active 3)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Joy 1\\+(?:[);/ ]|$)",
                "model": "Joy 1 Plus"
            },
            {
                "regex": "Active 1\\+(?:[);/ ]|$)",
                "model": "Active 1 Plus"
            },
            {
                "regex": "Bee 3(?:[);/ ]|$)",
                "model": "Bee 3"
            },
            {
                "regex": "Active 3(?:[);/ ]|$)",
                "model": "Active 3"
            }
        ]
    },
    "MiXzo": {
        "regex": "(?:MiXzo[ _])?(?:ME1023|ME1030|MX1037|MX1024|MX10[24]1|MX1397)([ _][34]G)?(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "MiXzo[_ ]ME(1023|1030)(?:[_ ]3G)(?:[);/ ]|$)",
                "model": "ME$1 3G"
            },
            {
                "regex": "MiXzo[_ ]MX1021(?:[_ ]3G)?(?:[);/ ]|$)",
                "model": "MX1021 3G"
            },
            {
                "regex": "(?:MiXzo[ _])?MX(1037|1041|1024|1397)[ _]4G(?:[);/ ]|$)",
                "model": "MX$1 4G"
            }
        ]
    },
    "Openbox": {
        "regex": "Openbox (A[34](?: (?:Pro|Lite))?)(?:[);/ ]|$)",
        "device": "tv",
        "model": "$1"
    },
    "Ziox": {
        "regex": "ZIOX[_ ]|(?:Astra Viva 4G|Astra NXT Pro|Duopix F9)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "ZIOX[_ ]F9[_ ]PRO(?:[);/ ]|$)",
                "model": "F9 Pro"
            },
            {
                "regex": "Astra Viva 4G(?:[);/ ]|$)",
                "model": "Astra Viva 4G"
            },
            {
                "regex": "Astra NXT Pro(?:[);/ ]|$)",
                "model": "Astra NXT Pro"
            },
            {
                "regex": "Duopix F9(?:[);/ ]|$)",
                "model": "Duopix F9"
            }
        ]
    },
    "iDroid": {
        "regex": "APACHE G6(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "Apache G6"
    },
    "Aoson": {
        "regex": "aoson(?:R10[1-3]|S[78]|M707TG)(?:[_ ]?Pro)?(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "aosonR10([1-3])(?:[);/ ]|$)",
                "model": "R10$1 10.1\""
            },
            {
                "regex": "aosonS7_Pro(?:[);/ ]|$)",
                "model": "S7 Pro 7.0\""
            },
            {
                "regex": "aosonS7(?:[);/ ]|$)",
                "model": "S7 7.0\""
            },
            {
                "regex": "aosonS8Pro(?:[);/ ]|$)",
                "model": "S8 Pro 8.0\""
            },
            {
                "regex": "aoson(M707TG)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "FireFly Mobile": {
        "regex": "(?:AURII[ _](?:Passion|Virtuoso|ULTRA|Secret Lite|AMUSE[_ ]3G|FORCE|FUSION|F8[_ ]Premium|XCITE|DELIGHT|DREAM[_ ](?:MINI|One))|SECRET_XR|INTENSE[ _](?:POWER|METAL_2|XT)|SUPER SWEET|SWEET MINI)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "AURII[ _]FORCE(?:[);/ ]|$)",
                "model": "Aurii Force"
            },
            {
                "regex": "AURII[ _]DELIGHT(?:[);/ ]|$)",
                "model": "Aurii Delight"
            },
            {
                "regex": "AURII[ _]DREAM[_ ]MINI(?:[);/ ]|$)",
                "model": "Aurii Dream Mini"
            },
            {
                "regex": "AURII[ _]DREAM[_ ]One(?:[);/ ]|$)",
                "model": "Aurii Dream One"
            },
            {
                "regex": "AURII[ _]ULTRA(?:[);/ ]|$)",
                "model": "Aurii Ultra"
            },
            {
                "regex": "AURII[ _]AMUSE[_ ]3G(?:[);/ ]|$)",
                "model": "Aurii Amuse 3G"
            },
            {
                "regex": "AURII[ _]FUSION(?:[);/ ]|$)",
                "model": "Aurii Fusion"
            },
            {
                "regex": "AURII[ _]XCITE(?:[);/ ]|$)",
                "model": "Aurii xCite"
            },
            {
                "regex": "SECRET_XR(?:[);/ ]|$)",
                "model": "Aurii Secret XR"
            },
            {
                "regex": "AURII[ _]((?:Passion|Virtuoso|Secret Lite|F8[_ ]Premium))(?:[);/ ]|$)",
                "model": "Aurii $1"
            },
            {
                "regex": "INTENSE POWER(?:[);/ ]|$)",
                "model": "Intense Power"
            },
            {
                "regex": "INTENSE_XT(?:[);/ ]|$)",
                "model": "Intense XT"
            },
            {
                "regex": "INTENSE_METAL_2(?:[);/ ]|$)",
                "model": "Intense Metal 2"
            },
            {
                "regex": "SUPER SWEET(?:[);/ ]|$)",
                "model": "Super Sweet"
            },
            {
                "regex": "SWEET MINI(?:[);/ ]|$)",
                "model": "Sweet Mini"
            }
        ]
    },
    "i-Cherry": {
        "regex": "iCherry_?",
        "device": "smartphone",
        "models": [
            {
                "regex": "iCherry[ _](C(?:25[1258]|21[16]|110|121|22[0379]|23[03])|X1)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "iCherry-C256",
                "device": "tablet",
                "model": "C256 Nano 3G"
            }
        ]
    },
    "NextTab": {
        "regex": "TAB (A40[13]0)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "Time2": {
        "regex": "(TC1050G)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1 10.1\""
    },
    "Sunstech": {
        "regex": "(TAB2323GMQC)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "Hotwav": {
        "regex": "(?:Venus[_ ]X1[024569]|Cosmos[ _](?:V8_lite|V19_Plus|V2[0-3]|U2|V19|V[569])|Pearl K2|Magic Q8|Magic 5 Plus|Magic (?:[89]|1[13])|Hot[_ ]6)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Cosmos[ _]V8_lite(?:[);/ ]|$)",
                "model": "Cosmos V8 Lite"
            },
            {
                "regex": "Hot[_ ]6?(?:[);/ ]|$)",
                "model": "Hot 6"
            },
            {
                "regex": "(Venus[_ ]X1[024569]|Cosmos[ _](?:V19_Plus|V2[0-3]|U2|V19|V[569])|Pearl K2|Magic Q8|Magic 5 Plus|Magic (?:[89]|1[13]))(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Tetratab": {
        "regex": "CASEBOOK_3(?:[);/ ]|$)",
        "device": "tablet",
        "model": "Casebook 3"
    },
    "Dolamee": {
        "regex": "Dolamee[_ -](D[56])(?:[);/ ]|$)",
        "device": "tv",
        "model": "$1"
    },
    "Invin": {
        "regex": "INVIN[ _-]|KM5PRO(?:[);/ ]|$)",
        "device": "tv",
        "models": [
            {
                "regex": "INVIN[ _-](X[24]|KM[678])",
                "model": "$1"
            },
            {
                "regex": "KM5PRO",
                "model": "KM5 Pro"
            }
        ]
    },
    "Doopro": {
        "regex": "Doopro P3(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "P3"
    },
    "IQM": {
        "regex": "IQM (?:picasso|vivaldi|newton)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "IQM picasso(?:[);/ ]|$)",
                "model": "Picasso"
            },
            {
                "regex": "IQM vivaldi(?:[);/ ]|$)",
                "model": "Vivaldi"
            },
            {
                "regex": "IQM newton(?:[);/ ]|$)",
                "model": "Newton"
            }
        ]
    },
    "Nos": {
        "regex": "Nos (?:FIVE|NOVU|NEVA|Roya)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "NOS FIVE(?:[);/ ]|$)",
                "model": "Five"
            },
            {
                "regex": "NOS NOVU III(?:[);/ ]|$)",
                "model": "Novu III"
            },
            {
                "regex": "NOS NOVU II(?:[);/ ]|$)",
                "model": "Novu II"
            },
            {
                "regex": "NOS NOVU(?:[);/ ]|$)",
                "model": "Novu"
            },
            {
                "regex": "NOS NEVA 80(?:[);/ ]|$)",
                "model": "Neva 80"
            },
            {
                "regex": "NOS Roya(?:[);/ ]|$)",
                "model": "Roya"
            }
        ]
    },
    "Accent": {
        "regex": "(?:ACCENT[_ ]NEON|FAST73G|PEARL[_ ]A[24567]|TANK_P55|CAMELEON[_ ](?:C[456]|A1)(?:[_ ]PLUS)?|Speed[- ](?:[AXYM]2|X2\\+))(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "ACCENT[_ ]NEON(?:[);/ ]|$)",
                "model": "Neon"
            },
            {
                "regex": "CAMELEON[_ ]C6[_ ]PLUS(?:[);/ ]|$)",
                "model": "Cameleon C6 Plus"
            },
            {
                "regex": "Speed[- ]([AXYM]2)(?:[);/ ]|$)",
                "model": "Speed $1"
            },
            {
                "regex": "Speed-X2\\+(?:[);/ ]|$)",
                "model": "Speed X2 Plus"
            },
            {
                "regex": "CAMELEON[_ ](C[456]|A1)(?:[);/ ]|$)",
                "model": "Cameleon $1"
            },
            {
                "regex": "PEARL A4 Lite(?:[);/ ]|$)",
                "model": "Pearl A4 Lite"
            },
            {
                "regex": "PEARL[_ ]A([2567])(?:[);/ ]|$)",
                "model": "Pearl A$1"
            },
            {
                "regex": "TANK_P55(?:[);/ ]|$)",
                "model": "Tank P55"
            },
            {
                "regex": "FAST73G(?:[);/ ]|$)",
                "device": "phablet",
                "model": "FAST7 3G"
            }
        ]
    },
    "Avenzo": {
        "regex": "Avenzo(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "MOB4 4G(?:[);/ ]|$)",
                "model": "Mob 4 4G"
            },
            {
                "regex": "MOB4PRO 4G(?:[);/ ]|$)",
                "model": "Mob 4 Pro 4G"
            }
        ]
    },
    "Beelink": {
        "regex": "(?:Beelink (?:G[ST]1|LAKE I|SEA I|A1)|GTKing|GT1mini-2|GT1-mini(?:-AT)?)(?:[);/ ]|$)",
        "device": "tv",
        "models": [
            {
                "regex": "Beelink (G[ST]1|LAKE I|SEA I|A1)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "GT1-mini(?:-AT)?(?:[);/ ]|$)",
                "model": "GT1 Mini"
            },
            {
                "regex": "GT1mini-2(?:[);/ ]|$)",
                "model": "GT1 Mini 2"
            },
            {
                "regex": "GTKing(?:[);/ ]|$)",
                "model": "GT King"
            }
        ]
    },
    "MAXVI": {
        "regex": "MAXVI[ _](?:MS401)(?:[);/ ]|$)|(?:\\w+)?_MAXVI_\\w+|MS502[_ ]Orion|MS531",
        "device": "smartphone",
        "models": [
            {
                "regex": "MAXVI[ _](MS401)",
                "model": "$1"
            },
            {
                "regex": "MS531",
                "model": "MS531 Vega"
            },
            {
                "regex": "MS502[_ ]Orion",
                "model": "MS502 Orion"
            },
            {
                "regex": "(?:\\w+)?MAXVI_(P11|K10|X[358]00)_\\w+",
                "device": "feature phone",
                "model": "$1"
            }
        ]
    },
    "MDC Store": {
        "regex": "Prime_S(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Prime_S(?:[);/ ]|$)",
                "model": "Prime S"
            }
        ]
    },
    "meanIT": {
        "regex": "meanIT[ _](?:C[12]|X10|X[126]|MG430|Q[145])(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "meanIT[ _]Q1 plus(?:[);/ ]|$)",
                "model": "Q1 Plus"
            },
            {
                "regex": "meanIT[ _](C[12]|X[126]|MG430|Q[145])(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "meanIT[ _]X10(?:[);/ ]|$)",
                "device": "tablet",
                "model": "X10"
            }
        ]
    },
    "Kenxinda": {
        "regex": "Three[_ ]Proofings[_ ]W([5-9])(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "Three Proofings W$1"
    },
    "X-BO": {
        "regex": "X[-]?BO (V[78]|V1[01]|V[89]\\+)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Xgody": {
        "regex": "XGODY",
        "device": "smartphone",
        "models": [
            {
                "regex": "XGODY Y17(?:[);/ ]|$)",
                "model": "Y17"
            },
            {
                "regex": "XGODY_X96(?:[);/ ]|$)",
                "model": "X96",
                "device": "tv"
            }
        ]
    },
    "Alcor": {
        "regex": "(Access_Q784C)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "iBrit": {
        "regex": "IBRIT_|(?:SI5[12])(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "IBRIT_I([57])(?:[);/ ]|$)",
                "model": "I$1"
            },
            {
                "regex": "iBRIT_POWER6(?:[);/ ]|$)",
                "model": "Power 6"
            },
            {
                "regex": "iBRIT_Speed Pro(?:[);/ ]|$)",
                "model": "Speed Pro"
            },
            {
                "regex": "SI51(?:[);/ ]|$)",
                "model": "Speed"
            },
            {
                "regex": "SI52(?:[);/ ]|$)",
                "model": "Speed Plus"
            }
        ]
    },
    "Ovvi": {
        "regex": "Jumbo X1",
        "device": "smartphone",
        "model": "Jumbo X1"
    },
    "Fero": {
        "regex": "Fero[_ ]|Royale[_ ](?:[YX]2|A1)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Fero_Y1",
                "model": "Y1"
            },
            {
                "regex": "Royale Y2 Lite",
                "model": "Royale Y2 Lite"
            },
            {
                "regex": "Royale[_ ]([YX]2|A1)",
                "model": "Royale $1"
            },
            {
                "regex": "FERO PACE",
                "model": "Pace"
            },
            {
                "regex": "Fero[ _](A4001|A450[23]|A5001|L100|Iris|Power|Mega LTE|Vibe)",
                "model": "$1"
            }
        ]
    },
    "Ruio": {
        "regex": "Ruio[ _]",
        "device": "smartphone",
        "models": [
            {
                "regex": "Ruio[ _](S4)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "Ruio[ _](S5)Plus(?:[);/ ]|$)",
                "model": "$1 Plus"
            }
        ]
    },
    "Transpeed": {
        "regex": "Transpeed_|(?:TR99 MINI\\+|TR99)(?:[);/ ]|$)",
        "device": "tv",
        "models": [
            {
                "regex": "TR99 MINI\\+",
                "model": "TR99 Mini Plus"
            },
            {
                "regex": "(?:Transpeed_)?(TR99|H616|6K)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "VVETIME": {
        "regex": "ZYVV1(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "V1"
    },
    "A1": {
        "regex": "A1 (?:Alpha|Alpha 20\\+)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "A1 Alpha 20\\+",
                "model": "Alpha 20 Plus"
            },
            {
                "regex": "A1 Alpha",
                "model": "Alpha"
            }
        ]
    },
    "Swisstone": {
        "regex": "swisstone (SD 5[13]0)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "P-UP": {
        "regex": "MD-04P(?:[);/ ]|$)",
        "device": "phablet",
        "model": "Mode 1 RR"
    },
    "iVooMi": {
        "regex": "(?:Innelo_1|iV 505)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Innelo_1",
                "model": "Innelo 1"
            },
            {
                "regex": "iV 505",
                "model": "Me 1"
            }
        ]
    },
    "Pixelphone": {
        "regex": "Pixelphone S1(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "S1"
    },
    "Pixela": {
        "regex": "Infineum[_ ](Z4?5)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "Infineum $1"
    },
    "Iris": {
        "regex": "(?:Vox[_ ](?:[45]S|Alpha|POP|STEEL Plus|Energy)|IS2_?Plus|IS2S|Next_P_PRO|IRS002-16)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Vox[_ ]([45])S",
                "model": "Vox $1S"
            },
            {
                "regex": "VOX Alpha Plus",
                "model": "Vox Alpha Plus"
            },
            {
                "regex": "VOX STEEL Plus",
                "model": "Vox Steel Plus"
            },
            {
                "regex": "VOX Alpha",
                "model": "Vox Alpha"
            },
            {
                "regex": "VOX Energy",
                "model": "Vox Energy"
            },
            {
                "regex": "VOX POP plus",
                "model": "Vox Pop Plus"
            },
            {
                "regex": "VOX POP Pro",
                "model": "Vox Pop Pro"
            },
            {
                "regex": "Next_P_PRO",
                "model": "Next P Pro"
            },
            {
                "regex": "IS2_?Plus",
                "model": "IS2 Plus"
            },
            {
                "regex": "IRS002-16",
                "model": "I-S6"
            },
            {
                "regex": "IS2S",
                "model": "IS2S"
            }
        ]
    },
    "Fonos": {
        "regex": "Fonos-(O1)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Xtouch": {
        "regex": "Xtouch[ _](X10|A5)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Conquest": {
        "regex": "Conquest[-_](?:S(?:[68]|1[0126])|F2)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Conquest[-_]S([68]|1[0126])",
                "model": "S$1"
            },
            {
                "regex": "Conquest-F2",
                "model": "F2"
            }
        ]
    },
    "Zatec": {
        "regex": "ZATEC[_ ]|(?:[ ]WIND|ZPLAY1_2018|Zpower_(?:Plus|2018))(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "ZATEC[_ ]GRAVITY(?:[);/ ]|$)",
                "model": "Gravity"
            },
            {
                "regex": "[ ]WIND(?:[);/ ]|$)",
                "model": "Wind"
            },
            {
                "regex": "ZPLAY1_2018",
                "model": "Zplay (2018)"
            },
            {
                "regex": "Zpower_2018",
                "model": "Zpower (2018)"
            },
            {
                "regex": "Zpower_Plus",
                "model": "Zpower Plus"
            }
        ]
    },
    "Selenga": {
        "regex": "Selenga[ _](A[134])(?:[);/ ]|$)",
        "device": "tv",
        "model": "$1"
    },
    "Starlight": {
        "regex": "Starlight|(?:My_Star_X|RIO STAR|C_Note_Pro|Star_Mix_2)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "My_Star_X",
                "model": "My Star X"
            },
            {
                "regex": "RIO STAR",
                "model": "Rio Star"
            },
            {
                "regex": "C_Note_Pro",
                "model": "C Note Pro"
            },
            {
                "regex": "Star_Mix_2",
                "model": "Star Mix 2"
            },
            {
                "regex": "Starlight_ROME_X",
                "model": "Rome X"
            }
        ]
    },
    "GEOFOX": {
        "regex": "GEOFOX[_](MID743)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "Tinmo": {
        "regex": "TiNMO[- _](A11|K3)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Matrix": {
        "regex": "(7116-A5)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "Verico": {
        "regex": "(RP-UDM0[12]A|RP-UDP05A)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "Uni Pad $1"
    },
    "TTEC": {
        "regex": "(Magictab (?:9GX|7GE|718))(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "eSTAR": {
        "regex": "eSTAR[_ ](?:Grand|GO\\!?|Zoom Quad|Gemini|MINI[_ ]3G|BEAUTY|MOON)",
        "device": "tablet",
        "models": [
            {
                "regex": "eSTAR (Grand|GO\\!?) (HD Intel|IPS Intel|IPS|HD|Intel) Quad Core 3G",
                "model": "$1 $2 Quad Core 3G"
            },
            {
                "regex": "eSTAR (Zoom Quad|Gemini|GO)",
                "model": "$1"
            },
            {
                "regex": "eSTAR GRAND HD quad core( [34]G)?",
                "model": "Grand HD Quad Core$1"
            },
            {
                "regex": "eSTAR GRAND",
                "model": "Grand"
            },
            {
                "regex": "eSTAR BEAUTY HD Quad core",
                "model": "Beauty HD Quad Core"
            },
            {
                "regex": "eSTAR MOON HD Quad core",
                "model": "Moon HD Quad Core 3G"
            },
            {
                "regex": "eSTAR BEAUTY 2 HD Quad core",
                "model": "Beauty 2 HD Quad Core"
            },
            {
                "regex": "eSTAR[_ ]MINI[_ ]3G",
                "model": "Mini 3G"
            }
        ]
    },
    "Hometech": {
        "regex": "(?:Ideal (?:10S|Tab [79])|ALFA10TB|ALFA[_ ](?:10RC|10TM?|7RS|[78]RC|7M)|HT[ _](?:7RT|8MZ))(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "(Ideal (?:10S|Tab 9))(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "ALFA[_ ]?(10RC|10T[BM]?|7RS|[78]RC|7M)(?:[);/ ]|$)",
                "model": "ALFA $1"
            },
            {
                "regex": "Ideal Tab 7 Ips( 3G)?",
                "model": "Ideal Tab 7 IPS$1"
            },
            {
                "regex": "HT[ _](7RT|8MZ)(?:[);/ ]|$)",
                "model": "HT $1"
            }
        ]
    },
    "ExMobile": {
        "regex": "EX(29|45)i(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "EX $1i"
    },
    "Hipstreet": {
        "regex": "10DTB12(?:[);/ ]|$)",
        "device": "tablet",
        "model": "Phoenix"
    },
    "Navitech": {
        "regex": "(NeoTab)[ _-]((?:S1[23]0|S125|H85|H11[05]|KID741|S9700))(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1 $2"
    },
    "NorthTech": {
        "regex": "(NT-TP10)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "TouchPad 10.0\""
    },
    "UNIWA": {
        "regex": "UNIWA[ _](X5)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Andowl": {
        "regex": "ANDOWL-(Q[45])(?:[);/ ]|$)",
        "device": "tv",
        "model": "$1"
    },
    "Silelis": {
        "regex": "Silelis[ _](T-1)(?:[);/ ]|$)",
        "device": "tv",
        "model": "$1"
    },
    "Bluedot": {
        "regex": "BLUEDOT[_ ]|(?:BNT-71)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "(?:BLUEDOT[_ ])?(BNP-500)(?:[);/ ]|$)",
                "device": "smartphone",
                "model": "$1"
            },
            {
                "regex": "(BNT-71)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Planet Computers": {
        "regex": "(Cosmo_Communicator)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "Reach": {
        "regex": "(Allure[_ ](?:Ultra(?:[_]Curve)?|Secure|Admire|Rise))(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "MobiWire": {
        "regex": "(?:Kiona|KANUNA|Halona|Kicka[_ ][45][ _]Plus|Kohana|Kosumi|KWANITA|Lonan|Hotah|Kicka 5|Kiona)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "KANUNA",
                "model": "Kanuna"
            },
            {
                "regex": "KWANITA",
                "model": "Kwanita"
            },
            {
                "regex": "(Kiona|Kicka[_ ][45][ _]Plus|Kohana|Kosumi|Lonan|Halona|Hotah|Kicka 5|Kiona)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Hezire": {
        "regex": "(HTB801)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "Hurricane": {
        "regex": "Hurricane[ _]|(?:Flame_Plus)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Hurricane[ _]IGNITE",
                "model": "Ignite"
            },
            {
                "regex": "Hurricane[ _]Bolt",
                "model": "Bolt"
            },
            {
                "regex": "Hurricane[ _]VORTEX",
                "model": "Vortex"
            },
            {
                "regex": "Hurricane[ _]GIGA",
                "model": "Giga"
            },
            {
                "regex": "Flame_Plus",
                "model": "Flame Plus"
            }
        ]
    },
    "iSWAG": {
        "regex": "ISWAG[ _]",
        "device": "smartphone",
        "models": [
            {
                "regex": "ISWAG[_ ]VIPER",
                "model": "Viper"
            },
            {
                "regex": "iSWAG[_ ]Lynx",
                "model": "Lynx"
            },
            {
                "regex": "iSWAG[_ ]MANTRA",
                "model": "Mantra"
            },
            {
                "regex": "iSWAG[_ ]MEGA",
                "model": "Mega"
            },
            {
                "regex": "iSWAG[_ ]NEMESIS",
                "model": "Nemesis"
            }
        ]
    },
    "Reeder": {
        "regex": "(?:reeder[_ ](?:3G_Tablet|A7iC|A[78]i[_ ]Quad|A8i Q2|M8 Plus|[TM]8|M10 Plus|P11SE)|P12Curve)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "reeder[_ ](3G_Tablet|A7iC|A[78]i[_ ]Quad|A8i Q2|M8 Plus|[TM]8|M10 Plus|P11SE)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "P12Curve",
                "device": "smartphone",
                "model": "P12 Curve"
            }
        ]
    },
    "ELARI": {
        "regex": "KidPhone4G(?:[);/ ]|$)",
        "device": "wearable",
        "model": "KidPhone 4G"
    },
    "Epik One": {
        "regex": "(K501 Plus|[ ]K5(?:0[0-4]|1[01]|03T|3[456]|4[05]|73)|[ ]K60[01]|[ ]X507T|[ ]K40[56]|[ ]T700)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "K501 Plus",
                "model": "Leo Plus"
            },
            {
                "regex": "K501",
                "model": "Leo"
            },
            {
                "regex": "K500",
                "model": "Omega Lite"
            },
            {
                "regex": "K502",
                "model": "Omega"
            },
            {
                "regex": "K503T",
                "model": "Leo Turbo"
            },
            {
                "regex": "K503",
                "model": "Leo Max"
            },
            {
                "regex": "K504",
                "model": "Titan 3G"
            },
            {
                "regex": "X507T",
                "model": "Titan II"
            },
            {
                "regex": "K510",
                "model": "Bolt"
            },
            {
                "regex": "K511",
                "model": "Bolt Pro"
            },
            {
                "regex": "K534",
                "model": "Hada"
            },
            {
                "regex": "K535",
                "model": "Hada Turbo"
            },
            {
                "regex": "K545",
                "model": "Rocket"
            },
            {
                "regex": "K536",
                "model": "Royal"
            },
            {
                "regex": "K540",
                "model": "Excalibur"
            },
            {
                "regex": "K573",
                "model": "Panther"
            },
            {
                "regex": "K600",
                "model": "Jupiter"
            },
            {
                "regex": "K601",
                "model": "Jupiter One"
            },
            {
                "regex": "K406",
                "model": "Pebble Plus"
            },
            {
                "regex": "K405",
                "model": "Pebble II"
            },
            {
                "regex": "T700",
                "model": "Palette",
                "device": "tablet"
            }
        ]
    },
    "Altice": {
        "regex": "Altice[ _](S62|S31|S43)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Kult": {
        "regex": "(Ambition|Beyond)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Beyond": {
        "regex": "SURVE(10|[26-8]|[12] PRO)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "SURVE([12]) PRO",
                "model": "Surve $1 Pro"
            },
            {
                "regex": "SURVE(10|[26-8])",
                "model": "Surve $1"
            }
        ]
    },
    "BioRugged": {
        "regex": "(?:BioWolf 8-F30|BIOWOLF8F30|Ruggbo 20 Lite)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "BioWolf 8-F30|BIOWOLF8F30",
                "model": "BioWolf 8-F30"
            },
            {
                "regex": "Ruggbo 20 Lite",
                "model": "Ruggbo 20 Lite"
            }
        ]
    },
    "VAIO": {
        "regex": "(?:VPA051|VA-10J)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "VPA051",
                "model": "Phone A"
            },
            {
                "regex": "(VA-10J)",
                "model": "$1"
            }
        ]
    },
    "Spark": {
        "regex": "Spark Plus 3(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Plus 3",
                "model": "Plus 3"
            }
        ]
    },
    "SPC": {
        "regex": "(?:Gravity Max|GRAVITY_OCTACORE|SPC SMART LITE|SPC_ALIEN(?:_STICK)?|SPC Glow9.7)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "GRAVITY_OCTACORE",
                "model": "Gravity OctaCore"
            },
            {
                "regex": "Gravity Max",
                "model": "Gravity Max"
            },
            {
                "regex": "SPC Glow9.7",
                "model": "Glow 9.7\""
            },
            {
                "regex": "SPC SMART LITE",
                "device": "smartphone",
                "model": "Smart Lite"
            },
            {
                "regex": "SPC_ALIEN_STICK",
                "device": "tv",
                "model": "Alien Stick"
            },
            {
                "regex": "SPC_ALIEN",
                "device": "tv",
                "model": "Alien"
            }
        ]
    },
    "Winmax": {
        "regex": "Winmax[ _](?:XC14|XC[46]|TX30)(?:[);/ ]|$)|MAUI.+Winmax_BD45",
        "device": "smartphone",
        "models": [
            {
                "regex": "Winmax[ _](XC14|XC[46])(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "Winmax[ _](TX30)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "$1"
            },
            {
                "regex": "MAUI.+Winmax_(BD45)",
                "device": "feature phone",
                "model": "$1"
            }
        ]
    },
    "Zebra": {
        "regex": " (TC5[16]|TC2[05]|TC75|TC7[05]x|TC700H)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Thuraya": {
        "regex": "Thuraya[ _](X5-Touch)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Bkav": {
        "regex": "Bphone[ _](B111[45]|B2017)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "Bphone $1"
    },
    "Brandt": {
        "regex": "(BPRIME|BSTAR\\+?|BPRIMES|B-ONE)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Bigben": {
        "regex": "GAMETAB-ONE(?:[);/ ]|$)",
        "device": "tablet",
        "model": "GameTab-One"
    },
    "SMARTEC": {
        "regex": "(Atractivo)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Asanzo": {
        "regex": "ASANZO[ _](?:A2|S[23]|[SZ]5|S3_PLUS)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "ASANZO[ _]S3[ _]PLUS",
                "model": "S3 Plus"
            },
            {
                "regex": "ASANZO[ _](A2|S[23]|[SZ]5)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Artizlee": {
        "regex": "Artizlee[ _]S9|ATL-(?:16|21(?:PLUS|[XT])?|31|26)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "ATL-21PLUS(?:[);/ ]|$)",
                "model": "ATL-21 Plus"
            },
            {
                "regex": "(ATL-(?:16|21[XT]?|31|26))(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "Artizlee[ _]S9",
                "device": "smartphone",
                "model": "S9"
            }
        ]
    },
    "Mintt": {
        "regex": "(?:Coolmintt(?:A3|M2)?|Ultramintt(?:S2)?|FRESHMINTT(?:A7|A10)?)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Coolmintt ?([PM]1|P3|A3|M2)(?:[);/ ]|$)",
                "model": "CoolMintt $1"
            },
            {
                "regex": "Ultramintt ?([SR]1|S2)(?:[);/ ]|$)",
                "model": "UltraMintt $1"
            },
            {
                "regex": "FRESHMINTT ?(A7|A10)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "FreshMintt $1"
            }
        ]
    },
    "Premio": {
        "regex": "(?:186HS2080531|186HS1050531|186HS1050232|186FT1080231|185FT5050231|PREMIO[ _](?:S50|P420|P451))(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "186HS2080531",
                "model": "X1"
            },
            {
                "regex": "186HS1050531",
                "model": "P541"
            },
            {
                "regex": "185FT5050231",
                "model": "P511"
            },
            {
                "regex": "186FT1080231",
                "model": "P571"
            },
            {
                "regex": "186HS1050232",
                "model": "P630"
            },
            {
                "regex": "PREMIO[ _](S50|P420|P451)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Trifone": {
        "regex": "Passion[-_](?:1S|PRO|PLUS)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Passion-1S",
                "model": "Passion 1S"
            },
            {
                "regex": "Passion-Plus",
                "model": "Passion Plus"
            },
            {
                "regex": "Passion_Pro",
                "model": "Passion Pro"
            }
        ]
    },
    "Bluewave": {
        "regex": "(?:33GT HD|WV8R-M|WV8R[_-]N)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "33GT HD",
                "model": "Wave 8 HD+"
            },
            {
                "regex": "WV8R-M",
                "model": "Wave 8 Red Marshmallow"
            },
            {
                "regex": "WV8R[_-]N",
                "model": "Wave 8 Red Nougat"
            }
        ]
    },
    "Siragon": {
        "regex": "Siragon|(?:SP-(?:5250|5400|5050))(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(?:Siragon[_ ])?(SP-(?:5[12]50|5400|5050))",
                "model": "$1"
            }
        ]
    },
    "Poppox": {
        "regex": "Poppox P3",
        "device": "smartphone",
        "model": "P3"
    },
    "Zentality": {
        "regex": "C-723ULTRA(?:[);/ ]|$)",
        "device": "tablet",
        "model": "C-723 Ultra"
    },
    "Black Bear": {
        "regex": "(B6 Master|B6 Note X)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Fortis": {
        "regex": "(Apollo 5 Neo)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "OpelMobile": {
        "regex": " OpelMobile_(SmartJX)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "NuAns": {
        "regex": "NEO \\[Reloaded\\](?:[);/ ]|$)",
        "device": "smartphone",
        "model": "NEO Reloaded"
    },
    "Koolnee": {
        "regex": "(K1 Trio)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Sansui": {
        "regex": "Sansui[_ ]",
        "device": "smartphone",
        "models": [
            {
                "regex": "Sansui[_ ](Switch|Play|Fun|SA4[01]|U4[56]|U3[02]|Nova)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Sico": {
        "regex": "SICO[_ ](?:PLUS|e1002)|Express3-Max|SSR3-1-51-5M|SSD-1-53-13M",
        "device": "smartphone",
        "models": [
            {
                "regex": "SICO[_ ]PLUS2",
                "model": "Plus 2"
            },
            {
                "regex": "SICO[_ ]PLUS",
                "model": "Plus"
            },
            {
                "regex": "SSR3-1-51-5M",
                "model": "Pro 3"
            },
            {
                "regex": "SSD-1-53-13M",
                "model": "Diamond"
            },
            {
                "regex": "SICO[_ ]e1002",
                "device": "tablet",
                "model": "E1002"
            },
            {
                "regex": "Express3-Max",
                "device": "tablet",
                "model": "Express 3 Max"
            }
        ]
    },
    "Smadl": {
        "regex": "Android.+(?:Safari)(?!/[\\d.]+)(?: Build|\\) AppleWebKit)|SMADL[ _]",
        "device": "smartphone",
        "models": [
            {
                "regex": "Android.+(?:Safari)(?!/[\\d.]+)(?: Build|\\) AppleWebKit)",
                "model": "Safari"
            },
            {
                "regex": "SMADL[ _]Matri([2X])",
                "model": "Matri $1"
            }
        ]
    },
    "EXCEED": {
        "regex": "(EX7SL?4|EX10S4)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "Tymes": {
        "regex": "Tymes[ _](V5)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Solone": {
        "regex": "SOLONE|(?:E1457|W145[02])(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "E1457",
                "model": "Earth Iron E1457"
            },
            {
                "regex": "W1450",
                "model": "Wind Breeze W1450"
            },
            {
                "regex": "W1452",
                "model": "Wind Air W1452"
            },
            {
                "regex": "SOLONE A47",
                "model": "SL-A47"
            },
            {
                "regex": "SOLONE[ _]Elite[ _]L5501",
                "model": "Elite L5501"
            },
            {
                "regex": "SOLONE[ _]SUMO[_ ]S5001",
                "model": "Sumo S5001"
            },
            {
                "regex": "SOLONE[ _](SL-K40|S5002)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "FarEasTone": {
        "regex": "Smart ?(601|550|50[59])(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "Smart $1"
    },
    "Smailo": {
        "regex": "Smailo_(2GO)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "Colors": {
        "regex": "(?:Colors[ _](S(?:[19]|11)|P70|P[456]5|P32|E15|P50 Plus)|Pride_1A|P52_Pride5C|P90_Pride5X)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Pride_1A(?:[);/ ]|$)",
                "model": "Pride 1A"
            },
            {
                "regex": "P52_Pride5C(?:[);/ ]|$)",
                "model": "Pride 5C"
            },
            {
                "regex": "P90_Pride5X(?:[);/ ]|$)",
                "model": "Pride 5X"
            },
            {
                "regex": "Colors[ _](S(?:[19]|11)|P70|E15|P[456]5|P32|P50 Plus)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Honeywell": {
        "regex": "(EDA50K?|EDA70|EDA51)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "ScanPal $1"
    },
    "Electroneum": {
        "regex": "electroneum(M1)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "High Q": {
        "regex": "(ELT070[46]H|ELT0802H)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "Pluzz": {
        "regex": "Pluzz[_-](Z552|PL5[05]10|PL4010|PL501[46]|Elite_Dual)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "IKU Mobile": {
        "regex": "(Powerful_K55i|Curvy C50L)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "IKI Mobile": {
        "regex": "(?:GO5_55|BLESS_GO55|BLESSPLUS|KF4Go|KF5bless)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "GO5_55",
                "model": "Go 5.5\""
            },
            {
                "regex": "BLESS_GO55",
                "model": "Bless Go 5.5\""
            },
            {
                "regex": "BLESSPLUS",
                "model": "Bless Plus"
            },
            {
                "regex": "(KF4Go|KF5bless)",
                "model": "$1"
            }
        ]
    },
    "CG Mobile": {
        "regex": "(?:CG_OMEGA6|Omega (?:[38])|CG_EON_Blaze_Pro|CG\\|EON Blaze GOLD)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Omega ([38])(?:[);/ ]|$)",
                "model": "Omega $1"
            },
            {
                "regex": "CG_OMEGA6(?:[);/ ]|$)",
                "model": "Omega 6"
            },
            {
                "regex": "CG_EON_Blaze_Pro(?:[);/ ]|$)",
                "model": "EON Blaze Pro"
            },
            {
                "regex": "CG\\|EON Blaze GOLD(?:[);/ ]|$)",
                "model": "EON Blaze Gold"
            }
        ]
    },
    "IUNI": {
        "regex": "IUNI[ _]",
        "device": "smartphone",
        "models": [
            {
                "regex": "IUNI[ _](U3|i1|N1)",
                "model": "$1"
            }
        ]
    },
    "ZH&K": {
        "regex": "(?:ODYSSEY O2|ODYSSEY RANGE)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "ODYSSEY O2",
                "model": "Odyssey O2"
            },
            {
                "regex": "ODYSSEY RANGE",
                "model": "Odyssey Range"
            }
        ]
    },
    "Ordissimo": {
        "regex": "(LeNumero1)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Necnot": {
        "regex": "(M002[DGQK]-2|M002N|M002N-3T|M002W-2T|6M-3T)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "Einstein": {
        "regex": "Einstein(T3)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "AfriOne": {
        "regex": "(CYGNUSX?|Gravity Z2|ChampionPro)(?:[);/ ]|$)",
        "device": "phablet",
        "models": [
            {
                "regex": "CYGNUSX(?:[);/ ]|$)",
                "model": "Cygnus X"
            },
            {
                "regex": "CYGNUS(?:[);/ ]|$)",
                "model": "Cygnus"
            },
            {
                "regex": "Gravity Z2(?:[);/ ]|$)",
                "device": "smartphone",
                "model": "Gravity Z2"
            },
            {
                "regex": "ChampionPro(?:[);/ ]|$)",
                "device": "smartphone",
                "model": "Champion Pro"
            }
        ]
    },
    "Connex": {
        "regex": "(CTAB1044hn|CTAB-1044)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "Connectce": {
        "regex": "(?:CP6001A)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "Connect Core 6"
    },
    "Clementoni": {
        "regex": "(?:CLEMPAD[_ ](?:6_XL|HR_Plus|HR|[68]|7_S|201[89])|ClemPhone_7|MFC_(?:2018|8))(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "CLEMPAD_(201[89])",
                "model": "Clempad ($1)"
            },
            {
                "regex": "MFC_2018",
                "model": "Clempad (2018)"
            },
            {
                "regex": "MFC_8",
                "model": "Clempad 8"
            },
            {
                "regex": "CLEMPAD_7_S",
                "model": "Clempad 7S"
            },
            {
                "regex": "Clempad[_ ](6_XL|HR_Plus|HR|[68])",
                "model": "Clempad $1"
            },
            {
                "regex": "ClemPhone[ _](7)",
                "device": "smartphone",
                "model": "Clemphone $1"
            }
        ]
    },
    "Cell-C": {
        "regex": "Cell_?C_",
        "device": "smartphone",
        "models": [
            {
                "regex": "Cell_C_(Summit_Plus|Fame|Evolve)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "CELLC_Evolve",
                "device": "tablet",
                "model": "Evolve"
            }
        ]
    },
    "VC": {
        "regex": "(?:Camellia_II|Cactus II|Maple III|Maple-II|Cactus-Pro|LavenderII|Jasmine-II)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(Camellia_II|Cactus II|Maple III)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "Cactus-Pro",
                "model": "Cactus Pro"
            },
            {
                "regex": "(Maple|Jasmine|Lavender)-?II(?:[);/ ]|$)",
                "model": "$1 II"
            }
        ]
    },
    "CellAllure": {
        "regex": "(?:Cool_Extreme_2|Earn|Fashion2Plus|Fashion[ _]C|Cool Duo|CHIC_X1)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(Cool_Extreme_2|Earn|Fashion[ _]C|Cool Duo)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "Fashion2Plus",
                "model": "Fashion 2 Plus"
            },
            {
                "regex": "CHIC_X1",
                "model": "Chic X1"
            }
        ]
    },
    "Eagle": {
        "regex": "Eagle[ _](Classic Pro|Elite|Gold)(?:[);/ ]|$)",
        "device": "tv",
        "model": "$1"
    },
    "Invens": {
        "regex": "(Fighter|Eager)(?: Build|[;)])",
        "device": "smartphone",
        "model": "$1"
    },
    "Chico Mobile": {
        "regex": "(?:ALTO (?:Bombo|classic|Estrella|JUEGO|SUBIR|[235])|FUEGO MAX|OJOS[ _](?:CAMBIO|NUBE|[68i]|ORO))(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "ALTO Bombo",
                "model": "Alto Bombo"
            },
            {
                "regex": "ALTO classic",
                "model": "Alto Classic"
            },
            {
                "regex": "ALTO Estrella",
                "model": "Alto Estrella"
            },
            {
                "regex": "ALTO JUEGO",
                "model": "Alto Juego"
            },
            {
                "regex": "ALTO SUBIR",
                "model": "Alto Subir"
            },
            {
                "regex": "ALTO ([235])",
                "model": "Alto $1"
            },
            {
                "regex": "OJOS[ _]([68i])",
                "model": "Ojos $1"
            },
            {
                "regex": "OJOS NUBE",
                "model": "Ojos Nube"
            },
            {
                "regex": "OJOS CAMBIO",
                "model": "Ojos Cambio"
            },
            {
                "regex": "OJOS ORO",
                "model": "Ojos Oro"
            },
            {
                "regex": "FUEGO MAX",
                "device": "tablet",
                "model": "Fuego Max"
            }
        ]
    },
    "Claresta": {
        "regex": "Claresta[ _]|(?:AirPhoneS[56]|AirPhone K6)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "AirPhone ?(S[56]|K6)",
                "model": "AirPhone $1"
            },
            {
                "regex": "Claresta (F3) PRO",
                "model": "$1 Pro"
            },
            {
                "regex": "Claresta[ _](F4)",
                "model": "$1"
            }
        ]
    },
    "Camfone": {
        "regex": "(?:Camfone (?:SE)|Hero[_ ](?:H5\\+|SE2|H9|(?:H7|XR)_Plus|SX|1[123]|SE3[ _]Max)|Honey (?:Y1|Y2s))(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(Hero[_ ](?:H5\\+|SE2|H9|(?:H7|XR)_Plus|SX|1[123]|SE3[ _]Max)|Honey (?:Y1|Y2s))(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "Camfone (SE)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Bellphone": {
        "regex": "Bp100 X-Plus(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "BP 100 X-Plus"
    },
    "Gini": {
        "regex": "Gini[ _]",
        "device": "smartphone",
        "models": [
            {
                "regex": "Gini[ _]W(7)",
                "model": "W$1"
            },
            {
                "regex": "Gini[ _]n(8)",
                "model": "N$1"
            },
            {
                "regex": "Gini[ _]s5Pro",
                "model": "S5 Pro"
            },
            {
                "regex": "Gini[ _]Tab[ _](V[78])",
                "device": "tablet",
                "model": "Tab $1"
            }
        ]
    },
    "MIVO": {
        "regex": "(Caryota [1245789]|Jazz J[13] 2018)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(Caryota [1245789])(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "Jazz (J[13]) 2018",
                "model": "Jazz $1 (2018)"
            }
        ]
    },
    "Jesy": {
        "regex": "Jesy_(J7)",
        "device": "smartphone",
        "model": "$1"
    },
    "Teknosa": {
        "regex": "Preo[ _]",
        "device": "smartphone",
        "models": [
            {
                "regex": "Preo Teknosa P1",
                "model": "Preo P1"
            },
            {
                "regex": "Preo[ _]P([23])",
                "model": "Preo P$1"
            }
        ]
    },
    "Hyve": {
        "regex": "(?:Pryme 01|ST01)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Pryme 01",
                "model": "Pryme 01"
            },
            {
                "regex": "ST01",
                "model": "Storm ST01"
            }
        ]
    },
    "EWIS": {
        "regex": "OCTA T720Q(?:[);/ ]|$)",
        "device": "tablet",
        "model": "Octa T720Q"
    },
    "Leader Phone": {
        "regex": "(Vyper|N-5[07]|N-45)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "N-45 light",
                "model": "N-45 Light"
            },
            {
                "regex": "(Vyper|N-5[07]|N-45)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Cavion": {
        "regex": "(?:Cavion[_ ](?:Solid[_ ]4[\\._]5|Base[ _](?:7|7_3G_Silver|5\\.0|10|7.2 Quad)|10_3GRQ)|Cavion7.1quad)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Cavion[_ ]Solid[_ ]4[\\._]5",
                "model": "Solid 4.5\""
            },
            {
                "regex": "Cavion BASE 5.0",
                "model": "Base 5.0\""
            },
            {
                "regex": "CAVION_10_3GRQ",
                "device": "tablet",
                "model": "Base 10 3GR Quad"
            },
            {
                "regex": "Cavion(?:7.1quad)",
                "device": "tablet",
                "model": "Base 7.1\" Quad"
            },
            {
                "regex": "Cavion Base 7.2 Quad",
                "device": "tablet",
                "model": "Base 7.2\" Quad"
            },
            {
                "regex": "Cavion Base 10",
                "device": "tablet",
                "model": "Base 10"
            },
            {
                "regex": "Cavion Base 7 Dual",
                "device": "tablet",
                "model": "Base 7.0\" Dual"
            },
            {
                "regex": "Cavion Base 7 Quad",
                "device": "tablet",
                "model": "Base 7.0\" Quad"
            },
            {
                "regex": "Cavion_Base_7_3G_Silver",
                "device": "tablet",
                "model": "Base 7.0\" 3G Silver"
            }
        ]
    },
    "GLX": {
        "regex": "GLX (?:Aria|Dorna|Taban|Shine|Spark|ASA)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "GLX ASA",
                "model": "Asa"
            },
            {
                "regex": "GLX (Aria|Taban|Shine|Spark)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "GLX (Dorna)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "$1"
            }
        ]
    },
    "Seeken": {
        "regex": "Seeken[ _-]",
        "device": "smartphone",
        "models": [
            {
                "regex": "Seeken[_ -](SG1|SJ1)",
                "model": "$1"
            },
            {
                "regex": "Seeken[_ -](ST-101)",
                "device": "tablet",
                "model": "$1"
            }
        ]
    },
    "Jivi": {
        "regex": "(?:Revolution TnT3|Xtreme_(?:[37]|3x)|Xtreme (?:XT84|XT309 Plus|1))(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Revolution (TnT3)",
                "model": "Revolution $1"
            },
            {
                "regex": "Xtreme (XT84)",
                "model": "Xtreme Klick $1"
            },
            {
                "regex": "Xtreme (XT309) Plus",
                "model": "Xtreme View Pro $1Plus"
            },
            {
                "regex": "Xtreme[ _]([137]|3x)(?:[);/ ]|$)",
                "model": "Xtreme $1"
            }
        ]
    },
    "Winnovo": {
        "regex": "Winnovo[ _]",
        "device": "tablet",
        "models": [
            {
                "regex": "Winnovo[ _](M866|M798|VTab|750[PK])(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "Winnovo[ _](K5[46]|K43)[a-z]{2}",
                "device": "smartphone",
                "model": "$1"
            }
        ]
    },
    "WE": {
        "regex": "WE (A[15]0|B3|F[12]0|E[12]|L[3469]|R[34]|V[34]|S1|X[13]|T1)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Trio": {
        "regex": "Trio V51 DT(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "V51DT"
    },
    "Seuic": {
        "regex": "(AUTOID9N)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Centric": {
        "regex": "(?:Centric[- ]CM(?:5001|33[23]1|3321Plus)|CM4331)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "(?:Centric[- ])?CM5001",
                "model": "A1"
            },
            {
                "regex": "(?:Centric[- ])?CM3321Plus",
                "model": "P1 Plus"
            },
            {
                "regex": "(?:Centric[- ])?CM3321",
                "model": "P1"
            },
            {
                "regex": "(?:Centric[- ])?CM3331",
                "model": "L3"
            },
            {
                "regex": "(?:Centric[- ])?CM4331",
                "model": "G1"
            }
        ]
    },
    "Kata": {
        "regex": "Kata[ _-]",
        "device": "smartphone",
        "models": [
            {
                "regex": "Kata[ _-](C3|L1|M3s|M4|V[56])(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "Kata[ _-]i5-\\w",
                "model": "I5"
            },
            {
                "regex": "Kata-(T5)(?:[);/ ]|$)",
                "device": "tablet",
                "model": "$1"
            },
            {
                "regex": "Kata-Tmini4(?:[);/ ]|$)",
                "device": "tablet",
                "model": "T Mini 4"
            },
            {
                "regex": "Kata Box 2(?:[);/ ]|$)",
                "device": "tv",
                "model": "Box 2"
            }
        ]
    },
    "Leff": {
        "regex": "Leff Shell (?:HV320WHB-N55|LC390TA2A)(?:[);/ ]|$)",
        "device": "tv",
        "model": ""
    },
    "Hardkernel": {
        "regex": "ODROID[-]?(C2|U2|XU[34])(?:[);/ ]|$)",
        "device": "peripheral",
        "model": "ODROID $1"
    },
    "Wiseasy": {
        "regex": "WISENET5(?:[);/ ]|$)",
        "device": "peripheral",
        "model": "WISENET5"
    },
    "Shtrikh-M": {
        "regex": "SHTRIH-SMARTPOS-F2(?:[);/ ]|$)",
        "device": "peripheral",
        "model": "Smartpos F2"
    },
    "BBK": {
        "regex": "([65]5|4[03]|3[29]|28)LEX[-_](81(?:27|39|45|58|61)|71(?:[23]7|4[345]|5[45]|6[368]|[57]8))[_/]([FU]?TS2C)(?:[);/ ]|$)",
        "device": "tv",
        "model": "$1LEX-$2/$3"
    },
    "4ife": {
        "regex": "4ife (4K Smart TV Box)(?:[);/ ]|$)",
        "device": "tv",
        "model": "$1"
    },
    "Westpoint": {
        "regex": "WESTPOINT[;,]",
        "device": "tv",
        "models": [
            {
                "regex": "(TETS-4919SM)",
                "model": "$1"
            }
        ]
    },
    "Wintouch": {
        "regex": "Q93S",
        "device": "tablet",
        "model": "Q93S"
    },
    "Aiuto": {
        "regex": "(AT702)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "Seatel": {
        "regex": "Seatel",
        "device": "smartphone",
        "models": [
            {
                "regex": "Seatel[ _](L2|V8[AE]|T[5-8]|R6|V[89])(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Ditecma": {
        "regex": "(M1092R(?:-[12]|V[45])?)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "Datamini": {
        "regex": "(K908H4G)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "3GNET": {
        "regex": "(?<!NetType/)3GNET[ _]",
        "device": "smartphone",
        "models": [
            {
                "regex": "3GNET[ _](U8|T800|U6s)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "3GNET[ _]T ?900",
                "model": "T900"
            }
        ]
    },
    "Yoka TV": {
        "regex": "KB2 PRO(?:[);/ ]|$)",
        "device": "tv",
        "model": "KB2 Pro"
    },
    "Formuler": {
        "regex": "Formuler[ _]",
        "device": "tv",
        "models": [
            {
                "regex": "Formuler[ _](Z8(?: Pro)?)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Malata": {
        "regex": "(?:Build/)?Malata[ _]?([a-z0-9]+)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "IT": {
        "regex": "IT-(?:1101S?|1101-QUAD)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "IT-1101-QUAD",
                "model": "1101 Quad"
            },
            {
                "regex": "IT-(1101S?)",
                "model": "$1"
            }
        ]
    },
    "Navcity": {
        "regex": "NP-752Go(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "NP-752Go",
                "model": "NP-752 Go"
            }
        ]
    },
    "Jiake": {
        "regex": "(?:JK(?:960|103) 3G|JK-?760)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "JK-?(760)(?:[);/ ]|$)",
                "model": "JK-$1"
            },
            {
                "regex": "JK(960|103) 3G(?:[);/ ]|$)",
                "device": "tablet",
                "model": "JK-$1 3G"
            }
        ]
    },
    "iQ&T": {
        "regex": "(IFoo (?:U200|H55))(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Mantra": {
        "regex": "(MFSTAB(?:_74G)?)(?:[);/ ]|$)",
        "device": "peripheral",
        "model": "$1"
    },
    "Datalogic": {
        "regex": "(?:MEMOR 10)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "Memor 10"
    },
    "Naomi Phone": {
        "regex": "NAOMIPHONE Ambar(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "Ambar"
    },
    "Navitel": {
        "regex": "NAVITEL[ _]",
        "device": "tablet",
        "models": [
            {
                "regex": "NAVITEL[ _](T70[07]) 3G(?:[);/ ]|$)",
                "model": "$1 3G"
            },
            {
                "regex": "NAVITEL[ _](T757)LTE(?:[);/ ]|$)",
                "model": "$1 LTE"
            },
            {
                "regex": "NAVITEL[ _](A73[0157]|A650)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "RE900 Full HD(?:[);/ ]|$)",
                "model": "RE900 Full HD"
            }
        ]
    },
    "Linnex": {
        "regex": "LI(22)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "Li $1"
    },
    "Smartab": {
        "regex": "(ST1009)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "SuperTab": {
        "regex": "SuperTab(A10|K7|S7G)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "Suzuki": {
        "regex": "SUZUKI[ _]|(?:ECOPAD7|SUMOPad10)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "SUMOPAD(8100|10)(?:[);/ ]|$)",
                "model": "SumoPad $1"
            },
            {
                "regex": "ECOPAD7",
                "model": "ECOPad 7"
            }
        ]
    },
    "Philips": {
        "regex": "Philips|AND1E(?:[);/ ]|$)|NETTV/|PI3210G|TLE(821L|722G)|TPM1[79]1E|70PUH6774_96|QM16[134]E|Xenium S[25]66|S397|W5510",
        "device": "smartphone",
        "models": [
            {
                "regex": "(?:Philips[_ ])?Xenium[ _](S[25]66|V787)(?:[);/ ]|$)",
                "model": "Xenium $1"
            },
            {
                "regex": "(?:Philips[_ ])?(S397|W5510)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "AND1E(?: TV)?(?:[);/ ]|$)",
                "model": "Android TV",
                "device": "tv"
            },
            {
                "regex": "(PI3210G)",
                "model": "$1",
                "device": "tablet"
            },
            {
                "regex": "(TPM1[79]1E)",
                "model": "$1",
                "device": "tv"
            },
            {
                "regex": "(QM16[134]E)",
                "model": "$1",
                "device": "tv"
            },
            {
                "regex": "(70PUH6774)_96",
                "model": "$1/96 4K UHD",
                "device": "tv"
            },
            {
                "regex": "(TLE821L)(?:[);/ ]|$)",
                "model": "$1 4G LTE",
                "device": "tablet"
            },
            {
                "regex": "(TLE722G)(?:[);/ ]|$)",
                "model": "$1",
                "device": "tablet"
            },
            {
                "regex": "Philips, BDP([0-9]{4})",
                "model": "Blu-ray Player (BDP$1)",
                "device": "tv"
            },
            {
                "regex": "Philips, ([a-z0-9-]+)",
                "model": "$1",
                "device": "tv"
            },
            {
                "regex": "(NETTV/[0-9\\.]{5})",
                "model": "NetTV Series",
                "device": "tv"
            },
            {
                "regex": "Philips-FISIO ([a-z0-9]+)",
                "model": "Fisio $1"
            },
            {
                "regex": "Philips[ _-]?([a-z0-9\\-@]+)",
                "model": "$1"
            }
        ]
    },
    "INCAR": {
        "regex": "(KZCAR)(?:[);/ ]|$)",
        "device": "car browser",
        "model": "$1"
    },
    "FaRao Pro": {
        "regex": "(FTRW100)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "Nabi": {
        "regex": "(?:DMTAB-(?:IN08A|NV08B|NV2[04]A)|NBTY07SMKG|NBFP07PMKG|SNB02-NV7A|NABI2-NV7A)(?:[);/ ]|$)",
        "device": "tablet",
        "models": [
            {
                "regex": "DMTAB-(?:IN08A|NV08B)",
                "model": "Dream Tab HD 8.0\""
            },
            {
                "regex": "DMTAB-NV(2[04])A",
                "model": "Big Tab HD $1.0\""
            },
            {
                "regex": "NBTY07SMKG",
                "model": "Barbie Tab 7.0\""
            },
            {
                "regex": "NBFP07PMKG",
                "model": "Fisher Price Learning Tab 7.0\""
            },
            {
                "regex": "SNB02-NV7A",
                "model": "2S Tab"
            },
            {
                "regex": "NABI2-NV7A",
                "model": "2 Tab"
            }
        ]
    },
    "StrawBerry": {
        "regex": "StrawBerry[ _]",
        "device": "smartphone",
        "models": [
            {
                "regex": "StrawBerry[ _](SX|QX15)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Gresso": {
        "regex": "Gresso[ _]",
        "device": "smartphone",
        "models": [
            {
                "regex": "Gresso[ _]Regal(?:[);/ ]|$)",
                "model": "Regal"
            }
        ]
    },
    "Pendoo": {
        "regex": "Pendoo|A5X MAX(?:[\\.-]\\w+)?\\.01\\.d4",
        "device": "tv",
        "models": [
            {
                "regex": "Pendoo ?(T95Z|X10) PLUS",
                "model": "$1 Plus"
            },
            {
                "regex": "Pendoo ?X8PRO+",
                "model": "X8 Pro+"
            },
            {
                "regex": "Pendoo[ \\.]?(X9[29])",
                "model": "$1"
            },
            {
                "regex": "pendoo mini",
                "model": "Mini"
            },
            {
                "regex": "A5X MAX(?:[\\.-]\\w+)?\\.01\\.d4",
                "model": "A5X Max"
            }
        ]
    },
    "PiPO": {
        "regex": "Pipo[_ ]|PipoM9Pro",
        "device": "tablet",
        "models": [
            {
                "regex": "Pipo[_ ]([TP]9)",
                "model": "$1"
            },
            {
                "regex": "PipoM9Pro",
                "model": "M9 Pro"
            },
            {
                "regex": "PIPO[_ ](X10|X9S)",
                "device": "tv",
                "model": "$1"
            }
        ]
    },
    "Engel": {
        "regex": "(EN1007Q)plus(?:[);/ ]|$)",
        "device": "tv",
        "model": "$1 Plus"
    },
    "Genesis": {
        "regex": "(SK-Mtek GT730[15])(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "InfoKit": {
        "regex": "(TVB-906X)(?:[);/ ]|$)",
        "device": "tv",
        "model": "$1"
    },
    "Blloc": {
        "regex": "(Zero18)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Huskee": {
        "regex": "VULKANO(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "Vulkano"
    },
    "SOLE": {
        "regex": "SOLE[_ ]POP[_ ]S([45]0)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "Pop S$1"
    },
    "SOLO": {
        "regex": "SOLO[_ ](Aspire(?: M)?)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "OINOM": {
        "regex": "OINOM[_ ](V9D|V7H|V[679])(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "Singtech": {
        "regex": "Singtech|(?:Sapphire[_ ](?:H50S|H7S?|H90))(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Sapphire[_ ](H50S|H7S?|H90)(?:[);/ ]|$)",
                "model": "Sapphire $1"
            },
            {
                "regex": "Singtech[ _](Q2|S1)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "Sunny": {
        "regex": "(SS4G9 Mira|SS4G7 Sole|SS4G1 Luna|SS4G8 Nora|SS4G6 Lola)(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "TEENO": {
        "regex": "TEENO",
        "device": "smartphone",
        "models": [
            {
                "regex": "TEENO[_ ](S9|A1|S11)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "v-mobile": {
        "regex": "Vmobile[ _]",
        "device": "smartphone",
        "models": [
            {
                "regex": "Vmobile[ _](J7)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "Vmobile XS Pro(?:[);/ ]|$)",
                "model": "XS Pro"
            }
        ]
    },
    "OASYS": {
        "regex": "(TEG9300)(?:[);/ ]|$)",
        "device": "peripheral",
        "model": "$1"
    },
    "Aspera": {
        "regex": "ASPERA|(?:Wren)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "ASPERA[ _](R5h|RT3|A4[02]|A50)(?:[);/ ]|$)",
                "model": "$1"
            },
            {
                "regex": "Wren(?:[);/ ]|$)",
                "model": "Wren"
            }
        ]
    },
    "Vision Touch": {
        "regex": "Vision ?Touch",
        "device": "smartphone",
        "models": [
            {
                "regex": "VisionTouchLife",
                "model": "Life"
            },
            {
                "regex": "Inspire",
                "model": "Inspire"
            }
        ]
    },
    "NOBUX": {
        "regex": "SMART_VOLT_NX5(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "Smart Volt NX5"
    },
    "Hitech": {
        "regex": "(Amaze[ _](?:S2_4G|S[56] 4G|S1|S[56](?: Plus)?))(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "$1"
    },
    "F150": {
        "regex": "Bison2021(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "B2021"
    },
    "BilimLand": {
        "regex": "BilimBook mini(?:[);/ ]|$)",
        "device": "desktop",
        "model": "BilimBook Mini"
    },
    "Greentel": {
        "regex": "X1_Light_Lte(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "X1_Light_Lte",
                "model": "X1 Light LTE"
            }
        ]
    },
    "Kyowon": {
        "regex": "(KA-E410W)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "LT Mobile": {
        "regex": "(?:LT-NOTE 10S)",
        "device": "smartphone",
        "models": [
            {
                "regex": "LT-NOTE 10S",
                "model": "Note 10S"
            }
        ]
    },
    "ONYX BOOX": {
        "regex": "KON_TIKI(?:[);/ ]|$)",
        "device": "tablet",
        "model": "Kon Tiki"
    },
    "GFive": {
        "regex": "(?:PRESIDENT_GOLD_(9|10))(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "PRESIDENT_GOLD_(9|10)",
                "model": "President Gold $1"
            }
        ]
    },
    "KINGZONE": {
        "regex": "KINGZONE_",
        "device": "smartphone",
        "models": [
            {
                "regex": "KINGZONE_(N5)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "M-Tech": {
        "regex": "EROS 4G(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "Eros 4G"
    },
    "Fantec": {
        "regex": "FAN-M300H(?:[);/ ]|$)",
        "device": "smartphone",
        "model": "M300H"
    },
    "AURIS": {
        "regex": "(TB-703G)(?:[);/ ]|$)",
        "device": "tablet",
        "model": "$1"
    },
    "YUHO": {
        "regex": "YUHO_",
        "device": "smartphone",
        "models": [
            {
                "regex": "YUHO_(O2|H2|Y[123])_PRO",
                "model": "$1 Pro"
            },
            {
                "regex": "YUHO_Vast_Plus",
                "model": "Vast Plus"
            },
            {
                "regex": "YUHO_Y1_ACE",
                "model": "Y1 Ace"
            },
            {
                "regex": "YUHO_Y1_STAR",
                "model": "Y1 Star"
            },
            {
                "regex": "YUHO_O1_LITE",
                "model": "O1 Lite"
            },
            {
                "regex": "YUHO_(Vast2?|O[12]|Y2)(?:[);/ ]|$)",
                "model": "$1"
            }
        ]
    },
    "2F Mobile": {
        "regex": "(Z8PLUS|Z8POCKET)(?:[);/ ]|$)",
        "device": "smartphone",
        "models": [
            {
                "regex": "Z8PLUS",
                "model": "Z8 Plus"
            },
            {
                "regex": "Z8POCKET",
                "model": "Z8 Pocket"
            }
        ]
    }
}
