import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LottieInfoDialogModel } from './lottie-info-dialog.model';
import { LottieComponent } from 'ngx-lottie';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'app-lotti-info-dialog',
  templateUrl: './lottie-info-dialog.component.html',
  styleUrls: ['./lottie-info-dialog.component.scss'],
  standalone: true,
  imports: [LottieComponent, TranslocoModule],
})
export class LottieInfoDialogComponent implements OnInit {
  @Input() public infoTitle?: string;
  @Input() public infoSubTitle?: string;
  @Input() public lottiePath?: string;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: LottieInfoDialogModel) {}

  ngOnInit(): void {
    if (this.data != null) {
      const { infoTitle, infoSubTitle, lottiePath } = this.data;
      this.infoTitle = infoTitle;
      this.infoSubTitle = infoSubTitle;
      this.lottiePath = lottiePath;
    }
  }
}
