[
    {
        "regex": "NetFront",
        "name": "NetFront"
    },
    {
        "regex": "Edge",
        "name": "Edge"
    },
    {
        "regex": "Trident",
        "name": "Trident"
    },
    {
        "regex": "(?<!SmartHu)Blink",
        "name": "Blink"
    },
    {
        "regex": "(?:Apple)?WebKit",
        "name": "WebKit"
    },
    {
        "regex": "Presto",
        "name": "Presto"
    },
    {
        "regex": "Goanna",
        "name": "Goanna"
    },
    {
        "regex": "(?<!like )Gecko",
        "name": "Gecko"
    },
    {
        "regex": "KHTML",
        "name": "KHTML"
    },
    {
        "regex": "NetSurf",
        "name": "NetSurf"
    },
    {
        "regex": "Servo",
        "name": "Servo"
    },
    {
        "regex": "Goanna",
        "name": "Goanna"
    },
    {
        "regex": "Ekioh(?:Flow)?",
        "name": "EkiohFlow"
    }
]
